import { Action } from 'redux';
import Statuses from 'types/statuses';

export interface IMailTemplate {
  id: number;
  body: string;
  name: string;
  subject: string;
  variables: Array<{ description:string, variable:string }>
}

export interface IMailTemplateData {
  body: string;
  subject: string;
}

export interface IMailTemplateState {
  templates: {
    items: Array<IMailTemplate> | null;
    isFetching: boolean;
    isLoading: boolean;
  },
  template: {
    item: IMailTemplate | null;
    isFetching: boolean;
    isLoading: boolean;
  },
  status: Statuses;
}

// Messages
export enum MailTemplateMessages {
  Update = 'Mail template was updated'
}

// Types
export const FETCH_LIST = '@@mailTemplate/Fetch list';
export const FETCH_LIST_SUCCESS = '@@mailTemplate/Fetch list success';

export const FETCH_BY_ID = '@@mailTemplate/Fetch by id';
export const FETCH_BY_ID_SUCCESS = '@@mailTemplate/Fetch by id success';

export const UPDATE = '@@mailTemplate/Update';
export const UPDATE_SUCCESS = '@@mailTemplate/Update success';

export const SET_STATUS = '@@mailTemplate/Set status';


// Actions
interface FetchList extends Action<typeof FETCH_LIST> {}
interface FetchListSuccess extends Action<typeof FETCH_LIST_SUCCESS> {
  payload: { items:Array<IMailTemplate> }
}

interface FetchById extends Action<typeof FETCH_BY_ID> {
  payload: { id:number }
}
interface FetchByIdSuccess extends Action<typeof FETCH_BY_ID_SUCCESS> {
  payload: { mailTemplate:IMailTemplate }
}

interface Update extends Action<typeof UPDATE> {
  payload: { id:number, data:IMailTemplateData }
}
interface UpdateSuccess extends Action<typeof UPDATE_SUCCESS> {
  payload: { mailTemplate:IMailTemplate }
}

interface SetStatus extends Action<typeof SET_STATUS> {
  payload: { status:Statuses }
}

export type MailTemplateActionTypes =
  FetchList | FetchListSuccess |
  FetchById | FetchByIdSuccess |
  Update | UpdateSuccess |
  SetStatus
;