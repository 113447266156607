import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
// Redux
import { connect } from 'react-redux';
import { MessageTemplateActions as Actions } from 'app/store/message-template/message-template.actions';
import * as Selectors from 'app/store/message-template/message-template.selectors';
// Bootstrap
import Modal from 'react-bootstrap/Modal';
// Components
import { DataLoading } from 'app/components/Loadings';
import { ButtonLoading } from 'app/components/Buttons';
// Utilities
import { isRequired } from 'utilities/validation';
import { toNormalCase } from 'utilities/wordbreak.utility';

const MessageTemplateFormDialog = ({
  // Props
  messageTemplateId, show, onHide,
  // State
  messageTemplate, isFetching, isLoading, status,
  // Dispatch
  fetchMessageTemplateById, updateMessageTemplate
}) => {
  const [ selectedVariable, setSelectedVariable ] = useState(null);
  const { register, handleSubmit, errors, reset, setValue } = useForm();

  const onSubmit = data => updateMessageTemplate(messageTemplateId, data);

  useEffect(() => {
    if ( messageTemplateId ) fetchMessageTemplateById(messageTemplateId);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( messageTemplate && messageTemplate.id ){
      reset({
        // description: messageTemplate.description,
        text: messageTemplate.text
      });
    }
    // eslint-disable-next-line
  }, [messageTemplate]);

  useEffect(() => {
    if ( status === 'Success' ) onHide();
    // eslint-disable-next-line
  }, [status]);

  const handleSelectVariable = variable => setSelectedVariable(variable);
  const handleClearVariable = () => setSelectedVariable(null);

  const handleFocus = e => {
    e.persist();
    if ( selectedVariable ){
      let { name, value } = e.target;
      setValue(name, value + selectedVariable);
      setSelectedVariable(null);
    }
  }

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Update message template: {messageTemplate.id ? <span className="text-capitalize">{toNormalCase(messageTemplate.name)}</span> : ''}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isFetching ? (
            <DataLoading />
          ) : (
            <div className="row row-8">
              <div className="col-12 col-sm-8">
                {/* Description */}
                {/* <div className="form-group">
                  <label htmlFor="description">Description *</label>
                  <input
                    ref={register({ required: isRequired })}
                    defaultValue=""
                    className={`
                      form-control form-control-sm
                      ${ errors.description ? 'is-invalid' : null }
                    `}
                    name="description" id="description" type="text"
                    onFocus={handleFocus}
                  />
                  {errors.description ? <div className="invalid-feedback">{errors.description.message}</div> : null}
                </div> */}
                {/* Text */}
                <div className="form-group">
                  <label htmlFor="description">Text *</label>
                  <textarea
                    ref={register({ required: isRequired })}
                    defaultValue=""
                    className={`
                      form-control form-control-sm
                      ${ errors.text ? 'is-invalid' : null }
                    `}
                    name="text" id="text"
                    rows={15}
                    onFocus={handleFocus}
                  ></textarea>
                </div>
              </div>
              <div className="col-12 col-sm-4">
                {messageTemplate.variables && messageTemplate.variables.map((variable, index) => (
                  <div key={`form-variables-${index}`} className="form-group">
                    <button
                      className="btn btn-primary btn-sm btn-block"
                        type="button"
                        onClick={() => handleSelectVariable(variable.variable)}
                    >{variable.variable}</button>
                    <small className="form-text text-muted">{variable.description}</small>
                  </div>
                ))}
                {selectedVariable ?
                  <button
                    className="btn btn-light btn-sm btn-block"
                    type="button"
                    onClick={handleClearVariable}
                  >Clear</button> : null
                }
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary btn-sm"
            type="button"
            onClick={onHide}
          >Cancel</button>
          <ButtonLoading
            loading={isLoading}
            type="submit"
          >Save</ButtonLoading>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

MessageTemplateFormDialog.defaultProps = {
  messageTemplateId: null
}

const mapStateToProps = state => ({
  messageTemplate: Selectors.getItem(state),
  isFetching: Selectors.getItemIsFetching(state),
  isLoading: Selectors.getItemIsLoading(state),
  status: Selectors.getStatus(state)
});

const mapDispatchToProps = dispatch => ({
  fetchMessageTemplateById: id => dispatch(Actions.fetchById(id)),
  updateMessageTemplate: (id, data) => dispatch(Actions.update(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageTemplateFormDialog);
