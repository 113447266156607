import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
// Redux
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import MailTemplateActions from 'store/mail-template/mail-template.actions';
import * as MailTemplateSelectors from 'store/mail-template/mail-template.selectors';
import { IMailTemplate, IMailTemplateData } from 'store/mail-template/mail-template.types';
import Statuses from 'types/statuses';
// Bootstrap
import Modal from 'react-bootstrap/Modal';
// Components
import { DataLoading } from 'app/components/Loadings';
import { ButtonLoading } from 'app/components/Buttons';
// Utilities
import { isRequired } from 'utilities/validation';
// Utilities
import { toNormalCase } from 'utilities/wordbreak.utility';
import CustomEditor from 'app/components/CustomEditor';

type IProps = {
  // Props
  mailTemplateId:number | null;
  show:boolean;
  onHide:() => void;
  // State
  mailTemplate:IMailTemplate;
  isFetching:boolean;
  isLoading:boolean;
  status:Statuses;
  // Dispatch
  fetchMailTemplateById:(id:number) => void;
  updateMailTemplate:(id:number, data:IMailTemplateData) => void;
}

const MailTemplateFormDialog:React.FC<IProps> = ({
  // Props
  mailTemplateId, show, onHide,
  // State
  mailTemplate, isFetching, isLoading, status,
  // Dispatch
  fetchMailTemplateById, updateMailTemplate
}) => {
  const [ selectedVariable, setSelectedVariable ] = useState<string | null>(null);
  const { register, handleSubmit, errors, reset, setValue, control, watch } = useForm<any>({
    defaultValues: {
      subject: '',
      body: ''
    }
  });

  const bodyWatcher = watch('body');

  const onSubmit = (data:any) => {
    if ( mailTemplateId ) updateMailTemplate(mailTemplateId, data);
  }

  useEffect(() => {
    if ( mailTemplateId ) fetchMailTemplateById(mailTemplateId);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( mailTemplate && mailTemplate.id ){
      reset({
        body: mailTemplate.body,
        subject: mailTemplate.subject,
      });
    }
    // eslint-disable-next-line
  }, [mailTemplate]);

  useEffect(() => {
    if ( status === 'Success' ) onHide();
    // eslint-disable-next-line
  }, [status]);

  const handleSelectVariable = (variable:string) => setSelectedVariable(variable);
  const handleClearVariable = () => setSelectedVariable(null);

  const handleFocus = (e:React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
    e.persist();
    if ( selectedVariable ){
      let { name, value } = e.target;
      setValue(name, value + selectedVariable);
      setSelectedVariable(null);
    }
  }

  const handleFocusEditor = (_: any, editor: any) => {
    setTimeout(() => {
      editor.model.change((writer: any) => {
        if ( !selectedVariable ) return;
        const position = editor.model.document.selection.getFirstPosition();
        writer.insertText(selectedVariable, position, 'after');
        setSelectedVariable(null);
      });
    }, 100);
  }

  return (
    <Modal show={show} onHide={onHide} size="lg" enforceFocus={false}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Update mail template: {mailTemplate?.id ? <span className="text-capitalize">{toNormalCase(mailTemplate.name)}</span> : ''}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isFetching ? (
            <DataLoading />
          ) : (
            <div className="row row-8">
              <div className="col-12 col-sm-8">
                {/* Description */}
                <div className="form-group">
                  <label htmlFor="description">Subject *</label>
                  <input
                    ref={register({ required: isRequired })}
                    className={`
                      form-control form-control-sm
                      ${ errors.subject ? 'is-invalid' : null }
                    `}
                    name="subject" id="subject" type="text"
                    onFocus={handleFocus}
                  />
                  {errors.subject ? <div className="invalid-feedback">{errors.subject.message}</div> : null}
                </div>
                {/* Text */}
                <div className="form-group">
                  <label htmlFor="description">Body *</label>
                  <Controller
                    control={control} name="body"
                    render={({ onChange }) => (
                      <CustomEditor
                        value={bodyWatcher}
                        onChange={(e: any, editor: any) => {
                          const data = editor.getData();
                          onChange(data);
                        }}
                        onFocus={handleFocusEditor}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-4">
                {mailTemplate?.variables && mailTemplate.variables.map((variable, index) => (
                  // if ( variable.description === 'Visitor name' ) return null;
                  <div key={`form-variables-${index}`} className="form-group">
                    <button
                      className="btn btn-primary btn-sm btn-block"
                        type="button"
                        onClick={() => handleSelectVariable(variable.variable)}
                    >{variable.variable}</button>
                    <small className="form-text text-muted">{variable.description}</small>
                  </div>
                ))}
                {selectedVariable ?
                  <button
                    className="btn btn-light btn-sm btn-block"
                    type="button"
                    onClick={handleClearVariable}
                  >Clear</button> : null
                }
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary btn-sm"
            type="button"
            onClick={onHide}
          >Cancel</button>
          <ButtonLoading
            loading={isLoading}
            type="submit"
          >Save</ButtonLoading>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

MailTemplateFormDialog.defaultProps = {
  mailTemplateId: null,
  show: false,
  onHide: () => null
}

const mapStateToProps = (state:any) => ({
  mailTemplate: MailTemplateSelectors.getTemplate(state),
  isFetching: MailTemplateSelectors.getTemplateFetching(state),
  isLoading: MailTemplateSelectors.getTemplateLoading(state),
  status: MailTemplateSelectors.getStatus(state)
});

const mapDispatchToProps = (dispatch:Dispatch) => ({
  fetchMailTemplateById: (id:number) => dispatch(MailTemplateActions.fetchById(id)),
  updateMailTemplate: (id:number, data:IMailTemplateData) => dispatch(MailTemplateActions.update(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MailTemplateFormDialog);
