import React from 'react';
// Store
import { connect } from 'react-redux';
import UserActions from 'app/actions/user.actions';
import * as UserSelectors from 'app/_selectors/user.selectors';
// Components
import { Input, Select } from 'app/components/Controls';

let timeout = null;

const CampersFilter = ({
  // Props
  // State
  params,
  // Dispatch
  fetchAllCampers
}) => {
  const handleChange = event => {
    const { target: { name, value } } = event;
    if ( timeout ) clearTimeout(timeout);
    timeout = setTimeout(() => {
      fetchAllCampers({ ...params, offset: 0, [name]: value });
    }, 1000);
  }

  return (
    <div className="row row-8">
      <div className="col-12 col-sm-4 col-lg-2">
        <Input
          label="Name" id="username" name="username"
          onChange={handleChange}
        />
      </div>
      <div className="col-12 col-sm-4 col-lg-2">
        <Input
          label="Phone" type="tel" id="phone" name="phone"
          onChange={handleChange}
        />
      </div>
      <div className="col-12 col-sm-4 col-lg-2">
        <Select
          label="OS" id="camperPlatform" name="camperPlatform"
          onChange={handleChange}
          options={[
            { value: '', label: 'All' },
            { value: 'android', label: 'Android' },
            { value: 'ios', label: 'iOS' },
            { value: 'none', label: 'None' }
          ]}
        />
      </div>
      <div className="col-12 col-sm-6 col-lg-3">
        <Input
          label="Visiting from" type="date" id="visitingFrom" name="visitingFrom"
          onChange={handleChange}
        />
      </div>
      <div className="col-12 col-sm-6 col-lg-3">
        <Input
          label="Visiting to" type="date" id="visitingTo" name="visitingTo"
          onChange={handleChange}
        />
      </div>
    </div>
  )
}

export default connect(
  ({ UserState }) => ({
    params: UserSelectors.getParams(UserState)
  }),
  dispatch => ({
    fetchAllCampers: params => dispatch(UserActions.fetchAllCampers(params))
  })
)(CampersFilter);
