import { takeLatest, call, put } from 'redux-saga/effects';
// Actions
import * as LayoutActions from 'app/actions/layout.actions';
import Types from 'app/types/notification.types';
import NotificationService from 'app/services/notification.service';
import NotificationActions from 'app/actions/notification.actions';

// Fetch order list
function* _fetchAll(action){
  try {
    const { params, isMore } = action.payload;
    const response = yield call(NotificationService.fetchAll, params);
    yield put(NotificationActions.fetchAllSuccess(response, params, isMore));
  } catch(err){
    yield put(NotificationActions.fetchAllFailure());
    console.error(err);
  }
}

// Send one time notification
function* _send(action){
  try {
    const { notification } = action.payload;
    yield call(NotificationService.send, notification);
    yield put(NotificationActions.sendSuccess());
    yield put(NotificationActions.setInitialState());
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: 'Notification sent'
    }));
  } catch(err){
    yield put(NotificationActions.sendFailure());
    console.error(err);
  }
}

function* _sendBulk(action){
  try {
    const { data } = action.payload;
    yield call(NotificationService.sendBulk, data);
    yield put(NotificationActions.sendBulkSuccess());
    yield put(NotificationActions.setInitialState());
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: 'Bulk notification sent'
    }));
  } catch(err){
    yield put(NotificationActions.sendFailure());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: err.message
    }));
    console.error(err);
  }
}

export function* notificationSaga(){
  yield takeLatest(Types.FETCH_ALL, _fetchAll);
  yield takeLatest(Types.SEND, _send);
  yield takeLatest(Types.SEND_BULK, _sendBulk);
}