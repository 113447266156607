import React, { useState } from 'react';
// Dialogs
import CamperFormDialog from 'dialogs/CamperForm.dialog';
// 
import { parseUTCDateTime } from 'app/utilities/datetime.convert';

const CampersListItem = ({
  // Props
  item, onCheck, onUncheck, onDelete
}) => {
  const { appRegisteredOn, firstName, lastName, email, callingCode, phone, checked, platform, notificationPreferences, address } = item;

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);

  const handleCheck = () => onCheck(item.id);
  const handleUncheck = () => onUncheck(item.id);

  const handleDelete = () => onDelete(item);

  return (
    <>
      <div className={`card | w-100 ${ checked ? '_checked' : '' }`}>
        <div className="card-body">
          <h5 className="card-title | mb-2">{firstName} {lastName}</h5>
          {appRegisteredOn ? (
            <small className="card-subtitle | d-block mb-2">
              <span className="d-block">App installed at:&nbsp;</span>
              <span className="text-muted">{parseUTCDateTime(appRegisteredOn)}</span>
            </small>
          ) : null}
          <small className="card-subtitle | d-block mb-2 text-muted">{email}</small>
          <small className="card-subtitle | d-block mb-2 text-muted">{callingCode}{phone}</small>
          {address ? (
            <small className="card-subtitle | d-block mb-2 text-muted">{address}</small>
          ) : null}
        </div>
        <div className="card-footer | d-flex justify-content-between align-items-center">
          <div className="text-muted">
            {platform ? (
              <span className="mr-2 pr-2 border-right">
                {platform === 'android' ? (
                  <i className="fa fa-android" aria-hidden="true"></i>
                ) : (
                  <i className="fa fa-apple" aria-hidden="true"></i>
                )}
              </span>
            ) : null}
            {notificationPreferences ? (
              <>
                {notificationPreferences.sms ? (
                  <i className="fa fa-sms mr-2" aria-hidden="true"></i>
                ) : null}
                {notificationPreferences.email ? (
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                ) : null}
              </>
            ) : null}
          </div>
          <div>
            <button
              className="btn btn-primary btn-sm | mr-2"
              type="button"
              onClick={handleShow}
            >Edit</button>
            {checked ? (
              <button
                className="btn btn-secondary btn-sm | mr-2"
                type="button"
                onClick={handleUncheck}
              >Unselect</button>
            ) : (
              <button
                className="btn btn-outline-secondary btn-sm | mr-2"
                type="button"
                onClick={handleCheck}
              >Select</button>
            )}
            <button
              className="btn btn-outline-danger btn-sm"
              type="button"
              onClick={handleDelete}
            >Delete</button>
          </div>
        </div>
      </div>
      {show ? (
        <CamperFormDialog
          camper={item}
          show={show}
          onHide={handleHide}
        />
      ) : null}
    </>
  )
}

export default CampersListItem;
