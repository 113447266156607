import React from 'react'

const CategorySeasonal = ({
  // Props
  values, onChange, errors
  // State
  // Dispatch
}) => {
  return (
    <div className="row row-8">
      <div className="col-12 col-lg-4">

        <div className="form-group">
          <label htmlFor="seasonStart">Season start&nbsp;*</label>
          <input
            className={`form-control form-control-sm ${ errors.seasonStart && 'is-invalid' }`}
            id="seasonStart"
            type="date"
            name="seasonStart"
            value={values.seasonStart}
            onChange={onChange}
          />
          {errors.seasonStart && <div className="invalid-feedback">{errors.seasonStart}</div>}
        </div>

      </div>
      <div className="col-12 col-lg-4">

        <div className="form-group">
          <label htmlFor="seasonEnd">Season end&nbsp;*</label>
          <input
            className={`form-control form-control-sm ${ errors.seasonEnd && 'is-invalid' }`}
            id="seasonEnd"
            type="date"
            name="seasonEnd"
            value={values.seasonEnd}
            onChange={onChange}
          />
          {errors.seasonEnd && <div className="invalid-feedback">{errors.seasonEnd}</div>}
        </div>

      </div>
      <div className="col-12 col-lg-4">

        <div className="form-group">
          <label htmlFor="price">Period price&nbsp;*</label>
          <div className="input-group input-group-sm">
            <div className="input-group-prepend">
              <span className="input-group-text">$</span>
            </div>
            <input
              className={`form-control form-control-sm ${ errors.periodPrice && 'is-invalid' }`}
              id="periodPrice"
              type="number"
              name="periodPrice"
              value={values.periodPrice}
              onChange={onChange}
            />
            {errors.periodPrice && <div className="invalid-feedback">{errors.periodPrice}</div>}
          </div>
        </div>

      </div>
    </div>
  )
}

export default CategorySeasonal;
