import {
  IAppState, AppTypes, AppActionTypes
} from './app.types';

export default class AppActions {
  // App inited
  public static setInited = ():AppActionTypes => ({
    type: AppTypes.SetInited
  });
  public static setRequestedUrl = (requestedUrl:string):AppActionTypes => ({
    type: AppTypes.SetRequestedUrl, payload: { requestedUrl }
  });
}