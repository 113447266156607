import React from 'react';
// Store
import { useDispatch } from 'react-redux';
// Actions
import MembershipActions from 'store/membership/membership.actions';
// React bootstrap
import { Modal } from 'react-bootstrap';
// 
import TransactionsList from './TransactionsList';

type Props = {
  membershipId: number;
  show: boolean;
  onHide: () => void;
};

const TransactionsDialog:React.FC<Props> = ({
  // Props
  membershipId, show, onHide
}) => {
  // Dispatch
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(MembershipActions.fetchMembershipTransactions(membershipId));
    return () => {
      dispatch(MembershipActions.setInitialField('transactions'));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>Transactions</Modal.Header>
      <Modal.Body>
        <TransactionsList membershipId={membershipId} />
      </Modal.Body>
    </Modal>
  )
}

export default TransactionsDialog;
