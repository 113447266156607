import { takeLatest, call, put } from 'redux-saga/effects';
// Store
import * as LayoutActions from 'app/actions/layout.actions';
import Types from '../types/order.types';
import OrderService from '../services/order.service';
import OrderActions from '../actions/order.actions';

function* _fetchTransactionsList(action){ 
  try {
    const { orderId } = action.payload;
    const response = yield call(OrderService.fetchTransactionsList, orderId);
    yield put(OrderActions.fetchTransactionsListSuccess(response));
  } catch(err){
    yield put(OrderActions.fetchTransactionsListFailure());
    console.error(err);
  }
}

// Fetch order list
function* _fetchAll(action){
  try {
    const { params, isMore } = action.payload;
    const response = yield call(OrderService.fetchAll, params);
    yield put(OrderActions.fetchAllSuccess(response, params, isMore));
  } catch(err){
    yield put(OrderActions.fetchAllFailure());
    console.error(err);
  }
}

// Fetch order by id
function* _fetchById(action){
  try {
    const { orderId } = action.payload;
    const response = yield call(OrderService.fetchById, orderId);
    yield put(OrderActions.fetchByIdSuccess(response));
  } catch(err){
    yield put(OrderActions.fetchByIdFailure());
  }
}

// Send receipts
function* _sendReceipt(action){
  try {
    const { orderId, transactionId, email } = action.payload;
    yield call(OrderService.sendReceipt, orderId, transactionId, email);
    yield put(OrderActions.sendReceiptSuccess());
    yield put(OrderActions.setStateStatus('Success'));
    yield put(OrderActions.setStateStatus('Initial'));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: 'Receipt was sent'
    }));
  } catch(err){
    yield put(OrderActions.setStatusFailure());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: err.message
    }));
    yield put(OrderActions.setStateStatus('Error'));
  }
}

// Set order status
function* _setStatus(action){
  try {
    const { orderId, status } = action.payload;
    const response = yield call(OrderService.setStatus, orderId, status);
    yield put(OrderActions.setStatusSuccess(response));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: 'Status was updated'
    }));
  } catch(err){
    yield put(OrderActions.setStatusFailure());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: err.message
    }));
  }
}

// Cancel
function* _cancel(action){
  try {
    const { orderId, notes } = action.payload;
    // if ( window.confirm(`Are you sure you want to cancel order #${order.orderNumber} and refund $${order.totalPrice} ?`) ){
      yield call(OrderService.cancel, orderId, notes);
      yield put(OrderActions.cancelSuccess(orderId));
      yield put(OrderActions.setStateStatus('Success'));
      yield put(OrderActions.setStateStatus('Initial'));
      yield put(LayoutActions.addNotification({
        type: 'success',
        message: 'Order was cancelled'
      }));
    // } else {
    //   yield put(OrderActions.cancelFailure());
    // }
  } catch(err){
    yield put(OrderActions.cancelFailure());
    console.error(err);
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: err.message
    }));
  }
}

// Export
function* _export(action){
  try {
    const { params } = action.payload;
    yield call(OrderService.export, params);
    yield put(OrderActions.exportSuccess());
  } catch(err){
    yield put(OrderActions.exportFailure());
    console.error(err);
  }
}

function* _checkIn(action){
  try {
    const { id, activityId, confirmationCode, status } = action.payload;
    yield call(OrderService.checkIn, activityId, confirmationCode, status);
    yield put(OrderActions.checkInSuccess(id, {
      id: activityId,
      status
    }));
  } catch(err){
    console.error(err);
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: err.message
    }));
  }
}

export function* orderSaga(){
  yield takeLatest(Types.FETCH_TRANSACTIONS_LIST, _fetchTransactionsList);
  yield takeLatest(Types.FETCH_ALL, _fetchAll);
  yield takeLatest(Types.FETCH_BY_ID, _fetchById);
  yield takeLatest(Types.SET_STATUS, _setStatus);
  yield takeLatest(Types.CANCEL, _cancel);
  yield takeLatest(Types.EXPORT, _export);
  yield takeLatest(Types.SEND_RECEIPT, _sendReceipt);
  yield takeLatest(Types.CHECK_IN, _checkIn);
}
