import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
// Redux
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import MapActions from 'store/map/map.actions';
import { getIsLoading, getStatus } from 'store/map/map.selectors';
import { IMapData } from 'store/map/map.types';
import Statuses from 'types/statuses';
import MapTypes from 'types/map-types';
// Bootstrap
import Modal from 'react-bootstrap/Modal';
// Components
import { Input } from 'components/Controls';
import { ButtonLoading } from 'app/components/Buttons';
// Utilities
import { isRequired } from 'utilities/validation';

type IProps = {
  // Props
  show: boolean;
  onHide: () => void;
  // State
  isLoading: boolean;
  status: Statuses;
  // Dispatch
  createMap: (data:IMapData) => void;
};

const MapCreateFormDialog:React.FC<IProps> = ({
  // Props
  show, onHide,
  // State
  isLoading, status,
  // Dispatch
  createMap
}) => {
  const { register, handleSubmit, errors } = useForm<any>({
    defaultValues: {
      name: '',
      mapTypeId: ''
    }
  });

  const onSubmit = (data:any) => {
    const { name } = data;
    const newData = { name, options: {
      mapTypeId: MapTypes.Roadmap,
      lat: 0.000000,
      lng: 0.000000,
      zoom: 1
    }};
    createMap(newData);
  }

  useEffect(() => {
    if ( status === Statuses.Success ) onHide();
    // eslint-disable-next-line
  }, [status]);

  return (
    <Modal show={show} onHide={onHide}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Create map</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Input
            controlRef={register({ required: isRequired })}
            label="Name" id="name" name="name"
            required={true}
            errorText={errors.name ? errors.name.message : null}
          />

        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary btn-sm"
            type="button"
            onClick={onHide}
          >Cancel</button>
          <ButtonLoading
            loading={isLoading}
            type="submit"
          >Create</ButtonLoading>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

MapCreateFormDialog.defaultProps = {
  show: false,
  onHide: () => null
};

const mapStateToProps = (state:any) => ({
  isLoading: getIsLoading(state),
  status: getStatus(state)
});

const mapDispatchToProps = (dispatch:Dispatch) => ({
  createMap: (data:IMapData) => dispatch(MapActions.create(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(MapCreateFormDialog);
