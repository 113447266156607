import HttpUtility from 'utilities/http.utility';
import { IAccountData } from './account.types';

export default class AccountService {
  private static _url:string = '/admin/accounts';
  private static _http:HttpUtility = new HttpUtility();

  // Fetch list
  public static async fetchList():Promise<any> {
    return AccountService._http.get(AccountService._url);
  }
  // Create
  public static async create(data:IAccountData):Promise<any> {
    return AccountService._http.post(AccountService._url, data);
  }
  // Update
  public static async update(id:number, data:IAccountData):Promise<any> {
    return AccountService._http.put(`${AccountService._url}/${id}`, data);
  }
  // Delete
  public static async delete(id:number):Promise<any> {
    return AccountService._http.delete(`${AccountService._url}/${id}`);
  }
  // Setting names
  public static async fetchAccountSettingNames():Promise<any> {
    return AccountService._http.get(`${AccountService._url}/accountSettingNames`);
  }
}
