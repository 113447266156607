import React from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
// Redux
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import MapActions from 'store/map/map.actions';
import { getItem, getIsLoading } from 'store/map/map.selectors';
import { IMap, IMapData } from 'store/map/map.types';
// Components
import { Input } from 'components/Controls';
import { ButtonLoading } from 'app/components/Buttons';
// Utilities
import { isRequired } from 'utilities/validation';

type IProps = {
  // State
  item: IMap;
  isLoading: boolean;
  // Dispatch
  updateMap: (id:number, data:IMapData) => void;
}

const MapDetailsForm:React.FC<IProps> = ({
  // State
  item, isLoading,
  // Dispatch
  updateMap
}) => {
  const { id } = useParams<{ id?:string }>();

  const { register, handleSubmit, errors } = useForm<any>({
    defaultValues: {
      name: item?.name || ''
    }
  });

  const onSubmit = (data:any) => {
    console.log(data);
    const { name } = data;
    const newData = { name, options: item.options };
    updateMap(Number(id), newData);
  }

  return (
    <form>
      <Input
        controlRef={register({ required: isRequired })}
        label="Name" id="name" name="name"
        required={true}
        errorText={errors.name ? errors.name.message : null}
      />
      <div className="text-right">
        <ButtonLoading
          loading={isLoading}
          type="button"
          onClick={handleSubmit(onSubmit)}
        >Save</ButtonLoading>
      </div>
    </form>
  )
}

const mapStateToProps = (state:any) => ({
  item: getItem(state),
  isLoading: getIsLoading(state)
});

const mapDispatchToProps = (dispatch:Dispatch) => ({
  updateMap: (id:number, data:IMapData) => dispatch(MapActions.update(id, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(MapDetailsForm);
