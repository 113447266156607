import { takeLatest, all, call, put } from 'redux-saga/effects';
// Redux
// import UserActions from '../../actions/user.actions';
// import UserService from '../../services/user.service';
// import InventoryGroupActions from '../../actions/inventory-group.actions';
// import InventoryGroupService from '../../services/inventory-group.service';
import { AppActionTypes } from './app.types';
import { AppActions } from './app.actions';
import { fetchTemplatesVariablesSuccess } from '../../actions/template.actions';
import { $fetchTemplatesVariables } from '../../services/template.service';
import { ContentCategoryActions } from 'app/store/content-category/content-category.actions';

import { ContentCategoryService } from 'app/store/content-category/content-category.service';

import { ProductCategoryActions } from 'app/store/product-category/product-category.actions';
import { ProductCategoryService } from 'app/store/product-category/product-category.service';
// Next
import AccountService from 'store/account/account.service';
import StaffService from 'store/staff/staff.service';
import InventoryGroupService from 'store/inventory-group/inventory-group.service';
import AccountActions from 'store/account/account.actions';
import StaffActions from 'store/staff/staff.actions';
import InventoryGroupActions from 'store/inventory-group/inventory-group.actions';

// Fetch admin
function* _fetchAdminData(){
  try {
    const [
      responseStaff, responseVariables, responseInventoryGroup, responseProductCategory, responseContentCategory
    ] = yield all([
      // call(UserService.fetchAllStaff, {}),
      call(StaffService.fetchList),
      call($fetchTemplatesVariables),
      call(InventoryGroupService.fetchList),
      call(ProductCategoryService.fetchList),
      call(ContentCategoryService.fetchList)
    ]);
    // yield put(UserActions.fetchAllStaffSuccess(responseStaff));
    yield put(StaffActions.fetchListSuccess(responseStaff.parseBody));
    yield put(InventoryGroupActions.fetchListSuccess(responseInventoryGroup.parseBody));
    yield put(fetchTemplatesVariablesSuccess(responseVariables));
    yield put(ProductCategoryActions.fetchListSuccess(responseProductCategory));
    yield put(ContentCategoryActions.fetchListSuccess(responseContentCategory));

    yield put(AppActions.fetchAdminDataSuccess());
  } catch(err){
    yield put(AppActions.fetchAdminDataFailure());
    console.error(err);
  }
}

// Fetch manager
function* _fetchManagerData(){
  try {
    const [
      responseStaff, responseInventoryGroup, responseProductCategory
    ] = yield all([
      // call(UserService.fetchAllStaff, {}),
      call(StaffService.fetchList),
      call(InventoryGroupService.fetchList),
      call(ProductCategoryService.fetchList)
    ]);
    // yield put(UserActions.fetchAllStaffSuccess(responseStaff));
    yield put(StaffActions.fetchListSuccess(responseStaff.parseBody));
    yield put(InventoryGroupActions.fetchListSuccess(responseInventoryGroup.parseBody));
    yield put(ProductCategoryActions.fetchListSuccess(responseProductCategory));

    yield put(AppActions.fetchAdminDataSuccess());
  } catch(err){
    console.error(err);
    yield put(AppActions)
  }
}

// Fetch account manager data
function* _fetchAccountManagerData(){
  try {
    const { parseBody } = yield call(AccountService.fetchAccountSettingNames);
    yield put(AccountActions.fetchSettingNamesSuccess(parseBody));
    yield put(AppActions.fetchAdminDataSuccess());
  } catch(err){
    console.error(err);
    yield put(AppActions)
  }
}

export default function* (){
  yield takeLatest(AppActionTypes.FetchAdminData, _fetchAdminData);
  yield takeLatest(AppActionTypes.FetchManagerData, _fetchManagerData);
  yield takeLatest(AppActionTypes.FetchAccountManagerData, _fetchAccountManagerData);
}
