import { createSelector } from 'reselect';

const _waivers = state => state.waivers;
const _waiver = state => state.waiver;
const _status = state => state.status;

export const getWaivers = createSelector(
  _waivers, waivers => waivers
);
export const getWaiver = createSelector(
  _waiver, waiver => waiver
);
export const getStatus = createSelector(
  _status, status => status
);
