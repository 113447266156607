export const formControlsValue = {
  location: '',
  startDate: '',
  startTime: '',
  endDate: '',
  endTime: '',
  description: ''
};

export const formControlsOptions = {
  location: {
    label: 'Location',
    id: 'location',
    name: 'location',
    placeholder: 'Location',
    rules: {
      isRequired: true
    }
  },
  startDate: {
    label: 'Start date',
    id: 'startDate',
    name: 'startDate',
    type: 'date',
    placeholder: 'Start date',
    rules: {
      isRequired: true
    }
  },
  startTime: {
    label: 'Start time',
    id: 'startTime',
    name: 'startTime',
    type: 'time',
    placeholder: 'Start time',
    rules: {
      isRequired: true
    }
  },
  endDate: {
    label: 'End date',
    id: 'endDate',
    name: 'endDate',
    type: 'date',
    placeholder: 'End date',
    rules: {
      isRequired: true
    }
  },
  endTime: {
    label: 'End time',
    id: 'endTime',
    name: 'endTime',
    type: 'time',
    placeholder: 'End time',
    rules: {
      isRequired: true
    }
  },
  description: {
    label: 'Description',
    id: 'description',
    name: 'description',
    placeholder: 'Description',
    rules: {
      isRequired: true
    }
  }
}
