import React from 'react';
// Dialogs
import TaxFormDialog from './TaxForm.dialog';

type Props = {
  bookingPropertyId?: number;
  inventoryGroupId?: number;
  membershipTypeId?: number;
};

const TaxesCreate:React.FC<Props> = (props) => {
  const [ show, setShow ] = React.useState<boolean>(false);

  const toggleDialog = () => setShow((prevState:boolean) => !prevState);

  return (
    <React.Fragment>
      <div className="text-right mb-2">
        <button
          className="btn btn-primary btn-sm"
          type="button"
          onClick={toggleDialog}
        >Create tax</button>
      </div>
      {show ? (
        <TaxFormDialog
          {...props}
          show={show}
          onHide={toggleDialog}
        />
      ) : null}
    </React.Fragment>
  )
}

export default TaxesCreate;
