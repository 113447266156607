import React from 'react';
// 
import DrawerTop from './DrawerTop';
import DrawerMiddle from './DrawerMiddle';

const Drawer = ({
  open
}) => {
  return (
    <div className={`root-drawer ${ open ? `is-open` : `` }`}>
      <DrawerTop />
      <DrawerMiddle />
    </div>
  )
}

export default Drawer;
