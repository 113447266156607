import firebase from 'firebase';
import 'firebase/messaging';
import fetcher from 'app/utilities/fetcher';
import { store } from './index';
import { AppActions } from 'app/store/app/app.actions';

firebase.initializeApp({
  // Test
  // apiKey: "AIzaSyBwZ1k7hLCdCuI-g-jpLDonGMA77q3aIzs",
  // authDomain: "aicamping-test.firebaseapp.com",
  // databaseURL: "https://aicamping-test.firebaseio.com",
  // projectId: "aicamping-test",
  // storageBucket: "aicamping-test.appspot.com",
  // messagingSenderId: "53744021497",
  // appId: "1:53744021497:web:7b30625f568d1a4c895728"
  // Test END
  apiKey: "AIzaSyBocmpA4_mFD_Yck0Bu6Vp_knufQxhxgRw",
  authDomain: "aicamping.firebaseapp.com",
  databaseURL: "https://aicamping.firebaseio.com",
  projectId: "aicamping",
  storageBucket: "aicamping.appspot.com",
  messagingSenderId: "596405147313",
  appId: "1:596405147313:web:75d1ce04c3273b3e41f490"
});

let messaging = null;
let deviceToken = null;

if ( firebase.messaging.isSupported() ){
  messaging = firebase.messaging()
}

export function unsubscribeToken(){
  if ( checkIsPushNotificationSupported() ) return;

  messaging.deleteToken(deviceToken)
    .then(() => {
      deviceToken = null;
      deleteDeviceToken();
    })
    .catch(error => {
      console.error(error);
    });
}

export function initializePush(){
  if ( checkIsPushNotificationSupported() ) return;

  messaging.requestPermission()
    .then(() => messaging.getToken())
    .then(token => {
      deviceToken = getDeviceToken();
      checkDeviceToken(token);
    })
    .catch(error => {
      console.error('Unable to get permission to notify', error);
    });

  messaging.onTokenRefresh(() => {
    messaging.getToken()
      .then(refreshToken => {
        checkDeviceToken(refreshToken, true);
      })
      .catch(error => {
        console.error('Refresh token error', error);
      });
  });

  messaging.onMessage(payload => {
    console.log(payload);
    store.dispatch(AppActions.addPushNotification(payload));
  });
}

function checkIsPushNotificationSupported(){
  return !('PushManager' in window);
}

function getDeviceToken(){
  return localStorage.getItem('aicamping:device_token') || '';
}

function setDeviceToken(token){
  localStorage.setItem('aicamping:device_token', token);
}

function deleteDeviceToken(){
  localStorage.removeItem('aicamping:device_token');
}

async function checkDeviceToken(token, isRefreshToken){
  if ( isRefreshToken ){
    const oldToken = getDeviceToken();
    await fetcher(`/v1/users/deviceTokens?platform=web&token=${oldToken}`, {
      method: 'DELETE'
    }, () => null);
  }
  setDeviceToken(token);
  await fetcher(`/v1/users/deviceTokens?platform=web&token=${token}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  }, () => {});
}
