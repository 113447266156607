import create from './create-type';

const _type = 'assets';

const _actions = [
  'CREATE_ASSET',
  'DELETE_ASSET',
];

const Types = {};

for ( let i in _actions ){
  Types[_actions[i]] = create(_type, [_actions[i]]);
  Types[`${_actions[i]}_SUCCESS`] = create(_type, `${[_actions[i]]}_SUCCESS`);
  Types[`${_actions[i]}_FAILURE`] = create(_type, `${[_actions[i]]}_FAILURE`);
}

Types['SET_INITIAL_STATE'] = create(_type, 'SET_INITIAL_STATE');

export default Types;
