import PropTypes from 'prop-types';
import React, { Component } from 'react';
import dayjs from 'dayjs';
// Components
import { InputControl, TextareaControl } from '../../../components/FormControls';
import { ButtonLoading } from '../../../components/Buttons';
// Utils
import { parseUTCDate, parseUTCTime, setTimeDate } from '../../../utilities/datetime.convert';
// 
import { formControlsValue, formControlsOptions } from './FormControls';

class ScheduleSlotFormBuilder extends Component {
  constructor(props){
    super(props);

    const { selectedSlot } = props;
    const slot = {}

    if ( selectedSlot && selectedSlot.id ){
      slot['location'] = selectedSlot.location;
      slot['startDate'] = dayjs(selectedSlot.start).format('YYYY-MM-DD');
      slot['startTime'] = dayjs(selectedSlot.start).format('HH:mm');
      slot['endDate'] = dayjs(selectedSlot.end).format('YYYY-MM-DD');
      slot['endTime'] = dayjs(selectedSlot.end).format('HH:mm');
      slot['description'] = selectedSlot.description;
    }

    this.state = {
      isFormSubmitted: false,
      formControlsValue: {
        ...formControlsValue,
        ...slot
      },
      formControlsOptions
    }
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState(prevState => ({
      formControlsValue: {
        ...prevState.formControlsValue,
        [name]: value
      }
    }));
  }

  handleSubmit = evt => {
    evt.preventDefault();

    this.setState({
      isFormSubmitted: true
    });

    if ( !this.refForm.checkValidity() ) return;

    const { formControlsValue } = this.state;
    const { selectedSlot, onCreateScheduleSlot, onUpdateScheduleSlot } = this.props;
    const { location, startDate, startTime, endDate, endTime, description } = formControlsValue;

    const [ startHour, startMinute ] = startTime.split(':');
    const [ endHour, endMinute ] = endTime.split(':');

    const start = dayjs(startDate).hour(startHour).minute(startMinute).second(0).format('YYYY-MM-DDTHH:mm:ss.sss[Z]');
    let end;
    if ( endHour === '00' ){
      end = dayjs(endDate).hour(endHour).minute(endMinute).second(0).add(1, 'day').format('YYYY-MM-DDTHH:mm:ss.sss[Z]');
    } else {
      end = dayjs(endDate).hour(endHour).minute(endMinute).second(0).format('YYYY-MM-DDTHH:mm:ss.sss[Z]');
    }
    // const start = setTimeDate(startDate, startTime);
    // const end = setTimeDate(endDate, endTime);

    const slot = {
      location,
      start,
      end,
      description
    };

    if ( selectedSlot && selectedSlot.id ){
      onUpdateScheduleSlot({...selectedSlot, ...slot});
    } else {
      onCreateScheduleSlot(slot);
    }
  }

  render() {
    const { isFormSubmitted, formControlsValue, formControlsOptions } = this.state;
    const { onHide, isFormLoading } = this.props;
    return (
      <form
        noValidate
        name="form"
        ref={ref => this.refForm = ref}
        onSubmit={this.handleSubmit}
      >
        {/* Location */}
        <InputControl
          submitted={isFormSubmitted}
          value={formControlsValue.location}
          options={formControlsOptions.location}
          onChange={this.handleChange}
        />
        {/* Start */}
        {/* End */}
        <div className="row row-8">
          <div className="col-6">
            <InputControl
              submitted={isFormSubmitted}
              value={formControlsValue.startDate}
              options={formControlsOptions.startDate}
              onChange={this.handleChange}
            />
            <InputControl
              submitted={isFormSubmitted}
              value={formControlsValue.startTime}
              options={formControlsOptions.startTime}
              onChange={this.handleChange}
            />
          </div>
          <div className="col-6">
            <InputControl
              submitted={isFormSubmitted}
              value={formControlsValue.endDate}
              options={formControlsOptions.endDate}
              onChange={this.handleChange}
            />
            <InputControl
              submitted={isFormSubmitted}
              value={formControlsValue.endTime}
              options={formControlsOptions.endTime}
              onChange={this.handleChange}
            />
          </div>
        </div>
        {/* Description */}
        <TextareaControl
          submitted={isFormSubmitted}
          value={formControlsValue.description}
          options={formControlsOptions.description}
          onChange={this.handleChange}
        />
        <div className="text-right | border-top pt-3 mt-2">
          <button
            className="btn btn-outline-secondary btn-sm | mr-2"
            type="button"
            onClick={onHide}
          >Cancel</button>
          <ButtonLoading
            loading={isFormLoading}
            type="submit"
          >Save</ButtonLoading>
        </div>
      </form>
    )
  }
}

ScheduleSlotFormBuilder.propTypes = {
  selectedSlot: PropTypes.object.isRequired
}

export default ScheduleSlotFormBuilder;