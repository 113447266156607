// Types
import Statuses from 'types/statuses';
import { WaiverTypes, WaiverActionTypes } from './waiver.types';
// Models
import { IWaiver } from 'models/Waiver';

export default class WaiverActions {
  // Fetch waivers
  public static fetchWaivers = (params:any):WaiverActionTypes => ({
    type: WaiverTypes.FetchWaivers, payload: { params }
  });
  public static fetchWaiversSuccess = (waivers:Array<IWaiver>):WaiverActionTypes => ({
    type: WaiverTypes.FetchWaiversSuccess, payload: { waivers }
  });
  // Fetch waiver
  public static fetchWaiver = (waiverId:number):WaiverActionTypes => ({
    type: WaiverTypes.FetchWaiver, payload: { waiverId }
  });
  public static fetchWaiverSuccess = (waiver:IWaiver):WaiverActionTypes => ({
    type: WaiverTypes.FetchWaiverSuccess, payload: { waiver }
  });
  // Create
  public static createWaiver = (data:any):WaiverActionTypes => ({
    type: WaiverTypes.CreateWaiver, payload: { data }
  });
  public static createWaiverSuccess = (waiver:IWaiver):WaiverActionTypes => ({
    type: WaiverTypes.CreateWaiverSuccess, payload: { waiver }
  });
  // Update
  public static updateWaiver = (waiverId:number, data:any):WaiverActionTypes => ({
    type: WaiverTypes.UpdateWaiver, payload: { waiverId, data }
  });
  public static updateWaiverSuccess = (waiver:IWaiver):WaiverActionTypes => ({
    type: WaiverTypes.UpdateWaiverSuccess, payload: { waiver }
  });
  // Delete
  public static deleteWaiver = (waiverId:number):WaiverActionTypes => ({
    type: WaiverTypes.DeleteWaiver, payload: { waiverId }
  });
  public static deleteWaiverSuccess = (waiverId:number):WaiverActionTypes => ({
    type: WaiverTypes.DeleteWaiverSuccess, payload: { waiverId }
  });
  // Default
  // Set status
  public static setStatus = (status:Statuses):WaiverActionTypes => ({
    type: WaiverTypes.SetStatus, payload: { status }
  })
}