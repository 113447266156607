import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(utc);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);


export let dateTimeFormat = 'MMMM DD, YYYY hh:mm a';
export let dateFormat = 'MMMM DD, YYYY';
export let timeFormat = 'hh:mm a';

export const toCamperZone = date => {
  return dayjs(new Date(date).toLocaleString('en-US', { timeZone: 'America/New_York' }))
}
export const toCamperZoneDate = date => {
  return toCamperZone(date).format(dateFormat);
}
export const toCamperZoneTime = date => {
  return toCamperZone(date).format(timeFormat);
}

export const checkIsBeforeCamperTimeZone = (date1, date2, type) => {
  return dayjs(toCamperZone(date1)).isBefore(toCamperZone(date2), type)
}
export const checkIsAfterCamperTimeZone = (date1, date2, type) => {
  return dayjs(toCamperZone(date1)).isAfter(toCamperZone(date2), type)
}
export const checkIsSameOrAfterCamperTimeZone = (date1, date2, type) => {
  return dayjs(toCamperZone(date1)).isSameOrAfter(toCamperZone(date2), type)
}



export let defaultDateFormat = 'YYYY-MM-DD';

export let currentDate = dayjs().format('YYYY-MM-DD');
export let firstDate = dayjs().date(1).format('YYYY-MM-DD');

export const parseUTCStartEndDate = (start, end) => {
  if ( dayjs(start).isSame(dayjs(end), 'day') ){
    return `${dayjs.utc(start).format(dateTimeFormat)} - ${dayjs.utc(end).format(timeFormat)}`;
  }
  return `${dayjs.utc(start).format(dateTimeFormat)} - ${dayjs.utc(end).format(dateTimeFormat)}`;
}

export const toDateTime = (date, format = dateTimeFormat) => dayjs(date).local().format(format);

export const parseUTCDateTime = (datetime, format = dateTimeFormat) => dayjs(datetime).utc().local().format(format);
export const parseUTCDate = (date, format = dateFormat) => dayjs(date).utc().local().format(format);
export const parseUTCTime = (time, format = timeFormat) => dayjs(time).utc().local().format(format);

export const parseDate = (date, format = dateFormat) => dayjs(date).format(format);
export const parseDateTime = (datetime, format = dateTimeFormat) => {
  return toCamperZone(datetime).format(format);
}

export const setStartDate = date => dayjs(date).hour(0).minute(0).second(0).toISOString();
export const setEndDate = date => dayjs(date).hour(23).minute(59).second(59).toISOString();

export const setStart = (date, format, nullHour = true) => {
  const d = dayjs(date).minute(0).second(0);
  if ( nullHour ) d.hour(0);
  if ( format ) return d.format(format);
  return d;
}
export const setEnd = (date, format, substract = false, nullHour = false) => {
  const d = dayjs(date);
  if ( substract ) d.subtract(1, 'day');
  if ( nullHour ){
    d.hour(23).minute(59).second(59);
  } else {
    d.minute(0).second(0)
  }
  if ( format ) return d.format(format);
  return d;
}

export const setEndEnd = (date, format) => {
  const d = dayjs(date).subtract(1, 'day').hour(23).minute(59).second(59);
  if ( format ) return d.format(format);
  return d;
}

export const setTimeDate = (date, time) => {
  if ( typeof time === 'string' && time.indexOf(':') !== -1 ) time = time.split(':');
  return dayjs(date).hour(time[0]).minute(time[1]).second(0).toISOString();
}

export const isInRange = (start, end, currentDate) => {
  start = parseUTCDate(start);
  end = parseUTCDate(end);
  if (
    dayjs(currentDate).isSame(dayjs(start), 'day') ||
    dayjs(currentDate).isSame(dayjs(end), 'day')
  ){
    return true;
  } else {
    if ( dayjs(currentDate).isAfter(dayjs(start)) && dayjs(currentDate).isBefore(dayjs(end)) ){
      return true;
    }
  }
  return false;
}

export const isTimeGreater = (start, end) => {
  const [startHours, startMinutes] = typeof start === 'string' ? start.split(':') : '';
  const [endHours, endMinutes] = typeof end === 'string' ? end.split(':') : '';
  const startDate = dayjs().hour(startHours).minute(startMinutes);
  // console.log(startMinutes);
  const endDate = dayjs().hour(endHours).minute(endMinutes);
  // console.log(endMinutes);
  if ( dayjs(startDate).unix() === dayjs(endDate).unix() ) return true;
  if ( dayjs(startDate).isAfter(dayjs(endDate)) ) return true;
  return false;
}

export const checkIsSame = (date1, date2, type) => dayjs(date1).isSame(date2, type);
export const checkIsBefore = (date1, date2, type) => dayjs(date1).isBefore(dayjs(date2), type);
export const checkIsAfter = (date1, date2, type) => dayjs(date1).isAfter(dayjs(date2), type);
export const checkIsSameOrAfter = (date1, date2, type) => dayjs(date1).isSameOrAfter(dayjs(date2), type);
export const checkIsSameOrBefore = (date1, date2, type) => dayjs(date1).isSameOrBefore(dayjs(date2), type);

export const buildRange = (start, end, format = 'dddd') => {
  const result = [];
  let nextStart = dayjs(start);
  let nextEnd = dayjs(end);
  let done = false;
  while (!done){
    const day = nextStart.format(format);
    result.push(day.toLowerCase());
    nextStart = nextStart.add(1, 'day');
    done = nextStart.isSame(nextEnd, 'day');
  }
  return result;
}
