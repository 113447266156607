import React from 'react';
// Models
import { ITax } from 'models/Tax';
// Types
import Statuses from 'types/statuses';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Actions
import TaxesActions from 'store/taxes/taxes.actions';
// Selectors
import { selectIsLoading, selectStatus } from 'store/taxes/taxes.selectors';
// Dialogs
import ConfirmationDialog from 'dialogs/Confirmation.dialog';
import TaxFormDialog from './TaxForm.dialog';

type Props = {
  tax: ITax;
}

const TaxesListItem:React.FC<Props> = ({
  // Props
  tax
}) => {
  // Dispatch
  const dispatch = useDispatch();
  // State
  const isLoading:boolean = useSelector(selectIsLoading);
  const status:Statuses = useSelector(selectStatus);

  const [ showConfirmationDialog, setShowConfirmationDialog ] = React.useState<boolean>(false);
  const [ showTaxFormDialog, setShowTaxFormDialog ] = React.useState<boolean>(false);

  const toggleConfirmationDialog = () => setShowConfirmationDialog((prevState:boolean) => !prevState);
  const toggleTaxFormDialog = () => setShowTaxFormDialog((prevState:boolean) => !prevState);

  const onConfirm = () => {
    dispatch(TaxesActions.deleteTax(tax.id))
  }

  return (
    <React.Fragment>
      <li className='list-group-item d-flex align-items-center'>
        <div className="flex-fill">
          <h6 className="mb-0">{tax.name}</h6>
          <small className="text-capitalize">
            {tax.type}&nbsp;
            {tax.type === 'absolute' ? '$' : ''}
            {tax.value}
            {tax.type === 'percentage' ? '%' : ''}
          </small>
        </div>
        <div className="pl-2">
          <button
            className="btn btn-primary btn-sm"
            type="button"
            onClick={toggleTaxFormDialog}
          ><i className="fa fa-edit" aria-hidden="true"></i></button>
          <button
            className="btn btn-danger btn-sm ml-2"
            type="button"
            onClick={toggleConfirmationDialog}
          ><i className="fa fa-trash" aria-hidden="true"></i></button>
        </div>
      </li>
      {showTaxFormDialog ? (
        <TaxFormDialog
          show={showTaxFormDialog}
          onHide={toggleTaxFormDialog}
          taxId={tax.id}
        />
      ) : null}
      {showConfirmationDialog ? (
        <ConfirmationDialog
          show={showConfirmationDialog}
          onHide={toggleConfirmationDialog}
          onConfirm={onConfirm}
          title="Delete tax"
          content={`Are you sure you want to delete tax: ${tax.name} ?`}
          loading={isLoading}
          status={status}
        />
      ) : null}
    </React.Fragment>
  )
}

export default TaxesListItem;
