import React, { useEffect } from 'react';
// Redux
import {connect} from 'react-redux';
import {ProductCategoryActions} from 'app/store/product-category/product-category.actions';
// Components
import { ContentLoading } from 'app/components/Loadings';
// 
import ProductCategoriesListItem from './ProductCategoriesListItem';

const ProductCategoriesList = ({
  // Props
  // State
  categories, status,
  // Dispatch
  fetchProductCategories
}) => {

  useEffect(() => {
    fetchProductCategories();
    // eslint-disable-next-line
  }, []);

  return (
    <ContentLoading data={categories} status={status}>
      <div className="row row-8">
        {categories.map((category, index) => (
          <ProductCategoriesListItem
            key={index}
            category={category}
          />
        ))}
      </div>
    </ContentLoading>
  )
}

const mapStateToProps = ({ ProductCategoryState }) => ({
  categories: ProductCategoryState.categories,
  status: ProductCategoryState.status
});

const mapDispatchToProps = dispatch => ({
  fetchProductCategories: () => dispatch(ProductCategoryActions.fetchList())
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductCategoriesList);
