import React, { useState } from 'react';
// Dialogs
import ContentItemFormDialog from 'dialogs/ContentItemForm.dialog';

const ContentItemListItem = ({
  // Props
  index, item, items, onMove, onDelete
}) => {
  const { id, headline, description, enabled, image, category } = item;

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);

  const handleMoveBefore = () => {
    const beforeId = typeof items[index - 1] !== 'undefined' ? items[index - 1]['id'] : -1;
    onMove(id, 'beforeId', beforeId);
  }
  const handleMoveAfter = () => {
    const afterId = typeof items[index + 1] !== 'undefined' ? items[index + 1]['id'] : -1;
    onMove(id, 'afterId', afterId);
  }
  const handleDelete = () => onDelete(item);

  return (
    <>
      <div className="col-12">
        <div className="card card-content-item">
          <div className="card-head">
            <div className="card-move">
              {index !== 0 ? (
                <span
                  className="card-move-before"
                  title="Move up"
                  onClick={handleMoveBefore}
                ><i className="fa fa-chevron-circle-up" aria-hidden="true"></i></span>
              ) : null}
              {index !== items.length - 1 ? (
                <span
                  className="card-move-after"
                  title="Move down"
                  onClick={handleMoveAfter}
                ><i className="fa fa-chevron-circle-down" aria-hidden="true"></i></span>
              ) : null}
            </div>
            {category.name !== 'islandRule' ? (
              image && image.href ? (
                <div className="card-image" style={{backgroundImage: `url(${image.href})`}}></div>
              ) : (
                <div className="card-no-image">
                  <i className="fa fa-image" aria-hidden="true"></i>
                  <h5>No image</h5>
                </div>
              )
            ) : null}
          </div>
          <div className="card-body">
            <h5 className="card-title mb-1">{headline}</h5>
            <div>
              <span className="mb-1 mr-1 badge badge-info">{category.name}</span>
            </div>
            <span className={`mb-1 badge ${enabled ? 'badge-success' : 'badge-danger'}`}>
              {enabled ? 'Available' : 'Not available'}
            </span>
            <p className="card-text">{description}</p>
          </div>
          <div className="card-foot">
            <button
              className="btn btn-primary btn-sm | mr-2"
              type="button"
              onClick={handleShow}
            >Edit</button>
            <button
              className="btn btn-outline-danger btn-sm"
              type="button"
              onClick={handleDelete}
            >Delete</button>
          </div>
        </div>
      </div>
      {show ? (
        <ContentItemFormDialog
          contentItem={item}
          show={show}
          onHide={handleHide}
        />
      ) : null}
    </>
  )
}

export default ContentItemListItem;
