import create from './create-type';

const type = 'schedule';

// Fetch schedule slots
export const FETCH_SCHEDULE_SLOTS = create(type, 'FETCH_SCHEDULE_SLOTS');
export const FETCH_SCHEDULE_SLOTS_SUCCESS = create(type, 'FETCH_SCHEDULE_SLOTS_SUCCESS');

// Create schedule slot
export const CREATE_SCHEDULE_SLOT = create(type, 'CREATE_SCHEDULE_SLOT');
export const CREATE_SCHEDULE_SLOT_SUCCESS = create(type, 'CREATE_SCHEDULE_SLOT_SUCCESS');
export const CREATE_SCHEDULE_SLOT_FAILURE = create(type, 'CREATE_SCHEDULE_SLOT_FAILURE');

// Update schedule slot
export const UPDATE_SCHEDULE_SLOT = create(type, 'UPDATE_SCHEDULE_SLOT');
export const UPDATE_SCHEDULE_SLOT_SUCCESS = create(type, 'UPDATE_SCHEDULE_SLOT_SUCCESS');
export const UPDATE_SCHEDULE_SLOT_FAILURE = create(type, 'UPDATE_SCHEDULE_SLOT_FAILURE');

// Delete schedule slot
export const DELETE_SCHEDULE_SLOT = create(type, 'DELETE_SCHEDULE_SLOT');
export const DELETE_SCHEDULE_SLOT_SUCCESS = create(type, 'DELETE_SCHEDULE_SLOT_SUCCESS');
export const DELETE_SCHEDULE_SLOT_FAILURE = create(type, 'DELETE_SCHEDULE_SLOT_FAILURE');

// Select schedule slot
export const SELECT_SCHEDULE_SLOT = create(type, 'SELECT_SCHEDULE_SLOT');
// Clear schedule slot
export const CLEAR_SELECTED_SCHEDULE_SLOT = create(type, 'CLEAR_SELECTED_SCHEDULE_SLOT')
// Reset form
export const RESET_FORM = create(type, 'RESET_FORM');
