import HttpUtility from 'utilities/http.utility';
import { IInventoryGroupData } from './inventory-group.types';

export default class MapService {
  private static _url:string = '/admin/store/inventoryGroups';
  private static _http:HttpUtility = new HttpUtility();

  // Fetch list
  public static async fetchList():Promise<any> {
    return MapService._http.get(`${MapService._url}`);
  }
  // Fetch by id
  public static async fetchById(id:number):Promise<any> {
    return MapService._http.get(`${MapService._url}/${id}`);
  }
  // Create
  public static async create(data:IInventoryGroupData):Promise<any> {
    return MapService._http.post(MapService._url, data);
  }
  // Update
  public static async update(id:number, data:IInventoryGroupData):Promise<any> {
    return MapService._http.put(`${MapService._url}/${id}`, data);
  }
  // Delete
  public static async delete(id:number):Promise<any> {
    return MapService._http.delete(`${MapService._url}/${id}`);
  }
  // Actions
  // Enable
  public static async enable(id:number):Promise<any> {
    return MapService._http.post(`${MapService._url}/${id}/actions/enable`);
  }
  // Disable
  public static async disable(id:number):Promise<any> {
    return MapService._http.post(`${MapService._url}/${id}/actions/disable`);
  }
  // Reset default waiver
  public static async resetDefaultWaiver(id:number):Promise<any> {
    return MapService._http.post(`${MapService._url}/${id}/actions/resetDefaultWaiver`);
  }
  // Set default waiver
  public static async setDefaultWaiver(waiverId:number):Promise<any> {
    return MapService._http.post(`${MapService._url}/actions/setDefaultWaiver/${waiverId}`);
  }
}
