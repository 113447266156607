import { createSelector } from 'reselect';
import { IStaff } from './staff.types';

export const getItems = (state:any) => state.staff.items;
export const getIsFetching = (state:any) => state.staff.isFetching;
export const getIsLoading = (state:any) => state.staff.isLoading;
export const getStatus = (state:any) => state.staff.status;

export const getItemsForSelect = createSelector(
  getItems, items => {
    if ( !items || items.length === 0 ) return [];
    return items.map((item:IStaff) => ({ value: item.id, label: `${item.firstName} ${item.lastName}` }))
  }
)
