import { createSelector } from 'reselect';
import { AppColors } from 'app/App.constants';

const _summary = state => state.summary;
const _filter = state => state.filter;
const _status = state => state.status;

let colorIndex = 0;

export const getSummary = createSelector(
  _summary, summary => {
    const data = {};
    if ( summary.total ) data['total'] = summary.total;
    if ( summary.orders && summary.orders.length !== 0 ){
      data['orders'] = summary.orders.map(order => {
        const { gross, net, inventoryGroupName, items, tipsTotal } = order;
        colorIndex = 0;
        return {
          name: inventoryGroupName,
          gross,
          net,
          tipsTotal,
          items: items.sort((a, b) => b.totalSoldQuantity - a.totalSoldQuantity).map(item => ({
            visible: true,
            name: item.productName,
            value: item.totalSoldQuantity,
            color: generateColor()
          }))
        }
      })
    }
    if ( summary.reservations && summary.reservations.length !== 0 ){
      data['reservations'] = summary.reservations.map(reservation => {
        const { gross, net, bookingPropertyName, items } = reservation;
        colorIndex = 0;
        return {
          name: bookingPropertyName,
          gross,
          net,
          items: items.sort((a, b) => b.totalReservedDays - a.totalReservedDays).map(item => ({
            visible: true,
            name: item.reservationCategoryName,
            value: item.totalReservedDays,
            color: generateColor()
          }))
        }
      })
    }
    if ( summary.memberships && summary.memberships.length !== 0 ){
      data['memberships'] = summary.memberships.map(membership => {
        const { gross, net, membershipTypeName } = membership;
        colorIndex = 0;
        return {
          name: membershipTypeName,
          gross,
          net,
          items: [{
            visible: true,
            name: membershipTypeName,
            value: 1,
            color: generateColor()
          }]
        }
      })
    }
    return data;
  }
);
export const getFilter = createSelector(
  _filter, filter => filter
);
export const getStatus = createSelector(
  _status, status => status
);

const generateColor = () => {
  const color = AppColors[colorIndex];
  if ( colorIndex === AppColors.length - 1 ){
    colorIndex = 0;
  } else {
    colorIndex = colorIndex + 1;
  }
  return color;
}
