import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
import * as AuthActions from 'app/actions/auth.actions';

import { getToken, checkIsExpires } from '../services/storage.service';

const PrivateRoute = ({ component: Component, path, logout, ...rest }) => {

  useEffect(() => {
    if ( !getToken() || checkIsExpires() ) logout();
    // eslint-disable-next-line
  }, []);

  // if (
  //   !getToken() || checkIsExpires()
  // ) return <Redirect to="/" />
  return (
    <Route path={path} {...rest} render={(props) => <Component {...props} />} />
  );
}

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(AuthActions.logout())
});

export default connect(null, mapDispatchToProps)(PrivateRoute);
