import { createSelector } from 'reselect';

const _items = state => state.ContentCategoryState.items;

export const getItems = createSelector(
  _items, items => items
);

export const getIsFetching = state => state.ContentCategoryState.isFetching;
export const getIsLoading = state => state.ContentCategoryState.isLoading;
export const getStatus = state => state.ContentCategoryState.status;

