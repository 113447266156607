import React, { useState } from 'react';
// Redux
import { IAccount } from 'store/account/account.types';
// Dialogs
import AccountFormDialog from 'dialogs/AccountForm.dialog';

type IProps = {
  item: IAccount,
  onDelete: (user:IAccount) => void;
}

const AccountsListItem:React.FC<IProps> = ({
  // Props
  item, onDelete
}) => {
  const { name, email, callingCode, phone, adminEmail, settings } = item;

  const [show, setShow] = useState<boolean>(false);

  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);

  const handleDelete = () => onDelete(item);

  return (
    <>
      <div className="card | w-100">
        <div className="card-body">
          <h5 className="card-title | mb-2">{name}</h5>
          <small className="card-subtitle | d-block mb-2 text-muted">{email}</small>
          <small className="card-subtitle | d-block mb-2 text-muted">{callingCode}{phone}</small>
          {adminEmail ? (
            <div className="mb-2">
              <small className="text-muted | d-block">Admin E-mail</small>
              <span style={{ fontSize: '12px' }}>{adminEmail}</span>
            </div>
          ) : null}
          <hr />
          {settings && settings.map((setting, index) => (
            <div key={`account-setting-${index}`} className="mb-1">
              <small className="text-muted | d-block">{setting.name}</small>
              <span style={{ fontSize: '12px' }}>{setting.value}</span>
            </div>
          ))}
        </div>
        <div className="card-footer | text-right">
          <button
            className="btn btn-primary btn-sm | mr-2"
            type="button"
            onClick={handleShow}
          >Edit</button>
          <button
            className="btn btn-outline-danger btn-sm"
            type="button"
            onClick={handleDelete}
          >Delete</button>
        </div>
      </div>
      {show ? (
        <AccountFormDialog
          account={item}
          show={show}
          onHide={handleHide}
        />
      ) : null}
    </>
  )
}

export default AccountsListItem;
