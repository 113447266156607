import React from 'react';

type MessageProps = {
  text?: string;
};

export const Message:React.FC<MessageProps> = ({
  // Props
  text
}) => {
  return (
    <div className="text-center p-4"><p className="mb-0">{text || 'No data'}</p></div>
  )
}

type LoaderProps = {};

export const Loader:React.FC<LoaderProps> = () => {
  return (
    <div className="text-center p-4">
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )
}
