import React, { useState } from 'react';
// Hook
import useStateCallback from '../../../hooks/useStateCallback';

const CategoryFormAttributes = props => {
  const {
    value, onChange
  } = props;

  const attributesCallback = state => {
    onChange({
      target: {
        name: 'attributes',
        value: state
      }
    });
  }

  const [attributes, setAttributes] = useStateCallback(value || [], attributesCallback);
  const [attributeName, setAttributeName] = useState('');
  const [attributeValue, setAttributeValue] = useState('');

  const handleAdd = () => {
    setAttributes(prevState => [...prevState, { 'name': attributeName, 'value': attributeValue }]);
    setAttributeName('');
    setAttributeValue('');
  }
  const handleRemove = index => {
    setAttributes(prevState => prevState.filter((_, i) => i !== index));
  }

  return (
    <div className="form-group">
      <label htmlFor="">Attributes</label>
      <div className="form-group row row-8">
        <div className="col-5">
          <input
            className="form-control form-control-sm"
            type="text"
            value={attributeName}
            placeholder="Name"
            onChange={event => setAttributeName(event.target.value)}
          />
        </div>
        <div className="col-5">
          <input
            className="form-control form-control-sm"
            type="text"
            value={attributeValue}
            placeholder="Value"
            onChange={event => setAttributeValue(event.target.value)}
          />
        </div>
        <div className="col-2">
          <button
            className="btn btn-primary btn-block btn-sm"
            type="button"
            onClick={handleAdd}
            disabled={!(attributeName && attributeValue)}
          ><i className="fa fa-plus" aria-hidden="true"></i></button>
        </div>
      </div>
      {attributes.map((attribute, index) => (
        <div key={index} className="form-group row row-8 align-items-center">
          <div className="col-5 col-form-label pl-2 pr-2" style={{fontSize: '0.75rem', fontWeight: '700', lineHeight: '1.2'}}>{attribute.name}</div>
          <div className="col-5 col-form-label pl-2 pr-2" style={{fontSize: '0.75rem'}}>{attribute.value}</div>
          <div className="col-2">
            <button
              className="btn btn-danger btn-block btn-sm"
              type="button"
              onClick={() => handleRemove(index)}
            ><i className="fa fa-minus" aria-hidden="true"></i></button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default CategoryFormAttributes;