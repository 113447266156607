import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
// Redux
import { connect } from 'react-redux';
import { ContentCategoryActions as Actions } from 'app/store/content-category/content-category.actions';
import * as Selectors from 'app/store/content-category/content-category.selectors';
// Bootstrap
import Modal from 'react-bootstrap/Modal';
// Components
import { ButtonLoading } from 'app/components/Buttons';
// Utilities
import { isRequired } from 'utilities/validation';

const ContentCategoryFormDialog = ({
  // Props
  contentCategory, show, onHide,
  // State
  isLoading, status,
  // Dispatch
  createContentCategory, updateContentCategory
}) => {
  const [attribute, setAttribute] = useState({
    item: '',
    items: contentCategory.attributes || []
  });

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      name: contentCategory.name || '',
      description: contentCategory.description || ''
    }
  });

  const onSubmit = data => {
    const { name, description } = data;
    const newData = { name }
    if ( description ) newData['description'] = description;
    if ( attribute.items.length !== 0 ) newData['attributes'] = attribute.items;
    if ( contentCategory.id ){
      updateContentCategory(contentCategory.id, newData);
    } else {
      createContentCategory(newData);
    }
  }

  useEffect(() => {
    if ( status === 'Success' ) onHide();
    // eslint-disable-next-line
  }, [status]);

  const handleChange = e => {
    e.persist();
    setAttribute(prevState => ({
      ...prevState,
      item: e.target.value
    }));
  }
  const handleAdd = () => {
    setAttribute(prevState => ({
      ...prevState,
      item: '',
      items: [...prevState.items, prevState.item]
    }));
  }
  const handleDelete = index => {
    setAttribute(prevState => ({
      ...prevState,
      items: prevState.items.filter((_, i) => i !== index)
    }));
  }

  return (
    <Modal show={show} onHide={onHide}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>{contentCategory.id ? 'Update' : 'Create'} content category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Name */}
          <div className="form-group">
            <label htmlFor="name">Name *</label>
            <input
              ref={register({
                required: isRequired
              })}
              className={`
                form-control form-control-sm
                ${ errors.name ? 'is-invalid' : null }
              `}
              name="name" id="name" type="text"
            />
            {errors.name ? <div className="invalid-feedback">{errors.name.message}</div> : null}
          </div>
          {/* Description */}
          <div className="form-group">
            <label htmlFor="description">Description</label>
            <textarea
              ref={register()}
              className="form-control form-control-sm"
              name="description" id="description"
              rows={5}
            ></textarea>
          </div>
          {/* Attributes */}
          {/* <div className="form-group">
            <label htmlFor="description">Attributes</label>
            <div className="row row-8">
              <div className="col-10">
                <input
                  className="form-control form-control-sm"
                  name="attribute" id="attribute" type="text"
                  value={attribute.item}
                  onChange={handleChange}
                />
              </div>
              <div className="col-2">
                <button
                  className="btn btn-primary btn-sm btn-block"
                  type="button"
                  onClick={handleAdd}
                  disabled={attribute.item === ''}
                >Add</button>
              </div>
            </div>
          </div>
          {attribute.items.map((item, index) => (
            <span
              key={`form-attribute-${index}`}
              className="badge badge-primary | mt-1 mr-1"
            >
              {item}
              <button
                className="btn btn-danger btn-sm | ml-2"
                style={{
                  borderRadius: '50%',
                  padding: 0,
                  width: '24px',
                  height: '24px',
                  lineHeight: '22px'
                }}
                type="button"
                onClick={() => handleDelete(index)}
              ><i className="fa fa-times" aria-hidden="true"></i></button>
            </span>
          ))} */}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary btn-sm"
            type="button"
            onClick={onHide}
          >Cancel</button>
          <ButtonLoading
            loading={isLoading}
            type="submit"
          >Save</ButtonLoading>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

ContentCategoryFormDialog.defaultProps = {
  contentCategory: {}
}

const mapStateToProps = state => ({
  isLoading: Selectors.getIsLoading(state),
  status: Selectors.getStatus(state)
});

const mapDispatchToProps = dispatch => ({
  createContentCategory: data => dispatch(Actions.create(data)),
  updateContentCategory: (id, data) => dispatch(Actions.update(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentCategoryFormDialog);
