import HttpUtility from 'utilities/http.utility';

export default class SettingAPI {
  private static _urlV2:string = '/v1/settings';
  private static _http:HttpUtility = new HttpUtility();

  // Fetch square config
  public static async fetchAccountSettings():Promise<any> {
    return SettingAPI._http.get(`${SettingAPI._urlV2}/accountSettings`);
  }
}
