import React from 'react';

const NotFound = () => {
  return (
    <div style={{textAlign: 'center'}}>
      Not found
    </div>
  )
}

export default NotFound;
