import { BookingActionTypes } from './booking.types';

export const BookingActions = {
  // Fetch bookings
  fetchBookings: () => ({
    type: BookingActionTypes.FetchBookings
  }),
  fetchBookingsSuccess: bookings => ({
    type: BookingActionTypes.FetchBookingsSuccess,
    bookings
  }),
  // Fetch booking by id
  fetchBookingById: bookingId => ({
    type: BookingActionTypes.FetchBookingById,
    bookingId
  }),
  fetchBookingByIdSuccess: booking => ({
    type: BookingActionTypes.FetchBookingByIdSuccess,
    booking
  }),

  // Check availability
  checkAvailability: (booking, start, end, quantity, hourQuantity = 0) => ({
    type: BookingActionTypes.CheckAvailability,
    booking,
    start,
    end,
    quantity,
    hourQuantity
  }),
  checkAvailabilitySuccess: availability => ({
    type: BookingActionTypes.CheckAvailabilitySuccess,
    availability
  }),

  // Fetch min days reservation number
  fetchMinDaysReservationNumber: (bookingId, start) => ({
    type: BookingActionTypes.FetchMinDaysReservationNumber,
    bookingId,
    start
  }),
  fetchMinDaysReservationNumberSuccess: days => ({
    type: BookingActionTypes.FetchMinDaysReservationNumberSuccess,
    days
  }),

  create: data => ({
    type: BookingActionTypes.Create,
    data
  }),
  createSuccess: booking => ({
    type: BookingActionTypes.CreateSuccess,
    booking
  }),

  update: (bookingId, data) => ({
    type: BookingActionTypes.Update,
    bookingId,
    data
  }),
  updateSuccess: booking => ({
    type: BookingActionTypes.UpdateSuccess,
    booking
  }),

  delete: booking => ({
    type: BookingActionTypes.Delete,
    booking
  }),
  deleteSuccess: bookingId => ({
    type: BookingActionTypes.DeleteSuccess,
    bookingId
  }),

  // Image
  // Update
  updateImages: (bookingId, assetsIds) => ({
    type: BookingActionTypes.UpdateImages,
    bookingId,
    assetsIds
  }),
  updateImagesSuccess: booking => ({
    type: BookingActionTypes.UpdateImagesSuccess,
    booking
  }),

  // Actions
  // Enable/Disable
  enable: bookingId => ({
    type: BookingActionTypes.Enable,
    bookingId
  }),
  enableSuccess: bookingId => ({
    type: BookingActionTypes.EnableSuccess,
    bookingId
  }),

  disable: bookingId => ({
    type: BookingActionTypes.Disable,
    bookingId
  }),
  disableSuccess: bookingId => ({
    type: BookingActionTypes.DisableSuccess,
    bookingId
  }),

  setDefaultWaiver: waiverId => ({
    type: BookingActionTypes.SetDefaultWaiver,
    waiverId
  }),
  setDefaultWaiverSuccess: booking => ({
    type: BookingActionTypes.SetDefaultWaiverSuccess,
    booking
  }),

  resetDefaultWaiver: bookingId => ({
    type: BookingActionTypes.ResetDefaultWaiver,
    bookingId
  }),
  resetDefaultWaiverSuccess: booking => ({
    type: BookingActionTypes.ResetDefaultWaiverSuccess,
    booking
  }),

  // Filter
  setFilter: (filterType, filterValue) => ({
    type: BookingActionTypes.Filter,
    filterType,
    filterValue
  }),
  clearFilter: () => ({
    type: BookingActionTypes.ClearFilter
  }),

  // Set status
  setStatusSuccess: () => ({
    type: BookingActionTypes.StatusSuccess
  }),
  setStatusError: () => ({
    type: BookingActionTypes.StatusError
  }),
  setStatusInitial: () => ({
    type: BookingActionTypes.StatusInitial
  }),
  reset: () => ({
    type: BookingActionTypes.Reset
  })
}