import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
// Store
import { connect } from 'react-redux';
import { AppActions } from '../../store/app/app.actions';
import * as AppSelectors from '../../store/app/app.selectors';
// Pages
import InventoryGroupsPage from 'pages/OrderManager/InventoryGroupsPage';
// import InventoryGroupWaiversPage from 'pages/OrderManager/InventoryGroupWaiversPage';
import Products from './Products';
import ProductForm from './ProductForm';
import ProductCategories from './ProductCategories';
import Orders from './Orders';
import ContactMessages from './ContactMessages';
// Bookins
import RemotelockDevicesPage from 'pages/OrderManager/RemotelockDevicesPage';
import BookingsPage from '../Booking/BookingsPage'
import BookingDetailsPage from '../Booking/BookingDetailsPage';
import MembershipPassesPage from 'pages/OrderManager/MembershipPassesPage';
import MembershipTypesPage from 'pages/OrderManager/MembershipTypesPage';
import WaiversPage from 'pages/OrderManager/WaiversPage';
// Layouts
import Drawer from 'app/layouts/Drawer';
// Components
import { DataLoading } from '../../components/Loadings';
import { initializePush } from '../../../firebase';

const OrderManager = ({
  // State
  status, drawerOpen,
  // Dispatch
  fetchManagerData
}) => {
  const { path } = useRouteMatch();
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    initializePush();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchManagerData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( status === 'Fetched' ) setFetching(false);
    // eslint-disable-next-line
  }, [status]);

  if ( fetching ) return <DataLoading />
  return (
    <>
      <Drawer open={drawerOpen} />
      <div className={`root-container ${ drawerOpen ? 'is-open' : '' }`}>
        <Switch>
          {/* Inventory groups */}
          <Route exact path={`${path}/inventory-groups`} component={InventoryGroupsPage} />
          <Route path={`${path}/inventory-groups/:id/waivers`} component={WaiversPage} />
          {/* Products */}
          <Route exact path={`${path}/products`} component={Products} />
          <Route path={`${path}/products/create`} component={ProductForm} />
          <Route path={`${path}/products/:productId/update`} component={ProductForm} />
          {/* Product categories */}
          <Route path={`${path}/product-categories`} component={ProductCategories} />
          {/* Orders */}
          <Route path={`${path}/orders`} component={Orders} />
          {/* Contact messages */}
          <Route path={`${path}/contact-messages`} component={ContactMessages} />
          {/* Removelocked devices */}
          <Route path={`${path}/remotelock-devices`} component={RemotelockDevicesPage} />
          {/* Booking */}
          <Route exact path={`${path}/booking-properties`} component={BookingsPage} />
          <Route path={`${path}/booking-properties/:bookingId`} component={BookingDetailsPage} />

          <Route path={`${path}/membership-passes`} component={MembershipPassesPage} />
          <Route path={`${path}/membership-types`} component={MembershipTypesPage} />
          <Route path={`${path}/membership-types/:id/waivers`} component={WaiversPage} />

          <Redirect from={`${path}`} to={`${path}/campers`} />
        </Switch>
      </div>
    </>
  )
}

const mapStateToProps = ({ AppState, layout }) => ({
  drawerOpen: layout.drawerOpen,
  status: AppSelectors.getStatus(AppState)
});

const mapDispatchToProps = dispatch => ({
  fetchManagerData: () => dispatch(AppActions.fetchManagerData())
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderManager);
