// import { createSelector } from 'reselect';

export const getTemplates = (state:any) => state.mailTemplate.templates.items;
export const getTemplatesFetching = (state:any) => state.mailTemplate.templates.isFetching;
export const getTemplatesLoading = (state:any) => state.mailTemplate.templates.isLoading;

export const getTemplate = (state:any) => state.mailTemplate.template.item;
export const getTemplateFetching = (state:any) => state.mailTemplate.template.isFetching;
export const getTemplateLoading = (state:any) => state.mailTemplate.template.isLoading;

export const getStatus = (state:any) => state.mailTemplate.status;
