import './Notifications.scss';

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
// Store
import { connect } from 'react-redux';
import { removeNotification } from '../../actions/layout.actions';

class Notifications extends Component {
  handleRemove = notification => () => {
    this.props.removeNotification(notification)
  }

  render() {
    const { notifications } = this.props;
    if ( notifications.length ) return ReactDOM.createPortal(
      <div className="notifications">
        {notifications.map((item, index) => (
          <div
            key={index}
            className={`notifications-item is-${item.type}`}
          >
            {(item.type === 'danger') && <i className="fa fa-exclamation-circle | notifications-item-icon" aria-hidden="true"></i>}
            {(item.type === 'warning') && <i className="fa fa-exclamation-triangle | notifications-item-icon" aria-hidden="true"></i>}
            {(item.type === 'success') && <i className="fa fa-check-circle | notifications-item-icon" aria-hidden="true"></i>}
            {(item.type === 'info') && <i className="fa fa-info-circle | notifications-item-icon" aria-hidden="true"></i>}
            <div className="pl-3">
              <h6 className="notifications-item-title">{
                item.title
                ? item.title
                : item.type === 'danger' ? 'Error' : item.type
              }</h6>
              <p className="notifications-item-message">{item.message}</p>
            </div>
            <span className="spacer"></span>
            <button
              className="btn btn-link btn-sm"
              onClick={this.handleRemove(item)}
            ><i className="fa fa-times" aria-hidden="true"></i></button>
          </div>
        ))}
      </div>,
      document.getElementById('root-notification')
    );
    return null;
  }
}

const mapStateToProps = state => ({
  notifications: state.layout.notifications
});

const mapDispatchToProps = dispatch => ({
  removeNotification: notification => dispatch(removeNotification(notification))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications);
