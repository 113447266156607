import { Reducer } from 'redux';
import Statuses from 'types/statuses';
import { IOrderState, OrderTypes, OrderActionTypes, } from './order.types';

export default class OrderReducer {
  private static readonly _initialState:IOrderState = {
    activity: {
      item: null,
      isFetching: false,
      isLoading: false
    },
    status: Statuses.Initial
  };

  public static reducer:Reducer<IOrderState, OrderActionTypes> = (
    state = OrderReducer._initialState,
    action
  ) => {
    switch (action.type){
      // Check-in
      case OrderTypes.CheckInOrder:
        return { ...state, activity: {
          ...state.activity,
          isLoading: true
        }};
      case OrderTypes.CheckInOrderSuccess:
        return { ...state, activity: {
          ...state.activity,
          item: action.payload.item,
          isLoading: false
        }};
      // Default
      // Set status
      case OrderTypes.SetStatus:
        const hasError:boolean = action.payload.status === Statuses.Error;
        return {
          ...state,
          activity: {
            ...state.activity,
            isFetching: hasError ? false : state.activity.isFetching,
            isLoading: hasError ? false : state.activity.isLoading,
          },
          status: action.payload.status
        };
      default:
        return state;
    }
  }

}