import Types from '../types/asset.types';

const initialState = {
  asset: {},
  isLoading: false,
  isSuccess: false
};

export default function(state = initialState, { type, payload }){
  switch (type){
    // Request
    case Types.CREATE_ASSET:
      return { ...state, asset: {}, isLoading: true, isSuccess: false };
    // Success
    case Types.CREATE_ASSET_SUCCESS:
      return { ...state, asset: payload.asset, isLoading: false, isSuccess: true };
    // Failure
    case Types.CREATE_ASSET_FAILURE:
    case Types.SET_INITIAL_STATE:
      return { ...state, ...initialState };
    // Default
    default:
      return state;
  }
}

