import { call, put, takeLatest } from 'redux-saga/effects';
import * as LayoutActions from 'app/actions/layout.actions';
import StaffService from './staff.service';
import StaffActions from './staff.actions';
import {
  StaffMessages,
  FETCH_LIST, CREATE, UPDATE, ENABLE, DISABLE, DELETE
} from './staff.types';
import Statuses from 'types/statuses';

function* _fetchList(){
  try {
    const { parseBody } = yield call(StaffService.fetchList);
    yield put(StaffActions.fetchListSuccess(parseBody));
  } catch(error:any){
    console.log(error);
  }
}

function* _create(action:any){
  try {
    const { parseBody } = yield call(StaffService.create, action.payload.data);
    yield put(StaffActions.createSuccess(parseBody));
    yield put(StaffActions.setStatus(Statuses.Success));
    yield put(StaffActions.setStatus(Statuses.Initial));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: StaffMessages.Create
    }));
  } catch(error:any){
    yield put(StaffActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _update(action:any){
  try {
    const { parseBody } = yield call(StaffService.update, action.payload.id, action.payload.data);
    yield put(StaffActions.updateSuccess(parseBody));
    yield put(StaffActions.setStatus(Statuses.Success));
    yield put(StaffActions.setStatus(Statuses.Initial));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: StaffMessages.Update
    }));
  } catch(error:any){
    yield put(StaffActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _enable(action:any){
  try {
    yield call(StaffService.enable, action.payload.id);
    yield put(StaffActions.enableSuccess(action.payload.id));
  } catch(error:any){
    yield put(StaffActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _disable(action:any){
  try {
    yield call(StaffService.disable, action.payload.id);
    yield put(StaffActions.disableSuccess(action.payload.id));
  } catch(error:any){
    yield put(StaffActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _delete(action:any){
  try {
    const { staff } = action.payload;
    if ( window.confirm(`Are you sure you want to delete user: ${staff.firstName} ${staff.lastName}?`) ){
      yield call(StaffService.delete, staff.id);
      yield put(StaffActions.deleteSuccess(staff.id));
      yield put(StaffActions.setStatus(Statuses.Success));
      yield put(StaffActions.setStatus(Statuses.Initial));
      yield put(LayoutActions.addNotification({
        type: 'success',
        message: StaffMessages.Delete
      }));
    }
  } catch(error:any){
    yield put(StaffActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

export default function* (){
  yield takeLatest(FETCH_LIST, _fetchList);
  yield takeLatest(CREATE, _create);
  yield takeLatest(UPDATE, _update);
  yield takeLatest(ENABLE, _enable);
  yield takeLatest(DISABLE, _disable);
  yield takeLatest(DELETE, _delete);
}
