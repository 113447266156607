import fetcher from 'app/utilities/fetcher';

class UserService {
  constructor(){
    this._url = '/admin/users';
  }

  // Fetch users
  fetchUsers = (params = {}) => {
    const searchParams = new URLSearchParams();
    Object.keys(params).forEach(key => {
      if ( params[key] ) searchParams.append(key, params[key]);
    });
    return fetcher(`${this._url}?${searchParams}`);
  }

  // Fetch me
  fetchMe = () => {
    return fetcher(`${this._url}/me`)
      // .then(response => {
      //   if ( response.data.role === 'camper' ){
      //     errorHandler('User with role `camper` not allowed');
      //     throw new Error('Error')
      //   }
      //   return response;
      // })
      // .catch(errorHandler);
  }

  // Update me
  updateMe = user => {
    return fetcher(`${this._url}/${user.id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(user)
    });
      // .then(response => responseHandler(response, this._UPDATE_ME))
      // .catch(errorHandler);
  }

  // Fetch campers list
  fetchAllCampers = (params = {}) => {
    const searchParams = new URLSearchParams();
    Object.keys(params).forEach(key => {
      if ( params[key] ) searchParams.append(key, params[key]);
    });
    return fetcher(`${this._url}/campers?${searchParams}`, {
      method: 'GET'
    });
  }

  // Fetch staff list
  fetchAllStaff = () => {
    return fetcher(`${this._url}/staff`, {
      method: 'GET'
    });
  }

  // Get user by id
  fetchById = userId => {
    return fetcher(`${this._url}/${userId}`, {
      method: 'GET'
    });
  }

  // Create User
  create = user => {
    return fetcher(this._url, {
      method: 'POST',
      body: JSON.stringify(user),
      headers: { 'Content-Type': 'application/json' }
    })
      // .then(response => responseHandler(response, this._CREATE))
      // .catch(errorHandler);
  }

  // Update user
  update = user => {
    return fetcher(`${this._url}/${user.id}`, {
      method: 'PUT',
      body: JSON.stringify(user),
      headers: { 'Content-Type': 'application/json' }
    })
      // .then(response => responseHandler(response, this._UPDATE))
      // .catch(errorHandler);
  }

  // Update camper user
  updateCamper = user => {
    return fetcher(`${this._url}/camper/${user.id}`, {
      method: 'PUT',
      body: JSON.stringify(user),
      headers: { 'Content-Type': 'application/json' }
    });
      // .then(response => responseHandler(response.json(), this._UPDATE))
      // .catch(errorHandler);
  }

  // Create camper
  createCamper = user => {
    return fetcher(`${this._url}/camper`, {
      method: 'POST',
      body: JSON.stringify(user),
      headers: { 'Content-Type': 'application/json' }
    });
      // .then(response => responseHandler(response.json(), this._UPDATE))
      // .catch(errorHandler);
  }

  // Delete
  delete = userId => {
    return fetcher(`${this._url}/${userId}`, {
      method: 'DELETE'
    }, () => null);
      // .then(() => responseHandler({}, this._DELETE))
      // .catch(errorHandler);
  }

  disable = userId => {
    return fetcher(`${this._url}/${userId}/actions/disable`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }, () => null);
      // .then(() => responseHandler({}, this._DISABLED))
      // .catch(errorHandler);
  }

  enable = userId => {
    return fetcher(`${this._url}/${userId}/actions/enable`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }, () => null)
      // .then(() => responseHandler({}, this._ENABLED))
      // .catch(errorHandler);
  }
}

const service = new UserService();
export default service;
