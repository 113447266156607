import React from 'react';
import ReactDOM from 'react-dom';
// Redux
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { AppActions } from '../store/app/app.actions';
import * as AppSelectors from '../store/app/app.selectors';

const PushNotifications = ({
  // Props
  // State
  pushNotifications,
  // Dispatch
  removePushNotification, goTo
}) => {
  const handleClick = (event, pushNotification) => {
    event.persist();
    if ( pushNotification ){
      const { userRole, notificationType, notificationSubjectType, notificationSubjectId, notificationTargetId, messageUserId, reservationStatus, reservationNumber } = pushNotification.data;
      let url = `/${userRole === 'admin' ? 'admin' : 'manager'}`;
      if ( notificationType === 'reservation' ){
        if ( notificationSubjectType === 'bookingProperty' ){
          url += `/booking-properties/${notificationSubjectId}/reservations?statuses=${reservationStatus}&reservationNumber=${reservationNumber}`;
        }
      }
      if ( notificationType === 'message' ) url += `/contact-messages?messageId=${notificationTargetId}&userId=${messageUserId}`;
      if ( notificationType === 'order' ) url += '/orders';
      // goTo(url);
      window.location.replace(url);
    }
  }

  if ( pushNotifications.length !== 0 ) return ReactDOM.createPortal(
    <div className="push-notifications">
      {pushNotifications.map((pushNotification, index) => (
        <div key={index} className="push-notifications-item">
          <div
            className="push-notifications-item--body action"
            onClick={event => handleClick(event, pushNotification)}
          >
            {pushNotification.title && <h6 className="title">{pushNotification.title}</h6>}
            {pushNotification.body && <p className="body">{pushNotification.body}</p>}
          </div>
          <div
            className="push-notifications-item--action"
            onClick={() => removePushNotification(pushNotification.id)}
          ><i className="fa fa-times" aria-hidden="true"></i></div>
        </div>
      ))}
    </div>,
    document.getElementById('root-push-notification')
  )
  return null;
}

const mapStateToProps = ({ auth, AppState }) => ({
  pushNotifications: AppSelectors.getPushNotifications(AppState)
});

const mapDispatchToProps = dispatch => ({
  removePushNotification: pushNotificationId => dispatch(AppActions.removePushNotification(pushNotificationId)),
  goTo: path => dispatch(push(path))
});

export default connect(mapStateToProps, mapDispatchToProps)(PushNotifications);
