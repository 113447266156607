import React from 'react';
// Types
import Statuses from 'types/statuses';
// Bootstrap
import Modal from 'react-bootstrap/Modal';
// Components
import { ButtonLoading } from 'app/components/Buttons';

type Props = {
  show: boolean;
  onHide: () => void;
  onConfirm: () => void;
  loading?: boolean;
  status?: Statuses;
  title?: string;
  content: string;
  confirmLabel?: string;
};

const ConfirmationDialog:React.FC<Props> = ({
  // Props
  show, onHide, onConfirm, loading, status, title, content, confirmLabel
}) => {
  React.useEffect(() => {
    if ( status === Statuses.Success ) onHide();
    // eslint-disable-next-line
  }, [status])

  return (
    <Modal
      backdropClassName="modal-confirmation-backdrop"
      className="modal-confirmation"
      show={show}
      onHide={onHide}
    >
      {title ? (
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      ) : null}
      <Modal.Body>
        <p className="mb-0">{content}</p>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-secondary btn-sm"
          type="button"
          onClick={onHide}
        >Cancel</button>
        <ButtonLoading
          loading={loading}
          type="button"
          onClick={onConfirm}
        >{confirmLabel}</ButtonLoading>
      </Modal.Footer>
    </Modal>
  )
}

ConfirmationDialog.defaultProps = {
  show: false,
  onHide: () => null,
  onConfirm: () => null,
  loading: false,
  status: Statuses.Initial,
  title: '',
  content: '',
  confirmLabel: 'Confirm'
}

export default ConfirmationDialog;
