import { Reducer } from 'redux';
import Statuses from 'types/statuses';
import { ISeason, ISeasonState, SeasonTypes, SeasonActionTypes } from './season.types';

export default class SeasonReducer {
  private static readonly _initialState:ISeasonState = {
    items: null,
    isFetching: false,
    isLoading: false,
    status: Statuses.Initial
  };

  public static reducer:Reducer<ISeasonState, SeasonActionTypes> = (
    state = SeasonReducer._initialState,
    action
  ) => {
    switch (action.type){
      // Fetch seasons list
      case SeasonTypes.FetchSeasonsList:
        return { ...state, items: null, isFetching: true };
      case SeasonTypes.FetchSeasonsListSuccess:
        return { ...state, items: action.payload.items, isFetching: false };
      // Create season
      case SeasonTypes.CreateSeason:
        return { ...state, isLoading: true };
      case SeasonTypes.CreateSeasonSuccess:
        return {
          ...state,
          items: state.items
            ? [...state.items, action.payload.item]
            : state.items
          ,
          isLoading: false
        };
      // Update season
      case SeasonTypes.UpdateSeason:
        return { ...state, isLoading: true };
      case SeasonTypes.UpdateSeasonSuccess:
        return {
          ...state,
          items: state.items
            ? state.items.map((item:ISeason) => item.id === action.payload.item.id ? action.payload.item : item)
            : state.items
          ,
          isLoading: false
        };
      // Delete season
      case SeasonTypes.DeleteSeason:
        return { ...state, isLoading: true }
      case SeasonTypes.DeleteSeasonSuccess:
        return {
          ...state,
          items: state.items
            ? state.items.filter((item:ISeason) => item.id !== action.payload.seasonId)
            : state.items
          ,
          isLoading: false
        }
      // Update slots
      case SeasonTypes.UpdateSlots:
        return { ...state, isLoading: true };
      case SeasonTypes.UpdateSlotsSuccess:
        const { item:season, items:seasons } = action.payload;
        const hasSeason = state.items ? state.items.find((item:ISeason) => item.id === season.id) : null;
        const seasonsItems:Array<ISeason> | null =
          state.items
            ? hasSeason
                ? state.items.map((item:ISeason) => item.id === season.id ? season : item)
                : [...state.items, season]
            : state.items
        ;
        return {
          ...state,
          items: seasonsItems
            ? seasonsItems.map((item:ISeason) => {
                const findedItem:ISeason | undefined = seasons.find((i:ISeason) => i.id === item.id);
                return findedItem && item.id === findedItem.id ? findedItem : item
              })
            : seasonsItems
          ,
          isLoading: false
        };
      // Default
      case SeasonTypes.SetStatus:
        const hasError:boolean = action.payload.status === Statuses.Error;
        return {
          ...state,
          isLoading: hasError ? false : state.isLoading,
          status: action.payload.status
        };
      case SeasonTypes.SetInitialField:
        return {
          ...state,
          [action.payload.field]: SeasonReducer._initialState[action.payload.field]
        }
      default:
        return state;
    }
  }

}