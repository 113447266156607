import { createSelector } from 'reselect';

const _categories = state => state.categories;
const _availableCategories = state => state.availableCategories;
const _status = state => state.status;

export const getCategories = createSelector(
  _categories, state => state.filter,
  (categories, filter) => {
    const { search } = filter;
    const nextSearch = search.toLowerCase();
    return categories.filter(c => {
      return (
        c.name.toLowerCase().indexOf(nextSearch) > -1 ||
        c.description.toLowerCase().indexOf(nextSearch) > -1
      )
    })
  }
);
export const getAvailableCategories = createSelector(
  _availableCategories, availableCategories => availableCategories
);
export const getCategoryById = createSelector(
  _categories, (_, props) => props,
  (categories, categoryId) => {
    if ( !categoryId ) return {};
    return categories.find(c => c.id === categoryId)
  }
)
export const getStatus = createSelector(
  _status, status => status
);

// Other
export const checkItemsQuantity = createSelector(
  getAvailableCategories, availableCategories => {
    return availableCategories.some(c => c.quantity !== 0)
  }
)
export const getTotalPrice = createSelector(
  getAvailableCategories,
  (availableCategories) => {
    let result = 0;
    for ( let category of availableCategories ){
      const { dailyCategoryPricing, quantity } = category;
      let totalPrice = 0;
      if ( quantity && category.dailyCategoryPricing ){
        for ( let price of dailyCategoryPricing ){
          if ( price.price ){
            totalPrice += price.price;
          }
        }
        totalPrice = totalPrice * quantity;
      }
      result += totalPrice;
    }
    return result;
  }
)
