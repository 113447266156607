import { AvailabilityActionTypes } from './availability.types';

const initialState = {
  restrictions: [],
  status: 'Initial'
}

export default (state = initialState, action) => {
  switch (action.type) {
    case AvailabilityActionTypes.FetchList:
      return { ...state, restrictions: initialState.restrictions, status: 'Fetching' }
    case AvailabilityActionTypes.FetchListSuccess:
      return { ...state, restrictions: action.restrictions, status: 'Fetched' };
    // Create
    case AvailabilityActionTypes.Create:
      return { ...state, status: 'Loading' };
    case AvailabilityActionTypes.CreateSuccess:
      return { ...state, restrictions: [...state.restrictions, action.restriction], status: 'Loaded' };
    // Update
    case AvailabilityActionTypes.Update:
      return { ...state, status: 'Loading' };
    case AvailabilityActionTypes.UpdateSuccess:
      return {
        ...state,
        restrictions: state.restrictions.map(b =>
          b.id === action.restriction.id
          ? { ...b, ...action.restriction }
          : b
        ),
        status: 'Loaded'
      };
    // Delete
    case AvailabilityActionTypes.Delete:
      return state;
    case AvailabilityActionTypes.DeleteSuccess:
      return {
        ...state,
        restrictions: state.restrictions.filter(b => b.id !== action.restrictionId)
      };
    // Filter
    case AvailabilityActionTypes.Filter:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.filterType]: action.filterValue
        }
      };
    case AvailabilityActionTypes.ClearFilter:
      return { ...state, filter: initialState.filter };
    // Set status
    case AvailabilityActionTypes.StatusSuccess:
      return { ...state, status: 'Success' };
    case AvailabilityActionTypes.StatusError:
      return { ...state, status: 'Error' };
    case AvailabilityActionTypes.StatusInitial:
      return { ...state, status: 'Initial' };
    default:
      return state;
  }
}