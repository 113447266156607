import React from 'react';
// 
import RemotelockDevicesList from './RemotelockDevicesList';

const RemotelockDevicesPage = () => {
  return (
    <div className="container-fluid pt-3 pb-3">
      <RemotelockDevicesList />
    </div>
  )
}

export default RemotelockDevicesPage;
