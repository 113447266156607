import fetcher from '../../utilities/fetcher';

const adminURL = '/admin/bookingProperties';
const v1Url = '/v1/bookingProperties';
const headers = {
  'Content-Type': 'application/json'
};

export const BookingService = {
  // Fetch bookings
  fetchBookings: () => {
    return fetcher(adminURL);
  },
  // Fetch booking by id
  fetchBookingById: bookingId => {
    return fetcher(`${adminURL}/${bookingId}`);
  },
  // Check availability
  checkAvailability: (bookingId, reservationStart, reservationEnd) => {
    const params = new URLSearchParams();
    params.append('reservationStart', reservationStart);
    params.append('reservationEnd', reservationEnd);
    return fetcher(`${v1Url}/${bookingId}/checkAvailability?${params}`);
  },
  // Fetch min days reservation number
  fetchMinDaysReservationNumber: (bookingId, reservationStart) => {
    const params = new URLSearchParams();
    params.append('reservationStart', reservationStart);
    return fetcher(`${v1Url}/${bookingId}/minDaysReservationNumber?${params}`);
  },
  // Create
  create: data => {
    return fetcher(`${adminURL}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(data)
    });
  },
  // Update
  update: (bookingId, data) => {
    return fetcher(`${adminURL}/${bookingId}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(data)
    });
  },
  // Delete
  delete: bookingId => {
    return fetcher(`${adminURL}/${bookingId}`, {
      method: 'DELETE'
    }, () => null);
  },
  // Image
  // Update
  updateImages: (bookingId, assetsIds) => {
    return fetcher(`${adminURL}/${bookingId}/images/update`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(assetsIds)
    });
  },

  // Actions
  // Enable/Disable
  enable: bookingId => {
    return fetcher(`${adminURL}/${bookingId}/actions/enable`, {
      method: 'POST',
      headers
    }, () => null);
  },
  disable: bookingId => {
    return fetcher(`${adminURL}/${bookingId}/actions/disable`, {
      method: 'POST',
      headers
    }, () => null);
  },
  // Set default waiver
  setDefaultWaiver: waiverId => {
    return fetcher(`${adminURL}/actions/setDefaultWaiver/${waiverId}`, {
      method: 'POST',
      headers
    });
  },
  resetDefaultWaiver: bookingId => {
    return fetcher(`${adminURL}/${bookingId}/actions/resetDefaultWaiver`, {
      method: 'POST',
      headers
    });
  },
}
