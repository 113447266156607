import React from 'react';
// Types
import Statuses from 'types/statuses';
import { ISeason } from 'store/season/season.types';
// Components
import { Loader, Message } from 'components/Utility';
// 
import SeasonsListItem from './SeasonsListItem';

type Props = {
  items: Array<ISeason> | null;
  fetching: boolean;
  loading: boolean;
  status: Statuses;
  onDelete: (seasonId:number) => void;
};

const SeasonsList:React.FC<Props> = ({
  // Props
  items, fetching, loading, status, onDelete
}) => {
  if ( !items || fetching ) return <Loader />;
  if ( items && items.length === 0 ) return <Message text="No season(s)" />;
  return (
    <div className="d-flex flex-wrap">
      {items.map((item:ISeason) => (
        <SeasonsListItem
          key={`season-item-${item.id}`}
          item={item}
          loading={loading}
          status={status}
          onDelete={onDelete}
        />
      ))}
    </div>
  )
}

export default SeasonsList;
