import React from 'react';
// 
import FinancialReportPieChart from './FinancialReportPieChart';

const FinancialReportTab = ({
  // Props
  type, items
  // State
  // Dispatch
}) => {
  return (
    <div className="row row-8">
      {items.map((item, index) => (
        <div key={index} className="col-12 col-md-6 col-lg-4 | d-flex flex-direction-column">
          
          <div className="card | flex-grow-1">
            <div className="card-header">
              <h6 className="mb-0">{item.name}</h6>
              <small className="mr-2"><b>Gross</b> ${toNumberWithCommas(item.gross)}</small>
              <small className="mr-2"><b>Net</b> ${toNumberWithCommas(item.net)}</small>
              <small className="mr-2"><b>Tax</b> ${toNumberWithCommas(item.gross - item.net)}</small>
              {item.tipsTotal && <small><b>Tips</b> ${item.tipsTotal}</small>}
            </div>

            <div className="card-body">
              <FinancialReportPieChart
                type={type}
                item={item}
              />
            </div>
          </div>

        </div>
      ))}
    </div>
  )
}

const toNumberWithCommas = num => {
  return Number(num).toLocaleString()
}

export default FinancialReportTab;
