import React from 'react';
// Redux
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import InventoryGroupActions from 'store/inventory-group/inventory-group.actions';
import * as InventoryGroupSelectors from 'store/inventory-group/inventory-group.selectors';
import InventoryGroupTypes from 'types/inventory-group-types';
// Components
import { Select } from 'components/Controls';

type IProps = {
  // State
  filter: any;
  // Dispatch
  setInventoryGroupFilter: (field:string, value:string) => void
}

const InventoryGroupFilter:React.FC<IProps> = ({
  // State
  filter,
  // Dispatch
  setInventoryGroupFilter
}) => {
  const handleChange = (e:React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setInventoryGroupFilter(name, value);
  }
  return (
    <div className="row row-8 row-cols-1 row-cols-sm-2 row-cols-md-3 | mb-2">
      <div className="col">
        <Select
          controlRef={null}
          label="Type" id="type" name="type" value={filter.type}
          options={[
            { value: '', label: 'Choose type' },
            { value: InventoryGroupTypes.Physical, label: 'Physical' },
            { value: InventoryGroupTypes.Online, label: 'Online' },
          ]}
          onChange={handleChange}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state:any) => ({
  filter: InventoryGroupSelectors.getFilter(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setInventoryGroupFilter: (field:string, value:string) => dispatch(InventoryGroupActions.setFilter(field, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryGroupFilter);
