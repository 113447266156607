import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// Actions
import { connect } from 'react-redux';
import { login } from '../../../actions/auth.actions';
// Components
import { InputControl } from '../../../components/FormControls';
import { ButtonLoading } from '../../../components/Buttons';

// 
import { formControlsValue, formControlsOptions } from './FormControls';

class LoginForm extends Component {
  state = {
    isFormSubmitted: false,
    formControlsValue,
    formControlsOptions
  }

  handleChange = evt => {
    const { target: { name, value } } = evt;
    this.setState(prevState => ({
      formControlsValue: {
        ...prevState.formControlsValue,
        [name]: value
      }
    }))
  }

  handleSubmit = evt => {
    evt.preventDefault();

    this.setState({
      isFormSubmitted: true
    });

    if ( !this.refForm.checkValidity() ) return;

    const { formControlsValue: { email, password } }  = this.state;

    this.props.login({ email, password });
  }

  render() {
    const { isFormSubmitted, formControlsValue, formControlsOptions } = this.state;
    const { isFormLoading } = this.props;
    return (
      <form
        noValidate
        name="form"
        ref={ref => this.refForm = ref}
        onSubmit={this.handleSubmit}
      >
        {/* E-mail */}
        <InputControl
          value={formControlsValue.email}
          options={formControlsOptions.email}
          submitted={isFormSubmitted}
          onChange={this.handleChange}
        />
        {/* Password */}
        <InputControl
          value={formControlsValue.password}
          options={formControlsOptions.password}
          submitted={isFormSubmitted}
          onChange={this.handleChange}
        />
        <div className="pt-2">
          <ButtonLoading
            classes="btn btn-primary btn-sm btn-block"
            type="submit"
            loading={isFormLoading}
          >Login</ButtonLoading>
          <Link className="btn btn-link btn-sm btn-block" to="/forgot-password">Forgot password?</Link>
        </div>
      </form>
    )
  }
}

const mapStateToProps = state => ({
  isFormLoading: state.auth.isFormLoading
});

const mapDispatchToProps = dispatch => ({
  login: credential => dispatch(login(credential))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginForm);
