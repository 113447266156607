interface IConfig {
  [key:string]: {
    production: boolean;
    apiUrl: string;
  }
}

const config:IConfig = {
  'development': {
    production: false,
    apiUrl: 'http://localhost:8080'
  },
  'qa': {
    production: false,
    apiUrl: 'https://qa.api.aicamping.com'
  },
  'production': {
    production: true,
    apiUrl: 'https://api.aicamping.com'
  }
}

const type = process.env.REACT_APP_STAGE || 'development';

export default config[type];
