// Types
export const FETCH_LIST = '[Content Category] Fetch list';
export const FETCH_LIST_SUCCESS = '[Content Category] Fetch list success';

export const CREATE = '[Content Category] Create';
export const CREATE_SUCCESS = '[Content Category] Create success';

export const UPDATE = '[Content Category] Update';
export const UPDATE_SUCCESS = '[Content Category] Update success';

export const DELETE = '[Content Category] Delete';
export const DELETE_SUCCESS = '[Content Category] Delete success';

export const ENABLE = '[Content Category] Enable';
export const ENABLE_SUCCESS = '[Content Category] Enable success';

export const DISABLE = '[Content Category] Disable';
export const DISABLE_SUCCESS = '[Content Category] Disable success';

export const SET_STATUS = '[Content Category] Set status'

// Actions
export const ContentCategoryActions = {
  // Fetch list
  fetchList: () => ({
    type: FETCH_LIST
  }),
  fetchListSuccess: items => ({
    type: FETCH_LIST_SUCCESS,
    payload: { items }
  }),
  // Create
  create: data => ({
    type: CREATE,
    payload: { data }
  }),
  createSuccess: item => ({
    type: CREATE_SUCCESS,
    payload: { item }
  }),
  // Update
  update: (id, data) => ({
    type: UPDATE,
    payload: { id, data }
  }),
  updateSuccess: item => ({
    type: UPDATE_SUCCESS,
    payload: { item }
  }),
  // Delete
  delete: item => ({
    type: DELETE,
    payload: { item }
  }),
  deleteSuccess: id => ({
    type: DELETE_SUCCESS,
    payload: { id }
  }),
  // Enable/Disable
  // Enable
  enable: id => ({
    type: ENABLE,
    payload: { id }
  }),
  enableSuccess: id => ({
    type: ENABLE_SUCCESS,
    payload: { id }
  }),
  // Disable
  disable: id => ({
    type: DISABLE,
    payload: { id }
  }),
  disableSuccess: id => ({
    type: DISABLE_SUCCESS,
    payload: { id }
  }),
  // Default
  // Set status
  setStatus: status => ({
    type: SET_STATUS,
    payload: { status }
  })
}
