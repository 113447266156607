import fetcher from 'app/utilities/fetcher';

const apiURL = '/admin/contentCategories';
const headers = {
  'Content-Type': 'application/json'
};

export const ContentCategoryService = {
  // Fetch list
  fetchList: () => {
    return fetcher(apiURL, { method: 'GET' });
  },
  // Create
  create: data => {
    return fetcher(apiURL, {
      method: 'POST',
      headers,
      body: JSON.stringify(data)
    });
  },
  // Update
  update: (id, data) => {
    return fetcher(`${apiURL}/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(data)
    });
  },
  // Delete
  delete: id => {
    return fetcher(`${apiURL}/${id}`, {
      method: 'DELETE',
    }, () => null);
  },
  // Enable/Disable
  // Enable
  enable: id => {
    return fetcher(`${apiURL}/${id}/actions/enable`, {
      method: 'POST'
    }, () => null);
  },
  disable: id => {
    return fetcher(`${apiURL}/${id}/actions/disable`, {
      method: 'POST'
    }, () => null);
  },
}
