import React, {useState} from 'react';
// Redux
import {connect} from 'react-redux';
import {ProductCategoryActions} from 'app/store/product-category/product-category.actions';
// Layouts
import ProductCategoryFormModal from 'app/layouts/Modals/ProductCategoryFormModal';

const ProductCategoriesListItem = ({
  // Props
  category,
  // Dispatch
  deleteProductCategorie
}) => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);

  const handleDelete = () => deleteProductCategorie(category);

  return (
    <>
      <div className="col-12 col-sm-6 col-md-4 col-lg-3 | d-flex">
        <div className="card card-inventory-group">
          <div className="card-body">
            <h5 className="card-title mb-1">{category.name}</h5>
          </div>
          <div className="card-foot">
            <button
              className="btn btn-primary btn-sm | mr-2"
              type="button"
              onClick={handleShow}
            >Edit</button>
            <button
              className="btn btn-outline-danger btn-sm"
              type="button"
              onClick={handleDelete}
            >Delete</button>
          </div>
        </div>
      </div>
      {show ? (
        <ProductCategoryFormModal
          show={show}
          onHide={handleHide}
          productCategory={category}
        />
      ) : null}
    </>
  )
}

const mapDispatchToProps = dispatch => ({
  deleteProductCategorie: category => dispatch(ProductCategoryActions.delete(category))
});

export default connect(null, mapDispatchToProps)(ProductCategoriesListItem);
