import { call, put, takeLatest } from 'redux-saga/effects';
// Store
import * as LayoutActions from 'app/actions/layout.actions';

import { ReservationActionTypes, ReservationMessageTypes } from './reservation.types';
// import { BlackoutActions } from '../blackout/blackout.actions';
import { ReservationActions } from './reservation.actions';
// import { BlackoutService } from '../blackout/blackout.service';
import { ReservationService } from './reservation.service';
import OrderService from 'app/services/order.service';

function* _fetchReservations(action){
  try {
    const { bookingId, params } = action;
    const response = yield call(ReservationService.fetchReservations, bookingId, params);
    yield put(ReservationActions.fetchReservationsSuccess(response));
  } catch(error){
    console.log(error);
    yield put(ReservationActions.setStatusError());
  }
}

function* _fetchReservationDetails(action){
  try {
    const { bookingId, params } = action;
    // const [ blackouts, reservations ] = yield all([
    //   call(BlackoutService.fetchBlackouts, bookingId, startBlackout, endBlackout, status),
    //   call(ReservationService.fetchReservations, bookingId, start, end, status)
    // ]);
    const response = yield call(ReservationService.fetchReservations, bookingId, params)
    // yield put(BlackoutActions.fetchBlackoutsSuccess(blackouts));
    // yield put(ReservationActions.fetchReservationsSuccess(response));
    yield put(ReservationActions.fetchReservationDetailsSuccess(response));
  } catch(error){
    console.error(error);
    yield put(ReservationActions.setStatusError());
  }
}

function* _fetchAvailableBookingItemsForAssigning(action){
  try {
    const { includeAssignedBookingPropertyItems, reservationId, reservationCategoryId } = action;
    const response = yield call(ReservationService.fetchAvailableBookingItemsForAssigning, includeAssignedBookingPropertyItems, reservationId, reservationCategoryId);
    yield put(ReservationActions.fetchAvailableBookingItemsForAssigningSuccess(response));
  } catch(error){
    yield put(ReservationActions.setStatusError());
  }
}

function* _create(action){
  try {
    const { data } = action;
    const response = yield call(ReservationService.create, data);
    yield put(ReservationActions.createSuccess(response));
    yield put(ReservationActions.setStatusSuccess());
    yield put(ReservationActions.setStatusInitial());
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: ReservationMessageTypes.Create
    }));
  } catch(error){
    yield put(ReservationActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _assign(action){
  try {
    const { reservationId, bookingItemIds } = action;
    const response = yield call(ReservationService.assign, reservationId, bookingItemIds);
    yield put(ReservationActions.assingSuccess(response));
    yield put(ReservationActions.setStatusSuccess());
    yield put(ReservationActions.setStatusInitial());
  } catch(error){
    yield put(ReservationActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _unassign(action){
  try {
    const { reservationId, bookingItemIds } = action;
    const response = yield call(ReservationService.unassign, reservationId, bookingItemIds);
    yield put(ReservationActions.unassignSuccess(response));
    yield put(ReservationActions.setStatusSuccess());
    yield put(ReservationActions.setStatusInitial());
  } catch(error){
    yield put(ReservationActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}
function* _unassignAssign(action){
  try {
    const { reservationId, unassignBookingItemIds, assignBookingItemIds } = action;
    yield call(ReservationService.unassign, reservationId, unassignBookingItemIds);
    const response = yield call(ReservationService.assign, reservationId, assignBookingItemIds);
    yield put(ReservationActions.unassignAssignSuccess(response));
    yield put(ReservationActions.setStatusSuccess());
    yield put(ReservationActions.setStatusInitial());
  } catch(error){
    yield put(ReservationActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

// Actions
// Check in
function* _checkIn(action){
  try {
    const { reservation, sendCheckInNotification } = action;
    if ( window.confirm(`Are you sure you want to check in reservation: ${reservation.reservationNumber} ?`) ){
      const response = yield call(ReservationService.checkIn, reservation.id, sendCheckInNotification);
      yield put(ReservationActions.checkInSuccess(response));
      yield put(LayoutActions.addNotification({
        type: 'success',
        message: ReservationMessageTypes.CheckIn
      }));
    }
  } catch(error){
    yield put(ReservationActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}
// Check out
function* _checkOut(action){
  try {
    const { reservation } = action;
    if ( window.confirm(`Are you sure you want to check out reservation: ${reservation.reservationNumber} ?`) ){
      const response = yield call(ReservationService.checkOut, reservation.id);
      yield put(ReservationActions.checkOutSuccess(response));
      yield put(LayoutActions.addNotification({
        type: 'success',
        message: ReservationMessageTypes.CheckOut
      }));
    }
  } catch(error){
    yield put(ReservationActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}
// Refund
function* _refund(action){
  try {
    const { reservationId, data } = action;
    const response = yield call(ReservationService.refund, reservationId, data);
    yield put(ReservationActions.refundSuccess(response));
    yield put(ReservationActions.setStatusSuccess());
    yield put(ReservationActions.setStatusInitial());
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: ReservationMessageTypes.Refund
    }));
  } catch(error){
    yield put(ReservationActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}
function* _remainingPayment(action){
  try {
    const { reservationId, data } = action;
    const response = yield call(ReservationService.remainingPayment, reservationId, data);
    yield put(ReservationActions.remainingPaymentSuccess(response));
    yield put(ReservationActions.setStatusSuccess());
    yield put(ReservationActions.setStatusInitial());
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: ReservationMessageTypes.RemainingPayment
    }));
  } catch(error){
    yield put(ReservationActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}
// Cancel
function* _cancel(action){
  try {
    const { reservation } = action;
    const response = yield call(ReservationService.cancel, reservation.id);
    yield put(ReservationActions.cancelSuccess(response));
    yield put(ReservationActions.setStatusSuccess());
    yield put(ReservationActions.setStatusInitial());
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: ReservationMessageTypes.Cancel
    }));
  } catch(error){
    yield put(ReservationActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

// Send receipts
function* _sendReceipt(action){
  try {
    const { reservationId, transactionId, email } = action;
    yield call(ReservationService.sendReceipt, reservationId, transactionId, email);
    yield put(ReservationActions.sendReceiptSuccess());
    yield put(ReservationActions.setStatusSuccess());
    yield put(ReservationActions.setStatusInitial());
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: 'Receipt was sent'
    }));
  } catch(err){
    yield put(ReservationActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: err.message
    }));
  }
}

// Mark order complete
function* _markOrderComplete(action){
  try {
    const { reservationId, orderId, status } = action;
    yield call(OrderService.setStatus, orderId, status);
    yield put(ReservationActions.markOrderCompleteSuccess({
      id: reservationId,
      status
    }));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: 'Reservation order was marked as complete'
    }));
  } catch(err){
    yield put(ReservationActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: err.message
    }));
  }
}

// Shift
function* _shift(action){
  try {
    const { reservationId, params } = action;
    const response = yield call(ReservationService.shift, reservationId, params);
    if ( response.datesChanged ){
      yield put(ReservationActions.shiftSuccess(response.reservation));
      yield put(ReservationActions.setStatusSuccess());
      yield put(ReservationActions.setStatusInitial());
      yield put(LayoutActions.addNotification({
        type: 'success',
        message: 'Reservation start date/time was changed'
      }));
    } else {
      yield put(ReservationActions.shiftError(response));
    }
  } catch(error){
    yield put(ReservationActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

// Shift
function* _changeEndDate(action){
  try {
    const { reservationId, params } = action;
    const response = yield call(ReservationService.changeEndDate, reservationId, params);
    if ( response.datesChanged ){
      yield put(ReservationActions.changeEndDateSuccess(response.reservation));
      yield put(ReservationActions.setStatusSuccess());
      yield put(ReservationActions.setStatusInitial());
      yield put(LayoutActions.addNotification({
        type: 'success',
        message: 'Reservation end date/time was changed'
      }));
    } else {
      yield put(ReservationActions.changeEndDateFailure(response));
    }
  } catch(error){
    yield put(ReservationActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

// Transactions
function* _fetchTransactions(action){
  try {
    const { reservationId } = action;
    const response = yield call(ReservationService.fetchTransactions, reservationId);
    yield put(ReservationActions.fetchTransactionsSuccess(response));
  } catch(error){
    yield put(ReservationActions.setStatusError());
  }
}

export default function* (){
  yield takeLatest(ReservationActionTypes.FetchReservations, _fetchReservations);
  yield takeLatest(ReservationActionTypes.FetchReservationDetails, _fetchReservationDetails);
  yield takeLatest(ReservationActionTypes.FetchAvailableBookingItemsForAssigning, _fetchAvailableBookingItemsForAssigning);
  yield takeLatest(ReservationActionTypes.Create, _create);
  yield takeLatest(ReservationActionTypes.Assign, _assign);
  yield takeLatest(ReservationActionTypes.Unassign, _unassign);
  yield takeLatest(ReservationActionTypes.UnassignAssign, _unassignAssign);
  // Actions
  yield takeLatest(ReservationActionTypes.CheckIn, _checkIn);
  yield takeLatest(ReservationActionTypes.CheckOut, _checkOut);
  yield takeLatest(ReservationActionTypes.Refund, _refund);
  yield takeLatest(ReservationActionTypes.RemainingPayment, _remainingPayment);
  yield takeLatest(ReservationActionTypes.Cancel, _cancel);
  yield takeLatest(ReservationActionTypes.SendReceipt, _sendReceipt);
  yield takeLatest(ReservationActionTypes.MarkOrderComplete, _markOrderComplete);
  yield takeLatest(ReservationActionTypes.Shift, _shift);
  yield takeLatest(ReservationActionTypes.ChangeEndDate, _changeEndDate);
  // Transaction
  yield takeLatest(ReservationActionTypes.FetchTransactions, _fetchTransactions);
}