import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
// Redux
import { connect } from 'react-redux';
import { BookingItemActions } from 'app/store/booking-item/booking-item.actions';
import * as BookingSelectors from 'app/store/booking/booking.selectors';
import * as BookingItemSelectors from 'app/store/booking-item/booking-item.selectors';
// Components
import { DataLoading } from 'app/components/Loadings';
// Hooks
import useStateCallback from 'app/hooks/useStateCallback';
// Constants
import { AppMonths } from 'app/App.constants';
// 
import CalendarDailyTimeline from './CalendarDailyTimeline';
import CalendarHourlyTimeline from './CalendarHourlyTimeline';

const calendarStatuses = [
  { status: 'Upcoming', color: 'warning' },
  { status: 'Active', color: 'success' },
  // { status: 'Cancelled', color: 'danger' },
  { status: 'Completed', color: 'info' }
]

const CalendarDetails = ({
  // Props
  // State
  booking, status,
  // Dispatch
  fetchBookingItems, resetField
}) => {
  const { bookingId } = useParams();

  const [dataFetching, setDataFetching] = useState(true);

  const callbackNow = nextDate => {
    const firstDayInMonth = nextDate.startOf('month');
    const daysInMonth = nextDate.daysInMonth();
    const days = Array(daysInMonth).fill(0).map((_, i) => firstDayInMonth.add(i, 'day'));
    setDays(days);
  }

  const [now, setNow] = useStateCallback(dayjs(), callbackNow);
  const [days, setDays] = useState([]);

  useEffect(() => {
    fetchBookingItems(bookingId);
    return () => resetField('bookingItems');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( status === 'Fetched' ) setDataFetching(false);
    // eslint-disable-next-line
  }, [status]);

  const handlePrevMonth = () => setNow(prevState => dayjs(prevState).subtract('1', booking.period !== 'hour' ? 'month' : 'day'));
  const handleNextMonth = () => setNow(prevState => dayjs(prevState).add('1', booking.period !== 'hour' ? 'month' : 'day'));

  const handleDaySwitch = event => setNow(prevState => dayjs(prevState).date(event.target.value));
  const handleMonthSwitch = event => setNow(prevState => dayjs(prevState).month(event.target.value));


  if ( dataFetching ) return <DataLoading />;
  return (
    <>
      <div className="d-flex">
        {calendarStatuses.map((cs, i) => (
          <div key={i} className="d-flex align-items-center mb-2 mr-2" style={{
            lineHeight: 1
          }}>
            <span className={`badge-${cs.color} mr-2`} style={{
              width: '1rem',
              height: '1rem'
            }}></span>
            {cs.status}
          </div>
        ))}
      </div>
      <div className="reservation">
        <div className="reservation-head">
          <div className="reservation-right">
            <div className="d-flex justify-content-between align-items-center flex-1 p-2">
              <button
                className="btn btn-round"
                type="button"
                onClick={handlePrevMonth}
              ><i className="fa fa-angle-left" aria-hidden="true"></i></button>
              <div className="d-flex align-items-center">
                {booking.period === 'hour' ? (
                  <select
                    className="form-control form-control-sm | mr-2"
                    value={now.date()}
                    onChange={handleDaySwitch}
                  >
                    {days.map((day, i) => (
                      <option key={i} value={day.date()}>{day.date()}</option>
                    ))}
                  </select>
                ) : null}
                <select
                  style={{minWidth: '120px'}}
                  className="form-control form-control-sm | mr-2"
                  value={now.month()}
                  onChange={handleMonthSwitch}
                >
                  {AppMonths().map((month, i) => (
                    <option key={i} value={month.value}>{month.label}</option>
                  ))}
                </select>
                <span style={{whiteSpace: 'nowrap'}}>{now.format('YYYY')}</span>
              </div>
              <button
                className="btn btn-round"
                type="button"
                onClick={handleNextMonth}
              ><i className="fa fa-angle-right" aria-hidden="true"></i></button>
            </div>
          </div>
        </div>
        {booking.period === 'hour' ? (
          <CalendarHourlyTimeline now={now} days={days} />
        ) : (
          <CalendarDailyTimeline now={now} days={days} />
        )}
      </div>
    </>
  )
}

const mapStateToProps = ({ BookingState, BookingItemState }) => ({
  booking: BookingSelectors.getBooking(BookingState),
  status: BookingItemSelectors.getStatus(BookingItemState)
});

const mapDispatchToProps = dispatch => ({
  fetchBookingItems: bookingId => dispatch(BookingItemActions.fetchBookingItems(bookingId)),
  resetField: field => dispatch(BookingItemActions.resetField(field))
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarDetails);
