import React from 'react';
import { useForm } from 'react-hook-form';
// Redux
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import OrderActions from 'app/actions/order.actions';
// Components
import { ButtonLoading } from 'app/components/Buttons';
// React bootstrap
import { Modal } from 'react-bootstrap';

type Props = {
  // Props
  orderId: number;
  show: boolean;
  onHide: () => void;
  // State
  isLoading: boolean;
  status: string;
  // Dispatch
  cancelOrder: (orderId:number, notes:string) => void;
}

const OrderCancelDialog:React.FC<Props> = ({
  // Props
  orderId, show, onHide,
  // State
  isLoading, status,
  // Dispatch
  cancelOrder
}) => {

  React.useEffect(() => {
    if ( status === 'Success' ) onHide();
    // eslint-disable-next-line
  }, [status]);

  const { register, handleSubmit } = useForm<any>({
    defaultValues: {
      notes: ''
    }
  });

  const onSubmit = handleSubmit((data) => {
    cancelOrder(orderId, data.notes);
  });

  return (
    <Modal show={show} onHide={onHide}>
      <form onSubmit={onSubmit}>
        <Modal.Header closeButton>Cancel order</Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="description">Notes</label>
            <textarea
              ref={register()}
              className="form-control form-control-sm"
              name="notes" id="notes"
              rows={8}
            ></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-secondary btn-sm"
            type="button"
            onClick={onHide}
          >Close</button>
          <ButtonLoading
            loading={isLoading}
            type="submit"
          >Cancel</ButtonLoading>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

const mapStateToProps = (state:any) => ({
  isLoading: state.OrderState.isLoading,
  status: state.OrderState.status
});

const mapDispatchToProps = (dispatch:Dispatch) => ({
  cancelOrder: (orderId:number, notes:string) => dispatch(OrderActions.cancel(orderId, notes))
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderCancelDialog);
