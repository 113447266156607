import React, { useEffect } from 'react';
// Redux
import { connect } from 'react-redux';
import { MessageTemplateActions as Actions } from 'app/store/message-template/message-template.actions';
import * as Selectors from 'app/store/message-template/message-template.selectors';
// Components
import { DataLoading } from 'app/components/Loadings';
import { DataMessage } from 'app/components/DataMessage';
// 
import SystemMessageTemplatesListItem from './SystemMessageTemplatesListItem';

const SystemMessageTemplatesList = ({
  // Sttae
  items, isFetching,
  // Dispatch
  fetchMessageTemplates
}) => {

  useEffect(() => {
    fetchMessageTemplates();
    // eslint-disable-next-line
  }, []);

  if ( isFetching ) return <DataLoading />;
  return (
    <div className="pt-3">
      <DataMessage data={items}>
        <ul className="list-group">
          {items.map(item => (
            <SystemMessageTemplatesListItem
              key={`message-template-${item.id}`}
              item={item}
            />
          ))}
        </ul>
      </DataMessage>
    </div>
  )
}

const mapStateToProps = state => ({
  items: Selectors.getItems(state),
  isFetching: Selectors.getItemsIsFetching(state)
});

const mapDispatchToProps = dispatch => ({
  fetchMessageTemplates: () => dispatch(Actions.fetchList()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SystemMessageTemplatesList);
