import Types from '../types/inventory-group.types';

const inventoryGroup = {
  name: '',
  pickupLocation: '',
  deliveryAvailable: false,
  orderManagers: [],
  availability: {
    sunday: {
      available: true,
      time: { end: '', start: '' }
    },
    monday: {
      available: true,
      time: { end: '', start: '' }
    },
    tuesday: {
      available: true,
      time: { end: '', start: '' }
    },
    wednesday: {
      available: true,
      time: { end: '', start: '' }
    },
    thursday: {
      available: true,
      time: { end: '', start: '' }
    },
    friday: {
      available: true,
      time: { end: '', start: '' }
    },
    saturday: {
      available: true,
      time: { end: '', start: '' }
    },
  }
}

const initialState = {
  inventoryGroups: [],
  inventoryGroup,

  params: {
    limit: 3,
    offset: 0
  },

  isFetching: true,

  hasMore: true,
  hasMoreLoading: false,

  isFormLoading: false,
  isFormSuccess: false
};

export default function(state = initialState, { type, payload }){
  switch (type){
    // Request
    case Types.FETCH_ALL:
      return { ...state, inventoryGroups: [], isFetching: true };
    case Types.FETCH_BY_ID:
      return { ...state, inventoryGroup, isFetching: true };
    case Types.CREATE:
    case Types.UPDATE:
      return { ...state, isFormLoading: true, isFormSuccess: false };
    // Success
    case Types.FETCH_ALL_SUCCESS:
      return { ...state, inventoryGroups: payload.inventoryGroups, isFetching: false };
    case Types.FETCH_BY_ID_SUCCESS:
      return { ...state, inventoryGroup: { ...state.inventoryGroup, ...payload.inventoryGroup }, isFetching: false };
    case Types.CREATE_SUCCESS:
      return { ...state,
        inventoryGroups:
          [...state.inventoryGroups, payload.inventoryGroup],
        isFormLoading: false,
        isFormSuccess: true
      };
    case Types.UPDATE_SUCCESS:
      return { ...state,
        inventoryGroups:
          state.inventoryGroups.map(
            i => i.id === payload.inventoryGroup.id
            ? { ...i, inventoryGroup }
            : i
          ),
        isFormLoading: false,
        isFormSuccess: true };
    case Types.DELETE_SUCCESS:
      return { ...state, inventoryGroups: state.inventoryGroups.filter(ci => ci.id !== payload.inventoryGroupId) }
    case Types.ENABLE_SUCCESS:
      return {
        ...state,
        inventoryGroups: state.inventoryGroups.map(
          g => g.id === payload.inventoryGroupId ? { ...g, enabled: true } : g
        )
      };
    case Types.DISABLE_SUCCESS:
      return {
        ...state,
        inventoryGroups: state.inventoryGroups.map(
          g => g.id === payload.inventoryGroupId ? { ...g, enabled: false } : g
        )
      };
    // Failure
    case Types.FETCH_ALL_FAILURE:
      return { ...state, inventoryGroups: [], isFetching: false };
    case Types.FETCH_BY_ID_FAILURE:
      return { ...state, inventoryGroups: state.inventoryGroup, isFetching: false };
    case Types.CREATE_FAILURE:
    case Types.UPDATE_FAILURE:
      return { ...state, isFormLoading: false, isFormSuccess: false };

    case Types.CLEAR_FETCH_BY_ID:
      return { ...state, inventoryGroup: initialState.inventoryGroup };
    // Other
    case Types.SET_IS_FETCHING:
      return { ...state, isFetching: true };
    case Types.SET_IS_FETCHED:
      return { ...state, isFetching: false };
    case Types.RESET_FORM:
      return { ...state, isFormLoading: false, isFormSuccess: false };
    case Types.SET_INITIAL_STATE:
      return initialState;
    default:
      return state;
  }
}
