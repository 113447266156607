import React, { useState } from 'react';
// Modal
import AvailabilityFormModal from '../../../layouts/Modals/AvailabilityFormModal';

const AvailabilityCreate = () => {
  const [ show, setShow ] = useState(false);
  return (
    <>
      <div className="form-group text-right">
        <button
          className="btn btn-primary btn-sm"
          type="button"
          onClick={() => setShow(true)}
        >Create</button>
      </div>
      {/* Modal */}
      {show &&
        <AvailabilityFormModal
          show={show}
          onHide={() => setShow(false)}
        />
      }
    </>
  )
}

export default AvailabilityCreate;
