import React, { Component } from 'react';
// React bootstrap
import { Modal } from 'react-bootstrap';
// Store
import { connect } from 'react-redux';
import {
  createScheduleSlot,
  updateScheduleSlot,
  clearSelectedScheduleSlot,
  resetForm
} from '../../../actions/schedule.actions';
// 
import ScheduleSlotFormBuilder from './ScheduleSlotFormBuilder';

class ScheduleSlotFormModal extends Component {
  componentWillReceiveProps(newProps){
    if ( newProps.isFormSuccess ) this.handleHide();
  }

  handleHide = () => {
    this.props.onHide();
    this.props.clearSelectedScheduleSlot();
    this.props.resetForm();
  }

  render() {
    const {
      show,
      selectedSlot, isFormLoading
    } = this.props;
    return (
      <Modal
        show={show}
        onHide={this.handleHide}
      >
        <Modal.Header closeButton>
          {(selectedSlot && selectedSlot.id)
            ? <Modal.Title>Update schedule slot</Modal.Title>
            : <Modal.Title>Create schedule slot</Modal.Title>
          }
        </Modal.Header>
        <Modal.Body>
          <ScheduleSlotFormBuilder
            selectedSlot={selectedSlot}
            isFormLoading={isFormLoading}
            onHide={this.handleHide}
            onCreateScheduleSlot={this.props.createScheduleSlot}
            onUpdateScheduleSlot={this.props.updateScheduleSlot}
          />
        </Modal.Body>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  selectedSlot: state.schedule.selectedSlot,

  isFormLoading: state.schedule.isFormLoading,
  isFormSuccess: state.schedule.isFormSuccess
});

const mapDispatchToProps = dispatch => ({
  createScheduleSlot: slot => dispatch(createScheduleSlot(slot)),
  updateScheduleSlot: slot => dispatch(updateScheduleSlot(slot)),
  clearSelectedScheduleSlot: () => dispatch(clearSelectedScheduleSlot()),
  resetForm: () => dispatch(resetForm())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleSlotFormModal);