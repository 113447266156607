import { takeLatest, call, put } from 'redux-saga/effects';
// Action
import Types from '../types/asset.types';
import AssetService from '../services/asset.service';
import AssetActions from '../actions/asset.actions';

// Fetch categories
function* _createAsset(action){
  try {
    const { file, folder } = action.payload;
    const response = yield call(AssetService.createAsset, file, folder);
    yield put(AssetActions.createAssetSuccess(response));
  } catch(err){
    yield put(AssetActions.createAssetFailure());
    console.error(err);
  }
}

export function* assetSaga(){
  yield takeLatest(Types.CREATE_ASSET, _createAsset);
}
