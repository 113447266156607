export const ContactMessageActionTypes = {
  FetchList: '[Contact message] Fetch list',
  FetchListSuccess: '[Contact message] Fetch list success',

  FetchListConversation: '[Contact message] Fetch list conversation',
  FetchListConversationSuccess: '[Contact message] Fetch list conversation success',

  MarkAsRead: '[Contact message] Mark as read',
  MarkAsReadSuccess: '[Contact message] Mark as read success',

  SendInApp: '[Contact message] Send in app',
  SendInAppSuccess: '[Contact message] Send in app success',

  // Status
  StatusSuccess: '[Contact message] Status success',
  StatusError: '[Contact message] Status error',
  StatusInitial: '[Contact message] Status initial',
  SetInitialState: '[Contact message] Set initial state'
}

export const ContactMessageMessageTypes = {
  
}
