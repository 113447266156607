import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
import { ReservationActions } from 'app/store/reservation/reservation.actions';
import * as BookingSelectors from 'app/store/booking/booking.selectors';
import * as ReservationSelectors from 'app/store/reservation/reservation.selectors';
// Utilities
import { setStart } from 'app/utilities/datetime.convert';

let timeout = null;

const isDateValid = value => /^\d{4}-\d{2}-\d{2}$/.test(value);
const format = 'YYYY-MM-DDTHH:mm:ss.sss[Z]';

const ReservationFilter = ({
  // Props
  // State
  booking, params,
  // Dispatch
  fetchReservations
}) => {
  const { bookingId } = useParams();
  const [ username, setUsername ] = useState('');
  const [ statuses, setStatuses ] = useState('');

  useEffect(() => {
    setUsername(params.reservationNumber);
    // eslint-disable-next-line
  }, [params.reservationNumber]);

  useEffect(() => {
    setStatuses(params.statuses);
    // eslint-disable-next-line
  }, [params.statuses]);

  const handleChange = e => {
    const { name, value } = e.target;
    if ( timeout ) clearTimeout(timeout);
    let fields = {};
    if ( name === 'username' ){
      if ( value !== '' ){
        fields[!isNaN(value) ? 'reservationNumber' : 'username'] = value;
      } else {
        fields['reservationNumber'] = value;
        fields['username'] = value;
      }
      setUsername(value);
    } else if ( name === 'start' || name === 'end' ){
      fields[name] = isDateValid(value) ? setStart(value, format) : '';
    } else {
      fields[name] = value;
    }
    if ( name === 'statuses' ) setStatuses(value);

    timeout = setTimeout(() => {
      fetchReservations(bookingId, {
        ...params,
        ...fields,
        offset: 0,
      });
    }, 1000);
  }

  return (
    <>
      <div className="row row-8">
        <div className="col-12 col-lg-4 col-xl-2">
          <div className="form-group">
            <label htmlFor="username">Search</label>
            <input
              className="form-control form-control-sm"
              id="username" name="username" value={username} type="text" placeholder="Username or ID"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 col-xl-2">
          <div className="form-group">
            <label htmlFor="statuses">Status</label>
            <select
              className="form-control form-control-sm"
              id="statuses" name="statuses" value={statuses}
              onChange={handleChange}
            >
              <option value="">All</option>
              <option value="upcoming">Upcoming</option>
              <option value="active">Active</option>
              <option value="completed">Completed</option>
              <option value="cancelled">Cancelled</option>
            </select>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 col-xl-2">
          <div className="form-group">
            <label htmlFor="unassignedOnly">Assignment status</label>
            <select
              className="form-control form-control-sm"
              id="unassignedOnly" name="unassignedOnly"
              onChange={handleChange}
            >
              <option value={false}>All</option>
              <option value={true}>Unassigned</option>
            </select>
          </div>
        </div>  
        <div className="col-12 col-md-6 col-xl-3">
          <div className="form-group">
            <label htmlFor="start">{booking.period !== 'hour' ? 'Arrival' : 'Reservation time'}</label>
            <input
              className="form-control form-control-sm"
              id="start" name="start" type="date"
              onChange={handleChange}
            />
          </div>
        </div>
        {booking.period !== 'hour' ? (
          <div className="col-12 col-md-6 col-xl-3">
            <div className="form-group">
              <label htmlFor="end">Departure</label>
              <input
                className="form-control form-control-sm"
                id="end" name="end" type="date"
                onChange={handleChange}
              />
            </div>
          </div>
        ) : null}
      </div>
      <hr />
    </>
  )
}

const mapStateToProps = ({ BookingState, ReservationState }) => ({
  booking: BookingSelectors.getBooking(BookingState),
  params: ReservationSelectors.getParams(ReservationState)
});

const mapDispatchToProps = dispatch => ({
  fetchReservations: (bookingId, params) => dispatch(ReservationActions.fetchReservations(bookingId, params))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReservationFilter);
