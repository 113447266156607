import React from 'react';
import PhoneInput from 'react-phone-input-2';

export const Phone = ({
  // Props
  label = 'Phone', value, onChange, onBlur, errorText
}) => {
  const inputValue = value ? value.replace('+', '') : '';

  const handleChange = (value, country) => {
    let hiddenValue = value;
    hiddenValue = hiddenValue.substring(country.dialCode.length);
    onChange(value, hiddenValue ? `+${country.dialCode}_${hiddenValue}` : '');
  }

  return (
    <div className="form-group">
      <label htmlFor="">{label}&nbsp;*</label>
      <PhoneInput
        country={'us'}
        value={inputValue}
        onChange={handleChange}
        onBlur={onBlur}
        containerClass={errorText ? 'is-invalid' : ''}
        inputClass={errorText ? 'is-invalid' : ''}
      />
      {errorText ? <div className="invalid-feedback">{errorText}</div> : null}
    </div>
  )
}
