import React from 'react';

const Details = props => {
  const { onClose, children } = props;
  return (
    <div className="details">
      <div className="details-overlay" onClick={onClose}></div>
      <div className="details-wrapper">
        <div className="details-container">
          <div className="form-group text-start">
            <button
              className="btn btn-outline-secondary btn-sm"
              type="button"
              onClick={onClose}
            >Close</button>
          </div>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Details;
