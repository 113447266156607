import { call, put, takeLatest } from 'redux-saga/effects';
import { ContentCategoryService } from './content-category.service';
import * as LayoutActions from 'app/actions/layout.actions';
import {
  FETCH_LIST, CREATE, UPDATE, DELETE, ENABLE, DISABLE,
  ContentCategoryActions
} from './content-category.actions';

function* _fetchList(){
  try {
    const response = yield call(ContentCategoryService.fetchList);
    yield put(ContentCategoryActions.fetchListSuccess(response));
  } catch(error){
    console.error(error);
    yield put(ContentCategoryActions.setStatus('Error'));
  }
}

function* _create({ payload }){
  try {
    const response = yield call(ContentCategoryService.create, payload.data);
    yield put(ContentCategoryActions.createSuccess(response));
    yield put(ContentCategoryActions.setStatus('Success'));
    yield put(ContentCategoryActions.setStatus('Initial'));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: 'Content category was created'
    }));
  } catch(error){
    console.error(error);
    yield put(ContentCategoryActions.setStatus('Error'));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _update({ payload }){
  try {
    const response = yield call(ContentCategoryService.update, payload.id, payload.data);
    yield put(ContentCategoryActions.updateSuccess(response));
    yield put(ContentCategoryActions.setStatus('Success'));
    yield put(ContentCategoryActions.setStatus('Initial'));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: 'Content category was updated'
    }));
  } catch(error){
    console.error(error);
    yield put(ContentCategoryActions.setStatus('Error'));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _delete({ payload }){
  try {
    if ( window.confirm(`Are you sure you want to delete category: ${payload.item.name}?`) ){
      yield call(ContentCategoryService.delete, payload.item.id);
      yield put(ContentCategoryActions.deleteSuccess(payload.item.id));
      yield put(ContentCategoryActions.setStatus('Success'));
      yield put(ContentCategoryActions.setStatus('Initial'));
      yield put(LayoutActions.addNotification({
        type: 'success',
        message: 'Content category was deleted'
      }));
    }
  } catch(error){
    console.error(error);
    yield put(ContentCategoryActions.setStatus('Error'));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _enable({ payload }){
  try {
    yield call(ContentCategoryService.enable, payload.id);
    yield put(ContentCategoryActions.enableSuccess(payload.id));
  } catch(error){
    console.error(error);
    yield put(ContentCategoryActions.setStatus('Error'));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _disable({ payload }){
  try {
    yield call(ContentCategoryService.disable, payload.id);
    yield put(ContentCategoryActions.disableSuccess(payload.id));
  } catch(error){
    console.error(error);
    yield put(ContentCategoryActions.setStatus('Error'));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

export default function* (){
  yield takeLatest(FETCH_LIST, _fetchList);
  yield takeLatest(CREATE, _create);
  yield takeLatest(UPDATE, _update);
  yield takeLatest(DELETE, _delete);
  yield takeLatest(ENABLE, _enable);
  yield takeLatest(DISABLE, _disable);
}
