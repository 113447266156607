import Statuses from 'types/statuses';
import { ISeason, ISeasonData, ISeasonState, SeasonTypes, SeasonActionTypes } from './season.types';

export default class SeasonActions {
  // Fetch list staff
  public static fetchSeasonsList = ():SeasonActionTypes => ({
    type: SeasonTypes.FetchSeasonsList
  });
  public static fetchSeasonsListSuccess = (items: Array<ISeason>):SeasonActionTypes => ({
    type: SeasonTypes.FetchSeasonsListSuccess, payload: { items }
  });
  // Create
  public static createSeason = (data:ISeasonData):SeasonActionTypes => ({
    type: SeasonTypes.CreateSeason, payload: { data }
  });
  public static createSeasonSuccess = (item:ISeason):SeasonActionTypes => ({
    type: SeasonTypes.CreateSeasonSuccess, payload: { item }
  });
  // Update
  public static updateSeason = (seasonId:number, data:ISeasonData):SeasonActionTypes => ({
    type: SeasonTypes.UpdateSeason, payload: { seasonId, data }
  });
  public static updateSeasonSuccess = (item:ISeason):SeasonActionTypes => ({
    type: SeasonTypes.UpdateSeasonSuccess, payload: { item }
  });
  // Delete
  public static deleteSeason = (seasonId:number):SeasonActionTypes => ({
    type: SeasonTypes.DeleteSeason, payload: { seasonId }
  });
  public static deleteSeasonSuccess = (seasonId:number):SeasonActionTypes => ({
    type: SeasonTypes.DeleteSeasonSuccess, payload: { seasonId }
  });
  // Update slots
  public static updateSlots = (slots:Array<any>, seasonId?:number, data?:ISeasonData):SeasonActionTypes => ({
    type: SeasonTypes.UpdateSlots, payload: { slots, seasonId, data }
  });
  public static updateSlotsSuccess = (item:ISeason, items:Array<ISeason>):SeasonActionTypes => ({
    type: SeasonTypes.UpdateSlotsSuccess, payload: { item, items }
  });
  // Default
  // Set status
  public static setStatus = (status:Statuses):SeasonActionTypes => ({
    type: SeasonTypes.SetStatus, payload: { status }
  });
  public static setInitialField = (field:keyof ISeasonState):SeasonActionTypes => ({
    type: SeasonTypes.SetInitialField, payload: { field }
  });
}