import { Action } from 'redux';
// Types
import Statuses from 'types/statuses';
// Modules
import { IMembership, IMembershipTransaction, IMembershipVisit } from 'models/Membership';
import { IState } from './membership.models';

// Types
export enum MembershipTypes {
  FetchMemberships = '@@membership/Fetch memberships',
  FetchMembershipsSuccess = '@@membership/Fetch memberships success',

  FetchMembership = '@@membership/Fetch membership',
  FetchMembershipSuccess = '@@membership/Fetch membership success',

  FetchMembershipByNumber = '@@membership/Fetch membership by number',
  FetchMembershipByNumberSuccess = '@@membership/Fetch membership by number success',

  FetchMembershipTransactions = '@@membership/Fetch membership transactions',
  FetchMembershipTransactionsSuccess = '@@membership/Fetch membership transactions success',

  FetchMembershipVisits = '@@membership/Fetch membership visits',
  FetchMembershipVisitsSuccess = '@@membership/Fetch membership visits success',

  CheckInMembership = '@@membership/Check in membership',
  CheckInMembershipSuccess = '@@membership/Check in membership success',

  InactivateMembership = '@@membership/Inactivate membership',
  InactivateMembershipSuccess = '@@membership/Inactivate membership success',

  SendMembershipReceipt = '@@membership/Send membership receipt',
  SendMembershipReceiptSuccess = '@@membership/Send membership receipt success',

  SetStatus = '@@membership/Set status',
  SetInitialField = '@@membership/Set initial field'
};

// Actions
interface FetchMemberships extends Action<typeof MembershipTypes.FetchMemberships> {
  payload: { params:any }
};
interface FetchMembershipsSuccess extends Action<typeof MembershipTypes.FetchMembershipsSuccess> {
  payload: { memberships:Array<IMembership>, total:number }
};

interface FetchMembership extends Action<typeof MembershipTypes.FetchMembership> {
  payload: { membershipId:number }
};
interface FetchMembershipSuccess extends Action<typeof MembershipTypes.FetchMembershipSuccess> {
  payload: { membership:IMembership }
};

interface FetchMembershipByNumber extends Action<typeof MembershipTypes.FetchMembershipByNumber> {
  payload: { membershipNumber:number }
};
interface FetchMembershipByNumberSuccess extends Action<typeof MembershipTypes.FetchMembershipByNumberSuccess> {
  payload: { membership:IMembership }
};

interface FetchMembershipTransactions extends Action<typeof MembershipTypes.FetchMembershipTransactions> {
  payload: { membershipId:number }
};
interface FetchMembershipTransactionsSuccess extends Action<typeof MembershipTypes.FetchMembershipTransactionsSuccess> {
  payload: { transactions:Array<IMembershipTransaction> }
};

interface FetchMembershipVisits extends Action<typeof MembershipTypes.FetchMembershipVisits> {
  payload: { membershipId:number, params:any }
};
interface FetchMembershipVisitsSuccess extends Action<typeof MembershipTypes.FetchMembershipVisitsSuccess> {
  payload: { visits:Array<IMembershipVisit>, total:number }
};

interface CheckInMembership extends Action<typeof MembershipTypes.CheckInMembership> {
  payload: { membershipId:number }
};
interface CheckInMembershipSuccess extends Action<typeof MembershipTypes.CheckInMembershipSuccess> {};

interface InactivateMembership extends Action<typeof MembershipTypes.InactivateMembership> {
  payload: { membershipId:number, params:any }
};
interface InactivateMembershipSuccess extends Action<typeof MembershipTypes.InactivateMembershipSuccess> {
  payload: { membership:IMembership }
};

interface SendMembershipReceipt extends Action<typeof MembershipTypes.SendMembershipReceipt> {
  payload: { membershipId:number, transactionId:number, params:any }
};
interface SendMembershipReceiptSuccess extends Action<typeof MembershipTypes.SendMembershipReceiptSuccess> {};

interface SetStatus extends Action<typeof MembershipTypes.SetStatus> {
  payload: { status:Statuses }
}
interface SetInitialField extends Action<typeof MembershipTypes.SetInitialField> {
  payload: { field:keyof IState }
}

export type MembershipActionTypes =
  FetchMemberships | FetchMembershipsSuccess |
  FetchMembership | FetchMembershipSuccess |
  FetchMembershipByNumber | FetchMembershipByNumberSuccess |
  FetchMembershipTransactions | FetchMembershipTransactionsSuccess |
  FetchMembershipVisits | FetchMembershipVisitsSuccess |
  CheckInMembership | CheckInMembershipSuccess |
  InactivateMembership | InactivateMembershipSuccess |
  SendMembershipReceipt | SendMembershipReceiptSuccess |
  SetStatus | SetInitialField
;
