import { ReservationActionTypes } from './reservation.types';

const initialState = {
  bookingItems: [],

  reservations: [],
  reservationTotal: null,

  transactions: [],

  reservationShift: null,
  reservationProlong: null,

  params: {
    limit: 20,
    offset: 0,
    username: '',
    reservationNumber: '',
    statuses: '',
    start: '',
    end: '',
    initial: false,
    unassignedOnly: false,
  },
  status: 'Initial',
  isLoading: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ReservationActionTypes.FetchReservations:
      const { params, isLoadMore } = action;
      return {
        ...state,
        reservations: isLoadMore ? state.reservations : initialState.reservations,
        reservationTotal: null,
        params: params.initial ? params : {...state.params, ...params},
        status: isLoadMore ? 'FetchingMore' : 'Fetching'
      }
    case ReservationActionTypes.FetchReservationsSuccess:
      const { data = [], total } = action.reservation;
      return {
        ...state,
        reservations: state.reservations ? [...state.reservations, ...data] : state.reservations,
        reservationTotal: total,
        params: {
          ...state.params,
          initial: false
        },
        status: 'Fetched'
      };
    
    case ReservationActionTypes.FetchReservationDetails:
      return { ...state, status: 'Fetching' };
    case ReservationActionTypes.FetchReservationDetailsSuccess:
      return { ...state, status: 'Fetched', reservations: action.reservation.data };

    case ReservationActionTypes.FetchAvailableBookingItemsForAssigning:
      return { ...state, bookingItems: initialState.bookingItems, status: 'ModalFetching' };
    case ReservationActionTypes.FetchAvailableBookingItemsForAssigningSuccess:
      return { ...state, bookingItems: action.bookingItems, status: 'ModalFetched' };

    case ReservationActionTypes.ClearAvailableBookingItems:
      return { ...state, bookingItems: initialState.bookingItems };

    case ReservationActionTypes.Create:
    case ReservationActionTypes.Assign:
    case ReservationActionTypes.Unassign:
    case ReservationActionTypes.UnassignAssign:
      return { ...state, status: 'Loading' };
    case ReservationActionTypes.CreateSuccess:
      return {
        ...state,
        reservations: [...state.reservations, action.reservation],
        status: 'Loaded'
      };
    case ReservationActionTypes.AssignSuccess:
    case ReservationActionTypes.UnassignSuccess:
    case ReservationActionTypes.UnassignAssignSuccess:
      return {
        ...state,
        reservations: state.reservations.map(r => r.id === action.reservation.id ? { ...r, ...action.reservation } : r),
        status: 'Loaded'
      };

    case ReservationActionTypes.CheckIn:
    case ReservationActionTypes.CheckOut:
    case ReservationActionTypes.Cancel:
      return { ...state, isLoading: true };
    case ReservationActionTypes.CheckInSuccess:
    case ReservationActionTypes.CheckOutSuccess:
    case ReservationActionTypes.CancelSuccess:
      return {
        ...state,
        reservations: state.reservations.map(r => r.id === action.reservation.id ? { ...r, ...action.reservation } : r),
        isLoading: false
      };

    case ReservationActionTypes.SendReceipt:
      return { ...state, isLoading: true };
    case ReservationActionTypes.SendReceiptSuccess:
      return { ...state, isLoading: false };

    case ReservationActionTypes.MarkOrderComplete:
      return { ...state, isLoading: true };
    case ReservationActionTypes.MarkOrderCompleteSuccess:
      return {
        ...state,
        reservations: state.reservations ? state.reservations.map(r => r.id === action.reservation.id ? {
          ...r,
          extraOrder: {
            ...r.extraOrder,
            status: action.reservation.status
          }
        } : r) : state.reservations,
        isLoading: false
      }

    case ReservationActionTypes.Refund:
      return { ...state, status: 'Loading' }
    case ReservationActionTypes.RefundSuccess:
      return {
        ...state,
        transactions: [...state.transactions, action.transaction],
        status: 'Loaded'
      };

    case ReservationActionTypes.RemainingPayment:
      return { ...state, status: 'Loading' }
    case ReservationActionTypes.RemainingPaymentSuccess:
      return {
        ...state,
        reservations: state.reservations.map((r) => r.id === action.reservation.id ? action.reservation : r),
        status: 'Loaded'
      };

    case ReservationActionTypes.Shift:
      return {...state, status: 'Loading'}
    case ReservationActionTypes.ShiftSuccess:
      return {
        ...state,
        reservations: state.reservations.map(
          r => r.id === action.reservation.id ? action.reservation : r
        ),
        status: 'Loaded'
      };
    case ReservationActionTypes.ShiftError:
      return {
        ...state,
        reservationShift: action.reservationShift,
        status: 'Loaded'
      };

    case ReservationActionTypes.ChangeEndDate:
      return {...state, status: 'Loading'}
    case ReservationActionTypes.ChangeEndDateSuccess:
      return {
        ...state,
        reservations: state.reservations.map(
          r => r.id === action.reservation.id ? action.reservation : r
        ),
        status: 'Loaded'
      };
    case ReservationActionTypes.ChangeEndDateFailure:
      return {
        ...state,
        reservationProlong: action.reservationProlong,
        status: 'Loaded'
      };

    case ReservationActionTypes.FetchTransactions:
      return { ...state, transactions: initialState.transactions, status: 'ModalFetching' };
    case ReservationActionTypes.FetchTransactionsSuccess:
      return { ...state, transactions: action.transactions, status: 'ModalFetched' };

    // Filter
    case ReservationActionTypes.SetParams:
      const { paramsType, paramsValue } = action;
      return {
        ...state,
        params: {
          ...state.params,
          [paramsType]: paramsValue
        }
      };
    case ReservationActionTypes.ResetParams:
      return {
        ...state,
        params: initialState.params
      };

    // Set status
    case ReservationActionTypes.ResetField:
      return {
        ...state,
        [action.field]: initialState[action.field]
      };
    case ReservationActionTypes.StatusSuccess:
      return { ...state, status: 'Success' };
    case ReservationActionTypes.StatusError:
      return { ...state, status: 'Error' };
    case ReservationActionTypes.StatusInitial:
      return { ...state, status: 'Initial' };
    default:
      return state;
  }
}