import React, { Component } from 'react';
// Store
import { connect } from 'react-redux';
import { addNotification } from '../../../actions/layout.actions';

import LoginForm from './LoginForm';

class Login extends Component {
  componentDidMount(){
    const { location } = this.props;
    if ( location.search ){
      let status = new URLSearchParams(location.search).get('status');
      if ( status === '403' || status === '401' ){
        this.props.addNotification({
          type: 'danger',
          message: 'Token is expired'
        });
      }
    }
  }

  render(){
    return (
      <div className="area area-auth">
        <div className="container-fluid">
          <div className="area-container">
            <h1 className="area-title">Login</h1>
            <LoginForm />
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  ({ router }) => ({
    location: router.location
  }),
  dispatch => ({
    addNotification: message => dispatch(addNotification(message))
  })
)(Login);
