import { Reducer } from 'redux';
import { IAppState, AppTypes, AppActionTypes } from './app.types';
import Statuses from 'types/statuses';

export default class AppReducer {
  private static readonly _initialState:IAppState = {
    requestedUrl: null,
    status: Statuses.Initial
  }

  public static reducer:Reducer<IAppState, AppActionTypes> = (
    state = AppReducer._initialState,
    action
  ) => {
    switch (action.type){
      case AppTypes.SetInited:
        return state;
      case AppTypes.SetRequestedUrl:
        return {...state, requestedUrl: action.payload.requestedUrl};
      default:
        return state;
    }
  }
}

