import React from 'react';
import { Switch, Route } from 'react-router-dom';
// Utils
import PrivateRoute from './utilities/private.route';
// Pages
import Login from 'app/pages/Auth/Login';
import ForgotPasswordPage from 'app/pages/ForgotPasswordPage';
import UpdatePasswordPage from 'app/pages/UpdatePasswordPage';
import UnsubscribePage from 'pages/Unsubscribe.page';
// Admin
import Admin from 'app/pages/Admin';
import OrderManager from 'app/pages/OrderManager';
import AccountManager from 'pages/AccountManager';
import ProfilePage from 'pages/Profile.page';
import OrderCheckInPage from 'pages/OrderCheckIn.page';
import MembershipCheckInPage from 'pages/MembershipCheckIn.page';
import NotFound from 'app/pages/NotFound';

export const AppRouting = ({user}) => {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route path="/forgot-password" component={ForgotPasswordPage} />
      <Route path="/email-subscription-unsubscribe" component={UnsubscribePage} />
      <Route path="/update-password" component={UpdatePasswordPage} />
      {user.role === 'admin' && <PrivateRoute path="/admin" component={Admin} />}
      {user.role === 'orderManager' && <PrivateRoute path="/manager" component={OrderManager} />}
      {user.role === 'accountManager' && <PrivateRoute path="/am" component={AccountManager} />}
      <PrivateRoute path="/profile" component={ProfilePage} />
      <PrivateRoute path="/order-check-in" component={OrderCheckInPage} />
      <PrivateRoute path="/membership-check-in" component={MembershipCheckInPage} />
      <Route path="*" component={NotFound} />
    </Switch>
  )
}
