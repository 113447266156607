import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
// Next rework
import AppReducer from 'store/app/app.reducer';
import StaffReducer from 'store/staff/staff.reducer';
import MailTemplateReducer from 'store/mail-template/mail-template.reducer';
import AccountManagerReducer from 'store/account/account.reducer';
import SettingReducer from 'store/setting/setting.reducer';
import MapReducer from 'store/map/map.reducer';
import MapPoiReducer from 'store/map-poi/map-poi.reducer';
import InventoryGroupReducer from 'store/inventory-group/inventory-group.reducer';
import OrderReducer from 'store/order/order.reducer';
import WaiverReducer from 'store/waiver/waiver.reducer';
import SeasonReducer from 'store/season/season.reducer';
import MembershipReducer from 'store/membership/membership.reducer';
import MembershipTypeReducer from 'store/membership-type/membership-type.reducer';
import RemotelockDeviceReducer from 'store/remotelock-device/remotelock-device.reducer';
import TaxesReducer from 'store/taxes/taxes.reducer';

// Reducers
// Old
import layoutReducer from 'app/reducers/layout.reducer';
import NotificationReducer from 'app/reducers/notification.reducer';
import authReducer from 'app/reducers/auth.reducer';
import UserReducer from 'app/reducers/user.reducer';
import templateReducer from 'app/reducers/template.reducer';
import scheduleReducer from 'app/reducers/schedule.reducer';
import ProductReducer from 'app/reducers/product.reducer';
import _OrderReducer from 'app/reducers/order.reducer';
import AssetReducer from 'app/reducers/asset.reducer';
import _InventoryGroupReducer from 'app/reducers/inventory-group.reducer';
// Next
import _AppReducer from 'app/store/app/app.reducer';
import _UserReducer from 'app/store/user/user.reducer';
import BookingReducer from 'app/store/booking/booking.reducer';
import BookingItemReducer from 'app/store/booking-item/booking-item.reducer';
import BlackoutReducer from 'app/store/blackout/blackout.reducer';
import ReservationReducer from 'app/store/reservation/reservation.reducer';
import ReservationCategoryReducer from 'app/store/reservation-category/reservation-category.reducer';
import RestrictionReducer from 'app/store/restriction/restriction.reducer';
import AvailabilityReducer from 'app/store/availability/availability.reducer';
import _WaiverReducer from 'app/store/waiver/waiver.reducer';
import FinancialReportReducer from 'app/store/financial-report/financial-report.reducer';
import ContactMessageReducer from 'app/store/contact-message/contact-message.reducer';
import ProductCategoryReducer from 'app/store/product-category/product-category.reducer';
import MessageTemplateReducer from 'app/store/message-template/message-template.reducer';
import ContentCategoryReducer from 'app/store/content-category/content-category.reducer';
import ContentItemReducer from 'app/store/content-item/content-item.reducer';

const rootReducer = history => combineReducers({
  // Next rework
  app: AppReducer.reducer,
  staff: StaffReducer.reducer,
  mailTemplate: MailTemplateReducer.reducer,
  accountManager: AccountManagerReducer.reducer,
  setting: SettingReducer.reducer,
  map: MapReducer.reducer,
  mapPoi: MapPoiReducer.reducer,
  inventoryGroup: InventoryGroupReducer.reducer,
  order: OrderReducer.reducer,
  waiver: WaiverReducer.reducer,
  season: SeasonReducer.reducer,
  membership: MembershipReducer.reducer,
  membershipType: MembershipTypeReducer.reducer,
  remotelockDevice: RemotelockDeviceReducer.reducer,
  taxes: TaxesReducer.reducer,


  NotificationState: NotificationReducer,
  UserState: UserReducer,
  AssetState: AssetReducer,
  // CategoryState: CategoryReducer,
  InventoryGroupState: _InventoryGroupReducer,
  // MailTemplateState: MailTemplateReducer,
  OrderState: _OrderReducer,
  ContactMessageState: ContactMessageReducer,
  ProductState: ProductReducer,
  ProductCategoryState: ProductCategoryReducer,

  auth: authReducer,
  layout: layoutReducer,
  template: templateReducer,
  schedule: scheduleReducer,

  AppState: _AppReducer,
  _UserState: _UserReducer,
  BookingState: BookingReducer,
  BookingItemState: BookingItemReducer,
  BlackoutState: BlackoutReducer,
  ReservationState: ReservationReducer,
  ReservationCategoryState: ReservationCategoryReducer,
  RestrictionState: RestrictionReducer,
  AvailabilityState: AvailabilityReducer,
  WaiverState: _WaiverReducer,
  FinancialReportState: FinancialReportReducer,
  MessageTemplateState: MessageTemplateReducer,
  ContentCategoryState: ContentCategoryReducer,
  ContentItemState: ContentItemReducer,

  router: connectRouter(history)
});

export default rootReducer;
