import React, { useState } from 'react';
// Dialogs
import MapCreateFormDialog from 'dialogs/MapCreateForm.dialog';

type IProps = {}

const StaffCreate:React.FC<IProps> = () => {
  const [show, setShow] = useState<boolean>(false);

  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);

  return (
    <>
      <div className="text-right mb-3">
        <button
          className="btn btn-primary btn-sm"
          type="button"
          onClick={handleShow}
        >Create map</button>
      </div>
      {show ? (
        <MapCreateFormDialog
          show={show}
          onHide={handleHide}
        />
      ) : null}
    </>
  )
}

export default StaffCreate;
