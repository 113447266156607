import React from 'react';
// Dialogs
import SendReceiptFormDialog from 'dialogs/SendReceiptForm.dialog';
// Utilities
import { toDateTime } from 'app/utilities/datetime.convert';

type Props = {
  // Props
  orderId: number;
  email: string | null;
  item: any;
  isLoading: boolean;
  status: string;
}

const OrderTransactionsListItem:React.FC<Props> = ({
  // Props
  orderId, email, item, isLoading, status
}) => {
  const [ showReceiptDialog, setShowReceiptDialog ] = React.useState(false);

  const toggleReceiptDialog = () => setShowReceiptDialog(prevState => !prevState);

  return (
    <React.Fragment>
      <tr>
        <td><small className="text-muted">{item.type}</small></td>
        <td>
          <span className={`badge badge-${classes(item.status)}`}>{item.status}</span>
        </td>
        <td><b>${item.amount}</b></td>
        <td><small>{item.notes || '-'}</small></td>
        <td><small className="text-muted">{toDateTime(item.createdOn)}</small></td>
        <td>
          <button
            className="btn btn-outline-primary btn-sm"
            type="button"
            onClick={toggleReceiptDialog}
          >Send receipt</button>
        </td>
      </tr>
      {showReceiptDialog ? (
        <SendReceiptFormDialog
          type="order"
          id={orderId}
          email={email}
          transactionId={item.id}
          show={showReceiptDialog}
          onHide={toggleReceiptDialog}
          isLoading={isLoading}
          status={status}
        />
      ) : null}
    </React.Fragment>
  )
}

const classes = (status:string) => {
  return status === 'completed' ? 'success' : 'danger';
}

export default OrderTransactionsListItem;
