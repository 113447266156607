import { Action } from 'redux';
import OrderStatuses from 'types/order-statuses';
import Statuses from 'types/statuses';
import DeliveryTypes from 'types/delivery-types';

export interface IOrder {
  id: number;
  createdOn: string;
  deliveryLocation: string;
  deliveryType: DeliveryTypes;
  items: Array<IOrderItem>;
  orderActivityItems: Array<IActivity>;
  orderNumber: number;
  reservationId: number;
  status: OrderStatuses;
  tipAmount: number;
  totalPrice: number;
  userData: {
    id: number;
    callingCode: string;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
  }
}
export interface IOrderFilter {
  [key:string]: string;
}

export interface IOrderItem {
  price: number;
  product: { id:number, name:string };
  quantity: number;
  tax: number;
}

export interface IActivity {
  id: number;
  productionName: string;
  status: OrderStatuses
}

export interface IOrderState {
  activity: {
    item: IActivity | null;
    isFetching: boolean;
    isLoading: boolean;
  },
  status: Statuses;
}

// Messages

// Types
export enum OrderTypes {
  FetchOrdersList = '@@order/Fetch orders list',
  FetchOrdersListSuccess = '@@order/Fetch orders list success',

  ExportOrders = '@@order/Export orders',
  ExportOrdersSuccess = '@@order/Export orders success',
  
  FetchOrderById = '@@order/Fetch order by id',
  FetchOrderByIdSuccess = '@@order/Fetch order by id success',

  UpdateOrderStatus = '@@order/Update status',
  UpdateOrderStatusSuccess = '@@order/Update status success',

  CancelOrder = '@@order/Cancel order',
  CancelOrderSuccess = '@@order/Cancel order success',

  SendOrderReceipt = '@@order/Send order receipt',
  SendOrderReceiptSuccess = '@@order/Send order receipt success',

  CheckInOrder = '@@order/Check in order',
  CheckInOrderSuccess = '@@order/Check in order success',

  FetchTransactionsList = '@@order/Fetch transactions list',
  FetchTransactionsListSuccess = '@@order/Fetch transactions list success',
  
  SetStatus = '@@order/Set status',
  SetInitialField = '@@order/Set initial field',
}

// Actions
interface FetchOrdersList extends Action<typeof OrderTypes.FetchOrdersList> {
  payload: { filter:IOrderFilter }
}
interface FetchOrdersListSuccess extends Action<typeof OrderTypes.FetchOrdersListSuccess> {
  payload: { items:Array<IOrder> }
}

interface ExportOrders extends Action<typeof OrderTypes.ExportOrders> {
  payload: { filter:IOrderFilter }
}
interface ExportOrdersSuccess extends Action<typeof OrderTypes.ExportOrdersSuccess> {}

interface FetchOrderById extends Action<typeof OrderTypes.FetchOrderById> {
  payload: { id:number }
}
interface FetchOrderByIdSuccess extends Action<typeof OrderTypes.FetchOrderByIdSuccess> {
  payload: { item:IOrder }
}

interface UpdateOrderStatus extends Action<typeof OrderTypes.UpdateOrderStatus>{
  payload: { id:number, status:OrderStatuses.ReadyForPickup }
}
interface UpdateOrderStatusSuccess extends Action<typeof OrderTypes.UpdateOrderStatusSuccess>{
  payload: { id:number, status:OrderStatuses.ReadyForPickup }
}

interface CancelOrder extends Action<typeof OrderTypes.CancelOrder>{
  payload: { id:number }
}
interface CancelOrderSuccess extends Action<typeof OrderTypes.CancelOrderSuccess> {
  payload: { id:number }
}

interface SendOrderReceipt extends Action<typeof OrderTypes.SendOrderReceipt>{
  payload: { id:number, email:string }
}
interface SendOrderReceiptSuccess extends Action<typeof OrderTypes.SendOrderReceiptSuccess>{}

interface CheckInOrder extends Action<typeof OrderTypes.CheckInOrder> {
  payload: { activityId:number, confirmationCode:string, status:string }
}
interface CheckInOrderSuccess extends Action<typeof OrderTypes.CheckInOrderSuccess> {
  payload: { item:IActivity }
}

interface FetchTransactionsList extends Action<typeof OrderTypes.FetchTransactionsList> {
  payload: { orderId:number }
}
interface FetchTransactionsListSuccess extends Action<typeof OrderTypes.FetchTransactionsListSuccess> {
  payload: { items:Array<any> }
}

interface SetStatus extends Action<typeof OrderTypes.SetStatus> {
  payload: { status:Statuses }
}
interface SetInitialField extends Action<typeof OrderTypes.SetInitialField> {
  payload: { field:keyof IOrderState }
}

export type OrderActionTypes =
  FetchOrdersList | FetchOrdersListSuccess |
  ExportOrders | ExportOrdersSuccess |
  FetchOrderById | FetchOrderByIdSuccess |
  UpdateOrderStatus | UpdateOrderStatusSuccess |
  CancelOrder | CancelOrderSuccess |
  SendOrderReceipt | SendOrderReceiptSuccess |
  CheckInOrder | CheckInOrderSuccess |
  FetchTransactionsList | FetchTransactionsListSuccess |
  SetStatus | SetInitialField
;
