import React from 'react';

type IProps = {
  data: Array<any> | null;
  isFetching: boolean;
  text?: string;
}

export const Container:React.FC<IProps> = ({
  data, isFetching, text = 'No item(s)', children
}) => {
  if ( !data || isFetching ) return (
    <div className="spinner-border text-primary" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  )
  if ( data && data.length === 0 ) return (
    <small className="d-block text-muted text-center">{text}</small>
  );
  return <>{children}</>
}
