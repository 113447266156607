import { ProductCategoryActionTypes } from './product-category.types';

const initialState = {
  categories: [],
  status: 'Initial'
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ProductCategoryActionTypes.FetchList:
      return { ...state, categories: initialState.categories, status: 'Fetching' }
    case ProductCategoryActionTypes.FetchListSuccess:
      return { ...state, categories: action.categories, status: 'Fetched' };
    // Create
    case ProductCategoryActionTypes.Create:
      return { ...state, status: 'Loading' };
    case ProductCategoryActionTypes.CreateSuccess:
      return { ...state, categories: [...state.categories, action.productCategory], status: 'Loaded' };
    // Update
    case ProductCategoryActionTypes.Update:
      return { ...state, status: 'Loading' };
    case ProductCategoryActionTypes.UpdateSuccess:
      return {
        ...state,
        categories: state.categories.map(b =>
          b.id === action.productCategory.id
          ? action.productCategory
          : b
        ),
        status: 'Loaded'
      };
    // Delete
    case ProductCategoryActionTypes.Delete:
      return state;
    case ProductCategoryActionTypes.DeleteSuccess:
      return {
        ...state,
        categories: state.categories.filter(b => b.id !== action.productCategoryId)
      };
    // Set status
    case ProductCategoryActionTypes.StatusSuccess:
      return { ...state, status: 'Success' };
    case ProductCategoryActionTypes.StatusError:
      return { ...state, status: 'Error' };
    case ProductCategoryActionTypes.StatusInitial:
      return { ...state, status: 'Initial' };
    default:
      return state;
  }
}