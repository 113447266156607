import React from 'react';
// Redux
import { connect } from 'react-redux';
import { FinancialReportActions } from 'app/store/financial-report/financial-report.actions';
import * as FinancialReportSelectors from 'app/store/financial-report/financial-report.selectors';
// Hooks
import useForm from 'app/hooks/useForm';

const validation = values => {
  const errors = {};
  if ( values.start && values.end ){
    if ( new Date(values.end).getTime() - new Date(values.start).getTime() < 0 ){
      errors['end'] = 'End date can`t be less than start date';
    }
  }
  return errors;
}

const FinancialReportFilter = ({
  // Props
  // State
  filter,
  // Dispatch
  fetchListSummary, setFilter
}) => {
  const callback = () => {
    const { start, end } = values;
    fetchListSummary(start, end);

    setFilter('start', start);
    setFilter('end', end);
  }

  const [values, errors, handleChange, handleSubmit] = useForm(callback, {
    start: filter.start,
    end: filter.end
  }, validation);

  return (
    <div className="container-sm">
      <form className="row row-8" onSubmit={handleSubmit}>
        <div className="col-12 col-md-4 col-lg-5">
          <div className="form-group">
            <label htmlFor="start">Start</label>
            <input
              className={`form-control form-control-sm ${ errors.start && 'is-invalid' }`}
              id="start"
              name="start"
              type="date"
              value={values.start}
              onChange={handleChange}
            />
            {errors.start && <div className="invalid-feedback">{errors.start}</div>}
          </div>
        </div>
        <div className="col-12 col-md-4 col-lg-5">
          <div className="form-group">
            <label htmlFor="end">End</label>
            <input
              className={`form-control form-control-sm ${ errors.end && 'is-invalid' }`}
              id="end"
              name="end"
              type="date"
              value={values.end}
              onChange={handleChange}
            />
            {errors.end && <div className="invalid-feedback">{errors.end}</div>}
          </div>
        </div>
        <div className="col-12 col-md-4 col-lg-2">
          <div className="form-group">
            <label htmlFor="" className="d-none d-md-inline-block">&nbsp;</label>
            <button
              className="btn btn-primary btn-sm btn-block"
              type="submit"
            >Filter</button>
          </div>
        </div>
      </form>
    </div>
  )
}

const mapStateToProps = ({ FinancialReportState }) => ({
  filter: FinancialReportSelectors.getFilter(FinancialReportState)
});

const mapDispatchToProps = dispatch => ({
  fetchListSummary: (start, end) => dispatch(FinancialReportActions.fetchListSummary(start, end)),
  setFilter: (filterType, filterValue) => dispatch(FinancialReportActions.setFilter(filterType, filterValue))
});

export default connect(mapStateToProps, mapDispatchToProps)(FinancialReportFilter);
