import './google-map.scss';
import React, { useState, useCallback } from 'react';
import {
  GoogleMap as Map, Marker, StandaloneSearchBox,
  useLoadScript
} from '@react-google-maps/api';
// Redux
import { connect } from 'react-redux';
import { getItem } from 'store/map/map.selectors';
import { IMap } from 'store/map/map.types';
import { IMapPoi } from 'store/map-poi/map-poi.types';
// 
import GoogleMapMarkerItem from './GoogleMapMarkerItem';

type IProps = {
  // Props
  marker?: { lat:number|undefined, lng:number|undefined },
  markers?: Array<IMapPoi>;
  containerClassName?: string;
  onClick?: (e:any) => void;
  onDragEnd?: (map:any) => void;
  onZoomChange?: (map:any) => void;
  center?: any;
  // State
  item: IMap;
}

const libraries:Array<any> = ['places'];

const GoogleMap:React.FC<IProps> = ({
  // Props
  marker, markers, containerClassName, onClick, onDragEnd, onZoomChange, center = null,
  // State
  item
}) => {
  const [ map, setMap ] = useState<any>(null);
  const [ searchBox, setSearchBox ] = useState<any>(null);
  const [ options, setOptions ] = useState<any>({
    streetViewControl: false,
    center: center || {
      lat: item?.options.lat,
      lng: item?.options.lng
    },
    zoom: item?.options.zoom,
  });

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyB0dpNffa9_OHpIxaTxkezX7gro7hLAjA8',
    version: 'quarterly', // ToDO: Will removed when google fix problem with 'apply'null
    libraries
  });

  // Map
  const handleMapLoad = useCallback((map:any) => {
    setMap(map);
    // eslint-disable-next-line
  }, []);

  const handleMapUnmount = useCallback(() => {
    setMap(null);
    // eslint-disable-next-line
  }, []);

  // SearchBox
  const handleSearchBoxLoad = (ref:any) => setSearchBox(ref);
  const handlePlacesChanged = () => {
    if ( searchBox.getPlaces() && searchBox.getPlaces().length > 0 ){
      const place:any = searchBox.getPlaces()[0];
      const { geometry: { location }, types } = place;
      map.setCenter(location);
      let zoomLevel = 10;
      if ( types.indexOf('route') !== -1 ) zoomLevel = 15;
      if ( types.indexOf('premise') !== -1 || types.indexOf('point_of_interest') !== -1 || types.indexOf('establishment') !== -1 ) zoomLevel = 18;
      map.setZoom(zoomLevel);
    }
  }

  // Events
  const handleClick = (e:any) => {
    if ( onClick ) onClick(e);
  }
  const handleDragEnd = () => {
    if ( map && onDragEnd ) onDragEnd(map);
  }
  const handleCurrentLocation = () => {
    if ( navigator.geolocation ) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          map.setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          map.setZoom(18);
        },
        () => {
          console.error('Error: The Geolocation service failed.')
        }
      );
    } else {
      console.error('Error: Your browser doesn`t support geolocation.')
    }
  }

  const handleZoomChange = () => {
    if ( map && onZoomChange ) onZoomChange(map);
  }

  const renderMap = () => {
    return (
      <Map
        mapContainerClassName={containerClassName}
        options={options}
        onLoad={handleMapLoad}
        onUnmount={handleMapUnmount}
        onClick={handleClick}
        onDragEnd={handleDragEnd}
        onZoomChanged={handleZoomChange}
      >
        <StandaloneSearchBox
          onLoad={handleSearchBoxLoad}
          onPlacesChanged={handlePlacesChanged}
        >
          <input
            className="control-search"
            id="details-search"
            type="text"
            placeholder="Search location"
          />
        </StandaloneSearchBox>
        {markers && markers.length > 0 ? (
          markers.map((item:IMapPoi, index:number) => (
            <GoogleMapMarkerItem
              key={`map-marker-item-${index}`}
              item={item}
            />
          ))
        ) : null}
        {(marker && marker.lat && marker.lng) ? (
          <Marker position={marker} />
        ) : null}
        <button
          className="button-location"
          onClick={handleCurrentLocation}
        ><i className="fa fa-dot-circle"></i></button>
      </Map>
    )
  }

  if ( loadError ) return <div>Something went wrong</div>;
  return isLoaded ? renderMap() : <h1>Loading ...</h1>;
}

const mapStateToProps = (state:any) => ({
  item: getItem(state),
});

export default connect(mapStateToProps)(GoogleMap)
