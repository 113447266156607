import { call, put, takeLatest } from 'redux-saga/effects';
// Types
import { SettingTypes } from './setting.types';
// Actions
import SettingActions from './setting.actions';
import RemotelockDeviceActions from 'store/remotelock-device/remotelock-device.actions';
// API
import SettingAPI from './setting.api';

export default function* (){
  yield takeLatest(SettingTypes.FetchAccountSettings, _fetchAccountSettings);
}

function* _fetchAccountSettings(){
  try {
    const { parseBody:settings } = yield call(SettingAPI.fetchAccountSettings);
    yield put(SettingActions.fetchAccountSettingsSuccess(settings));
    if ( settings.remotelockIntegrationEnabled ){
      yield put(RemotelockDeviceActions.fetchRemotelockDevices());
    }
  } catch(error:any){
    console.error(error);
  }
}
