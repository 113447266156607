import dayjs from 'dayjs';

export const months = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December'
};

export const BOOKING_TYPES = {
  hotel: 'Hotel',
  rentals: 'Rentals',
  rv_sites: 'RV Site'
};

export const BOOKING_PERIOD = {
  day: 'Daily',
  season: 'Seasonal',
  hour: 'Hourly'
}

export const AppColors = [
  '#8bc34a',
  '#ff5722',
  '#e91e63',
  '#ffc107',
  '#3f51b5',
  '#2196f3',
  '#9c27b0',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#f44336',
  '#cddc39',
  '#ffeb3b',
  '#03a9f4',
  '#ff9800',
  '#795548',
  '#9e9e9e',
  '#673ab7',
  '#607d8b'
];

export const UserRoleLabels = {
  accountManager: 'Account Manager',
  admin: 'Admin',
  camper: 'Camper',
  orderManager: 'Order Manager'
}

export const AppDays = () => {
  return Array.apply(0, Array(7)).map((_, i) => ({
    value: i,
    shortLabels: dayjs().day(i).format('ddd'),
    label: dayjs().day(i).format('dddd')
  }))
}

export const AppMonths = () => {
  return Array.apply(0, Array(12)).map((_,i) => ({
    value: i,
    label: dayjs().month(i).format('MMMM')
  }));
}

export const AppYears = () => {
  let years = [];
  // const currentYear = dayjs().format('YYYY');
  let minYear = '1980';
  let maxYear = '2100';
  while ( minYear <= maxYear ) {
    years.push({
      value: minYear.toString(),
      label: minYear.toString()
    });
    minYear++;
  }
  return years;
}

export const UserRoles = [
  { value: 'admin', label: 'Admin' },
  { value: 'orderManager', label: 'Order manager' }
];

export const ContentItemTypes = [
  { value: 'exploreIsland', label: 'Explore island' },
  { value: 'islandRule', label: 'Island rule' }
];

export const locations = [
  { value: 'LL05', label: 'LL05' },
  { value: 'LL06', label: 'LL06' },
  { value: 'LL07', label: 'LL07' },
  { value: 'O01', label: 'O01' },
  { value: 'O02', label: 'O02' },
  { value: 'O03', label: 'O03' },
  { value: 'O04', label: 'O04' },
  { value: 'O05', label: 'O05' },
  { value: 'O06', label: 'O06' },
  { value: 'O07', label: 'O07' },
  { value: 'O08', label: 'O08' },
  { value: 'O09', label: 'O09' },
  { value: 'O10', label: 'O10' },
  { value: 'O11', label: 'O11' },
  { value: 'O12', label: 'O12' },
  { value: 'O13', label: 'O13' },
  { value: 'O14', label: 'O14' },
  { value: 'O15', label: 'O15' },
  { value: 'O16', label: 'O16' },
  { value: 'O17', label: 'O17' },
  { value: 'O18', label: 'O18' },
  { value: 'O19', label: 'O19' },
  { value: 'O20', label: 'O20' },
  { value: 'O21', label: 'O21' },
  { value: 'O22', label: 'O22' },
  { value: 'O23', label: 'O23' },
  { value: 'O24', label: 'O24' },
  { value: 'O45', label: 'O45' },
  { value: 'O44', label: 'O44' },
  { value: 'O43', label: 'O43' },
  { value: 'O42', label: 'O42' },
  { value: 'O41', label: 'O41' },
  { value: 'O40', label: 'O40' },
  { value: 'O39', label: 'O39' },
  { value: 'O38', label: 'O38' },
  { value: 'O37', label: 'O37' },
  { value: 'O36', label: 'O36' },
  { value: 'O35', label: 'O35' },
  { value: 'O34', label: 'O34' },
  { value: 'O33', label: 'O33' },
  { value: 'O32', label: 'O32' },
  { value: 'O31', label: 'O31' },
  { value: 'O30', label: 'O30' },
  { value: 'O29', label: 'O29' },
  { value: 'O28', label: 'O28' },
  { value: 'O27', label: 'O27' },
  { value: 'O26', label: 'O26' },
  { value: 'O25', label: 'O25' },
  { value: 'H01', label: 'H01' },
  { value: 'H02', label: 'H02' },
  { value: 'H03', label: 'H03' },
  { value: 'H04', label: 'H04' },
  { value: 'H05', label: 'H05' },
  { value: 'H06', label: 'H06' },
  { value: 'H07', label: 'H07' },
  { value: 'H08', label: 'H08' },
  { value: 'H09', label: 'H09' },
  { value: 'H10', label: 'H10' },
  { value: 'H11', label: 'H11' },
  { value: 'H12', label: 'H12' },
  { value: 'H13', label: 'H13' },
  { value: 'H14', label: 'H14' },
  { value: 'H15', label: 'H15' },
  { value: 'H16', label: 'H16' },
  { value: 'H17', label: 'H17' },
  { value: 'H18', label: 'H18' },
  { value: 'H19', label: 'H19' },
  { value: 'H20', label: 'H20' },
  { value: 'H21', label: 'H21' },
  { value: 'H22', label: 'H22' },
  { value: 'H23', label: 'H23' },
  { value: 'H24', label: 'H24' },
  { value: 'H25', label: 'H25' },
  { value: 'H26', label: 'H26' },
  { value: 'H27', label: 'H27' },
  { value: 'H28', label: 'H28' },
  { value: 'H29', label: 'H29' },
  { value: 'H30', label: 'H30' },
  { value: 'H31', label: 'H31' },
  { value: 'H32', label: 'H32' },
  { value: 'H33', label: 'H33' },
  { value: 'H34', label: 'H34' },
  { value: 'H35', label: 'H35' },
  { value: 'H36', label: 'H36' },
  { value: 'H37', label: 'H37' },
  { value: 'H38', label: 'H38' },
  { value: 'H39', label: 'H39' },
  { value: 'H40', label: 'H40' },
  { value: 'H41', label: 'H41' },
  { value: 'H42', label: 'H42' },
  { value: 'H43', label: 'H43' },
  { value: 'H44', label: 'H44' },
  { value: 'H45', label: 'H45' },
  { value: 'H46', label: 'H46' },
  { value: 'H47', label: 'H47' },
  { value: 'H48', label: 'H48' },
  { value: 'H49', label: 'H49' },
  { value: 'H50', label: 'H50' },
  { value: 'H51', label: 'H51' },
  { value: 'H52', label: 'H52' },
  { value: 'H53', label: 'H53' },
  { value: 'H54', label: 'H54' },
  { value: 'H55', label: 'H55' },
  { value: 'H56', label: 'H56' },
  { value: 'H57', label: 'H57' },
  { value: 'H58', label: 'H58' },
  { value: 'H59', label: 'H59' },
  { value: 'H60', label: 'H60' },
  { value: 'H61', label: 'H61' },
  { value: 'H62', label: 'H62' },
  { value: 'H63', label: 'H63' },
  { value: 'H64', label: 'H64' },
  { value: 'H65', label: 'H65' },
  { value: 'H66', label: 'H66' },
  { value: 'H67', label: 'H67' },
  { value: 'H68', label: 'H68' },
  { value: 'H69', label: 'H69' },
  { value: 'T01', label: 'T01' },
  { value: 'T02', label: 'T02' },
  { value: 'T03', label: 'T03' },
  { value: 'M01', label: 'M01' },
  { value: 'M02', label: 'M02' },
  { value: 'M03', label: 'M03' },
  { value: 'M04', label: 'M04' },
  { value: 'M05', label: 'M05' },
  { value: 'M06', label: 'M06' },
  { value: 'M07', label: 'M07' },
  { value: 'M08', label: 'M08' },
  { value: 'M09', label: 'M09' },
  { value: 'M10', label: 'M10' },
  { value: 'M11', label: 'M11' },
  { value: 'M12', label: 'M12' },
  { value: 'M13', label: 'M13' },
  { value: 'M14', label: 'M14' },
  { value: 'M15', label: 'M15' },
  { value: 'M16', label: 'M16' },
  { value: 'M17', label: 'M17' },
  { value: 'M18', label: 'M18' },
  { value: 'M19', label: 'M19' },
  { value: 'M20', label: 'M20' },
  { value: 'M21', label: 'M21' },
  { value: 'M22', label: 'M22' },
  { value: 'M23', label: 'M23' },
  { value: 'M24', label: 'M24' },
  { value: 'M25', label: 'M25' },
  { value: 'M26', label: 'M26' },
  { value: 'M27', label: 'M27' },
  { value: 'M28', label: 'M28' },
  { value: 'M29', label: 'M29' },
  { value: 'M30', label: 'M30' },
  { value: 'M31', label: 'M31' },
  { value: 'M32', label: 'M32' },
  { value: 'M33', label: 'M33' },
  { value: 'M34', label: 'M34' },
  { value: 'M35', label: 'M35' },
  { value: 'M36', label: 'M36' },
  { value: 'B01', label: 'B01' },
  { value: 'B02', label: 'B02' },
  { value: 'B03', label: 'B03' },
  { value: 'B04', label: 'B04' },
  { value: 'B05', label: 'B05' },
  { value: 'B06', label: 'B06' },
  { value: 'B07', label: 'B07' },
  { value: 'B08', label: 'B08' },
  { value: 'B09', label: 'B09' },
  { value: 'B10', label: 'B10' },
  { value: 'B11', label: 'B11' },
  { value: 'B12', label: 'B12' },
  { value: 'B13', label: 'B13' },
  { value: 'B14', label: 'B14' },
  { value: 'B15', label: 'B15' },
  { value: 'B16', label: 'B16' },
  { value: 'B17', label: 'B17' },
  { value: 'B18', label: 'B18' },
  { value: 'B19', label: 'B19' },
  { value: 'B20', label: 'B20' },
  { value: 'B21', label: 'B21' },
  { value: 'B22', label: 'B22' },
  { value: 'B23', label: 'B23' },
  { value: 'B24', label: 'B24' },
  { value: 'B25', label: 'B25' },
  { value: 'L01', label: 'L01' },
  { value: 'L02', label: 'L02' },
  { value: 'L03', label: 'L03' },
  { value: 'L04', label: 'L04' },
  { value: 'L05', label: 'L05' },
  { value: 'L06', label: 'L06' },
  { value: 'L07', label: 'L07' },
  { value: 'L08', label: 'L08' },
  { value: 'L09', label: 'L09' },
  { value: 'L10', label: 'L10' },
  { value: 'L11', label: 'L11' },
  { value: 'L12', label: 'L12' },
  { value: 'L13', label: 'L13' },
  { value: 'L14', label: 'L14' },
  { value: 'L15', label: 'L15' },
  { value: 'L16', label: 'L16' },
  { value: 'L17', label: 'L17' },
  { value: 'L18', label: 'L18' },
  { value: 'L19', label: 'L19' },
  { value: 'L20', label: 'L20' },
  { value: 'L21', label: 'L21' },
  { value: 'L22', label: 'L22' },
  { value: 'L23', label: 'L23' },
  { value: 'L24', label: 'L24' },
  { value: 'L25', label: 'L25' },
  { value: 'L26', label: 'L26' },
  { value: 'L27', label: 'L27' },
  { value: 'L28', label: 'L28' },
  { value: 'L29', label: 'L29' },
  { value: 'L30', label: 'L30' },
  { value: 'L31', label: 'L31' },
  { value: 'L32', label: 'L32' },
  { value: 'L33', label: 'L33' },
  { value: 'L34', label: 'L34' },
  { value: 'L35', label: 'L35' },
  { value: 'L36', label: 'L36' },
  { value: 'L37', label: 'L37' },
  { value: 'L38', label: 'L38' },
  { value: 'L39', label: 'L39' },
  { value: 'L40', label: 'L40' },
  { value: 'L41', label: 'L41' },
  { value: 'L42', label: 'L42' },
  { value: 'L43', label: 'L43' },
  { value: 'L44', label: 'L44' },
  { value: 'L45', label: 'L45' },
  { value: 'L46', label: 'L46' },
  { value: 'L47', label: 'L47' },
  { value: 'L48', label: 'L48' },
  { value: 'E01', label: 'E01' },
  { value: 'E02', label: 'E02' },
  { value: 'E03', label: 'E03' },
  { value: 'E04', label: 'E04' },
  { value: 'E05', label: 'E05' },
  { value: 'E06', label: 'E06' },
  { value: 'E07', label: 'E07' },
  { value: 'E08', label: 'E08' },
  { value: 'E09', label: 'E09' },
  { value: 'E10', label: 'E10' },
  { value: 'E11', label: 'E11' },
  { value: 'E12', label: 'E12' },
  { value: 'E13', label: 'E13' },
  { value: 'E14', label: 'E14' },
  { value: 'E15', label: 'E15' },
  { value: 'E16', label: 'E16' },
  { value: 'E17', label: 'E17' },
  { value: 'E18', label: 'E18' },
  { value: 'E19', label: 'E19' },
  { value: 'E20', label: 'E20' },
  { value: 'E21', label: 'E21' },
  { value: 'E22', label: 'E22' },
  { value: 'E23', label: 'E23' },
  { value: 'E24', label: 'E24' },
  { value: 'E25', label: 'E25' },
  { value: 'E26', label: 'E26' },
  { value: 'E27', label: 'E27' },
  { value: 'E28', label: 'E28' },
  { value: 'E29', label: 'E29' },
  { value: 'E30', label: 'E30' },
  { value: 'E31', label: 'E31' },
  { value: 'E32', label: 'E32' },
  { value: 'E33', label: 'E33' },
  { value: 'E34', label: 'E34' },
  { value: 'E35', label: 'E35' },
  { value: 'E36', label: 'E36' },
  { value: 'E37', label: 'E37' },
  { value: 'E38', label: 'E38' },
  { value: 'E39', label: 'E39' },
  { value: 'E40', label: 'E40' },
  { value: 'E41', label: 'E41' },
  { value: 'E42', label: 'E42' },
  { value: 'E43', label: 'E43' },
  { value: 'E44', label: 'E44' },
  { value: 'E45', label: 'E45' },
  { value: 'E46', label: 'E46' },
  { value: 'E47', label: 'E47' },
  { value: 'Y01', label: 'Y01' },
  { value: 'Y02', label: 'Y02' },
  { value: 'Y03', label: 'Y03' },
  { value: 'Y04', label: 'Y04' },
  { value: 'Y05', label: 'Y05' },
  { value: 'Y06', label: 'Y06' },
  { value: 'Y07', label: 'Y07' },
  { value: 'Y08', label: 'Y08' },
  { value: 'Y09', label: 'Y09' },
  { value: 'Y10', label: 'Y10' },
  { value: 'Y11', label: 'Y11' },
  { value: 'Y12', label: 'Y12' },
  { value: 'G01', label: 'G01' },
  { value: 'G02', label: 'G02' },
  { value: 'G03', label: 'G03' },
  { value: 'G04', label: 'G04' },
  { value: 'G05', label: 'G05' },
  { value: 'G06', label: 'G06' },
  { value: 'G07', label: 'G07' },
  { value: 'C1', label: 'C1' },
  { value: 'C2', label: 'C2' },
  { value: 'C3', label: 'C3' },
  { value: 'C4', label: 'C4' },
  { value: 'C5', label: 'C5' },
  { value: 'LAUNDRY', label: 'LAUNDRY' },
  { value: 'RESTROOM', label: 'RESTROOM' },
  { value: 'BATHROOM', label: 'BATHROOM' },
  { value: 'SHOWER', label: 'SHOWER' },
  { value: 'OFFICE', label: 'OFFICE' },
  { value: 'STORE', label: 'STORE' },
  { value: 'POOL', label: 'POOL' },
  { value: 'PARKING', label: 'PARKING' },
  { value: 'DUMPSTER', label: 'DUMPSTER' },
  { value: 'BOATHOUSE_1922', label: 'BOATHOUSE_1922' },
  { value: 'CAUSEWAY_WASHOUT_1970', label: 'CAUSEWAY_WASHOUT_1970' },
  { value: 'CLUBHOUSE_1940', label: 'CLUBHOUSE_1940' },
  { value: 'FISHING_TROFIES_1954', label: 'FISHING_TROFIES_1954' },
  { value: 'GE_CEREMONY_FLAG_POLE_1950', label: 'GE_CEREMONY_FLAG_POLE_1950' },
  { value: 'GE_MARCHING_BAND_1930', label: 'GE_MARCHING_BAND_1930' },
  { value: 'HOSPITAL', label: 'HOSPITAL' },
  { value: 'ISLANDER2_1956', label: 'ISLANDER2_1956' },
  { value: 'ISLAND_HOUSE_1930', label: 'ISLAND_HOUSE_1930' },
  { value: 'SHUFFLE_BOARD_1950', label: 'SHUFFLE_BOARD_1950' },
  { value: 'WWII_PLAQUE', label: 'WWII_PLAQUE' },
  { value: 'YMCA_AUDITORIUM', label: 'YMCA_AUDITORIUM' }
];