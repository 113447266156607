import HttpUtility from 'utilities/http.utility';
import { IOrderFilter } from './order.types';

export default class OrderService {
  private static _url:string = '/admin/store/orders';
  private static _http:HttpUtility = new HttpUtility();
  // Fetch list
  public static async fetchList(filter:IOrderFilter):Promise<any> {
    const query = new URLSearchParams();
    Object.keys(filter).forEach((key:string) => {
      if ( filter[key] ) query.append(key, filter[key]);
    });
    return OrderService._http.get(`${OrderService._url}?${query}`);
  };
  // Fetch by id
  public static async fetchById(id:number):Promise<any> {

  };
  // Cancel
  public static async cancel():Promise<any> {

  };
  // Send receipt
  public static async sendReceipt():Promise<any> {

  };
  // Update status
  public static async updateStatus():Promise<any> {

  };
  // Check-in
  public static async checkIn(activityId:number, confirmationCode:string, status:string):Promise<any> {
    return OrderService._http.put(`${OrderService._url}/activities/${activityId}/status`, {
      confirmationCode,
      status
    });
  };
  // Export
  public static async export():Promise<any> {

  };
}