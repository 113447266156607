import { call, put, takeLatest } from 'redux-saga/effects';
// Types
import Statuses from 'types/statuses';
import { RemotelockDeviceTypes } from './remotelock-device.types';
// Api
import RemotelockDeviceAPI from './remotelock-device.api';
// Actions
import * as LayoutActions from 'app/actions/layout.actions';
import RemotelockDeviceActions from './remotelock-device.actions';

export default function* (){
  yield takeLatest(RemotelockDeviceTypes.FetchRemotelockDevices, _fetchRemotelockDevices);
  yield takeLatest(RemotelockDeviceTypes.FetchRemotelockDeviceAudits, _fetchRemotelockDeviceAudits);
  yield takeLatest(RemotelockDeviceTypes.UnlockDevice, _unlockDevice);
}

function* _fetchRemotelockDevices(){
  try {
    const { parseBody:remotelockDevices } = yield call(RemotelockDeviceAPI.fetchRemotelockDevices);
    yield put(RemotelockDeviceActions.fetchRemotelockDevicesSuccess(remotelockDevices));
  } catch(error:any){
    console.log(error);
    yield put(RemotelockDeviceActions.setStatus(Statuses.Error));
  }
}

function* _fetchRemotelockDeviceAudits(action:any){
  try {
    const { deviceId, params } = action.payload;
    const { parseBody } = yield call(RemotelockDeviceAPI.fetchRemotelockDeviceAudits, deviceId, params);
    yield put(RemotelockDeviceActions.fetchRemotelockDeviceAuditsSuccess(parseBody.data || [], parseBody.total));
  } catch(error:any){
    console.log(error);
    yield put(RemotelockDeviceActions.setStatus(Statuses.Error));
  }
}

function* _unlockDevice(action:any){
  try {
    const { deviceId } = action.payload;
    yield call(RemotelockDeviceAPI.unlockDevice, deviceId);
    yield put(RemotelockDeviceActions.unlockDeviceSuccess());
    yield put(RemotelockDeviceActions.setStatus(Statuses.Success));
    yield put(RemotelockDeviceActions.setStatus(Statuses.Initial));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: 'Unlock request sent. Click on any keypad button on door lock, to activate it.'
    }));
  } catch(error:any){
    console.log(error);
    yield put(RemotelockDeviceActions.setStatus(Statuses.Error));
    if ( error.message ){
      yield put(LayoutActions.addNotification({
        type: 'danger',
        message: error.message
      }));
    }
  }
}
