import PropTypes from 'prop-types';
import React, { Component } from 'react';
// Store
import { connect } from 'react-redux';
import {
  createTemplate,
  updateTemplate
} from '../../../actions/template.actions';
// React bootstrap
import { Modal } from 'react-bootstrap';
// Components
import { DataLoading } from '../../../components/Loadings';
import { ButtonLoading } from '../../../components/Buttons';
// 
import { formControlsValue, formControlsOptions } from './FormControls';
import FormFields from './FormFields';

class TemplatesFormModal extends Component {
  constructor(props){
    super(props);

    const { selectedTemplate } = this.props;
    const hasEmail = typeof selectedTemplate.email !== 'undefined' ? true : false;
    const hasSMS = typeof selectedTemplate.sms !== 'undefined' ? true : false;
    const hasInApp = typeof selectedTemplate.inApp !== 'undefined' ? true : false;

    this.state = {
      isFormSubmitted: false,
      formControlsValue,
      formControlsOptions,
  
      isEmailVisible: selectedTemplate && selectedTemplate.id ? hasEmail : true,
      isSMSVisible: selectedTemplate && selectedTemplate.id ? hasSMS : true,
      isInAppVisible: selectedTemplate && selectedTemplate.id ? hasInApp : true,
    };

  }

  componentDidUpdate(newProps){
    const { isFormSuccess, onHide } = this.props;
    if ( isFormSuccess ) onHide();
  }

  componentDidMount(){
    const { selectedTemplate } = this.props;

    if ( selectedTemplate && selectedTemplate.id ){
      this.setState(prevState => {
        const valueState = {
          name: selectedTemplate.name,
          deliverySchedule: selectedTemplate.deliverySchedule,
          deliveryHour: selectedTemplate.deliveryHour,
          smsText: selectedTemplate.sms ? selectedTemplate.sms.text : '',
          emailSubject: selectedTemplate.email ? selectedTemplate.email.subject : '',
          emailBody: selectedTemplate.email ? selectedTemplate.email.body : '',
          inAppText: selectedTemplate.inApp ? selectedTemplate.inApp.text : ''
        };
        if ( typeof selectedTemplate.deliveryDaysSpan !== 'undefined' ){
          valueState['deliveryType'] =
            selectedTemplate.deliveryDaysSpan !== 0
              ? selectedTemplate.deliveryDaysSpan > 0
                ? 'after'
                : 'before'
              : 0
          ;
          valueState['deliveryDaysSpan'] =
            selectedTemplate.deliveryDaysSpan < 0
            ? selectedTemplate.deliveryDaysSpan * -1
            : selectedTemplate.deliveryDaysSpan
          ;
        }
        return {
          formControlsValue: {
            ...prevState.formControlsValue,
            ...valueState
          }
        }
      });
    }
  }

  handleChange = evt => {
    const { target: { name, value } } = evt;
    this.setState(prevState => ({
      formControlsValue: {
        ...prevState.formControlsValue,
        [name]: value
      }
    }));
  }

  handleChangeVisible = ({ target: { name, checked } }) => {
    this.setState({
      [name]: checked
    });
  }

  handleSubmit = evt => {
    evt.preventDefault();

    this.setState({
      isFormSubmitted: true
    });

    if ( !this.refForm.checkValidity() ) return;

    const {
      formControlsValue: {
        name, deliverySchedule, deliveryType, deliveryDaysSpan, deliveryHour, smsText, emailSubject, emailBody, inAppText
    }, isSMSVisible, isEmailVisible, isInAppVisible } = this.state;

    if ( !isSMSVisible && !isEmailVisible && !isInAppVisible ) return;

    const { selectedTemplate } = this.props;

    let template = {
      name,
      deliverySchedule,
      deliveryHour: parseInt(deliveryHour, 10)
    };

    if ( isSMSVisible ){
      template = { ...template, sms: { text: smsText } };
    } else {
      if ( template.hasOwnProperty('sms') ){
        delete template['sms'];
      }
    }
    if ( isEmailVisible ){
      template = { ...template, email: { subject: emailSubject, body: emailBody } };
    } else {
      if ( template.hasOwnProperty('sms') ){
        delete template['inApp'];
      }
    }
    if ( isInAppVisible ){
      template = { ...template, inApp: { text: inAppText } };
    } else {
      if ( template.hasOwnProperty('inApp') ){
        delete template['inApp'];
      }
    }

    if (
      deliverySchedule === 'arrival' ||
      deliverySchedule === 'departure'
    ){
      template['deliveryDaysSpan'] =
        deliveryType !== 'sameDay'
        ? 
          deliveryType === 'before'
          ? -(parseInt(deliveryDaysSpan, 10))
          : parseInt(deliveryDaysSpan, 10)
        : 0
      ;
    }

    if ( selectedTemplate && selectedTemplate.id ){
      this.props.updateTemplate({id: selectedTemplate.id, ...template});
    } else {
      this.props.createTemplate(template);
    }
  }

  render() {
    const { isFormSubmitted, formControlsValue, formControlsOptions, isEmailVisible, isSMSVisible, isInAppVisible } = this.state;
    const {
      show, onHide,
      selectedTemplate, templatesVariables, isModalFetching, isFormLoading
    } = this.props;
    return (
      <Modal
        size="lg"
        show={show}
        onHide={onHide}
        enforceFocus={false}
      >
        <form
          noValidate
          name="form"
          ref={ref => this.refForm = ref}
          onSubmit={this.handleSubmit}
        >
          <Modal.Header closeButton>
            {selectedTemplate && selectedTemplate.id
              ? <Modal.Title>Update template</Modal.Title>
              : <Modal.Title>Create template</Modal.Title>
            }
          </Modal.Header>
          <Modal.Body>
            {isModalFetching && <DataLoading />}
            {!isModalFetching && 
              <FormFields
                templatesVariables={templatesVariables}

                isEmailVisible={isEmailVisible}
                isSMSVisible={isSMSVisible}
                isInAppVisible={isInAppVisible}

                submitted={isFormSubmitted}
                formControlsValue={formControlsValue}
                formControlsOptions={formControlsOptions}
                onChange={this.handleChange}
                onChangeVisible={this.handleChangeVisible}
              />
            }
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-outline-secondary btn-sm"
              type="button"
              onClick={onHide}
            >Cancel</button>
            <ButtonLoading
              type="submit"
              loading={isFormLoading}
              disabled={(!isEmailVisible && !isSMSVisible && !isInAppVisible) && true}
            >Save</ButtonLoading>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

TemplatesFormModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  templatesVariables: state.template.templatesVariables,
  selectedTemplate: state.template.selectedTemplate,

  isModalFetching: state.template.isModalFetching,

  isFormLoading: state.template.isFormLoading,
  isFormSuccess: state.template.isFormSuccess,
});

const mapDispatchToProps = dispatch => ({
  createTemplate: data => dispatch(createTemplate(data)),
  updateTemplate: data => dispatch(updateTemplate(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplatesFormModal);
