import create from './create-type';

const _type = 'notification';

const _actions = [
  'FETCH_ALL',
  'SEND',
  'SEND_BULK'
];

const Types = {};

for ( let i in _actions ){
  Types[_actions[i]] = create(_type, [_actions[i]]);
  Types[`${_actions[i]}_SUCCESS`] = create(_type, `${[_actions[i]]}_SUCCESS`);
  Types[`${_actions[i]}_FAILURE`] = create(_type, `${[_actions[i]]}_FAILURE`);
}

Types['SET_INITIAL_STATE'] = create(_type, 'SET_INITIAL_STATE');
Types['SET_IS_FETCHED'] = create(_type, 'SET_IS_FETCHED');

export default Types;
