import React from 'react';

export { default as AppLoading } from './AppLoading';
export { default as DataLoading } from './DataLoading';

export const ContentLoading = props => {
  const { data, status, children } = props;
  if ( status === 'Fetching' ) return (
    <div className="text-center | pt-3 pb-3">
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )
  return (
    <>
      {data.length !== 0
        ? children
        :
          <h6 className="text-muted text-center">Nothing found</h6>
      }
    </>
  )
}
