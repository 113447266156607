import { UserActionTypes } from './user.types';

const initialState = {
  currentUser: {},

  campers: [],
  users: [],
  user: {},

  isFetching: false,
  isLoading: false,
  status: 'Initial'
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UserActionTypes.ForgotPassword:
    case UserActionTypes.UpdatePassword:
    // case UserActionTypes.UpdateMe:
    case UserActionTypes.Create:
    case UserActionTypes.Update:
      return { ...state, status: 'Loading' };
    case UserActionTypes.ForgotPasswordSuccess:
    case UserActionTypes.UpdatePasswordSuccess:
    // case UserActionTypes.UpdateMeSuccess:
    case UserActionTypes.CreateSuccess:
    case UserActionTypes.UpdateSuccess:
      return { ...state, status: 'Loaded' };

    case UserActionTypes.FetchMe:
      return { ...state, currentUser: initialState.currentUser };
    case UserActionTypes.FetchMeSuccess:
      return { ...state, currentUser: action.currentUser };

    case UserActionTypes.UpdateMe:
      return { ...state, isLoading: true };
    case UserActionTypes.UpdateMeSuccess:
      return { ...state, currentUser: action.currentUser, isLoading: false };

    case UserActionTypes.FetchById:
      return { ...state, user: initialState.user };
    case UserActionTypes.FetchByIdSuccess:
      return { ...state, user: action.user };

    case UserActionTypes.FetchListCampers:
      return { ...state, campers: initialState.campers, status: 'Fetching' };
    case UserActionTypes.FetchListCampersSuccess:
      return { ...state, campers: action.campers, status: 'Fetched' };

    case UserActionTypes.FetchList:
      return { ...state, users: initialState.users, status: 'Fetching' };
    case UserActionTypes.FetchListSuccess:
      return { ...state, users: action.users, status: 'Fetched' };

    case UserActionTypes.Delete:
    case UserActionTypes.Enable:
    case UserActionTypes.Disable:
      return state;
    case UserActionTypes.DeleteSuccess:
      return {
        ...state,
        users: state.users.filter(u => u.id !== action.userId)
      };
    case UserActionTypes.EnableSuccess:
      return {
        ...state,
        users: state.users.map(
          u => u.id === action.userId ? { ...u, enabled: true } : u
        )
      };
    case UserActionTypes.DisableSuccess:
      return {
        ...state,
        users: state.users.map(
          u => u.id === action.userId ? { ...u, enabled: false } : u
        )
      };
  
    // UpdateCamper: '[User] Update camper',
    // UpdateCamperSuccess: '[User] Update camper success',
  
    // CreateDeviceToken: '[User] Create device token',
    // CreateDeviceTokenSuccess: '[User] Create device token success',
  
    // DeleteDeviceToken: '[User] Delete device token',
    // DeleteDeviceTokenSuccess: '[User] Delete device token success',

    case UserActionTypes.StatusSuccess:
      return { ...state, status: 'Success' };
    case UserActionTypes.StatusError:
      return { ...state, status: 'Error' };
    case UserActionTypes.StatusInitial:
      return { ...state, status: 'Initial' };
    default:
      return state;
  }
}
