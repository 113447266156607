import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
// Store
import { connect } from 'react-redux';
import { ContactMessageActions } from 'app/store/contact-message/contact-message.actions';
import { getConversation } from 'app/store/contact-message/contact-message.selectors';
// Components
import { ContentLoading } from 'app/components/Loadings';
import { ButtonLoading } from 'app/components/Buttons';
// Utilities
import { parseUTCDateTime, parseDate, parseUTCTime } from 'app/utilities/datetime.convert';
// 
import ConversationForm from './ConversationForm';

const parseQueryByName = (search, name) => {
  if ( !search ) return '';
  return new URLSearchParams(search).get(name);
}

let timeout = null;

const ContactMessages = ({
  // Props
  // State
  messages, messageTotal, conversation, params, status, isLoading,
  // Dispatch
  fetchList, fetchListConversation, setInitialState
}) => {
  const {search} = useLocation();
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    fetchList(params);
    return () => setInitialState();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const nextUserId = parseQueryByName(search, 'userId');
    if ( nextUserId ){
      setUserId(Number(nextUserId));
      fetchListConversation(nextUserId);
    }
    // eslint-disable-next-line
  }, [search]);

  const handleClickLoadMore = () => {
    const {limit, offset} = params;
    fetchList({...params, offset: offset + limit}, true);
  }

  const handleChange = event => {
    const { target: { value } } = event;
    if ( timeout ) clearTimeout(timeout);
    timeout = setTimeout(() => {
      fetchList({ ...params, offset: 0, username: value });
    }, 1000);
  }

  return (
    <>
      <div className="area area-orders">
        <div className="container-fluid">

          <div className="chat">
            <div className="chat-users">
              <div className="chat-users-top">
                <div className="form-group mb-0">
                  <input
                    className="form-control form-control-sm"
                    id="username" name="username"
                    placeholder="Search by name"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="chat-users-middle">
                <ContentLoading data={messages} status={status}>
                  <ul className="list-group">
                    {messages.map((message, index) => (
                      <Link
                        key={index}
                        className={
                          `
                            list-group-item list-group-item-action
                            ${message.status === 'unread' ? 'badge-gray-300' : ''}
                            ${userId === message.userData.id ? 'active' : ''}
                          `
                        }
                        to={`?messageId=${message.id}&userId=${message.userData.id}`}
                      >
                        <h6 className="mb-0">{message.userData.firstName}</h6>
                        <small style={{fontSize: '10px'}}>{parseUTCDateTime(message.createdOn)}</small>
                        <small
                          className={`
                            w-100 d-inline-block text-truncate
                            ${userId === message.userData.id ? '' : 'text-muted'}
                          `}
                        >{message.message}</small>
                      </Link>
                    ))}
                  </ul>
                  {(messageTotal !== messages.length) &&
                    <div className="pt-3 text-center">
                      <ButtonLoading
                        loading={status === 'FetchingMore'}
                        onClick={handleClickLoadMore}
                      >Load more</ButtonLoading>
                    </div>
                  }
                </ContentLoading>
              </div>
            </div>
            <div className="chat-conversation">
              {userId ? (
                <>
                  <div className="chat-conversation-top">
                    <ConversationForm />
                  </div>
                  <div className="chat-conversation-middle">
                    <ContentLoading data={conversation} status={isLoading ? 'Fetching' : 'Fetched'}>
                      <div className="conversation">

                        {Object.keys(conversation).map((key, index) => (
                          <React.Fragment key={index}>
                            <span className="conversation-divider">
                              <span>{parseDate(key)}</span>
                            </span>
                            {conversation[key].map((c, i) => (
                              <div
                                key={i}
                                className={`
                                  conversation-item
                                  ${c.incoming ? 'incoming' : ''}
                                  ${c.seen ? 'seen' : ''}
                                `}
                              >
                                <span className="conversation-item-message">
                                  {c.message}
                                  <span>{parseUTCTime(c.createdOn)}</span>
                                </span>
                              </div>
                            ))}
                          </React.Fragment>
                        ))}
                      </div>
                    </ContentLoading>
                  </div>
                </>
              ) : (
                <div className="chat-conversation-middle">
                  <h6 className="
                    text-center text-muted
                  ">Choose camper to load conversation</h6>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({ ContactMessageState }) => ({
  messages: ContactMessageState.messages,
  messageTotal: ContactMessageState.messageTotal,
  conversation: getConversation(ContactMessageState),
  params: ContactMessageState.params,
  status: ContactMessageState.status,
  isLoading: ContactMessageState.isLoading,
});

const mapDispatchToProps = dispatch => ({
  fetchList: (params, isLoadMore) => dispatch(ContactMessageActions.fetchList(params, isLoadMore)),
  fetchListConversation: userId => dispatch(ContactMessageActions.fetchListConversation(userId)),
  setInitialState: () => dispatch(ContactMessageActions.setInitialState())
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactMessages);
