import { Action } from 'redux';
// Types
import Statuses from 'types/statuses';

// Types
export enum SettingTypes {
  FetchAccountSettings = '@@setting/Fetch account settings',
  FetchAccountSettingsSuccess = '@@setting/Fetch account settings success',

  SetStatus = '@@setting/Set status'
}

// Actions
interface FetchAccountSettings extends Action<typeof SettingTypes.FetchAccountSettings> {}
interface FetchAccountSettingsSuccess extends Action<typeof SettingTypes.FetchAccountSettingsSuccess> {
  payload: { settings:any }
}

interface SetStatus extends Action<typeof SettingTypes.SetStatus> {
  payload: { status:Statuses }
}

export type SettingActionTypes =
  FetchAccountSettings | FetchAccountSettingsSuccess |
  SetStatus
;
