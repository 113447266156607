import { createSelector } from 'reselect';

const _restrictions = state => state.restrictions;
// const _filter = state => state.filter;
const _status = state => state.status;

export const getRestrictions = createSelector(
  _restrictions, restrictions => restrictions
);
export const getRestrictionById = createSelector(
  getRestrictions, (_, props) => props,
  (restrictions, restrictionId) => {
    if ( !restrictionId ) return {};
    return restrictions.find(r => r.id === restrictionId);
  }
)
export const getStatus = createSelector(
  _status, status => status
);
