import HttpUtility from 'utilities/http.utility';
import { IMailTemplateData } from './mail-template.types';

export default class MailTemplateService {
  private static _url:string = '/admin/mailTemplates';
  private static _http:HttpUtility = new HttpUtility();

  // Fetch list
  public static async fetchList():Promise<any> {
    return MailTemplateService._http.get(`${MailTemplateService._url}`);
  }
  // Fetch by id
  public static async fetchById(id:number):Promise<any> {
    return MailTemplateService._http.get(`${MailTemplateService._url}/${id}`);
  }
  // Update
  public static async update(id:number, data:IMailTemplateData):Promise<any> {
    return MailTemplateService._http.put(`${MailTemplateService._url}/${id}`, data);
  }
}
