import {
  IInventoryGroup, IInventoryGroupData, IInventoryGroupState,
  InventoryGroupActionTypes,
  FETCH_LIST, FETCH_LIST_SUCCESS,
  FETCH_BY_ID, FETCH_BY_ID_SUCCESS,
  CREATE, CREATE_SUCCESS,
  UPDATE, UPDATE_SUCCESS,
  DELETE, DELETE_SUCCESS,
  ENABLE, ENABLE_SUCCESS,
  DISABLE, DISABLE_SUCCESS,
  RESET_DEFAULT_WAIVER, RESET_DEFAULT_WAIVER_SUCCESS,
  SET_DEFAULT_WAIVER, SET_DEFAULT_WAIVER_SUCCESS,
  SET_FILTER, SET_STATUS, SetInitialField
} from './inventory-group.types';
import Statuses from 'types/statuses';

export default class InventoryGroupActions {
  // Fetch list
  public static fetchList = ():InventoryGroupActionTypes => ({
    type: FETCH_LIST
  });
  public static fetchListSuccess = (items: Array<IInventoryGroup>):InventoryGroupActionTypes => ({
    type: FETCH_LIST_SUCCESS, payload: { items }
  });
  // Fetch by id
  public static fetchById = (id:number):InventoryGroupActionTypes => ({
    type: FETCH_BY_ID, payload: { id }
  });
  public static fetchByIdSuccess = (item:IInventoryGroup):InventoryGroupActionTypes => ({
    type: FETCH_BY_ID_SUCCESS, payload: { item }
  });
  // Create
  public static create = (data:IInventoryGroupData):InventoryGroupActionTypes => ({
    type: CREATE, payload: { data }
  });
  public static createSuccess = (item:IInventoryGroup):InventoryGroupActionTypes => ({
    type: CREATE_SUCCESS, payload: { item }
  });
  // Update
  public static update = (id:number, data:IInventoryGroupData):InventoryGroupActionTypes => ({
    type: UPDATE, payload: { id, data }
  });
  public static updateSuccess = (item:IInventoryGroup):InventoryGroupActionTypes => ({
    type: UPDATE_SUCCESS, payload: { item }
  });
  // Delete
  public static delete = (id:number):InventoryGroupActionTypes => ({
    type: DELETE, payload: { id }
  });
  public static deleteSuccess = (id:number):InventoryGroupActionTypes => ({
    type: DELETE_SUCCESS, payload: { id }
  });
  // Enable
  public static enable = (id:number):InventoryGroupActionTypes => ({
    type: ENABLE, payload: { id }
  });
  public static enableSuccess = (id:number):InventoryGroupActionTypes => ({
    type: ENABLE_SUCCESS, payload: { id }
  });
  // Disable
  public static disable = (id:number):InventoryGroupActionTypes => ({
    type: DISABLE, payload: { id }
  });
  public static disableSuccess = (id:number):InventoryGroupActionTypes => ({
    type: DISABLE_SUCCESS, payload: { id }
  });
  // Reset default waiver
  public static resetDefaultWaiver = (id:number):InventoryGroupActionTypes => ({
    type: RESET_DEFAULT_WAIVER, payload: { id }
  });
  public static resetDefaultWaiverSuccess = (item:IInventoryGroup):InventoryGroupActionTypes => ({
    type: RESET_DEFAULT_WAIVER_SUCCESS, payload: { item }
  });
  // Set default waiver
  public static setDefailtWaiver = (waiverId:number):InventoryGroupActionTypes => ({
    type: SET_DEFAULT_WAIVER, payload: { waiverId }
  });
  public static setDefailtWaiverSuccess = (item:IInventoryGroup):InventoryGroupActionTypes => ({
    type: SET_DEFAULT_WAIVER_SUCCESS, payload: { item }
  });
  // Default
  // Set filter
  public static setFilter = (field:string, value:string):InventoryGroupActionTypes => ({
    type: SET_FILTER, payload: { field, value }
  })
  // Set status
  public static setStatus = (status:Statuses):InventoryGroupActionTypes => ({
    type: SET_STATUS, payload: { status }
  })
  public static setInitialField = (field:keyof IInventoryGroupState):InventoryGroupActionTypes => ({
    type: SetInitialField, payload: { field }
  })
}
