import { Action } from 'redux';
import Statuses from 'types/statuses';
import MapTypes from 'types/map-types';
import { IMapPoi } from 'store/map-poi/map-poi.types';

export interface IMap {
  enabled: boolean;
  id: number;
  main: boolean;
  name: string;
  options: IMapOptions;
  pois: Array<IMapPoi>;
}

export interface IMapData {
  name: string;
  options: IMapOptions;
}

interface IMapOptions {
  lat: number;
  lng: number;
  mapTypeId: MapTypes;
  zoom: number;
}

// State
export interface IMapState {
  items: Array<IMap> | null;
  item: IMap | null;
  isFetching: boolean;
  isLoading: boolean;
  status: Statuses;
}

// Messages
export enum MapMessages {
  Create = 'Map was created',
  Update = 'Map was updated',
  Delete = 'Map was deleted'
}

// Types
export const FETCH_LIST = '@@map/Fetch list';
export const FETCH_LIST_SUCCESS = '@@map/Fetch list success';

export const FETCH_BY_ID = '@@map/Fetch by id';
export const FETCH_BY_ID_SUCCESS = '@@map/Fetch by id success';

export const CREATE = '@@map/Create';
export const CREATE_SUCCESS = '@@map/Create success';

export const UPDATE = '@@map/Update';
export const UPDATE_SUCCESS = '@@map/Update success';

export const DELETE = '@@map/Delete';
export const DELETE_SUCCESS = '@@map/Delete success';

export const ENABLE = '@@map/Enable';
export const ENABLE_SUCCESS = '@@map/Enable success';

export const DISABLE = '@@map/Disable';
export const DISABLE_SUCCESS = '@@map/Disable success';

export const SET_AS_MAIN = '@@map/Set as main';
export const SET_AS_MAIN_SUCCESS = '@@map/Set as main success';

export const SET_MAP_OPTIONS = '@@map/Set map options';
export const SET_STATUS = '@@map/Set status';

// Actions
interface FetchList extends Action<typeof FETCH_LIST> {}
interface FetchListSuccess extends Action<typeof FETCH_LIST_SUCCESS> {
  payload: { items:Array<IMap> }
}

interface FetchById extends Action<typeof FETCH_BY_ID> {
  payload: { id:number }
}
interface FetchByIdSuccess extends Action<typeof FETCH_BY_ID_SUCCESS> {
  payload: { item:IMap }
}

interface Create extends Action<typeof CREATE> {
  payload: { data:IMapData }
}
interface CreateSuccess extends Action<typeof CREATE_SUCCESS> {
  payload: { item:IMap }
}

interface Update extends Action<typeof UPDATE> {
  payload: { id:number, data:IMapData }
}
interface UpdateSuccess extends Action<typeof UPDATE_SUCCESS> {
  payload: { item:IMap }
}

interface Delete extends Action<typeof DELETE> {
  payload: { id:number }
}
interface DeleteSuccess extends Action<typeof DELETE_SUCCESS> {
  payload: { id:number }
}

interface Enable extends Action<typeof ENABLE> {
  payload: { id:number }
}
interface EnableSuccess extends Action<typeof ENABLE_SUCCESS> {
  payload: { id:number }
}

interface Disable extends Action<typeof DISABLE> {
  payload: { id:number }
}
interface DisableSuccess extends Action<typeof DISABLE_SUCCESS> {
  payload: { id:number }
}

interface SetAsMain extends Action<typeof SET_AS_MAIN> {
  payload: { id:number }
}
interface SetAsMainSuccess extends Action<typeof SET_AS_MAIN_SUCCESS> {
  payload: { id:number }
}

interface SetMapOptions extends Action<typeof SET_MAP_OPTIONS> {
  payload: { options:{ lat:number, lng:number, zoom:number } }
}
interface SetStatus extends Action<typeof SET_STATUS> {
  payload: { status:Statuses }
}

export type MapActionTypes =
  FetchList | FetchListSuccess |
  FetchById | FetchByIdSuccess |
  Create | CreateSuccess |
  Update | UpdateSuccess |
  Delete | DeleteSuccess |
  Enable | EnableSuccess |
  Disable | DisableSuccess |
  SetAsMain | SetAsMainSuccess |
  SetMapOptions |
  SetStatus
;

