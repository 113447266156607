import React, { useState } from 'react';
// Redux
import Roles from 'types/roles';
import { IStaff } from 'store/staff/staff.types';
// Dialogs
import StaffFormDialog from 'dialogs/StaffForm.dialog';
// Constants
import { UserRoleLabels } from 'app/App.constants';

type IProps = {
  item: IStaff,
  onEnable: (id:number) => void;
  onDisable: (id:number) => void;
  onDelete: (user:IStaff) => void;
}

const StaffListItem:React.FC<IProps> = ({
  // Props
  item, onEnable, onDisable, onDelete
}) => {
  const { id, role, firstName, lastName, email, callingCode, phone, enabled, notificationPreferences } = item;

  const [show, setShow] = useState<boolean>(false);

  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);

  const handleEnable = () => onEnable(id);
  const handleDisable = () => onDisable(id);
  const handleDelete = () => onDelete(item);

  return (
    <>
      <div className="card | w-100">
        <div className="card-body">
          <small className={`${role === Roles.Admin ? 'text-primary' : 'text-secondary'}`}>{UserRoleLabels[item.role]}</small>
          <h5 className="card-title | mb-2">{`${firstName} ${lastName}`}</h5>
          <small className="card-subtitle | d-block mb-2 text-muted">{email}</small>
          <small className="card-subtitle | d-block mb-2 text-muted">{callingCode}{phone}</small>
        </div>
        <div className="card-footer | d-flex justify-content-between align-items-center">
          <div className="text-muted">
            {notificationPreferences ? (
              <>
                {notificationPreferences.sms ? (
                  <i className="fa fa-sms mr-2" aria-hidden="true"></i>
                ) : null}
                {notificationPreferences.email ? (
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                ) : null}
              </>
            ) : null}
          </div>
          <div>
            <button
              className="btn btn-primary btn-sm | mr-2"
              type="button"
              onClick={handleShow}
            >Edit</button>
            {enabled ? (
              <button
                className="btn btn-outline-secondary btn-sm | mr-2"
                type="button"
                onClick={handleDisable}
              >Disable</button>
            ) : (
              <button
                className="btn btn-outline-success btn-sm | mr-2"
                type="button"
                onClick={handleEnable}
              >Enable</button>
            )}
            <button
              className="btn btn-outline-danger btn-sm"
              type="button"
              onClick={handleDelete}
            >Delete</button>
          </div>
        </div>
      </div>
      {show ? (
        <StaffFormDialog
          staff={item}
          show={show}
          onHide={handleHide}
        />
      ) : null}
    </>
  )
}

export default StaffListItem;
