import HttpUtility from 'utilities/http.utility';

export default class RemotelockDeviceAPI {
  private static _url:string = '/admin/remotelockDevices';
  private static _http:HttpUtility = new HttpUtility();

  // Fetch removelock devices
  public static async fetchRemotelockDevices():Promise<any> {
    return RemotelockDeviceAPI._http.get(RemotelockDeviceAPI._url);
  };
  // Fetch remotelock device audits
  public static async fetchRemotelockDeviceAudits(deviceId:number, params:any):Promise<any> {
    const searchParams = new URLSearchParams();
    Object.keys(params).forEach((key:string) => {
      if ( params[key] ) searchParams.append(key, params[key]);
    });
    return RemotelockDeviceAPI._http.get(`${RemotelockDeviceAPI._url}/${deviceId}/audit?${searchParams}`);
  };
  // Unlock device
  public static async unlockDevice(deviceId:number):Promise<any> {
    return RemotelockDeviceAPI._http.post(`${RemotelockDeviceAPI._url}/${deviceId}/unlock`);
  };
}