import {
  IStaff, IStaffData,
  StaffActionTypes,
  FETCH_LIST, FETCH_LIST_SUCCESS,
  CREATE, CREATE_SUCCESS,
  UPDATE, UPDATE_SUCCESS,
  ENABLE, ENABLE_SUCCESS,
  DISABLE, DISABLE_SUCCESS,
  DELETE, DELETE_SUCCESS,
  SET_STATUS
} from './staff.types';
import Statuses from 'types/statuses';

export default class WaiverActions {
  // Fetch list staff
  public static fetchList = ():StaffActionTypes => ({
    type: FETCH_LIST
  });
  public static fetchListSuccess = (items: Array<IStaff>):StaffActionTypes => ({
    type: FETCH_LIST_SUCCESS, payload: { items }
  });
  // Create
  public static create = (data:IStaffData):StaffActionTypes => ({
    type: CREATE, payload: { data }
  });
  public static createSuccess = (staff:IStaff):StaffActionTypes => ({
    type: CREATE_SUCCESS, payload: { staff }
  });
  // Update
  public static update = (id:number, data:IStaffData):StaffActionTypes => ({
    type: UPDATE, payload: { id, data }
  });
  public static updateSuccess = (staff:IStaff):StaffActionTypes => ({
    type: UPDATE_SUCCESS, payload: { staff }
  });
  // Enable
  public static enable = (id:number):StaffActionTypes => ({
    type: ENABLE, payload: { id }
  });
  public static enableSuccess = (id:number):StaffActionTypes => ({
    type: ENABLE_SUCCESS, payload: { id }
  });
  // Disable
  public static disable = (id:number):StaffActionTypes => ({
    type: DISABLE, payload: { id }
  });
  public static disableSuccess = (id:number):StaffActionTypes => ({
    type: DISABLE_SUCCESS, payload: { id }
  });
  // Delete
  public static delete = (staff:IStaff):StaffActionTypes => ({
    type: DELETE, payload: { staff }
  });
  public static deleteSuccess = (id:number):StaffActionTypes => ({
    type: DELETE_SUCCESS, payload: { id }
  });
  // Set status
  public static setStatus = (status:Statuses):StaffActionTypes => ({
    type: SET_STATUS, payload: { status }
  })
}