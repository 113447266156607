import { call, put, takeLatest } from 'redux-saga/effects';
import * as LayoutActions from 'app/actions/layout.actions';
import SeasonAPI from './season.api';
import SeasonActions from './season.actions';
import { ISeason, SeasonMessages, SeasonTypes } from './season.types';
import Statuses from 'types/statuses';

export default function* (){
  yield takeLatest(SeasonTypes.FetchSeasonsList, _fetchSeasonsList);
  yield takeLatest(SeasonTypes.CreateSeason, _createSeason);
  yield takeLatest(SeasonTypes.UpdateSeason, _updateSeason);
  yield takeLatest(SeasonTypes.DeleteSeason, _deleteSeason);
  yield takeLatest(SeasonTypes.UpdateSlots, _updateSlots);
}

function* _fetchSeasonsList(){
  try {
    const { parseBody:items } = yield call(SeasonAPI.fetchSeasonsList);
    yield put(SeasonActions.fetchSeasonsListSuccess(items));
  } catch(error:any){
    console.log(error);
  }
}

function* _createSeason(action:any){
  try {
    const { data } = action.payload;
    const { parseBody:item } = yield call(SeasonAPI.createSeason, data);
    yield put(SeasonActions.createSeasonSuccess(item));
    yield put(SeasonActions.setStatus(Statuses.Success));
    yield put(SeasonActions.setStatus(Statuses.Initial));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: SeasonMessages.CreateSeason
    }));
  } catch(error:any){
    yield put(SeasonActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _updateSeason(action:any){
  try {
    const { seasonId, data } = action.payload;
    const { parseBody:item } = yield call(SeasonAPI.updateSeason, seasonId, data);
    yield put(SeasonActions.updateSeasonSuccess(item));
    yield put(SeasonActions.setStatus(Statuses.Success));
    yield put(SeasonActions.setStatus(Statuses.Initial));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: SeasonMessages.UpdateSeason
    }));
  } catch(error:any){
    yield put(SeasonActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _deleteSeason(action:any){
  try {
    const { seasonId } = action.payload;
    yield call(SeasonAPI.deleteSeason, seasonId);
    yield put(SeasonActions.deleteSeasonSuccess(seasonId));
    yield put(SeasonActions.setStatus(Statuses.Success));
    yield put(SeasonActions.setStatus(Statuses.Initial));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: SeasonMessages.DeleteSeason
    }));
  } catch(error:any){
    yield put(SeasonActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _updateSlots(action:any){
  try {
    const { slots, seasonId, data } = action.payload;
    const { parseBody:items } = yield call(SeasonAPI.updateSlots, seasonId ? slots.filter((s:any) => s.reservationSeasonId !== seasonId) : slots);
    let season:ISeason;
    if ( seasonId ){
      const { parseBody:item } = yield call(SeasonAPI.updateSeason, seasonId, data);
      season = item;
    } else {
      const { parseBody:item } = yield call(SeasonAPI.createSeason, data);
      season = item;
    }
    yield put(SeasonActions.updateSlotsSuccess(season, items));
    yield put(SeasonActions.setStatus(Statuses.Success));
    yield put(SeasonActions.setStatus(Statuses.Initial));
  } catch(error:any){
    yield put(SeasonActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}
