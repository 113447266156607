import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
// Redux
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import MapActions from 'store/map/map.actions';
import { getItem, getIsFetching } from 'store/map/map.selectors';
import { getItems } from 'store/map-poi/map-poi.selectors';
import { IMap } from 'store/map/map.types';
import { IMapPoi } from 'store/map-poi/map-poi.types';
// Components
import GoogleMap from 'components/GoogleMap';
// 
import MapDetailsForm from './MapDetailsForm';
import MapDetailsMarkers from './MapDetailsMarkers';

type IProps = {
  // State
  item: IMap;
  items: Array<IMapPoi>;
  isFetching: boolean;
  // Dispatch
  fetchMapById: (id:number) => void;
  setMapOptions: (options:{ lat:number, lng:number, zoom:number }) => void;
}

const MapDetailsPage:React.FC<IProps> = ({
  // State
  item, items, isFetching,
  // Dispatch
  fetchMapById, setMapOptions
}) => {
  const { id } = useParams<{ id?:string }>();

  useEffect(() => {
    if ( id ) fetchMapById(Number(id));
    // eslint-disable-next-line
  }, []);

  const handleDragEnd = (map:any) => {
    if ( map ) setMapOptions({
      lat: map.center.lat(),
      lng: map.center.lng(),
      zoom: map.zoom
    });
  }

  const handleZoomChange = (map:any) => {
    if ( map ) setMapOptions({
      lat: map.center.lat(),
      lng: map.center.lng(),
      zoom: map.zoom
    });
  }

  if ( isFetching ) return <h1>Loading ...</h1>
  return (
    <div className="container-fluid pt-3 pb-3">
      <div className="pb-3">
        <Link
          className="btn btn-link btn-sm pl-0 pr-0"
          to="/admin/maps"
        ><i className="fa fa-chevron-left mr-1" aria-hidden="true"></i>Maps</Link>
      </div>
      <div className="card p-3">
        <div className="row row-8">
          <div className="col-12 col-sm-6 col-mg-7 col-lg-8">
            <GoogleMap
              markers={items}
              containerClassName="google-map-page"
              onDragEnd={handleDragEnd}
              onZoomChange={handleZoomChange}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-5 col-lg-4">
            <MapDetailsForm />
            <hr />
            <MapDetailsMarkers />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state:any) => ({
  item: getItem(state),
  items: getItems(state),
  isFetching: getIsFetching(state)
});

const mapDispatchToProps = (dispatch:Dispatch) => ({
  fetchMapById: (id:number) => dispatch(MapActions.fetchById(id)),
  setMapOptions: (options:{ lat:number, lng:number, zoom:number }) => dispatch(MapActions.setMapOptions(options))
});

export default connect(mapStateToProps, mapDispatchToProps)(MapDetailsPage);
