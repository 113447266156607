import { ContactMessageActionTypes } from './contact-message.types';

export const ContactMessageActions = {
  // Fetch list
  fetchList: (params, isLoadMore = false) => ({
    type: ContactMessageActionTypes.FetchList,
    params,
    isLoadMore
  }),
  fetchListSuccess: messages => ({
    type: ContactMessageActionTypes.FetchListSuccess,
    messages
  }),
  // Fetch list conversation
  fetchListConversation: userId => ({
    type: ContactMessageActionTypes.FetchListConversation,
    userId
  }),
  fetchListConversationSuccess: conversation => ({
    type: ContactMessageActionTypes.FetchListConversationSuccess,
    conversation
  }),
  // Mark as read
  markAsRead: messageId => ({
    type: ContactMessageActionTypes.MarkAsRead,
    messageId
  }),
  markAsReadSuccess: messageId => ({
    type: ContactMessageActionTypes.MarkAsReadSuccess,
    messageId
  }),
  // Send in app
  sendInApp: (userId, text) => ({
    type: ContactMessageActionTypes.SendInApp,
    userId,
    text
  }),
  sendInAppSuccess: conversation => ({
    type: ContactMessageActionTypes.SendInAppSuccess,
    conversation
  }),

  // Set status
  setStatusSuccess: () => ({
    type: ContactMessageActionTypes.StatusSuccess
  }),
  setStatusError: () => ({
    type: ContactMessageActionTypes.StatusError
  }),
  setStatusInitial: () => ({
    type: ContactMessageActionTypes.StatusInitial
  }),
  setInitialState: () => ({
    type: ContactMessageActionTypes.SetInitialState
  })
}
