import React, { useState } from 'react';
// Hook
import useStateCallback from '../../../hooks/useStateCallback';

const BookingFormDynamicFields = ({
  // Props
  value, onChange
}) => {

  const fieldsCallback = state => {
    onChange({
      target: {
        name: 'customerDataFields',
        value: state
      }
    });
  }

  const [fields, setFields] = useStateCallback(value || [], fieldsCallback);
  const [fieldName, setFieldName] = useState('');
  const [fieldRequired, setFieldRequired] = useState(false);

  const handleAdd = () => {
    setFields(prevState => [...prevState, { 'name': fieldName, 'required': fieldRequired }]);
    setFieldName('');
    setFieldRequired(false);
  }
  const handleRemove = index => {
    setFields(prevState => prevState.filter((_, i) => i !== index));
  }

  return (
    <div className="form-group">
      <label htmlFor="">Customer data fields</label>
      <div className="form-group row row-8">
        <div className="col-7">
          <input
            className="form-control form-control-sm"
            type="text"
            value={fieldName}
            placeholder="Name"
            onChange={e => setFieldName(e.target.value)}
          />
        </div>
        <div className="col-3">
          <div className="form-group form-check">
            <input
              className="form-check-input" style={{ top: '2px' }}
              id="required" type="checkbox" checked={fieldRequired}
              onChange={e => setFieldRequired(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="required">Required</label>
          </div>
        </div>
        <div className="col-2">
          <button
            className="btn btn-primary btn-block btn-sm"
            type="button"
            onClick={handleAdd}
            disabled={!fieldName}
          ><i className="fa fa-plus" aria-hidden="true"></i></button>
        </div>
      </div>
      {fields.map((field, index) => (
        <div key={`${field.name}-${index}`} className="form-group row row-8 align-items-center">
          <div
            className="col-5 col-form-label pl-2 pr-2"
            style={{fontSize: '0.75rem', fontWeight: '700', lineHeight: '1.2'}}
          >{field.name}</div>
          <div
            className={`col-5 col-form-label pl-2 pr-2 ${field.required ? 'text-success' : ''}`}
            style={{fontSize: '0.75rem'}}
          >{field.required ? 'Required' : 'Not-required'}</div>
          <div className="col-2">
            <button
              className="btn btn-danger btn-block btn-sm"
              type="button"
              onClick={() => handleRemove(index)}
            ><i className="fa fa-minus" aria-hidden="true"></i></button>
          </div>
        </div>
      ))}
    </div>
  )
}

export default BookingFormDynamicFields;
