import React from 'react';
// Models
import { IMembershipType } from 'models/MembershipType';
// Store
import { useDispatch, useSelector } from 'react-redux';
// Actions
import { goBack } from 'connected-react-router';
import InventoryGroupActions from 'store/inventory-group/inventory-group.actions';
import MembershipTypeActions from 'store/membership-type/membership-type.actions';
// ToDO
import { BookingActions } from 'app/store/booking/booking.actions';
// Selectors
import { getBooking } from 'app/store/booking/booking.selectors';
import { getInventoryGroup } from 'store/inventory-group/inventory-group.selectors';
import { getMembershipType } from 'store/membership-type/membership-type.selectors';
// Dialogs
import WaiverFormDialog from 'dialogs/WaiverForm.dialog';

type Props = {
  params: any;
};

const WaiversToolbar:React.FC<Props> = ({
  // Props
  params
}) => {
  // Dispatch
  const dispatch = useDispatch();
  // State
  const bookingItem:any = useSelector((state:any) => getBooking(state.BookingState));
  const inventoryGroup:any = useSelector((state:any) => getInventoryGroup(state));
  const membershipType:IMembershipType|null = useSelector((state:any) => getMembershipType(state));

  const [ currentItem, setCurrentItem ] = React.useState<any | null>(null);
  const [ dialogOpen, setDialogOpen ] = React.useState<boolean>(false);

  const toggleDialog = () => setDialogOpen((prevState:boolean) => !prevState);

  React.useEffect(() => {
    if ( params.inventoryGroupId ){
      dispatch(InventoryGroupActions.fetchById(params.inventoryGroupId));
    }
    if ( params.membershipTypeId ){
      dispatch(MembershipTypeActions.fetchMembershipType(params.membershipTypeId));
    }
    return () => {
      if ( params.inventoryGroupId ){
        dispatch(InventoryGroupActions.setInitialField('item'));
      }
      if ( params.membershipTypeId ){
        dispatch(MembershipTypeActions.setInitialField('membershipType'));
      }
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if ( params.inventoryGroupId && inventoryGroup ) setCurrentItem(inventoryGroup);
    if ( params.membershipTypeId && membershipType ) setCurrentItem(membershipType);
    if ( params.bookingPropertyId && bookingItem ) setCurrentItem(bookingItem);
    // eslint-disable-next-line
  }, [inventoryGroup, membershipType, bookingItem]);

  const handleGoBack = () => dispatch(goBack());

  const handleResetDefaultWaiver = () => {
    if ( params.inventoryGroupId ){
      dispatch(InventoryGroupActions.resetDefaultWaiver(currentItem.id));
    }
    if ( params.membershipTypeId ){
      dispatch(MembershipTypeActions.resetDefaultWaiver(currentItem.id));
    }
    if ( params.bookingPropertyId ){
      dispatch(BookingActions.resetDefaultWaiver(currentItem.id));
    }
  }

  return (
    <React.Fragment>
      <div className="container-fluid px-0 d-flex justify-content-between align-items-center">
        <div>
          {!params.bookingPropertyId ? (
            <React.Fragment>
              <button
                className="btn btn-link btn-sm pl-0 pr-0"
                onClick={handleGoBack}
              ><i className="fa fa-chevron-left mr-1" aria-hidden="true"></i>Back</button>
              {currentItem ? (
                <h5>{currentItem.name}</h5>
              ) : null}
            </React.Fragment>
          ) : null}
        </div>
        <div>
          {currentItem && currentItem.waiverId ? (
            <button
              className="btn btn-secondary btn-sm | mr-2"
              type="button"
              onClick={handleResetDefaultWaiver}
            >Reset default waiver</button>
          ) : null}
          <button
            className="btn btn-primary btn-sm"
            type="button"
            onClick={toggleDialog}
          >Create waiver</button>
        </div>
      </div>
      {dialogOpen ? (
        <WaiverFormDialog
          params={params}
          show={dialogOpen}
          onHide={toggleDialog}
        />
      ) : null}
    </React.Fragment>
  )
}

export default WaiversToolbar;
