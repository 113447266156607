import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
import { AvailabilityActions } from '../../../store/availability/availability.actions';
import * as AvailabilitySelectors from '../../../store/availability/availability.selectors';
// Components
import { ContentLoading } from '../../../components/Loadings';
// 
import AvailabilityCreate from './AvailabilityCreate';
import AvailabilityListItem from './AvailabilityListItem';

const RestrictionsPage = ({
  // Props
  // State
  restrictions, status,
  // Dispatch
  fetchRestrictions
}) => {
  const { bookingId } = useParams();

  useEffect(() => {
    fetchRestrictions(bookingId);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <AvailabilityCreate />
      <ContentLoading data={restrictions} status={status}>
        <div className="container-sm">
          <ul className="list-group">
            {restrictions.map((restriction, index) => (
              <AvailabilityListItem
                key={index}
                restriction={restriction}
              />
            ))}
          </ul>
        </div>
      </ContentLoading>
    </>
  )
}

const mapStateToProps = ({ AvailabilityState }) => ({
  restrictions: AvailabilitySelectors.getRestrictions(AvailabilityState),
  status: AvailabilitySelectors.getStatus(AvailabilityState)
});

const mapDispatchToProps = dispatch => ({
  fetchRestrictions: bookingId => dispatch(AvailabilityActions.fetchList(bookingId))
});

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionsPage);
