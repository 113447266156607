import React, { useState } from 'react';
// Layouts
import ReservationModal from 'app/layouts/Modals/ReservationModal';

const CalendarDailyTimelineItem = props => {
  const { reservation } = props;
  const [ show, setShow ] = useState(false);

  const handleModalShow = () => setShow(true);
  const handleModalHide = () => setShow(false);

  // upcoming, active, completed, cancelled
  const { userData, status, isBlackout } = reservation.item;

  const classes = 
    isBlackout
    ? 'badge-dark'
    :
      status === 'upcoming'
      ? 'badge-warning'
      : status === 'active'
        ? 'badge-success'
        : status === 'completed'
          ? 'badge-info'
          : 'badge-danger'
  ;

  return (
    <>
      <span
        className={`_reservation | ${classes}`}
        style={{
          width: `calc(${100 * reservation.days - ( reservation.hasEnd ? 4 : 2 )}% + ${reservation.days - 1}px)`,
          minWidth: '96%',
          marginLeft: `${ reservation.hasStart ? '2%' : '0' }`
        }}
        title={!isBlackout ? `${userData.firstName} ${userData.lastName}` : 'Blackout'}
        onClick={handleModalShow}
      >{!isBlackout ? `${userData.firstName} ${userData.lastName}` : 'Blackout'}</span>
      {show &&
        <ReservationModal
          reservation={reservation}
          show={show}
          onHide={handleModalHide}
        />
      }
    </>
  )
}

export default CalendarDailyTimelineItem;
