import { call, put, takeLatest } from 'redux-saga/effects';
// Types
import Statuses from 'types/statuses';
import { OrderTypes } from './order.types';
// Actions
import * as LayoutActions from 'app/actions/layout.actions';
import OrderActions from './order.actions';
// Service
import OrderService from './order.service';

export default function* (){
  yield takeLatest(OrderTypes.CheckInOrder, _checkInOrder);
}

function* _checkInOrder(action:any){
  try {
    const { activityId, confirmationCode, status } = action.payload;
    const { parseBody } = yield call(OrderService.checkIn, activityId, confirmationCode, status);
    console.log(parseBody);
    yield put(OrderActions.checkInSuccess(parseBody));
  } catch(error:any){
    yield put(OrderActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}
