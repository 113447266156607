import fetcher from '../../utilities/fetcher';

const apiURL = '/admin/bookingPropertyItems';
const headers = {
  'Content-Type': 'application/json'
};

export const BookingItemService = {
  // Fetch booking property items
  fetchBookingItems: (bookingPropertyId, status) => {
    const params = new URLSearchParams();
    if ( bookingPropertyId ) params.append('bookingPropertyId', bookingPropertyId);
    if ( status ) params.append('status', status);
    return fetcher(`${apiURL}?${params}`);
  },
  // Create booking item
  create: data => {
    return fetcher(`${apiURL}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(data)
    })
  },
  // Update booking item
  update: (bookingItemId, data) => {
    return fetcher(`${apiURL}/${bookingItemId}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(data)
    });
  },
  // Delete booking item
  delete: bookingItemId => {
    return fetcher(`${apiURL}/${bookingItemId}`, {
      method: 'DELETE'
    }, () => null);
  },
  // Actions
  // Mark as free
  markAsFree: bookingItemId => {
    return fetcher(`${apiURL}/${bookingItemId}/actions/markAsFree`, {
      method: 'POST',
      headers
    });
  },
  // Mark as out of service
  markAsOutOfService: bookingItemId => {
    return fetcher(`${apiURL}/${bookingItemId}/actions/markAsOutOfService`, {
      method: 'POST',
      headers
    });
  },

  assignRemotelockDevises: (bookingItemId, remotelockDevicesIds) => {
    const searchParams = new URLSearchParams();
    searchParams.append('remotelockDevicesIds', remotelockDevicesIds);
    return fetcher(`${apiURL}/${bookingItemId}/actions/assignRemotelockDevices?${searchParams}`, {
      method: 'POST',
      headers
    });
  }
}
