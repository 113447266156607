import fetcher from 'app/utilities/fetcher';

const headers = { 'Content-Type': 'application/json' };

class NotificationService {
  constructor(){
    this._url = '/admin/notifications';
  }
  // Fetch all
  fetchAll = params => {
    const searchParams = new URLSearchParams();
    Object.keys(params).forEach(key => {
      if ( params[key] ) searchParams.append(key, params[key]);
    });
    return fetcher(`${this._url}?${searchParams}`, {
      method: 'GET'
    });
  }
  // Send notification
  send = notification => {
    return fetcher(this._url, {
      method: 'POST',
      headers,
      body: JSON.stringify(notification),
    }, () => null);
  }
  // Send bulk notification
  sendBulk = data => {
    return fetcher(`${this._url}/actions/bulk`, {
      method: 'POST',
      headers,
      body: JSON.stringify(data)
    }, () => null);
  }
}

const service = new NotificationService();
export default service;
