import React from 'react';
// 
import ProductFilter from './ProductFilter';
import ProductList from './ProductList';

const Products = ({ match: { path } }) => {
  return (
    <div className="area area-products">
      <div className="container-fluid">
        <ProductFilter path={path} />
        <ProductList path={path} />
      </div>
    </div>
  )
}

export default Products;
