import React from 'react';
import { useForm } from 'react-hook-form';
// Types
import Statuses from 'types/statuses';
// Store
import { useSelector, useDispatch } from 'react-redux';
// Actions
import MembershipActions from 'store/membership/membership.actions';
// Selectors
import { getIsLoading, getStatus } from 'store/membership/membership.selectors';
// Components
import { ButtonLoading } from 'app/components/Buttons';
// React bootstrap
import { Modal } from 'react-bootstrap';

type Props = {
  membershipId: number;
  show: boolean;
  onHide: () => void;
}

const CancelDialog:React.FC<Props> = ({
  // Props
  membershipId, show, onHide
}) => {
  // Dispatch
  const dispatch = useDispatch();
  // State
  const isLoading:boolean = useSelector((state:any) => getIsLoading(state));
  const status:Statuses = useSelector((state:any) => getStatus(state));

  React.useEffect(() => {
    if ( status === Statuses.Success ) onHide();
    // eslint-disable-next-line
  }, [status]);

  const { register, handleSubmit } = useForm<any>({
    defaultValues: {
      notes: ''
    }
  });

  const onSubmit = handleSubmit((data:any) => {
    dispatch(MembershipActions.inactivateMembership(membershipId, data));
  });

  return (
    <Modal show={show} onHide={onHide}>
      <form onSubmit={onSubmit}>
        <Modal.Header closeButton>Cancel & Refund</Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="description">Notes</label>
            <textarea
              ref={register()}
              className="form-control form-control-sm"
              name="notes" id="notes"
              rows={8}
            ></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-secondary btn-sm"
            type="button"
            onClick={onHide}
          >Close</button>
          <ButtonLoading
            loading={isLoading}
            type="submit"
          >Cancel</ButtonLoading>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default CancelDialog;
