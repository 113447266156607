import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import ReactSelect from 'react-select';
// Redux
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import InventoryGroupActions from 'store/inventory-group/inventory-group.actions';
import { getIsLoading, getStatus } from 'store/inventory-group/inventory-group.selectors';
import { IInventoryGroup, IInventoryGroupData } from 'store/inventory-group/inventory-group.types';
import * as StaffSelectors from 'store/staff/staff.selectors';
import InventoryGroupTypes from 'types/inventory-group-types';
import Statuses from 'types/statuses';
// Bootstrap
import { Modal } from 'react-bootstrap';
// Components
import { Input, Select, Checkbox } from 'components/Controls';
import { ButtonLoading } from 'app/components/Buttons';
// Utilities
import { isRequired, lessThanStartTime } from 'utilities/validation';
import Taxes from 'components/Taxes';

type IProps = {
  // Props
  inventoryGroup?: IInventoryGroup | null;
  show: boolean;
  onHide: () => void;
  // State
  staffForSelect: Array<{ value:number, label:string }>;
  isLoading: boolean;
  status: Statuses;
  // Dispatch
  createInventoryGroup: (data:IInventoryGroupData) => void;
  updateInventoryGroup: (id:number, data:IInventoryGroupData) => void;
}

const days:Array<string> = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const InventoryGroupFormDialog:React.FC<IProps> = ({
  // Props
  inventoryGroup, show, onHide,
  // State
  staffForSelect, isLoading, status,
  // Dispatch
  createInventoryGroup, updateInventoryGroup
}) => {
  const orderManagers = inventoryGroup && inventoryGroup.orderManagers
    ? inventoryGroup.orderManagers.map((manager:any) => ({ value: manager.id, label: `${manager.firstName} ${manager.lastName}` }))
    : []
  ;
  const { register, control, handleSubmit, errors, watch } = useForm<any>({
    defaultValues: {
      name: inventoryGroup?.name || '',
      type: inventoryGroup?.type || '',
      tax: inventoryGroup?.tax || 0,
      pickupLocation: inventoryGroup?.pickupLocation || '',
      deliveryAvailable: inventoryGroup?.deliveryAvailable || false,
      orderManagers,
      // Availability
      // Monday
      mondayAvailable: getAvailability(inventoryGroup?.availability, 'monday'),
      mondayStart: getAvailabilityTime(inventoryGroup?.availability, 'monday', 'start'),
      mondayEnd: getAvailabilityTime(inventoryGroup?.availability, 'monday', 'end'),
      // Monday
      tuesdayAvailable: getAvailability(inventoryGroup?.availability, 'tuesday'),
      tuesdayStart: getAvailabilityTime(inventoryGroup?.availability, 'tuesday', 'start'),
      tuesdayEnd: getAvailabilityTime(inventoryGroup?.availability, 'tuesday', 'end'),
      // Monday
      wednesdayAvailable: getAvailability(inventoryGroup?.availability, 'wednesday'),
      wednesdayStart: getAvailabilityTime(inventoryGroup?.availability, 'wednesday', 'start'),
      wednesdayEnd: getAvailabilityTime(inventoryGroup?.availability, 'wednesday', 'end'),
      // Monday
      thursdayAvailable: getAvailability(inventoryGroup?.availability, 'thursday'),
      thursdayStart: getAvailabilityTime(inventoryGroup?.availability, 'thursday', 'start'),
      thursdayEnd: getAvailabilityTime(inventoryGroup?.availability, 'thursday', 'end'),
      // Monday
      fridayAvailable: getAvailability(inventoryGroup?.availability, 'friday'),
      fridayStart: getAvailabilityTime(inventoryGroup?.availability, 'friday', 'start'),
      fridayEnd: getAvailabilityTime(inventoryGroup?.availability, 'friday', 'end'),
      // Monday
      saturdayAvailable: getAvailability(inventoryGroup?.availability, 'saturday'),
      saturdayStart: getAvailabilityTime(inventoryGroup?.availability, 'saturday', 'start'),
      saturdayEnd: getAvailabilityTime(inventoryGroup?.availability, 'saturday', 'end'),
      // Monday
      sundayAvailable: getAvailability(inventoryGroup?.availability, 'sunday'),
      sundayStart: getAvailabilityTime(inventoryGroup?.availability, 'sunday', 'start'),
      sundayEnd: getAvailabilityTime(inventoryGroup?.availability, 'sunday', 'end'),
    }
  });

  const onSubmit = (data:any) => {
    const {
      name, type, tax, pickupLocation, deliveryAvailable, orderManagers
    } = data;
    const newData:IInventoryGroupData = {
      name, tax, deliveryAvailable: type === InventoryGroupTypes.Physical ? deliveryAvailable : false
    };
    if ( type === InventoryGroupTypes.Physical ){
      newData['pickupLocation'] = pickupLocation;
      newData['availability'] = {};
      days.forEach((day:string) => {
        if ( data[`${day}Available`] ){
          newData['availability'] = {
            ...newData['availability'],
            [day]: {
              available: true,
              time: {
                start: data[`${day}Start`],
                end: data[`${day}End`]
              }
            }
          }
        } else {
          newData['availability'] = {
            ...newData['availability'],
            [day]: { available: false }
          }
        }
      });
    }
    if ( orderManagers.length > 0 ) newData['orderManagerIds'] = orderManagers.map((m:any) => m.value);
    if ( inventoryGroup && inventoryGroup.id ){
      updateInventoryGroup(inventoryGroup.id, newData);
    } else {
      newData['type'] = type;
      createInventoryGroup(newData);
    }
  }

  useEffect(() => {
    if ( status === Statuses.Success ) onHide();
    // eslint-disable-next-line
  }, [status])

  return (
    <Modal show={show} onHide={onHide}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>{`${inventoryGroup?.id ? 'Update' : 'Create'}`} inventory group</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Input
            controlRef={register({ required: isRequired })}
            label="Name" id="name" name="name"
            required={true}
            errorText={errors.name ? errors.name.message : null}
          />

          <Select
            controlRef={register({ required: isRequired })}
            label="Type" id="type" name="type"
            required={true}
            options={[
              { value: '', label: 'Choose type' },
              { value: InventoryGroupTypes.Physical, label: 'Physical' },
              { value: InventoryGroupTypes.Online, label: 'Online' },
            ]}
            errorText={errors.type ? errors.type.message : null}
            readOnly={inventoryGroup && inventoryGroup.id ? true : false}
          />  

          {/* <Input
            controlRef={register({ required: isRequired })}
            label="Tax" id="tax" name="tax" type="number" min={0} max={100} step="0.0000001"
            required={true}
            errorText={errors.tax ? errors.tax.message : null}
          /> */}
          {inventoryGroup && inventoryGroup.id ? (
            <Taxes inventoryGroupId={inventoryGroup.id} />
          ) : null}

          <Controller
            control={control}
            name="orderManagers"
            render={({ onChange, onBlur, value }) => (
              <div className="form-group">
                <label htmlFor="orderManagers">Staff</label>
                <ReactSelect
                  id="orderManagers"
                  value={value}
                  onChange={(newStaff:any) => onChange(newStaff)}
                  options={staffForSelect}
                  isMulti={true}
                />
              </div>
            )}
          />
          {watch('type') === InventoryGroupTypes.Physical ? (
            <div>
              <Input
                controlRef={register({ required: isRequired })}
                label="Pickup location" id="pickupLocation" name="pickupLocation"
                required={true}
                errorText={errors.pickupLocation ? errors.pickupLocation.message : null}
              />

              <Controller
                control={control}
                name="deliveryAvailable"
                render={({ onChange, onBlur, value }) => (
                  <Checkbox
                    label="Delivery availability"
                    id="deliveryAvailable" name="deliveryAvailable"
                    checked={value}
                    onChange={(e:any) => onChange(e.target.checked)}
                  />
                )}
              />

              {days.map((day:string, index:number) => (
                <div className="form-group mb-0" key={`available-item-${index}`}>
                  <hr />
                  <div className="row row-8 | d-flex align-items-center">
                    <div className="col-12 col-sm-4">
                      <Controller
                        control={control}
                        name={`${day}Available`}
                        render={({ onChange, onBlur, value }) => (
                          <Checkbox
                            label={day}
                            id={`${day}Available`} name={`${day}Available`}
                            checked={value}
                            onChange={(e:React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked)}
                          />
                        )}
                      />
                    </div>
                    {watch(`${day}Available`) ? (
                      <div className="col-12 col-sm-8">
                        <div className="row row-8">
                          <div className="col-12 col-sm-6">
                            <Input
                              controlRef={register({ required: isRequired })}
                              label="Start" id={`${day}Start`} name={`${day}Start`} type="time"
                              required={true}
                              errorText={errors[`${day}Start`] ? errors[`${day}Start`].message : null}
                            />
                          </div>
                          <div className="col-12 col-sm-6">
                            <Input
                              controlRef={register({
                                required: isRequired,
                                validate: value => lessThanStartTime(value, watch(`${day}Start`))
                              })}
                              label="End" id={`${day}End`} name={`${day}End`} type="time"
                              required={true}
                              errorText={errors[`${day}End`] ? errors[`${day}End`].message : null}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          ) : null}

        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary btn-sm"
            type="button"
            onClick={onHide}
          >Cancel</button>
          <ButtonLoading
            loading={isLoading}
            type="submit"
          >Save</ButtonLoading>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

const getAvailability = (availability:any = {}, day:string) => {
  return availability[day] ? availability[day].available : true;
}
const getAvailabilityTime = (availability:any = {}, day:string, time:string) => {
  return availability[day] && availability[day]['time'] ? availability[day]['time'][time] : '';
}

InventoryGroupFormDialog.defaultProps = {
  inventoryGroup: null,
  show: false,
  onHide: () => null
}

const mapStateToProps = (state:any) => ({
  staffForSelect: StaffSelectors.getItemsForSelect(state),
  isLoading: getIsLoading(state),
  status: getStatus(state)
});

const mapDispatchToProps = (dispatch:Dispatch) => ({
  createInventoryGroup: (data:IInventoryGroupData) => dispatch(InventoryGroupActions.create(data)),
  updateInventoryGroup: (id:number, data:IInventoryGroupData) => dispatch(InventoryGroupActions.update(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryGroupFormDialog);
