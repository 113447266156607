import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
import { ReservationActions } from 'app/store/reservation/reservation.actions';
import * as BookingSelectors from 'app/store/booking/booking.selectors';
import * as ReservationSelectors from 'app/store/reservation/reservation.selectors';
// Components
import { ContentLoading } from 'app/components/Loadings';
import { ButtonLoading } from 'app/components/Buttons';
// 
import ReservationListItem from './ReservationListItem';

// Selectors
import { getRemotelockIntegrationLocked } from 'store/setting/setting.selectors';

const parseQueryByName = (search, name) => {
  if ( !search ) return '';
  return new URLSearchParams(search).get(name) || '';
}

const ReservationList = ({
  // Props
  // reservationStatus,
  // State
  reservations, reservationTotal, status, params, remotelockIntegrationLocked,
  // Dispatch
  fetchReservations, resetParams
}) => {
  const { bookingId } = useParams();
  const { search } = useLocation();

  useEffect(() => {
    const nextReservationNumber = parseQueryByName(search, 'reservationNumber');
    const nextStatuses = parseQueryByName(search, 'statuses');
    fetchReservations(bookingId, {
      ...params,
      statuses: nextStatuses,
      reservationNumber: nextReservationNumber,
      initial: true
    });
    return () => resetParams();
    // eslint-disable-next-line
  }, []);

  const handleClickLoadMore = () => {
    const { limit, offset } = params;
    fetchReservations(bookingId, { ...params, offset: offset + limit }, true);
  }

  return (
    <>
      <ContentLoading data={reservations} status={status}>
        <div className="row row-8">
          {reservations.map((reservation, index) => (
            <ReservationListItem
              key={index}
              reservation={reservation}

              remotelockIntegrationLocked={remotelockIntegrationLocked}
            />
          ))}
        </div>
        {(reservationTotal !== reservations.length) &&
          <div className="pt-3 text-center">
            <ButtonLoading
              loading={status === 'FetchingMore'}
              onClick={handleClickLoadMore}
            >Load more</ButtonLoading>
          </div>
        }
      </ContentLoading>
    </>
  )
}

const mapStateToProps = (state) => ({
  booking: BookingSelectors.getBooking(state.BookingState),
  reservations: ReservationSelectors.getReservations(state.ReservationState),
  reservationTotal: ReservationSelectors.getReservationTotal(state.ReservationState),
  status: ReservationSelectors.getStatus(state.ReservationState),
  params: ReservationSelectors.getParams(state.ReservationState),

  remotelockIntegrationLocked: getRemotelockIntegrationLocked(state)
});

const mapDipsatchToProps = dispatch => ({
  fetchReservations: (bookingId, params, isLoadMore) => dispatch(ReservationActions.fetchReservations(bookingId, params, isLoadMore)),
  resetParams: () => dispatch(ReservationActions.resetParams())
});

export default connect(mapStateToProps, mapDipsatchToProps)(ReservationList);
