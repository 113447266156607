import { Action } from 'redux';
import Statuses from 'types/statuses';

export interface IAccount {
  id: number;
  email: string;
  name: string;
  adminPhone: string;
  adminCallingCode: string;
  adminEmail: string;
  callingCode: string;
  phone: string;
  settings: Array<{ name:string, value:string }>
}
export interface IAccountData {
  email: string;
  name: string;
  adminPhone?: string;
  adminCallingCode?: string;
  adminEmail?: string;
  callingCode: string;
  phone: string;
  settings?: Array<{ name:string, value:string }>
};

export interface IAccountState {
  items: Array<IAccount> | null;
  settings: Array<string> | null;
  isFetching: boolean;
  isLoading: boolean;
  status: Statuses;
}

// Messages
export enum AccountMessages {
  Create = 'Account was created',
  Update = 'Account was updated',
  Delete = 'Account was delete'
}

// Types
export const FETCH_LIST = '@@accountManager/Fetch list';
export const FETCH_LIST_SUCCESS = '@@accountManager/Fetch list success';

export const CREATE = '@@accountManager/Create';
export const CREATE_SUCCESS = '@@accountManager/Create success';

export const UPDATE = '@@accountManager/Update';
export const UPDATE_SUCCESS = '@@accountManager/Update success';

export const DELETE = '@@accountManager/Delete';
export const DELETE_SUCCESS = '@@accountManager/Delete success';

export const FETCH_SETTING_NAMES = '@@accountManager/Fetch setting names';
export const FETCH_SETTING_NAMES_SUCCESS = '@@accountManager/Fetch setting names success';

export const SET_STATUS = '@@accountManager/Set status';

// Actions
interface FetchList extends Action<typeof FETCH_LIST> {}
interface FetchListSuccess extends Action<typeof FETCH_LIST_SUCCESS> {
  payload: { items:Array<IAccount> }
}

interface Create extends Action<typeof CREATE> {
  payload: { data:IAccountData }
}
interface CreateSuccess extends Action<typeof CREATE_SUCCESS> {
  payload: { account:IAccount }
}

interface Update extends Action<typeof UPDATE> {
  payload: { id:number, data:IAccountData }
}
interface UpdateSuccess extends Action<typeof UPDATE_SUCCESS> {
  payload: { account:IAccount }
}

interface Delete extends Action<typeof DELETE> {
  payload: { account:IAccount }
}
interface DeleteSuccess extends Action<typeof DELETE_SUCCESS> {
  payload: { id:number }
}

interface FetchSettingNames extends Action<typeof FETCH_SETTING_NAMES> {}
interface FetchSettingNamesSuccess extends Action<typeof FETCH_SETTING_NAMES_SUCCESS> {
  payload: { settings:Array<string> }
}

interface SetStatus extends Action<typeof SET_STATUS> {
  payload: { status:Statuses }
}

export type AccountActionTypes =
  FetchList | FetchListSuccess |
  Create | CreateSuccess |
  Update | UpdateSuccess |
  Delete | DeleteSuccess |
  FetchSettingNames | FetchSettingNamesSuccess |
  SetStatus
;