import * as AuthTypes from '../types/auth.types';
import UserTypes from '../types/user.types';

const initialState = {
  user: {},
  token: '',
  isAuthenticated: '',

  isFetching: true,
  isFormLoading: false,
  isFormSuccess: false
};

export default function(state = initialState, action){
  const { type } = action;
  switch (type){
    // Request
    case UserTypes.FETCH_ME:
      return { ...state, user: {}, token: '', isFetching: true };
    case AuthTypes.LOGIN:
      return { ...state, isFormLoading: true, isFormSuccess: false };
    case UserTypes.UPDATE_ME:
      return { ...state, isFormLoading: true, isFormSuccess: true };
    // Success
    case AuthTypes.SET_LOGIN_CREDENTIAL:
      return _setLoginCredential(state, action.authCredential);
    case AuthTypes.LOGOUT_SUCCESS:
      return _logoutSuccess(state);
    case UserTypes.FETCH_ME_SUCCESS:
      return { ...state, user: action.payload.user, isAuthenticated: true, isFetching: false };
    case UserTypes.UPDATE_ME_SUCCESS:
      return _updateMeSuccess(state, action.payload.user);
    // Failure
    case AuthTypes.LOGIN_FAILURE:
    case UserTypes.UPDATE_ME_FAILURE:
      return { ...state, isFormLoading: false, isFormSuccess: false };
    case UserTypes.FETCH_ME_FAILURE:
      return { ...state, user: {}, token: '', isAuthenticated: false, isFetching: false };
    // Other
    case AuthTypes.RESET_FORM:
      return _resetForm(state);
    case AuthTypes.SET_IS_FETCHED:
      return { ...state, isFetching: false };
    default:
      return state;
  }
}

// Login
const _setLoginCredential = (state, { accessToken }) => ({
  ...state, token: accessToken, isFormLoading: false, isFormSuccess: true
});

// Logout success
const _logoutSuccess = state => ({
  ...state, user: {}, token: '', isAuthenticated: false, isFetching: false
});

// Update success
const _updateMeSuccess = (state, user) => ({
  ...state, user, isFormLoading: false, isFormSuccess: true
});

// Reset form
const _resetForm = state => ({
  ...state, isFormLoading: false, isFormSuccess: false
});
