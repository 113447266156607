import React, { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
// Redux
import { IInventoryGroup } from 'store/inventory-group/inventory-group.types';
// Bootstrap
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
// Dialog
import InventoryGroupFormDialog from './InventoryGroupFormDialog';

type IProps = {
  item: IInventoryGroup;
  onDelete: (id:number) => void;
  onEnable: (id:number) => void;
  onDisable: (id:number) => void;
}

const InventoryGroupsListItem:React.FC<IProps> = ({
  // Props
  item, onDelete, onEnable, onDisable
}) => {
  const { id, name, type, enabled, pickupLocation, deliveryAvailable, tax, availability } = item;

  const days = typeof availability !== 'undefined' ? Object.keys(availability) : [];
  const isAnyDayAvailable = days.length !== 0 ? days.some((d:string) => availability[d]['available']) : false;

  const { path } = useRouteMatch();

  const [show, setShow] = useState<boolean>(false);

  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);

  const handleEnable = () => onEnable(id);
  const handleDisable = () => onDisable(id);
  const handleDelete = () => onDelete(id);

  return (
    <>
      <div className="card | w-100">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="card-title mb-1">{name}</h5>
          </div>
          <div className="mb-1">
            <span className={`badge ${enabled ? 'badge-success' : 'badge-danger'} | mr-1`}>
              {enabled ? 'Enabled' : 'Disabled'}
            </span>
            <span className={`badge ${deliveryAvailable ? 'badge-success' : 'badge-danger'}`}>
              {deliveryAvailable ? 'Delivery available' : 'Delivery not available'}
            </span>
          </div>
          <small className="d-block text-muted mb-1">Type <b className="text-dark">{type}</b></small>
          {pickupLocation ? (
            <small className="d-block text-muted mb-1">Pickup at <b className="text-dark">{pickupLocation}</b></small>
          ) : null}
          {isAnyDayAvailable &&
            <small className="d-block text-muted mb-1" style={{wordBreak: 'break-all'}}>
              Available at&nbsp;
              <b className="text-dark">
                {days.map((day, index) => {
                  if ( !availability[day]['available'] ) return null;
                  return (
                    <span key={index} style={{textTransform: 'capitalize'}}>
                      {day.slice(0, 3)}&nbsp;
                    </span>
                  );
                })}
              </b>
            </small>
          }
          {/* {tax !== 0 ? (
            <small className="d-block text-muted">Tax <b className="text-dark">{tax}%</b></small>
          ) : null} */}
        </div>
        <div className="card-footer | text-right">
          <button
            className="btn btn-primary btn-sm | mr-2"
            type="button"
            onClick={handleShow}
          >Edit</button>
          <DropdownButton
            as={ButtonGroup}
            alignRight
            id={`inventory-group-item-${id}-dropdown`}
            size="sm"
            variant="outline-secondary"
            title={''}
          >
            <Dropdown.Item as={Link} to={`${path}/${id}/waivers`}>Waivers</Dropdown.Item>
            {enabled ? (
              <Dropdown.Item as="button" onClick={handleDisable}>Disable</Dropdown.Item>
            ) : (
              <Dropdown.Item as="button" onClick={handleEnable}>Enable</Dropdown.Item>
            )}
            <Dropdown.Item
              as="button"
              className="text-danger"
            onClick={handleDelete}>Delete</Dropdown.Item>
          </DropdownButton>
        </div>
      </div>
      {show ? (
        <InventoryGroupFormDialog
          inventoryGroup={item}
          show={show}
          onHide={handleHide}
        />
      ) : null}
    </>
  )
}

export default InventoryGroupsListItem;
