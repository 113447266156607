import React, { useState } from 'react';
// Bootstrap
import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
// Dialogs
import ContentCategoryFormDialog from 'dialogs/ContentCategoryForm.dialog';

const ContentCategoriesListItem = ({
  // Props
  item, onEnable, onDisable, onDelete
}) => {
  const { name, description, attributes } = item;
  const [ show, setShow ] = useState(false);

  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);

  const handleEnable = () => onEnable(item.id);
  const handleDisable = () => onDisable(item.id);
  const handleDelete = () => onDelete(item);

  return (
    <>
      <div className="col-12 col-sm-6 col-md-4 col-lg-3 | d-flex">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="card-title mb-1">{name}</h5>
            </div>
            <div className="mb-1">
              <span className={`badge ${item.enabled ? 'badge-success' : 'badge-danger'}`}>
                {item.enabled ? 'Enabled' : 'Disabled'}
              </span>
            </div>
            {description ? (
              <small className="d-block card-text mb-2">{description}</small>
            ) : null}
            {attributes && attributes.length !== 0 ? (
              <small>
                <b className="d-block">Attributes</b>
                {attributes.join(', ')}
              </small>
            ) : null}
          </div>
          <div className="card-foot">
            <button
              className="btn btn-primary btn-sm | mr-2"
              type="button"
              onClick={handleShow}
            >Edit</button>
            <DropdownButton
              as={ButtonGroup}
              alignRight
              id={`content-category-list-item-dropdown-${item.id}`}
              size="sm"
              variant="outline-secondary"
              title={''}
            >
              {!item.enabled
                ? <Dropdown.Item as="button" onClick={handleEnable}>Enable</Dropdown.Item>
                : <Dropdown.Item as="button" onClick={handleDisable}>Disable</Dropdown.Item>
              }
              <Dropdown.Item as="button" className="text-danger" onClick={handleDelete}>Delete</Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
      </div>
      {show ? (
        <ContentCategoryFormDialog
          contentCategory={item}
          show={show}
          onHide={handleHide}
        />
      ) : null}
    </>
  )
}

export default ContentCategoriesListItem;
