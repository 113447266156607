import { takeLatest, call, put } from 'redux-saga/effects';
// Service
import {
  $fetchScheduleSlots,
  $createScheduleSlot,
  $updateScheduleSlot,
  $deleteScheduleSlot
} from '../services/schedule.service';
// Types
import * as LayoutActions from 'app/actions/layout.actions';
import * as Types from '../types/schedule.types';
// Actions
import {
  fetchScheduleSlotsSuccess,
  createScheduleSlotSuccess, createScheduleSlotFailure,
  updateScheduleSlotSuccess, updateScheduleSlotFailure,
  deleteScheduleSlotSuccess, deleteScheduleSlotFailure
} from '../actions/schedule.actions';

// Fetch schedule slots list
function* _fetchScheduleSlots(action){
  try {
    const response = yield call($fetchScheduleSlots, action.params);
    yield put(fetchScheduleSlotsSuccess(response));
  } catch(err){
    console.error(err);
  }
}

function* _createScheduleSlot(action){
  try {
    const response = yield call($createScheduleSlot, action.slot);
    yield put(createScheduleSlotSuccess(response));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: 'Slot was created'
    }));
  } catch(err){
    yield put(createScheduleSlotFailure());
    console.error(err);
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: err.message
    }));
  }
}

function* _updateScheduleSlot(action){
  try {
    const response = yield call($updateScheduleSlot, action.slot);
    yield put(updateScheduleSlotSuccess(response));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: 'Slot was updated'
    }));
  } catch(err){
    yield put(updateScheduleSlotFailure());
    console.error(err);
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: err.message
    }));
  }
}

// Delete template
function* _deleteScheduleSlot(action){
  try {
    if ( window.confirm('Are you sure you want to delete schedule slot?') ){
      yield call($deleteScheduleSlot, action.slotId);
      yield put(deleteScheduleSlotSuccess(action.slotId));
      yield put(LayoutActions.addNotification({
        type: 'success',
        message: 'Slot was deleted'
      }));
    }
  } catch(err){
    yield put(deleteScheduleSlotFailure());
    console.error(err);
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: err.message
    }));
  }
}

export function* scheduleSaga(){
  yield takeLatest(Types.FETCH_SCHEDULE_SLOTS, _fetchScheduleSlots);
  yield takeLatest(Types.CREATE_SCHEDULE_SLOT, _createScheduleSlot);
  yield takeLatest(Types.UPDATE_SCHEDULE_SLOT, _updateScheduleSlot);
  yield takeLatest(Types.DELETE_SCHEDULE_SLOT, _deleteScheduleSlot);
}