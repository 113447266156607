import { WaiverActionTypes } from './waiver.types';

const initialState = {
  waivers: [],
  waiver: {},
  status: 'Initial'
}

export default (state = initialState, action) => {
  switch (action.type) {
    // Fetch list
    case WaiverActionTypes.FetchList:
      return { ...state, waivers: initialState.waivers, status: 'Fetching' }
    case WaiverActionTypes.FetchListSuccess:
      return { ...state, waivers: action.waivers, status: 'Fetched' };
    // Fetch by id
    case WaiverActionTypes.FetchById:
      return { ...state, waiver: initialState.waiver, status: 'ModalFetching' };
    case WaiverActionTypes.FetchByIdSuccess:
      return { ...state, waiver: action.waiver, status: 'ModalFetched' };
    case WaiverActionTypes.ClearWaiver:
      return { ...state, waiver: initialState.waiver };
    // Create
    case WaiverActionTypes.Create:
      return { ...state, status: 'Loading' };
    case WaiverActionTypes.CreateSuccess:
      return { ...state, waivers: [...state.waivers, action.waiver], status: 'Loaded' };
    // Update
    case WaiverActionTypes.Update:
      return { ...state, status: 'Loading' };
    case WaiverActionTypes.UpdateSuccess:
      return {
        ...state,
        waivers: state.waivers.map(b =>
          b.id === action.waiver.id
          ? { ...b, ...action.waiver }
          : b
        ),
        status: 'Loaded'
      };
    // Delete
    case WaiverActionTypes.Delete:
      return state;
    case WaiverActionTypes.DeleteSuccess:
      return {
        ...state,
        waivers: state.waivers.filter(b => b.id !== action.waiverId)
      };
    // Set status
    case WaiverActionTypes.StatusSuccess:
      return { ...state, status: 'Success' };
    case WaiverActionTypes.StatusError:
      return { ...state, status: 'Error' };
    case WaiverActionTypes.StatusInitial:
      return { ...state, status: 'Initial' };
    case WaiverActionTypes.Reset:
      return { ...state, ...initialState };
    default:
      return state;
  }
}