import './index.scss';
import React from 'react';
import { render } from 'react-dom';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import App from 'app/App';
import configureStore, { history } from './app/App.store';
import config from './config';

import * as serviceWorker from './serviceWorker';

export const store = configureStore();

declare global {
  interface Array<T> {
    swap(a: T, b:T): Array<T>;
  }
}

Array.prototype.swap = function(indexA, indexB){
  let input = this;
  let temp = input[indexA];
  input[indexA] = input[indexB];
  input[indexB] = temp;
  return input;
}

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

if ( config.production && window ) window.console.log = function(){};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
