// Types
export const FETCH_LIST = '[Message Templates] Fetch list';
export const FETCH_LIST_SUCCESS = '[Message Templates] Fetch list success';

export const FETCH_BY_ID = '[Message Templates] Fetch by id';
export const FETCH_BY_ID_SUCCESS = '[Message Templates] Fetch by id success';

export const UPDATE = '[Message Templates] Update';
export const UPDATE_SUCCESS = '[Message Templates] Update success';

export const SET_STATUS = '[Message Templates] Set status'

// Actions
export const MessageTemplateActions = {
  // Fetch list
  fetchList: () => ({
    type: FETCH_LIST
  }),
  fetchListSuccess: items => ({
    type: FETCH_LIST_SUCCESS,
    payload: { items }
  }),
  // Create
  fetchById: id => ({
    type: FETCH_BY_ID,
    payload: { id }
  }),
  fetchByIdSuccess: item => ({
    type: FETCH_BY_ID_SUCCESS,
    payload: { item }
  }),
  // Update
  update: (id, data) => ({
    type: UPDATE,
    payload: { id, data }
  }),
  updateSuccess: item => ({
    type: UPDATE_SUCCESS,
    payload: { item }
  }),
  // Default
  // Set status
  setStatus: status => ({
    type: SET_STATUS,
    payload: { status }
  })
}
