import React from 'react';
import { Link } from 'react-router-dom';
// Store
import { connect } from 'react-redux';
import ProductActions from '../../../actions/product.actions';

const ProductListItem = ({ path, product, onDelete }) => {
  return (
    <div className="col-12 col-md-6 col-lg-4 col-xl-3 | d-flex">
      <div className="card card-product">
        {product.image && product.image.href
          ? 
            <div className="card-image" style={{backgroundImage: `url(${product.image.href})`}}></div>
          :
            <div className="card-no-image">
              <i className="fa fa-image" aria-hidden="true"></i>
              <h5>No image</h5>
            </div>
        }
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-end">
            <h5 className="card-title mb-1">{product.name}</h5>
            <p className="card-text mb-1 ms-1">{`$${product.price}`}</p>
          </div>
          <div className="d-flex justify-content-between align-items-start | mb-1">
            <span className={`badge ${product.available ? 'badge-success' : 'badge-danger'}`}>
              {product.available ? 'Available' : 'Not available'}
            </span>
            {typeof product.quantity !== 'undefined' &&
              <p className="card-text text-muted mb-1 ms-1" style={{lineHeight: '1'}}>{`x${product.quantity}`}</p>
            }
          </div>
          {product.reservationAvailable &&
            <div className="mb-1"><span className="badge badge-success">Reservable</span></div>
          }
          <p className="card-text mb-0">{product.description}</p>
          <small className="text-muted">{product.inventoryGroup.name}</small>
          {(product.productCategory && product.productCategory.id) ? (
            <>
              &nbsp;&bull;&nbsp;<small className="text-muted">{product.productCategory.name}</small>
            </>
          ) : null}
        </div>
        <div className="card-foot">
          <Link
            className="btn btn-primary btn-sm | mr-2"
            to={`${path}/${product.id}/update`}
          >Edit</Link>
          <button
            className="btn btn-outline-danger btn-sm"
            type="button"
            onClick={() => onDelete(product)}
          >Delete</button>
        </div>
      </div>
    </div>
  );
}

export default connect(
  null,
  dispatch => ({
    onDelete: product => dispatch(ProductActions.delete(product))
  })
)(ProductListItem);
