import React from 'react';
import { useForm } from 'react-hook-form';
// Redux
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ReservationActions } from 'app/store/reservation/reservation.actions';
import * as BookingSelectors from 'app/store/booking/booking.selectors';
// React bootstrap
import { Modal } from 'react-bootstrap';
// Components
import { ButtonLoading } from 'app/components/Buttons';
// Utilities
import { setStart, parseDate, parseDateTime, toCamperZone, checkIsSameOrBefore } from 'app/utilities/datetime.convert';
import { isRequired } from 'utilities/validation';
import { build24Hours } from 'utilities/other.utility';

const formatISO = 'YYYY-MM-DDTHH:mm:ss.sss[Z]';
const formatDate = 'YYYY-MM-DD';
const formatTime = 'HH:mm';

type Props = {
  // Props
  reservation: any;
  show: boolean;
  onHide: () => void;
  // State
  booking: any;
  reservationShift: any;
  status: string;
  // Dispatch
  shift: (reservationId:number, data:any) => void;
  resetField: (field:string) => void;
}

const ReservationShiftDialog:React.FC<Props> = ({
  // Props
  reservation, show, onHide,
  // State
  booking, reservationShift, status,
  // Dispatch
  shift, resetField
}) => {
  React.useEffect(() => {
    return () => resetField('reservationShift');
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if ( status === 'Success' ) onHide();
    // eslint-disable-next-line
  }, [status]);

  const { register, handleSubmit, errors, getValues } = useForm<any>({
    defaultValues: {
      startDate: parseDate(reservation.start, formatDate) || '',
      startTime: parseDateTime(reservation.start, formatTime) || ''
    }
  });

  const onSubmit = handleSubmit((data:any) => {
    const newData:any = {}
    if ( booking.period !== 'hour' ){
      newData['start'] = setStart(data.startDate, formatISO);
    } else {
      const dateTime = `${data.startDate}T${data.startTime}`;
      newData['start'] = setStart(dateTime, formatISO, false);
    }
    shift(reservation.id, newData);
  });

  const handleShiftAnyway = () => {
    const values = getValues();
    const newData:any = { force: true };
    if ( booking.period !== 'hour' ){
      newData['start'] = setStart(values.startDate, formatISO);
    } else {
      const dateTime = `${values.startDate}T${values.startTime}`;
      newData['start'] = setStart(dateTime, formatISO, false);
    }
    shift(reservation.id, newData);
  }

  return (
    <Modal show={show} onHide={onHide} backdropClassName="modal-backdrop-nested">
      <form onSubmit={onSubmit}>
        <Modal.Header>#{reservation.reservationNumber}</Modal.Header>
        <Modal.Body>
          <div className="row row-8">
            <div className={`${ booking.period === 'hour' ? 'col-8' : 'col-12'} form-group`}>
              <label htmlFor="startDate">Start Date&nbsp;*</label>
              <input
                ref={register({ required: isRequired, validate: {
                  beforeOrNow: v => validateBeforeOrNow(v)
                } })}
                className={`form-control form-control-sm ${ errors.startDate && 'is-invalid' }`}
                id="startDate" name="startDate" type="date"
                required
              />
              {errors.startDate
                ? 
                  errors.startDate.type === 'beforeOrNow'
                  ? <div className="invalid-feedback">Start date can`t be now or before</div>
                  : <div className="invalid-feedback">{errors.startDate.message}</div>
                : null
              }
            </div>
            {booking.period === 'hour' ? (
              <div className="col-4 form-group">
                <label htmlFor="startTime">Start time</label>
                <select
                  ref={register()}
                  className="form-control form-control-sm"
                  id="startTime" name="startTime"
                >
                  {build24Hours().map((hour:{ value:string, label:string }, index:number) => (
                    <option key={`hour-item-${index}`} value={hour.value}>{hour.label}</option>
                  ))}
                </select>
              </div>
            ) : null}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {(reservationShift && reservationShift.messages && reservationShift.messages.length) ? (
            <>
              {reservationShift.messages.map((message:string, index:number) => (
                <div key={`error-message-${index}`} className="alert alert-danger w-100 mb-2">{message}</div>
              ))}
            </>
          ) : null}
          <button
            className="btn btn-outline-secondary btn-sm"
            type="button"
            onClick={onHide}
          >Cancel</button>
          {(reservationShift && !reservationShift.datesChanged) ? (
            <ButtonLoading
              classes="btn btn-outline-danger btn-sm"
              loading={status === 'Loading'}
              onClick={handleShiftAnyway}
            >Shift anyway</ButtonLoading>
          ) : (
            <ButtonLoading
              type="submit"
              loading={status === 'Loading'}
            >Shift</ButtonLoading>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  )
}

const validateBeforeOrNow = (value:any) => {
  return !checkIsSameOrBefore(value, toCamperZone(new Date()), 'day');
}

const mapStateToProps = ({ BookingState, ReservationState }:any) => ({
  booking: BookingSelectors.getBooking(BookingState),
  reservationShift: ReservationState.reservationShift,
  status: ReservationState.status
});

const mapDispatchToProps = (dispatch:Dispatch) => ({
  shift: (reservationId:number, data:any) => dispatch(ReservationActions.shift(reservationId, data)),
  resetField: (field:string) => dispatch(ReservationActions.resetField(field))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReservationShiftDialog);
