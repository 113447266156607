import React from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
// Components
import { Loader } from 'components/Utility';
// 
import WaiversToolbar from './WaiversToolbar';
import WaiversList from './WaiversList';

const inventoryGroupURL = '/inventory-groups';
const membershipTypeURL = '/membership-types';
const bookingPropertyURL = '/booking-properties';

type Props = {};

const WaiversPage:React.FC<Props> = () => {
  const { id, bookingId } = useParams<{ id:string, bookingId:string }>();
  const { url } = useRouteMatch<{ url:string }>();

  const [ params, setPrams ] = React.useState<any>(null);

  React.useEffect(() => {
    const idToNum = bookingId ? Number(bookingId) : Number(id);
    if ( url.includes(inventoryGroupURL) ) setPrams({ inventoryGroupId: idToNum });
    if ( url.includes(membershipTypeURL) ) setPrams({ membershipTypeId: idToNum });
    if ( url.includes(bookingPropertyURL) ) setPrams({ bookingPropertyId: idToNum });
    // eslint-disable-next-line
  }, []);

  if ( !params ) return <Loader />
  return (
    <div className="container-fluid pt-3 pb-3">
      <WaiversToolbar params={params} />
      <WaiversList params={params} />
    </div>
  )
}

export default WaiversPage;
