import { all } from 'redux-saga/effects';
// Next rework
import StaffSaga from 'store/staff/staff.saga';
import MailTemplateSaga from 'store/mail-template/mail-template.saga';
import AccountManagerSaga from 'store/account/account.saga';
import SettingSaga from 'store/setting/setting.saga';
import MapSaga from 'store/map/map.saga';
import MapPoiSaga from 'store/map-poi/map-poi.saga';
import InventoryGroupSaga from 'store/inventory-group/inventory-group.saga';
import OrderSaga from 'store/order/order.saga';
import WaiverSaga from 'store/waiver/waiver.saga';
import SeasonSaga from 'store/season/season.saga';
import MembershipSaga from 'store/membership/membership.saga';
import MembershipTypeSaga from 'store/membership-type/membership-type.saga';
import RemotelockDeviceSaga from 'store/remotelock-device/remotelock-device.saga';
import TaxesSaga from 'store/taxes/taxes.saga';

// Sagas
import { layoutSaga } from 'app/sagas/layout.saga';
import { notificationSaga } from 'app/sagas/notification.saga';
import { authSaga } from 'app/sagas/auth.saga';
import { userSaga } from 'app/sagas/user.saga';
import { templateSaga } from 'app/sagas/template.saga';
import { scheduleSaga } from 'app/sagas/schedule.saga';
import { productSaga } from 'app/sagas/product.saga';
import { orderSaga } from 'app/sagas/order.saga';
// import { contentItemSaga } from 'app/sagas/content-item.saga';
// import { categorySaga } from 'app/sagas/category.saga';
import { assetSaga } from 'app/sagas/asset.saga';
// import { mailTemplateSaga } from 'app/sagas/mail-template.saga';
import { inventoryGroupSaga } from 'app/sagas/inventory-group.saga';
// Next
import { default as appSaga } from 'app/store/app/app.saga';
import { default as _userSaga } from 'app/store/user/user.saga';
import { default as bookingSaga } from 'app/store/booking/booking.saga';
import { default as bookingItemSaga } from 'app/store/booking-item/booking-item.saga';
import { default as blackoutSaga } from 'app/store/blackout/blackout.saga';
import { default as reservationSaga } from 'app/store/reservation/reservation.saga';
import { default as reservationCategorySaga } from 'app/store/reservation-category/reservation-category.saga';
import { default as restrictionSaga } from 'app/store/restriction/restriction.saga';
import { default as availabilitySaga } from 'app/store/availability/availability.saga';
import { default as waiverSaga } from 'app/store/waiver/waiver.saga';
import { default as financialReportSaga } from 'app/store/financial-report/financial-report.saga';
import { default as contactMessageSaga } from 'app/store/contact-message/contact-message.saga';
import { default as productCategorySaga } from 'app/store/product-category/product-category.saga';
import { default as messageTemplateSaga } from 'app/store/message-template/message-template.saga';

import { default as contentCategorySaga } from 'app/store/content-category/content-category.saga';
import { default as contentItemSaga } from 'app/store/content-item/content-item.saga';

export default function* rootSaga(){
  yield all([
    // Next rework
    StaffSaga(),
    MailTemplateSaga(),
    AccountManagerSaga(),
    SettingSaga(),
    MapSaga(),
    MapPoiSaga(),
    InventoryGroupSaga(),
    OrderSaga(),
    WaiverSaga(),
    SeasonSaga(),
    MembershipSaga(),
    MembershipTypeSaga(),
    RemotelockDeviceSaga(),
    TaxesSaga(),


    layoutSaga(),
    notificationSaga(),
    authSaga(),
    userSaga(),
    templateSaga(),
    scheduleSaga(),
    productSaga(),
    orderSaga(),
    // categorySaga(),
    assetSaga(),
    // mailTemplateSaga(),
    inventoryGroupSaga(),
    contactMessageSaga(),
    productCategorySaga(),
    // Next
    appSaga(),
    _userSaga(),
    bookingSaga(),
    bookingItemSaga(),
    blackoutSaga(),
    reservationSaga(),
    reservationCategorySaga(),
    restrictionSaga(),
    availabilitySaga(),
    waiverSaga(),
    financialReportSaga(),
    contentCategorySaga(),
    contentItemSaga(),
    messageTemplateSaga()
  ]);
}
