import React, { useState, useEffect, Fragment } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
// Redux
import { connect } from 'react-redux';
import UserActions from 'app/actions/user.actions';
import { ReservationActions } from 'app/store/reservation/reservation.actions';
import { ReservationCategoryActions } from 'app/store/reservation-category/reservation-category.actions';
import * as ReservationSelectors from 'app/store/reservation/reservation.selectors';
import * as ReservationCategorySelectors from 'app/store/reservation-category/reservation-category.selectors';
import * as UserSelectors from 'app/_selectors/user.selectors';
// Bootstrap
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import Modal from 'react-bootstrap/Modal';
// Dialogs
import SquarePaymentFormDialog from 'dialogs/SquarePaymentForm.dialog';
// Components
import { ContentLoading } from 'app/components/Loadings';
import { ButtonLoading } from 'app/components/Buttons';
// Utilities
import { setStart } from 'app/utilities/datetime.convert';
import { isRequired } from 'utilities/validation';
// 
import ReservationSearchForm from './ReservationSearchForm';
import ReservationCategories from './ReservationCategories';
import ReservationTimeSlots from './ReservationTimeSlots';

const format = 'YYYY-MM-DDTHH:mm:ss.sss[Z]';

const ReservationFormModal = ({
  // Props
  show, onHide,
  // State
  booking, users, userStatus, reservationStatus, availableCategories, totalPrice, status,
  // Dispatch
  searchUsers, createReservation, clearAvailableCategories
}) => {
  const { bookingId } = useParams();
  const [ showDialog, setShowDialog ] = useState(false);
  const [ formData, setFormData ] = useState({});
  const [ fetched, setFetched ] = useState(false);
  const [ data, setData ] = useState({
    formVisible: false,
    totalPrice: 0,
    slot: null,

    start: '',
    end: '',
    hours: null,
    quantity: null,
    items: [],
  });

  const { control, register, handleSubmit, errors, setValue, getValues, watch } = useForm({
    defaultValues: {
      totalPrice: 0,
      depositPaid: 0,
      userId: null,
      notes: '',
      paymentType: 'cash' // 'cash' | 'online'
    }
  });

  const onSubmit = formData => {
    const { totalPrice, depositPaid, userId, notes, petFeeIncluded, paymentType } = formData;
    const newData = {
      bookingPropertyId: Number(bookingId),
      items: data.items,
      totalPrice: Number(totalPrice),
      depositPaid: Number(depositPaid),
      paymentType: Number(totalPrice) === 0 ? 'cash' : paymentType
    };
    if ( booking.period !== 'hour' ){
      newData['start'] = setStart(data.start, format);
      newData['end'] = setStart(data.end, format);
    } else {
      const [startTime, endTime] = data.slot.split('-');
      const [startHour] = startTime.split(':');
      const [endHour] = endTime.split(':');
      newData['start'] = dayjs(data.start).hour(startHour).minute(0).second(0).format(format);
      if ( endHour === '00' ){
        newData['end'] = dayjs(data.start).hour(endHour).minute(0).second(0).add(1, 'day').format(format);
      } else {
        newData['end'] = dayjs(data.start).hour(endHour).minute(0).second(0).format(format);
      }
    }
    if ( userId ) newData['userId'] = userId;
    if ( notes ) newData['notes'] = notes;
    if ( petFeeIncluded ) newData['petFeeIncluded'] = petFeeIncluded;

    setFormData(newData);

    if ( newData.totalPrice === 0 || paymentType === 'cash' ){
      createReservation(newData);
    } else {
      setShowDialog(true);
    }
  }

  useEffect(() => {
    return () => clearAvailableCategories();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( status === 'FetchedModal' ) setFetched(true);
    // eslint-disable-next-line
  }, [status]);

  useEffect(() => {
    if ( reservationStatus === 'Success' ) onHide();
    // eslint-disable-next-line
  }, [reservationStatus]);

  useEffect(() => {
    setValue('totalPrice', booking.period === 'day' ? totalPrice : data.totalPrice)
    // eslint-disable-next-line
  }, [data.totalPrice, totalPrice]);

  const handleChangePetFee = (checked, onChange) => {
    const totalPrice = Number(getValues('totalPrice'));
    setValue('totalPrice', checked ? totalPrice + booking.petFee : totalPrice - booking.petFee);
    onChange(checked);
  }

  const totalPriceWatched = watch('totalPrice');

  return (
    <>
      <Modal show={show} onHide={onHide} size="lg">
        <Modal.Header>Create reservation</Modal.Header>
        <Modal.Body>
          <ReservationSearchForm setData={setData} />
          <ContentLoading data={availableCategories} status={status === 'FetchingModal' ? 'Fetching' : status === 'FetchedModal' ? 'Fetched' : ''}>
            {fetched ? (
              <>
                {booking.period === 'day'
                  ? <ReservationCategories data={data} setData={setData} />
                  : <ReservationTimeSlots data={data} setData={setData} />
                }
                {data.formVisible && data.items.length === 0
                  ? 
                    <small className="text-danger">
                      {booking.period === 'day'
                        ? 'Should be selected one of category'
                        : 'Should be selected one of slot'
                      }
                    </small>
                  : null
                }
                <form className={
                  (data.formVisible && data.items.length !== 0) ? 'd-block' : 'd-none'
                } onSubmit={handleSubmit(onSubmit)}>
                  <div className="row row-8">
                    <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="totalPrice">Total price *</label>
                        <div className="input-group input-group-sm">
                          <div className="input-group-prepend">
                            <div className="input-group-text">$</div>
                          </div>
                          <input
                            ref={register({
                              required: isRequired,
                              min: {
                                value: 0,
                                message: 'Total price can`t be less than 0'
                              }
                            })}
                            className={`form-control form-control-sm ${ errors.totalPrice ? 'is-invalid' : null }`}
                            id="totalPrice" name="totalPrice" type="number"
                          />
                          {errors.totalPrice
                            ? <div className="invalid-feedback">{errors.totalPrice.message}</div>
                            : null
                          }
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      {/* Total price */}
                      <div className="form-group">
                        <label htmlFor="depositPaid">Deposit paid *</label>
                        <div className="input-group input-group-sm">
                          <div className="input-group-prepend">
                            <div className="input-group-text">$</div>
                          </div>
                          <input
                            ref={register({
                              required: isRequired,
                              min: {
                                value: 0,
                                message: 'Deposit paid can`t be less than 0'
                              },
                              validate: value => {
                                if ( !value ) return true;
                                return Number(value) <= Number(totalPriceWatched);
                              }
                            })}
                            className={`form-control form-control-sm ${ errors.depositPaid ? 'is-invalid' : null }`}
                            id="depositPaid" name="depositPaid" type="number"
                          />
                          {errors.depositPaid
                            ? 
                              errors.depositPaid.type === 'validate'
                              ? <div className="invalid-feedback">Deposit paid can't be greater than total price</div>
                              : <div className="invalid-feedback">{errors.depositPaid.message}</div>
                            : null
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Users */}
                  <Controller
                    control={control}
                    name="userId"
                    render={({ onChange, onBlur, value }) => (
                      <div className="form-group">
                        <label htmlFor="">Users</label>
                        <AsyncTypeahead
                          id="customers"
                          isLoading={userStatus === 'Searching' ? true : false}
                          onChange={selected => {
                            if ( selected && selected.length !== 0 ){
                              onChange(selected[0].id)
                            }
                          }}
                          options={users}
                          onSearch={query => searchUsers(query)}
                          size="small"
                          align="left"
                        />
                      </div>
                    )}
                  />
                  {/* Notes */}
                  <div className="form-group">
                    <label htmlFor="notes">Notes</label>
                    <textarea
                      ref={register()}
                      className="form-control form-control-sm"
                      id="notes" name="notes" rows="5"
                    ></textarea>
                  </div>
                  {/* Pet fee include */}
                  {booking.type === 'hotel' &&
                    <Controller
                      control={control} defaultValue={false}
                      name="petFeeIncluded"
                      render={({ onChange, onBlur, value }) => (
                        <div className="form-group form-check">
                          <input
                            className="form-check-input"
                            id="petFeeIncluded" name="petFeeIncluded" type="checkbox"
                            checked={value}
                            onChange={e => handleChangePetFee(e.target.checked, onChange)}
                            onBlur={onBlur}
                          />
                          <label className="form-check-label" htmlFor="petFeeIncluded">Pet fee included (${booking.petFee})</label>
                        </div>
                      )}
                    />
                  }
                  {/* Payment type */}
                  {totalPriceWatched && Number(totalPriceWatched) > 0 ? (
                    <div className="form-group">
                      <label htmlFor="">Payment type</label>
                      <Controller
                        control={control}
                        name="paymentType"
                        render={({ onChange, onBlur, value }) => (
                          <Fragment>
                            <div className="form-group form-check mb-1">
                              <input
                                className="form-check-input" style={{ marginTop: '0.35rem' }}
                                id="paymentTypeCash" name="paymentType" type="radio" value="cash"
                                checked={value === 'cash'}
                                onChange={e => onChange(e.target.value)}
                                onBlur={onBlur}
                              />
                              <label className="form-check-label" htmlFor="paymentTypeCash">Cash</label>
                            </div>
                            <div className="form-group form-check mb-1">
                              <input
                                className="form-check-input" style={{ marginTop: '0.35rem' }}
                                id="paymentTypeOnline" name="paymentType" type="radio" value="online"
                                checked={value === 'online'}
                                onChange={e => onChange(e.target.value)}
                                onBlur={onBlur}
                              />
                              <label className="form-check-label" htmlFor="paymentTypeOnline">Online</label>
                            </div>
                          </Fragment>
                        )}
                      />
                    </div>
                  ) : null}
                  <ButtonLoading
                    loading={reservationStatus === 'Loading' ? true : false}
                    type="submit"
                  >Create</ButtonLoading>
                </form>
              </>
            ) : null}
          </ContentLoading>

        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-secondary btn-sm"
            type="button"
            onClick={onHide}
          >Cancel</button>
        </Modal.Footer>
      </Modal>
      {showDialog ? (
        <SquarePaymentFormDialog
          data={formData}
          show={showDialog}
          onHide={() => setShowDialog(false)}
        />
      ) : null}
    </>
  )
}

const mapStateToProps = ({ UserState, BookingState, ReservationState, ReservationCategoryState }) => ({
  booking: BookingState.booking,

  users: UserSelectors.getUsersOnSearch(UserState),
  userStatus: UserState.status,

  hasItemsQuantity: ReservationCategorySelectors.checkItemsQuantity(ReservationCategoryState),

  reservationStatus: ReservationSelectors.getStatus(ReservationState),
  availableCategories: ReservationCategorySelectors.getAvailableCategories(ReservationCategoryState),
  totalPrice: ReservationCategorySelectors.getTotalPrice(ReservationCategoryState),
  status: ReservationCategorySelectors.getStatus(ReservationCategoryState)
});

const mapDispatchToProps = dispatch => ({
  searchUsers: username => dispatch(UserActions.searchUsers({ username, limit: 50 })),
  createReservation: data => dispatch(ReservationActions.create(data)),
  clearAvailableCategories: () => dispatch(ReservationCategoryActions.clearAvailableCategories()),
  incrementAvailableCategory: categoryId => dispatch(
    ReservationCategoryActions.incrementAvailableCategory(categoryId)
  ),
  decrementAvailableCategory: categoryId => dispatch(
    ReservationCategoryActions.decrementAvailableCategory(categoryId)
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReservationFormModal);
