import React from 'react';
import { useForm, Controller } from 'react-hook-form';
// Redux
import { connect } from 'react-redux';
import UserActions from 'app/actions/user.actions';
// Layouts
import Drawer from 'app/layouts/Drawer';
// Components
import { Input, Checkbox } from 'components/Controls';
import { Phone } from 'components/Phone';
import { ButtonLoading } from 'app/components/Buttons';
// Utilities
import { isRequired, isEmail } from 'utilities/validation';
import ModuleNotificationTypes from 'types/module-notification-types';

const ProfilePage = ({
  // State
  drawerOpen, currentUser, isLoading,
  // Dispatch
  updateMe
}) => {
  const { register, control, handleSubmit, errors, watch, setValue } = useForm({
    defaultValues: {
      firstName: currentUser.firstName || '',
      lastName: currentUser.lastName || '',
      email: currentUser.email || '',
      hiddenPhone: (currentUser && currentUser.phone && currentUser.callingCode) ? `${currentUser.callingCode}_${currentUser.phone}` : '',
      phone: (currentUser && currentUser.phone && currentUser.callingCode) ? `${currentUser.callingCode}${currentUser.phone}` : '',
      notificationPreferencesEmail: currentUser.notificationPreferences ? currentUser.notificationPreferences.email : false,
      notificationPreferencesSms: currentUser.notificationPreferences ? currentUser.notificationPreferences.sms : false,

      biometricSignInEnabled: currentUser.biometricSignInEnabled || false,

      moduleNotificationReservation: currentUser && currentUser.moduleNotificationTypes
        ? currentUser.moduleNotificationTypes.includes(ModuleNotificationTypes.Reservations)
        : false
      ,
      moduleNotificationOrders: currentUser && currentUser.moduleNotificationTypes
        ? currentUser.moduleNotificationTypes.includes(ModuleNotificationTypes.Orders)
        : false
      ,
      moduleNotificationMemberships: currentUser && currentUser.moduleNotificationTypes
        ? currentUser.moduleNotificationTypes.includes(ModuleNotificationTypes.Memberships)
        : false
      ,
      moduleNotificationHelpDesk: currentUser && currentUser.moduleNotificationTypes
        ? currentUser.moduleNotificationTypes.includes(ModuleNotificationTypes.HelpDesk)
        : false
      ,

      newPassword: '',
      reNewPassword: ''
    }
  });

  const onSubmit = data => {
    const {
      firstName, lastName, email, hiddenPhone, notificationPreferencesSms, notificationPreferencesEmail, newPassword,
      biometricSignInEnabled,
      moduleNotificationReservation, moduleNotificationOrders, moduleNotificationMemberships, moduleNotificationHelpDesk
    } = data;
    const [callingCode, phone] = hiddenPhone.split('_');
    const newData = {
      id: currentUser.id,
      firstName, lastName, email, callingCode, phone,
      notificationPreferences: {
        sms: notificationPreferencesSms,
        email: notificationPreferencesEmail
      },
      biometricSignInEnabled
    };
    const moduleNotificationTypes = [];
    if ( moduleNotificationReservation ) moduleNotificationTypes.push(ModuleNotificationTypes.Reservations);
    if ( moduleNotificationOrders ) moduleNotificationTypes.push(ModuleNotificationTypes.Orders);
    if ( moduleNotificationMemberships ) moduleNotificationTypes.push(ModuleNotificationTypes.Memberships);
    if ( moduleNotificationHelpDesk ) moduleNotificationTypes.push(ModuleNotificationTypes.HelpDesk);
    if ( moduleNotificationTypes.length ) newData['moduleNotificationTypes'] = moduleNotificationTypes;
    if ( newPassword ) newData['password'] = newPassword;
    updateMe(newData);
  }

  return (
    <>
      <Drawer open={drawerOpen} />
      <div className={`root-container ${ drawerOpen ? 'is-open' : '' }`}>
        <div className="area area-profile">
          <div className="area-container">

            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <div className="row row-8">
                {/* First name */}
                <div className="col-12 col-sm-6">
                  <Input
                    controlRef={register({ required: isRequired })}
                    label="First Name" id="firstName" name="firstName"
                    required={true}
                    errorText={errors.firstName ? errors.firstName.message : null}
                  />
                </div>
                <div className="col-12 col-sm-6">
                  {/* Last name */}
                  <Input
                    controlRef={register({ required: isRequired })}
                    label="Last Name" id="lastName" name="lastName"
                    required={true}
                    errorText={errors.lastName ? errors.lastName.message : null}
                  />
                </div>
              </div>
              {/* E-mail */}
              <Input
                controlRef={register({ required: isRequired, pattern: isEmail })}
                label="E-mail" id="email" name="email"
                required={true}
                errorText={errors.email ? errors.email.message : null}
              />
              {/* Phone */}
              <input ref={register({ required: isRequired })} type="hidden" name="hiddenPhone" />
              <Controller
                control={control} name="phone"
                render={({ onChange, onBlur, value }) => (
                  <Phone
                    value={value}
                    onChange={(newValue, newHiddenPhoneValue) => {
                      onChange(newValue);
                      setValue('hiddenPhone', newHiddenPhoneValue);
                    }}
                    onBlur={onBlur}
                    errorText={errors.hiddenPhone ? errors.hiddenPhone.message : null}
                  />
                )}
              />
              {/* Notification preferences */}
              <hr />
              {/* Email */}
              <Controller
                control={control}
                name="notificationPreferencesEmail"
                render={({ onChange, onBlur, value }) => (
                  <Checkbox
                    label="Enable e-mail notification"
                    id="notificationPreferencesEmail" name="notificationPreferencesEmail"
                    checked={value}
                    onChange={e => onChange(e.target.checked)}
                    onBlur={onBlur}
                  />
                )}
              />
              {/* SMS */}
              <Controller
                control={control}
                name="notificationPreferencesSms"
                render={({ onChange, onBlur, value }) => (
                  <Checkbox
                    label="Enable sms notification"
                    id="notificationPreferencesSms" name="notificationPreferencesSms"
                    checked={value}
                    onChange={e => onChange(e.target.checked)}
                    onBlur={onBlur}
                  />
                )}
              />
              <hr/>
              <Controller
                control={control}
                name="biometricSignInEnabled"
                render={({ onChange, onBlur, value }) => (
                  <Checkbox
                    label="Biometric sign in enabled"
                    id="biometricSignInEnabled" name="biometricSignInEnabled"
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                )}
              />
              {/* Notifications */}
              <hr />
              <label htmlFor="">Module notifications</label>
              {currentUser !== 'orderManager' ? (
                <React.Fragment>
                  <Controller
                    control={control}
                    name="moduleNotificationMemberships"
                    render={({ onChange, onBlur, value }) => (
                      <Checkbox
                        label="Memberships"
                        id="moduleNotificationMemberships" name="moduleNotificationMemberships"
                        checked={value}
                        onChange={(e) => onChange(e.target.checked)}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="moduleNotificationReservation"
                    render={({ onChange, onBlur, value }) => (
                      <Checkbox
                        label="Reservation"
                        id="moduleNotificationReservation" name="moduleNotificationReservation"
                        checked={value}
                        onChange={(e) => onChange(e.target.checked)}
                      />
                    )}
                  />
                </React.Fragment>
              ) : null}
              <Controller
                control={control}
                name="moduleNotificationOrders"
                render={({ onChange, onBlur, value }) => (
                  <Checkbox
                    label="Orders"
                    id="moduleNotificationOrders" name="moduleNotificationOrders"
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                )}
              />
              <Controller
                control={control}
                name="moduleNotificationHelpDesk"
                render={({ onChange, onBlur, value }) => (
                  <Checkbox
                    label="Help desk"
                    id="moduleNotificationHelpDesk" name="moduleNotificationHelpDesk"
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                )}
              />
              {/* Password */}
              <hr />
              {/* New password */}
              <Input
                controlRef={register({
                  required: {
                    ...isRequired,
                    value: watch('reNewPassword') !== ''
                  }
                })}
                label="New password" id="newPassword" name="newPassword" type="password"
                required={watch('reNewPassword') !== ''}
                errorText={errors.newPassword ? errors.newPassword.message : null}
              />
              {/* Re new password */}
              <Input
                controlRef={register({
                  required: {
                    ...isRequired,
                    value: watch('newPassword') !== ''
                  },
                  validate: value => value === watch('newPassword') || 'Password doesn`t match'
                })}
                label="Confirm new password" id="reNewPassword" name="reNewPassword" type="password"
                required={watch('newPassword') !== ''}
                errorText={errors.reNewPassword ? errors.reNewPassword.message : null}
              />

              <ButtonLoading
                loading={isLoading}
                type="submit"
              >Save</ButtonLoading>
            </form>

          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  drawerOpen: state.layout.drawerOpen,

  currentUser: state.auth.user,
  isLoading: state.auth.isLoading
});

const mapDispatchToProps = dispatch => ({
  updateMe: (data) => dispatch(UserActions.updateMe(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
