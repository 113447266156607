import create from './create-action';
import Types from '../types/inventory-group.types';

class InventoryGroupActions {
  // Fetch content items
  fetchAll = (limit, offset) => create(Types.FETCH_ALL, { limit, offset })
  fetchAllSuccess = inventoryGroups => create(Types.FETCH_ALL_SUCCESS, { inventoryGroups })
  fetchAllFailure = () => create(Types.FETCH_ALL_FAILURE)
  // Fetch content item by id
  fetchById = inventoryGroupId => create(Types.FETCH_BY_ID, { inventoryGroupId })
  fetchByIdSuccess = inventoryGroup => create(Types.FETCH_BY_ID_SUCCESS, { inventoryGroup })
  fetchByIdFailure = () => create(Types.FETCH_BY_ID_FAILURE)

  clearFetchById = () => create(Types.CLEAR_FETCH_BY_ID)
  // Create content item
  create = inventoryGroup => create(Types.CREATE, { inventoryGroup })
  createSuccess = inventoryGroup => create(Types.CREATE_SUCCESS, { inventoryGroup })
  createFailure = () => create(Types.CREATE_FAILURE)
  // Update content item
  update = inventoryGroup => create(Types.UPDATE, { inventoryGroup })
  updateSuccess = inventoryGroup => create(Types.UPDATE_SUCCESS, { inventoryGroup })
  updateFailure = () => create(Types.UPDATE_FAILURE)
  // Delete content item
  delete = inventoryGroup => create(Types.DELETE, { inventoryGroup })
  deleteSuccess = inventoryGroupId => create(Types.DELETE_SUCCESS, { inventoryGroupId })
  deleteFailure = () => create(Types.DELETE_FAILURE)
  // Actions: enable/disable
  // Enable
  enable = inventoryGroupId => create(Types.ENABLE, { inventoryGroupId })
  enableSuccess = inventoryGroupId => create(Types.ENABLE_SUCCESS, { inventoryGroupId })
  enableFailure = () => create(Types.ENABLE_FAILURE)
  // Disable
  disable = inventoryGroupId => create(Types.DISABLE, { inventoryGroupId })
  disableSuccess = inventoryGroupId => create(Types.DISABLE_SUCCESS, { inventoryGroupId })
  disableFailure = () => create(Types.DISABLE_FAILURE)
  // Set is fetched
  setIsFetching = () => create(Types.SET_IS_FETCHING);
  setIsFetched = () => create(Types.SET_IS_FETCHED)
  // Set initial state
  resetForm = () => create(Types.RESET_FORM)
  setInitialState = () => create(Types.SET_INITIAL_STATE)
};

const actions = new InventoryGroupActions();
export default actions;