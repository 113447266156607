import './App.scss';

import React, { useEffect } from 'react';
// Store
import { connect } from 'react-redux';
import AppActions from 'store/app/app.actions';
import { checkIsTokenExpired } from './actions/auth.actions'
// Layouts
import Header from './layouts/Header';
// import Footer from './layouts/Footer';
import { Notifications } from './layouts/Notifications';
import PushNotifications from './layouts/PushNotifications';
// Components
import { AppLoading } from './components/Loadings';
// Routing
import { AppRouting } from './App.routing';

const App = ({
  // Props
  // State
  user, isFetching, isAuthenticated,
  // Dispatch
  setAppInited, checkIsTokenExpired
}) => {
  useEffect(() => {
    setAppInited();
    checkIsTokenExpired();
    // eslint-disable-next-line
  }, []);

  if ( isFetching ) return <AppLoading />;
  return (
    <>
      <Header user={user} isAuthenticated={isAuthenticated} />
      <div className="root-main">
        <AppRouting user={user} />
      </div>
      <Notifications />
      <PushNotifications />
    </>
  );
}

const mapStateToProps = state => ({
  user: state.auth.user,
  isFetching: state.auth.isFetching,
  isAuthenticated: state.auth.isAuthenticated
});

const mapDispatchToProps = dispatch => ({
  setAppInited: () => dispatch(AppActions.setInited()),
  checkIsTokenExpired: () => dispatch(checkIsTokenExpired())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
