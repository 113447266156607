import Statuses from 'types/statuses';
// Types
import { MembershipTypes, MembershipActionTypes } from './membership.types';
// Models
import { IMembership, IMembershipTransaction, IMembershipVisit } from 'models/Membership';

export default class MembershipActions {
  // Fetch memberships
  public static fetchMemberships = (params:any = {}):MembershipActionTypes => ({
    type: MembershipTypes.FetchMemberships, payload: { params }
  });
  public static fetchMembershipsSuccess = (memberships:Array<IMembership>, total:number):MembershipActionTypes => ({
    type: MembershipTypes.FetchMembershipsSuccess, payload: { memberships, total }
  });
  // Fetch membership
  public static fetchMembership = (membershipId:number):MembershipActionTypes => ({
    type: MembershipTypes.FetchMembership, payload: { membershipId }
  });
  public static fetchMembershipSuccess = (membership:IMembership):MembershipActionTypes => ({
    type: MembershipTypes.FetchMembershipSuccess, payload: { membership }
  });
  // Fetch membership by number
  public static fetchMembershipByNumber = (membershipNumber:number):MembershipActionTypes => ({
    type: MembershipTypes.FetchMembershipByNumber, payload: { membershipNumber }
  });
  public static fetchMembershipByNumberSuccess = (membership:IMembership):MembershipActionTypes => ({
    type: MembershipTypes.FetchMembershipByNumberSuccess, payload: { membership }
  });
  // Fetch membership transactions
  public static fetchMembershipTransactions = (membershipId:number):MembershipActionTypes => ({
    type: MembershipTypes.FetchMembershipTransactions, payload: { membershipId }
  });
  public static fetchMembershipTransactionsSuccess = (transactions:Array<IMembershipTransaction>):MembershipActionTypes => ({
    type: MembershipTypes.FetchMembershipTransactionsSuccess, payload: { transactions }
  });
  // Fetch membership visits
  public static fetchMembershipVisits = (membershipId:number, params:any):MembershipActionTypes => ({
    type: MembershipTypes.FetchMembershipVisits, payload: { membershipId, params }
  });
  public static fetchMembershipVisitsSuccess = (visits:Array<IMembershipVisit>, total:number):MembershipActionTypes => ({
    type: MembershipTypes.FetchMembershipVisitsSuccess, payload: { visits, total }
  });
  // Check in membership
  public static checkInMembership = (membershipId:number):MembershipActionTypes => ({
    type: MembershipTypes.CheckInMembership, payload: { membershipId }
  });
  public static checkInMembershipSuccess = ():MembershipActionTypes => ({
    type: MembershipTypes.CheckInMembershipSuccess
  });
  // Inactivate membership
  public static inactivateMembership = (membershipId:number, params:any):MembershipActionTypes => ({
    type: MembershipTypes.InactivateMembership, payload: { membershipId, params }
  });
  public static inactivateMembershipSuccess = (membership:IMembership):MembershipActionTypes => ({
    type: MembershipTypes.InactivateMembershipSuccess, payload: { membership }
  });
  // Send membership receipt
  public static sendMembershipReceipt = (membershipId:number, transactionId:number, params:any):MembershipActionTypes => ({
    type: MembershipTypes.SendMembershipReceipt, payload: { membershipId, transactionId, params }
  });
  public static sendMembershipReceiptSuccess = ():MembershipActionTypes => ({
    type: MembershipTypes.SendMembershipReceiptSuccess
  });
  // Default
  // Set status
  public static setStatus = (status:Statuses):MembershipActionTypes => ({
    type: MembershipTypes.SetStatus, payload: { status }
  });
  public static setInitialField = (field:any):MembershipActionTypes => ({
    type: MembershipTypes.SetInitialField, payload: { field }
  });
}