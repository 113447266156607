import React from 'react';
// Redux
import { connect } from 'react-redux';
import * as FinancialReportSelectors from 'app/store/financial-report/financial-report.selectors';

const FinancialReportTotal = ({
  // Props
  label, gross, net, tips,
  // State
  summary
}) => {
  const { total } = summary;

  const toValue = type => {
    if ( !total ) return 0;
    return `$${Number(total[type]).toLocaleString() || 0}`;
  }

  const calculateTax = (gross, net) => {
    if ( !total ) return 0;
    return `$${Number(total[gross] - total[net]).toLocaleString()}`;
  }

  return (
    <div className="mb-1">
      <b className="mr-2">{label}:&nbsp;</b>
      <span className="mr-2">&nbsp;<b className="text-muted">Gross</b>&nbsp;{toValue(gross)}</span>
      <span className="mr-2">&nbsp;<b className="text-muted">Net</b>&nbsp;{toValue(net)}</span>
      <span className="mr-2">&nbsp;<b className="text-muted">Tax</b>&nbsp;{calculateTax(gross, net)}</span>
      {(total && total[tips]) ? <span>&nbsp;<b className="text-muted">Tips</b>&nbsp;{`$${total[tips]}`}</span> : null}
    </div>
  )
}

const mapStateToProps = ({ FinancialReportState }) => ({
  summary: FinancialReportSelectors.getSummary(FinancialReportState)
});

export default connect(mapStateToProps)(FinancialReportTotal);
