import React, { Component } from 'react';
// Store
import { connect } from 'react-redux';
import OrderActions from '../../../actions/order.actions';
// Components
import { DataLoading } from '../../../components/Loadings';
import { ButtonLoading } from '../../../components/Buttons';
// 
import OrderListItem from './OrderListItem';

class OrderList extends Component {
  componentDidMount(){
    const { params } = this.props;
    this.props.fetchAll({ ...params, offset: 0 });
  }

  handleMore = () => {
    const { params } = this.props;
    const { limit, offset } = params;
    this.props.fetchAll({ ...params, offset: offset + limit }, true);
  }

  render(){
    const { orders, isFetching, hasMore, hasMoreLoading } = this.props;
    if ( isFetching ) return <DataLoading />
    return (
      <div className="area-container">
        <table className="table table-sm mb-0">
          <thead className="thead-dark">
            <tr>
              <th scope="col">№</th>
              <th scope="col">Info</th>
              <th scope="col">Status</th>
              <th scope="col">Delivery type</th>
              <th scope="col">Location</th>
              <th scope="col">Tip</th>
              <th scope="col">Total</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order, index) => (
              <OrderListItem
                key={index}
                order={order}
              />
            ))}
          </tbody>
          {hasMore &&
            <tfoot>
              <tr><td colSpan="8" className="text-center">
                <ButtonLoading
                  loading={hasMoreLoading}
                  onClick={() => this.handleMore()}
                >Load more</ButtonLoading>
              </td></tr>
            </tfoot>
          }
        </table>
      </div>
    );
  }
}

export default connect(
  ({ OrderState }) => ({
    orders: OrderState.orders,
    params: OrderState.params,
    isFetching: OrderState.isFetching,
    hasMore: OrderState.hasMore,
    hasMoreLoading: OrderState.hasMoreLoading
  }),
  dispatch => ({
    fetchAll: (params, isMore) => dispatch(OrderActions.fetchAll(params, isMore))
  })
)(OrderList);
