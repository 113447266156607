import { takeLatest, call, put } from 'redux-saga/effects';
// Store
import * as LayoutActions from 'app/actions/layout.actions';
import Types, { ProductMessageTypes } from 'app/types/product.types';
import ProductService from '../services/product.service';
import ProductActions from '../actions/product.actions';

// Fetch products list
function* _fetchAll(action){
  try {
    const { params, isMore } = action.payload;
    const response = yield call(ProductService.fetchAll, params);
    yield put(ProductActions.fetchAllSuccess(response, params, isMore));
  } catch(err){
    console.error(err);
    yield put(ProductActions.fetchAllFailure());
  }
}

// Fetch product by id
function* _fetchById(action){
  try {
    const { productId } = action.payload;
    const response = yield call(ProductService.fetchById, productId)
    yield put(ProductActions.fetchByIdSuccess(response));
  } catch(err){
    console.error(err);
    yield put(ProductActions.fetchByIdFailure());
  }
}

// Create product
function* _create(action){
  try {
    const { product } = action.payload;
    const response = yield call(ProductService.create, product);
    yield put(ProductActions.createSuccess(response));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: ProductMessageTypes.Create
    }));
  } catch(err) {
    console.error(err);
    yield put(ProductActions.createFailure());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: err.message
    }));
  }
}

// Update product
function* _update(action){
  try {
    const { product } = action.payload;
    const response = yield call(ProductService.update, product);
    yield put(ProductActions.updateSuccess(response));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: ProductMessageTypes.Update
    }));
  } catch(err) {
    console.error(err);
    yield put(ProductActions.updateFailure());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: err.message
    }));
  }
}

// Delete product
function* _delete(action){
  try {
    const { product } = action.payload;
    if ( window.confirm(`Are you sure you want to delete product: ${product.name}?`) ){
      yield call(ProductService.delete, product.id);
      yield put(ProductActions.deleteSuccess(product.id));
      yield put(LayoutActions.addNotification({
        type: 'success',
        message: ProductMessageTypes.Delete
      }));
    }
  } catch(err){
    console.error(err);
    yield put(ProductActions.deleteFailure());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: err.message
    }));
  }
}

// Create/update product image
function* _uploadImage(action){
  try {
    const { productId, image } = action.payload;
    const response = yield call(ProductService.uploadImage, productId, image);
    yield put(ProductActions.uploadImageSuccess({ href: response.href }))
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: ProductMessageTypes.UploadImage
    }));
  } catch(err){
    console.error(err);
    yield put(ProductActions.uploadImageFailure());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: err.message
    }));
  }
}

// Delete product image
function* _deleteImage(action){
  try {
    const { productId } = action.payload;
    yield call(ProductService.deleteImage, productId);
    yield put(ProductActions.deleteImageSuccess());
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: ProductMessageTypes.DeleteImage
    }));
  } catch(err){
    console.error(err);
    yield put(ProductActions.deleteImageFailure());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: err.message
    }));
  }
}

export function* productSaga(){
  yield takeLatest(Types.FETCH_ALL, _fetchAll);
  yield takeLatest(Types.FETCH_BY_ID, _fetchById);
  yield takeLatest(Types.CREATE, _create);
  yield takeLatest(Types.UPDATE, _update);
  yield takeLatest(Types.DELETE, _delete);

  yield takeLatest(Types.UPLOAD_IMAGE, _uploadImage);
  yield takeLatest(Types.DELETE_IMAGE, _deleteImage);
}
