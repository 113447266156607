import React from 'react';
// Redux
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import StaffActions from 'store/staff/staff.actions';
import * as StaffSelectors from 'store/staff/staff.selectors';
import { IStaff } from 'store/staff/staff.types';
// Components
import { Container } from 'components/Container';
// 
import StaffCreate from './StaffCreate';
import StaffListItem from './StaffListItem';

type IProps = {
  items: Array<IStaff>;
  isFetching: boolean;
  fetchStaffList: () => void;
  enableStaff: (id:number) => void;
  disableStaff: (id:number) => void;
  deleteStaff: (staff:IStaff) => void;
}

const StaffPages:React.FC<IProps> = ({
  // State
  items,
  // Dispatch
  enableStaff, disableStaff, deleteStaff
}) => {
  return (
    <div className="container-fluid pt-3 pb-3">
      <StaffCreate />
      <Container data={items} isFetching={false}>
        <div className="row row-8 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
          {items && items.map(item => (
            <div key={`staff-item-${item.id}`} className="col | d-flex mb-2">
              <StaffListItem
                item={item}
                onEnable={enableStaff}
                onDisable={disableStaff}
                onDelete={deleteStaff}
              />
            </div>
          ))}
        </div>
      </Container>
    </div>
  )
}

const mapStateToProps = (state:any) => ({
  items: StaffSelectors.getItems(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  enableStaff: (id:number) => dispatch(StaffActions.enable(id)),
  disableStaff: (id:number) => dispatch(StaffActions.disable(id)),
  deleteStaff: (user:IStaff) => dispatch(StaffActions.delete(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StaffPages);
