import React, {useState} from 'react';
// Layouts
import ProductCategoryFormModal from 'app/layouts/Modals/ProductCategoryFormModal';

const ProductCategoriesCreate = () => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);

  return (
    <>
      <div className="form-group text-right">
        <button
          className="btn btn-primary btn-sm"
          type="button"
          onClick={handleShow}
        >Create product category</button>
      </div>
      {show ? (
        <ProductCategoryFormModal
          show={show}
          onHide={handleHide}
        />
      ) : null}
    </>
  )
}

export default ProductCategoriesCreate;
