export default class StorageService {
  private static readonly TOKEN:string = 'aicamping:token';
  private static readonly TOKEN_TYPE:string = 'aicamping:token_type';
  private static readonly EXPIRES_IN:string = 'aicamping:expires_in';
  // private static readonly DEVICE_TOKEN:string = 'aicamping:device_token';

  public static getToken():string {
    return localStorage.getItem(StorageService.TOKEN) || '';
  }
  public static getTokenType():string {
    return localStorage.getItem(StorageService.TOKEN_TYPE) || '';
  }
  public static getTokenExpiresIn():number {
    const expiresIn = localStorage.getItem(StorageService.EXPIRES_IN) || 0;
    return Number(expiresIn);
  }
}