import { call, put, takeLatest } from 'redux-saga/effects';
import Statuses from 'types/statuses';
import * as LayoutActions from 'app/actions/layout.actions';
import AccountService from './account.service';
import AccountActions from './account.actions';
import {
  AccountMessages,
  FETCH_LIST, CREATE, UPDATE, DELETE
} from './account.types';

function* _fetchList(){
  try {
    const { parseBody } = yield call(AccountService.fetchList);
    yield put(AccountActions.fetchListSuccess(parseBody));
  } catch(error:any){
    yield put(AccountActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _create(action:any){
  try {
    const { parseBody } = yield call(AccountService.create, action.payload.data);
    yield put(AccountActions.createSuccess(parseBody));
    yield put(AccountActions.setStatus(Statuses.Success));
    yield put(AccountActions.setStatus(Statuses.Initial));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: AccountMessages.Create
    }));
  } catch(error:any){
    console.log(error);
    yield put(AccountActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _update(action:any){
  try {
    const { parseBody } = yield call(AccountService.update, action.payload.id, action.payload.data);
    yield put(AccountActions.updateSuccess(parseBody));
    yield put(AccountActions.setStatus(Statuses.Success));
    yield put(AccountActions.setStatus(Statuses.Initial));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: AccountMessages.Update
    }));
  } catch(error:any){
    yield put(AccountActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _delete(action:any){
  try {
    const { account } = action.payload;
    if ( window.confirm(`Are you sure you want to delete account: ${account.name}?`) ){
      yield call(AccountService.delete, account.id);
      yield put(AccountActions.deleteSuccess(account.id));
      yield put(AccountActions.setStatus(Statuses.Success));
      yield put(AccountActions.setStatus(Statuses.Initial));
      yield put(LayoutActions.addNotification({
        type: 'success',
        message: AccountMessages.Delete
      }));
    }
  } catch(error:any){
    yield put(AccountActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

export default function* (){
  yield takeLatest(FETCH_LIST, _fetchList);
  yield takeLatest(CREATE, _create);
  yield takeLatest(UPDATE, _update);
  yield takeLatest(DELETE, _delete);
}
