import fetcher from '../../utilities/fetcher';

const apiURL = '/admin/users';
const apiV1URL = '/v1/users';
const headers = {
  'Content-Type': 'application/json'
};

export const UserService = {
  // Send recovery email
  sendPasswordRecoveryEmail: email => {
    const formData = new FormData();
    formData.append('email', email)
    return fetcher(`${apiURL}/actions/sendPasswordRecoveryEmail`, {
      method: 'POST',
      body: formData
    }, () => null);
  },
  // Update password
  // @params { Object } data
  // @params { string } data.confirmationCode
  // @params { string } data.email
  // @params { string } data.password
  updatePassword: data => {
    return fetcher(`${apiURL}/passwords`, {
      method: 'POST',
      headers,
      body: JSON.stringify(data)
    }, () => null);
  },
  // Fetch me
  fetchMe: () => {
    return fetcher(`${apiURL}/me`);
  },
  // Update me
  updateMe: (userId, data) => {
    return fetcher(`${apiURL}/${userId}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(data)
    });
  },
  // Fetch list campers
  fetchListCampers: (name, visitingFrom, visitingTo) => {
    const params = new URLSearchParams();
    if ( name ) params.append('name', name);
    if ( visitingFrom ) params.append('visitingFrom', visitingFrom);
    if ( visitingTo ) params.append('visitingTo', visitingTo);
    return fetcher(`${apiURL}/campers?${params}`);
  },
  // Update camper
  updateCamper: (userId, data) => {
    return fetcher(`${apiURL}/camper/${userId}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(data)
    });
  },
  // Fetch list staff
  fetchList: () => {
    return fetcher(`${apiURL}/staff`);
  },
  // Fetch by id
  fetchById: userId => {
    return fetcher(`${apiURL}/${userId}`);
  },
  // Create
  create: data => {
    return fetcher(`${apiURL}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(data)
    });
  },
  // Update
  update: (userId, data) => {
    return fetcher(`${apiURL}/${userId}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(data)
    });
  },
  // Delete
  delete: userId => {
    return fetcher(`${apiURL}/${userId}`, {
      method: 'DELETE'
    }, () => null);
  },
  // Enable
  enable: userId => {
    return fetcher(`${apiURL}/${userId}/actions/enable`, {
      method: 'POST',
      headers
    }, () => null);
  },
  // Disable
  disable: userId => {
    return fetcher(`${apiURL}/${userId}/actions/disable`, {
      method: 'POST',
      headers
    }, () => null);
  },
  // Create device token
  createDeviceToken: token => {
    return fetcher(`${apiV1URL}/deviceTokens?platform=web&token=${token}`, {
      method: 'POST',
      headers
    });
  },
  // Delete device token
  deleteDeviceToken: token => {
    return fetcher(`${apiV1URL}/deviceTokens?platform=web&token=${token}`, {
      method: 'DELETE'
    }, () => null);
  }
}
