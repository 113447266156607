import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { FinancialReportActions } from 'app/store/financial-report/financial-report.actions';
import * as FinancialReportSelectors from 'app/store/financial-report/financial-report.selectors';
// Bootstrap
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
// Components
import { ContentLoading } from 'app/components/Loadings';
// 
import FinancialReportFilter from './FinancialReportFilter';
import FinancialReportTotal from './FinancialReportTotal';
import FinancialReportTab from './FinancialReportTab';

const parseQueryByName = (search, name) => {
  if ( !search ) return '';
  return new URLSearchParams(search).get(name);
}

const FinancialReportPage = ({
  // Props
  // State
  summary, filter, status,
  // Dispatch
  goTo, fetchListSummary, downloadOrders, downloadReservations, downloadMemberships
}) => {
  const { search } = useLocation();
  const { start, end } = filter;

  const [defaultActiveKey, setDefaultActiveKey] = useState('orders');

  useEffect(() => {
    fetchListSummary(start, end);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const nextActiveKey = parseQueryByName(search, 'key') || 'orders';
    setDefaultActiveKey(nextActiveKey);
    // eslint-disable-next-line
  }, [search]);

  const handleClick = key => {
    goTo({ search: `?key=${key}` })
    setDefaultActiveKey(key);
  }

  return (
    <div className="area">
      <div className="area-container">
        <FinancialReportFilter />
        <ContentLoading data={summary} status={status}>
          <div className="container-fluid">
            <FinancialReportTotal label="Grand total" gross="totalGross" net="totalNet" tips="totalTips" />
            <Tab.Container defaultActiveKey={defaultActiveKey}>
              <Nav className="justify-content-center mb-2 badge-white p-2 border shadow-sm" variant="pills" fill>
                <Nav.Item onClick={() => handleClick('orders')}>
                  <Nav.Link eventKey="orders">Orders</Nav.Link>
                </Nav.Item>
                <Nav.Item onClick={() => handleClick('reservations')}>
                  <Nav.Link eventKey="reservations">Reservations</Nav.Link>
                </Nav.Item>
                <Nav.Item onClick={() => handleClick('memberships')}>
                  <Nav.Link eventKey="memberships">Memberships</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="orders">
                  <div className="d-flex justify-content-between | mb-2">
                    <FinancialReportTotal
                      label="Total"
                      gross="ordersGross"
                      net="ordersNet"
                      tips="totalTips"
                    />
                    <button
                      className="btn btn-primary btn-sm"
                      type="button"
                      onClick={() => downloadOrders(start, end)}
                    >Export CSV</button>
                  </div>
                  {summary.orders && <FinancialReportTab type="orders" items={summary.orders} />}
                </Tab.Pane>
                <Tab.Pane eventKey="reservations">
                  <div className="d-flex justify-content-between mb-2">
                    <FinancialReportTotal
                      label="Total"
                      gross="reservationsGross"
                      net="reservationsNet"
                    />
                    <button
                      className="btn btn-primary btn-sm"
                      type="button"
                      onClick={() => downloadReservations(start, end)}
                    >Export CSV</button>
                  </div>
                  {summary.reservations && <FinancialReportTab type="reservations" items={summary.reservations} />}
                </Tab.Pane>
                <Tab.Pane eventKey="memberships">
                  <div className="d-flex justify-content-between mb-2">
                    <FinancialReportTotal
                      label="Total"
                      gross="membershipsGross"
                      net="membershipsNet"
                    />
                    <button
                      className="btn btn-primary btn-sm"
                      type="button"
                      onClick={() => downloadMemberships(start, end)}
                    >Export CSV</button>
                  </div>
                  {summary.memberships && <FinancialReportTab type="memberships" items={summary.memberships} />}
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </ContentLoading>
      </div>
    </div>
  )
}

const mapStateToProps = ({ FinancialReportState }) => ({
  summary: FinancialReportSelectors.getSummary(FinancialReportState),
  filter: FinancialReportSelectors.getFilter(FinancialReportState),
  status: FinancialReportSelectors.getStatus(FinancialReportState)
});

const mapDispatchToProps = dispatch => ({
  goTo: options => dispatch(push(options)),
  fetchListSummary: (start, end) => dispatch(FinancialReportActions.fetchListSummary(start, end)),
  downloadOrders: (start, end) => dispatch(FinancialReportActions.downloadOrders(start, end)),
  downloadReservations: (start, end) => dispatch(FinancialReportActions.downloadReservations(start, end)),
  downloadMemberships: (start, end) => dispatch(FinancialReportActions.downloadMemberships(start, end)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FinancialReportPage);
