import create from './create-type';

const type = 'auth';

export const LOGIN = create(type, 'LOGIN');
export const SET_LOGIN_CREDENTIAL = create(type, 'SET_LOGIN_CREDENTIAL');;
export const LOGIN_FAILURE = create(type, 'LOGIN_FAILURE');

export const LOGOUT = create(type, 'LOGOUT');;
export const LOGOUT_SUCCESS = create(type, 'LOGOUT_SUCCESS');;

export const CHECK_IS_TOKEN_EXPIRED = create(type, 'CHECK_IS_TOKEN_EXPIRED');;
export const TOKEN_NOT_FOUND = create(type, 'TOKEN_NOT_FOUND');;

export const RESET_FORM = create(type, 'RESET_FORM');
export const SET_IS_FETCHED = create(type, 'SET_IS_FETCHED');