import { createSelector } from 'reselect';

const _templates = state => state.MessageTemplateState.templates;
const _template = state => state.MessageTemplateState.template;

export const getItems = createSelector(
  _templates, templates => templates.items
);
export const getItem = createSelector(
  _template, tempate => tempate.item
);

export const getItemsIsFetching = state => _templates(state).isFetching;
export const getItemsIsLoading = state => _templates(state).isLoading;

export const getItemIsFetching = state => _template(state).isFetching;
export const getItemIsLoading = state => _template(state).isLoading;

export const getStatus = state => state.MessageTemplateState.status;
