import * as Types from '../types/schedule.types';

// Fetch schedule slots
export const fetchScheduleSlots = params => ({
  type: Types.FETCH_SCHEDULE_SLOTS,
  params
});
export const fetchScheduleSlotsSuccess = slots => ({
  type: Types.FETCH_SCHEDULE_SLOTS_SUCCESS,
  slots
});

// Create template
export const createScheduleSlot = slot => ({
  type: Types.CREATE_SCHEDULE_SLOT,
  slot
});
export const createScheduleSlotSuccess = slot => ({
  type: Types.CREATE_SCHEDULE_SLOT_SUCCESS,
  slot
});
export const createScheduleSlotFailure = () => ({
  type: Types.CREATE_SCHEDULE_SLOT_FAILURE
});

// Update Template
export const updateScheduleSlot = slot => ({
  type: Types.UPDATE_SCHEDULE_SLOT,
  slot
});
export const updateScheduleSlotSuccess = slot => ({
  type: Types.UPDATE_SCHEDULE_SLOT_SUCCESS,
  slot
});
export const updateScheduleSlotFailure = () => ({
  type: Types.UPDATE_SCHEDULE_SLOT_FAILURE
});

// Delete template
export const deleteScheduleSlot = slotId => ({
  type: Types.DELETE_SCHEDULE_SLOT,
  slotId
});
export const deleteScheduleSlotSuccess = slotId => ({
  type: Types.DELETE_SCHEDULE_SLOT_SUCCESS,
  slotId
});
export const deleteScheduleSlotFailure = () => ({
  type: Types.DELETE_SCHEDULE_SLOT_FAILURE
});


// Select schedule slot
export const selectScheduleSlot = slotId => ({
  type: Types.SELECT_SCHEDULE_SLOT,
  slotId
});
// Clear selected schedule slot
export const clearSelectedScheduleSlot = () => ({
  type: Types.CLEAR_SELECTED_SCHEDULE_SLOT
});

// Reset form
export const resetForm = () => ({
  type: Types.RESET_FORM
});
