export default function(value, rules, match, msg){
  let isValid = true;
  let validateMessage = '';
  for ( let rule in rules ){
    switch (rule){
      case 'isRequired':
        isValid = (rules[rule] && isValid) ? _requiredValidator(value) : true;
        validateMessage = !isValid ? 'This field is required' : '';
        break;
      case 'isEmail':
        isValid = isValid && _emailValidator(value);
        validateMessage = !isValid ? 'E-mail is not valid' : '';
        break;
      case 'isNumber':
        isValid = isValid && _numberValidator(value);
        validateMessage = !isValid ? 'This field should be numeric' : '';
        break;
      case 'isPrice':
        isValid = isValid && _priceValidator(value);
        validateMessage = !isValid ? 'The price can`t be negative or equal zero' : '';
        break;
      case 'isMatch':
        isValid = isValid && _match(value, match);
        validateMessage = !isValid ? msg : '';
        break;
      default:
        isValid = true;
    }
    if ( !isValid ) break;
  }
  return {
    isValid,
    validateMessage
  };
}

/*
  Required
  @param value
  @return
*/
const _requiredValidator = value => {
  return value.toString().trim() !== '';
}

/*
  Email
  @param value
  @return
*/
const _emailValidator = value => {
  const regexp = /^[a-zA-Z0-9._.+.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const validationRegexp = (regexp instanceof RegExp ? regexp : (new RegExp(regexp)));
  return validationRegexp.test(value);
}

const _numberValidator = value => {
  return /^-?\d*\.?\d*$/.test(value);
}

/*
*/
const _priceValidator = value => {
  return value !== '' && parseFloat(value) > 0;
}

/*
  Match
  @param value,
  @param secondValue
  @return
*/
const _match = (value, secondValue) => {
  return value && secondValue ? value.trim() === secondValue.trim() : true;
}