import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
// Redux
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import StaffActions from 'store/staff/staff.actions';
import * as StaffSelectors from 'store/staff/staff.selectors';
import { IStaff, IStaffData } from 'store/staff/staff.types';
import Statuses from 'types/statuses';
import Roles from 'types/roles';
// Bootstrap
import Modal from 'react-bootstrap/Modal';
// Components
import { Input, Select, Checkbox } from 'components/Controls';
import { Phone } from 'components/Phone';
import { ButtonLoading } from 'app/components/Buttons';
// Utilities
import { isRequired, isEmail } from 'utilities/validation';
import { UserRoleLabels } from 'app/App.constants';
import ModuleNotificationTypes from 'types/module-notification-types';

type IProps = {
  // Props
  staff?: IStaff | null;
  show: boolean;
  onHide: () => void;
  // State
  isLoading: boolean;
  status: Statuses;
  // Dispatch
  createStaff: (data:IStaffData) => void;
  updateStaff: (id:number, data:IStaffData) => void;
}

const StaffFormDialog:React.FC<IProps> = ({
  // Props
  staff, show, onHide,
  // State
  isLoading, status,
  // Dispatch
  createStaff, updateStaff
}) => {
  const { register, control, handleSubmit, errors, setValue, watch } = useForm<any>({
    defaultValues: {
      role: staff?.role || '',
      firstName: staff?.firstName || '',
      lastName: staff?.lastName || '',
      email: staff?.email || '',
      hiddenPhone: (staff && staff.phone && staff.callingCode) ? `${staff.callingCode}_${staff.phone}` : '',
      phone: (staff && staff.phone && staff.callingCode) ? `${staff.callingCode}${staff.phone}` : '',
      notificationPreferencesEmail: staff?.notificationPreferences.email || false,
      notificationPreferencesSms: staff?.notificationPreferences.sms || false,

      biometricSignInEnabled: staff?.biometricSignInEnabled || false,

      moduleNotificationReservation: staff && staff.moduleNotificationTypes
        ? staff.moduleNotificationTypes.includes(ModuleNotificationTypes.Reservations)
        : false
      ,
      moduleNotificationOrders: staff && staff.moduleNotificationTypes
        ? staff.moduleNotificationTypes.includes(ModuleNotificationTypes.Orders)
        : false
      ,
      moduleNotificationMemberships: staff && staff.moduleNotificationTypes
        ? staff.moduleNotificationTypes.includes(ModuleNotificationTypes.Memberships)
        : false
      ,
      moduleNotificationHelpDesk: staff && staff.moduleNotificationTypes
        ? staff.moduleNotificationTypes.includes(ModuleNotificationTypes.HelpDesk)
        : false
    }
  });

  const onSubmit = (data: any) => {
    const {
      role, firstName, lastName, email, hiddenPhone, notificationPreferencesEmail, notificationPreferencesSms,
      biometricSignInEnabled,
      moduleNotificationReservation, moduleNotificationOrders, moduleNotificationMemberships, moduleNotificationHelpDesk
    } = data;
    const [callingCode, phone] = hiddenPhone.split('_');
    const newData:IStaffData = { firstName, lastName, email, callingCode, phone, biometricSignInEnabled }
    const moduleNotificationTypes = [];
    if ( moduleNotificationReservation ) moduleNotificationTypes.push(ModuleNotificationTypes.Reservations);
    if ( moduleNotificationOrders ) moduleNotificationTypes.push(ModuleNotificationTypes.Orders);
    if ( moduleNotificationMemberships ) moduleNotificationTypes.push(ModuleNotificationTypes.Memberships);
    if ( moduleNotificationHelpDesk ) moduleNotificationTypes.push(ModuleNotificationTypes.HelpDesk);
    if ( moduleNotificationTypes.length ) newData['moduleNotificationTypes'] = moduleNotificationTypes;
    if ( staff && staff.id ){
      newData['notificationPreferences'] = {
        email: notificationPreferencesEmail,
        sms: notificationPreferencesSms
      }
      updateStaff(staff.id, newData);
    } else {
      newData['role'] = role;
      createStaff(newData);
    }
  }

  useEffect(() => {
    if ( status === 'Success' ) onHide();
    // eslint-disable-next-line
  }, [status]);

  const watchRole = watch('role');

  return (
    <Modal show={show} onHide={onHide}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>{`${staff?.id ? 'Update' : 'Create'}`} staff</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {/* Role */}
          {!staff ? (
            <Select
              controlRef={register({ required: isRequired })}
              label="Role" id="role" name="role"
              required={true}
              options={[
                { value: '', label: 'Choose role' },
                { value: Roles.Admin, label: UserRoleLabels[Roles.Admin] },
                { value: Roles.OrderManager, label: UserRoleLabels[Roles.OrderManager] },
              ]}
              errorText={errors.role ? errors.role.message : null}
            />
          ) : null}
          <div className="row row-8">
            {/* First name */}
            <div className="col-12 col-sm-6">
              <Input
                controlRef={register({ required: isRequired })}
                label="First Name" id="firstName" name="firstName"
                required={true}
                errorText={errors.firstName ? errors.firstName.message : null}
              />
            </div>
            <div className="col-12 col-sm-6">
              {/* Last name */}
              <Input
                controlRef={register({ required: isRequired })}
                label="Last Name" id="lastName" name="lastName"
                required={true}
                errorText={errors.lastName ? errors.lastName.message : null}
              />
            </div>
          </div>
          {/* E-mail */}
          <Input
            controlRef={register({ required: isRequired, pattern: isEmail })}
            label="E-mail" id="email" name="email"
            required={true}
            errorText={errors.email ? errors.email.message : null}
          />
          {/* Phone */}
          {/* Hidden country code */}
          <input ref={register({ required: isRequired })} type="hidden" name="hiddenPhone" />
          <Controller
            control={control} name="phone"
            render={({ onChange, onBlur, value }) => (
              <Phone
                value={value}
                onChange={(newValue:string, newHiddenPhoneValue:string) => {
                  onChange(newValue);
                  setValue('hiddenPhone', newHiddenPhoneValue);
                }}
                onBlur={onBlur}
                errorText={errors.hiddenPhone ? errors.hiddenPhone.message : null}
              />
            )}
          />
          {staff && staff.id ? (
            <React.Fragment>
              {/* Notification preferences */}
              <hr />
              {/* Email */}
              <Controller
                control={control}
                name="notificationPreferencesEmail"
                render={({ onChange, onBlur, value }) => (
                  <Checkbox
                    label="Enable e-mail notification"
                    id="notificationPreferencesEmail" name="notificationPreferencesEmail"
                    checked={value}
                    onChange={(e:React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked)}
                  />
                )}
              />
              {/* SMS */}
              <Controller
                control={control}
                name="notificationPreferencesSms"
                render={({ onChange, onBlur, value }) => (
                  <Checkbox
                    label="Enable sms notification"
                    id="notificationPreferencesSms" name="notificationPreferencesSms"
                    checked={value}
                    onChange={(e:React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked)}
                  />
                )}
              />
              <hr/>
              <Controller
                control={control}
                name="biometricSignInEnabled"
                render={({ onChange, onBlur, value }) => (
                  <Checkbox
                    label="Biometric sign in enabled"
                    id="biometricSignInEnabled" name="biometricSignInEnabled"
                    checked={value}
                    onChange={(e:React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked)}
                  />
                )}
              />
              {/* Notifications */}
              <hr />
              <label htmlFor="">Module notifications</label>
              {watchRole !== Roles.OrderManager ? (
                <React.Fragment>
                  <Controller
                    control={control}
                    name="moduleNotificationMemberships"
                    render={({ onChange, onBlur, value }) => (
                      <Checkbox
                        label="Memberships"
                        id="moduleNotificationMemberships" name="moduleNotificationMemberships"
                        checked={value}
                        onChange={(e:React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked)}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="moduleNotificationReservation"
                    render={({ onChange, onBlur, value }) => (
                      <Checkbox
                        label="Reservation"
                        id="moduleNotificationReservation" name="moduleNotificationReservation"
                        checked={value}
                        onChange={(e:React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked)}
                      />
                    )}
                  />
                </React.Fragment>
              ) : null}
              <Controller
                control={control}
                name="moduleNotificationOrders"
                render={({ onChange, onBlur, value }) => (
                  <Checkbox
                    label="Orders"
                    id="moduleNotificationOrders" name="moduleNotificationOrders"
                    checked={value}
                    onChange={(e:React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked)}
                  />
                )}
              />
              <Controller
                control={control}
                name="moduleNotificationHelpDesk"
                render={({ onChange, onBlur, value }) => (
                  <Checkbox
                    label="Help desk"
                    id="moduleNotificationHelpDesk" name="moduleNotificationHelpDesk"
                    checked={value}
                    onChange={(e:React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked)}
                  />
                )}
              />
            </React.Fragment>
          ) : null}

        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary btn-sm"
            type="button"
            onClick={onHide}
          >Cancel</button>
          <ButtonLoading
            loading={isLoading}
            type="submit"
          >Save</ButtonLoading>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

StaffFormDialog.defaultProps = {
  staff: null,
  show: false,
  onHide: () => null
};

const mapStateToProps = (state:any) => ({
  isLoading: StaffSelectors.getIsLoading(state),
  status: StaffSelectors.getStatus(state)
});

const mapDispatchToProps = (dispatch:Dispatch) => ({
  createStaff: (data:any) => dispatch(StaffActions.create(data)),
  updateStaff: (id:number, data:any) => dispatch(StaffActions.update(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StaffFormDialog);
