import { call, put, takeLatest } from 'redux-saga/effects';
// Types
import Statuses from 'types/statuses';
import { MembershipTypes } from './membership.types';
// Actions
import * as LayoutActions from 'app/actions/layout.actions';
import MembershipActions from './membership.actions';
// API
import MembershipAPI from './membership.api';

export default function* (){
  yield takeLatest(MembershipTypes.FetchMemberships, _fetchMemberships);
  yield takeLatest(MembershipTypes.FetchMembership, _fetchMembership);
  yield takeLatest(MembershipTypes.FetchMembershipByNumber, _fetchMembershipByNumber);
  yield takeLatest(MembershipTypes.FetchMembershipTransactions, _fetchMembershipTransactions);
  yield takeLatest(MembershipTypes.FetchMembershipVisits, _fetchMembershipVisits);
  yield takeLatest(MembershipTypes.CheckInMembership, _checkInMembership);
  yield takeLatest(MembershipTypes.InactivateMembership, _inactivateMembership);
  yield takeLatest(MembershipTypes.SendMembershipReceipt, _sendMembershipReceipt);
}

function* _fetchMemberships(action:any){
  try {
    const { params } = action.payload;
    const { parseBody:{ data = [], total }} = yield call(MembershipAPI.fetchMemberships, params);
    yield put(MembershipActions.fetchMembershipsSuccess(data, total));
  } catch(error:any){
    yield put(MembershipActions.setStatus(Statuses.Error));
  }
}

function* _fetchMembership(action:any){
  try {
    const { membershipId } = action.payload;
    const { parseBody:membership } = yield call(MembershipAPI.fetchMembership, membershipId);
    yield put(MembershipActions.fetchMembershipSuccess(membership));
  } catch(error:any){
    yield put(MembershipActions.setStatus(Statuses.Error));
  }
}

function* _fetchMembershipByNumber(action:any){
  try {
    const { membershipNumber } = action.payload;
    const { parseBody:membership } = yield call(MembershipAPI.fetchMembershipByNumber, membershipNumber);
    yield put(MembershipActions.fetchMembershipByNumberSuccess(membership));
  } catch(error:any){
    yield put(MembershipActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _fetchMembershipTransactions(action:any){
  try {
    const { membershipId } = action.payload;
    const { parseBody:transactions } = yield call(MembershipAPI.fetchMembershipTransactions, membershipId);
    yield put(MembershipActions.fetchMembershipTransactionsSuccess(transactions));
  } catch(error:any){
    yield put(MembershipActions.setStatus(Statuses.Error));
  }
}

function* _fetchMembershipVisits(action:any){
  try {
    const { membershipId, params } = action.payload;
    const { parseBody:{ data = [], total } } = yield call(MembershipAPI.fetchMembershipVisits, membershipId, params);
    yield put(MembershipActions.fetchMembershipVisitsSuccess(data || [], total));
  } catch(error:any){
    yield put(MembershipActions.setStatus(Statuses.Error));
  }
}

function* _checkInMembership(action:any){
  try {
    const { membershipId } = action.payload;
    yield call(MembershipAPI.checkInMembership, membershipId);
    yield put(MembershipActions.checkInMembershipSuccess());
    yield put(MembershipActions.setStatus(Statuses.Success));
    yield put(MembershipActions.setStatus(Statuses.Initial));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: 'Successfully checked in'
    }));
  } catch(error:any){
    yield put(MembershipActions.setStatus(Statuses.Error));
  }
}

function* _inactivateMembership(action:any){
  try {
    const { membershipId, params } = action.payload;
    const { parseBody:membership } = yield call(MembershipAPI.inactivateMembership, membershipId, params);
    yield put(MembershipActions.inactivateMembershipSuccess(membership));
    yield put(MembershipActions.setStatus(Statuses.Success));
    yield put(MembershipActions.setStatus(Statuses.Initial));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: 'Membership was cancelled'
    }));
  } catch(error:any){
    yield put(MembershipActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _sendMembershipReceipt(action:any){
  try {
    const { membershipId, transactionId, params } = action.payload;
    yield call(MembershipAPI.sendMembershipReceipt, membershipId, transactionId, params);
    yield put(MembershipActions.sendMembershipReceiptSuccess());
    yield put(MembershipActions.setStatus(Statuses.Success));
    yield put(MembershipActions.setStatus(Statuses.Initial));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: 'Receipt was sent'
    }));
  } catch(error:any){
    yield put(MembershipActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}
