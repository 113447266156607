import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
// Store
import { connect } from 'react-redux';
import { AppActions } from 'app/store/app/app.actions';
import * as AppSelectors from 'app/store/app/app.selectors';
// Pages
import Campers from './Campers';
import Templates from './Templates';
import SystemNotificationsPage from 'pages/Admin/SystemNotificationsPage';
import Schedule from './Schedule';
import ContentCategories from './ContentCategories';
import ContentItems from './ContentItems';
import Notifications from './Notifications';

import StaffPage from 'pages/Admin/StaffPage';

import InventoryGroupsPage from 'pages/OrderManager/InventoryGroupsPage';
import Products from '../OrderManager/Products';
import ProductForm from '../OrderManager/ProductForm';
import ProductCategories from '../OrderManager/ProductCategories';
import Orders from '../OrderManager/Orders';
import ContactMessages from '../OrderManager/ContactMessages';

// Bookins
import RemotelockDevicesPage from 'pages/OrderManager/RemotelockDevicesPage';
import BookingsPage from '../Booking/BookingsPage'
import BookingDetailsPage from '../Booking/BookingDetailsPage';
import FinancialReportPage from './FinancialReportPage';
import MapsPage from 'pages/Admin/MapsPage';
import MapDetailsPage from 'pages/Admin/MapDetailsPage';
import SeasonsPage from 'pages/Admin/SeasonsPage';
import MembershipPassesPage from 'pages/OrderManager/MembershipPassesPage';
import MembershipTypesPage from 'pages/OrderManager/MembershipTypesPage';
import WaiversPage from 'pages/OrderManager/WaiversPage';
// Layouts
import Drawer from 'app/layouts/Drawer';

// Components
import { DataLoading } from 'app/components/Loadings';
import { initializePush } from '../../../firebase';

const Admin = ({
  // State
  status, drawerOpen,
  // Dispatch
  fetchAdminData
}) => {
  const {path} = useRouteMatch();
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    initializePush();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchAdminData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( status === 'Fetched' ) setFetching(false);
    // eslint-disable-next-line
  }, [status]);

  if ( fetching ) return <DataLoading />;
  return (
    <>
      <Drawer open={drawerOpen} />
      <div className={`root-container ${ drawerOpen ? 'is-open' : '' }`}>
        <Switch>
          {/* Users */}
          <Route path={`${path}/users`} component={Campers} />
          <Route path={`${path}/templates`} component={Templates} />
          {/* Message templates */}
          <Route path={`${path}/system-notifications`} component={SystemNotificationsPage} />
          {/* Mail templates */}
          <Route path={`${path}/schedule`} component={Schedule} />
          {/* Content categories */}
          <Route path={`${path}/content-categories`} component={ContentCategories} />
          {/* Content items */}
          <Route path={`${path}/content-items`} component={ContentItems} />
          {/* Messages */}
          <Route path={`${path}/notifications`} component={Notifications} />
          {/* Staff */}
          <Route path={`${path}/staff`} component={StaffPage} />
          {/* Inventory groups */}
          <Route exact path={`${path}/inventory-groups`} component={InventoryGroupsPage} />
          <Route path={`${path}/inventory-groups/:id/waivers`} component={WaiversPage} />
          {/* Products */}
          <Route exact path={`${path}/products`} component={Products} />
          <Route path={`${path}/products/create`} component={ProductForm} />
          <Route path={`${path}/products/:productId/update`} component={ProductForm} />
          {/* Product categories */}
          <Route exact path={`${path}/product-categories`} component={ProductCategories} />
          {/* Orders */}
          <Route path={`${path}/orders`} component={Orders} />
          {/* Contact messages */}
          <Route path={`${path}/contact-messages`} component={ContactMessages} />
          {/* Removelocked devices */}
          <Route path={`${path}/remotelock-devices`} component={RemotelockDevicesPage} />
          {/* Booking */}
          <Route exact path={`${path}/booking-properties`} component={BookingsPage} />
          <Route path={`${path}/booking-properties/:bookingId`} component={BookingDetailsPage} />
          {/* Financial report */}
          <Route exact path={`${path}/financial-report`} component={FinancialReportPage} />
          {/* Map */}
          <Route exact path={`${path}/maps`} component={MapsPage} />
          <Route path={`${path}/maps/:id`} component={MapDetailsPage} />

          <Route exact path={`${path}/seasons`} component={SeasonsPage} />

          <Route path={`${path}/membership-passes`} component={MembershipPassesPage} />
          <Route exact path={`${path}/membership-types`} component={MembershipTypesPage} />
          <Route path={`${path}/membership-types/:id/waivers`} component={WaiversPage} />

          <Redirect from={`${path}`} to={`${path}/campers`} />
        </Switch>
      </div>
    </>
  )
}

const mapStateToProps = ({ AppState, layout }) => ({
  drawerOpen: layout.drawerOpen,
  status: AppSelectors.getStatus(AppState)
});

const mapDispatchToProps = dispatch => ({
  fetchAdminData: () => dispatch(AppActions.fetchAdminData())
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
