export const FinancialReportActionTypes = {
  DownloadOrders: '[Financial report] Download orders',
  DownloadOrdersSuccess: '[Financial report] Download orders success',

  DownloadReservations: '[Financial report] Download reservations',
  DownloadReservationsSuccess: '[Financial report] Download reservations success',

  DownloadMemberships: '[Financial report] Download memberships',
  DownloadMembershipsSuccess: '[Financial report] Download memberships success',

  FetchListSummary: '[Financial report] Fetch list summary',
  FetchListSummarySuccess: '[Financial report] Fetch list summary success',

  SetFilter: '[Financial report] Set filter',
  ResetFilter: '[Financial report] Reset filter',
  // Status
  StatusSuccess: '[Financial report] Status success',
  StatusError: '[Financial report] Status error',
  StatusInitial: '[Financial report] Status initial'
}

export const FinancialReportMessageTypes = {

}
