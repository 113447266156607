import { takeLatest, call, put } from 'redux-saga/effects';
// 
import * as LayoutActions from 'app/actions/layout.actions';
import Types from '../types/user.types';
import UserService from '../services/user.service';
import UserActions from '../actions/user.actions';

function* _searchUsers(action){
  try {
    const { params } = action.payload;
    const response = yield call(UserService.fetchUsers, params);
    yield put(UserActions.searchUsersSuccess(response));
  } catch(err){
    yield put(UserActions.searchUsersFailure());
    console.error(err);
  }
}

// Fetch campers list
function* _fetchAllCampers(action){
  try {
    const { params } = action.payload;
    const response = yield call(UserService.fetchAllCampers, params);
    yield put(UserActions.fetchAllCampersSuccess(response));
  } catch(err){
    yield put(UserActions.fetchAllCampersFailure());
    console.error(err);
  }
}

function* _updateMe(action){
  try {
    const { user } = action.payload;
    const response = yield call(UserService.updateMe, user);
    yield put(UserActions.updateMeSuccess(response));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: 'Profile was updated'
    }));
  } catch(error){
    console.error(error);
    yield put(UserActions.updateMeFailure());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _fetchAllStaff(action){
  UserActions.setIsFetching();
  try {
    const { params } = action.payload;
    const response = yield call(UserService.fetchAllStaff, params);
    yield put(UserActions.fetchAllStaffSuccess(response));
    UserActions.setIsFetched();
  } catch(err){
    yield put(UserActions.fetchAllStaffFailure());
    UserActions.setIsFetched();
    console.error(err);
  }
}

function* _fetchById(action){
  try {
    const { userId } = action.payload;
    const response = yield call(UserService.fetchById, userId);
    yield put(UserActions.fetchByIdSuccess(response));
  } catch(err){
    yield put(UserActions.fetchByIdFailure());
  }
}

function* _create(action){
  try {
    const { user } = action.payload;
    const response = yield call(UserService.create, user);
    yield put(UserActions.createSuccess(response));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: 'User was created'
    }));
  } catch(err){
    yield put(UserActions.createFailure());
    console.error(err);
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: err.message
    }));
  }
}

function* _update(action){
  try {
    const { user } = action.payload;
    const response = yield call(UserService.update, user);
    yield put(UserActions.updateSuccess(response));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: 'User was updated'
    }));
  } catch(err){
    yield put(UserActions.updateFailure());
    console.error(err);
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: err.message
    }));
  }
}

function* _updateCamper(action){
  try {
    const { user } = action.payload;
    const response = yield call(UserService.updateCamper, user);
    yield put(UserActions.updateCamperSuccess(response));
    yield put(UserActions.resetForm());
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: 'User was updated'
    }));
  } catch(err){
    yield put(UserActions.updateCamperFailure());
    console.error(err);
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: err.message
    }));
  }
}

function* _createCamper(action){
  try {
    const { data } = action.payload;
    const response = yield call(UserService.createCamper, data);
    yield put(UserActions.createCamperSuccess(response));
    yield put(UserActions.resetForm());
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: 'User was created'
    }));
  } catch(err){
    yield put(UserActions.updateCamperFailure());
    console.error(err);
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: err.message
    }));
  }
}

// function* _searchCamper(action){
//   try {
//     const { params } = action.payload;
//     const response = yield call(UserService.fetchAllCampers, {...params, limit: 50});
//     yield put(UserActions.searchCamperSuccess(response.data));
//   } catch(err){
//     yield put(UserActions.searchCamperFailure());
//     console.error(err);
//   }
// }

function* _delete(action){
  try {
    const { user } = action.payload;
    if ( window.confirm(`Are you sure you want to delete user: ${user.firstName}?`) ){
      yield call(UserService.delete, user.id);
      yield put(UserActions.deleteSuccess(user.id));
      yield put(LayoutActions.addNotification({
        type: 'success',
        message: 'User was deleted'
      }));
    }
  } catch(err){
    yield put(UserActions.deleteFailure());
    console.error(err);
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: err.message
    }));
  }
}

function* _disable(action){
  try {
    const { userId } = action.payload;
    yield call(UserService.disable, userId);
    yield put(UserActions.disableSuccess(userId));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: 'User was disabled'
    }));
  } catch(err){
    yield put(UserActions.disableFailure());
    console.error(err);
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: err.message
    }));
  }
}

function* _enable(action){
  try {
    const { userId } = action.payload;
    yield call(UserService.enable, userId);
    yield put(UserActions.enableSuccess(userId));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: 'User was enabled'
    }));
  } catch(err){
    yield put(UserActions.enableFailure());
    console.error(err);
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: err.message
    }));
  }
}

export function* userSaga(){
  yield takeLatest(Types.SEARCH_USERS, _searchUsers);
  yield takeLatest(Types.FETCH_ALL_CAMPERS, _fetchAllCampers);
  yield takeLatest(Types.FETCH_ALL_STAFF, _fetchAllStaff);
  yield takeLatest(Types.FETCH_BY_ID, _fetchById);
  yield takeLatest(Types.CREATE, _create);
  yield takeLatest(Types.UPDATE, _update);
  yield takeLatest(Types.UPDATE_ME, _updateMe);
  yield takeLatest(Types.UPDATE_CAMPER, _updateCamper);
  yield takeLatest(Types.CREATE_CAMPER, _createCamper);
  // yield takeLatest(Types.SEARCH_CAMPER, _searchCamper);
  yield takeLatest(Types.DELETE, _delete);
  yield takeLatest(Types.DISABLE, _disable);
  yield takeLatest(Types.ENABLE, _enable);
}
