import { Action } from 'redux';
import Statuses from 'types/statuses';

// Models
export interface ISeason {
  id: number;
  name: string;
  slots: Array<ISeasonSlot>;
  // 
  color?: string;
};

export interface ISeasonSlot {
  start: string;
  end: string;
};

export interface ISeasonData {
  name: string;
  slots?: Array<ISeasonSlot>;
};

export interface ISlotData {
  uuid: string;
  startMonth: string;
  startDay: string;
  endMonth: string;
  endDay: string;
};

// State
export interface ISeasonState {
  items: Array<ISeason> | null;
  isFetching: boolean;
  isLoading: boolean;
  status: Statuses;
};

// Messages
export enum SeasonMessages {
  CreateSeason = 'Season was created',
  UpdateSeason = 'Season was updated',
  DeleteSeason = 'Season was deleted'
};

// Types
export enum SeasonTypes {
  FetchSeasonsList = '@@season/Fetch seasons list',
  FetchSeasonsListSuccess = '@@season/Fetch seasons list success',

  CreateSeason = '@@season/Create season',
  CreateSeasonSuccess = '@@season/Create season success',

  UpdateSeason = '@@season/Update season',
  UpdateSeasonSuccess = '@@season/Update season success',

  DeleteSeason = '@@season/Delete season',
  DeleteSeasonSuccess = '@@season/Delete season success',

  UpdateSlots = '@@season/Update slots',
  UpdateSlotsSuccess = '@@season/Update slots success',

  SetStatus = '@@season/Set status',
  SetInitialField = '@@season/Set initial field'
};

// Action types
interface FetchSeasonsList extends Action<typeof SeasonTypes.FetchSeasonsList> {}
interface FetchSeasonsListSuccess extends Action<typeof SeasonTypes.FetchSeasonsListSuccess> {
  payload: { items:Array<ISeason> }
}

interface CreateSeason extends Action<typeof SeasonTypes.CreateSeason> {
  payload: { data:ISeasonData }
}
interface CreateSeasonSuccess extends Action<typeof SeasonTypes.CreateSeasonSuccess> {
  payload: { item:ISeason }
}

interface UpdateSeason extends Action<typeof SeasonTypes.UpdateSeason> {
  payload: { seasonId:number; data:ISeasonData; }
}
interface UpdateSeasonSuccess extends Action<typeof SeasonTypes.UpdateSeasonSuccess> {
  payload: { item:ISeason }
}

interface DeleteSeason extends Action<typeof SeasonTypes.DeleteSeason> {
  payload: { seasonId:number }
}
interface DeleteSeasonSuccess extends Action<typeof SeasonTypes.DeleteSeasonSuccess> {
  payload: { seasonId:number }
}

interface UpdateSlots extends Action<typeof SeasonTypes.UpdateSlots> {
  payload: { slots:any, seasonId?:number, data?:ISeasonData }
}
interface UpdateSlotsSuccess extends Action<typeof SeasonTypes.UpdateSlotsSuccess> {
  payload: { item:ISeason, items:Array<ISeason> }
}

interface SetStatus extends Action<typeof SeasonTypes.SetStatus> {
  payload: { status:Statuses }
}
interface SetInitialField extends Action<typeof SeasonTypes.SetInitialField> {
  payload: { field:keyof ISeasonState }
}

export type SeasonActionTypes =
  FetchSeasonsList | FetchSeasonsListSuccess |
  CreateSeason | CreateSeasonSuccess |
  UpdateSeason | UpdateSeasonSuccess |
  DeleteSeason | DeleteSeasonSuccess |
  UpdateSlots | UpdateSlotsSuccess |
  SetStatus | SetInitialField
;
