import React from 'react';
// Models
import { IWaiver } from 'models/Waiver';
// Bootstrap
import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
// Dialogs
import WaiverFormDialog from 'dialogs/WaiverForm.dialog';
// Utilities
import { parseUTCDate } from 'app/utilities/datetime.convert';

type Props = {
  waiver: IWaiver;
  waiverId: number | null;
  onSetDefault: (waiverId:number) => void;
  onDelete: (waiverId:number) => void;
};

const WaiversListItem:React.FC<Props> = ({
  // Props
  waiver, waiverId, onSetDefault, onDelete
}) => {
  const [ dialogWaiverPreviewOpen, setDialogWaiverPreviewOpen ] = React.useState<boolean>(false);
  const [ dialogWaiverFormOpen, setDialogWaiverFormOpen ] = React.useState<boolean>(false);

  const toggleDialogWaiverPreview = () => setDialogWaiverPreviewOpen((prevState:boolean) => !prevState);
  const toggleDialogWaiverForm = () => setDialogWaiverFormOpen((prevState:boolean) => !prevState);

  const handleSetDefault = () => onSetDefault(waiver.id);
  const handleDelete = () => onDelete(waiver.id);

  return (
    <React.Fragment>
      <li className="list-group-item | d-flex align-items-center">
        <div className="flex-grow-1">
          <h4 className="mb-1">{waiver.name}</h4>
          <small className="d-block">{parseUTCDate(waiver.createdOn)}</small>
          {waiverId === waiver.id ? (
            <div className="pt-1">
              <span className="badge bg-info text-white">Default</span>
            </div>
          ) : null}
        </div>
        <div>
          <DropdownButton
            as={ButtonGroup}
            alignRight
            className="ml-2"
            id={`waiver-item-${waiver.id}-dropdown`}
            size="sm"
            variant="outline-secondary"
            title={''}
          >
            <Dropdown.Item
              as="button"
              onClick={toggleDialogWaiverPreview}
            >Preview</Dropdown.Item>
            {waiverId !== waiver.id &&
              <Dropdown.Item
                as="button"
                onClick={handleSetDefault}
              >Set default waiver</Dropdown.Item>
            }
            {!waiver.inUse ? (
              <React.Fragment>
                <Dropdown.Item
                  as="button"
                  onClick={toggleDialogWaiverForm}
                >Edit</Dropdown.Item>
                {waiverId !== waiver.id &&
                  <Dropdown.Item
                    as="button"
                    className="text-danger"
                    onClick={handleDelete}
                  >Delete</Dropdown.Item>
                }
              </React.Fragment>
            ) : null}
          </DropdownButton>
        </div>
      </li>
      {dialogWaiverPreviewOpen ? (
        <WaiverFormDialog
          isPreview={true}
          waiverId={waiver.id}
          show={dialogWaiverPreviewOpen}
          onHide={toggleDialogWaiverPreview}
        />
      ) : null}
      {dialogWaiverFormOpen ? (
        <WaiverFormDialog
          waiverId={waiver.id}
          show={dialogWaiverFormOpen}
          onHide={toggleDialogWaiverForm}
        />
      ) : null}
    </React.Fragment>
  )
}

export default WaiversListItem;
