import React, { Component } from 'react';
// Store
import { connect } from 'react-redux';
import OrderActions from '../../../actions/order.actions';
import * as InventoryGroupSelectors from 'store/inventory-group/inventory-group.selectors';
// Components
import { Input, Select } from '../../../components/Controls';
// Utils
import { setStartDate, setEndDate } from '../../../utilities/datetime.convert';

let timeout = null;

class OrderFilter extends Component {
  constructor(props){
    super(props);

    const { params: { orderNumber, statuses, start, end, inventoryGroupId }, inventoryGroupOptions } = props;

    this.state = {
      limit: 20,
      offset: 0,
      orderNumber,
      statuses,
      start,
      end,
      inventoryGroupId
    };
  }

  handleSearchOrder = ({ target: { name, value } }) => {
    this.setState({
      [name]: value
    }, () => {
      const { offset, limit, orderNumber, statuses, start, end } = this.state;
      const self = this;
      if ( timeout ) clearTimeout(timeout);
      timeout = setTimeout(() => {
        const params = { offset, limit, orderNumber, statuses };
        if ( start ) params['start'] = setStartDate(start);
        if ( end ) params['end'] = setEndDate(end);
        console.log(params);
        self.props.fetchAll(params);
      }, 1000);
    })
  }

  handleSearchDate = ({ target: { name, value } }) => {
    this.setState({
      [name]: value
    }, () => {
      const { offset, limit, orderNumber, statuses, start, end } = this.state;
      const params = { offset, limit, orderNumber, statuses };
      if ( start ) params['start'] = setStartDate(start);
      if ( end ) params['end'] = setEndDate(end);
      this.props.fetchAll(params);
    })
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState({
      [name]: value
    }, () => {
      const { offset, limit, orderNumber, statuses, start, end, inventoryGroupId } = this.state;
      const params = { offset, limit, orderNumber, statuses };
      if ( start ) params['start'] = setStartDate(start);
      if ( end ) params['end'] = setEndDate(end);
      if ( inventoryGroupId ) params['inventoryGroupId'] = inventoryGroupId;
      this.props.fetchAll(params);
    });
  }

  handleExport = () => {
    const { orderNumber, statuses, start, end, inventoryGroupId } = this.state;
    const params = { orderNumber, statuses };
    if ( start ) params['start'] = setStartDate(start);
    if ( end ) params['end'] = setEndDate(end);
    if ( inventoryGroupId ) params['inventoryGroupId'] = inventoryGroupId;
    this.props.export(params);
  }

  render() {
    const { orderNumber, statuses, start, end, inventoryGroupId } = this.state;
    const { inventoryGroupOptions } = this.props;
    return (
      <div className="row row-8">
        <div className="col-12 col-md-6 col-lg-2">
          <Input
            label="Order number" type="number" id="orderNumber" name="orderNumber"
            value={orderNumber}
            onChange={this.handleSearchOrder}
          />
        </div>
        <div className="col-12 col-md-6 col-lg-2">
          <Select
            label="Status" id="statuses" name="statuses"
            value={statuses}
            onChange={this.handleChange}
            options={[
              { value: '', label: 'All' },
              { value: 'processing', label: 'Processing' },
              { value: 'readyForPickup', label: 'Ready for pickup' },
              { value: 'completed', label: 'Completed' },
              { value: 'cancelled', label: 'Cancelled' }
            ]}
          />
        </div>
        <div className="col-12 col-md-6 col-lg-2">
          <Select
            label="Inventory group" id="inventoryGroupId" name="inventoryGroupId"
            value={inventoryGroupId}
            onChange={this.handleChange}
            options={[
              { value: '', label: 'All' },
              ...inventoryGroupOptions
            ]}
          />
        </div>
        <div className="col-12 col-md-6 col-lg-2">
          <Input
            label="From date" type="date" id="fromDate" name="start"
            value={start}
            onChange={this.handleSearchDate}
          />
        </div>
        <div className="col-12 col-md-6 col-lg-2">
          <Input
            label="To date" type="date" id="toDate" name="end"
            value={end}
            onChange={this.handleSearchDate}
          />
        </div>
        <div className="col-12 col-lg-2">
          <div className="form-group">
            <label htmlFor="" className="d-none d-lg-inline-block">&nbsp;</label>
            <button
              className="btn btn-primary btn-sm btn-block"
              onClick={() => this.handleExport()}
            >Export</button>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    params: state.OrderState.params,
    inventoryGroupOptions: InventoryGroupSelectors.getItemsForSelect(state)
  }),
  dispatch => ({
    fetchAll: params => dispatch(OrderActions.fetchAll(params)),
    export: params => dispatch(OrderActions.export(params))
  })
)(OrderFilter);
