import React from 'react';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
// Redux
import { connect } from 'react-redux';
import { BookingActions } from 'app/store/booking/booking.actions';
import * as BookingSelectors from 'app/store/booking/booking.selectors';
// Components
import ButtonLoading from 'app/components/Buttons/ButtonLoading';
// Utilities
import { isRequired, isDate, startValidation, sameAsStart, lessThanStart } from 'utilities/validation';

const ReservationSearchForm = ({
  // Props
  setData,
  // State
  booking, status,
  // Dispatch
  checkAvailability
}) => {
  const { period } = booking;
  const { register, handleSubmit, errors, getValues } = useForm({
    defaultValues: {
      start: '',
      end: '',
      hours: 1
      // quantity: 1
    }
  });

  const onSubmit = data => {
    const { start, end, hours } = data;
    const quantity = 1;
    checkAvailability(
      booking,
      start,
      booking.period === 'hour' ? dayjs(start).add(1, 'day') : end,
      quantity,
      hours
    );
    setData(prevState => ({
      ...prevState,
      formVisible: false,
      totalPrice: 0,
      start,
      end: booking.period === 'hour' ? dayjs(start).add(1, 'day') : end,
      quantity,
      hours,
      items: []
    }))
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row row-8">
        <div className="col-12 col-sm-6">
          <div className="form-group">
            <label htmlFor="start">{period !== 'hour' ? 'Start' : 'Date'}&nbsp;*</label>
            <input
              ref={register({
                required: isRequired,
                pattern: isDate,
                validate: value => startValidation(value)
              })}
              className={`form-control form-control-sm ${ errors.start && 'is-invalid' }`}
              id="start" name="start" type="date"
            />
            {errors.start
              ? errors.start.type === 'validate'
                ? <div className="invalid-feedback">Start date can`t be in past</div>
                : <div className="invalid-feedback">{errors.start.message}</div>
              : null
            }
          </div>
        </div>
        {period !== 'hour' ? (
          <div className="col-12 col-sm-6">
            <div className="form-group">
              <label htmlFor="end">End&nbsp;*</label>
              <input
                ref={register({
                  required: isRequired,
                  pattern: isDate,
                  validate: {
                    sameAsStart: value => sameAsStart(value, getValues('start')),
                    lessThanStart: value => lessThanStart(value, getValues('start'))
                  }
                })}
                className={`form-control form-control-sm ${ errors.end && 'is-invalid' }`}
                id="end" name="end" type="date"
              />
              {errors.end
                ? errors.end.type === 'sameAsStart'
                  ? <div className="invalid-feedback">End date can`t be same as start</div>
                  : errors.end.type === 'lessThanStart'
                    ? <div className="invalid-feedback">End date can`t be less than start date</div>
                    : <div className="invalid-feedback">{errors.end.message}</div>
                : null
              }
            </div>
          </div>
        ) : (
          <div className="col-12 col-sm-6">
            <div className="form-group">
              <label htmlFor="end">Hours&nbsp;*</label>
              <input
                ref={register({ required: isRequired })}
                className={`form-control form-control-sm ${ errors.hours && 'is-invalid' }`}
                id="hours" name="hours" type="number"
              />
              {errors.hours ? <div className="invalid-feedback">{errors.hours.message}</div> : null}
            </div>
          </div>
        )}
        {/* <div className="col-12 col-sm-2">
          <div className="form-group">
            <label htmlFor="quantity">Quantity&nbsp;*</label>
            <input
              ref={register({ required: isRequired })}
              className={`form-control form-control-sm ${ errors.quantity && 'is-invalid' }`}
              id="quantity" name="quantity" type="number"
            />
            {errors.quantity ? <div className="invalid-feedback">{errors.quantity.message}</div> : null}
          </div>
        </div> */}
      </div>
      <div className="text-right">
        <ButtonLoading
          type="submit"
          loading={status === 'Loading'}
        >Search</ButtonLoading>
      </div>
    </form>
  )
}

const mapStateToProps = ({ BookingState }) => ({
  booking: BookingSelectors.getBooking(BookingState),
  status: BookingSelectors.getStatus(BookingState)
});

const mapDispatchToProps = dispatch => ({
  checkAvailability: (booking, start, end, quantity, hourQuantity) => dispatch(BookingActions.checkAvailability(booking, start, end, quantity, hourQuantity))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReservationSearchForm);
