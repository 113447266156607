import React, {useEffect, useRef} from 'react';
import {useParams} from 'react-router-dom';
import dayjs from 'dayjs';
// Redux
import { connect } from 'react-redux';
import { ReservationActions } from 'app/store/reservation/reservation.actions';
import * as BookingItemSelectors from 'app/store/booking-item/booking-item.selectors';
import * as ReservationSelectors from 'app/store/reservation/reservation.selectors';
// Components
import { DataLoading } from 'app/components/Loadings';
// 
import CalendarHourlyTimelineItem from './CalendarHourlyTimelineItem';

const format = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';

let last_known_scroll_position = 0;
let ticking = false;
let last_known_top = 0;

const start = dayjs().startOf('day');
const times = 24;
const hours = [];
for ( let i = 0; i < times; i++ ){
  const hour = dayjs(start).add(i, 'hour').format('hh:mm A')
  hours.push(hour);
}

const CalendarHourlyTimeline = ({
  // Props
  now,
  // State
  bookingItems, reservationItems, status,
  // Dispatch
  fetchReservationDetails
}) => {
  const {bookingId} = useParams();

  const dayEl = useRef(null);

  useEffect(() => {
    const start = now.hour(0).minute(0).second(0).format(format);
    const end = now.hour(23).minute(59).second(59).format(format);

    fetchReservationDetails(bookingId, {
      limit: bookingItems.length * 24, start, end,
      statuses: 'upcoming,active,completed'
    });
    // eslint-disable-next-line
  }, [now]);

  useEffect(() => {
    last_known_top = dayEl.current.getBoundingClientRect().top;
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      last_known_scroll_position = 0;
      ticking = false;
      last_known_top = 0;
    }
    // eslint-disable-next-line
  }, []);

  const handleScroll = () => {
    last_known_scroll_position = window.scrollY - last_known_top >= 0 ? window.scrollY - last_known_top : 0;

    if ( !ticking ){
      window.requestAnimationFrame(() => {
        if ( dayEl && dayEl.current ){
          dayEl.current.style.top = `${ last_known_scroll_position }px`;
          ticking = false;
        }
      });
      ticking = true;
    }
  }

  if ( status === 'Fetching' ) return <DataLoading />;
  return (
    <div className="reservation-body">
      <div className="reservation-left">
        <div className="timeline timeline-category">
          <div className="timeline-item"/>
          {reservationItems.map((reservationItem, index) => {
            if ( reservationItem.bookingItem.id === 0 && reservationItem.timelines.length === 0 ) return '';
            return (
              <div
                key={index}
                className="timeline-item"
                style={{
                  height: Array.isArray(reservationItem.timelines)
                    ? `${reservationItem.timelines.length * 2.5}rem`
                    : `2.5rem`
                }}
              >
                <b>{reservationItem.bookingItem.identifier}</b>
                <small className="d-block text-muted">{reservationItem.bookingItem.reservationCategoryName}</small>
              </div>
            )
          })}
        </div>
      </div>
      <div className="reservation-right" style={{paddingBottom: '1rem', position: 'relative'}}>
        <div style={{ height: '2.5rem' }}></div>

        <div ref={dayEl} className="timeline timeline-day" style={{ position: 'absolute', top: 0, left: 0, right: 0, zIndex: 2 }}>
          {hours.map((hour, idx) => (
            <div key={idx} className="timeline-item | badge-white d-flex flex-direction-column justify-content-center">
              <span className="_day">{hour}</span>
            </div>
          ))}
        </div>

        {reservationItems.map((reservationItem, index) => (
          Array.isArray(reservationItem.timelines)
          ? 
            reservationItem.timelines.map((timeline, ti) => (
              <div key={`_${ti}`} className="timeline timeline-day">
                {Object.keys(timeline).map((hour, i) => (
                  <div key={`hour_${reservationItem.bookingItem.id}_${i}`} className="timeline-item">
                    {timeline[hour].hasReservation &&
                      <CalendarHourlyTimelineItem reservation={timeline[hour]} />
                    }
                  </div>
                ))}
              </div>
            ))
          :
            <div key={index} className="timeline timeline-day">
              {Object.keys(reservationItem.timelines).map((hour, i) => (
                <div key={`hour_${reservationItem.bookingItem.id}_${i}`} className="timeline-item">
                  {reservationItem.timelines[hour].hasReservation &&
                    <CalendarHourlyTimelineItem reservation={reservationItem.timelines[hour]} />
                  }
                  {reservationItem.timelines[hour].isOutOfService &&
                    <span
                      className="_outOfService"
                      style={{
                        width: `${100 * reservationItem.timelines[hour].days - 4}%`,
                      }}
                      title="Out of service"
                    >Out of service</span>
                  }
                </div>
              ))}
            </div>
        ))}
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  bookingItems: BookingItemSelectors.getBookingItems(state.BookingItemState),
  reservationItems: ReservationSelectors.getReservationItemsHourly(state, ownProps.now),
  status: ReservationSelectors.getStatus(state.ReservationState)
});

const mapDispatchToProps = dispatch => ({
  fetchReservationDetails: (bookingId, params) => dispatch(ReservationActions.fetchReservationDetails(bookingId, params))
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarHourlyTimeline);
