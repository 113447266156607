export const BookingItemActionTypes = {
  FetchBookingItems: '[Booking item] Fetch booking items',
  FetchBookingItemsSuccess: '[Booking item] Fetch booking items success',
  // Create
  Create: '[Booking item] Create',
  CreateSuccess: '[Booking item] Create success',
  // Update
  Update: '[Booking item] Update',
  UpdateSuccess: '[Booking item] Update success',
  // Delete
  Delete: '[Booking item] Delete',
  DeleteSuccess: '[Booking item] Delete success',
  // Actions
  // Mark as free
  MarkAsFree: '[Booking item] Mark as free',
  MarkAsFreeSuccess: '[Booking item] Mark as free success',
  // Mark as out of service
  MarkAsOutOfService: '[Booking item] Mark as out of service',
  MarkAsOutOfServiceSuccess: '[Booking item] Mark as out of service success',
  // Set filter
  Filter: '[Booking item] Filter',
  ClearFilter: '[Booking item] Clear filter',
  // Status
  ResetField: '[Booking item] Reset field',
  StatusSuccess: '[Booking item] Status success',
  StatusError: '[Booking item] Status error',
  StatusInitial: '[Booking item] Status initial',

  AssignRemotelockDevices: '[Booking item] Assign remotelock devices',
  AssignRemotelockDevicesSuccess: '[Booking item] Assign remotelock devices success',
}

export const ReservationMessageTypes = {
  Create: 'Booking property item created',
  Update: 'Booking property item updated',
  Delete: 'Booking property item deleted'
}
