import React from 'react';
// Dialogs
import SeasonFormDialog from 'dialogs/SeasonFormDialog';

const SeasonsCreate = () => {
  const [ dialogOpen, setDialogOpen ] = React.useState<boolean>(false);

  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);

  return (
    <React.Fragment>
      <div className="text-right mb-3">
        <button
          className="btn btn-primary btn-sm"
          type="button"
          onClick={handleDialogOpen}
        >Create season</button>
      </div>
      {dialogOpen ? (
        <SeasonFormDialog
          show={dialogOpen}
          onHide={handleDialogClose}
        />
      ) : null}
    </React.Fragment>
  )
}

export default SeasonsCreate;
