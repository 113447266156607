import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// Models
import { IMembership } from 'models/Membership';
// 
import VisitsDialog from './VisitsDialog';
import TransactionsDialog from './TransactionsDialog';
import CancelDialog from './CancelDialog';

dayjs.extend(utc);

type Props = {
  membership: IMembership;
};

const MembershipPassesListItem:React.FC<Props> = ({
  // Props
  membership
}) => {
  const { userData, membershipTypeData } = membership;

  const [ dialogVisitsOpen, setDialogVisitsOpen ] = React.useState<boolean>(false);
  const [ dialogTransactionsOpen, setDialogTransactionsOpen ] = React.useState<boolean>(false);
  const [ dialogCancelOpen, setDialogCancelOpen ] = React.useState<boolean>(false);

  const toggleDialogVisits = () => setDialogVisitsOpen((prevState:boolean) => !prevState);
  const toggleDialogTransactions = () => setDialogTransactionsOpen((prevState:boolean) => !prevState);
  const toggleDialogCancel = () => setDialogCancelOpen((prevState:boolean) => !prevState);

  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <div className="row row-8">
            <div className="col-9">
              <div className="d-flex align-items-center mb-2">
                <h5 className="mb-0 mr-3">{membershipTypeData.name}</h5>
                <span className={`badge ${membership.status === 'active' ? 'badge-success' : 'badge-secondary'}`}>
                  {membership.status}
                </span>
              </div>
              <div className="d-flex">
                <div className="d-flex flex-column pr-3">
                  <small className="text-secondary">Price</small>
                  <span style={{ fontWeight: 500 }}>${membershipTypeData.price}</span>
                </div>
                {/* {membershipTypeData.tax !== 0 ? (
                  <div className="d-flex flex-column pr-3">
                    <small className="text-secondary">Tax</small>
                    <span style={{ fontWeight: 500 }}>{membershipTypeData.tax}%</span>
                  </div>
                ) : null} */}
                {membership.membershipNumber ? (
                  <div className="d-flex flex-column pr-3">
                    <small className="text-secondary">Membership number</small>
                    <span style={{ fontWeight: 500 }}>{membership.membershipNumber}</span>
                  </div>
                ) : null}
                {membership.status === 'active' && membership.daysLeft ? (
                  <div className="d-flex flex-column pr-3">
                    <small className="text-secondary">Days left</small>
                    <span style={{ fontWeight: 500 }}>{membership.daysLeft}</span>
                  </div>
                ) : null}
                {membership.purchaseDate ? (
                  <div className="d-flex flex-column pr-3">
                    <small className="text-secondary">Purchase date</small>
                    <span style={{ fontWeight: 500 }}>{dayjs(membership.purchaseDate).format('MMMM DD, YYYY')}</span>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-3">
              <button
                className="btn btn-primary btn-sm btn-block"
                type="button"
                onClick={toggleDialogVisits}
              >Visits</button>
              <button
                className="btn btn-outline-primary btn-sm btn-block"
                type="button"
                onClick={toggleDialogTransactions}
              >Transactions</button>
              {membership.status === 'active' ? (
                <button
                  className="btn btn-outline-danger btn-sm btn-block"
                  type="button"
                  onClick={toggleDialogCancel}
                >Cancel & Refund</button>
              ) : null}
            </div>
          </div>
          <hr />
          <div className="d-flex">
            <div className="d-flex flex-column pr-3">
              <small className="text-secondary">User</small>
              <span style={{ fontWeight: 500 }}>{userData.firstName} {userData.lastName}</span>
            </div>
            {userData.email ? (
              <div className="d-flex flex-column pr-3">
                <small className="text-secondary">E-mail</small>
                <span style={{ fontWeight: 500 }}>{userData.email}</span>
              </div>
            ) : null}
            {userData.phone ? (
              <div className="d-flex flex-column pr-3">
                <small className="text-secondary">Phone</small>
                <span style={{ fontWeight: 500 }}>{userData.callingCode}{userData.phone}</span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {dialogVisitsOpen ? (
        <VisitsDialog
          membershipId={membership.id}
          show={dialogVisitsOpen}
          onHide={toggleDialogVisits}
        />
      ) : null}
      {dialogTransactionsOpen ? (
        <TransactionsDialog
          membershipId={membership.id}
          show={dialogTransactionsOpen}
          onHide={toggleDialogTransactions}
        />
      ) : null}
      {dialogCancelOpen ? (
        <CancelDialog
          membershipId={membership.id}
          show={dialogCancelOpen}
          onHide={toggleDialogCancel}
        />
      ) : null}
    </React.Fragment>
  )
}

export default MembershipPassesListItem;
