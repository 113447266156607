import React, { Component } from 'react';
// Store
import { connect } from 'react-redux';
import ProductActions from '../../../actions/product.actions';
// Components
import { DataLoading } from '../../../components/Loadings';
import { DataMessage } from '../../../components/DataMessage';
import { ButtonLoading } from '../../../components/Buttons';
// 
import ProductListItem from './ProductListItem';

class ProductList extends Component {
  componentDidMount(){
    const { params } = this.props;
    this.props.fetchAll({ ...params, offset: 0 });
  }

  handleMore = () => {
    const { params } = this.props;
    const { limit, offset } = params;
    this.props.fetchAll({ ...params, offset: offset + limit }, true);
  }

  render() {
    const { path, products, isFetching, hasMore, hasMoreLoading } = this.props;
    if ( isFetching ) return <DataLoading />
    return (
      <DataMessage data={products} type="product">
        <div className="row row-8">
          {products.map((product, index) => (
            <ProductListItem
              key={index}
              path={path}
              product={product}
            />
          ))}
        </div>
        {hasMore &&
          <div className="pt-1 text-center">
            <ButtonLoading
              loading={hasMoreLoading}
              onClick={() => this.handleMore()}
            >Load more</ButtonLoading>
          </div>
        }
      </DataMessage>
    )
  }
}

export default connect(
  ({ ProductState }) => ({
    params: ProductState.params,
    products: ProductState.products,
    isFetching: ProductState.isFetching,
    hasMore: ProductState.hasMore,
    hasMoreLoading: ProductState.hasMoreLoading
  }),
  dispatch => ({
    fetchAll: (params, isMore) => dispatch(ProductActions.fetchAll(params, isMore))
  })
)(ProductList);
