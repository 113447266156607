import create from './create-type';

const _type = 'product';

const _actions = [
  'FETCH_ALL',
  'FETCH_BY_ID',
  'CREATE',
  'UPDATE',
  'DELETE',
  'UPLOAD_IMAGE',
  'DELETE_IMAGE'
];

const Types = {};

for ( let i in _actions ){
  Types[_actions[i]] = create(_type, [_actions[i]]);
  Types[`${_actions[i]}_SUCCESS`] = create(_type, `${[_actions[i]]}_SUCCESS`);
  Types[`${_actions[i]}_FAILURE`] = create(_type, `${[_actions[i]]}_FAILURE`);
}

Types['SET_IS_FETCHED'] = create(_type, 'SET_IS_FETCHED');
Types['SET_INITIAL_STATE'] = create(_type, 'SET_INITIAL_STATE');

export default Types;

export const ProductMessageTypes = {
  Create: 'Product was created',
  Update: 'Product was updated',
  Delete: 'Product was deleted',

  UploadImage: 'Product image was uploaded',
  DeleteImage: 'Product image was deleted'
}
