import React from 'react';
// Redux
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import InventoryGroupActions from 'store/inventory-group/inventory-group.actions';
import * as InventoryGroupSelectors from 'store/inventory-group/inventory-group.selectors';
import { IInventoryGroup } from 'store/inventory-group/inventory-group.types';
// Components
import { Container } from 'components/Container';
// 
import InventoryGroupsCreateButton from './InventoryGroupsCreateButton';
import InventoryGroupFilter from './InventoryGroupFilter';
import InventoryGroupsListItem from './InventoryGroupsListItem';

type IProps = {
  // State
  items: Array<IInventoryGroup>;
  // Dispatch
  enableInventoryGroup: (id:number) => void;
  disableInventoryGroup: (id:number) => void;
  deleteInventoryGroup: (id:number) => void;
}

const InventoryGroupsPage:React.FC<IProps> = ({
  // State
  items,
  // Dispatch
  enableInventoryGroup, disableInventoryGroup, deleteInventoryGroup
}) => {
  return (
    <div className="container-fluid pt-3 pb-3">
      <InventoryGroupsCreateButton />
      <InventoryGroupFilter />
      <Container data={items} isFetching={false}>
        <div className="row row-8 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
          {items && items.map(item => (
            <div key={`inventory-group-item-${item.id}`} className="col | d-flex mb-2">
              <InventoryGroupsListItem
                item={item}
                onEnable={enableInventoryGroup}
                onDisable={disableInventoryGroup}
                onDelete={deleteInventoryGroup}
              />
            </div>
          ))}
        </div>
      </Container>
    </div>
  )
}

const mapStateToProps = (state:any) => ({
  items: InventoryGroupSelectors.getFilteredItems(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  enableInventoryGroup: (id:number) => dispatch(InventoryGroupActions.enable(id)),
  disableInventoryGroup: (id:number) => dispatch(InventoryGroupActions.disable(id)),
  deleteInventoryGroup: (id:number) => dispatch(InventoryGroupActions.delete(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryGroupsPage);
