import Statuses from 'types/statuses';
// Types
import { MembershipTypeTypes, MembershipTypeActionTypes } from './membership-type.types';
// Models
import { IMembershipType } from 'models/MembershipType';

export default class MembershipTypeActions {
  // Fetch membership types
  public static fetchMembershipTypes = ():MembershipTypeActionTypes => ({
    type: MembershipTypeTypes.FetchMembershipTypes
  });
  public static fetchMembershipTypesSuccess = (membershipTypes:Array<IMembershipType>):MembershipTypeActionTypes => ({
    type: MembershipTypeTypes.FetchMembershipTypesSuccess, payload: { membershipTypes }
  });
  // Fetch membership type
  public static fetchMembershipType = (membershipTypeId:number):MembershipTypeActionTypes => ({
    type: MembershipTypeTypes.FetchMembershipType, payload: { membershipTypeId }
  });
  public static fetchMembershipTypeSuccess = (membershipType:IMembershipType):MembershipTypeActionTypes => ({
    type: MembershipTypeTypes.FetchMembershipTypeSuccess, payload: { membershipType }
  });
  // Create membership type
  public static createMembershipType = (data:any):MembershipTypeActionTypes => ({
    type: MembershipTypeTypes.CreateMembershipType, payload: { data }
  });
  public static createMembershipTypeSuccess = (membershipType:IMembershipType):MembershipTypeActionTypes => ({
    type: MembershipTypeTypes.CreateMembershipTypeSuccess, payload: { membershipType }
  });
  // Update membership type
  public static updateMembershipType = (membershipTypeId:number, data:any):MembershipTypeActionTypes => ({
    type: MembershipTypeTypes.UpdateMembershipType, payload: { membershipTypeId, data }
  });
  public static updateMembershipTypeSuccess = (membershipType:IMembershipType):MembershipTypeActionTypes => ({
    type: MembershipTypeTypes.UpdateMembershipTypeSuccess, payload: { membershipType }
  });
  // Delete membership type
  public static deleteMembershipType = (membershipTypeId:number):MembershipTypeActionTypes => ({
    type: MembershipTypeTypes.DeleteMembershipType, payload: { membershipTypeId }
  });
  public static deleteMembershipTypeSuccess = (membershipTypeId:number):MembershipTypeActionTypes => ({
    type: MembershipTypeTypes.DeleteMembershipTypeSuccess, payload: { membershipTypeId }
  });
  // Enable membership type
  public static enableMembershipType = (membershipTypeId:number):MembershipTypeActionTypes => ({
    type: MembershipTypeTypes.EnableMembershipType, payload: { membershipTypeId }
  });
  public static enableMembershipTypeSuccess = (membershipTypeId:number):MembershipTypeActionTypes => ({
    type: MembershipTypeTypes.EnableMembershipTypeSuccess, payload: { membershipTypeId }
  });
  // Disable membership type
  public static disableMembershipType = (membershipTypeId:number):MembershipTypeActionTypes => ({
    type: MembershipTypeTypes.DisableMembershipType, payload: { membershipTypeId }
  });
  public static disableMembershipTypeSuccess = (membershipTypeId:number):MembershipTypeActionTypes => ({
    type: MembershipTypeTypes.DisableMembershipTypeSuccess, payload: { membershipTypeId }
  });
  // Reset default waiver
  public static resetDefaultWaiver = (membershipTypeId:number):MembershipTypeActionTypes => ({
    type: MembershipTypeTypes.ResetDefaultWaiver, payload: { membershipTypeId }
  });
  public static resetDefaultWaiverSuccess = (membershipType:IMembershipType):MembershipTypeActionTypes => ({
    type: MembershipTypeTypes.ResetDefaultWaiverSuccess, payload: { membershipType }
  });
  // Set default waiver
  public static setDefaultWaiver = (membershipTypeId:number):MembershipTypeActionTypes => ({
    type: MembershipTypeTypes.SetDefaultWaiver, payload: { membershipTypeId }
  });
  public static setDefaultWaiverSuccess = (membershipType:IMembershipType):MembershipTypeActionTypes => ({
    type: MembershipTypeTypes.SetDefaultWaiverSuccess, payload: { membershipType }
  });
  // Default
  // Set status
  public static setStatus = (status:Statuses):MembershipTypeActionTypes => ({
    type: MembershipTypeTypes.SetStatus, payload: { status }
  });
  public static setInitialField = (field:any):MembershipTypeActionTypes => ({
    type: MembershipTypeTypes.SetInitialField, payload: { field }
  });
}