import fetcher from 'app/utilities/fetcher';

const apiURL = '/admin/contentItems';
const headers = {
  'Content-Type': 'application/json'
};

export const ContentItemService = {
  // Fetch list
  fetchList: params => {
    const searchParams = new URLSearchParams();
    Object.keys(params).forEach(key => {
      if ( params[key] ) searchParams.append(key, params[key]);
    });
    return fetcher(`${apiURL}?${searchParams}`, { method: 'GET' });
  },
  // Create
  create: data => {
    return fetcher(apiURL, {
      method: 'POST',
      headers,
      body: JSON.stringify(data)
    });
  },
  // Update
  update: (id, data) => {
    return fetcher(`${apiURL}/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(data)
    });
  },
  // Delete
  delete: id => {
    return fetcher(`${apiURL}/${id}`, {
      method: 'DELETE'
    }, () => null);
  },
  // Move
  move: (id, type, moveId) => {
    return fetcher(`${apiURL}/${id}/actions/move`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ [type]: moveId })
    }, () => null);
  }
}

// class ContentItemService {
//   constructor(){
//     this._url = '/admin/contentItems';
//     this._categoriesUrl = '/admin/contentCategories';

//     this._CREATE_CONTENT_ITEM = 'Content item was created';
//     this._UPDATE_CONTENT_ITEM = 'Content item was updated';
//     this._DELETE_CONTENT_ITEM = 'Content item was deleted';
//   }
//   // Fetch content items
//   // @params {Object} params
//   // @params {string} params.category
//   // @params {boolean} params.enabled
//   // @params {number|string} params.limit
//   // @params {number|string} params.offset
//   // @params {string} params.sort
//   fetchContentItems = params => {
//     const searchParams = new URLSearchParams();
//     Object.keys(params).forEach(key => {
//       if ( params[key] ) searchParams.append(key, params[key]);
//     });
//     return fetcher(`${this._url}?${searchParams}`, {
//       method: 'GET'
//     });
//   }
//   // Fetch content item by id
//   // @params {string|number} contenteItemId
//   fetchContentItemById = contentItemId => {
//     return fetcher(`${this._url}/${contentItemId}`, {
//       method: 'GET'
//     });
//   }
//   // Create content item
//   // @params {object} contenteItem
//   createContentItem = contentItem => {
//     return fetcher(`${this._url}`, {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify(contentItem)
//     });
//       // .then(response => responseHandler(response, this._CREATE_CONTENT_ITEM))
//       // .catch(errorHandler);
//   }
//   // Update content item
//   // @params {object} contenteItem
//   updateContentItem = contentItem => {
//     return fetcher(`${this._url}/${contentItem.id}`, {
//       method: 'PUT',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify(contentItem)
//     });
//       // .then(response => responseHandler(response, this._UPDATE_CONTENT_ITEM))
//       // .catch(errorHandler);
//   }
//   // Delete content item
//   // @params {string|number} contenteItemId
//   deleteContentItem = contentItemId => {
//     return fetcher(`${this._url}/${contentItemId}`, {
//       method: 'DELETE'
//     }, () => null)
//       // .then(response => responseHandler(response, this._DELETE_CONTENT_ITEM))
//       // .catch(errorHandler);
//   }
//   // Move
//   moveContentItem = (contentItemId, type, moveId) => {
//     return fetcher(`${this._url}/${contentItemId}/actions/move`, {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify({ [type]: moveId })
//     }, () => null);
//   }

//   // Fetch categories
//   fetchCategories = () => {
//     return fetcher(`${this._categoriesUrl}`, {
//       method: 'GET'
//     });
//   }
//   // Fetch category details - TO DELETE - ALL RETURNED IN LIST
//   fetchCategoryDetails = name => {
//     return fetcher(`${this._categoriesUrl}/${name}`, {
//       method: 'GET'
//     });
//   }
// }

// const service = new ContentItemService();
// export default service;