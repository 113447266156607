import React from 'react';

import validate from '../../utilities/control.validate';

const InputControl = ({
  submitted = false, classes = 'form-control form-control-sm ',
  value = '', options, match, onChange, onFocus = () => {}
}) => {
  let { id, label, name, type = 'text', placeholder, autocomplete = 'off', hasAddon } = options;

  let inputRequired = false;

  if ( options.rules && options.rules.isRequired ) inputRequired = true;

  let valid = {
    isValid: true,
    validateMessage: ''
  };
  if ( submitted ){
    let msg;
    if ( typeof match !== 'undefined' && type === 'password' ) msg = 'Passwords do not match';
    valid = validate(value, options.rules, match, msg);
  }

  classes += !valid.isValid ? ' is-invalid' : '';

  const input = (
    <input
      className={classes}
      id={id}
      name={name}
      type={type}
      value={value}
      placeholder={placeholder}
      autoComplete={autocomplete}
      onChange={onChange}
      onFocus={onFocus}
      required={inputRequired}
    />
  );

  return (
    <div className="form-group">
      {
        label &&
        <label htmlFor={id}>
          {label}
          {inputRequired && <span>&nbsp;*</span>}
        </label>
      }
      {(hasAddon && hasAddon.prepend)
        ? 
          <div className="input-group input-group-sm">
            <div className="input-group-prepend">
              <div className="input-group-text">{hasAddon.prependIcon}</div>
            </div>
            {input}
          </div>
        :
          input
      }
      {
        valid.validateMessage &&
        <div className="invalid-feedback">{valid.validateMessage}</div>
      }
    </div>
  )
}

export default InputControl;