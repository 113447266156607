import React from 'react';
// Bootstrap
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
// 
import SystemEmailTemplatesList from './SystemEmailTemplatesList';
import SystemMessageTemplatesList from './SystemMessageTemplatesList';

type Props = {};

const SystemNotificationsPage:React.FC<Props> = () => {

  const [ defaultActiveKey, setDefaultActiveKey ] = React.useState<string>('mailTemplate');

  return (
    <div className="container-fluid pt-3 pb-3">
      <Tab.Container defaultActiveKey={defaultActiveKey}>
        <Nav className="justify-content-center mb-2 badge-white p-2 border shadow-sm" variant="pills" fill>
          <Nav.Item
            className="flex-1"
            onClick={() => setDefaultActiveKey('mailTemplate')}
          >
            <Nav.Link eventKey="mailTemplate">System email templates</Nav.Link>
          </Nav.Item>
          <Nav.Item
            className="flex-1"
            onClick={() => setDefaultActiveKey('messageTemplates')}
          >
            <Nav.Link eventKey="messageTemplates">System in-app/push and text message templates</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="mailTemplate">
            <SystemEmailTemplatesList />
          </Tab.Pane>
        </Tab.Content>
        <Tab.Content>
          <Tab.Pane eventKey="messageTemplates">
            <SystemMessageTemplatesList />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  )
}

export default SystemNotificationsPage;
