import { useEffect, useState } from 'react';

export default (callback, initialValue, validate) => {
  const [values, setValues] = useState(initialValue);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSetValues = values => setValues(values);

  const handleChange = event => {
    if ( event.persist ) event.persist();

    const { target: { type, name, value, checked } } = event;

    setValues(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  }

  const handleSubmit = event => {
    event.preventDefault();

    setIsSubmitting(true);
    setErrors(validate(values));
  }

  useEffect(() => {
    if ( Object.keys(errors).length === 0 && isSubmitting ) callback();
    // eslint-disable-next-line
  }, [errors])

  return [values, errors, handleChange, handleSubmit, handleSetValues];
}
