import React, { useState } from 'react';
// Rechart
import { ResponsiveContainer, PieChart, Pie, Legend, Tooltip, Cell } from 'recharts';
// Layouts
import FinancialReportDetails from 'app/layouts/Modals/FinancialReportDetails';

const FinancialReportPieChart = ({
  // Props
  type, item
}) => {
  const { items } = item;
  const hasMore = items.length > 9;
  const otherData =
    hasMore
    ? items.slice(9, items.length).reduce((acc, cur) => {
      acc['value'] = acc['value'] + cur.value;
      return acc;
    }, {
      color: '#d3d3d3',
      name: 'Other',
      value: 0
    })
    : {}
  ;
  const data = hasMore ? [...items.slice(0, 9), otherData] : items;

  const [show, setShow] = useState(false);

  const CustomTooltipContent = ({ active, payload }) => {
    if ( active ){
      return (
        <small className="d-block badge-white p-2 border shadow-sm">
          {`${payload[0].name}: ${payload[0].value} ${ type === 'orders' ? 'item(s)' : 'day(s)' }`}
        </small>
      )
    }
    return null;
  }

  const CustomLegendContent = ({ payload }) => {
    return (
      <ul className="list-unstyled | d-flex flex-wrap">
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            className={'d-flex align-items-center | mr-1'}
            style={{ fontSize: '.875rem', marginBottom: '1px' }}
          >
            <span
              style={{
                display: 'flex',
                flexShrink: 0,
                backgroundColor: entry.color,
                borderRadius: '50%',
                width: '.875rem',
                height: '.875rem',
                marginRight: '.25rem'
              }}
            ></span>
            {entry.value}
          </li>
        ))}
      </ul>
    )
  }

  return (
    <>
      <ResponsiveContainer width="100%" height={320}>
        <PieChart width={240} height={240}>
          <Pie
            data={data}
            dataKey="value"
            nameKey="name"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color}/>
            ))}
          </Pie>
          <Tooltip content={CustomTooltipContent} />
          <Legend
            iconType="circle"
            content={<CustomLegendContent />}
          />
        </PieChart>
      </ResponsiveContainer>
      {hasMore &&
        <div className="text-center">
          <button
            className="btn btn-primary btn-sm"
            type="button"
            onClick={() => setShow(true)}
          >Show full details</button>
        </div>
      }
      {/* Modal */}
      {show &&
        <FinancialReportDetails
          type={type}
          item={item}
          show={show}
          onHide={() => setShow(false)}
        />
      }
    </>
  )
}

export default FinancialReportPieChart;
