import { createSelector } from 'reselect';

const _pushNotifications = state => state.pushNotifications;
const _status = state => state.status;

export const getPushNotifications = createSelector(
  _pushNotifications, pushNotifications => pushNotifications
);
export const getStatus = createSelector(
  _status, status => status
);
