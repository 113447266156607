import React, { useState, useEffect } from 'react';
import { Switch, Route, Link, useLocation, useParams, useRouteMatch, Redirect } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
import { BookingActions } from 'app/store/booking/booking.actions';
import * as BookingSelectors from 'app/store/booking/booking.selectors';
// Bootstrap
import Nav from 'react-bootstrap/Nav';
// Pages
import CalendarPage from '../CalendarPage';
import ReservationsPage from '../ReservationsPage';
// ToDO
// import BlackoutsPage from '../BlackoutsPage';
import BookingItemsPage from '../BookingItemsPage';
import CtaegoriesPage from '../CategoriesPage';
import RestrictionsPage from '../RestrictionsPage';
import AvailabilityPage from '../AvailabilityPage';
import WaiversPage from 'pages/OrderManager/WaiversPage';
// import WaiversPage from '../WaiversPage';

const BookingDetailsPage = ({
  // Props
  // State
  booking, status,
  // Dispatch
  fetchBookingById
}) => {
  const {bookingId} = useParams();
  const {path, url} = useRouteMatch();
  const {pathname} = useLocation();

  const [dataFetched, setDataFetched] = useState(false);
  const [restrictionVisible, setRestrictionVisible] = useState(false);
  const [availabilityVisible, setAvailabilityVisible] = useState(false);

  useEffect(() => {
    fetchBookingById(bookingId);
    // eslint-disable-next-line
  }, [bookingId]);

  useEffect(() => {
    if ( status === 'Fetched' ){
      setDataFetched(true);
      if ( booking ){
        if ( booking.period === 'day' ){
          setRestrictionVisible(true);
          setAvailabilityVisible(true);
        }
        if ( booking.period === 'hour' ){
          setAvailabilityVisible(true);
        }
      }
    }
    // eslint-disable-next-line
  }, [status]);

  if ( !dataFetched ) return (
    <div className="text-center pt-3 pb-3">
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )
  return (
    <div className="area">
      <div className="container-fluid">
        <div className="area-head">
          <Link
            className="btn btn-link btn-sm pl-0 pr-0"
            to={`/${path.split('/')[1]}/booking-properties`}
          >
            <i className="fa fa-chevron-left mr-1" aria-hidden="true"></i>
            Booking Properties
          </Link>
          <h1 className="area-title">Booking Property: {booking.name}</h1>
        </div>
        <div className="area-container">

          <Nav
            activeKey={pathname}
            className="justify-content-center mb-2 p-1 badge-white border shadow-sm"
            variant="pills"
            fill
          >
            <Nav.Item>
              <Nav.Link href={`${url}/calendar`} as={Link} to={`${url}/calendar`}>Calendar</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href={`${url}/reservations`} as={Link} to={`${url}/reservations`}>Reservations</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href={`${url}/booking-property-items`} as={Link} to={`${url}/booking-property-items`}>
                {booking.type === 'hotel'
                  ? 'Rooms'
                  : 
                    booking.type === 'rentals'
                    ? 'Items'
                    : 'Sites'
                }
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href={`${url}/categories`} as={Link} to={`${url}/categories`}>Categories</Nav.Link>
            </Nav.Item>
            {restrictionVisible ? (
              <Nav.Item>
                <Nav.Link href={`${url}/restrictions`} as={Link} to={`${url}/restrictions`}>Restrictions</Nav.Link>
              </Nav.Item>
            ) : null}
            {availabilityVisible ? (
              <Nav.Item>
                <Nav.Link href={`${url}/availability`} as={Link} to={`${url}/availability`}>Availability</Nav.Link>
              </Nav.Item>
            ) : null}
            <Nav.Item>
              <Nav.Link href={`${url}/waivers`} as={Link} to={`${url}/waivers`}>Waivers</Nav.Link>
            </Nav.Item>
          </Nav>

          <div className="shadow-sm p-2 bg-white">
            <Switch>
              <Route path={`${path}/calendar`} component={CalendarPage} />
              <Route path={`${path}/reservations`} component={ReservationsPage} />
              {/* <Route path={`${path}/blackouts`} component={BlackoutsPage} /> */}
              <Route path={`${path}/booking-property-items`} component={BookingItemsPage} />
              <Route path={`${path}/categories`} component={CtaegoriesPage} />
              {restrictionVisible ? <Route path={`${path}/restrictions`} component={RestrictionsPage} /> : null}
              {availabilityVisible ? <Route path={`${path}/availability`} component={AvailabilityPage} /> : null}
              <Route path={`${path}/waivers`} component={WaiversPage} />
              <Redirect to={`${path}/calendar`} />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ BookingState }) => ({
  booking: BookingSelectors.getBooking(BookingState),
  status: BookingSelectors.getStatus(BookingState)
});

const mapDispatchToProps = dispatch => ({
  fetchBookingById: bookingId => dispatch(BookingActions.fetchBookingById(bookingId))
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingDetailsPage);
