import create from './create-action';
import Types from '../types/user.types';

class UserActions {
  // FETCH_ME
  fetchMe = () => create(Types.FETCH_ME)
  fetchMeSuccess = user => create(Types.FETCH_ME_SUCCESS, { user })
  fetchMeFailure = () => create(Types.FETCH_ME_FAILURE)
  // UPDATE_ME
  updateMe = user => create(Types.UPDATE_ME, { user })
  updateMeSuccess = user => create(Types.UPDATE_ME_SUCCESS, { user })
  updateMeFailure = () => create(Types.UPDATE_ME_FAILURE)
  // FETCH_ALL_CAMPERS
  fetchAllCampers = (params, isLoadInitial = false, isLoadMore = false) => create(Types.FETCH_ALL_CAMPERS, { params, isLoadInitial, isLoadMore })
  fetchAllCampersSuccess = camper => create(Types.FETCH_ALL_CAMPERS_SUCCESS, { camper })
  fetchAllCampersFailure = () => create(Types.FETCH_ALL_CAMPERS_FAILURE)
  // FETCH_ALL_STAFF
  fetchAllStaff = params => create(Types.FETCH_ALL_STAFF, { params })
  fetchAllStaffSuccess = staff => create(Types.FETCH_ALL_STAFF_SUCCESS, { staff })
  fetchAllStaffFailure = () => create(Types.FETCH_ALL_STAFF_FAILURE)
  // FETCH_BY_ID
  fetchById = userId => create(Types.FETCH_BY_ID, { userId })
  fetchByIdSuccess = user => create(Types.FETCH_BY_ID_SUCCESS, { user })
  fetchByIdFailure = () => create(Types.FETCH_BY_ID_FAILURE)
  // CREATE
  create = user => create(Types.CREATE, { user })
  createSuccess = user => create(Types.CREATE_SUCCESS, { user })
  createFailure = () => create(Types.CREATE_FAILURE)
  // UPDATE
  update = user => create(Types.UPDATE, { user })
  updateSuccess = user => create(Types.UPDATE_SUCCESS, { user })
  updateFailure = () => create(Types.UPDATE_FAILURE)
  // Camper
  updateCamper = user => create(Types.UPDATE_CAMPER, { user })
  updateCamperSuccess = user => create(Types.UPDATE_CAMPER_SUCCESS, { user })
  updateCamperFailure = () => create(Types.UPDATE_CAMPER_FAILURE)
  // Search camper
  // searchCamper = params => create(Types.SEARCH_CAMPER, { params })
  // searchCamperSuccess = camper => create(Types.SEARCH_CAMPER_SUCCESS, { camper })
  // searchCamperFailure = () => create(Types.SEARCH_CAMPER_FAILURE)
  searchUsers = params => create(Types.SEARCH_USERS, { params })
  searchUsersSuccess = user => create(Types.SEARCH_USERS_SUCCESS, { user })
  searchUsersFailure = () => create(Types.SEARCH_USERS_FAILURE)
  // DELETE
  delete = user => create(Types.DELETE, { user })
  deleteSuccess = userId => create(Types.DELETE_SUCCESS, { userId })
  deleteFailure = () => create(Types.DELETE_FAILURE)
  // DISABLE
  disable = userId => create(Types.DISABLE, { userId })
  disableSuccess = userId => create(Types.DISABLE_SUCCESS, { userId })
  disableFailure = () => create(Types.DISABLE_FAILURE)
  // ENABLE
  enable = userId => create(Types.ENABLE, { userId })
  enableSuccess = userId => create(Types.ENABLE_SUCCESS, { userId })
  enableFailure = () => create(Types.ENABLE_FAILURE)

  // Create camper
  createCamper = data => create(Types.CREATE_CAMPER, { data })
  createCamperSuccess = user => create(Types.CREATE_CAMPER_SUCCESS, { user })
  createCamperFailure = () => create(Types.CREATE_CAMPER_FAILURE)

  // 
  // filterCampers = params => create(Types.FILTER_CAMPERS, { params })
  resetParams = () => create(Types.RESET_PARAMS)

  checkUser = userId => create(Types.CHECK_USER, { userId })
  uncheckUser = userId => create(Types.UNCHECK_USER, { userId })

  checkAll = () => create(Types.CHECK_ALL)
  uncheckAll = () => create(Types.UNCHECK_ALL)

  // Set is fetched
  resetForm = () => create(Types.RESET_FORM);
  setIsFetching = () => create(Types.SET_IS_FETCHING);
  setIsFetched = () => create(Types.SET_IS_FETCHED)

  setInitialState = () => create(Types.SET_INITIAL_STATE)
}

const actions = new UserActions();
export default actions;
