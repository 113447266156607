export const ReservationActionTypes = {
  FetchReservations: '[Reservation] Fetch reservations',
  FetchReservationsSuccess: '[Reservation] Fetch reservations success',

  FetchReservationDetails: '[Reservation] Fetch reservation details',
  FetchReservationDetailsSuccess: '[Reservation] Fetch reservation details success',

  FetchAvailableBookingItemsForAssigning: '[Reservation] Fetch available booking items for assigning',
  FetchAvailableBookingItemsForAssigningSuccess: '[Reservation] Fetch available booking items for assigning success',

  ClearAvailableBookingItems: '[Reservatio] Clear availabel booking items',

  Create: '[Reservation] Create',
  CreateSuccess: '[Reservation] Create success',

  Assign: '[Reservation] Assign',
  AssignSuccess: '[Reservation] Assign success',

  Unassign: '[Reservation] Unassign',
  UnassignSuccess: '[Reservation] Unassign success',

  UnassignAssign: '[Reservation] Unassign assign',
  UnassignAssignSuccess: '[Reservation] Unassign assign success',

  CheckIn: '[Reservation] Check in',
  CheckInSuccess: '[Reservation] Check in success',

  CheckOut: '[Reservation] Check out',
  CheckOutSuccess: '[Reservation] Check out success',

  Refund: '[Reservation] Refund',
  RefundSuccess: '[Reservation] Refund success',

  Cancel: '[Reservation] Cancel',
  CancelSuccess: '[Reservation] Cancel success',

  SendReceipt: '[Reservation] Send receipt',
  SendReceiptSuccess: '[Reservation] Send receipt success',

  MarkOrderComplete: '[Reservation] Mark order complete',
  MarkOrderCompleteSuccess: '[Reservation] Mark order complete success',

  RemainingPayment: '[Reservation] Remaining payment',
  RemainingPaymentSuccess: '[Reservation] Remaining payment success',

  ChangeEndDate: '[Reservation] Change end date',
  ChangeEndDateSuccess: '[Reservation] Change end date success',
  ChangeEndDateFailure: '[Reservation] Change end date failure',

  Shift: '[Reservation] Shift',
  ShiftSuccess: '[Reservation] Shift success',
  ShiftError: '[Reservation] Shift error',

  FetchTransactions: '[Reservation] Fetch transactions',
  FetchTransactionsSuccess: '[Reservation] Fetch transactions success',

  SetParams: '[Reservation] Set params',
  ResetParams: '[Reservation] Reset params',

  ResetField: '[Reservation] Reset field',
  // Status
  StatusSuccess: '[Reservation] Status success',
  StatusError: '[Reservation] Status error',
  StatusInitial: '[Reservation] Status initial'
}

export const ReservationMessageTypes = {
  Create: 'Reservation created',
  CheckIn: 'Reservation checked in',
  CheckOut: 'Reservation checked out',
  Refund: 'Reservation refunded',
  Cancel: 'Reservation cancelled',
  RemainingPayment: 'Remaining payment success'
}
