import { call, put, takeLatest } from 'redux-saga/effects';
// Store
import * as LayoutActions from '../../actions/layout.actions';
import { ReservationCategoryActionTypes, ReservationCategoryMessageTypes } from './reservation-category.types';
import { ReservationCategoryActions } from './reservation-category.actions';
import { ReservationCategoryService } from './reservation-category.service';

function* _fetchCategories(action){
  try {
    const { bookingId, start, end, status } = action;
    const response = yield call(ReservationCategoryService.fetchCategories, bookingId, start, end, status);
    yield put(ReservationCategoryActions.fetchCategoriesSuccess(response));
  } catch(error){
    console.log(error);
    yield put(ReservationCategoryActions.setStatusError());
  }
}

function* _fetchAvailableCategories(action){
  try {
    const { bookingId, quantity, start, end, daysRange, hourQuantity } = action;
    const response = yield call(ReservationCategoryService.fetchAvailableCategories, bookingId, quantity, start, end, hourQuantity);
    yield put(ReservationCategoryActions.fetchAvailableCategoriesSuccess(response, daysRange));
  } catch(error){
    console.log(error);
    yield put(ReservationCategoryActions.setStatusError());
  }
}

function* _create(action){
  try {
    const { data } = action;
    const response = yield call(ReservationCategoryService.create, data);
    const imageResponse = yield call(ReservationCategoryService.updateImages, response.id, data.images.map(image => image.id));

    yield put(ReservationCategoryActions.createSuccess(imageResponse));
    yield put(ReservationCategoryActions.setStatusSuccess());
    yield put(ReservationCategoryActions.setStatusInitial());
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: ReservationCategoryMessageTypes.Create
    }));
  } catch(error){
    console.log(error);
    yield put(ReservationCategoryActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _update(action){
  try {
    const { categoryId, data } = action;
    yield call(ReservationCategoryService.update, categoryId, data);
    const response = yield call(ReservationCategoryService.updateImages, categoryId, data.images.map(image => image.id))
    const nextData = { ...response, attributes: data.attributes };
    if ( data.images.length === 0 ){
      nextData['images'] = [];
    }
    yield put(ReservationCategoryActions.updateSuccess(nextData));
    yield put(ReservationCategoryActions.setStatusSuccess());
    yield put(ReservationCategoryActions.setStatusInitial());
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: ReservationCategoryMessageTypes.Update
    }));
  } catch(error){
    console.log(error);
    yield put(ReservationCategoryActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _delete(action){
  try {
    const { category } = action;
    if ( window.confirm(`Are you sure you want to delete category: ${category.name}?`) ){
      yield call(ReservationCategoryService.delete, category.id);
      yield put(ReservationCategoryActions.deleteSuccess(category.id));
      yield put(LayoutActions.addNotification({
        type: 'success',
        message: ReservationCategoryMessageTypes.Delete
      }));
    }
  } catch(error){
    console.log(error);
    yield put(ReservationCategoryActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

// Actions
function* _enable(action){
  try {
    const { categoryId } = action;
    const response = yield call(ReservationCategoryService.enable, categoryId);
    yield put(ReservationCategoryActions.enableSuccess(response));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: ReservationCategoryMessageTypes.Enable
    }));
  } catch(error){
    console.log(error);
    yield put(ReservationCategoryActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _disable(action){
  try {
    const { categoryId } = action;
    const response = yield call(ReservationCategoryService.disable, categoryId);
    yield put(ReservationCategoryActions.disableSuccess(response));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: ReservationCategoryMessageTypes.Disable
    }));
  } catch(error){
    console.log(error);
    yield put(ReservationCategoryActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

export default function* (){
  yield takeLatest(ReservationCategoryActionTypes.FetchCategories, _fetchCategories);
  yield takeLatest(ReservationCategoryActionTypes.FetchAvailableCategories, _fetchAvailableCategories);
  yield takeLatest(ReservationCategoryActionTypes.Create, _create);
  yield takeLatest(ReservationCategoryActionTypes.Update, _update);
  yield takeLatest(ReservationCategoryActionTypes.Delete, _delete);
  // Actions
  yield takeLatest(ReservationCategoryActionTypes.Enable, _enable);
  yield takeLatest(ReservationCategoryActionTypes.Disable, _disable);
}