import React from 'react';
import { Link, useLocation } from 'react-router-dom';
// Redux
import { IMap } from 'store/map/map.types';
// Bootstrap
import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';

type IProps = {
  item: IMap,
  onDelete: (id:number) => void,
  onEnable: (id:number) => void,
  onDisable: (id:number) => void,
  onSetAsMain: (id:number) => void
}

const MapsListItem:React.FC<IProps> = ({
  // Props
  item, onDelete, onEnable, onDisable, onSetAsMain
}) => {
  const { id, enabled, main, name } = item;

  const { pathname } = useLocation();

  const handleDelete = () => onDelete(id);
  const handleEnable = () => onEnable(id);
  const handleDisable = () => onDisable(id);
  const handleSetAsMain = () => onSetAsMain(id);

  return (
    <div className="card | w-100">
      <div className="card-body">
        <h5 className="card-title | mb-2">{name}</h5>
        <div className="mb-2">
          {main ? (
            <span className="badge badge-primary | mr-2">Main</span>
          ) : null}
          <span className={`badge badge-${ enabled ? 'success' : 'danger' }`}>
            {enabled ? 'Enabled' : 'Disabled'}
          </span>
        </div>
      </div>
      <div className="card-footer | text-right">
        <Link
          className="btn btn-primary btn-sm"
          to={`${pathname}/${id}`}
        >Edit</Link>
        <DropdownButton
          as={ButtonGroup}
          alignRight
          className="ml-2"
          id={`map-list-item-${id}-dropdown`}
          size="sm"
          variant="outline-secondary"
          title={''}
        >
          {enabled ? (
            <Dropdown.Item
              as="button"
              onClick={handleDisable}
            >Disable</Dropdown.Item>
          ) : (
            <Dropdown.Item
              as="button"
              onClick={handleEnable}
            >Enable</Dropdown.Item>
          )}
          {!main ? (
            <Dropdown.Item
              as="button"
              onClick={handleSetAsMain}
            >Set as main</Dropdown.Item>
          ) : null}
          <Dropdown.Item
            as="button"
            className="text-danger"
            onClick={handleDelete}
          >Delete</Dropdown.Item>
        </DropdownButton>
      </div>
    </div>
  )
}

export default MapsListItem;
