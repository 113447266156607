import { takeLatest, call, put } from 'redux-saga/effects';
// Service
import {
  $fetchTemplatesList,
  $createTemplate,
  $updateTemplate,
  $deleteTemplate,
  $fetchTemplatesVariables
} from '../services/template.service';
// Types
import * as LayoutActions from 'app/actions/layout.actions';
import * as Types from '../types/template.types';
// Actions
import {
  fetchTemplatesListSuccess, fetchTemplatesListFailure,
  createTemplateSuccess, createTemplateFailure,
  updateTemplateSuccess, updateTemplateFailure,
  deleteTemplateSuccess, deleteTemplateFailure,
  fetchTemplatesVariablesSuccess,
  resetForm
} from '../actions/template.actions';

// Fetch templates variables
function* _fetchTemplatesList(){
  try {
    const response = yield call($fetchTemplatesList);
    yield put(fetchTemplatesListSuccess(response));
  } catch(err){
    yield put(fetchTemplatesListFailure());
    console.error(err);
  }
}

// Create template
function* _createTemplate(action){
  try {
    const response = yield call($createTemplate, action.template);
    yield put(createTemplateSuccess(response));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: 'Template was created'
    }));
    yield put(resetForm());
  } catch(err){
    yield put(createTemplateFailure());
    console.error(err);
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: err.message
    }));
  }
}
// Update template
function* _updateTemplate(action){
  try {
    const response = yield call($updateTemplate, action.template);
    yield put(updateTemplateSuccess(response));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: 'Template was updated'
    }));
    yield put(resetForm());
  } catch(err){
    yield put(updateTemplateFailure());
    console.error(err);
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: err.message
    }));
  }
}
// Delete template
function* _deleteTemplate(action){
  try {
    if ( window.confirm('Are you sure you want to delete template?') ){
      yield call($deleteTemplate, action.templateId);
      yield put(deleteTemplateSuccess(action.templateId));
      yield put(LayoutActions.addNotification({
        type: 'success',
        message: 'Template was deleted'
      }));
    }
  } catch(err){
    yield put(deleteTemplateFailure());
    console.error(err);
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: err.message
    }));
  }
}

// Fetch templates variables
function* _fetchTemplatesVariables(){
  const response = yield call($fetchTemplatesVariables);
  yield put(fetchTemplatesVariablesSuccess(response));
}

export function* templateSaga(){
  yield takeLatest(Types.FETCH_TEMPLATES_LIST, _fetchTemplatesList);
  yield takeLatest(Types.CREATE_TEMPLATE, _createTemplate);
  yield takeLatest(Types.UPDATE_TEMPLATE, _updateTemplate);
  yield takeLatest(Types.DELETE_TEMPLATE, _deleteTemplate);
  yield takeLatest(Types.FETCH_TEMPLATES_VARIABLES, _fetchTemplatesVariables);
}
