import { Reducer } from 'redux';
// Types
import Statuses from 'types/statuses';
import { WaiverTypes, WaiverActionTypes, } from './waiver.types';
// Models
import { IWaiverState } from './waiver.models'

export default class WaiverReducer {
  private static readonly _initialState:IWaiverState = {
    waivers: null,
    waiver: null,
    isLoading: false,
    status: Statuses.Initial
  };

  public static reducer:Reducer<IWaiverState, WaiverActionTypes> = (
    state = WaiverReducer._initialState,
    action
  ) => {
    switch (action.type){
      // Fetch waivers
      case WaiverTypes.FetchWaivers:
        return { ...state, waivers: null };
      case WaiverTypes.FetchWaiversSuccess:
        return { ...state, waivers: action.payload.waivers };
      // Fetch waiver
      case WaiverTypes.FetchWaiver:
        return { ...state, waiver: null };
      case WaiverTypes.FetchWaiverSuccess:
        return { ...state, waiver: action.payload.waiver };
      // Create waiver
      case WaiverTypes.CreateWaiver:
        return { ...state, isLoading: true };
      case WaiverTypes.CreateWaiverSuccess:
        return {
          ...state,
          waivers: state.waivers
            ? [...state.waivers, action.payload.waiver]
            : state.waivers
          ,
          isLoading: false
        };
      // Update waiver
      case WaiverTypes.UpdateWaiver:
        return { ...state, isLoading: true };
      case WaiverTypes.UpdateWaiverSuccess:
        return {
          ...state,
          waivers: state.waivers
            ? state.waivers.map((waiver:any) => {
                if ( waiver.id === action.payload.waiver.id ) return action.payload.waiver;
                return waiver;
              })
            : state.waivers
          ,
          isLoading: false
        }
      // Delete
      case WaiverTypes.DeleteWaiver:
        return { ...state, isLoading: true };
      case WaiverTypes.DeleteWaiverSuccess:
        return {
          ...state,
          waivers: state.waivers
            ? state.waivers.filter((waiver:any) => {
                return waiver.id !== action.payload.waiverId
              })
            : state.waivers
          ,
          isLoading: false
        };
      // Default
      // Set status
      case WaiverTypes.SetStatus:
        const hasError:boolean = action.payload.status === Statuses.Error;
        return {
          ...state,
          isLoading: hasError ? false : state.isLoading,
          status: action.payload.status
        };
      default:
        return state;
    }
  }

}