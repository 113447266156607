import React, { Component } from 'react';
// Store
import { connect } from 'react-redux';
import {
  fetchTemplatesList,
  setSelectedTemplate, clearSelectedTemplate,
  deleteTemplate
} from '../../../actions/template.actions';
// Layouts
import { TemplatesFormModal } from '../../../layouts/Modals';
// Components
import { DataLoading } from '../../../components/Loadings';
// 
import TemplatesList from './TemplatesList';

class Templates extends Component {
  state = {
    isModalShown: false
  }

  componentDidMount(){
    this.props.fetchTemplatesList();
  }

  handleShowModal = template => () => {
    (template && template.id) && this.props.setSelectedTemplate(template);
    this.setState(prevState => ({
      isModalShown: !prevState.isModalShown
    }));
  }

  handleHideModal = () => {
    this.setState({
      isModalShown: false
    }, () => {
      this.props.clearSelectedTemplate()
    });
  }

  handleDeleteTemplate = templateId => () => {
    this.props.deleteTemplate(templateId);
  }

  render() {
    const { isModalShown } = this.state;
    const { templates, isFetching } = this.props;
    return (
      <div className="area area-templates">
        <div className="container-fluid">
          <div className="area-container">
            <div className="form-group | clearfix">
              <button
                className="btn btn-primary btn-sm | float-right"
                type="button"
                onClick={this.handleShowModal(null)}
              >Create template</button>
            </div>
            {isFetching && <DataLoading />}
            {!isFetching &&
              <TemplatesList
                templates={templates}
                onSelectTemplate={this.handleShowModal}
                onDeleteTemplate={this.handleDeleteTemplate}
              />
            }
            {/* Modal */}
            {isModalShown &&
              <TemplatesFormModal
                show={isModalShown}
                onHide={this.handleHideModal}
              />
            }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  templates: state.template.templates,
  isFetching: state.template.isFetching
});

const mapDispatchToProps = dispatch => ({
  fetchTemplatesList: () => dispatch(fetchTemplatesList()),
  setSelectedTemplate: template => dispatch(setSelectedTemplate(template)),
  clearSelectedTemplate: () => dispatch(clearSelectedTemplate()),
  deleteTemplate: templateId => dispatch(deleteTemplate(templateId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Templates);
