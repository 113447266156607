import * as Types from '../types/auth.types';

// Login
export const login = credential => ({
  type: Types.LOGIN,
  credential
});
export const setLoginCredential = authCredential => ({
  type: Types.SET_LOGIN_CREDENTIAL,
  authCredential
});
export const loginFailure = () => ({
  type: Types.LOGIN_FAILURE
});

// Logout
export const logout = status => ({
  type: Types.LOGOUT,
  status
});
export const logoutSuccess = () => ({
  type: Types.LOGOUT_SUCCESS
});

// Check is token expired
export const checkIsTokenExpired = () => ({
  type: Types.CHECK_IS_TOKEN_EXPIRED,
});
// Token not found
export const tokenNotFound = () => ({
  type: Types.TOKEN_NOT_FOUND
});


// Reset form
export const resetForm = () => ({
  type: Types.RESET_FORM
});
export const setIsFetched = () => ({ type: Types.SET_IS_FETCHED });