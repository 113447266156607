export const ReservationCategoryActionTypes = {
  FetchCategories: '[Reservation category] Fetch categories',
  FetchCategoriesSuccess: '[Reservation category] Fetch categories success',
  // Fetch available categories
  FetchAvailableCategories: '[Reservation category] Fetch available categories',
  FetchAvailableCategoriesSuccess: '[Reservation category] Fetch available categories success',

  ClearAvailableCategories: '[Reservation category] Clear available categories',

  // Create
  Create: '[Reservation category] Create',
  CreateSuccess: '[Reservation category] Create success',
  // Update
  Update: '[Reservation category] Update',
  UpdateSuccess: '[Reservation category] Update success',
  // Delete
  Delete: '[Reservation category] Delete',
  DeleteSuccess: '[Reservation category] Delete success',
  // Actions
  // Enable
  Enable: '[Reservation category] Enable',
  EnableSuccess: '[Reservation category] Enable success',
  // Disable
  Disable: '[Reservation category] Disable',
  DisableSuccess: '[Reservation category] Disable success',
  // Images
  // Update
  UpdateImages: '[Reservation category] Update images',
  UpdateImagesSuccess: '[Reservation category] Update images success',

  // Increment/decrement available category
  IncrementAvailableCategory: '[Reservation category] Increment available category',
  DecrementAvailableCategory: '[Reservation category] Decrement available category',

  // Set filter
  Filter: '[Reservation category] Filter',
  ClearFilter: '[Reservation category] Clear filter',
  // Status
  StatusSuccess: '[Reservation category] Status success',
  StatusError: '[Reservation category] Status error',
  StatusInitial: '[Reservation category] Status initial'
}

export const ReservationCategoryMessageTypes = {
  Create: 'Reservation category created',
  Update: 'Reservation category updated',
  Delete: 'Reservation category deleted',
  Enable: 'Reservation category enabled',
  Disable: 'Reservation category disabled',
}
