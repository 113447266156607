import React, { useState } from 'react'
// Redux
import { connect } from 'react-redux';
import { ReservationActions } from 'app/store/reservation/reservation.actions';
import * as BookingSelectors from 'app/store/booking/booking.selectors';
import * as ReservationSelectors from 'app/store/reservation/reservation.selectors';
// Dialogs
import SendReceiptFormDialog from 'dialogs/SendReceiptForm.dialog'
import WaiverFormDialog from 'dialogs/WaiverForm.dialog';
// Layouts
import Details from 'app/layouts/Details';
import ReservationShiftDialog from 'dialogs/ReservationShift.dialog';
import ReservationProlongDialog from 'dialogs/ReservationProlong.dialog';
import ConfirmationDialog from 'dialogs/Confirmation.dialog';
// Utils
import { toCamperZoneTime, checkIsBeforeCamperTimeZone, parseUTCDate, checkIsAfterCamperTimeZone, checkIsSameOrAfterCamperTimeZone, toCamperZoneDate } from 'app/utilities/datetime.convert';
// 
import ReservationTransactionsDialog from './ReservationTransactionsDialog';

const ReservationDetails = ({
  // Props
  onClose,
  // State
  booking, reservation, stateIsLoading, stateStatus,
  // Dispatch
  onCheckIn, onCheckOut, onCancel, onMarkOrderComplete
}) => {
  const [ showReservationTransactionDialog, setShowReservationTransactionDialog ] = useState(false);
  const [ showReceiptDialog, setShowReceiptDialog ] = useState(false);

  const [ showReservationShiftDialog, setShowReservationShiftDialog ] = useState(false);
  const [ showReservationProlongDialog, setShowReservationProlongDialog ] = useState(false);
  const [ showConfirmationDialog, setShowConfirmationDialog ] = useState(false);

  const toggleReservationShiftDialog = () => setShowReservationShiftDialog(prevState => !prevState);
  const toggleReservationProlongDialog = () => setShowReservationProlongDialog(prevState => !prevState);
  const toggleConfirmationDialog = () => setShowConfirmationDialog(prevState => !prevState);

  const toggleReservationTransactionDialog = () => setShowReservationTransactionDialog(prevState => !prevState);
  const toggleReceiptDialog = () => setShowReceiptDialog(prevState => !prevState);

  const handleCheckIn = () => onCheckIn(reservation, false);
  const handleCheckOut = () => onCheckOut(reservation, false);
  const handleCancel = () => onCancel(reservation, false);
  const handleMarkOrderComplete = () => onMarkOrderComplete(reservation.id, reservation.extraOrder.id, 'completed');

  const isShiftButtonVisible = 
    booking.period !== 'hour'
    ? checkIsSameOrAfterCamperTimeZone(reservation.start, new Date(), 'day')
    : checkIsAfterCamperTimeZone(reservation.start, new Date(),  'hour')
  ;

  return (
    <>
      <Details onClose={onClose}>
        <small className="text-muted">ID: {reservation.reservationNumber}</small>
        <h6>Person</h6>
        <ul className="list-group | mb-2">
          <li className="list-group-item | d-flex align-items-center">
            <div className="text-center" style={{width: '2rem'}}>
              <i className="fa fa-user | text-primary" aria-hidden="true"></i>
            </div>
            <div className="flex-grow-1 pl-2" style={{lineHeight: '1.2'}}>
              <small className="text-muted">Username</small>
              <b className="d-block" style={{fontSize: '0.875rem'}}>{reservation.userData.firstName} {reservation.userData.lastName}</b>
            </div>
          </li>
          <li className="list-group-item | d-flex align-items-center">
            <div className="text-center" style={{width: '2rem'}}>
              <i className="fa fa-phone | text-primary" aria-hidden="true"></i>
            </div>
            <div className="flex-grow-1 pl-2" style={{lineHeight: '1.2'}}>
              <small className="text-muted">Phone</small>
              <b className="d-block" style={{fontSize: '0.875rem'}}>{reservation.userData.callingCode}{reservation.userData.phone}</b>
            </div>
          </li>
          <li className="list-group-item | d-flex align-items-center">
            <div className="text-center" style={{width: '2rem'}}>
              <i className="fa fa-envelope | text-primary" aria-hidden="true"></i>
            </div>
            <div className="flex-grow-1 pl-2" style={{lineHeight: '1.2'}}>
              <small className="text-muted">E-mail</small>
              <b className="d-block" style={{fontSize: '0.875rem'}}>{reservation.userData.email}</b>
            </div>
          </li>
        </ul>
        <h6>Reservation</h6>
        <ul className="list-group | mb-2">
          {booking.period !== 'hour' ? (
            <>
              <li className="list-group-item | d-flex align-items-center">
                <div className="text-center" style={{width: '2rem'}}>
                  <i className="fa fa-calendar-day | text-primary" aria-hidden="true"></i>
                </div>
                <div className="flex-grow-1 pl-2" style={{lineHeight: '1.2'}}>
                  <small className="text-muted">Arrival</small>
                  <b className="d-block" style={{fontSize: '0.875rem'}}>{toCamperZoneDate(reservation.start)}</b>
                </div>
              </li>
              <li className="list-group-item | d-flex align-items-center">
                <div className="text-center" style={{width: '2rem'}}>
                  <i className="fa fa-calendar-day | text-primary" aria-hidden="true"></i>
                </div>
                <div className="flex-grow-1 pl-2" style={{lineHeight: '1.2'}}>
                  <small className="text-muted">Departure</small>
                  <b className="d-block" style={{fontSize: '0.875rem'}}>{toCamperZoneDate(reservation.end)}</b>
                </div>
              </li>
            </>
          ) : (
            <li className="list-group-item | d-flex align-items-center">
              <div className="text-center" style={{width: '2rem'}}>
                <i className="fa fa-calendar-day | text-primary" aria-hidden="true"></i>
              </div>
              <div className="flex-grow-1 pl-2" style={{lineHeight: '1.2'}}>
                <small className="text-muted">Reservation time</small>
                <b className="d-block" style={{fontSize: '0.875rem'}}>{parseUTCDate(reservation.start)}, {toCamperZoneTime(reservation.start)} - {toCamperZoneTime(reservation.end)}</b>
              </div>
            </li>
          )}
          {reservation.checkOutDate ? (
            <li className="list-group-item | d-flex align-items-center">
              <div className="text-center" style={{width: '2rem'}}>
                <i className="fa fa-calendar-day | text-primary" aria-hidden="true"></i>
              </div>
              <div className="flex-grow-1 pl-2" style={{lineHeight: '1.2'}}>
                <small className="text-muted">Checked-out earlier at</small>
                <b className="d-block" style={{fontSize: '0.875rem'}}>
                  {booking.period !== 'hour' ? parseUTCDate(reservation.checkOutDate) : toCamperZoneTime(reservation.checkOutDate)}
                </b>
              </div>
            </li>
          ) : null}
          <li className="list-group-item | d-flex align-items-center">
            <div className="text-center" style={{width: '2rem'}}>
              <i className="fa fa-info-circle | text-primary" aria-hidden="true"></i>
            </div>
            <div className="flex-grow-1 pl-2" style={{lineHeight: '1.2'}}>
              <small className="text-muted">Status</small>
              <b className="d-block" style={{fontSize: '0.875rem'}}>{reservation.status}</b>
            </div>
          </li>
          {reservation.notes &&
            <li className="list-group-item | d-flex align-items-center">
              <div className="text-center" style={{width: '2rem'}}>
                <i className="fa fa-comment | text-primary" aria-hidden="true"></i>
              </div>
              <div className="flex-grow-1 pl-2" style={{lineHeight: '1.2'}}>
                <small className="text-muted">Notes</small>
                <b className="d-block" style={{fontSize: '0.875rem'}}>{reservation.notes}</b>
              </div>
            </li>
          }
          {(reservation.items && reservation.items.length !== 0) &&
            <li className="list-group-item | d-flex align-items-center">
              <div className="text-center" style={{width: '2rem'}}>
                <i className="fa fa-building | text-primary" aria-hidden="true"></i>
              </div>
              <div className="flex-grow-1 pl-2" style={{lineHeight: '1.2'}}>
                <small className="text-muted">Type</small>
                {reservation.items.map((item, index) => (
                  <React.Fragment key={`reservation-item-${reservation.id}-${index}-${item.reservationCategoryId}`} >
                    <small className="d-block mt-1"><b>{item.reservationCategoryName}</b></small>
                    {item.customerData && item.customerData.length !== 0 ? (
                      <>
                        {item.customerData.map((data, i) => (
                          <small key={`reservation-item-${reservation.id}-${index}-${item.reservationCategoryId}-data-${i}`} className="d-block mt-1">
                            {data.name}&nbsp;&bull;&nbsp;{data.value}
                          </small>
                        ))}
                      </>
                    ) : null}
                  </React.Fragment>
                ))}
                {/* {Object.keys(items).map((item, index) => (
                  <div key={index} className="mt-1" style={{fontSize: '0.875rem'}}>
                    {item}<b>&nbsp;x&nbsp;{items[item]}</b>
                  </div>
                ))} */}
              </div>
            </li>
          }
          {(reservation.petFeeIncluded) &&
            <li className="list-group-item | d-flex align-items-center">
              <div className="text-center" style={{width: '2rem'}}>
                <i className="fa fa-dog | text-primary" aria-hidden="true"></i>
              </div>
              <div className="flex-grow-1 pl-2" style={{lineHeight: '1.2'}}>
                <b className="d-block" style={{fontSize: '0.875rem'}}>Pet fee included</b>
              </div>
            </li>
          }
        </ul>
        {(reservation.extraOrder && reservation.extraOrder.id) ?
          <>
            <div className="d-flex align-items-center | mb-2">
              <div className="d-flex align-items-center | flex-grow-1">
                <h6 className="mr-2 mb-0">Extra orders</h6>
                <span className={`badge badge-${
                  reservation.extraOrder.status === 'processing'
                  ? 'secondary'
                  : reservation.extraOrder.status === 'completed'
                  ? 'success'
                  : reservation.extraOrder.status === 'pending'
                  ? 'warning'
                  : 'primary'
                }`}>{reservation.extraOrder.status}</span>
              </div>
              {reservation.extraOrder.status !== 'completed' ? (
                <div className="ml-2">
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={handleMarkOrderComplete}
                  >Mark completed</button>
                </div>
              ) : null}
            </div>
            <ul className="list-group | mb-2">
              {reservation.extraOrder.items.map((item, index) => (
                <Item key={index} item={item} />
              ))}
            </ul>
          </>
        : null}
        {/* {!reservation.paid && typeof reservation.deposit !== 'undefined' ? (
          <React.Fragment>
            {reservation.deposit > 0 ? (
              <React.Fragment>
                <div className="d-flex justify-content-between align-items-center">
                  <h6>Deposit paid</h6>
                  <b>${reservation.depositAmount}</b>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <h6>Remaining amount</h6>
                  <b>${reservation.totalPrice - reservation.depositAmount}</b>
                </div>
              </React.Fragment>
            ) : null}
            {reservation.deposit === 0 ? (
              <div className="d-flex justify-content-between align-items-center">
                <h6>Remaining amount</h6>
                <b>${reservation.totalPrice}</b>
              </div>
            ) : null}
          </React.Fragment>
        ) : null} */}
        {typeof reservation.depositAmount !== 'undefined' ? (
          <React.Fragment>
            <div className="d-flex justify-content-between align-items-center">
              <h6>Deposit paid</h6>
              <b>${reservation.depositAmount}</b>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <h6>Remaining amount</h6>
              <b>${reservation.totalPrice - reservation.depositAmount}</b>
            </div>
          </React.Fragment>
        ) : (
          !reservation.paid ? (
            <div className="d-flex justify-content-between align-items-center">
              <h6>Remaining amount</h6>
              <b>${reservation.totalPrice}</b>
            </div>
          ) : null
        )}
        <div className="d-flex justify-content-between align-items-center | mb-2">
          <h6>Total price</h6>
          <b>${reservation.totalPrice}</b>
        </div>
        {/* upcoming, active, completed, cancelled */}
        <div className="text-right">
          {(reservation.status !== 'cancelled' && reservation.status !== 'completed') &&
            <button className="btn btn-primary btn-sm | ml-2 mb-2" type="button" onClick={handleCheckOut}>Check Out</button>
          }
          {reservation.status === 'upcoming' ?
            checkIsBeforeCamperTimeZone(new Date(), reservation.end, booking.period !== 'hour' ? 'day' : 'hour') &&
              <button className="btn btn-primary btn-sm | ml-2 mb-2" type="button" onClick={handleCheckIn}>Check In</button>
          : null}
          {(reservation.status === 'upcoming' || reservation.status === 'active') && isShiftButtonVisible ? (
            <button
              className="btn btn-primary btn-sm | ml-2 mb-2"
              type="button"
              onClick={toggleReservationProlongDialog}
            >Change end date</button>
          ) : null}
          {(reservation.status === 'upcoming' && isShiftButtonVisible) ? (
            <button
              className="btn btn-primary btn-sm | ml-2 mb-2"
              type="button"
              onClick={toggleReservationShiftDialog}
            >Shift</button>
          ) : null}
          <div className="border-top pb-2" />
          <button
            className="btn btn-outline-primary btn-sm ml-2 mb-2"
            type="button"
            onClick={toggleReservationTransactionDialog}
          >Transactions</button>
          {reservation.status === 'upcoming' ? (
            <button
              className="btn btn-outline-danger btn-sm | ml-2 mb-2"
              type="button"
              onClick={toggleConfirmationDialog}
            >Cancel reservation</button>
          ) : null}
        </div>
      </Details>
      {showReservationTransactionDialog &&
        <ReservationTransactionsDialog
          reservation={reservation}
          email={reservation.userData.email}
          show={showReservationTransactionDialog}
          onHide={toggleReservationTransactionDialog}
        />
      }
      {showReceiptDialog &&
        <SendReceiptFormDialog
          id={reservation.id}
          type="reservation"
          isLoading={stateIsLoading}
          status={stateStatus}
          show={showReceiptDialog}
          onHide={toggleReceiptDialog}
        />
      }
      {showReservationShiftDialog ? (
        <ReservationShiftDialog
          reservation={reservation}
          show={showReservationShiftDialog}
          onHide={toggleReservationShiftDialog}
        />
      ) : null}
      {showReservationProlongDialog ? (
        <ReservationProlongDialog
          reservation={reservation}
          show={showReservationProlongDialog}
          onHide={toggleReservationProlongDialog}
        />
      ) : null}
      {showConfirmationDialog ? (
        <ConfirmationDialog
          show={showConfirmationDialog}
          onHide={toggleConfirmationDialog}
          onConfirm={handleCancel}
          title="Cancel reservation"
          content={`Are you sure you want to cancel reservation: ${reservation.reservationNumber}${reservation.userData ? ` - ${reservation.userData.firstName} ${reservation.userData.lastName}` : null}?`}
          loading={stateIsLoading}
          status={stateStatus}
        />
      ) : null}
    </>
  )
}

const Item = ({ item }) => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);
  return (
    <>
      <li className="list-group-item | d-flex align-items-center" style={{fontSize: '0.875rem'}}>
        <span className="flex-grow-1">{item.name}<b>&nbsp;x&nbsp;{item.quantity}</b></span>
        {item.waiverId ? (
          <button
            className="btn btn-link btn-sm"
            type="button"
            onClick={handleShow}
          >Waiver Accepted</button>
        ) : null}
      </li>
      {show ? (
        <WaiverFormDialog
          isPreview={true}
          waiverId={item.waiverId}
          show={show}
          onHide={handleHide}
        />
      ) : null}
    </>
  )
}

const mapStateToProps = (state, ownProps) => ({
  booking: BookingSelectors.getBooking(state.BookingState),
  reservation: ReservationSelectors.getReservationById(state.ReservationState, ownProps.reservationId),
  stateIsLoading: state.ReservationState.isLoading,
  stateStatus: state.ReservationState.status,
});

const mapDispatchToProps = dispatch => ({
  onCheckIn: (reservation, sendCheckInNotification) => dispatch(ReservationActions.checkIn(reservation, sendCheckInNotification)),
  onCheckOut: reservation => dispatch(ReservationActions.checkOut(reservation)),
  onCancel: reservation => dispatch(ReservationActions.cancel(reservation)),
  onMarkOrderComplete: (reservatioId, orderId, status) => dispatch(ReservationActions.markOrderComplete(reservatioId, orderId, status))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReservationDetails);
