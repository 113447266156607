import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
import { ReservationActions } from 'app/store/reservation/reservation.actions';
import * as BookingSelectors from 'app/store/booking/booking.selectors';
import * as ReservationSelectors from 'app/store/reservation/reservation.selectors';
// React bootstrap
import { Modal } from 'react-bootstrap';
// Layouts
import ReservationShiftDialog from 'dialogs/ReservationShift.dialog';
import ReservationProlongDialog from 'dialogs/ReservationProlong.dialog';
// Components
import { ButtonLoading } from 'app/components/Buttons';
import { DataLoading } from 'app/components/Loadings';
// Utils
import { parseUTCDate, checkIsAfterCamperTimeZone, checkIsSameOrAfterCamperTimeZone, toCamperZoneDate, toCamperZoneTime } from 'app/utilities/datetime.convert';

const ReservationModal = ({
  reservation, show, onHide,
  // State
  booking, bookingItems, status,
  // Dispatch
  fetchAvailableBookingItemsForAssigning, assignReservation, unassignReservation, unassignAssignReservation,
  clearAvailableBookingItems
}) => {
  const { bookingId } = useParams();
  const { item } = reservation;
  const [ bookingItemId, setBookingItemId ] = useState('none');
  const [ showReservationShiftDialog, setShowReservationShiftDialog ] = useState(false);
  const [ showReservationProlongDialog, setShowReservationProlongDialog ] = useState(false);

  const toggleReservationShiftDialog = () => setShowReservationShiftDialog(prevState => !prevState);
  const toggleReservationProlongDialog = () => setShowReservationProlongDialog(prevState => !prevState);

  useEffect(() => {
    if ( !item.isBlackout ){
      fetchAvailableBookingItemsForAssigning(
        item.bookingPropertyItemId ? true : false,
        item.id, item.reservationCategoryId
      );
    }
    return () => clearAvailableBookingItems();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( status === 'Success' ) onHide();
    // eslint-disable-next-line
  }, [status]);

  const handleChange = event => setBookingItemId(event.target.value);

  const handleAssignUnassingReservation = event => {
    event.preventDefault();
    if ( bookingItemId === 'none' ){
      if ( item.bookingPropertyItemId ){
        unassignReservation(item.id, [item.bookingPropertyItemId]);
      }
    } else {
      if ( item.bookingPropertyItemId ){
        unassignAssignReservation(item.id, [item.bookingPropertyItemId], [bookingItemId])
      } else {
        assignReservation(item.id, [bookingItemId]);
      }
    }
  }

  const isShiftButtonVisible = 
    booking.period !== 'hour'
    ? checkIsSameOrAfterCamperTimeZone(item.start, new Date(), 'day')
    : checkIsAfterCamperTimeZone(item.start, new Date(),  'hour')
  ;

  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Body>
          {status === 'ModalFetching'
            ? <DataLoading />
            :
              !item.isBlackout
              ?
                <>
                  <h6>Person</h6>
                  <ul className="list-group | mb-2">
                    <li className="list-group-item | d-flex align-items-center">
                      <div className="text-center" style={{width: '2rem'}}>
                        <i className="fa fa-user | text-primary" aria-hidden="true"></i>
                      </div>
                      <div className="flex-grow-1 pl-2" style={{lineHeight: '1.2'}}>
                        <small className="text-muted">Username</small>
                        <b className="d-block" style={{fontSize: '0.875rem'}}>{item.userData.firstName} {item.userData.lastName}</b>
                      </div>
                    </li>
                    <li className="list-group-item | d-flex align-items-center">
                      <div className="text-center" style={{width: '2rem'}}>
                        <i className="fa fa-phone | text-primary" aria-hidden="true"></i>
                      </div>
                      <div className="flex-grow-1 pl-2" style={{lineHeight: '1.2'}}>
                        <small className="text-muted">Phone</small>
                        <b className="d-block" style={{fontSize: '0.875rem'}}>{item.userData.callingCode}{item.userData.phone}</b>
                      </div>
                    </li>
                    <li className="list-group-item | d-flex align-items-center">
                      <div className="text-center" style={{width: '2rem'}}>
                        <i className="fa fa-envelope | text-primary" aria-hidden="true"></i>
                      </div>
                      <div className="flex-grow-1 pl-2" style={{lineHeight: '1.2'}}>
                        <small className="text-muted">E-mail</small>
                        <b className="d-block" style={{fontSize: '0.875rem'}}>{item.userData.email}</b>
                      </div>
                    </li>
                  </ul>
                  <h6>Reservation</h6>
                  <ul className="list-group | mb-2">
                    <li className="list-group-item | d-flex align-items-center">
                      <div className="text-center" style={{width: '2rem'}}>#</div>
                      <div className="flex-grow-1 pl-2" style={{lineHeight: '1.2'}}>
                        <b className="d-block" style={{fontSize: '0.875rem'}}>{item.reservationNumber}</b>
                      </div>
                    </li>
                    {booking.period !== 'hour' ? (
                      <>
                        <li className="list-group-item | d-flex align-items-center">
                          <div className="text-center" style={{width: '2rem'}}>
                            <i className="fa fa-calendar-day | text-primary" aria-hidden="true"></i>
                          </div>
                          <div className="flex-grow-1 pl-2" style={{lineHeight: '1.2'}}>
                            <small className="text-muted">Arrival</small>
                            <b className="d-block" style={{fontSize: '0.875rem'}}>{toCamperZoneDate(item.start)}</b>
                          </div>
                        </li>
                        <li className="list-group-item | d-flex align-items-center">
                          <div className="text-center" style={{width: '2rem'}}>
                            <i className="fa fa-calendar-day | text-primary" aria-hidden="true"></i>
                          </div>
                          <div className="flex-grow-1 pl-2" style={{lineHeight: '1.2'}}>
                            <small className="text-muted">Departure</small>
                            <b className="d-block" style={{fontSize: '0.875rem'}}>{toCamperZoneDate(item.end)}</b>
                          </div>
                        </li>
                      </>
                    ) : (
                      <li className="list-group-item | d-flex align-items-center">
                        <div className="text-center" style={{width: '2rem'}}>
                          <i className="fa fa-calendar-day | text-primary" aria-hidden="true"></i>
                        </div>
                        <div className="flex-grow-1 pl-2" style={{lineHeight: '1.2'}}>
                          <small className="text-muted">Reservation time</small>
                          <b className="d-block" style={{fontSize: '0.875rem'}}>{toCamperZoneDate(item.start)}, {toCamperZoneTime(item.start)} - {toCamperZoneTime(item.end)}</b>
                        </div>
                      </li>
                    )}
                    {item.checkOutDate ? (
                      <li className="list-group-item | d-flex align-items-center">
                        <div className="text-center" style={{width: '2rem'}}>
                          <i className="fa fa-calendar-day | text-primary" aria-hidden="true"></i>
                        </div>
                        <div className="flex-grow-1 pl-2" style={{lineHeight: '1.2'}}>
                          <small className="text-muted">Checked-out earlier at</small>
                          <b className="d-block" style={{fontSize: '0.875rem'}}>
                            {booking.period !== 'hour' ? parseUTCDate(item.checkOutDate) : toCamperZoneTime(item.checkOutDate)}
                          </b>
                        </div>
                      </li>
                    ) : null}
                    <li className="list-group-item | d-flex align-items-center">
                      <div className="text-center" style={{width: '2rem'}}>
                        <i className="fa fa-dollar-sign | text-primary" aria-hidden="true"></i>
                      </div>
                      <div className="flex-grow-1 pl-2" style={{lineHeight: '1.2'}}>
                        <small className="text-muted">Status</small>
                        <b className="d-block" style={{fontSize: '0.875rem'}}>{item.status}</b>
                      </div>
                    </li>
                    {item.notes &&
                      <li className="list-group-item | d-flex align-items-center">
                        <div className="text-center" style={{width: '2rem'}}>
                          <i className="fa fa-comment | text-primary" aria-hidden="true"></i>
                        </div>
                        <div className="flex-grow-1 pl-2" style={{lineHeight: '1.2'}}>
                          <small className="text-muted">Notes</small>
                          <b className="d-block" style={{fontSize: '0.875rem'}}>{item.notes}</b>
                        </div>
                      </li>
                    }
                    {(item.customerData && item.customerData.length !== 0) &&
                      <li className="list-group-item | d-flex align-items-center">
                        <div className="text-center" style={{width: '2rem'}}>
                          <i className="fa fa-building | text-primary" aria-hidden="true"></i>
                        </div>
                        <div className="flex-grow-1 pl-2" style={{lineHeight: '1.2'}}>
                          <small className="text-muted">Customer data</small>
                          {item.customerData.map((data, index) => (
                            <small key={`reservation-item-${item.id}-${index}`} className="d-block mt-1">
                              {data.name}&nbsp;&bull;&nbsp;{data.value}
                            </small>
                          ))}
                        </div>
                      </li>
                    }
                    <li className="list-group-item | d-flex align-items-center">
                      <div className="text-center" style={{width: '2rem'}}>
                        <i className="fa fa-door-closed | text-primary" aria-hidden="true"></i>
                      </div>
                      <div className="flex-grow-1 pl-2" style={{lineHeight: '1.2'}}>
                        <b className="d-block" style={{fontSize: '0.875rem'}}>
                          {item.reservationCategoryName}{item.bookingPropertyItemIdentifier && <span>&nbsp;&bull;&nbsp;{item.bookingPropertyItemIdentifier}</span>}
                        </b>
                      </div>
                    </li>
                    {(item.petFeeIncluded) &&
                      <li className="list-group-item | d-flex align-items-center">
                        <div className="text-center" style={{width: '2rem'}}>
                          <i className="fa fa-dog | text-primary" aria-hidden="true"></i>
                        </div>
                        <div className="flex-grow-1 pl-2" style={{lineHeight: '1.2'}}>
                          <b className="d-block" style={{fontSize: '0.875rem'}}>Pet fee include</b>
                        </div>
                      </li>
                    }
                  </ul>
                  {(item.extraOrdersItems && item.extraOrdersItems.length !== 0) &&
                    <>
                      <h6>Extra orders</h6>
                      <ul className="list-group | mb-2">
                        {item.extraOrdersItems.map((item, index) => (
                          <li key={index} className="list-group-item" style={{fontSize: '0.875rem'}}>
                            {item.name}<b>&nbsp;x&nbsp;{item.quantity}</b>
                          </li>
                        ))}
                      </ul>
                    </>
                  }
                  {typeof item.depositAmount !== 'undefined' ? (
                    <React.Fragment>
                      <div className="d-flex justify-content-between align-items-center">
                        <h6>Deposit paid</h6>
                        <b>${item.depositAmount}</b>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <h6>Remaining amount</h6>
                        <b>${item.totalPrice - item.depositAmount}</b>
                      </div>
                    </React.Fragment>
                  ) : (
                    !item.paid ? (
                      <div className="d-flex justify-content-between align-items-center">
                        <h6>Remaining amount</h6>
                        <b>${item.totalPrice}</b>
                      </div>
                    ) : null
                  )}
                  <div className="d-flex justify-content-between align-items-center | mb-2">
                    <h6>Total price</h6>
                    <b>${item.totalPrice}</b>
                  </div>

                  {item.status === 'upcoming' &&
                    <div className="row row-8">
                      <div className="col-12 col-sm-8">
                        <div className="form-group">
                          <label htmlFor="" className="d-block">Available booking items</label>
                          <select
                            className="form-control form-control-sm"
                            value={bookingItemId}
                            onChange={handleChange}
                          >
                            <option value="none">None</option>
                            {bookingItems.map((bookingItem, index) => (
                              <option key={index} value={bookingItem.id}>{bookingItem.identifier}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-12 col-sm-4">
                        <div className="form-group">
                          <label htmlFor="" className="d-none d-sm-block">&nbsp;</label>
                          <ButtonLoading
                            classes="btn btn-primary btn-block btn-sm"
                            type="button"
                            loading={status === 'Loading'}
                            onClick={handleAssignUnassingReservation}
                          >Assign</ButtonLoading>
                        </div>
                      </div>
                    </div>
                  }
                </>
              :
                <>
                  <h6>Blackout</h6>
                  <ul className="list-group | mb-2">
                    <li className="list-group-item | d-flex align-items-center">
                      <div className="text-center" style={{width: '2rem'}}>
                        <i className="fa fa-calendar-day | text-primary" aria-hidden="true"></i>
                      </div>
                      <div className="flex-grow-1 pl-2" style={{lineHeight: '1.2'}}>
                        <small className="text-muted">Start</small>
                        <b className="d-block" style={{fontSize: '0.875rem'}}>{parseUTCDate(item.start)}</b>
                      </div>
                    </li>
                    <li className="list-group-item | d-flex align-items-center">
                      <div className="text-center" style={{width: '2rem'}}>
                        <i className="fa fa-calendar-day | text-primary" aria-hidden="true"></i>
                      </div>
                      <div className="flex-grow-1 pl-2" style={{lineHeight: '1.2'}}>
                        <small className="text-muted">End</small>
                        <b className="d-block" style={{fontSize: '0.875rem'}}>{parseUTCDate(item.end)}</b>
                      </div>
                    </li>
                  </ul>
                </>
          }
        </Modal.Body>
        <Modal.Footer>
          {(item.status === 'upcoming' || item.status === 'active') && isShiftButtonVisible ? (
            <button
              className="btn btn-primary btn-sm | mr-2"
              type="button"
              onClick={toggleReservationProlongDialog}
            >Change end date</button>
          ) : null}
          {(item.status === 'upcoming' && isShiftButtonVisible) ? (
            <button
              className="btn btn-primary btn-sm"
              type="button"
              onClick={toggleReservationShiftDialog}
            >Shift</button>
          ) : null}
          <Link
            className="btn btn-primary btn-sm"
            to={`/admin/booking-properties/${bookingId}/reservations?statuses=${item.status}&reservationNumber=${item.reservationNumber}`}
          >Details</Link>
          <button
            className="btn btn-outline-secondary btn-sm"
            type="button"
            onClick={onHide}
          >Close</button>
        </Modal.Footer>
      </Modal>
      {showReservationShiftDialog ? (
        <ReservationShiftDialog
          reservation={reservation.item}
          show={showReservationShiftDialog}
          onHide={toggleReservationShiftDialog}
        />
      ) : null}
      {showReservationProlongDialog ? (
        <ReservationProlongDialog
          reservation={reservation.item}
          show={showReservationProlongDialog}
          onHide={toggleReservationProlongDialog}
        />
      ) : null}
    </>
  )
}

const mapStateToProps = state => ({
  booking: BookingSelectors.getBooking(state.BookingState),

  bookingItems: ReservationSelectors.getBookingItems(state.ReservationState),
  status: ReservationSelectors.getStatus(state.ReservationState)
});

const mapDispatchToProps = dispatch => ({
  fetchAvailableBookingItemsForAssigning: (
    includeAssignedBookingPropertyItems, reservationId, reservationCategoryId
  ) => dispatch(
    ReservationActions.fetchAvailableBookingItemsForAssigning(
      includeAssignedBookingPropertyItems, reservationId, reservationCategoryId
    )
  ),
  assignReservation: (reservationId, bookingItemIds) => dispatch(ReservationActions.assing(reservationId, bookingItemIds)),
  unassignReservation: (reservationId, bookingItemIds) => dispatch(ReservationActions.unassign(reservationId, bookingItemIds)),
  unassignAssignReservation: (
    reservationId, unassignBookingItemIds, assignBookingItemIds
  ) => dispatch(ReservationActions.unassignAssign(
    reservationId, unassignBookingItemIds, assignBookingItemIds
  )),
  clearAvailableBookingItems: () => dispatch(ReservationActions.clearAvailableBookingItems())
});

export default connect(mapStateToProps, mapDispatchToProps)(ReservationModal);
