import { call, put, takeLatest } from 'redux-saga/effects';
// Store
import * as LayoutActions from '../../actions/layout.actions';
import { RestrictionActionTypes, RestrictionMessageTypes } from './restriction.types';
import { RestrictionActions } from './restriction.actions';
import { RestrictionService } from './restriction.service';

function* _fetchList(action){
  try {
    const { bookingId } = action;
    const response = yield call(RestrictionService.fetchList, bookingId);
    yield put(RestrictionActions.fetchListSuccess(response));
  } catch(error){
    console.log(error);
    yield put(RestrictionActions.setStatusError());
  }
}

function* _create(action){
  try {
    const { data } = action;
    const response = yield call(RestrictionService.create, data);
    yield put(RestrictionActions.createSuccess(response));
    yield put(RestrictionActions.setStatusSuccess());
    yield put(RestrictionActions.setStatusInitial());
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: RestrictionMessageTypes.Create
    }));
  } catch(error){
    console.log(error);
    yield put(RestrictionActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _update(action){
  try {
    const { restrictionId, data } = action;
    const response = yield call(RestrictionService.update, restrictionId, data);
    yield put(RestrictionActions.updateSuccess(response));
    yield put(RestrictionActions.setStatusSuccess());
    yield put(RestrictionActions.setStatusInitial());
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: RestrictionMessageTypes.Update
    }));
  } catch(error){
    console.log(error);
    yield put(RestrictionActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _delete(action){
  try {
    const { restriction } = action;
    if ( window.confirm(`Are you sure you want to delete booking property reservation restriction?`) ){
      yield call(RestrictionService.delete, restriction.id);
      yield put(RestrictionActions.deleteSuccess(restriction.id));
      yield put(LayoutActions.addNotification({
        type: 'success',
        message: RestrictionMessageTypes.Delete
      }));
    }
  } catch(error){
    console.log(error);
    yield put(RestrictionActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

export default function* (){
  yield takeLatest(RestrictionActionTypes.FetchList, _fetchList);
  yield takeLatest(RestrictionActionTypes.Create, _create);
  yield takeLatest(RestrictionActionTypes.Update, _update);
  yield takeLatest(RestrictionActionTypes.Delete, _delete);
}