import React, { useState } from 'react';
// Rechart
import { ResponsiveContainer, PieChart, Pie, Legend, Tooltip, Cell } from 'recharts';
// Bootstrap
import Modal from 'react-bootstrap/Modal';

const FinancialReportDetails = ({
  type, item, show, onHide
}) => {
  const { name, gross, net, items } = item;
  const [data, setData] = useState(items);

  const handleLegendItemClick = entry => {
    const { name } = entry;
    setData(prevData => prevData.map(
      d => d.name === name ? { ...d, visible: !d.visible } : d
    ));
  }

  const CustomTooltipContent = ({ active, payload }) => {
    if ( active ){
      return (
        <small className="d-block badge-white p-2 border shadow-sm">
          {`${payload[0].name}: ${payload[0].value} ${ type === 'orders' ? 'item(s)' : 'day(s)' }`}
        </small>
      )
    }
    return null;
  }

  const CustomLegendContent = ({ payload }) => {
    return (
      <div className="row row-8">
        {payload.map((entry, index) => (
          <div
            key={`item-${index}`}
            className={
              `col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 | d-flex align-items-center
                ${ entry.visible ? '' : 'text-through' }
              `
            }
            onClick={() => handleLegendItemClick(entry)}
            style={{ fontSize: '.875rem', marginBottom: '1px', lineHeight: '1.2' }}
          >
            <span
              style={{
                display: 'flex',
                flexShrink: 0,
                backgroundColor: entry.color,
                borderRadius: '50%',
                width: '.875rem',
                height: '.875rem',
                marginRight: '.25rem'
              }}
            ></span>
            <span>{entry.name}</span>
          </div>
        ))}
      </div>
    )
  }

  return (
    <Modal show={show} onHide={onHide} size="xxl">
      <Modal.Header closeButton>
        <div>
          <h5 className="d-block mb-0">{name}</h5>
          <small className="mr-2"><b>Gross</b> ${toNumberWithCommas(gross)}</small>
          <small className="mr-2"><b>Net</b> ${toNumberWithCommas(net)}</small>
          <small><b>Tax</b> ${toNumberWithCommas(gross - net)}</small>
        </div>
      </Modal.Header>
      <Modal.Body>
        <ResponsiveContainer width="100%" height={892}>
          <PieChart width={480} height={480}>
            <Pie
              data={data.filter(d => d.visible)}
              dataKey="value"
              nameKey="name"
            >
              {data.map((entry, index) => (
                entry.visible
                  ? <Cell key={`cell-${index}`} fill={entry.color}/>
                  : null
              ))}
            </Pie>
            <Tooltip content={CustomTooltipContent} />
            <Legend
              verticalAlign="top"
              payload={data}
              iconType="circle"
              content={<CustomLegendContent />}
            />
          </PieChart>
        </ResponsiveContainer>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-outline-secondary btn-sm"
          type="button"
          onClick={onHide}
        >Close</button>
      </Modal.Footer>
    </Modal>
  )
}

const toNumberWithCommas = num => {
  return Number(num).toLocaleString()
}

export default FinancialReportDetails;
