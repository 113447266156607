import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
// Redux
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import AccountActions from 'store/account/account.actions';
import * as AccountSelectors from 'store/account/account.selectors';
import { IAccount, IAccountData } from 'store/account/account.types';
import Statuses from 'types/statuses';
// Bootstrap
import Modal from 'react-bootstrap/Modal';
// Components
import { Input } from 'components/Controls';
import { Phone } from 'components/Phone';
import { ButtonLoading } from 'app/components/Buttons';
// Utilities
import { isRequired, isEmail } from 'utilities/validation';

type IProps = {
  // Props
  account?:IAccount | null;
  show:boolean;
  onHide:() => void;
  // State
  settings:Array<string>;
  isLoading:boolean;
  status:Statuses;
  // Dispatch
  createAccount:(data:IAccountData) => void;
  updateAccount:(id:number, data:IAccountData) => void;
}

const AccountFormDialog:React.FC<IProps> = ({
  // Props
  account, show, onHide,
  // State
  settings, isLoading, status,
  // Dispatch
  createAccount, updateAccount
}) => {
  const settingObjects = 
    account && account.settings
    ? account.settings.reduce((acc:any, cur:{ name:string, value:string }) => {
      acc[cur.name] = cur.value;
      return acc;
    }, {}) : {}
  ;

  const { register, control, handleSubmit, errors, setValue } = useForm({
    defaultValues: {
      name: account?.name || '',
      email: account?.email || '',
      hiddenPhone: (account && account.phone && account.callingCode) ? `${account.callingCode}_${account.phone}` : '',
      phone: (account && account.phone && account.callingCode) ? `${account.callingCode}${account.phone}` : '',

      adminHiddenPhone: (account && account.adminPhone && account.adminCallingCode) ? `${account.adminCallingCode}_${account.adminPhone}` : '',
      adminPhone: (account && account.adminPhone && account.adminCallingCode) ? `${account.adminCallingCode}${account.adminPhone}` : '',
      adminEmail: account?.adminEmail || '',
      ...settingObjects
    }
  });

  const onSubmit = (data: any) => {
    const { name, email, adminEmail, hiddenPhone, adminHiddenPhone } = data;
    const settings:Array<{ name:string, value:string }> = [];
    Object.keys(data).forEach((key:string) => {
      if ( key !== 'name' && key !== 'email' && key !== 'phone' && key !== 'adminPhone'  && key !== 'adminEmail' && key !== 'hiddenPhone' && key !== 'adminHiddenPhone' ){
        if ( data[key] ) settings.push({ name: key, value: data[key] });
      }
    });
    const [ callingCode, phone ] = hiddenPhone.split('_');
    const newData:IAccountData = {
      name, email, callingCode, phone
    };
    if ( settings.length !== 0 ) newData['settings'] = settings;
    if ( account && account.id ){
      updateAccount(account.id, newData);
    } else {
      const [ adminCallingCode, adminPhone ] = adminHiddenPhone.split('_');
      newData['adminCallingCode'] = adminCallingCode;
      newData['adminPhone'] = adminPhone;
      newData['adminEmail'] = adminEmail;
      createAccount(newData);
    }
  }

  useEffect(() => {
    if ( status === 'Success' ) onHide();
    // eslint-disable-next-line
  }, [status]);

  return (
    <Modal show={show} onHide={onHide}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>{`${account?.id ? 'Update' : 'Create'}`} account</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {/* Name */}
          <Input
            controlRef={register({ required: isRequired })}
            label="Name" id="name" name="name"
            required={true}
            errorText={errors.name ? errors.name.message : null}
          />
          {/* E-mail */}
          <Input
            controlRef={register({ required: isRequired, pattern: isEmail })}
            label="E-mail" id="email" name="email"
            required={true}
            errorText={errors.email ? errors.email.message : null}
          />
          {/* Phone */}
          <input ref={register({ required: isRequired })} type="hidden" name="hiddenPhone" />
          <Controller
            control={control} name="phone"
            render={({ onChange, onBlur, value }) => (
              <Phone
                value={value}
                onChange={(newValue:string, newHiddenPhoneValue:string) => {
                  onChange(newValue);
                  setValue('hiddenPhone', newHiddenPhoneValue);
                }}
                onBlur={onBlur}
                errorText={errors.hiddenPhone ? errors.hiddenPhone.message : null}
              />
            )}
          />
          {!account || !account.id ? (
            <>
              {/* Admin E-mail */}
              <Input
                controlRef={register({ required: isRequired, pattern: isEmail })}
                label="Admin E-mail" id="adminEmail" name="adminEmail"
                required={true}
                errorText={errors.adminEmail ? errors.adminEmail.message : null}
              />
              {/* Phone */}
              <input ref={register({ required: isRequired })} type="hidden" name="adminHiddenPhone" />
              <Controller
                control={control} name="adminPhone"
                render={({ onChange, onBlur, value }) => (
                  <Phone
                    label="Admin Phone"
                    value={value}
                    onChange={(newValue:string, newHiddenPhoneValue:string) => {
                      onChange(newValue);
                      setValue('adminHiddenPhone', newHiddenPhoneValue);
                    }}
                    onBlur={onBlur}
                    errorText={errors.adminHiddenPhone ? errors.adminHiddenPhone.message : null}
                  />
                )}
              />
            </>
          ) : null}
          <hr />
          {/* Settings */}
          {settings && settings.map((setting, index) => (
            <Input
              key={`account-setting-form-${index}`}
              controlRef={register()} defaultValue=""
              label={setting} id={setting} name={setting}
            />
          ))}

        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary btn-sm"
            type="button"
            onClick={onHide}
          >Cancel</button>
          <ButtonLoading
            loading={isLoading}
            type="submit"
          >Save</ButtonLoading>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

AccountFormDialog.defaultProps = {
  account: null,
  show: false,
  onHide: () => null
};

const mapStateToProps = (state:any) => ({
  settings: AccountSelectors.getSettings(state),
  isLoading: AccountSelectors.getIsLoading(state),
  status: AccountSelectors.getStatus(state)
});

const mapDispatchToProps = (dispatch:Dispatch) => ({
  createAccount: (data:IAccountData) => dispatch(AccountActions.create(data)),
  updateAccount: (id:number, data:IAccountData) => dispatch(AccountActions.update(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountFormDialog);
