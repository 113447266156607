import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
import { AvailabilityActions } from '../../../store/availability/availability.actions';
import * as AvailabilitySelectors from '../../../store/availability/availability.selectors';
// Bootstrap
import Modal from 'react-bootstrap/Modal';
// Hooks
import useForm from '../../../hooks/useForm';

const pattern = /^\d{4}-\d{2}-\d{2}$/;

const validation = values => {
  const errors = {};
  if ( !values.type ){
    errors['type'] = 'This field is required';
  }
  if ( !values.start ){
    errors['start'] = 'This field is required';
  } else if ( !pattern.test(values.start) ){
    errors['start'] = 'Date is not valid';
  }
  if ( !values.end ){
    errors['end'] = 'This field is required';
  } else if ( !pattern.test(values.end) ){
    errors['end'] = 'Date is not valid';
  }
  if ( values.start && values.end ){
    if ( new Date(values.end).getTime() - new Date(values.start).getTime() < 0 ){
      errors['end'] = 'End date can`t be less than start date';
    }
  }
  return errors;
}

const AvailabilityFormModal = ({
  // Props
  show, onHide,
  // State
  restriction, status,
  // Dispatch
  createRestriction, updateRestriction
}) => {
  const { bookingId } = useParams();

  const callback = () => {
    const { type, start, end } = values;
    const data = { start, end };
    if ( restriction && restriction.id ){
      updateRestriction(restriction.id, data);
    } else {
      data['bookingPropertyId'] = parseInt(bookingId, 10);
      data['type'] = type;
      createRestriction(data);
    }
  }

  const [ values, errors, handleChange, handleSubmit ] = useForm(callback, {
    type: restriction.type || '',
    start: restriction.start || '',
    end: restriction.end || '',
  }, validation);

  useEffect(() => {
    if ( status === 'Success' ) onHide();
    // eslint-disable-next-line
  }, [status]);

  return (
    <Modal show={show} onHide={onHide}>
      <form onSubmit={handleSubmit}>
        <Modal.Header>
          {restriction.id ? `Update` : 'Create'} availability restriction
        </Modal.Header>
        <Modal.Body>

          {(!restriction || !restriction.id) &&
            <div className="form-group">
              <label htmlFor="start">Type&nbsp;*</label>
              <select
                className={`form-control form-control-sm ${ errors.type && 'is-invalid' }`}
                id="type"
                name="type"
                onChange={handleChange}
              >
                <option value="">Choose type</option>
                <option value="allow">Allow</option>
                <option value="deny">Deny</option>
              </select>
              {errors.type && <div className="invalid-feedback">{errors.type}</div>}
            </div>
          }
          <div className="row row-8">
            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label htmlFor="start">Start&nbsp;*</label>
                <input
                  className={`form-control form-control-sm ${ errors.start && 'is-invalid' }`}
                  id="start"
                  type="date"
                  name="start"
                  value={values.start}
                  onChange={handleChange}
                />
                {errors.start && <div className="invalid-feedback">{errors.start}</div>}
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label htmlFor="end">End&nbsp;*</label>
                <input
                  className={`form-control form-control-sm ${ errors.end && 'is-invalid' }`}
                  id="end"
                  type="date"
                  name="end"
                  value={values.end}
                  onChange={handleChange}
                />
                {errors.end && <div className="invalid-feedback">{errors.end}</div>}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-secondary btn-sm"
            type="button"
            onClick={onHide}
          >Cancel</button>
          <button
            className="btn btn-primary btn-sm"
            type="submit"
          >Save</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

const mapStateToProps = ({ AvailabilityState }, ownProps) => ({
  restriction: AvailabilitySelectors.getRestrictionById(AvailabilityState, ownProps.restrictionId),
  status: AvailabilitySelectors.getStatus(AvailabilityState)
});

const mapDispatchToProps = dispatch => ({
  createRestriction: data => dispatch(AvailabilityActions.create(data)),
  updateRestriction: (restrictionId, data) => dispatch(AvailabilityActions.update(restrictionId, data)),
});

AvailabilityFormModal.defaultProps = {
  restriction: null
};

export default connect(mapStateToProps, mapDispatchToProps)(AvailabilityFormModal);
