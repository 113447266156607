import { call, put, takeLatest } from 'redux-saga/effects';
import Statuses from 'types/statuses';
import * as LayoutActions from 'app/actions/layout.actions';
import InventoryGroupService from './inventory-group.service';
import InventoryGroupActions from './inventory-group.actions';
import MapPoiActions from 'store/map-poi/map-poi.actions';
import {
  InventoryGroupMessages,
  FETCH_LIST, FETCH_BY_ID, CREATE, UPDATE, DELETE, ENABLE, DISABLE,
  RESET_DEFAULT_WAIVER, SET_DEFAULT_WAIVER
} from './inventory-group.types'

export default function* (){
  yield takeLatest(FETCH_LIST, _fetchList);
  yield takeLatest(FETCH_BY_ID, _fetchById);
  yield takeLatest(CREATE, _create);
  yield takeLatest(UPDATE, _update);
  yield takeLatest(DELETE, _delete);
  yield takeLatest(ENABLE, _enable);
  yield takeLatest(DISABLE, _disable);
  yield takeLatest(RESET_DEFAULT_WAIVER, _resetDefaultWaiver);
  yield takeLatest(SET_DEFAULT_WAIVER, _setDefaultWaiver);
}

function* _fetchList(){
  try {
    const { parseBody } = yield call(InventoryGroupService.fetchList);
    yield put(InventoryGroupActions.fetchListSuccess(parseBody));
  } catch(error:any){
    yield put(InventoryGroupActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _fetchById(action:any){
  try {
    const { id } = action.payload;
    const { parseBody } = yield call(InventoryGroupService.fetchById, id);
    yield put(InventoryGroupActions.fetchByIdSuccess(parseBody));
    yield put(MapPoiActions.setList(parseBody.pois || []));
  } catch(error:any){
    yield put(InventoryGroupActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _create(action:any){
  try {
    const { data } = action.payload;
    const { parseBody } = yield call(InventoryGroupService.create, data);
    yield put(InventoryGroupActions.createSuccess(parseBody));
    yield put(InventoryGroupActions.setStatus(Statuses.Success));
    yield put(InventoryGroupActions.setStatus(Statuses.Initial));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: InventoryGroupMessages.Create
    }));
  } catch(error:any){
    yield put(InventoryGroupActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _update(action:any){
  try {
    const { id, data } = action.payload;
    const { parseBody } = yield call(InventoryGroupService.update, id, data);
    yield put(InventoryGroupActions.updateSuccess(parseBody));
    yield put(InventoryGroupActions.setStatus(Statuses.Success));
    yield put(InventoryGroupActions.setStatus(Statuses.Initial));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: InventoryGroupMessages.Update
    }));
  } catch(error:any){
    yield put(InventoryGroupActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _delete(action:any){
  try {
    const { id } = action.payload;
    yield call(InventoryGroupService.delete, id);
    yield put(InventoryGroupActions.deleteSuccess(id));
    yield put(InventoryGroupActions.setStatus(Statuses.Success));
    yield put(InventoryGroupActions.setStatus(Statuses.Initial));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: InventoryGroupMessages.Delete
    }));
  } catch(error:any){
    yield put(InventoryGroupActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _enable(action:any){
  try {
    const { id } = action.payload;
    yield call(InventoryGroupService.enable, id);
    yield put(InventoryGroupActions.enableSuccess(id));
  } catch(error:any){
    yield put(InventoryGroupActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _disable(action:any){
  try {
    const { id } = action.payload;
    yield call(InventoryGroupService.disable, id);
    yield put(InventoryGroupActions.disableSuccess(id));
  } catch(error:any){
    yield put(InventoryGroupActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _resetDefaultWaiver(action:any){
  try {
    const { id } = action.payload;
    const { parseBody } = yield call(InventoryGroupService.resetDefaultWaiver, id);
    yield put(InventoryGroupActions.resetDefaultWaiverSuccess(parseBody));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: InventoryGroupMessages.ResetDefaultWaiver
    }));
  } catch(error:any){
    yield put(InventoryGroupActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _setDefaultWaiver(action:any){
  try {
    const { waiverId } = action.payload;
    const { parseBody } = yield call(InventoryGroupService.setDefaultWaiver, waiverId);
    yield put(InventoryGroupActions.setDefailtWaiverSuccess(parseBody));
  } catch(error:any){
    yield put(InventoryGroupActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

