import { call, put, takeLatest } from 'redux-saga/effects';
import Statuses from 'types/statuses';
import * as LayoutActions from 'app/actions/layout.actions';
import MailTemplateService from './mail-template.service';
import MailTemplateActions from './mail-template.actions';
import {
  MailTemplateMessages,
  FETCH_LIST, FETCH_BY_ID, UPDATE
} from './mail-template.types'

function* _fetchList(){
  try {
    const { parseBody } = yield call(MailTemplateService.fetchList);
    yield put(MailTemplateActions.fetchListSuccess(parseBody));
  } catch(error:any){
    yield put(MailTemplateActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _fetchById(action:any){
  try {
    const { parseBody } = yield call(MailTemplateService.fetchById, action.payload.id);
    yield put(MailTemplateActions.fetchByIdSuccess(parseBody));
  } catch(error:any){
    yield put(MailTemplateActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _update(action:any){
  try {
    const { id, data } = action.payload;
    const { parseBody } = yield call(MailTemplateService.update, id, data);
    yield put(MailTemplateActions.updateSuccess(parseBody));
    yield put(MailTemplateActions.setStatus(Statuses.Success));
    yield put(MailTemplateActions.setStatus(Statuses.Initial));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: MailTemplateMessages.Update
    }));
  } catch(error:any){
    yield put(MailTemplateActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

export default function* (){
  yield takeLatest(FETCH_LIST, _fetchList);
  yield takeLatest(FETCH_BY_ID, _fetchById);
  yield takeLatest(UPDATE, _update);
}
