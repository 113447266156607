import React from 'react';
// Models
import { IWaiver } from 'models/Waiver';
import { IMembershipType } from 'models/MembershipType';
// Store
import { useDispatch, useSelector } from 'react-redux';
// Actions
import WaiverActions from 'store/waiver/waiver.actions';
import InventoryGroupActions from 'store/inventory-group/inventory-group.actions';
import MembershipTypeActions from 'store/membership-type/membership-type.actions';
import { BookingActions } from 'app/store/booking/booking.actions';
// Selectors
import { getWaivers } from 'store/waiver/waiver.selectors';
import { getBooking } from 'app/store/booking/booking.selectors';
import { getInventoryGroup } from 'store/inventory-group/inventory-group.selectors';
import { getMembershipType } from 'store/membership-type/membership-type.selectors';
// Components
import { Loader, Message } from 'components/Utility';
// 
import WaiversListItem from './WaiversListItem';

type Props = {
  params: any;
};

const WaiversList:React.FC<Props> = ({
  // Props
  params
}) => {
  // Dispatch
  const dispatch = useDispatch();
  // State
  const waivers:Array<IWaiver> | null = useSelector((state:any) => getWaivers(state));
  const bookingItem:any = useSelector((state:any) => getBooking(state.BookingState));
  const inventoryGroup:any = useSelector((state:any) => getInventoryGroup(state));
  const membershipType:IMembershipType|null = useSelector((state:any) => getMembershipType(state));

  const [ currentItem, setCurrentItem ] = React.useState<any | null>(null);

  React.useEffect(() => {
    dispatch(WaiverActions.fetchWaivers(params));
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if ( params.inventoryGroupId && inventoryGroup ) setCurrentItem(inventoryGroup);
    if ( params.membershipTypeId && membershipType ) setCurrentItem(membershipType);
    if ( params.bookingPropertyId && bookingItem ) setCurrentItem(bookingItem);
    // eslint-disable-next-line
  }, [inventoryGroup, membershipType, bookingItem]);

  const handleSetDefaultWaiver = (waiverId:number) => {
    if ( params.inventoryGroupId ){
      dispatch(InventoryGroupActions.setDefailtWaiver(waiverId));
    }
    if ( params.membershipTypeId ){
      dispatch(MembershipTypeActions.setDefaultWaiver(waiverId));
    }
    if ( params.bookingPropertyId ){
      dispatch(BookingActions.setDefaultWaiver(waiverId));
    }
  }
  const handleDeleteWaiver = (waiverId:number) => {
    dispatch(WaiverActions.deleteWaiver(waiverId));
  }

  if ( !waivers ) return <Loader />;
  if ( waivers.length === 0 ) return <Message text="No waiver(s)" />;
  return (
    <div className="container-sm pt-3 pb-3">
      <ul className="list-group">
        {waivers.map((waiver:IWaiver) => (
          <WaiversListItem
            key={`waiver-item-${waiver.id}`}
            waiver={waiver}
            waiverId={currentItem ? currentItem.waiverId : null}
            onSetDefault={handleSetDefaultWaiver}
            onDelete={handleDeleteWaiver}
          />
        ))}
      </ul>
    </div>
  )
}

export default WaiversList;
