import { RestrictionActionTypes } from './restriction.types';

export const RestrictionActions = {
  // Fetch list
  fetchList: bookingId => ({
    type: RestrictionActionTypes.FetchList,
    bookingId
  }),
  fetchListSuccess: restrictions => ({
    type: RestrictionActionTypes.FetchListSuccess,
    restrictions
  }),

  // Create
  create: data => ({
    type: RestrictionActionTypes.Create,
    data
  }),
  createSuccess: restriction => ({
    type: RestrictionActionTypes.CreateSuccess,
    restriction
  }),
  // Update
  update: (restrictionId, data) => ({
    type: RestrictionActionTypes.Update,
    restrictionId,
    data
  }),
  updateSuccess: restriction => ({
    type: RestrictionActionTypes.UpdateSuccess,
    restriction
  }),
  // Delete
  delete: restriction => ({
    type: RestrictionActionTypes.Delete,
    restriction
  }),
  deleteSuccess: restrictionId => ({
    type: RestrictionActionTypes.DeleteSuccess,
    restrictionId
  }),

  // Filter
  setFilter: (filterType, filterValue) => ({
    type: RestrictionActionTypes.Filter,
    filterType,
    filterValue
  }),
  clearFilter: () => ({
    type: RestrictionActionTypes.ClearFilter
  }),

  // Set status
  setStatusSuccess: () => ({
    type: RestrictionActionTypes.StatusSuccess
  }),
  setStatusError: () => ({
    type: RestrictionActionTypes.StatusError
  }),
  setStatusInitial: () => ({
    type: RestrictionActionTypes.StatusInitial
  })
}