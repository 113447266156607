import React, { Component } from 'react';
// Store
import { connect } from 'react-redux';
import NotificationActions from 'app/actions/notification.actions';
// Components
import { DataLoading } from 'app/components/Loadings';
import { ButtonLoading } from 'app/components/Buttons';
// Utils
import { parseUTCDateTime } from 'app/utilities/datetime.convert';
// 
import NotificationDetails from './NotificationDetails';

class Notifications extends Component {
  state = {
    selectedNotification: {},
    isDetailsShown: false,
  }

  componentDidMount(){
    const { params } = this.props;
    this.props.fetchAll(params);
  }

  handleMore = () => {
    const { params } = this.props;
    const { limit, offset } = params;
    this.props.fetchAll({ ...params, offset: offset + limit }, true);
  }

  handleDetailsShow = notification => {
    this.setState({ selectedNotification: notification, isDetailsShown: true });
  }

  handleDetailsHide = () => {
    this.setState({ selectedNotification: {}, isDetailsShown: false });
  }

  render() {
    const { selectedNotification, isDetailsShown } = this.state;
    const { notifications, isFetching, hasMore, hasMoreLoading } = this.props;
    if ( isFetching ) return <DataLoading />;
    return (
      <div className="area area-notifications">
        <div className="container-fluid">
          <div className="area-container">

            <ul className="list-group">
              {notifications.map((notification, index) => (
                <li key={index} className="list-group-item | d-flex justify-content-between align-items-center">
                  <div>
                    {(notification.sender && notification.sender.name)
                      ? <h5 className="mb-0">{notification.sender.name}</h5>
                      : <h5 className="mb-0">System</h5>
                    }
                    <small className="text-muted">{parseUTCDateTime(notification.createdOn)}</small>
                    {notification.email && <h6 className="mb-0">{notification.email.subject}</h6>}
                    {(!notification.email && notification.sms) && <h6 className="mb-0">{notification.sms.text}</h6>}
                    {(!notification.email && !notification.sms && notification.inApp) && <h6 className="mb-0">{notification.inApp.text}</h6>}
                  </div>
                  <div>
                    <button
                      className="btn btn-info btn-sm btn-block"
                      type="button"
                      onClick={() => this.handleDetailsShow(notification)}
                    >Details</button>
                  </div>
                </li>
              ))}
            </ul>
            {/* Has more */}
            {hasMore &&
              <div className="pt-3 text-center">
                <ButtonLoading
                  loading={hasMoreLoading}
                  onClick={() => this.handleMore()}
                >Load more</ButtonLoading>
              </div>
            }
            {/* Details */}
            {isDetailsShown &&
              <NotificationDetails notification={selectedNotification} onHide={this.handleDetailsHide} />
            }
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  ({ NotificationState }) => ({
    notifications: NotificationState.notifications,
    params: NotificationState.params,
    isFetching: NotificationState.isFetching,
    hasMore: NotificationState.hasMore,
    hasMoreLoading: NotificationState.hasMoreLoading
  }),
  dispatch => ({
    fetchAll: (params, isMore) => dispatch(NotificationActions.fetchAll(params, isMore))
  })
)(Notifications);
