import React from 'react';
// React bootstrap
import { Modal } from 'react-bootstrap';
// 
import ReservationTransactionsForm from './ReservationTransactionsForm';
import ReservationTransactionsList from './ReservationTransactionsList';

type Props = {
  // Props
  reservation: any;
  email: string;
  show: boolean;
  onHide: () => void;
};

const ReservationTransactionsDialog:React.FC<Props> = ({
  // Props
  reservation, email, show, onHide
}) => {
  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>Transactions</Modal.Header>
      <Modal.Body>
        <ReservationTransactionsForm reservation={reservation} />
        <ReservationTransactionsList reservationId={reservation.id} email={email} />
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-outline-secondary btn-sm"
          type="button"
          onClick={onHide}
        >Close</button>
      </Modal.Footer>
    </Modal>
  )
}

export default ReservationTransactionsDialog;
