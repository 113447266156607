import React, { Component } from 'react';
// Store
import { connect } from 'react-redux';
import AssetActions from '../../actions/asset.actions';
// Component
import { ButtonLoading } from '../Buttons';

class AssetsUploader extends Component {
  constructor(props){
    super(props);

    const { image = {} } = props;
    this.state = {
      image,
      imageToPreview: image && image.href ? image.href : ''
    }
  }

  componentWillUnmount(){
    this.props.setInitialState();
  }

  componentDidUpdate(prevProps){
    if ( prevProps.isLoading !== this.props.isLoading && this.props.isSuccess ){
      this.props.onUpdate(this.props.asset);
    }
  }

  handleChange = evt => {
    const image = evt.target.files[0];
    const imageToPreview = URL.createObjectURL(image);
    this.setState({
      image,
      imageToPreview
    }, () => {
      this.props.createAsset(image);
    });
  }

  handleRemove = () => {
    this.setState({
      image: {},
      imageToPreview: ''
    }, () => {
      const { image } = this.state;
      this.props.onUpdate(image);
    });
  }

  render() {
    const { imageToPreview } = this.state;
    const { isLoading } = this.props;
    return (
      <div className="image-upload">
        <div className="image-upload-preview">
          {imageToPreview
            ? <img src={imageToPreview} alt="" />
            : <div className="_no-image">
                <i className="fa fa-image" aria-hidden="true"></i>
                <h5>No image</h5>
              </div>
          }
          {isLoading &&
            <span className="image-upload-placeholder">
              <i className="fa fa-spinner fa-pulse | mr-2"></i>
            </span>
          }
        </div>
        <div className="form-group form-group-image-upload">
          <input type="file" id="image" accept="image/*" onChange={this.handleChange} disabled={isLoading} />
          <label
            htmlFor="image"
            className={`btn btn-primary btn-block btn-sm ${ isLoading && 'disabled' } `}
          >
            {imageToPreview
              ? 'Update image'
              : 'Choose image'
            }
          </label>
        </div>
        {(imageToPreview) &&
          <ButtonLoading
            loading={false}
            disabled={isLoading}
            classes="btn btn-outline-danger btn-block btn-sm"
            onClick={this.handleRemove}
          >Remove image</ButtonLoading>
        }
      </div>
    )
  }
}

export default connect(
  ({ AssetState }) => ({
    asset: AssetState.asset,
    isLoading: AssetState.isLoading,
    isSuccess: AssetState.isSuccess,
  }),
  dispatch => ({
    createAsset: file => dispatch(AssetActions.createAsset(file)),
    setInitialState: () => dispatch(AssetActions.setInitialState())
  })
)(AssetsUploader);
