import { takeLatest, call, put } from 'redux-saga/effects';
// 
import * as LayoutActions from 'app/actions/layout.actions';
import { WaiverActionTypes, WaiverMessageTypes } from './waiver.types';
import { WaiverService } from './waiver.service';
import { WaiverActions } from './waiver.actions';

function* _fetchList(action){
  try {
    const { bookingId } = action;
    const response = yield call(WaiverService.fetchList, bookingId);
    yield put(WaiverActions.fetchListSuccess(response));
  } catch(error){
    console.error(error);
    yield put(WaiverActions.setStatusError());
  }
}

function* _fetchById(action){
  try {
    const { waiverId } = action;
    const response = yield call(WaiverService.fetchById, waiverId);
    yield put(WaiverActions.fetchByIdSuccess(response));
  } catch(error){
    console.error(error);
    yield put(WaiverActions.setStatusError());
  }
}

function* _create(action){
  try {
    const { data } = action;
    const response = yield call(WaiverService.create, data);
    yield put(WaiverActions.createSuccess(response));
    yield put(WaiverActions.setStatusSuccess());
    yield put(WaiverActions.setStatusInitial());
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: WaiverMessageTypes.Create
    }));
  } catch(error){
    console.error(error);
    yield put(WaiverActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _update(action){
  try {
    const { waiverId, data } = action;
    const response = yield call(WaiverService.update, waiverId, data);
    yield put(WaiverActions.updateSuccess(response));
    yield put(WaiverActions.setStatusSuccess());
    yield put(WaiverActions.setStatusInitial());
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: WaiverMessageTypes.Update
    }));
  } catch(error){
    console.error(error);
    yield put(WaiverActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _delete(action){
  try {
    const { waiver } = action;
    if ( window.confirm(`Are you sure you want to delete waiver: ${waiver.name}?`) ){
      yield call(WaiverService.delete, waiver.id);
      yield put(WaiverActions.deleteSuccess(waiver.id));
      yield put(LayoutActions.addNotification({
        type: 'success',
        message: WaiverMessageTypes.Delete
      }));
    }
  } catch(error){
    console.error(error);
    yield put(WaiverActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

export default function* (){
  yield takeLatest(WaiverActionTypes.FetchList, _fetchList);
  yield takeLatest(WaiverActionTypes.FetchById, _fetchById);
  yield takeLatest(WaiverActionTypes.Create, _create);
  yield takeLatest(WaiverActionTypes.Update, _update);
  yield takeLatest(WaiverActionTypes.Delete, _delete);
}

