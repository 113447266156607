import HttpUtility from 'utilities/http.utility';
import { IMapPoiData } from './map-poi.types';

export default class MapPoiService {
  private static _url:string = '/admin/mapPois';
  private static _http:HttpUtility = new HttpUtility();

  // Create
  public static async create(data:IMapPoiData):Promise<any> {
    return MapPoiService._http.post(MapPoiService._url, data);
  }
  // Update
  public static async update(id:number, data:IMapPoiData):Promise<any> {
    return MapPoiService._http.put(`${MapPoiService._url}/${id}`, data);
  }
  // Delete
  public static async delete(id:number):Promise<any> {
    return MapPoiService._http.delete(`${MapPoiService._url}/${id}`);
  }
}
