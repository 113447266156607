import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
// Store
import { connect } from 'react-redux';
import UserActions from 'app/actions/user.actions';
// Bootstrap
import Modal from 'react-bootstrap/Modal';
// Component
import { Input, Checkbox } from 'components/Controls';
import { Phone } from 'components/Phone';
import { ButtonLoading } from 'app/components/Buttons';
// 
import { isRequired, isEmail } from 'utilities/validation';

const CamperFormDialog = ({
  // Props
  camper, show, onHide,
  // State
  isFormLoading, isFormSuccess,
  // Dispatch
  createCamper, updateCamper
}) => {
  const { register, control, handleSubmit, errors, setValue, watch } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: camper.email || '',
      hiddenPhone: (camper && camper.phone && camper.callingCode) ? `${camper.callingCode}_${camper.phone}` : '',
      phone: (camper && camper.phone && camper.callingCode) ? `${camper.callingCode}${camper.phone}` : '',
      address: camper.address || '',
      notificationPreferencesEmail: camper.notificationPreferences ? camper.notificationPreferences.email : false,
      notificationPreferencesSms: camper.notificationPreferences ? camper.notificationPreferences.sms : false,
      // ToDO
      receiveMessages: false,
      sendWelcomeUserEmail: false
    }
  })

  const onSubmit = data => {
    const { firstName, lastName, email, hiddenPhone, address, notificationPreferencesEmail, notificationPreferencesSms, receiveMessages, sendWelcomeUserEmail } = data;
    const [ callingCode, phone ] = hiddenPhone.split('_');
    const newData = { email, callingCode, phone }
    if ( address ) newData['address'] = address;
    if ( camper && camper.id ){
      newData['id'] = camper.id
      newData['notificationPreferences'] = {
        email: notificationPreferencesEmail,
        sms: notificationPreferencesSms
      };
      updateCamper(newData);
    } else {
      newData['firstName'] = firstName;
      newData['lastName'] = lastName;
      newData['receiveMessages'] = receiveMessages;
      newData['sendWelcomeUserEmail'] = sendWelcomeUserEmail;
      createCamper(newData);
    }
  }

  useEffect(() => {
    if ( isFormSuccess ) onHide();
    // eslint-disable-next-line
  }, [isFormSuccess]);

  const watchAddress = watch('address');

  return (
    <Modal show={show} onHide={onHide}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>{camper && camper.id ? 'Update' : 'Create'} user</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {!camper || !camper.id ? (
            <div className="row row-8">
              <div className="col-6">
                <Input
                  controlRef={register({ required: isRequired })}
                  label="First Name" id="firstName" name="firstName"
                  required={true}
                  errorText={errors.firstName ? errors.firstName.message : null}
                />
              </div>
              <div className="col-6">
                <Input
                  controlRef={register({ required: isRequired })}
                  label="Last Name" id="lastName" name="lastName"
                  required={true}
                  errorText={errors.lastName ? errors.lastName.message : null}
                />
              </div>
            </div>
          ) : null}

          {/* E-mail */}
          <Input
            controlRef={register({ required: isRequired, pattern: isEmail })}
            label="E-mail" id="email" name="email"
            required={true}
            errorText={errors.email ? errors.email.message : null}
          />
          {/* Phone */}
          <input ref={register({ required: isRequired })} type="hidden" name="hiddenPhone" />
          <Controller
            control={control} name="phone"
            render={({ onChange, onBlur, value }) => (
              <Phone
                value={value}
                onChange={(newValue, newHiddenPhoneValue) => {
                  onChange(newValue);
                  setValue('hiddenPhone', newHiddenPhoneValue);
                }}
                onBlur={onBlur}
                errorText={errors.hiddenPhone ? errors.hiddenPhone.message : null}
              />
            )}
          />
          {/* Address */}
          <hr />
          <Input
            controlRef={register()}
            label="Address" id="address" name="address"
            placeholder="Full address, City, State, Zip, Country"
            maxLength={150}
          />
          <small id="emailHelp" className="form-text text-muted text-right">{watchAddress.length} / 150</small>

          {/* Notification preferences */}
          <hr />

          {!camper || !camper.id ? (
            <React.Fragment>
              <Controller
                control={control}
                name="receiveMessages"
                render={({ onChange, onBlur, value }) => (
                  <Checkbox
                    label="Receive Messages"
                    id="receiveMessages" name="receiveMessages"
                    checked={value}
                    onChange={e => onChange(e.target.checked)}
                  />
                )}
              />
              <Controller
                control={control}
                name="sendWelcomeUserEmail"
                render={({ onChange, onBlur, value }) => (
                  <Checkbox
                    label="Send welcome user e-mail"
                    id="sendWelcomeUserEmail" name="sendWelcomeUserEmail"
                    checked={value}
                    onChange={e => onChange(e.target.checked)}
                  />
                )}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              {/* Email */}
              <Controller
                control={control}
                name="notificationPreferencesEmail"
                render={({ onChange, onBlur, value }) => (
                  <Checkbox
                    label="Enable e-mail notification"
                    id="notificationPreferencesEmail" name="notificationPreferencesEmail"
                    checked={value}
                    onChange={e => onChange(e.target.checked)}
                  />
                )}
              />
              {/* SMS */}
              <Controller
                control={control}
                name="notificationPreferencesSms"
                render={({ onChange, onBlur, value }) => (
                  <Checkbox
                    label="Enable sms notification"
                    id="notificationPreferencesSms" name="notificationPreferencesSms"
                    checked={value}
                    onChange={e => onChange(e.target.checked)}
                  />
                )}
              />
            </React.Fragment>
          )}

        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary btn-sm"
            type="button"
            onClick={onHide}
          >Cancel</button>
          <ButtonLoading
            loading={isFormLoading}
            type="submit"
          >Save</ButtonLoading>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

CamperFormDialog.defaultProps = {
  camper: {},
  show: false,
  onHide: () => null
}

const mapStateToProps = state => ({
  // user: state.UserState.user,
  isFormLoading: state.UserState.isFormLoading,
  isFormSuccess: state.UserState.isFormSuccess
});

const mapDispatchToProps = dispatch => ({
  fetchById: userId => dispatch(UserActions.fetchById(userId)),
  createCamper: data => dispatch(UserActions.createCamper(data)),
  updateCamper: camper => dispatch(UserActions.updateCamper(camper))
});

export default connect(mapStateToProps, mapDispatchToProps)(CamperFormDialog);
