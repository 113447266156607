import React from 'react';
import { useForm } from 'react-hook-form';
// Types
import { Dispatch } from 'redux';
import Statuses from 'types/statuses';
import { ISeason, ISeasonData, ISlotData } from 'store/season/season.types';
// Store
import { connect } from 'react-redux';
// Actions
import SeasonActions from 'store/season/season.actions';
// Selectors
import { getSeasons, getIsLoading, getStatus, getSeasonById, getSeasonsSlotsForCalendar } from 'store/season/season.selectors';
// Service
import { validateSlots } from 'store/season/season.service';
// Bootstrap
import Modal from 'react-bootstrap/Modal';
// Components
import { Input } from 'components/Controls';
import { ButtonLoading } from 'app/components/Buttons';
// Dialogs
import ConfirmationDialog from 'dialogs/Confirmation.dialog';
// Utilities
import { isRequired } from 'utilities/validation';
import { uuidv4 } from 'utilities/other.utility';
// 
import SeasonsSlotAdd from './SeasonSlotsAdd';
import SeasonSlotsList from './SeasonSlotsList';

type Props = {
  // Props
  seasonId?: number;
  show: boolean;
  onHide: () => void;
  // State
  seasons: Array<ISeason>;
  isLoading: boolean;
  status: Statuses;
  season: ISeason | null;
  seasonsSlots: any;
  // Dispatch
  createSeason: (data:ISeasonData) => void;
  updateSeason: (seasonId:number, data:ISeasonData) => void;
  updateSlots: (slots:Array<any>, seasonId?:number, data?:ISeasonData) => void;
};

const initialSlot:ISlotData = { uuid: uuidv4(), startMonth: '', startDay: '', endMonth: '', endDay: '' };

const SeasonFormDialog:React.FC<Props> = ({
  // Props
  seasonId, show, onHide,
  // State
  seasons, isLoading, status, season, seasonsSlots,
  // Dispatch
  createSeason, updateSeason, updateSlots
}) => {
  const [ seasonData, setSeasonData ] = React.useState<any>({});
  const [ slots, setSlots ] = React.useState<Array<ISlotData>>([]);
  const [ dialogOpen, setDialogOpen ] = React.useState<boolean>();

  const { register, handleSubmit, errors, setError, clearErrors } = useForm<any>({
    defaultValues: {
      intercectedSlots: '',
      name: season?.name || ''
    }
  });

  const onSubmit = handleSubmit((data:any) => {
    clearErrors('intercectedSlots')

    const dataLength = Object.keys(data).length;
    const slotsLength = (dataLength - 2)/4;
    const slots = [];

    const newData:any = { name: data.name };

    let hasIntercectedSlots:boolean = false;
    let seasonsToUpdate:Array<ISeason> = [];

    if ( slotsLength ){
      for ( let i = 0; i < slotsLength; i++ ){
        const startMonth = convertToBENumber(data[`startMonth-${i}`]);
        const startDay = convertToBENumber(data[`startDay-${i}`]);
        const endMonth = convertToBENumber(data[`endMonth-${i}`]);
        const endDay = convertToBENumber(data[`endDay-${i}`]);
        slots.push({
          start: `${startMonth}-${startDay}`,
          end: `${endMonth}-${endDay}`
        });
      }
      const validate = validateSlots(seasons, seasonsSlots, slots);
      hasIntercectedSlots = validate.hasIntercectedSlots;
      seasonsToUpdate = validate.seasonsToUpdate;
    }

    if ( hasIntercectedSlots ){
      setError('intercectedSlots', {
        type: 'manual',
        message: 'Has intercected slots',
      });
      return;
    }
    if ( slots && slots.length > 0 ) newData['slots'] = slots;
    if ( seasonsToUpdate.length > 0 ){
      setDialogOpen(true);
      setSeasonData({
        slots: seasonsToUpdate.map((season:ISeason) => ({
          reservationSeasonId: season.id,
          slots: season.slots
        })),
        data: newData
      });
    } else {
      if ( seasonId ){
        updateSeason(seasonId, newData);
      } else {
        createSeason(newData);
      }
    }
  });

  const handleConfirm = () => {
    if ( seasonData && seasonData.slots && seasonData.data ){
      setDialogOpen(false);
      updateSlots(seasonData.slots, seasonId, seasonData.data);
    }
  }

  React.useEffect(() => {
    if ( seasonId ){
      if ( season?.slots && season.slots.length > 0 ){
        const newSlots:Array<ISlotData> = [];
        for ( let i = 0; i < season.slots.length; i++ ){
          const { start, end } = season.slots[i];
          const [ startMonth, startDay ] = start.split('-');
          const [ endMonth, endDay ] = end.split('-');
          newSlots.push({
            uuid: uuidv4(),
            startMonth: `${Number(startMonth).toString()}`,
            startDay: `${Number(startDay).toString()}`,
            endMonth: `${Number(endMonth).toString()}`,
            endDay: `${Number(endDay).toString()}`,
          })
        }
        setSlots(newSlots);
      }
    }
    // eslint-disable-next-line
  }, [seasonId])

  React.useEffect(() => {
    if ( status === Statuses.Success ) onHide();
    // eslint-disable-next-line
  }, [status]);

  const handleAddSlot = () => {
    setSlots((prevState:Array<ISlotData>) => [...prevState, {...initialSlot, uuid: uuidv4()}]);
  }

  const handleDeleteSlot = (uuid:string) => {
    setSlots((prevState:Array<ISlotData>) => prevState.filter((slot:ISlotData) => slot.uuid !== uuid));
  }

  return (
    <React.Fragment>
      <Modal show={show} onHide={onHide}>
        <form onSubmit={onSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>{seasonId ? 'Update' : 'Create'} season</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Input
              controlRef={register({ required: isRequired })}
              label="Name" id="name" name="name"
              required={true}
              errorText={errors.name ? errors.name.message : null}
            />

            <hr />

            <SeasonsSlotAdd
              onAddSlot={handleAddSlot}
            />

            <div className="form-group">
              <input ref={register} className="form-control is-invalid" type="hidden" name="intercectedSlots" />
              {errors.intercectedSlots ? (
                <div className="invalid-feedback">{errors.intercectedSlots.message}</div>
              ) : null}
            </div>

            <SeasonSlotsList
              register={register}
              errors={errors}
              items={slots}
              onDeleteSlot={handleDeleteSlot}
            />

          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary btn-sm"
              type="button"
              onClick={onHide}
            >Cancel</button>
            <ButtonLoading
              loading={isLoading}
              type="submit"
            >{seasonId ? 'Update' : 'Create'}</ButtonLoading>
          </Modal.Footer>
        </form>
      </Modal>
      {dialogOpen ? (
        <ConfirmationDialog
          show={dialogOpen}
          onHide={() => setDialogOpen(false)}
          onConfirm={handleConfirm}
          title="Intercected slots"
          content="Slots intersect with another season. Proceed anyway?"
        />
      ) : null}
    </React.Fragment>
  )
}

const mapStateToProps = (state:any, props:any) => ({
  seasons: getSeasons(state),
  isLoading: getIsLoading(state),
  status: getStatus(state),
  season: getSeasonById(state, { seasonId: props.seasonId || null }),
  seasonsSlots: getSeasonsSlotsForCalendar(state)
});

const mapDispatchToProps = (dispatch:Dispatch) => ({
  createSeason: (data:ISeasonData) => dispatch(SeasonActions.createSeason(data)),
  updateSeason: (seasonId:number, data:ISeasonData) => dispatch(SeasonActions.updateSeason(seasonId, data)),
  updateSlots: (slots:any, seasonId?:number, data?:ISeasonData) => dispatch(SeasonActions.updateSlots(slots, seasonId, data))
});

const convertToBENumber = (value:string) => {
  const valueToNumber:number = Number(value);
  return ('0' + valueToNumber).slice(-2);
};

export default connect(mapStateToProps, mapDispatchToProps)(SeasonFormDialog);
