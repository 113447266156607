const TOKEN = 'aicamping:token';
const TOKEN_TYPE = 'aicamping:token_type';
const EXPIRES_IN = 'aicamping:expires_in';
const DEVICE_TOKEN = 'aicamping:device_token';

export const getToken = () => {
  return localStorage.getItem(TOKEN) || '';
}
export const getTokenType = () => {
  return localStorage.getItem(TOKEN_TYPE) || '';
}
export const getTokenExpiresIn = () => {
  const expiresIn = localStorage.getItem(EXPIRES_IN) || '';
  return parseInt(expiresIn, 10);
}
export const getDeviceToken = () => localStorage.getItem(DEVICE_TOKEN) || '';

export const setStorageCredential = credential => {
  const { accessToken, tokenType, expiresIn } = credential;
  const date = new Date();
  localStorage.setItem(TOKEN, accessToken);
  localStorage.setItem(TOKEN_TYPE, tokenType);
  localStorage.setItem(EXPIRES_IN, date.setSeconds(date.getSeconds() + expiresIn).toString());
}
export const setDeviceToken = token => {
  localStorage.setItem(DEVICE_TOKEN, token);
}

export const clearStorageCredential = () => {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(TOKEN_TYPE);
  localStorage.removeItem(EXPIRES_IN);
  localStorage.removeItem(DEVICE_TOKEN);
}

export const checkIsExpires = () => {
  const currentTime = new Date().getTime();
  const expiresTime = getTokenExpiresIn();
  return currentTime > expiresTime ? true : false;
}
