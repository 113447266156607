import React from 'react';
// Store
import { useSelector } from 'react-redux';
// Selectors
import { getTransactions } from 'store/membership/membership.selectors';
// React bootstrap
import { IMembershipTransaction } from 'models/Membership';
// Components
import { Message, Loader } from 'components/Utility';
// 
import TransactionsListItem from './TransactionsListItem';

type Props = {
  membershipId: number;
};

const TransactionsDialog:React.FC<Props> = ({
  // Props
  membershipId
}) => {
  // State
  const transactions:Array<IMembershipTransaction> | null = useSelector((state:any) => getTransactions(state));

  if ( !transactions ) return <Loader />;
  if ( transactions.length === 0 ) return <Message text="No transaction(s)" />;
  return (
    <table className="table">
      <thead>
        <tr>
          <th>Type</th>
          <th>Status</th>
          <th>Amount</th>
          <th>Notes</th>
          <th>Created On</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {transactions.map((transaction:IMembershipTransaction) => (
          <TransactionsListItem
            key={`transaction-item-${transaction.id}`}
            membershipId={membershipId}
            transaction={transaction}
          />
        ))}
      </tbody>
    </table>
  )
}

export default TransactionsDialog;
