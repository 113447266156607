import React from 'react';
// Types
import Statuses from 'types/statuses';
import { ISeason } from 'store/season/season.types';
// Dialogs
import ConfirmationDialog from 'dialogs/Confirmation.dialog';
import SeasonFormDialog from 'dialogs/SeasonFormDialog';

type Props = {
  // Props
  item: ISeason;
  loading: boolean;
  status: Statuses;
  onDelete: (seasonId:number) => void;
}

const SeasonsListItem:React.FC<Props> = ({
  // Props
  item, loading, status, onDelete
}) => {
  const [ dialogEditOpen, setDialogEditOpen ] = React.useState<boolean>(false);
  const [ dialogConfirmOpen, setDialogConfirmOpen ] = React.useState<boolean>(false);

  const handleDialogEditOpen = () => setDialogEditOpen(true);
  const handleDialogEditClose = () => setDialogEditOpen(false);

  const handleDialogConfirmOpen = () => setDialogConfirmOpen(true);
  const handleDialogConfirmClose = () => setDialogConfirmOpen(false);

  return (
    <React.Fragment>
      <div className="d-flex align-items-center shadow-sm py-2 px-4 mr-3 mb-3 bg-white rounded-pill">
        <span className="p-2 rounded-circle" style={{ backgroundColor: item.color }}></span>
        <span className="font-weight-bold ml-2 mr-2">{item.name}</span>
        <button
          className="close text-primary p-2 border border-primary rounded ml-2" aria-label="Edit" style={{ fontSize: '12px' }}
          type="button"
          onClick={handleDialogEditOpen}
        ><i className="fa fa-pen" aria-hidden="true"></i></button>
        <button
          className="close text-danger p-2 border border-danger rounded ml-2" aria-label="Delete" style={{ fontSize: '12px' }}
          type="button"
          onClick={handleDialogConfirmOpen}
        ><i className="fa fa-trash" aria-hidden="true"></i></button>
      </div>
      {dialogEditOpen ? (
        <SeasonFormDialog
          seasonId={item.id}
          show={dialogEditOpen}
          onHide={handleDialogEditClose}
        />
      ) : null}
      {dialogConfirmOpen ? (
        <ConfirmationDialog
          show={dialogConfirmOpen}
          onHide={handleDialogConfirmClose}
          onConfirm={() => onDelete(item.id)}
          loading={loading}
          status={status}
          title="Season delete"
          content={`Are you sure you want to delete season: ${item.name} ?`}
        />
      ) : null}
    </React.Fragment>
  )
}

export default SeasonsListItem;
