export const WaiverActionTypes = {
  FetchList: '[Waiver] Fetch list',
  FetchListSuccess: '[Waiver] Fetch list success',

  FetchById: '[Waiver] Fetch by id',
  FetchByIdSuccess: '[Waiver] Fetch by id success',
  ClearWaiver: '[Waiver] Clear waiver',

  Create: '[Waiver] Create',
  CreateSuccess: '[Waiver] Create success',

  Update: '[Waiver] Update',
  UpdateSuccess: '[Waiver] Update success',

  Delete: '[Waiver] Delete',
  DeleteSuccess: '[Waiver] Delete success',

  StatusSuccess: '[Waiver] Status success',
  StatusError: '[Waiver] Status error',
  StatusInitial: '[Waiver] Status initial'
};

export const WaiverMessageTypes = {
  Create: 'Waiver was created',
  Update: 'Waiver was updated',
  Delete: 'Waiver was deleted'
}
