import React from 'react';
// Models
import { ITax } from 'models/Tax';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Actions
import TaxesActions from 'store/taxes/taxes.actions';
// Selectors
import { selectAllTaxes } from 'store/taxes/taxes.selectors';
// Components
import { Loader, Message } from 'components/Utility';
// 
import TaxesListItem from './TaxesListItem';

type Props = {
  bookingPropertyId?: number;
  inventoryGroupId?: number;
  membershipTypeId?: number;
};

const TaxesList:React.FC<Props> = (props) => {
  // Dispatch
  const dispatch = useDispatch();
  // State
  const taxes:ITax[] | null = useSelector(selectAllTaxes);

  React.useEffect(() => {
    dispatch(TaxesActions.fetchTaxes(props));
    // eslint-disable-next-line
  }, []);

  if ( !taxes ) return <Loader />;
  if ( taxes.length === 0 ) return <Message text="No tax(es)" />;
  return (
    <ul className="list-group mb-2">
      {taxes.map((tax:ITax) => (
        <TaxesListItem key={`tax-item-${tax.id}`} tax={tax} />
      ))}
    </ul>
  );
}

export default TaxesList;
