// Types
import Statuses from 'types/statuses';
import { SettingTypes, SettingActionTypes } from './setting.types';

export default class SettingActions {
  // Fetch square config
  public static fetchAccountSettings = ():SettingActionTypes => ({
    type: SettingTypes.FetchAccountSettings
  });
  public static fetchAccountSettingsSuccess = (settings:any):SettingActionTypes => ({
    type: SettingTypes.FetchAccountSettingsSuccess, payload: { settings }
  });
  // Default
  // Set status
  public static setStatus = (status:Statuses):SettingActionTypes => ({
    type: SettingTypes.SetStatus, payload: { status }
  });
}
