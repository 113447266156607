import { ProductCategoryActionTypes } from './product-category.types';

export const ProductCategoryActions = {
  // Fetch bookings
  fetchList: () => ({
    type: ProductCategoryActionTypes.FetchList
  }),
  fetchListSuccess: categories => ({
    type: ProductCategoryActionTypes.FetchListSuccess,
    categories
  }),
  // Create
  create: data => ({
    type: ProductCategoryActionTypes.Create,
    data
  }),
  createSuccess: productCategory => ({
    type: ProductCategoryActionTypes.CreateSuccess,
    productCategory
  }),
  // Update
  update: (productCategoryId, data) => ({
    type: ProductCategoryActionTypes.Update,
    productCategoryId,
    data
  }),
  updateSuccess: productCategory => ({
    type: ProductCategoryActionTypes.UpdateSuccess,
    productCategory
  }),
  // Delete
  delete: productCategory => ({
    type: ProductCategoryActionTypes.Delete,
    productCategory
  }),
  deleteSuccess: productCategoryId => ({
    type: ProductCategoryActionTypes.DeleteSuccess,
    productCategoryId
  }),

  // Set status
  setStatusSuccess: () => ({
    type: ProductCategoryActionTypes.StatusSuccess
  }),
  setStatusError: () => ({
    type: ProductCategoryActionTypes.StatusError
  }),
  setStatusInitial: () => ({
    type: ProductCategoryActionTypes.StatusInitial
  })
}