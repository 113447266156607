import { takeLatest, call, put } from 'redux-saga/effects';
// Action
import * as LayoutActions from 'app/actions/layout.actions';
import Types, { InventoryGroupMessageTypes } from '../types/inventory-group.types';
import InventoryGroupService from '../services/inventory-group.service';
import InventoryGroupActions from '../actions/inventory-group.actions';

// Fetch all
function*  _fetchAll(action){
  yield put(InventoryGroupActions.setIsFetching());
  try {
    const { limit, offset } = action.payload;
    const response = yield call(InventoryGroupService.fetchAll, limit, offset);
    yield put(InventoryGroupActions.fetchAllSuccess(response));
    yield put(InventoryGroupActions.setIsFetched());
  } catch(err){
    console.error(err);
    yield put(InventoryGroupActions.fetchAllFailure());
    yield put(InventoryGroupActions.setIsFetched());
  }
}

// Fetch by id
function* _fetchById(action){
  yield put(InventoryGroupActions.setIsFetching());
  try {
    const { inventoryGroupId } = action.payload;
    const response = yield call(InventoryGroupService.fetchById, inventoryGroupId);
    // const [
    //   responseStaff, responseGroup
    // ] = yield all([
    //   // call(UserService.fetchAllStaff, {}),
    //   call(InventoryGroupService.fetchById, inventoryGroupId)
    // ]);
    // yield put(UserActions.fetchAllStaffSuccess(responseStaff.data));
    yield put(InventoryGroupActions.fetchByIdSuccess(response));
    yield put(InventoryGroupActions.setIsFetched());
  } catch(err){
    console.error(err);
    yield put(InventoryGroupActions.fetchByIdFailure());
    yield put(InventoryGroupActions.setIsFetched());
  }
}

// Create
function* _create(action){
  try {
    const { inventoryGroup } = action.payload;
    const response = yield call(InventoryGroupService.create, inventoryGroup);
    yield put(InventoryGroupActions.createSuccess(response));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: InventoryGroupMessageTypes.Create
    }));
  } catch(err){
    console.error(err);
    yield put(InventoryGroupActions.createFailure());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: err.message
    }));
  }
}

// Update
function* _update(action){
  try {
    const { inventoryGroup } = action.payload;
    const response = yield call(InventoryGroupService.update, inventoryGroup);
    yield put(InventoryGroupActions.updateSuccess(response));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: InventoryGroupMessageTypes.Update
    }));
  } catch(err){
    console.error(err);
    yield put(InventoryGroupActions.updateFailure());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: err.message
    }));
  }
}

// Delete
function* _delete(action){
  try {
    const { inventoryGroup } = action.payload;
    if ( window.confirm(`Are you sure you want to delete group: ${inventoryGroup.name}?`) ){
      yield call(InventoryGroupService.delete, inventoryGroup.id);
      yield put(InventoryGroupActions.deleteSuccess(inventoryGroup.id));
      yield put(LayoutActions.addNotification({
        type: 'success',
        message: InventoryGroupMessageTypes.Delete
      }));
    }
  } catch(err){
    console.error(err);
    yield put(InventoryGroupActions.deleteFailure());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: err.message
    }));
  }
}

// Enable
function* _enable(action){
  try {
    const { inventoryGroupId } = action.payload;
    yield call(InventoryGroupService.enable, inventoryGroupId);
    yield put(InventoryGroupActions.enableSuccess(inventoryGroupId));
  } catch(err){
    console.error(err);
    yield put(InventoryGroupActions.enableFailure());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: err.message
    }));
  }
}

// Disable
function* _disable(action){
  try {
    const { inventoryGroupId } = action.payload;
    yield call(InventoryGroupService.disable, inventoryGroupId);
    yield put(InventoryGroupActions.disableSuccess(inventoryGroupId));
  } catch(err){
    console.error(err);
    yield put(InventoryGroupActions.disableFailure());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: err.message
    }));
  }
}

export function* inventoryGroupSaga(){
  yield takeLatest(Types.FETCH_ALL, _fetchAll);
  yield takeLatest(Types.FETCH_BY_ID, _fetchById);
  yield takeLatest(Types.CREATE, _create);
  yield takeLatest(Types.UPDATE, _update);
  yield takeLatest(Types.DELETE, _delete);
  yield takeLatest(Types.ENABLE, _enable);
  yield takeLatest(Types.DISABLE, _disable);
}
