import React from 'react';
import { Link, useLocation } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
import { UserActions } from '../store/user/user.actions';
import * as UserSelectors from '../store/user/user.selectors';
// Components
import { ButtonLoading } from '../components/Buttons';
// Hooks
import useForm from '../hooks/useForm';
// Utilities
import { updatePasswordForm as validation } from '../utilities/validation';

const UpdatePasswordPage = ({
  // Props
  // State
  status,
  // Dispatch
  updatePassword
}) => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const urlConfirmationCode = params.get('confirmationCode');
  const urlEmail = params.get('email');

  const callback = () => {
    const { confirmationCode, email, password } = values;
    const data = { confirmationCode, email, password };
    updatePassword(data);
  }

  const [values, errors, handleChange, handleSubmit] = useForm(callback, {
    confirmationCode: urlConfirmationCode || '',
    email: urlEmail || '',
    password: '',
    rePassword: ''
  }, validation);

  return (
    <div className="area area-auth">
      <div className="container-fluid">
        <div className="area-container">
          <h1 className="area-title">Update password</h1>
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            
            {/* Confirmation code */}
            <div className="form-group">
              <label htmlFor="confirmationCode">Confirmation code&nbsp;*</label>
              <input
                className={`form-control form-control-sm ${ errors.confirmationCode ? 'is-invalid' : '' }`}
                id="confirmationCode"
                name="confirmationCode"
                value={values.confirmationCode}
                onChange={handleChange}
              />
              {errors.confirmationCode && <div className="invalid-feedback">{errors.confirmationCode}</div>}
            </div>

            {/* E-mail */}
            <div className="form-group">
              <label htmlFor="email">E-mail&nbsp;*</label>
              <input
                className={`form-control form-control-sm ${ errors.email ? 'is-invalid' : '' }`}
                id="email"
                name="email"
                type="email"
                value={values.email}
                onChange={handleChange}
              />
              {errors.email && <div className="invalid-feedback">{errors.email}</div>}
            </div>

            {/* Password */}
            <div className="form-group">
              <label htmlFor="password">New password&nbsp;*</label>
              <input
                className={`form-control form-control-sm ${ errors.password ? 'is-invalid' : '' }`}
                id="password"
                name="password"
                type="password"
                value={values.password}
                onChange={handleChange}
              />
              {errors.password && <div className="invalid-feedback">{errors.password}</div>}
            </div>

            {/* Re-Password */}
            <div className="form-group">
              <label htmlFor="rePassword">Confirm new password&nbsp;*</label>
              <input
                className={`form-control form-control-sm ${ errors.rePassword ? 'is-invalid' : '' }`}
                id="rePassword"
                name="rePassword"
                type="password"
                value={values.rePassword}
                onChange={handleChange}
              />
              {errors.rePassword && <div className="invalid-feedback">{errors.rePassword}</div>}
            </div>

            <div className="form-group">
              <ButtonLoading
                classes="btn btn-primary btn-sm btn-block"
                type="submit"
                loading={status === 'Loading' ? true : false}
              >Update password</ButtonLoading>
              <Link
                className="btn btn-link btn-sm btn-block"
                to="/"
              >Login</Link>
            </div>

          </form>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ _UserState }) => ({
  status: UserSelectors.getStatus(_UserState)
});

const mapDispatchToProps = dispatch => ({
  updatePassword: data => dispatch(UserActions.updatePassword(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePasswordPage);
