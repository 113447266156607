import { Action } from 'redux';
import Statuses from 'types/statuses';

export interface IMapPoi {
  id:number;
  name:string;
  options:IMapPoiOptions;
}

export interface IMapPoiData {
  mapId?:number;
  name:string;
  options:IMapPoiOptions;
}

interface IMapPoiOptions {
  lat:number;
  lng:number;
}

// State
export interface IMapPoiState {
  items: Array<IMapPoi> | null;
  isFetching: boolean;
  isLoading: boolean;
  status: Statuses;
}

// Messages
export enum MapPoiMessages {
  Create = 'Marker was created',
  Update = 'Marker was updated',
  Delete = 'Marker was deleted'
}

// Types
export const SET_LIST = '@@mapPoi/Set list';

export const CREATE = '@@mapPoi/Create';
export const CREATE_SUCCESS = '@@mapPoi/Create success';

export const UPDATE = '@@mapPoi/Update';
export const UPDATE_SUCCESS = '@@mapPoi/Update success';

export const DELETE = '@@mapPoi/Delete';
export const DELETE_SUCCESS = '@@mapPoi/Delete success';

export const SET_STATUS = '@@mapPoi/Set status';


// Actions
interface SetList extends Action<typeof SET_LIST> {
  payload: { items:Array<IMapPoi> }
}
interface Create extends Action<typeof CREATE> {
  payload: { data:IMapPoiData }
}
interface CreateSuccess extends Action<typeof CREATE_SUCCESS> {
  payload: { item:IMapPoi }
}

interface Update extends Action<typeof UPDATE> {
  payload: { id:number, data:IMapPoiData }
}
interface UpdateSuccess extends Action<typeof UPDATE_SUCCESS> {
  payload: { item:IMapPoi }
}

interface Delete extends Action<typeof DELETE> {
  payload: { id:number }
}
interface DeleteSuccess extends Action<typeof DELETE_SUCCESS> {
  payload: { id:number }
}

interface SetStatus extends Action<typeof SET_STATUS> {
  payload: { status:Statuses }
}

export type MapPoiActionTypes =
  SetList |
  Create | CreateSuccess |
  Update | UpdateSuccess |
  Delete | DeleteSuccess |
  SetStatus
;