import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// Store
import { connect } from 'react-redux';
import ProductActions from '../../../actions/product.actions';
// Component
import { Select } from '../../../components/Controls';

class ProductFilter extends Component {
  constructor(props){
    super(props);

    const { inventoryGroups, params } = props;

    this.state = {
      groups: [
        { value: '', label: 'Filter by inventory group' },
        ...inventoryGroups.map(g => ({ value: g.id, label: g.name }))
      ],
      params: {
        ...params,
        offset: 0,
        limit: 20
      }
    }
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState(prevState => ({
      params: {
        ...prevState.params,
        [name]: value
      }
    }), () => {
      const { params } = this.state;
      this.props.fetchAll(params);
    });
  }

  render(){
    const { params, groups } = this.state;
    const { path } = this.props;
    return (
      <>
        <div className="form-group text-right">
          <Link
            className="btn btn-primary btn-sm"
            to={`${path}/create`}
          >Create product</Link>
        </div>
        <div className="row row-8">
          <div className="col-12 col-md-6 col-lg-4 col-xl-3">
            <Select
              label="Inventory group" id="inventoryGroupId" name="inventoryGroupId"
              value={params.inventoryGroupId}
              onChange={this.handleChange}
              options={groups}
            />
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  ({ ProductState, inventoryGroup }) => ({
    inventoryGroups: inventoryGroup.items,
    params: ProductState.params,
  }),
  dispatch => ({
    fetchAll: (params, isMore) => dispatch(ProductActions.fetchAll(params, isMore))
  })
)(ProductFilter);
