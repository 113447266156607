import { Reducer } from 'redux';
// Types
import Statuses from 'types/statuses';
import { RemotelockDeviceTypes, RemotelockDeviceActionTypes, } from './remotelock-device.types';
// Models
import { IRemotelockDeviceState } from './remotelock-device.models'

export default class RemotelockDeviceReducer {
  private static readonly _initialState:IRemotelockDeviceState = {
    remotelockDevices: null,
    audits: null,
    auditTotal: 0,
    auditParams: {
      limit: 20,
      offset: 0,
      start: '',
      end: ''
    },
    isLoading: false,
    status: Statuses.Initial
  };

  public static reducer:Reducer<IRemotelockDeviceState, RemotelockDeviceActionTypes> = (
    state = RemotelockDeviceReducer._initialState,
    action
  ) => {
    switch (action.type){
      // Fetch remotelock devices
      case RemotelockDeviceTypes.FetchRemotelockDevices:
        return { ...state, remotelockDevices: null };
      case RemotelockDeviceTypes.FetchRemotelockDevicesSuccess:
        return { ...state, remotelockDevices: action.payload.remotelockDevices };
      // Fetch remotelock device audits
      case RemotelockDeviceTypes.FetchRemotelockDeviceAudits:
        return { ...state, audits: null };
      case RemotelockDeviceTypes.FetchRemotelockDeviceAuditsSuccess:
        return { ...state, audits: action.payload.audits, auditTotal: action.payload.auditTotal };
      // Default
      // Set status
      case RemotelockDeviceTypes.SetStatus:
        const hasError:boolean = action.payload.status === Statuses.Error;
        return {
          ...state,
          isLoading: hasError ? false : state.isLoading,
          status: action.payload.status
        };
      // Set initial field
      case RemotelockDeviceTypes.SetInitialField:
        return {
          ...state,
          [action.payload.field]: RemotelockDeviceReducer._initialState[action.payload.field]
        };
      default:
        return state;
    }
  }

}