import fetcher from 'app/utilities/fetcher';

const headers = { 'Content-Type': 'application/json' };

// Fetch templates list
export function $fetchTemplatesList(){
  return fetcher('/admin/notificationTemplates', {
    method: 'GET'
  });
}

export function $createTemplate(template){
  return fetcher('/admin/notificationTemplates', {
    method: 'POST',
    headers,
    body: JSON.stringify(template)
  });
    // .then(response => responseHandler(response, _CREATE_TEMPLATE))
    // .catch(errorHandler);
}

export function $updateTemplate(template){
  return fetcher(`/admin/notificationTemplates/${template.id}`, {
    method: 'PUT',
    headers,
    body: JSON.stringify(template)
  });
    // .then(response => responseHandler(response, _UPDATE_TEMPLATE))
    // .catch(errorHandler);
}

export function $deleteTemplate(templateId){
  return fetcher(`/admin/notificationTemplates/${templateId}`, {
    method: 'DELETE'
  }, () => null);
    // .then(response => responseHandler(response, _DELETE_TEMPLATE))
    // .catch(errorHandler);
}

export function $fetchTemplatesVariables(){
  return fetcher('/admin/notificationTemplates/variables', {
    method: 'GET'
  });
}
