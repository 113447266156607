import React, { useEffect } from 'react';
// Redux
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import MapActions from 'store/map/map.actions';
import * as MapSelectors from 'store/map/map.selectors';
import { IMap } from 'store/map/map.types';
// Components
import { Container } from 'components/Container';
// 
import MapCreate from './MapCreate';
import MapsListItem from './MapsListItem';

type IProps = {
  items: Array<IMap>,
  isFetching: boolean,
  fetchMapList: () => void,
  deleteMap: (id:number) => void,
  enableMap: (id:number) => void,
  disableMap: (id:number) => void,
  setMapAsMain: (id:number) => void
}

const MapsPage:React.FC<IProps> = ({
  // State
  items, isFetching,
  // Dispatch
  fetchMapList, deleteMap, enableMap, disableMap, setMapAsMain
}) => {
  useEffect(() => {
    fetchMapList();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="container-fluid pt-3 pb-3">
      <MapCreate />
      <Container data={items} isFetching={isFetching}>
        <div className="row row-8 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
          {items && items.map(item => (
            <div key={`map-list-item-${item.id}`} className="col | d-flex mb-2">
              <MapsListItem
                item={item}
                onDelete={deleteMap}
                onEnable={enableMap}
                onDisable={disableMap}
                onSetAsMain={setMapAsMain}
              />
            </div>
          ))}
        </div>
      </Container>
    </div>
  )
};

const mapStateToProps = (state:any) => ({
  items: MapSelectors.getItems(state),
  isFetching: MapSelectors.getIsFetching(state)
});

const mapDispatchToProps = (dispatch:Dispatch) => ({
  fetchMapList: () => dispatch(MapActions.fetchList()),
  deleteMap: (id:number) => dispatch(MapActions.delete(id)),
  enableMap: (id:number) => dispatch(MapActions.enable(id)),
  disableMap: (id:number) => dispatch(MapActions.disable(id)),
  setMapAsMain: (id:number) => dispatch(MapActions.setAsMain(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(MapsPage);
