import { Reducer } from 'redux';
import {
  IInventoryGroupState,
  InventoryGroupActionTypes,
  FETCH_LIST, FETCH_LIST_SUCCESS,
  FETCH_BY_ID, FETCH_BY_ID_SUCCESS,
  CREATE, CREATE_SUCCESS,
  UPDATE, UPDATE_SUCCESS,
  DELETE, DELETE_SUCCESS,
  ENABLE, ENABLE_SUCCESS,
  DISABLE, DISABLE_SUCCESS,
  RESET_DEFAULT_WAIVER, RESET_DEFAULT_WAIVER_SUCCESS,
  SET_DEFAULT_WAIVER, SET_DEFAULT_WAIVER_SUCCESS,
  SET_FILTER, SET_STATUS, SetInitialField
} from './inventory-group.types';
import Statuses from 'types/statuses';

export default class InventoryGroupReducer {
  private static readonly _initialState:IInventoryGroupState = {
    items: null,
    item: null,
    filter: {
      type: ''
    },
    isFetching: false,
    isLoading: false,
    status: Statuses.Initial
  }

  public static reducer:Reducer<IInventoryGroupState, InventoryGroupActionTypes> = (
    state = InventoryGroupReducer._initialState,
    action
  ) => {
    switch (action.type){
      // Fetch list
      case FETCH_LIST:
        return {...state, isFetching: true};
      case FETCH_LIST_SUCCESS:
        return {...state, items: action.payload.items, isFetching: false};
      // Fetch by id
      case FETCH_BY_ID:
        return {...state, isFetching: true};
      case FETCH_BY_ID_SUCCESS:
        return {...state, item: action.payload.item, isFetching: false};
      // Create
      case CREATE:
        return {...state, isLoading: true};
      case CREATE_SUCCESS:
        return {
          ...state,
          items: state.items ? [...state.items, action.payload.item] : state.items,
          isLoading: false
        };
      // Update
      case UPDATE:
        return {...state, isLoading: true};
      case UPDATE_SUCCESS:
        return {
          ...state,
          items: state.items
            ? state.items.map(
                item =>
                  item.id === action.payload.item.id
                  ? action.payload.item
                  : item
              )
            : state.items,
          isLoading: false
        };
      // Delete
      case DELETE:
        return {...state, isLoading: true};
      case DELETE_SUCCESS:
        return {
          ...state,
          items: state.items
            ? state.items.filter(item => item.id !== action.payload.id)
            : state.items,
          isLoading: false
        };
      // Enable
      case ENABLE:
        return state;
      case ENABLE_SUCCESS:
        return {
          ...state,
          items: state.items
            ? state.items.map(
                item =>
                  item.id === action.payload.id
                  ? {...item, enabled: true}
                  : item
              )
            : state.items
        }
      // Disable
      case DISABLE:
        return state;
      case DISABLE_SUCCESS:
        return {
          ...state,
          items: state.items
            ? state.items.map(
                item =>
                  item.id === action.payload.id
                  ? {...item, enabled: false}
                  : item
              )
            : state.items
        };
      // Reset/Set default waiver
      case RESET_DEFAULT_WAIVER:
      case SET_DEFAULT_WAIVER:
        return state;
      case RESET_DEFAULT_WAIVER_SUCCESS:
      case SET_DEFAULT_WAIVER_SUCCESS:
        return {
          ...state,
          item: action.payload.item,
          items: state.items
            ? state.items.map(
                item =>
                  item.id === action.payload.item.id
                  ? action.payload.item
                  : item
              )
            : state.items
        }
      // Default
      case SET_FILTER:
        return {
          ...state,
          filter: {
            ...state.filter,
            [action.payload.field]: action.payload.value
          }
        };
      case SET_STATUS:
        const hasError:boolean = action.payload.status === Statuses.Error;
        return {
          ...state,
          isFetching: hasError ? false : state.isFetching,
          isLoading: hasError ? false : state.isLoading,
          status: action.payload.status
        };
      case SetInitialField:
        return {
          ...state,
          [action.payload.field]: InventoryGroupReducer._initialState[action.payload.field]
        };
      default:
        return state;
    }
  }
}
