import { call, put, takeLatest } from 'redux-saga/effects';
// Store
import * as LayoutActions from '../../actions/layout.actions';
import { ProductCategoryActionTypes, ProductCategoryMessageTypes } from './product-category.types';
import { ProductCategoryActions } from './product-category.actions';
import { ProductCategoryService } from './product-category.service';

function* _fetchList(action){
  try {
    const response = yield call(ProductCategoryService.fetchList);
    yield put(ProductCategoryActions.fetchListSuccess(response));
    yield put(ProductCategoryActions.setStatusInitial());
  } catch(error){
    console.log(error);
    yield put(ProductCategoryActions.setStatusError());
  }
}

function* _create(action){
  try {
    const { data } = action;
    const response = yield call(ProductCategoryService.create, data);
    yield put(ProductCategoryActions.createSuccess(response));
    yield put(ProductCategoryActions.setStatusSuccess());
    yield put(ProductCategoryActions.setStatusInitial());
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: ProductCategoryMessageTypes.Create
    }));
  } catch(error){
    console.log(error);
    yield put(ProductCategoryActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _update(action){
  try {
    const { productCategoryId, data } = action;
    const response = yield call(ProductCategoryService.update, productCategoryId, data);
    yield put(ProductCategoryActions.updateSuccess(response));
    yield put(ProductCategoryActions.setStatusSuccess());
    yield put(ProductCategoryActions.setStatusInitial());
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: ProductCategoryMessageTypes.Update
    }));
  } catch(error){
    console.log(error);
    yield put(ProductCategoryActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _delete(action){
  try {
    const { productCategory } = action;
    if ( window.confirm(`Are you sure you want to delete product category: ${productCategory.name}?`) ){
      yield call(ProductCategoryService.delete, productCategory.id);
      yield put(ProductCategoryActions.deleteSuccess(productCategory.id));
      yield put(LayoutActions.addNotification({
        type: 'success',
        message: ProductCategoryMessageTypes.Delete
      }));
    }
  } catch(error){
    console.log(error);
    yield put(ProductCategoryActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

export default function* (){
  yield takeLatest(ProductCategoryActionTypes.FetchList, _fetchList);
  yield takeLatest(ProductCategoryActionTypes.Create, _create);
  yield takeLatest(ProductCategoryActionTypes.Update, _update);
  yield takeLatest(ProductCategoryActionTypes.Delete, _delete);
}