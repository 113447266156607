import React from 'react';
// Redux
import { connect } from 'react-redux';
import { toggleDrawer } from '../../actions/layout.actions';

const DrawerTop = props => {
  const {
    // State
    drawerOpen,
    // Dispatch
    toggleDrawer
  } = props;

  return (
    <div className="root-drawer-top">
      <button
        className="btn btn-outline-primary btn-sm"
        type="button"
        onClick={() => toggleDrawer()}
      ><i className={`fa fa-chevron-${ drawerOpen ? 'left' : 'right' }`} aria-hidden="true"></i></button>
    </div>
  )
}

const mapStateToProps = state => ({
  drawerOpen: state.layout.drawerOpen
});

const mapDispatchToProps = dispatch => ({
  toggleDrawer: () => dispatch(toggleDrawer())
});

export default connect(mapStateToProps, mapDispatchToProps)(DrawerTop);
