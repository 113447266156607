import React, { useEffect } from 'react';
// Store
import { connect } from 'react-redux';
import UserActions from 'app/actions/user.actions';
import * as UserSelectors from 'app/_selectors/user.selectors';
// Component
import { DataMessage } from 'app/components/DataMessage';
import { DataLoading } from 'app/components/Loadings';
import { ButtonLoading } from 'app/components/Buttons';
import CampersListItem from './CampersListItem';

const CampersList = ({
  // Props
  // State
  campers, camperTotal, params, status, checkedUserIds, isFetching,
  // Dispatch
  fetchAllCampers, checkAll, uncheckAll, checkUser, uncheckUser, deleteUser
}) => {
  useEffect(() => {
    fetchAllCampers({...params, offset: 0}, true);
    // eslint-disable-next-line
  }, []);

  const handleClickLoadMore = () => {
    const { limit, offset } = params;
    fetchAllCampers({ ...params, offset: offset + limit }, false, true)
  }

  if ( isFetching ) return <DataLoading />;
  return (
    <DataMessage data={campers} type="user">
      <div className="d-flex align-items-center mb-2">
        {checkedUserIds.length === campers.length
          ? 
            <button
              className="btn btn-primary btn-sm | mr-2"
              type="button"
              onClick={() => uncheckAll()}
            >Unselect all</button>
          :
            <button
              className="btn btn-outline-primary btn-sm | mr-2"
              type="button"
              onClick={() => checkAll()}
            >Select all visible</button>
        }
        <small className="text-secondary">{`${campers.length} camper(s) loaded of ${camperTotal} found`}</small>
      </div>
      <div className="row row-8 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
        {campers.map(camper => (
          <div key={`staff-item-${camper.id}`} className="col | d-flex mb-2">
            <CampersListItem
              item={camper}
              onCheck={checkUser}
              onUncheck={uncheckUser}
              onDelete={deleteUser}
            />
          </div>
        ))}
      </div>
      {(camperTotal !== campers.length) &&
        <>
          <div className="pt-3 text-center">
            <ButtonLoading
              loading={status === 'FetchingMore'}
              onClick={handleClickLoadMore}
            >Load more</ButtonLoading>
          </div>
        </>
      }
    </DataMessage>
  )
}

export default connect(
  ({ UserState }) => ({
    campers: UserSelectors.getCampers(UserState),
    camperTotal: UserSelectors.getCamperTotal(UserState),
    params: UserSelectors.getParams(UserState),
    status: UserSelectors.getStatus(UserState),

    checkedUserIds: UserState.checkedUserIds,
    isFetching: UserState.isFetching
  }),
  dispatch => ({
    fetchAllCampers: (params, isLoadInitial, isLoadMore)  => dispatch(UserActions.fetchAllCampers(params, isLoadInitial, isLoadMore)),
    checkUser: userId => dispatch(UserActions.checkUser(userId)),
    uncheckUser: userId => dispatch(UserActions.uncheckUser(userId)),
    deleteUser: user => dispatch(UserActions.delete(user)),

    checkAll: () => dispatch(UserActions.checkAll()),
    uncheckAll: () => dispatch(UserActions.uncheckAll()),
  })
)(CampersList);
