import { createSelector } from 'reselect';
// Types
import { ISeason } from './season.types';
// Service
import { buildSlot, stringToColor } from './season.service';

export const getSeasons = (state:any) => state.season.items;
export const getIsFetching = (state:any) => state.season.isFetching;
export const getIsLoading = (state:any) => state.season.isLoading;
export const getStatus = (state:any) => state.season.status;

export const getSeasonsWithColors = createSelector(
  getSeasons, seasons => {
    if ( !seasons ) return null;
    return seasons.map((season:ISeason) => ({...season, color: stringToColor(`${season.id}${season.name.trim()}`) }))
  }
)

export const getSeasonById = createSelector(
  getSeasons, (_:null, props:{ seasonId:number }) => props,
  (seasons:Array<ISeason>, { seasonId }) => {
    if ( !seasonId ) return null;
    if ( !seasons || seasons.length === 0 ) return null;
    return seasons.find((season:ISeason) => season.id === seasonId) || null;
  }
);

export const getSeasonsSlotsForCalendar = createSelector(
  (state:any) => getSeasonsWithColors(state),
  (seasons:any) => {
    if ( !seasons || seasons.length === 0 ) return {};
    let result:any = {};
    for ( let season of seasons ){
      if ( !season.slots || season.slots.length === 0 ) continue;
      const slotData = { id: season.id, name: season.name, color: season.color };
      for ( let slot of season.slots ){
        const calendarSlot = buildSlot(slot.start, slot.end, slotData);
        for ( let s of Object.keys(calendarSlot) ){
          if ( result[s] ){
            result[s] = { ...result[s], ...calendarSlot[s] };
          } else {
            result[s] = calendarSlot[s];
          }
        }
      }
    }
    // console.log(result);
    return result;
  }
)
