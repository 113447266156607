import { BookingItemActionTypes } from './booking-item.types';

export const BookingItemActions = {
  // Fetch bookings
  fetchBookingItems: (bookingId, status) => ({
    type: BookingItemActionTypes.FetchBookingItems,
    bookingId,
    status
  }),
  fetchBookingItemsSuccess: bookingItems => ({
    type: BookingItemActionTypes.FetchBookingItemsSuccess,
    bookingItems
  }),
  // Create
  create: data => ({
    type: BookingItemActionTypes.Create,
    data
  }),
  createSuccess: bookingItem => ({
    type: BookingItemActionTypes.CreateSuccess,
    bookingItem
  }),
  // Update
  update: (bookingItemId, data) => ({
    type: BookingItemActionTypes.Update,
    bookingItemId,
    data
  }),
  updateSuccess: bookingItem => ({
    type: BookingItemActionTypes.UpdateSuccess,
    bookingItem
  }),
  // Delete
  delete: bookingItem => ({
    type: BookingItemActionTypes.Delete,
    bookingItem
  }),
  deleteSuccess: bookingItemId => ({
    type: BookingItemActionTypes.DeleteSuccess,
    bookingItemId
  }),

  // Actions
  // Mark as free
  markAsFree: bookingItemId => ({
    type: BookingItemActionTypes.MarkAsFree,
    bookingItemId
  }),
  markAsFreeSuccess: bookingItem => ({
    type: BookingItemActionTypes.MarkAsFreeSuccess,
    bookingItem
  }),
  // Mark as out of service
  markAsOutOfService: bookingItemId => ({
    type: BookingItemActionTypes.MarkAsOutOfService,
    bookingItemId
  }),
  markAsOutOfServiceSuccess: bookingItem => ({
    type: BookingItemActionTypes.MarkAsOutOfServiceSuccess,
    bookingItem
  }),

  // Filter
  setFilter: (filterType, filterValue) => ({
    type: BookingItemActionTypes.Filter,
    filterType,
    filterValue
  }),
  clearFilter: () => ({
    type: BookingItemActionTypes.ClearFilter
  }),

  // Reset field
  resetField: field => ({
    type: BookingItemActionTypes.ResetField,
    field
  }),
  // Set status
  setStatusSuccess: () => ({
    type: BookingItemActionTypes.StatusSuccess
  }),
  setStatusError: () => ({
    type: BookingItemActionTypes.StatusError
  }),
  setStatusInitial: () => ({
    type: BookingItemActionTypes.StatusInitial
  }),


  assignRemotelockDevices: (bookingItemId, remotelockDevicesIds) => ({
    type: BookingItemActionTypes.AssignRemotelockDevices,
    payload: { bookingItemId, remotelockDevicesIds }
  }),
  assignRemotelockDevicesSuccess: (bookingItem) => ({
    type: BookingItemActionTypes.AssignRemotelockDevicesSuccess,
    payload: { bookingItem }
  })
}