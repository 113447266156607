import React from 'react';
// Components
import { DataMessage } from '../../../components/DataMessage';
// Utils
import { parseUTCStartEndDate } from '../../../utilities/datetime.convert';

const ScheduleSlotsList = ({slots, onUpdateScheduleSlot, onDeleteScheduleSlot}) => {
  return (
    <DataMessage data={slots} type="schedule">
      <ul className="list-group">
        {slots.map((slot, index) => (
          <li
            key={index}
            className="
              list-group-item |
              d-flex justify-content-between align-items-center
            "
          >
            <div>
              <h5 className="mb-2">{slot.location}</h5>
              <p className="text-muted mb-0">{slot.description}</p>
              <p className="text-muted mb-0" style={{fontSize: '0.875rem', fontWeight: '700'}}>{parseUTCStartEndDate(slot.start, slot.end)}</p>
            </div>
            <div className="pl-2">
              <button
                className="btn btn-primary btn-sm btn-block"
                type="button"
                onClick={onUpdateScheduleSlot(slot)}
              >Edit</button>
              <button
                className="btn btn-outline-danger btn-sm btn-block"
                type="button"
                onClick={() => onDeleteScheduleSlot(slot.id)}
              >Delete</button>
            </div>
          </li>
        ))}
      </ul>
    </DataMessage>
  )
}

export default ScheduleSlotsList;
