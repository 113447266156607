import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// Types
import Statuses from 'types/statuses';
// Model
import { IMembershipTransaction } from 'models/Membership';
// Store
import { useSelector } from 'react-redux';
// Selectors
import { selectUserEmailFromMembership, getIsLoading, getStatus } from 'store/membership/membership.selectors';
// Dialogs
import SendReceiptFormDialog from 'dialogs/SendReceiptForm.dialog';

dayjs.extend(utc)

type Props = {
  membershipId: number;
  transaction: IMembershipTransaction;
};

const TransactionsListItem:React.FC<Props> = ({
  // Props
  membershipId, transaction
}) => {
  // State
  const userEmail:string = useSelector((state:any) => selectUserEmailFromMembership(state, { membershipId }));
  const isLoading:boolean = useSelector((state:any) => getIsLoading(state));
  const status:Statuses = useSelector((state:any) => getStatus(state));

  const [ dialogOpen, setDialogOpen ] = React.useState<boolean>(false);

  const toggleDialog = () => setDialogOpen((prevState:boolean) => !prevState);

  return (
    <React.Fragment>
      <tr>
        <td><small className="text-muted">{transaction.type}</small></td>
        <td>
          <span className={`badge badge-${classes(transaction.status)}`}>{transaction.status}</span>
        </td>
        <td><b>${transaction.amount}</b></td>
        <td><small style={{ wordBreak: 'break-all' }}>{transaction.notes || '-'}</small></td>
        <td><small className="text-muted">{dayjs.utc(transaction.createdOn).format('MMMM DD YYYY, hh:mm a')}</small></td>
        <td>
          <button
            className="btn btn-outline-primary btn-sm"
            type="button"
            onClick={toggleDialog}
          >Send receipt</button>
        </td>
      </tr>
      {dialogOpen ? (
        <SendReceiptFormDialog
          type="membership"
          id={membershipId}
          email={userEmail}
          transactionId={transaction.id}
          show={dialogOpen}
          onHide={toggleDialog}
          isLoading={isLoading}
          status={status}
        />
      ) : null}
    </React.Fragment>
  )
}

const classes = (status:string) => {
  return status === 'completed' ? 'success' : 'danger';
}

export default TransactionsListItem;
