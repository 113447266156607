import React, { useState } from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';
// Redux
import { IMapPoi } from 'store/map-poi/map-poi.types';

type IProps = {
  item: IMapPoi;
}

const MapDetailsMapMarkerItem:React.FC<IProps> = ({
  // Props
  item
}) => {
  const [show, setShow] = useState<boolean>(false);

  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);

  const googleURL = `https://www.google.com/maps/dir/?api=1&origin=Current+Location&destination=${item.options.lat},${item.options.lng}`;

  return (
    <>
      <Marker
        position={item.options}
        onClick={handleShow}
      >
        {show ? (
          <InfoWindow
            onCloseClick={handleHide}
            position={item.options}
          >
            <div style={{ minWidth: '280px' }}>
              <h6 className="mb-0">{item.name}</h6>
              <div style={{ padding: '8px 4px 0' }}>
                <div className="row row-8">
                  <div className="col-4">
                    <a className="btn btn-outline-primary btn-sm btn-block" href={`${googleURL}&travelmode=driving`} target="_blank">
                      <i className="fa fa-car" aria-hidden="true"></i>
                      <span className="d-block">By Card</span>
                    </a>
                  </div>
                  <div className="col-4">
                    <a className="btn btn-outline-primary btn-sm btn-block" href={`${googleURL}&travelmode=bicycling`} target="_blank">
                      <i className="fa fa-bicycle" aria-hidden="true"></i>
                      <span className="d-block">Bike</span>
                    </a>
                  </div>
                  <div className="col-4">
                    <a className="btn btn-outline-primary btn-sm btn-block" href={`${googleURL}&travelmode=walking`} target="_blank">
                      <i className="fa fa-male" aria-hidden="true"></i>
                      <span className="d-block">Walking</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </InfoWindow>
        ) : null}
      </Marker>
    </>
  )
}

export default MapDetailsMapMarkerItem;
