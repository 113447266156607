import React, { useState, useEffect } from 'react';
// Store
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppActions } from 'app/store/app/app.actions';
import * as AppSelectors from 'app/store/app/app.selectors';
// Layouts
import Drawer from 'app/layouts/Drawer';
// Components
import { DataLoading } from 'app/components/Loadings';
// Routing
import AcccountManagerRouting from './AcccountManager.routing';

type IProps = {
  // Props
  // State
  drawerOpen: boolean;
  status:string;
  // Dispatch
  fetchAccountManagerData:() => void;
}

const AcccountManagerPage:React.FC<IProps> = ({
  // Props
  // State
  drawerOpen, status,
  // Dispatch
  fetchAccountManagerData
}) => {
  const [fetching, setFetching] = useState<boolean>(true);

  useEffect(() => {
    fetchAccountManagerData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( status === 'Fetched' ) setFetching(false);
    // eslint-disable-next-line
  }, [status]);

  if ( fetching ) return <DataLoading />;
  return (
    <>
      <Drawer open={drawerOpen} />
      <div className={`root-container ${ drawerOpen ? 'is-open' : '' }`}>
        <AcccountManagerRouting />
      </div>
    </>
  )
}

const mapStateToProps = (state:any) => ({
  drawerOpen: state.layout.drawerOpen,
  status: AppSelectors.getStatus(state.AppState)
});

const mapDispatchToProps = (dispatch:Dispatch) => ({
  fetchAccountManagerData: () => dispatch(AppActions.fetchAccountManagerData())
});

export default connect(mapStateToProps, mapDispatchToProps)(AcccountManagerPage);
