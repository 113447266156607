import React from 'react';
// Store
import { useSelector } from 'react-redux';
// Selectors
import { getVisitsTotal, getVisitsParams } from 'store/membership/membership.selectors';

type Props = {
  onFetchMembershipVisits: (params:any) => void;
};

const VisitsPagination:React.FC<Props> = ({
  // Props
  onFetchMembershipVisits
}) => {
  // Selector
  const total:number = useSelector((state:any) => getVisitsTotal(state));
  const params:any = useSelector((state:any) => getVisitsParams(state));

  const [ currentPage, setCurrentPage ] = React.useState<number>(1);

  const totalPages:number = Math.ceil(total/params.limit);

  const handleNextPage = (nextPage:number) => {
    setCurrentPage(nextPage);
    onFetchMembershipVisits({
      ...params,
      offset: (nextPage - 1) * params.limit
    });
  };

  if ( total < params.limit ) return null;
  return (
    <nav>
      <ul className="pagination pagination-sm justify-content-center mt-2">
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page:number) => (
          <li
            key={`pagination-item-${page}`}
            className={`page-item ${ page === currentPage ? 'active' : '' }`}
            onClick={() => handleNextPage(page)}
          ><a className="page-link">{page}</a></li>
        ))}
      </ul>
    </nav>
  )
}

export default VisitsPagination;
