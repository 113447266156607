import React from 'react';
// Connect
import { connect } from 'react-redux';
// Selectors
import { getSeasons } from 'store/season/season.selectors';
// Components
import { Loader } from 'components/Utility';

const CategoryDaily = ({
  // Props
  values, onChange, errors,
  // State
  seasons
  // Dispatch
}) => {
  const [ tab, setTab ] = React.useState(0);

  const handleNextTab = (nextTab) => setTab(nextTab);

  if ( !seasons ) return <Loader />
  return (
    <div>
      <div className="form-group border rounded p-3 pt-2">
        <label className="mb-2" htmlFor="">Availability hours&nbsp;*</label>
        {days.map((day, index) => (
          <InputDayTime
            key={`day-time-item-${day.name}-0-${index}`}
            label={day.label}
            startLabel="Start" startName={`${day.name}Start`} startValue={values[`${day.name}Start`]} startError={errors[`${day.name}Start`]}
            endLabel="End" endName={`${day.name}End`} endValue={values[`${day.name}End`]} endError={errors[`${day.name}End`]}
            onChange={onChange}
          />
        ))}
      </div>
      <div className="form-group border rounded p-3 mt-2">
        <label className="mb-2" htmlFor="">Day of week pricing&nbsp;*</label>
        <div className="row">
          <div className="col-4 | border-right">
            <div className="nav flex-column nav-pills">
              <a
                className={`nav-link ${tab === 0 ? 'active' : ''}`}
                href="#"
                onClick={() => handleNextTab(0)}
              >Default</a>
              {seasons.map(season => (
                <a
                  key={`season-nav-item-${season.id}`}
                  className={`nav-link ${tab === season.id ? 'active' : ''} d-flex align-items-center`}
                  href="#"
                  onClick={() => handleNextTab(season.id)}
                >
                  {season.name}
                  {errors[`sseason-${season.id}`] ? <span className="bg-danger p-1 rounded-circle ml-1"></span> : null}
                </a>
              ))}
            </div>
          </div>
          <div className="col-8">
            {tab === 0 ? (
              <React.Fragment>
                {days.map((day, index) => (
                  <InputDay
                    key={`day-item-${day.name}-0-${index}`}
                    label={day.label} name={day.name} value={values[day.name]}
                    required={true}
                    onChange={onChange}
                    error={errors[day.name]}
                  />
                ))}
              </React.Fragment>
            ) : null}
            {seasons.map(season => (
              <React.Fragment key={`season-content-item-${season.id}`}>
                {tab === season.id ? (
                  <React.Fragment>
                    {days.map((day, index) => (
                      <InputDay
                        key={`day-item-${season.name}-${season.id}-${index}`}
                        label={day.label} name={`season-${season.id}-${day.name}`} value={values[`season-${season.id}-${day.name}`]}
                        required={errors[`sseason-${season.id}`] ? true : false}
                        onChange={onChange}
                        error={errors[`season-${season.id}-${day.name}`]}
                      />
                    ))}
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

const days = [
  { label: 'Sunday', name: 'sunday' },
  { label: 'Monday', name: 'monday' },
  { label: 'Tuesday', name: 'tuesday' },
  { label: 'Wednesday', name: 'wednesday' },
  { label: 'Thursday', name: 'thursday' },
  { label: 'Friday', name: 'friday' },
  { label: 'Saturday', name: 'saturday' },
];

const InputDay = ({ label, name, value, onChange, error }) => {
  return (
    <div className="form-group row row-8">
      <label className="col-12 col-md-4 col-form-label text-right" style={{fontSize: '0.75rem'}}>{label}&nbsp;*</label>
      <div className="col-12 col-md-8">
        <div className="input-group input-group-sm">
          <div className="input-group-prepend">
            <span className="input-group-text">$</span>
          </div>
          <input
            className={`form-control form-control-sm ${ error && 'is-invalid' }`}
            id={name}
            type="number"
            name={name}
            value={value}
            onChange={onChange}
          />
          {error && <div className="invalid-feedback">{error}</div>}
        </div>
      </div>
    </div>
  );
}

const InputDayTime = ({
  // Props
  label,
  startLabel, startName, startValue, startError,
  endLabel, endName, endValue, endError,
  onChange
}) => {
  return (
    <div className="form-group row row-8">
      <label className="col-4 col-form-label text-right" style={{fontSize: '0.75rem', paddingTop: '2rem'}}>{label}&nbsp;*</label>
      <div className="col-8">
        <div className="row row-8">
          <div className="col-6">
            <label style={{fontSize: '0.75rem'}}>{startLabel}</label>
            <input
              className={`form-control form-control-sm ${ startError && 'is-invalid' }`}
              id={startName}
              type="time"
              name={startName}
              value={startValue}
              onChange={onChange}
            />
            {startError && <div className="invalid-feedback">{startError}</div>}
          </div>
          <div className="col-6">
            <label style={{fontSize: '0.75rem'}}>{endLabel}</label>
            <input
              className={`form-control form-control-sm ${ endError && 'is-invalid' }`}
              id={endName}
              type="time"
              name={endName}
              value={endValue}
              onChange={onChange}
            />
            {endError && <div className="invalid-feedback">{endError}</div>}
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  seasons: getSeasons(state)
});

export default connect(mapStateToProps)(CategoryDaily);
