import React, { Component } from 'react';
// Store
import { connect } from 'react-redux';
import OrderActions from '../../../actions/order.actions';
// 
import OrderFilter from './OrderFilter';
import OrderList from './OrderList';

class Orders extends Component {
  componentWillUnmount(){
    this.props.setInitialState();
  }

  render(){
    return (
      <div className="area area-orders">
        <div className="container-fluid">
          <OrderFilter />
          <OrderList />
        </div>
      </div>
    )
  }
}

export default connect(
  null,
  dispatch => ({
    setInitialState: () => dispatch(OrderActions.setInitialState())
  })
)(Orders);
