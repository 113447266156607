import React, { useState } from 'react';
// Redux
import { connect } from 'react-redux';
import { ReservationCategoryActions } from '../../../store/reservation-category/reservation-category.actions';
// Bootstrap
import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
// Modal
import CategoryFormModal from '../../../layouts/Modals/CategoryFormModal';
// Utilities
import { parseDate } from '../../../utilities/datetime.convert';

const CategoriesListItem = props => {
  const {
    // Props
    // State
    category,
    // Dispatch
    deleteCategory, enableCategory, disableCategory
  } = props;
  const [ show, setShow ] = useState(false);
  return (
    <>
      <div className="col-12 col-sm-6 col-md-4 col-lg-3 | d-flex">
        <div className="card">
          <div className="card-body">
            <h5 className="mb-0">{category.name}</h5>
            <div className="mb-1">
              <span className={`badge badge-${category.enabled ? 'success' : 'danger'}`}>
                {category.enabled ? 'Enabled' : 'Disabled'}
              </span>
            </div>
            <small className="d-block mb-2">{category.description}</small>
            {/* {category.dayOfWeekPricing &&
              <div>
                <b style={{fontSize: '0.875rem'}}>Day of week pricing</b>
                {Object.keys(category.dayOfWeekPricing).map((day, i) => (
                  <small key={i} className="d-block text-capitalize">
                    {day}:&nbsp;<b>${category.dayOfWeekPricing[day]}</b>
                    {category.dayOfWeekAvailabilityTime && (
                      <>
                        &nbsp;&nbsp;
                        {category.dayOfWeekAvailabilityTime[day].start}&nbsp;-&nbsp;
                        {category.dayOfWeekAvailabilityTime[day].end}
                      </>
                    )}
                  </small>
                ))}
              </div>
            } */}
            {category.seasonStart &&
              <small className="d-block">Season start&nbsp;<b>{parseDate(category.seasonStart)}</b></small>
            }
            {category.seasonEnd &&
              <small className="d-block">Season end&nbsp;<b>{parseDate(category.seasonEnd)}</b></small>
            }
            {category.periodPrice &&
              <small className="d-block">Period price&nbsp;<b>${category.periodPrice}</b></small>
            }
            {(category.attributes && category.attributes.length !== 0) &&
              <div>
                <b style={{fontSize: '0.875rem'}}>Attributes</b>
                {category.attributes.map((attribute, i) => (
                  <small key={i} className="d-block">{attribute.name}:&nbsp;<b>{attribute.value}</b></small>
                ))}
              </div>
            }
          </div>
          <div className="card-footer | text-right">
            <button
              className="btn btn-primary btn-sm"
              type="button"
              onClick={() => setShow(true)}
            >Edit</button>
            <DropdownButton
              as={ButtonGroup}
              alignRight
              className="ml-2"
              id={`dropdown-button-drop-${category.id}`}
              size="sm"
              variant="outline-secondary"
              title={''}
            >
              {!category.enabled
                ? <Dropdown.Item as="button" onClick={() => enableCategory(category.id)}>Enable</Dropdown.Item>
                : <Dropdown.Item as="button" onClick={() => disableCategory(category.id)}>Disable</Dropdown.Item>
              }
              <Dropdown.Item as="button" className="text-danger" onClick={() => deleteCategory(category)}>Delete</Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
      </div>
      {/* Modal */}
      {show &&
        <CategoryFormModal
          categoryId={category.id}
          show={show}
          onHide={() => setShow(false)}
        />
      }
    </>
  )
}

const mapDispatchToProps = dispatch => ({
  deleteCategory: category => dispatch(ReservationCategoryActions.delete(category)),
  enableCategory: categoryId => dispatch(ReservationCategoryActions.enable(categoryId)),
  disableCategory: categoryId => dispatch(ReservationCategoryActions.disable(categoryId)),
});

export default connect(null, mapDispatchToProps)(CategoriesListItem);
