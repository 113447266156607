import React from 'react';
import { useForm } from 'react-hook-form';
// Types
import Statuses from 'types/statuses';
// Models
import { IMembershipType } from 'models/MembershipType';
// Store
import { useDispatch, useSelector } from 'react-redux';
// Actions
import MembershipTypeActions from 'store/membership-type/membership-type.actions';
// Selectors
import { getIsLoading, getStatus } from 'store/membership-type/membership-type.selectors';
// Bootstrap
import Modal from 'react-bootstrap/Modal';
// Components
import { Input, Textarea } from 'components/Controls';
import { ButtonLoading } from 'app/components/Buttons';
// Utilities
import { isRequired } from 'utilities/validation';
import Taxes from 'components/Taxes';

type Props = {
  membershipType?: IMembershipType;
  show: boolean;
  onHide: () => void;
}

const MembershipTypeFormDialog:React.FC<Props> = ({
  // Props
  membershipType = null, show, onHide
}) => {
  // Dispatch
  const dispatch = useDispatch();
  // State
  const isLoading:boolean = useSelector((state:any) => getIsLoading(state));
  const status:Statuses = useSelector((state:any) => getStatus(state));

  const { register, handleSubmit, errors } = useForm<any>({
    defaultValues: {
      name: membershipType ? membershipType.name : '',
      description: membershipType ? membershipType.description : '',
      price: membershipType ? membershipType.price : '',
      tax: membershipType ? membershipType.tax : '',
      validityPeriodDays: membershipType ? membershipType.validityPeriodDays : ''
    }
  });

  const onSubmit = handleSubmit((data:any) => {
    const { name, description, price, tax, validityPeriodDays } = data;
    const newData:any = {
      name, description,
      price: Number(price),
      tax: Number(tax),
      validityPeriodDays: Number(validityPeriodDays)
    };
    if ( membershipType && membershipType.id ){
      dispatch(MembershipTypeActions.updateMembershipType(membershipType.id, newData));
    } else {
      dispatch(MembershipTypeActions.createMembershipType(newData));
    }
  });

  React.useEffect(() => {
    if ( status === Statuses.Success ) onHide();
    // eslint-disable-next-line
  }, [status]);

  return (
    <Modal show={show} onHide={onHide}>
      <form onSubmit={onSubmit} noValidate>
        <Modal.Header closeButton>
          <Modal.Title>{`${membershipType && membershipType.id ? 'Update' : 'Create'}`} membership type</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Input
            controlRef={register({ required: isRequired })}
            label="Name" id="name" name="name"
            required={true}
            errorText={errors.name ? errors.name.message : null}
          />

          <Textarea
            controlRef={register({ required: isRequired })}
            label="Description" id="description" name="description"
            rows={4}
            required={true}
            errorText={errors.name ? errors.name.message : null}
          />

          <div className="row row-16">
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="price">Price&nbsp;*</label>
                <div className="input-group input-group-sm">
                  <div className="input-group-prepend">
                    <div className="input-group-text">$</div>
                  </div>
                  <input
                    ref={register({ required: isRequired })}
                    className={`form-control form-control-sm ${ errors.price && 'is-invalid' }`}
                    id="price" name="price" type="number"
                    required
                  />
                  {errors.price ? <div className="invalid-feedback">{errors.price.message}</div> : null }
                </div>
              </div>
            </div>
            <div className="col-6">
              {/* <div className="form-group">
                <label htmlFor="tax">Tax&nbsp;*</label>
                <div className="input-group input-group-sm">
                  <div className="input-group-prepend">
                    <div className="input-group-text">%</div>
                  </div>
                  <input
                    ref={register({ required: isRequired })}
                    className={`form-control form-control-sm ${ errors.tax && 'is-invalid' }`}
                    id="tax" name="tax" type="number"
                    required
                  />
                  {errors.tax ? <div className="invalid-feedback">{errors.tax.message}</div> : null }
                </div>
              </div> */}
              <div className="form-group">
                <label htmlFor="validityPeriodDays">Validity Period Days&nbsp;*</label>
                <input
                  ref={register({ required: isRequired })}
                  className={`form-control form-control-sm ${ errors.validityPeriodDays && 'is-invalid' }`}
                  id="validityPeriodDays" name="validityPeriodDays" type="number"
                  required
                />
                {errors.validityPeriodDays ? <div className="invalid-feedback">{errors.validityPeriodDays.message}</div> : null }
              </div>
            </div>
          </div>

          {membershipType && membershipType.id ? (
            <Taxes membershipTypeId={membershipType.id} />
          ) : null}

        </Modal.Body>
        <Modal.Footer>
        <button
          className="btn btn-secondary btn-sm"
          type="button"
          onClick={onHide}
        >Cancel</button>
        <ButtonLoading
          loading={isLoading}
          type="submit"
        >Save</ButtonLoading>
      </Modal.Footer>
      </form>
    </Modal>
  )
}

export default MembershipTypeFormDialog;
