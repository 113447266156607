export const formControlsValue = {
  name: '',
  deliveryType: 'sameDay',
  deliveryDaysSpan: '',
  deliveryHour: '8',
  deliverySchedule: '',
  smsText: '',
  emailSubject: '',
  emailBody: '',
  inAppText: ''
}

export const formControlsOptions = {
  name: {
    id: 'Name',
    label: 'Name',
    name: 'name',
    placeholder: 'Name',
    rules: {
      isRequired: true
    }
  },
  deliverySchedule: {
    id: 'deliverySchedule',
    label: 'Template type',
    name: 'deliverySchedule',
    choises: [
      { value: '', label: 'Choose type' },
      { value: 'daily', label: 'Daily' },
      { value: 'arrival', label: 'Arrival' },
      { value: 'departure', label: 'Departure' }
    ],
    rules: {
      isRequired: true
    }
  },
  deliveryType: {
    id: 'deliveryType',
    label: 'Time offset',
    name: 'deliveryType',
    choises: [
      { value: 'sameDay', label: 'Same day' },
      { value: 'before', label: 'Before' },
      { value: 'after', label: 'After' }
    ]
  },
  deliveryDaysSpan: {
    id: 'deliveryDaysSpan',
    label: 'Days',
    name: 'deliveryDaysSpan',
    type: 'number',
    placeholder: 'Days',
    rules: {
      isRequired: true,
    }
  },
  deliveryHour: {
    id: 'deliveryHour',
    label: 'Delivery hour',
    name: 'deliveryHour',
    value: '8',
    choises: [...Array(24).keys()].map(i => ({
      value: i,
      label: `${i}:00`
    })),
    rules: {
      isRequired: true,
    }
  },
  smsText: {
    id: 'text',
    label: 'SMS text',
    name: 'smsText',
    placeholder: 'SMS text',
    rules: {
      isRequired: true,
    }
  },
  emailSubject: {
    id: 'subject',
    label: 'Subject',
    name: 'emailSubject',
    placeholder: 'Subject',
    rules: {
      isRequired: true,
    }
  },
  emailBody: {
    id: 'body',
    label: 'Body',
    name: 'emailBody',
    placeholder: 'Body',
    rules: {
      isRequired: true,
    }
  },
  inAppText: {
    id: 'inAppText',
    label: 'InApp text',
    name: 'inAppText',
    placeholder: 'InApp text',
    rules: {
      isRequired: true,
    }
  },
}