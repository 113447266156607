import React from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
// 
import AccountsPage from './AccountsPage';

type IProps = {

}

const AcccountManagerRouting:React.FC<IProps> = () => {
  const {path} = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/accounts`} component={AccountsPage} />
      <Redirect from={path} to={`${path}/accounts`} />
    </Switch>
  )
}

export default AcccountManagerRouting;
