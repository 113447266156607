import React from 'react';
// 
import TaxesCreate from './TaxesCreate';
import TaxesList from './TaxesList';

type Props = {
  bookingPropertyId?: number;
  inventoryGroupId?: number;
  membershipTypeId?: number;
};

const Taxes:React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      <TaxesCreate {...props} />
      <TaxesList {...props} />
    </React.Fragment>
  )
}

export default Taxes;
