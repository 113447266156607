import { Reducer } from 'redux';
import {
  IStaffState,
  StaffActionTypes,
  FETCH_LIST, FETCH_LIST_SUCCESS,
  CREATE, CREATE_SUCCESS,
  UPDATE, UPDATE_SUCCESS,
  ENABLE, ENABLE_SUCCESS,
  DISABLE, DISABLE_SUCCESS,
  DELETE, DELETE_SUCCESS,
  SET_STATUS
} from './staff.types';
import Statuses from 'types/statuses';

export default class StaffReducer {
  private static readonly _initialState:IStaffState = {
    items: null,
    isFetching: false,
    isLoading: false,
    status: Statuses.Initial
  };

  public static reducer:Reducer<IStaffState, StaffActionTypes> = (
    state = StaffReducer._initialState,
    action
  ) => {
    switch (action.type){
      // Fetch list
      case FETCH_LIST:
        return { ...state, isFetching: true };
      case FETCH_LIST_SUCCESS:
        return { ...state, items: action.payload.items, isFetching: false };
      // Create
      case CREATE:
        return { ...state, isLoading: true };
      case CREATE_SUCCESS:
        console.log(action.payload);
        return {
          ...state,
          items: state.items
            ? [...state.items, action.payload.staff]
            : state.items
          ,
          isLoading: false
        };
      // Update
      case UPDATE:
        return { ...state, isLoading: true };
      case UPDATE_SUCCESS:
        return {
          ...state,
          items: state.items
            ? state.items.map(item => item.id === action.payload.staff.id ? action.payload.staff : item)
            : state.items
          ,
          isLoading: false
        }
      // Enable
      case ENABLE:
        return state;
      case ENABLE_SUCCESS:
        return {
          ...state,
          items: state.items &&
            state.items.map(item =>
              item.id === action.payload.id ? {...item, enabled: true} : item
            )
        };
      // Disable
      case DISABLE:
        return state;
      case DISABLE_SUCCESS:
        return {
          ...state,
          items: state.items &&
            state.items.map(item =>
              item.id === action.payload.id ? {...item, enabled: false} : item
            )
        };
      // Delete
      case DELETE:
        return state;
      case DELETE_SUCCESS:
        return {
          ...state,
          items: state.items &&
            state.items.filter(item => item.id !== action.payload.id)
        };
      // Default
      case SET_STATUS:
        const hasError:boolean = action.payload.status === Statuses.Error;
        return {
          ...state,
          isFetching: hasError ? false : state.isFetching,
          isLoading: hasError ? false : state.isLoading,
          status: action.payload.status
        };
      default:
        return state;
    }
  }

}