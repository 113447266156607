import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
// Redux
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import MapPoiActions from 'store/map-poi/map-poi.actions';
import { getIsLoading, getStatus } from 'store/map-poi/map-poi.selectors';
import { IMapPoi, IMapPoiData } from 'store/map-poi/map-poi.types';
import Statuses from 'types/statuses';
// Bootstrap
import Modal from 'react-bootstrap/Modal';
// Components
import GoogleMap from 'components/GoogleMap';
import { Input } from 'components/Controls';
import { ButtonLoading } from 'app/components/Buttons';
// Utilities
import { isRequired } from 'utilities/validation';

type IProps = {
  // Props
  marker?: IMapPoi | null;
  show: boolean;
  onHide: () => void;
  // State
  isLoading: boolean;
  status: Statuses;
  // Dispatch
  createMapPoi: (data:IMapPoiData) => void;
  updateMapPoi: (id:number, data:IMapPoiData) => void;
}

const MapPoiFormDialog:React.FC<IProps> = ({
  // Props
  marker, show, onHide,
  // State
  isLoading, status,
  // Dispatch
  createMapPoi, updateMapPoi
}) => {
  const { id:mapId } = useParams<any>();

  console.log(marker);

  const { register, handleSubmit, errors, setValue, watch } = useForm<any>({
    defaultValues: {
      name: marker?.name || '',
      lat: marker?.options.lat || null,
      lng: marker?.options.lng || null
    }
  });

  const onSubmit = (data:any) => {
    const { name, lat, lng } = data;
    const newData:any = { name, options: {
      lat: Number(lat),
      lng: Number(lng)
    } }
    if ( marker && marker.id ){
      updateMapPoi(marker.id, newData);
    } else {
      newData['mapId'] = Number(mapId);
      createMapPoi(newData);
    }
  }

  useEffect(() => {
    if ( status === 'Success' ) onHide();
    // eslint-disable-next-line
  }, [status]);

  const handleClick = (e:any) => {
    setValue('lat', e.latLng.lat());
    setValue('lng', e.latLng.lng());
  }

  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>{`${marker?.id ? 'Update' : 'Create'}`} marker</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Input
            controlRef={register({ required: isRequired })}
            label="Name" id="name" name="name"
            required={true}
            errorText={errors.name ? errors.name.message : null}
          />

          <div className="form-group">
            <input
              ref={register({ required: isRequired })}
              className={`${ errors.lat ? 'is-invalid' : null }`}
              type="hidden"
              name="lat"
            />
            <input
              ref={register({ required: isRequired })}
              className={`${ errors.lng ? 'is-invalid' : null }`}
              type="hidden"
              name="lng"
            />
            {errors.lat ? <div className="invalid-feedback">Marker is required</div> : null}
          </div>

          <GoogleMap
            marker={{
              lat: Number(watch('lat')),
              lng: Number(watch('lng')),
            }}
            containerClassName="google-map-dialog"
            onClick={handleClick}
            center={marker ? {
              lat: marker.options.lat,
              lng: marker.options.lng,
            } : null}
          />

        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary btn-sm"
            type="button"
            onClick={onHide}
          >Cancel</button>
          <ButtonLoading
            loading={isLoading}
            type="submit"
          >Save</ButtonLoading>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

MapPoiFormDialog.defaultProps = {
  marker: null,
  show: false,
  onHide: () => null
};

const mapStateToProps = (state:any) => ({
  isLoading: getIsLoading(state),
  status: getStatus(state)
});

const mapDispatchToProps = (dispatch:Dispatch) => ({
  createMapPoi: (data:IMapPoiData) => dispatch(MapPoiActions.create(data)),
  updateMapPoi: (id:number, data:IMapPoiData) => dispatch(MapPoiActions.update(id, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(MapPoiFormDialog);
