import React, {useEffect} from 'react';
// Redux
import { connect } from 'react-redux';
import { ProductCategoryActions } from 'app/store/product-category/product-category.actions';
// Bootstrap
import Modal from 'react-bootstrap/Modal';
// Components
import ButtonLoading from 'app/components/Buttons/ButtonLoading';
// Hooks
import useForm from 'app/hooks/useForm';

const validation = values => {
  const errors = {};
  if ( !values.name ) errors['name'] = 'This field is required';
  return errors;
}

const ProductCategoryFormModal = ({
  // Props
  productCategory, show, onHide,
  // State
  status,
  // Dispatch
  createProductCategory, updateProductCategory
}) => {
  const callback = () => {
    const {name} = values;
    if ( productCategory && productCategory.id ){
      updateProductCategory(productCategory.id, { name });
    } else {
      createProductCategory({ name });
    }
  }

  const [values, errors, handleChange, handleSubmit] = useForm(callback, {
    name: productCategory.name || ''
  }, validation);

  useEffect(() => {
    if ( status === 'Success' ) onHide();
    // eslint-disable-next-line
  }, [status]);

  return (
    <Modal show={show} onHide={onHide}>
      <form onSubmit={handleSubmit}>
        <Modal.Header>
          {(productCategory && productCategory.id)
            ? `Update product category: ${productCategory.name}`
            : 'Create product category'
          }
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="name">Name&nbsp;*</label>
            <input
              className={`form-control form-control-sm ${ errors.name && 'is-invalid' }`}
              id="name" name="name" type="text"
              value={values.name}
              onChange={handleChange}
            />
            {errors.name && <div className="invalid-feedback">{errors.name}</div>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-secondary btn-sm"
            type="button"
            onClick={onHide}
          >Cancel</button>
          <ButtonLoading
            type="submit"
            loading={status === 'Loading'}
          >Save</ButtonLoading>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

ProductCategoryFormModal.defaultProps = {
  productCategory: {}
}

const mapStateToProps = ({ ProductCategoryState }) => ({
  status: ProductCategoryState.status
});

const mapDispatchToProps = dispatch => ({
  createProductCategory: data => dispatch(ProductCategoryActions.create(data)),
  updateProductCategory: (productCategoryId, data) => dispatch(ProductCategoryActions.update(productCategoryId, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductCategoryFormModal);
