import { Reducer } from 'redux';
import {
  IMapState,
  MapActionTypes,
  FETCH_LIST, FETCH_LIST_SUCCESS,
  FETCH_BY_ID, FETCH_BY_ID_SUCCESS,
  CREATE, CREATE_SUCCESS,
  UPDATE, UPDATE_SUCCESS,
  DELETE, DELETE_SUCCESS,
  ENABLE, ENABLE_SUCCESS,
  DISABLE, DISABLE_SUCCESS,
  SET_AS_MAIN, SET_AS_MAIN_SUCCESS,
  SET_MAP_OPTIONS, SET_STATUS
} from './map.types';
import Statuses from 'types/statuses';

export default class MapReducer {
  private static readonly _initialState:IMapState = {
    item: null,
    items: null,
    isFetching: false,
    isLoading: false,
    status: Statuses.Initial
  }

  public static reducer:Reducer<IMapState, MapActionTypes> = (
    state = MapReducer._initialState,
    action
  ) => {
    switch (action.type){
      // Fetch list
      case FETCH_LIST:
        return {...state, isFetching: true};
      case FETCH_LIST_SUCCESS:
        return {...state, items: action.payload.items, isFetching: false};
      // Fetch by id
      case FETCH_BY_ID:
        return {...state, isFetching: true};
      case FETCH_BY_ID_SUCCESS:
        return {...state, item: action.payload.item, isFetching: false};
      // Create
      case CREATE:
        return {...state, isLoading: true};
      case CREATE_SUCCESS:
        return {
          ...state,
          items: state.items ? [...state.items, action.payload.item] : state.items,
          isLoading: false
        };
      // Update
      case UPDATE:
        return {...state, isLoading: true};
      case UPDATE_SUCCESS:
        return {
          ...state,
          item: state.item ? action.payload.item : state.item,
          items: state.items
            ? state.items.map(
                item =>
                  item.id === action.payload.item.id
                  ? action.payload.item
                  : item
              )
            : state.items,
          isLoading: false
        };
      // Delete
      case DELETE:
        return {...state, isLoading: true};
      case DELETE_SUCCESS:
        return {
          ...state,
          items: state.items
            ? state.items.filter(item => item.id !== action.payload.id)
            : state.items,
          isLoading: false
        };
      // Enable
      case ENABLE:
        return state;
      case ENABLE_SUCCESS:
        return {
          ...state,
          items: state.items
            ? state.items.map(
                item =>
                  item.id === action.payload.id
                  ? {...item, enabled: true}
                  : item
              )
            : state.items
        }
      // Disable
      case DISABLE:
        return state;
      case DISABLE_SUCCESS:
        return {
          ...state,
          items: state.items
            ? state.items.map(
                item =>
                  item.id === action.payload.id
                  ? {...item, enabled: false}
                  : item
              )
            : state.items
        }
      // Set as main
      case SET_AS_MAIN:
        return state;
      case SET_AS_MAIN_SUCCESS:
        return {
          ...state,
          items: state.items
            ? state.items.map(
                item =>
                  item.id === action.payload.id
                  ? {...item, main: true}
                  : {...item, main: false}
              )
            : state.items
        }
      // Default
      case SET_MAP_OPTIONS:
        return {
          ...state,
          item: state.item ? {...state.item, options: {...state.item.options, ...action.payload.options}} : state.item
        }
      case SET_STATUS:
        const hasError:boolean = action.payload.status === Statuses.Error;
        return {
          ...state,
          isFetching: hasError ? false : state.isFetching,
          isLoading: hasError ? false : state.isLoading,
          status: action.payload.status
        };
      default:
        return state;
    }
  }
}
