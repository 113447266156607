import { useState, useEffect } from 'react';

export default (initialState, callback) => {
  const [state, setState] = useState(initialState);
  useEffect(() => {
    callback(state)
    // eslint-disable-next-line
  }, [state]);
  return [state, setState];
};
