import fetcher from '../../utilities/fetcher';

const apiURL = '/admin/reservations';
const headers = {
  'Content-Type': 'application/json'
};

export const ReservationService = {
  // Fetch reservations
  fetchReservations: (bookingId, params = {}) => {
    const searchParams = new URLSearchParams();
    searchParams.append('bookingPropertyId', bookingId);
    Object.keys(params).forEach(key => {
      if ( params[key] ) searchParams.append(key, params[key]);
    });
    return fetcher(`${apiURL}?${searchParams}`);
  },
  // Fetch available for assigning rooms
  fetchAvailableBookingItemsForAssigning: (includeAssignedBookingPropertyItems, reservationId, reservationCategoryId) => {
    const params = new URLSearchParams();
    // Worst ever
    params.append('includeAssignedBookingPropertyItems', includeAssignedBookingPropertyItems);
    if ( reservationCategoryId ) params.append('reservationCategoryId', reservationCategoryId);
    return fetcher(`${apiURL}/${reservationId}/bookingPropertyItems/availableForAssigning?${params}`);
  },
  // Create
  create: data => {
    return fetcher(apiURL, {
      method: 'POST',
      body: JSON.stringify(data),
      headers
    });
  },
  // Assign reservation
  assign: (reservationId, bookingItemIds) => {
    return fetcher(`${apiURL}/${reservationId}/bookingPropertyItems/assign`, {
      method: 'PUT',
      body: JSON.stringify(bookingItemIds),
      headers
    });
  },
  // Unassign reservation
  unassign: (reservationId, bookingItemIds) => {
    return fetcher(`${apiURL}/${reservationId}/bookingPropertyItems/unassign`, {
      method: 'PUT',
      body: JSON.stringify(bookingItemIds),
      headers
    });
  },
  // Actions
  // Check in
  checkIn: (reservationId, sendCheckInNotification = false) => {
    const params = new URLSearchParams();
    if ( sendCheckInNotification ) params.append('sendCheckInNotification', sendCheckInNotification);
    return fetcher(`${apiURL}/${reservationId}/actions/checkIn?${params}`, {
      method: 'POST',
      headers
    });
  },
  // Check out
  checkOut: reservationId => {
    return fetcher(`${apiURL}/${reservationId}/actions/checkOut`, {
      method: 'POST',
      headers
    });
  },
  // Refund
  refund: (reservationId, data) => {
    const searchParams = new URLSearchParams();
    Object.keys(data).forEach(key => {
      if ( data[key] ) searchParams.append(key, data[key]);
    })
    return fetcher(`${apiURL}/${reservationId}/actions/refund?${searchParams}`, {
      method: 'POST',
      headers
    })
  },
  // Remaining payment
  remainingPayment: (reservationId, params) => {
    const searchParams = new URLSearchParams();
    Object.keys(params).forEach(key => {
      if ( params[key] ) searchParams.append(key, params[key]);
    });
    return fetcher(`${apiURL}/${reservationId}/actions/remainingPayment?${searchParams}`, {
      method: 'POST',
      headers
    });
  },
  // Cancel
  cancel: reservationId => {
    return fetcher(`${apiURL}/${reservationId}/actions/cancel`, {
      method: 'POST',
      headers
    });
  },

  // Send receipt
  sendReceipt: (reservationId, transactionId, email) => {
    const searchParams = new URLSearchParams();
    searchParams.append('email', email);
    return fetcher(`${apiURL}/${reservationId}/sendReceipt/${transactionId}?${searchParams}`, {
      method: 'POST'
    }, () => null);
  },

  // Change end date
  changeEndDate: (reservationId, params) => {
    const searchParams = new URLSearchParams();
    Object.keys(params).forEach(key => {
      if ( params[key] ) searchParams.append(key, params[key]);
    });
    return fetcher(`${apiURL}/${reservationId}/actions/changeEndDate?${searchParams}`, {
      method: 'POST',
      headers
    });
  },
  // Shift
  shift: (reservationId, params) => {
    const searchParams = new URLSearchParams();
    Object.keys(params).forEach(key => {
      if ( params[key] ) searchParams.append(key, params[key]);
    });
    return fetcher(`${apiURL}/${reservationId}/actions/shift?${searchParams}`, {
      method: 'POST',
      headers
    });
  },
  // Transactions
  fetchTransactions: reservationId => {
    return fetcher(`${apiURL}/${reservationId}/transactions`)
  }
}
