import fetcher from 'app/utilities/fetcher';

const apiURL = '/admin/store/productCategories';
const headers = {
  'Content-Type': 'application/json'
};

export const ProductCategoryService = {
  // Fetch list
  fetchList: () => fetcher(apiURL),
  // Create
  create: data => {
    return fetcher(`${apiURL}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(data)
    })
  },
  // Update
  update: (productCategoryId, data) => {
    return fetcher(`${apiURL}/${productCategoryId}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(data)
    });
  },
  // Delete
  delete: productCategoryId => {
    return fetcher(`${apiURL}/${productCategoryId}`, {
      method: 'DELETE'
    }, () => null);
  }
}
