import React, { useState } from 'react';
// Store
import { connect } from 'react-redux';
// Layouts
import CamperFormDialog from 'dialogs/CamperForm.dialog';
import { UsersNotificationModal } from 'app/layouts/Modals';

const CampersSendMessage = ({
  // Props
  // State
  checkedUserIds
  // Dispatch
}) => {
  const [showCamperFormDialog, setShowCamperFormDialog] = useState(false);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [showNotificationBulkModal, setShowNotificationBulkModal] = useState(false);

  return (
    <>
      <div className="form-group | text-right">
        <button
          className="btn btn-primary btn-sm | mr-2"
          type="button"
          onClick={() => setShowCamperFormDialog(true)}
        >Create user</button>
        <button
          className="btn btn-primary btn-sm | mr-2"
          type="button"
          onClick={() => setShowNotificationModal(true)}
          disabled={checkedUserIds.length === 0}
        >Send message</button>
        <button
          className="btn btn-primary btn-sm"
          type="button"
          onClick={() => setShowNotificationBulkModal(true)}
        >Send bulk message</button>
      </div>
      {showCamperFormDialog &&
        <CamperFormDialog
          show={showCamperFormDialog}
          onHide={() => setShowCamperFormDialog(false)}
        />
      }
      {showNotificationModal &&
        <UsersNotificationModal
          show={showNotificationModal}
          onHide={() => setShowNotificationModal(false)}
        />
      }
      {showNotificationBulkModal &&
        <UsersNotificationModal
          bulk={true}
          show={showNotificationBulkModal}
          onHide={() => setShowNotificationBulkModal(false)}
        />
      }
    </>
  )
}

const mapStateToProps = ({ UserState }) => ({
  checkedUserIds: UserState.checkedUserIds
})

export default connect(mapStateToProps)(CampersSendMessage);
