import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
// Redux
import { connect } from 'react-redux';
import * as ReservationCategorySelectors from 'app/store/reservation-category/reservation-category.selectors';

const ReservationTimeSlots = ({
  // Props
  data, setData,
  // State
  booking, availableCategories,
}) => {
  const { customerDataFields } = booking;
  const [ selectedSlot, setSelectedSlot ] = useState('');
  const [ selectedCategoryId, setSelectedCagegoryId ] = useState('');

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = formData => {
    let hasFormData = Object.keys(formData).length !== 0;
    let items = [];
    let totalPrice = 0;
    let slot = null;
    if ( selectedSlot ){
      // let day = dayjs(data.start).format('dddd').toLocaleLowerCase();
      let category = availableCategories.find(c => c.id === selectedCategoryId);
      totalPrice = Number(data.hours) * category.hourlyCategoryPrice;
      slot = selectedSlot;
      items = [{
        reservationCategoryId: selectedCategoryId
      }];
    }
    if ( hasFormData ){
      let slotData = Object.keys(formData).reduce((acc, cur) => {
        let [ name ] = cur.split('_');
        if ( formData[cur] ) acc[name] = formData[cur];
        return acc;
      }, {});
      items[0]['customerData'] = Object.keys(slotData).map(key => ({
        name: key,
        value: slotData[key]
      }));
    }

    setData(prevState => ({
      ...prevState,
      formVisible: true,
      totalPrice,
      slot,
      items
    }));
  }

  const handleClick = (categoryId, slot) => {
    setSelectedCagegoryId(categoryId);
    setSelectedSlot(slot);
  }

  const handleReselect = () => {
    setData(prevState => ({
      ...prevState,
      formVisible: false,
      items: []
    }));
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ position: 'relative' }}>
        {(data.formVisible && data.items.length !== 0) ? <span style={{
          position: 'absolute',
          top: 0, left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255,255,255,0.35)',
          zIndex: 2
        }}></span> : null}
        <hr />
        <span style={{
          display: 'block',
          fontWeight: 700,
          marginTop: '8px',
          marginBottom: '8px'
        }}>Select reservation category(ies)</span>
        <ul className="list-group mb-2">
          {availableCategories.map((category) => (
            <li key={`category-${category.id}`} className="list-group-item | d-flex align-items-start">
              <div className="list-group-item--body">
                <h6 className="mb-0">{category.name}</h6>
                <small className="d-block">{category.description}</small>
                {category.slots.map((slot, i) => (
                  <div
                    key={`category-${category.id}-slot-${i}`}
                    className={`badge badge-${`${selectedCategoryId}-${selectedSlot}` === `${category.id}-${slot.start}-${slot.end}` ? 'primary' : 'secondary'} | mt-1 mr-1`}
                    style={{cursor: 'pointer'}}
                    onClick={() => handleClick(category.id, `${slot.start}-${slot.end}`)}
                  >{`${slot.start} - ${slot.end}`}</div>
                ))}
                {customerDataFields && customerDataFields.length !== 0 ? (
                  <>
                    {(selectedCategoryId && selectedSlot && selectedCategoryId === category.id) ? (
                      <>
                        {customerDataFields.map((field, i) => (
                          <div key={`category-${category.id}-field-${i}`} className="row row-8">
                            <small className="col-sm-5 col-form-label" style={{ fontSize: '0.875rem' }}>{field.name}&nbsp;{field.required ? '*' : ''}</small>
                            <div className="col-7">
                              <div className="form-group">
                                <input
                                  ref={register({
                                    required: {
                                      value: field.required,
                                      message: 'This field is required'
                                    }
                                  })}
                                  defaultValue=""
                                  className={`
                                    form-control form-control-sm
                                    ${ errors[`${field.name}_${category.id}_field_${i}`] ? 'is-invalid' : null
                                  }`}
                                  id={`${field.name}-${category.id}-field-${i}`}
                                  name={`${field.name}_${category.id}_field_${i}`}
                                />
                                {errors[`${field.name}_${category.id}_field_${i}`]
                                  ? <div className="invalid-feedback">{errors[`${field.name}_${category.id}_field_${i}`]['message']}</div>
                                  : null
                                }
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : null}
                  </>
                ) : null}
              </div>
            </li>
          ))}
        </ul>
        {(!data.formVisible || data.items.length === 0) ? (
          <div className="text-right">
            <button
              className="btn btn-primary btn-sm"
              type="submit"
            >Fill form</button>
          </div>
        ) : null}
      </form>
      {(data.formVisible && data.items.length !== 0) ? (
        <div className="text-right">
          <button
            className="btn btn-outline-primary btn-sm"
            type="button"
            onClick={handleReselect}
          >Reselect slot</button>
        </div>
      ) : null }
    </>
  )
}

const mapStateToProps = ({ BookingState, ReservationCategoryState }) => ({
  booking: BookingState.booking,

  availableCategories: ReservationCategorySelectors.getAvailableCategories(ReservationCategoryState),
});

export default connect(mapStateToProps)(ReservationTimeSlots);
