import { Action } from 'redux';
// Types
import Statuses from 'types/statuses';
// Modules
import { IMembershipType } from 'models/MembershipType';
import { IState } from './membership-type.models';

// Messages
export enum MembershipTypeMessages {
  CreateMembershipType = 'Membership type was created',
  UpdateMembershipType = 'Membership type was updated',
  DeleteMembershipType = 'Membership type was deleted',
};

// Types
export enum MembershipTypeTypes {
  FetchMembershipTypes = '@@membershipType/Fetch membership types',
  FetchMembershipTypesSuccess = '@@membershipType/Fetch membership types success',

  FetchMembershipType = '@@membershipType/Fetch membership type',
  FetchMembershipTypeSuccess = '@@membershipType/Fetch membership type success',

  CreateMembershipType = '@@membershipType/Create membership type',
  CreateMembershipTypeSuccess = '@@membershipType/Create membership type success',

  UpdateMembershipType = '@@membershipType/Update membership type',
  UpdateMembershipTypeSuccess = '@@membershipType/Update membership type success',

  DeleteMembershipType = '@@membershipType/Delete membership type',
  DeleteMembershipTypeSuccess = '@@membershipType/Delete membership type success',

  EnableMembershipType = '@@membershipType/Enable membership type',
  EnableMembershipTypeSuccess = '@@membershipType/Enable membership type success',

  DisableMembershipType = '@@membershipType/Disable membership type',
  DisableMembershipTypeSuccess = '@@membershipType/Disable membership type success',

  ResetDefaultWaiver = '@@membershipType/Reset default waiver',
  ResetDefaultWaiverSuccess = '@@membershipType/Reset default waiver success',

  SetDefaultWaiver = '@@membershipType/Set default waiver',
  SetDefaultWaiverSuccess = '@@membershipType/Set default waiver success',

  SetStatus = '@@membershipType/Set status',
  SetInitialField = '@@membershipType/Set initial field'
};

// Actions
interface FetchMembershipTypes extends Action<typeof MembershipTypeTypes.FetchMembershipTypes> {};
interface FetchMembershipTypesSuccess extends Action<typeof MembershipTypeTypes.FetchMembershipTypesSuccess> {
  payload: { membershipTypes:Array<IMembershipType> }
};

interface FetchMembershipType extends Action<typeof MembershipTypeTypes.FetchMembershipType> {
  payload: { membershipTypeId:number }
};
interface FetchMembershipTypeSuccess extends Action<typeof MembershipTypeTypes.FetchMembershipTypeSuccess> {
  payload: { membershipType:IMembershipType }
};

interface CreateMembershipType extends Action<typeof MembershipTypeTypes.CreateMembershipType> {
  payload: { data:any }
};
interface CreateMembershipTypeSuccess extends Action<typeof MembershipTypeTypes.CreateMembershipTypeSuccess> {
  payload: { membershipType:IMembershipType }
};

interface UpdateMembershipType extends Action<typeof MembershipTypeTypes.UpdateMembershipType> {
  payload: { membershipTypeId:number, data:any }
};
interface UpdateMembershipTypeSuccess extends Action<typeof MembershipTypeTypes.UpdateMembershipTypeSuccess> {
  payload: { membershipType:IMembershipType }
};

interface DeleteMembershipType extends Action<typeof MembershipTypeTypes.DeleteMembershipType> {
  payload: { membershipTypeId:number }
};
interface DeleteMembershipTypeSuccess extends Action<typeof MembershipTypeTypes.DeleteMembershipTypeSuccess> {
  payload: { membershipTypeId:number }
};

interface EnableMembershipType extends Action<typeof MembershipTypeTypes.EnableMembershipType> {
  payload: { membershipTypeId:number }
};
interface EnableMembershipTypeSuccess extends Action<typeof MembershipTypeTypes.EnableMembershipTypeSuccess> {
  payload: { membershipTypeId:number }
};

interface DisableMembershipType extends Action<typeof MembershipTypeTypes.DisableMembershipType> {
  payload: { membershipTypeId:number }
};
interface DisableMembershipTypeSuccess extends Action<typeof MembershipTypeTypes.DisableMembershipTypeSuccess> {
  payload: { membershipTypeId:number }
};

interface ResetDefaultWaiver extends Action<typeof MembershipTypeTypes.ResetDefaultWaiver> {
  payload: { membershipTypeId:number }
};
interface ResetDefaultWaiverSuccess extends Action<typeof MembershipTypeTypes.ResetDefaultWaiverSuccess> {
  payload: { membershipType:IMembershipType }
};

interface SetDefaultWaiver extends Action<typeof MembershipTypeTypes.SetDefaultWaiver> {
  payload: { membershipTypeId:number }
};
interface SetDefaultWaiverSuccess extends Action<typeof MembershipTypeTypes.SetDefaultWaiverSuccess> {
  payload: { membershipType:IMembershipType }
};

interface SetStatus extends Action<typeof MembershipTypeTypes.SetStatus> {
  payload: { status:Statuses }
}
interface SetInitialField extends Action<typeof MembershipTypeTypes.SetInitialField> {
  payload: { field:keyof IState }
}

export type MembershipTypeActionTypes =
  FetchMembershipTypes | FetchMembershipTypesSuccess |
  FetchMembershipType | FetchMembershipTypeSuccess |
  CreateMembershipType | CreateMembershipTypeSuccess |
  UpdateMembershipType | UpdateMembershipTypeSuccess |
  DeleteMembershipType | DeleteMembershipTypeSuccess |
  EnableMembershipType | EnableMembershipTypeSuccess |
  DisableMembershipType | DisableMembershipTypeSuccess |
  ResetDefaultWaiver | ResetDefaultWaiverSuccess |
  SetDefaultWaiver | SetDefaultWaiverSuccess |
  SetStatus | SetInitialField
;
