import create from './create-action';
import Types from '../types/product.types';

class ProductActions {
  // Fetch all
  fetchAll = (params, isMore) => create(Types.FETCH_ALL, { params, isMore })
  fetchAllSuccess = (data, params, isMore) => create(Types.FETCH_ALL_SUCCESS, { data, params, isMore })
  fetchAllFailure = () => create(Types.FETCH_ALL_FAILURE)
  // Fetch by id
  fetchById = productId => create(Types.FETCH_BY_ID, { productId })
  fetchByIdSuccess = product => create(Types.FETCH_BY_ID_SUCCESS, { product })
  fetchByIdFailure = () => create(Types.FETCH_BY_ID_FAILURE)
  // Create
  create = product => create(Types.CREATE, { product })
  createSuccess = product => create(Types.CREATE_SUCCESS, { product })
  createFailure = () => create(Types.CREATE_FAILURE)
  // Update
  update = product => create(Types.UPDATE, { product })
  updateSuccess = product => create(Types.UPDATE_SUCCESS, { product })
  updateFailure = () => create(Types.UPDATE_FAILURE)
  // Delete
  delete = product => create(Types.DELETE, { product })
  deleteSuccess = productId => create(Types.DELETE_SUCCESS, { productId })
  deleteFailure = () => create(Types.DELETE_FAILURE)
  // Upload image
  uploadImage = (productId, image) => create(Types.UPLOAD_IMAGE, { productId, image })
  uploadImageSuccess = image => create(Types.UPLOAD_IMAGE_SUCCESS, { image })
  uploadImageFailure = () => create(Types.UPLOAD_IMAGE_FAILURE)
  // Delete image
  deleteImage = productId => create(Types.DELETE_IMAGE, { productId })
  deleteImageSuccess = () => create(Types.DELETE_IMAGE_SUCCESS)
  deleteImageFailure = () => create(Types.DELETE_IMAGE_FAILURE)
  // Other
  setInitialState = () => create(Types.SET_INITIAL_STATE)
  setIsFetched = () => create(Types.SET_IS_FETCHED)

  setFilter = (name, value) => create(Types.SET_FILTER, { name, value })
};

const actions = new ProductActions();
export default actions;
