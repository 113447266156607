import { AppActionTypes } from './app.types';

const audio = document.getElementById('pn-audio');
audio.volume = 1;

export const AppActions = {
  addPushNotification: pushNotification => {
    audio.play();

    const { data, notification } = pushNotification;
    const nextPushNotification = {
      ...notification,
      id: new Date().getTime(),
      data
    }
    return ({
      type: AppActionTypes.AddPushNotification,
      pushNotification: nextPushNotification
    });
  },
  removePushNotification: pushNotificationId => ({
    type: AppActionTypes.RemovePushNotifcation,
    pushNotificationId
  }),

  // Fetch admin data
  fetchAdminData: () => ({
    type: AppActionTypes.FetchAdminData
  }),
  fetchAdminDataSuccess: () => ({
    type: AppActionTypes.FetchAdminDataSuccess
  }),
  // Fetch manager data
  fetchManagerData: () => ({
    type: AppActionTypes.FetchManagerData
  }),
  fetchManagerDataSuccess: () => ({
    type: AppActionTypes.FetchManagerDataSuccess
  }),
  // Fetch account manager data
  fetchAccountManagerData: () => ({
    type: AppActionTypes.FetchAccountManagerData
  }),
  fetchAccountManagerDataSuccess: () => ({
    type: AppActionTypes.FetchAccountManagerDataSuccess
  }),

  // Set status
  setStatusSuccess: () => ({
    type: AppActionTypes.StatusSuccess
  }),
  setStatusError: () => ({
    type: AppActionTypes.StatusError
  }),
  setStatusInitial: () => ({
    type: AppActionTypes.StatusInitial
  })
}
