import React, { useState } from 'react';
// Dialogs
import WaiverFormDialog from 'dialogs/WaiverForm.dialog';

const OrderListItemItem = ({
  item
}) => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);

  return (
    <>
      <h6 className="mb-0">
        <small className="text-muted">
          {item.product.name}</small>&nbsp;x&nbsp;<small><b>{item.quantity} - {`$${item.price}`}</b>
        </small>
        {item.waiverId ? (
          <button
            className="btn btn-link btn-sm"
            type="button"
            onClick={handleShow}
          >Waiver Accepted</button>
        ) : null}
      </h6>
      {show ? (
        <WaiverFormDialog
          isPreview={true}
          waiverId={item.waiverId}
          show={show}
          onHide={handleHide}
        />
      ) : null}
    </>
  )
}

export default OrderListItemItem;
