import React from 'react';
import { useForm } from 'react-hook-form';
// Types
import Statuses from 'types/statuses';
// Models
import { ITax } from 'models/Tax';
// Dispatch
import { useDispatch, useSelector } from 'react-redux';
// Actions
import TaxesActions from 'store/taxes/taxes.actions';
// Selectors
import { selectIsLoading, selectStatus, selectTax } from 'store/taxes/taxes.selectors';
// Bootstrap
import Modal from 'react-bootstrap/Modal';
// Components
import { Input, Select } from 'components/Controls';
import { ButtonLoading } from 'app/components/Buttons';
// Utilities
import { isRequired } from 'utilities/validation';

type Props = {
  show: boolean;
  onHide: () => void;
  taxId?: number;
  bookingPropertyId?: number;
  inventoryGroupId?: number;
  membershipTypeId?: number;
};

const TaxFormDialog:React.FC<Props> = ({
  // Props
  show, onHide, taxId, bookingPropertyId, inventoryGroupId, membershipTypeId
}) => {
  // Dispatch
  const dispatch = useDispatch();
  // State
  const tax:ITax | null = useSelector(selectTax);
  const isLoading:boolean = useSelector(selectIsLoading);
  const status:Statuses = useSelector(selectStatus);

  const { register, handleSubmit, errors, watch, reset } = useForm<any>({
    defaultValues: {
      name: '',
      type: '',
      value: ''
    }
  });

  React.useEffect(() => {
    if ( taxId ){
      dispatch(TaxesActions.fetchTax(taxId));
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if ( tax ){
      const { name, type, value } = tax;
      reset({ name, type, value });
    }
    // eslint-disable-next-line
  }, [tax]);

  React.useEffect(() => {
    if ( status === Statuses.Success ) onHide();
    // eslint-disable-next-line
  }, [status]);

  const onSubmit = handleSubmit((data:any) => {
    const nextData:any = {...data};
    if ( taxId ){
      dispatch(TaxesActions.updateTax(taxId, nextData));
    } else {
      if ( bookingPropertyId ) nextData['bookingPropertyId'] = bookingPropertyId;
      if ( inventoryGroupId ) nextData['inventoryGroupId'] = inventoryGroupId;
      if ( membershipTypeId ) nextData['membershipTypeId'] = membershipTypeId;
      dispatch(TaxesActions.createTax(nextData));
    }
  });

  const watchType = watch('type');
  const isDisabled = Boolean(taxId && !tax);

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdropClassName="modal-confirmation-backdrop"
      className="modal-confirmation"
    >
      <Modal.Header closeButton>
        <Modal.Title>{taxId ? 'Update' : 'Create'} tax</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form noValidate>

          <Input
            controlRef={register({ required: isRequired })}
            label="Name" id="name" name="name"
            required={true}
            errorText={errors.name ? errors.name.message : null}
            disabled={isDisabled}
          />

          <div className="row row-8">
            <div className="col-12 col-lg-6">
              <Select
                controlRef={register({ required: isRequired })}
                label="Type" id="type" name="type"
                required={true}
                errorText={errors.type ? errors.type.message : null}
                options={[
                  { value: '', label: 'All' },
                  { value: 'percentage', label: 'Percentage' },
                  { value: 'absolute', label: 'Absolute' }
                ]}
                disabled={isDisabled}
              />
            </div>
            <div className="col-12 col-lg-6">
              <label htmlFor="value">Value</label>
              <div className="input-group input-group-sm mb-2">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    {watchType === 'percentage' ? '%' : '$'}
                  </div>
                </div>
                <input
                  ref={register({ required: isRequired })}
                  className={`form-control form-control-sm ${ errors.value ? 'is-invalid' : null }`}
                  id="value" name="value" type="number"
                  disabled={isDisabled}
                />
              </div>
              {errors.value ? <div className="invalid-feedback">{errors.value.message}</div> : null}
            </div>
          </div>

        </form>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-secondary btn-sm"
          type="button"
          onClick={onHide}
        >Cancel</button>
        <ButtonLoading
          loading={isLoading}
          type="button"
          onClick={onSubmit}
        >Save</ButtonLoading>
      </Modal.Footer>
    </Modal>
  )
}

export default TaxFormDialog;
