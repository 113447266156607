// Types
import Statuses from 'types/statuses';
import { TaxesTypes, TaxesActionTypes } from './taxes.types';
// Models
import { ITax } from 'models/Tax';

export default class TaxesActions {
  // Fetch taxes
  public static fetchTaxes = (params:any):TaxesActionTypes => ({
    type: TaxesTypes.FetchTaxes, payload: { params }
  });
  public static fetchTaxesSuccess = (taxes:Array<ITax>):TaxesActionTypes => ({
    type: TaxesTypes.FetchTaxesSuccess, payload: { taxes }
  });
  // Fetch tax
  public static fetchTax = (taxId:number):TaxesActionTypes => ({
    type: TaxesTypes.FetchTax, payload: { taxId }
  });
  public static fetchTaxSuccess = (tax:ITax):TaxesActionTypes => ({
    type: TaxesTypes.FetchTaxSuccess, payload: { tax }
  });
  // Create tax
  public static createTax = (data:any):TaxesActionTypes => ({
    type: TaxesTypes.CreateTax, payload: { data }
  });
  public static createTaxSuccess = (tax:ITax):TaxesActionTypes => ({
    type: TaxesTypes.CreateTaxSuccess, payload: { tax }
  });
  // Update tax
  public static updateTax = (taxId:number, data:any):TaxesActionTypes => ({
    type: TaxesTypes.UpdateTax, payload: { taxId, data }
  });
  public static updateTaxSuccess = (tax:ITax):TaxesActionTypes => ({
    type: TaxesTypes.UpdateTaxSuccess, payload: { tax }
  });
  // Delete tax
  public static deleteTax = (taxId:number):TaxesActionTypes => ({
    type: TaxesTypes.DeleteTax, payload: { taxId }
  });
  public static deleteTaxSuccess = (taxId:number):TaxesActionTypes => ({
    type: TaxesTypes.DeleteTaxSuccess, payload: { taxId }
  });
  // Default
  // Set status
  public static setStatus = (status:Statuses):TaxesActionTypes => ({
    type: TaxesTypes.SetStatus, payload: { status }
  })
}