export const isRequired = {
  value: true,
  message: 'This field is required'
};

export const isMax = value => {
  return {
    value,
    message: `Max value is ${value}`
  }
}

export const isDate = {
  value: /^\d{4}-\d{2}-\d{2}$/,
  message: 'Date is not valid'
}

export const isNumeric = {
  value: /\+?[0-9]$/im,
  message: 'Should be number'
}

export const isEmail = {
  // eslint-disable-next-line
  value: /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i,
  message: 'E-mail is not valid'
};

export const startValidation = value => {
  const today = new Date();
  today.setDate(today.getDate() - 1);
  if ( !value ) return true;
  // Start date can`t be in past
  return !(new Date(value).getTime() - today.getTime() < 0);
}

export const sameAsStart = (value, startValue) => {
  if ( !value ) return true;
  // End date can`t be same as start
  return new Date(startValue).getTime() !== new Date(value).getTime();
}

export const lessThanStart = (value, startValue) => {
  if ( !value ) return true;
  // End date can`t be less than start date
  return !(new Date(value).getTime() - new Date(startValue).getTime() < 0);
}

export const lessThanStartTime = (value, startValue) => {
  if ( !value ) return true;
  // End time can`t be less than end date
  const [endHours, endMinutes] = typeof value === 'string' ? value.split(':') : [];
  const [startHours, startMinutes] = typeof startValue === 'string' ? startValue.split(':') : [];
  const endDate = new Date();
  endDate.setHours(endHours, endMinutes, 0);
  const startDate = new Date();
  startDate.setHours(startHours, startMinutes, 0);
  return (endDate !== startDate && endDate > startDate) ? true : 'End time can`t be the less or equal start time';
}
