import { ReservationCategoryActionTypes } from './reservation-category.types';

export const ReservationCategoryActions = {
  // Fetch reservations
  fetchCategories: (bookingId, enabled) => ({
    type: ReservationCategoryActionTypes.FetchCategories,
    bookingId,
    enabled
  }),
  fetchCategoriesSuccess: categories => ({
    type: ReservationCategoryActionTypes.FetchCategoriesSuccess,
    categories
  }),
  // Fetch available reservations
  fetchAvailableCategories: (bookingId, quantity, start, end, hourQuantity = 0) => ({
    type: ReservationCategoryActionTypes.FetchAvailableCategories,
    bookingId,
    quantity,
    start,
    end,
    hourQuantity
  }),
  fetchAvailableCategoriesSuccess: categories => ({
    type: ReservationCategoryActionTypes.FetchAvailableCategoriesSuccess,
    categories
  }),

  clearAvailableCategories: () => ({
    type: ReservationCategoryActionTypes.ClearAvailableCategories
  }),

  // Create
  create: data => ({
    type: ReservationCategoryActionTypes.Create,
    data
  }),
  createSuccess: category => ({
    type: ReservationCategoryActionTypes.CreateSuccess,
    category
  }),
  // Update
  update: (categoryId, data) => ({
    type: ReservationCategoryActionTypes.Update,
    categoryId,
    data
  }),
  updateSuccess: category => ({
    type: ReservationCategoryActionTypes.UpdateSuccess,
    category
  }),
  // Delete
  delete: category => ({
    type: ReservationCategoryActionTypes.Delete,
    category
  }),
  deleteSuccess: categoryId => ({
    type: ReservationCategoryActionTypes.DeleteSuccess,
    categoryId
  }),
  // Actions
  // Enable
  enable: categoryId => ({
    type: ReservationCategoryActionTypes.Enable,
    categoryId
  }),
  enableSuccess: category => ({
    type: ReservationCategoryActionTypes.EnableSuccess,
    category
  }),
  // Disable
  disable: categoryId => ({
    type: ReservationCategoryActionTypes.Disable,
    categoryId
  }),
  disableSuccess: category => ({
    type: ReservationCategoryActionTypes.DisableSuccess,
    category
  }),
  // Image
  // Update
  updateImages: (categoryId, assetsIds) => ({
    type: ReservationCategoryActionTypes.UpdateImages,
    categoryId,
    assetsIds
  }),
  updateImagesSuccess: category => ({
    type: ReservationCategoryActionTypes.UpdateImagesSuccess,
    category
  }),

  // Increment available categories
  incrementAvailableCategory: categoryId => ({
    type: ReservationCategoryActionTypes.IncrementAvailableCategory,
    categoryId
  }),
  decrementAvailableCategory: categoryId => ({
    type: ReservationCategoryActionTypes.DecrementAvailableCategory,
    categoryId
  }),

  // Filter
  setFilter: (filterType, filterValue) => ({
    type: ReservationCategoryActionTypes.Filter,
    filterType,
    filterValue
  }),
  clearFilter: () => ({
    type: ReservationCategoryActionTypes.ClearFilter
  }),

  // Set status
  setStatusSuccess: () => ({
    type: ReservationCategoryActionTypes.StatusSuccess
  }),
  setStatusError: () => ({
    type: ReservationCategoryActionTypes.StatusError
  }),
  setStatusInitial: () => ({
    type: ReservationCategoryActionTypes.StatusInitial
  })
}