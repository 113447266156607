import React from 'react';

// Form controls
export const Input = ({
  submitted = false,
  label = '', id, type = 'text', name, value, required, readonly = false, min = null, max = null,
  reference = () => {}, onChange = () => {}, onFocus = () => {},
}) => {
  let isInvalid = false;
  let message = '';
  if ( submitted ){
    if ( required && value === '' ){
      isInvalid = true;
      message = 'This field is required';
    }
    if ( !isInvalid ){
      if ( type === 'number' ){
        if ( isNaN(value) ){
          if ( value !== String(Number(value)) ){
            isInvalid = true;
            message = 'This field should be numeric';
          }
        }
        if ( min !== null && min > Number(value) ){
          isInvalid = true;
          message = `It can't be less than ${min}`;
        }
        if ( max !== null && max < Number(value) ){
          isInvalid = true;
          message = `It can't be more than ${max}`;
        }
      }
      if ( type === 'email' && !/^[a-zA-Z0-9._.+.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value) ){
        isInvalid = true;
        message = 'E-mail is not valid';
      }
      if ( type === 'date' && !/^\d{4}-\d{2}-\d{2}$/.test(value) ){
        isInvalid = true;
        message = 'Date is not valid';
      }
      if ( type === 'time' && !/^\d{2}:\d{2}$/.test(value) ){
        isInvalid = true;
        message = 'Time is not valid';
      }
    }
  }
  return (
    <div className="form-group">
      {label &&
        <label htmlFor={id}>{label}{required && <span>&nbsp;*</span>}</label>
      }
      <input
        ref={reference}
        className={`form-control form-control-sm ${ isInvalid && 'is-invalid' }`}
        id={id}
        type={type}
        name={name}
        value={value}
        max={max}
        placeholder={label}
        required={required}
        readOnly={readonly}
        onChange={onChange}
        onFocus={onFocus}
      />
      {isInvalid && <div className="invalid-feedback">{message}</div>}
    </div>
  )
}

export const Textarea = ({
  submitted = false,
  label = '', id, name, value, required, rows = 5,
  reference = () => {}, onChange = () => {}, onFocus = () => {},
}) => {
  let isInvalid = false;
  let message = '';
  if ( submitted ){
    if ( required && value === '' ){
      isInvalid = true;
      message = 'This field is required';
    }
  }
  return (
    <div className="form-group">
      {label &&
        <label htmlFor={id}>{label}{required && <span>&nbsp;*</span>}</label>
      }
      <textarea
        ref={reference}
        className={`form-control form-control-sm ${ isInvalid && 'is-invalid' }`}
        id={id}
        name={name}
        value={value}
        placeholder={label}
        required={required}
        rows={rows}
        onChange={onChange}
        onFocus={onFocus}
      ></textarea>
      {isInvalid && <div className="invalid-feedback">{message}</div>}
    </div>
  )
}

export const Select = ({
  submitted = false,
  label = '', id, name, value, onChange, required, options = []
}) => {
  let isInvalid = false;
  let message = '';
  if ( submitted ){
    if ( required && value === '' ){
      isInvalid = true;
      message = 'This field is required';
    }
  }
  return (
    <div className="form-group">
      {label &&
        <label htmlFor={id}>{label}{required && <span>&nbsp;*</span>}</label>
      }
       <select
        className={`form-control form-control-sm ${ isInvalid && 'is-invalid' }`}
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        required={required}
      >
        {options.map((o, i) => (
          <option key={i} value={o.value}>{o.label}</option>
        ))}
      </select>
      {isInvalid && <div className="invalid-feedback">{message}</div>}
    </div>
  )
}

export const Checkbox = ({
  label = '', id, name, checked, onChange
}) => {
  return (
    <div className="form-group form-check">
      <input
        className="form-check-input"
        type="checkbox"
        name={name}
        id={id}
        checked={checked}
        onChange={onChange}
      />
      <label
        className="form-check-label"
        htmlFor={id}
      >{label}</label>
    </div>
  )
}
