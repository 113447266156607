import { call, put, takeLatest } from 'redux-saga/effects';
// Store
import * as LayoutActions from '../../actions/layout.actions';
import { BookingItemActionTypes, ReservationMessageTypes } from './booking-item.types';
import { BookingItemActions } from './booking-item.actions';
import { BookingItemService } from './booking-item.service';

export default function* (){
  yield takeLatest(BookingItemActionTypes.FetchBookingItems, _fetchBookingItems);
  yield takeLatest(BookingItemActionTypes.Create, _create);
  yield takeLatest(BookingItemActionTypes.Update, _update);
  yield takeLatest(BookingItemActionTypes.Delete, _delete);
  yield takeLatest(BookingItemActionTypes.MarkAsFree, _markAsFree);
  yield takeLatest(BookingItemActionTypes.MarkAsOutOfService, _markAsOutOfService);
  yield takeLatest(BookingItemActionTypes.AssignRemotelockDevices, _assignRemotelockDevices);
}

function* _fetchBookingItems(action){
  try {
    const { bookingId, status } = action;
    const response = yield call(BookingItemService.fetchBookingItems, bookingId, status);
    yield put(BookingItemActions.fetchBookingItemsSuccess(response));
    yield put(BookingItemActions.setStatusInitial());
  } catch(error){
    console.log(error);
    yield put(BookingItemActions.setStatusError());
  }
}

function* _create(action){
  try {
    const { data } = action;
    const response = yield call(BookingItemService.create, data);
    yield put(BookingItemActions.createSuccess(response));
    yield put(BookingItemActions.setStatusSuccess());
    yield put(BookingItemActions.setStatusInitial());
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: ReservationMessageTypes.Create
    }));
  } catch(error){
    console.log(error);
    yield put(BookingItemActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _update(action){
  try {
    const { bookingItemId, data } = action;
    const response = yield call(BookingItemService.update, bookingItemId, data);
    yield put(BookingItemActions.updateSuccess(response));
    yield put(BookingItemActions.setStatusSuccess());
    yield put(BookingItemActions.setStatusInitial());
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: ReservationMessageTypes.Update
    }));
  } catch(error){
    console.log(error);
    yield put(BookingItemActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _delete(action){
  try {
    const { bookingItem } = action;
    if ( window.confirm(`Are you sure you want to delete booking property item: ${bookingItem.identifier}?`) ){
      yield call(BookingItemService.delete, bookingItem.id);
      yield put(BookingItemActions.deleteSuccess(bookingItem.id));
      yield put(LayoutActions.addNotification({
        type: 'success',
        message: ReservationMessageTypes.Delete
      }));
    }
  } catch(error){
    console.log(error);
    yield put(BookingItemActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

// Actions
function* _markAsFree(action){
  try {
    const { bookingItemId } = action;
    const response = yield call(BookingItemService.markAsFree, bookingItemId);
    yield put(BookingItemActions.markAsFreeSuccess(response));
  } catch(error){
    console.log(error);
    yield put(BookingItemActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _markAsOutOfService(action){
  try {
    const { bookingItemId } = action;
    const response = yield call(BookingItemService.markAsOutOfService, bookingItemId);
    yield put(BookingItemActions.markAsOutOfServiceSuccess(response));
  } catch(error){
    console.log(error);
    yield put(BookingItemActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _assignRemotelockDevices(action){
  try {
    const { bookingItemId, remotelockDevicesIds } = action.payload;
    const response = yield call(BookingItemService.assignRemotelockDevises, bookingItemId, remotelockDevicesIds);
    console.log(response);
    yield put(BookingItemActions.assignRemotelockDevicesSuccess(response));
    yield put(BookingItemActions.setStatusSuccess());
    yield put(BookingItemActions.setStatusInitial());
  } catch(error){
    console.log(error);
    yield put(BookingItemActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}
