import { call, put, takeLatest } from 'redux-saga/effects';
import Statuses from 'types/statuses';
import * as LayoutActions from 'app/actions/layout.actions';
import MapService from './map.service';
import MapActions from './map.actions';
import MapPoiActions from 'store/map-poi/map-poi.actions';
import {
  MapMessages,
  FETCH_LIST, FETCH_BY_ID, CREATE, UPDATE, DELETE, ENABLE, DISABLE, SET_AS_MAIN
} from './map.types'

export default function* (){
  yield takeLatest(FETCH_LIST, _fetchList);
  yield takeLatest(FETCH_BY_ID, _fetchById);
  yield takeLatest(CREATE, _create);
  yield takeLatest(UPDATE, _update);
  yield takeLatest(DELETE, _delete);
  yield takeLatest(ENABLE, _enable);
  yield takeLatest(DISABLE, _disable);
  yield takeLatest(SET_AS_MAIN, _setAsMain);
}

function* _fetchList(){
  try {
    const { parseBody } = yield call(MapService.fetchList);
    yield put(MapActions.fetchListSuccess(parseBody));
  } catch(error:any){
    yield put(MapActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _fetchById(action:any){
  try {
    const { id } = action.payload;
    const { parseBody } = yield call(MapService.fetchById, id);
    yield put(MapActions.fetchByIdSuccess(parseBody));
    yield put(MapPoiActions.setList(parseBody.pois || []));
  } catch(error:any){
    yield put(MapActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _create(action:any){
  try {
    const { data } = action.payload;
    const { parseBody } = yield call(MapService.create, data);
    yield put(MapActions.createSuccess(parseBody));
    yield put(MapActions.setStatus(Statuses.Success));
    yield put(MapActions.setStatus(Statuses.Initial));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: MapMessages.Create
    }));
  } catch(error:any){
    yield put(MapActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _update(action:any){
  try {
    const { id, data } = action.payload;
    const { parseBody } = yield call(MapService.update, id, data);
    yield put(MapActions.updateSuccess(parseBody));
    yield put(MapActions.setStatus(Statuses.Success));
    yield put(MapActions.setStatus(Statuses.Initial));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: MapMessages.Update
    }));
  } catch(error:any){
    yield put(MapActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _delete(action:any){
  try {
    const { id } = action.payload;
    yield call(MapService.delete, id);
    yield put(MapActions.deleteSuccess(id));
    yield put(MapActions.setStatus(Statuses.Success));
    yield put(MapActions.setStatus(Statuses.Initial));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: MapMessages.Delete
    }));
  } catch(error:any){
    yield put(MapActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _enable(action:any){
  try {
    const { id } = action.payload;
    yield call(MapService.enable, id);
    yield put(MapActions.enableSuccess(id));
  } catch(error:any){
    yield put(MapActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _disable(action:any){
  try {
    const { id } = action.payload;
    yield call(MapService.disable, id);
    yield put(MapActions.disableSuccess(id));
  } catch(error:any){
    yield put(MapActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _setAsMain(action:any){
  try {
    const { id } = action.payload;
    yield call(MapService.setAsMain, id);
    yield put(MapActions.setAsMainSuccess(id));
  } catch(error:any){
    yield put(MapActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

