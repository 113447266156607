// Types
import Statuses from 'types/statuses';
import { RemotelockDeviceTypes, RemotelockDeviceActionTypes } from './remotelock-device.types';
// Models
import { IRemotelockDevice, IRemotelockDeviceAudit } from 'models/RemotelockDevice';
import { IRemotelockDeviceState } from './remotelock-device.models';

export default class RemotelockDeviceActions {
  // Fetch remotelock devices
  public static fetchRemotelockDevices = ():RemotelockDeviceActionTypes => ({
    type: RemotelockDeviceTypes.FetchRemotelockDevices
  });
  public static fetchRemotelockDevicesSuccess = (remotelockDevices:Array<IRemotelockDevice>):RemotelockDeviceActionTypes => ({
    type: RemotelockDeviceTypes.FetchRemotelockDevicesSuccess, payload: { remotelockDevices }
  });
  // Fetch remotelock device audits
  public static fetchRemotelockDeviceAudits = (deviceId:number, params:any = {}):RemotelockDeviceActionTypes => ({
    type: RemotelockDeviceTypes.FetchRemotelockDeviceAudits, payload: { deviceId, params }
  });
  public static fetchRemotelockDeviceAuditsSuccess = (audits:Array<IRemotelockDeviceAudit>, auditTotal:number):RemotelockDeviceActionTypes => ({
    type: RemotelockDeviceTypes.FetchRemotelockDeviceAuditsSuccess, payload: { audits, auditTotal }
  });
  // Unlock device
  public static unlockDevice = (deviceId:number):RemotelockDeviceActionTypes => ({
    type: RemotelockDeviceTypes.UnlockDevice, payload: { deviceId }
  });
  public static unlockDeviceSuccess = ():RemotelockDeviceActionTypes => ({
    type: RemotelockDeviceTypes.UnlockDeviceSuccess
  });
  // Default
  // Set status
  public static setStatus = (status:Statuses):RemotelockDeviceActionTypes => ({
    type: RemotelockDeviceTypes.SetStatus, payload: { status }
  });
  // Set initial field
  public static setInitialField = (field:keyof IRemotelockDeviceState):RemotelockDeviceActionTypes => ({
    type: RemotelockDeviceTypes.SetInitialField, payload: { field }
  });
}