import { takeLatest, call, put } from 'redux-saga/effects';
// Service
import * as LayoutActions from 'app/actions/layout.actions';
import { ContactMessageActionTypes } from './contact-message.types';
import { ContactMessageService } from './contact-message.service';
import { ContactMessageActions } from './contact-message.actions';

// Fetch list
function* _fetchList(action){
  try {
    const { params } = action;
    const response = yield call(ContactMessageService.fetchList, params);
    yield put(ContactMessageActions.fetchListSuccess(response));
  } catch(error){
    console.log(error);
    yield put(ContactMessageActions.setStatusError());
  }
}

// Fetch list conversation
function* _fetchListConversation(action){
  try {
    const { userId } = action;
    const response = yield call(ContactMessageService.fetchListConversation, userId);
    yield put(ContactMessageActions.fetchListConversationSuccess(response));
  } catch(error){
    console.log(error);
    yield put(ContactMessageActions.setStatusError());
  }
}

// Fetch order by id
function* _markAsRead(action){
  try {
    const { messageId } = action;
    yield call(ContactMessageService.markAsRead, messageId);
    yield put(ContactMessageActions.markAsReadSuccess(messageId));
    yield put(ContactMessageActions.setStatusSuccess());
    yield put(ContactMessageActions.setStatusInitial());
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: 'The messages marked as read'
    }));
  } catch(error){
    console.log(error);
    yield put(ContactMessageActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

// Send in app
function* _sendInApp(action){
  try {
    const { userId, text } = action;
    const response = yield call(ContactMessageService.sendInApp, userId, text);
    yield put(ContactMessageActions.sendInAppSuccess(response));
    yield put(ContactMessageActions.setStatusSuccess());
    yield put(ContactMessageActions.setStatusInitial());
  } catch(error){
    console.log(error);
    yield put(ContactMessageActions.setStatusError());
  }
}

export default function* (){
  yield takeLatest(ContactMessageActionTypes.FetchList, _fetchList);
  yield takeLatest(ContactMessageActionTypes.FetchListConversation, _fetchListConversation);
  yield takeLatest(ContactMessageActionTypes.MarkAsRead, _markAsRead);
  yield takeLatest(ContactMessageActionTypes.SendInApp, _sendInApp);
}
