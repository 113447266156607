import { call, put, takeLatest } from 'redux-saga/effects';
// Types
import Statuses from 'types/statuses';
import { TaxesMessages, TaxesTypes } from './taxes.types';
// Api
import TagesAPI from './taxes.api';
// Actions
import * as LayoutActions from 'app/actions/layout.actions';
import TaxesActions from './taxes.actions';

export default function* (){
  yield takeLatest(TaxesTypes.FetchTaxes, _fetchTaxes);
  yield takeLatest(TaxesTypes.FetchTax, _fetchTax);
  yield takeLatest(TaxesTypes.CreateTax, _createTax);
  yield takeLatest(TaxesTypes.UpdateTax, _updateTax);
  yield takeLatest(TaxesTypes.DeleteTax, _deleteTax);
}

function* _fetchTaxes(action:any){
  try {
    const { params } = action.payload;
    const { parseBody:taxes } = yield call(TagesAPI.fetchTaxes, params);
    yield put(TaxesActions.fetchTaxesSuccess(taxes));
  } catch(error:any){
    console.log(error);
    yield put(TaxesActions.setStatus(Statuses.Error));
  }
}

function* _fetchTax(action:any){
  try {
    const { taxId } = action.payload;
    const { parseBody:tax } = yield call(TagesAPI.fetchTax, taxId);
    yield put(TaxesActions.fetchTaxSuccess(tax));
  } catch(error:any){
    console.log(error);
    yield put(TaxesActions.setStatus(Statuses.Error));
  }
}

function* _createTax(action:any){
  try {
    const { data } = action.payload;
    const { parseBody:tax } = yield call(TagesAPI.createTax, data);
    yield put(TaxesActions.createTaxSuccess(tax));
    yield put(TaxesActions.setStatus(Statuses.Success));
    yield put(TaxesActions.setStatus(Statuses.Initial));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: TaxesMessages.Create
    }));
  } catch(error:any){
    yield put(TaxesActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _updateTax(action:any){
  try {
    const { taxId, data } = action.payload;
    const { parseBody:tax } = yield call(TagesAPI.updateTax, taxId, data);
    yield put(TaxesActions.updateTaxSuccess(tax));
    yield put(TaxesActions.setStatus(Statuses.Success));
    yield put(TaxesActions.setStatus(Statuses.Initial));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: TaxesMessages.Update
    }));
  } catch(error:any){
    yield put(TaxesActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _deleteTax(action:any){
  try {
    const { taxId } = action.payload;
    yield call(TagesAPI.deleteTax, taxId);
    yield put(TaxesActions.deleteTaxSuccess(taxId));
    yield put(TaxesActions.setStatus(Statuses.Success));
    yield put(TaxesActions.setStatus(Statuses.Initial));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: TaxesMessages.Delete
    }));
  } catch(error:any){
    yield put(TaxesActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}
