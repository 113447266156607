import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// Store
import { connect } from 'react-redux';
import ProductActions from '../../../actions/product.actions';
// Components
import { DataLoading } from '../../../components/Loadings';
// 
import ProductFormBuilder from './ProductFormBuilder';

class ProductForm extends Component {
  componentDidMount(){
    const { match: { params } } = this.props;
    if ( params && params.productId ){
      this.props.fetchById(params.productId);
    }
  }

  componentDidUpdate(prevProps){
    const { match: { path }} = this.props;
    if ( this.props.isFormSuccess ){
      this.props.history.push(`/${path.split('/')[1]}/products`);
    }
  }

  componentWillUnmount(){
    this.props.setInitialState();
  }

  render() {
    const { match: { path }, product, isFetching } = this.props;
    if ( isFetching ) return <DataLoading />
    return (
      <div className="area area-product-form">
        <div className="container-fluid">
          <div className="area-header">
            <Link
              className="btn btn-link btn-sm pl-0 pr-0"
              to={`/${path.split('/')[1]}/products`}
            >
              <i className="fa fa-chevron-left mr-1" aria-hidden="true"></i>
              Products
            </Link>
            <h1 className="area-title">
              {product && product.id
                ? `Update product: ${product.name}`
                : 'Create product item'
              }
            </h1>
          </div>
          <div className="area-container">
            <ProductFormBuilder />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ ProductState }) => ({
    product: ProductState.product,
    isFetching: ProductState.isFetching,
    isFormSuccess: ProductState.isFormSuccess
  }),
  dispatch => ({
    fetchById: productId => dispatch(ProductActions.fetchById(productId)),
    setInitialState: () => dispatch(ProductActions.setInitialState())
  })
)(ProductForm);
