import { call, put, takeLatest } from 'redux-saga/effects';
// Types
import Statuses from 'types/statuses';
import { WaiverMessages, WaiverTypes } from './waiver.types';
// Api
import WaiverAPI from './waiver.api';
// Actions
import * as LayoutActions from 'app/actions/layout.actions';
import WaiverActions from './waiver.actions';

export default function* (){
  yield takeLatest(WaiverTypes.FetchWaivers, _fetchWaivers);
  yield takeLatest(WaiverTypes.FetchWaiver, _fetchWaiver);
  yield takeLatest(WaiverTypes.CreateWaiver, _createWaiver);
  yield takeLatest(WaiverTypes.UpdateWaiver, _updateWaiver);
  yield takeLatest(WaiverTypes.DeleteWaiver, _deleteWaiver);
}

function* _fetchWaivers(action:any){
  try {
    const { params } = action.payload;
    const { parseBody:waivers } = yield call(WaiverAPI.fetchWaivers, params);
    yield put(WaiverActions.fetchWaiversSuccess(waivers));
  } catch(error:any){
    console.log(error);
    yield put(WaiverActions.setStatus(Statuses.Error));
  }
}

function* _fetchWaiver(action:any){
  try {
    const { waiverId } = action.payload;
    const { parseBody:waiver } = yield call(WaiverAPI.fetchWaiver, waiverId);
    yield put(WaiverActions.fetchWaiverSuccess(waiver));
  } catch(error:any){
    console.log(error);
    yield put(WaiverActions.setStatus(Statuses.Error));
  }
}

function* _createWaiver(action:any){
  try {
    const { data } = action.payload;
    const { parseBody:waiver } = yield call(WaiverAPI.createWaiver, data);
    yield put(WaiverActions.createWaiverSuccess(waiver));
    yield put(WaiverActions.setStatus(Statuses.Success));
    yield put(WaiverActions.setStatus(Statuses.Initial));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: WaiverMessages.Create
    }));
  } catch(error:any){
    yield put(WaiverActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _updateWaiver(action:any){
  try {
    const { waiverId, data } = action.payload;
    const { parseBody:waiver } = yield call(WaiverAPI.updateWaiver, waiverId, data);
    yield put(WaiverActions.updateWaiverSuccess(waiver));
    yield put(WaiverActions.setStatus(Statuses.Success));
    yield put(WaiverActions.setStatus(Statuses.Initial));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: WaiverMessages.Update
    }));
  } catch(error:any){
    yield put(WaiverActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _deleteWaiver(action:any){
  try {
    const { waiverId } = action.payload;
    yield call(WaiverAPI.deleteWaiver, waiverId);
    yield put(WaiverActions.deleteWaiverSuccess(waiverId));
    yield put(WaiverActions.setStatus(Statuses.Success));
    yield put(WaiverActions.setStatus(Statuses.Initial));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: WaiverMessages.Delete
    }));
  } catch(error:any){
    yield put(WaiverActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}
