import { AvailabilityActionTypes } from './availability.types';

export const AvailabilityActions = {
  // Fetch list
  fetchList: bookingId => ({
    type: AvailabilityActionTypes.FetchList,
    bookingId
  }),
  fetchListSuccess: restrictions => ({
    type: AvailabilityActionTypes.FetchListSuccess,
    restrictions
  }),

  // Create
  create: data => ({
    type: AvailabilityActionTypes.Create,
    data
  }),
  createSuccess: restriction => ({
    type: AvailabilityActionTypes.CreateSuccess,
    restriction
  }),
  // Update
  update: (restrictionId, data) => ({
    type: AvailabilityActionTypes.Update,
    restrictionId,
    data
  }),
  updateSuccess: restriction => ({
    type: AvailabilityActionTypes.UpdateSuccess,
    restriction
  }),
  // Delete
  delete: restriction => ({
    type: AvailabilityActionTypes.Delete,
    restriction
  }),
  deleteSuccess: restrictionId => ({
    type: AvailabilityActionTypes.DeleteSuccess,
    restrictionId
  }),

  // Set status
  setStatusSuccess: () => ({
    type: AvailabilityActionTypes.StatusSuccess
  }),
  setStatusError: () => ({
    type: AvailabilityActionTypes.StatusError
  }),
  setStatusInitial: () => ({
    type: AvailabilityActionTypes.StatusInitial
  })
}