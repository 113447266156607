import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
import { BookingItemActions } from '../../../store/booking-item/booking-item.actions';
import { ReservationCategoryActions } from '../../../store/reservation-category/reservation-category.actions';
import * as BookingSelectors from '../../../store/booking/booking.selectors';
import * as BookingItemSelectors from '../../../store/booking-item/booking-item.selectors';
import * as ReservationCategorySelectors from '../../../store/reservation-category/reservation-category.selectors';
// Bootstrap
import { Modal } from 'react-bootstrap';
// Components
import { DataLoading } from '../../../components/Loadings';
// Hooks
import useForm from '../../../hooks/useForm';

const validate = values => {
  const errors = {};
  if ( !values.identifier ){
    errors['identifier'] = 'This field is required';
  }
  if ( !values.reservationCategoryId ){
    errors['reservationCategoryId'] = 'This field is required';
  }
  return errors;
}

const BookingItemFormModal = ({
  // Props
  show, onHide,
  // State
  booking, bookingItem, status, categories, categoryStatus,
  // Dispatch
  fetchCategories, createBookingItem, updateBookingItem
}) => {
  const { bookingId } = useParams();

  const callback = () => {
    if ( bookingItem && bookingItem.id ){
      updateBookingItem(bookingItem.id, values);
    } else {
      createBookingItem(values);
    }
  }

  const [values, errors, handleChange, handleSubmit] = useForm(callback, {
    identifier: bookingItem.identifier || '',
    reservationCategoryId: bookingItem.reservationCategoryId || '',
  }, validate);

  useEffect(() => {
    if ( !bookingItem || !bookingItem.id ){
      fetchCategories(bookingId);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( status === 'Success' ) onHide();
    // eslint-disable-next-line
  }, [status]);

  return (
    <Modal show={show} onHide={onHide}>
      <form onSubmit={handleSubmit}>
        <Modal.Header>
          {bookingItem.id
            ? `Update: ${bookingItem.identifier}`
            :
              booking.type === 'hotel'
              ? 'Create room'
              : 'Create boat slip'
            }
        </Modal.Header>
        <Modal.Body>
          {categoryStatus === 'Fetching'
            ? <DataLoading />
            :
              <>
                <div className="form-group">
                  <label htmlFor="identifier">Identifier&nbsp;*</label>
                  <input
                    className={`form-control form-control-sm ${ errors.identifier && 'is-invalid' }`}
                    id="identifier"
                    type="text"
                    name="identifier"
                    value={values.identifier}
                    onChange={handleChange}
                  />
                  {errors.identifier && <div className="invalid-feedback">{errors.identifier}</div>}
                </div>
                {(!bookingItem || !bookingItem.id) &&
                  <div className="form-group">
                    <label htmlFor="reservationCategoryId">Category&nbsp;*</label>
                    <select
                      className={`form-control form-control-sm ${ errors.reservationCategoryId && 'is-invalid' }`}
                      id="reservationCategoryId"
                      name="reservationCategoryId"
                      value={values.reservationCategoryId}
                      onChange={handleChange}
                    >
                      <option value="">Choose category</option>
                      {categories.map((category, index) => (
                        <option key={index} value={category.id}>{category.name}</option>
                      ))}
                    </select>
                    {errors.reservationCategoryId && <div className="invalid-feedback">{errors.reservationCategoryId}</div>}
                  </div>
                }
              </>
          }
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-secondary btn-sm"
            type="button"
            onClick={onHide}
          >Cancel</button>
          <button
            className="btn btn-primary btn-sm"
            type="submit"
          >Save</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

const mapStateToProps = ({ BookingState, BookingItemState, ReservationCategoryState }, ownProps) => ({
  booking: BookingSelectors.getBooking(BookingState),

  bookingItem: BookingItemSelectors.getBookingItemById(BookingItemState, ownProps.bookingItemId),
  status: BookingItemSelectors.getStatus(BookingItemState),

  categories: ReservationCategorySelectors.getCategories(ReservationCategoryState),
  categoryStatus: ReservationCategorySelectors.getStatus(ReservationCategoryState),
});

const mapDispatchToProps = dispatch => ({
  fetchCategories: bookingId => dispatch(ReservationCategoryActions.fetchCategories(bookingId)),
  createBookingItem: data => dispatch(BookingItemActions.create(data)),
  updateBookingItem: (bookingItemId, data) => dispatch(BookingItemActions.update(bookingItemId, data)),
});

BookingItemFormModal.defaultProps = {
  bookingItemId: null
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingItemFormModal);
