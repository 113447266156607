import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import config from 'config';
import { ButtonLoading } from 'app/components/Buttons';

const UnsubscribePage:React.FC = () => {
  const { search } = useLocation();
  const { hostname } = window.location;
  const domain = hostname.split('.').splice(-2).join('.');

  const { email, token }: any = search.slice(1).split('&').reduce((acc, cur) => {
    const [ name, value ] = cur.split('=');
    return { ...acc, [name]: value  }
  }, {});

  const [loading, setLoading] = useState<boolean>(false);
  const [unsubscribed, setUnsubscribed] = useState<boolean>(false);

  const unsubscribe = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${config.apiUrl}/api/v1/users/emailSubscriptions/unsubscribe`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${token}` }
      });

      if(!response.ok) throw Error(response.statusText);
      
      setUnsubscribed(true);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    return () => { setUnsubscribed(false) };
  }, []);

  if (unsubscribed) return (
    <div className='root-container is-open' style={{
      height: 'calc(100vh - 56px)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
    }}>
      <h4>You have been successfully unsubscribed</h4>
      <p>You can always subscribe back by updating email preferences on our profile page.</p>
    </div>
  )

  return (
    <div className='root-container is-open' style={{
      height: 'calc(100vh - 56px)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
    }}>
      <h2>Unsubscribe</h2>
      <p>{`Your email address ${email}`}</p>
      <p>{`Click unsubscribe button below to be excluded from ${domain} email campaigns.`}</p>
      <ButtonLoading loading={loading} onClick={unsubscribe}>Unsubscribe</ButtonLoading>
    </div>
  );
}

export default UnsubscribePage;
