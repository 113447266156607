import { FinancialReportActionTypes } from './financial-report.types';

export const FinancialReportActions = {
  // Download orders
  downloadOrders: (start, end) => ({
    type: FinancialReportActionTypes.DownloadOrders,
    start,
    end
  }),
  downloadOrdersSuccess: () => ({
    type: FinancialReportActionTypes.DownloadOrdersSuccess
  }),
  // Download reservations
  downloadReservations: (start, end) => ({
    type: FinancialReportActionTypes.DownloadReservations,
    start,
    end
  }),
  downloadReservationsSuccess: () => ({
    type: FinancialReportActionTypes.DownloadReservationsSuccess
  }),
  // Download membership
  downloadMemberships: (start, end) => ({
    type: FinancialReportActionTypes.DownloadMemberships,
    start,
    end
  }),
  downloadMembershipsSuccess: () => ({
    type: FinancialReportActionTypes.DownloadMembershipsSuccess
  }),
  // Fetch list summary
  fetchListSummary: (start, end) => ({
    type: FinancialReportActionTypes.FetchListSummary,
    start,
    end
  }),
  fetchListSummarySuccess: summary => ({
    type: FinancialReportActionTypes.FetchListSummarySuccess,
    summary
  }),

  // Filter
  setFilter: (filterType, filterValue) => ({
    type: FinancialReportActionTypes.SetFilter,
    filterType,
    filterValue
  }),
  resetFilter: () => ({
    type: FinancialReportActionTypes.ResetFilter
  }),

  // Set status
  setStatusSuccess: () => ({
    type: FinancialReportActionTypes.StatusSuccess
  }),
  setStatusError: () => ({
    type: FinancialReportActionTypes.StatusError
  }),
  setStatusInitial: () => ({
    type: FinancialReportActionTypes.StatusInitial
  })
}
