import HttpUtility from 'utilities/http.utility';

export default class MembershipAPI {
  private static _url:string = '/admin/memberships';
  private static _http:HttpUtility = new HttpUtility();
  // Fetch memberships
  public static async fetchMemberships(params:any):Promise<any> {
    const searchParams = new URLSearchParams();
    Object.keys(params).forEach((key:string) => {
      if ( params[key] !== '' ) searchParams.append(key, params[key]);
    });
    return MembershipAPI._http.get(`${MembershipAPI._url}?${searchParams}`);
  };
  // Fetch membership
  public static async fetchMembership(membershipId:number):Promise<any> {
    return MembershipAPI._http.get(`${MembershipAPI._url}/${membershipId}`);
  };
  // Fetch membership by number
  public static async fetchMembershipByNumber(membershipNumber:number):Promise<any> {
    return MembershipAPI._http.get(`${MembershipAPI._url}/getByMembershipNumber/${membershipNumber}`);
  };
  // Fetch membership transactions
  public static async fetchMembershipTransactions(membershipId:number):Promise<any> {
    return MembershipAPI._http.get(`${MembershipAPI._url}/${membershipId}/transactions`);
  };
  // Fetch membership visits
  public static async fetchMembershipVisits(membershipId:number, params:any):Promise<any> {
    const searchParams = new URLSearchParams();
    Object.keys(params).forEach((key:string) => {
      if ( params[key] ) searchParams.append(key, params[key]);
    });
    return MembershipAPI._http.get(`${MembershipAPI._url}/${membershipId}/visits?${searchParams}`);
  };
  // Check in membership
  public static async checkInMembership(membershipId:number):Promise<any> {
    return MembershipAPI._http.post(`${MembershipAPI._url}/${membershipId}/actions/checkIn`);
  };
  // Inactivate membership
  public static async inactivateMembership(membershipId:number, params:any):Promise<any> {
    const searchParams = new URLSearchParams();
    Object.keys(params).forEach((key:string) => {
      if ( params[key] ) searchParams.append(key, params[key]);
    });
    return MembershipAPI._http.post(`${MembershipAPI._url}/${membershipId}/actions/inactivate?${searchParams}`);
  };
  // Send membership receipt
  public static async sendMembershipReceipt(membershipId:number, transactionId:number, params:any):Promise<any> {
    const searchParams = new URLSearchParams();
    Object.keys(params).forEach((key:string) => {
      if ( params[key] ) searchParams.append(key, params[key]);
    });
    return MembershipAPI._http.post(`${MembershipAPI._url}/${membershipId}/sendReceipt/${transactionId}?${searchParams}`);
  };
}