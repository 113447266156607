import { BookingActionTypes } from './booking.types';

const initialState = {
  bookings: [],
  booking: {},
  filter: {
    search: ''
  },
  status: 'Initial'
}

export default (state = initialState, action) => {
  switch (action.type) {
    case BookingActionTypes.FetchBookings:
      return { ...state, bookings: initialState.bookings, status: 'Fetching' }
    case BookingActionTypes.FetchBookingsSuccess:
      return { ...state, bookings: action.bookings, status: 'Fetched' };
    case BookingActionTypes.FetchBookingById:
      return { ...state, booking: initialState.booking, status: 'Fetching' };
    case BookingActionTypes.FetchBookingByIdSuccess:
      return { ...state, booking: action.booking, status: 'Fetched' };

    case BookingActionTypes.CheckAvailability:
      return { ...state, status: 'Loading' };
    case BookingActionTypes.CheckAvailabilitySuccess:
      return { ...state, status: 'Loaded' };

    // Create
    case BookingActionTypes.Create:
      return { ...state, status: 'Loading' };
    case BookingActionTypes.CreateSuccess:
      return { ...state, bookings: [...state.bookings, action.booking], status: 'Loaded' };
    // Update
    case BookingActionTypes.Update:
      return { ...state, status: 'Loading' };
    case BookingActionTypes.UpdateSuccess:
      return {
        ...state,
        bookings: state.bookings.map(b =>
          b.id === action.booking.id
          ? action.booking
          : b
        ),
        status: 'Loaded'
      };
    // Delete
    case BookingActionTypes.Delete:
      return state;
    case BookingActionTypes.DeleteSuccess:
      return {
        ...state,
        bookings: state.bookings.filter(b => b.id !== action.bookingId)
      };
    // Actions
    // Enable/Disable
    case BookingActionTypes.Enable:
    case BookingActionTypes.Disable:
    case BookingActionTypes.SetDefaultWaiver:
    case BookingActionTypes.ResetDefaultWaiver:
      return state;
    case BookingActionTypes.EnableSuccess:
    case BookingActionTypes.DisableSuccess:
      const toNumber = Number(action.bookingId);
      return {
        ...state,
        bookings: state.bookings.map(
          b =>
            b.id === toNumber
            ? { ...b, enabled: !b.enabled }
            : b
        ),
      };
    case BookingActionTypes.SetDefaultWaiverSuccess:
    case BookingActionTypes.ResetDefaultWaiverSuccess:
      return { ...state, booking: action.booking };

    // Filter
    case BookingActionTypes.Filter:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.filterType]: action.filterValue
        }
      };
    case BookingActionTypes.ClearFilter:
      return { ...state, filter: initialState.filter };

    // Set status
    case BookingActionTypes.StatusSuccess:
      return { ...state, status: 'Success' };
    case BookingActionTypes.StatusError:
      return { ...state, status: 'Error' };
    case BookingActionTypes.StatusInitial:
      return { ...state, status: 'Initial' };
    case BookingActionTypes.Reset:
      return { ...state, ...initialState };
    default:
      return state;
  }
}