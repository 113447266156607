import React from 'react';
import { Link } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
import { UserActions } from '../store/user/user.actions';
import * as UserSelectors from '../store/user/user.selectors';
// Components
import { ButtonLoading } from '../components/Buttons';
// Hooks
import useForm from '../hooks/useForm';
// Utilities
import { forgotPasswordForm as validation } from '../utilities/validation';

const ForgotPasswordPage = ({
  // Props
  // State
  status,
  // Dispatch
  forgotPassword
}) => {
  const callback = () => forgotPassword(values.email);

  const [values, errors, handleChange, handleSubmit] = useForm(callback, {
    email: '',
  }, validation);

  return (
    <div className="area area-auth">
      <div className="container-fluid">
        <div className="area-container">
          <h1 className="area-title">Forgot password</h1>
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            {/* E-mail */}
            <div className="form-group">
              <label htmlFor="email">E-mail&nbsp;*</label>
              <input
                className={`form-control form-control-sm ${ errors.email ? 'is-invalid' : '' }`}
                id="email"
                name="email"
                value={values.email}
                onChange={handleChange}
              />
              {errors.email && <div className="invalid-feedback">{errors.email}</div>}
            </div>

            <div className="form-group">
              <ButtonLoading
                classes="btn btn-primary btn-sm btn-block"
                type="submit"
                loading={status === 'Loading' ? true : false}
              >Send password reset link</ButtonLoading>
              <Link
                className="btn btn-link btn-sm btn-block"
                to="/"
              >Login</Link>
            </div>

          </form>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ _UserState }) => ({
  status: UserSelectors.getStatus(_UserState)
});

const mapDispatchToProps = dispatch => ({
  forgotPassword: email => dispatch(UserActions.forgotPassword(email))
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);
