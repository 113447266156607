import React from 'react'

export const Input = ({
  // Props
  controlRef, defaultValue = '', id, label, name, type = 'text', required = false, errorText = null,
  ...props
}) => {
  const nextLabel = `${label}${required ? ' *' : ''}`;
  return (
    <div className="form-group">
      {label ? <label htmlFor={id}>{nextLabel}</label> : null}
      <input
        ref={controlRef} defaultValue={defaultValue}
        className={`form-control form-control-sm ${ errorText ? 'is-invalid' : null }`}
        id={id} name={name} type={type}
        {...props}
      />
      {errorText ? <div className="invalid-feedback">{errorText}</div> : null}
    </div>
  )
}

export const Textarea = ({
  // Props
  controlRef, defaultValue = '', id, label, name, required = false, errorText = null,
  ...props
}) => {
  const nextLabel = `${label}${required ? ' *' : ''}`;
  return (
    <div className="form-group">
      {label ? <label htmlFor={id}>{nextLabel}</label> : null}
      <textarea
        ref={controlRef} defaultValue={defaultValue}
        className={`form-control form-control-sm ${ errorText ? 'is-invalid' : null }`}
        id={id} name={name}
        {...props}
      ></textarea>
      {errorText ? <div className="invalid-feedback">{errorText}</div> : null}
    </div>
  )
}

export const Select = ({
  controlRef, id, label, name, required = false, options = [], errorText = null,
  ...props
}) => {
  label = `${label}${required ? ' *' : ''}`
  return (
    <div className="form-group">
      {label ? <label htmlFor={id}>{label}</label> : null}
      <select
        ref={controlRef}
        className={`form-control form-control-sm ${ errorText ? 'is-invalid' : null }`}
        id={id} name={name}
        {...props}
      >
        {options.map((option, index) => (
          <option key={`option-${id}-${index}`} value={option.value}>{option.label}</option>
        ))}
      </select>
      {errorText ? <div className="invalid-feedback">{errorText}</div> : null}
    </div>
  )
}

export const Checkbox = ({
  label, id, name, checked = false, onChange
}) => {
  return (
    <div className="form-group form-check">
      <input
        id={id} name={name}
        type="checkbox" className="form-check-input" 
        checked={checked}
        onChange={onChange}
      />
      <label className="form-check-label" htmlFor={id}>{label}</label>
    </div>
  )
}

export const Switch = ({
  label, id, name, checked = false, onChange
}) => {
  return (
    <div class="custom-control custom-switch">
      <input
        id={id} name={name}
        type="checkbox" class="custom-control-input"
        checked={checked}
        onChange={onChange}
      />
      <label class="custom-control-label" htmlFor={id}>{label}</label>
    </div>
  );
};
