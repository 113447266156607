import Statuses from 'types/statuses';
import { IOrderState, IActivity, OrderTypes, OrderActionTypes, } from './order.types';

export default class OrderActions {
  // Check-in
  public static checkIn = (activityId:number, confirmationCode:string, status:string):OrderActionTypes => ({
    type: OrderTypes.CheckInOrder, payload: { activityId, confirmationCode, status }
  });
  public static checkInSuccess = (item:IActivity):OrderActionTypes => ({
    type: OrderTypes.CheckInOrderSuccess, payload: { item }
  });
  // Default
  // Set status
  public static setStatus = (status:Statuses):OrderActionTypes => ({
    type: OrderTypes.SetStatus, payload: { status }
  });
  public static setInitialField = (field:keyof IOrderState):OrderActionTypes => ({
    type: OrderTypes.SetInitialField, payload: { field }
  });
}