import React from 'react';
// Redux
import { connect } from 'react-redux';
import * as BookingSelectors from 'app/store/booking/booking.selectors';
// 
import ReservationCreate from './ReservationCreate';
import ReservationFilter from './ReservationFilter';
import ReservationList from './ReservationList';

const Reservations = ({
  // Props
  // State
  booking
}) => {
  return (
    <>
      {booking.period !== 'season' && booking.enabled ? <ReservationCreate /> : null}
      <ReservationFilter />
      <ReservationList />
    </>
  )
}

const mapStateToProps = ({ BookingState }) => ({
  booking: BookingSelectors.getBooking(BookingState)
});

export default connect(mapStateToProps)(Reservations);
