import fetcher from 'app/utilities/fetcher';
// Service
import { getToken, checkIsExpires } from './storage.service';

// Login
// @params { object } credential
// @params { string } credential.email
// @params { string } credential.password
export function $login(credential){
  return fetcher('/admin/auth/login', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(credential)
  });
}

// Logout
export function $logout(){
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve('Logout');
    }, 300);
  })
}

// Check is expired
export function $checkIsTokenExpired(){
  return new Promise((resolve, reject) => {
    if ( getToken() ){
      checkIsExpires() ? reject(true) : resolve(true);
    } else {
      reject(true);
    }
  });
}
