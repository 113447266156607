import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
// Redux
import { connect } from 'react-redux';
import { ContentItemActions as Actions } from 'app/store/content-item/content-item.actions';
import * as CategorySelectors from 'app/store/content-category/content-category.selectors';
import * as ItemSelectors from 'app/store/content-item/content-item.selectors';
// Bootstrap
import Modal from 'react-bootstrap/Modal';
// Components
import { ButtonLoading } from 'app/components/Buttons';
import { ImageUploader } from 'app/components/ImageUploader';
// Cconstants
import { locations } from 'app/App.constants';
// Utilities
import { isRequired } from 'utilities/validation';

const ContentItemFormDialog = ({
  // Props
  contentItem, show, onHide,
  // State
  categories, isLoading, status,
  // Dispatch
  createContentItem, updateContentItem
}) => {
  const [ attributes, setAttributes ] = useState(contentItem.attributes || [{ name: 'location', value: '' }]);

  const { control, register, handleSubmit, errors, watch, setValue } = useForm({
    defaultValues: {
      image: contentItem.image || {},
      contentCategoryId: contentItem.category ? contentItem.category.id : '',
      headline: contentItem.headline || '',
      description: contentItem.description || '',
      enabled: contentItem.enabled || false,
      attributes: []
    }
  });

  const onSubmit = data => {
    const { image, contentCategoryId, headline, description, enabled, attributes } = data;
    const newData = { contentCategoryId: Number(contentCategoryId), headline, description, enabled };
    if ( attributes && attributes.length !== 0 ){
      newData['attributes'] = JSON.parse(attributes);
    }
    if ( image && image.id ) newData['imageId'] = image.id;
    if ( contentItem.id ){
      updateContentItem(contentItem.id, newData);
    } else {
      createContentItem(newData);
    }
  }

  useEffect(() => {
    if ( status === 'Success' ) onHide();
    // eslint-disable-next-line
  }, [status]);

  const handleChangeAttribute = e => {
    setValue('attributes', JSON.stringify([{ name: 'location', value: e.target.value }]));
  }

  return (
    <Modal show={show} onHide={onHide}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>{contentItem.id ? 'Update' : 'Create'} content item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {contentItem.id ? (
            <input ref={register()} type="hidden" name="contentCategoryId" />
          ) : null}
          {/* Image */}
          <Controller
            control={control}
            name="image"
            render={({ onChange, onBlur, value }) => (
              <ImageUploader
                image={value}
                onUpdate={newImage => onChange(newImage)}
              />
            )}
          />
          {/* Category */}
          {!contentItem.id ? (
            <div className="form-group">
              <label htmlFor="type">Category *</label>
              <select
                ref={register({ required: isRequired })}
                className={`
                  form-control form-control-sm
                  ${ errors.contentCategoryId ? 'is-invalid' : null }
                `}
                name="contentCategoryId" id="contentCategoryId"
              >
                <option value="">Choose category</option>
                {categories.map(category => (
                  <option key={`category-form-option-${category.id}`} value={category.id}>{category.name}</option>
                ))}
              </select>
              {errors.contentCategoryId ? <div className="invalid-feedback">{errors.contentCategoryId.message}</div> : null}
            </div>
          ) : null}
          {/* Headline */}
          <div className="form-group">
            <label htmlFor="headline">Headline *</label>
            <input
              ref={register({ required: isRequired })}
              className={`
                form-control form-control-sm
                ${ errors.headline ? 'is-invalid' : null }
              `}
              name="headline" id="headline" type="text"
            />
            {errors.headline ? <div className="invalid-feedback">{errors.headline.message}</div> : null}
          </div>
          {/* Description */}
          <div className="form-group">
            <label htmlFor="description">Description *</label>
            <textarea
              ref={register({ required: isRequired })}
              className={`
                form-control form-control-sm
                ${ errors.description ? 'is-invalid' : null }
              `}
              name="description" id="description"
              rows={5}
            ></textarea>
            {errors.description ? <div className="invalid-feedback">{errors.description.message}</div> : null}
          </div>
          {/* Enabled */}
          <Controller
            control={control}
            name="enabled"
            render={({ onChange, onBlur, value }) => (
              <div className="form-group form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="enabled"
                  name="enabled"
                  checked={value}
                  onChange={e => onChange(e.target.checked)}
                  onBlur={onBlur} />
                <label className="form-check-label" htmlFor="enabled">Enable</label>
              </div>
            )}
          />
          {/* Attributes */}
          {(watch('contentCategoryId') && Number(watch('contentCategoryId'))) === 1 ? (
            <>
              <input ref={register()} type="hidden" name="attributes" />
              {attributes.map((attribute, index) => (
                <div key={`attribute-form-${index}`} className="form-group">
                  <label htmlFor={attribute.name}>{attribute.name}</label>
                  <select
                    className="form-control form-control-sm"
                    id={attribute.name} name={attribute.name}
                    onChange={handleChangeAttribute}
                  >
                    <option value="">Choose location</option>
                    {locations.map((location, i) => (
                      <option
                        key={`attribute-form-${index}-location-${i}`}
                        value={location.value}
                      >{location.label}</option>
                    ))}
                  </select>
                </div>
              ))}
            </>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary btn-sm"
            type="button"
            onClick={onHide}
          >Cancel</button>
          <ButtonLoading
            loading={isLoading}
            type="submit"
          >Save</ButtonLoading>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

ContentItemFormDialog.defaultProps = {
  contentItem: {}
};

const mapStateToProps = state => ({
  categories: CategorySelectors.getItems(state),
  isLoading: ItemSelectors.getIsLoading(state),
  status: ItemSelectors.getStatus(state)
});

const mapDispatchToProps = dispatch => ({
  createContentItem: data => dispatch(Actions.create(data)),
  updateContentItem: (id, data) => dispatch(Actions.update(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentItemFormDialog);
