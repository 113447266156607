import fetcher from 'app/utilities/fetcher';

const apiURL = '/admin/contactMessages';
const headers = {
  'Content-Type': 'application/json'
};

export const ContactMessageService = {
  // Fetch list
  fetchList: params => {
    const searchParams = new URLSearchParams();
    Object.keys(params).forEach(key => {
      if ( params[key] ) searchParams.append(key, params[key]);
    });
    return fetcher(`${apiURL}?${searchParams}`, {
      method: 'GET'
    });
  },
  // Fetch list conversation
  fetchListConversation: userId => {
    return fetcher(`${apiURL}/messageConversation?userId=${userId}`, {
      method: 'GET'
    })
  },
  // Mark as read
  markAsRead: messageId => {
    return fetcher(`${apiURL}/${messageId}/actions/markAsRead`, {
      method: 'POST',
      headers
    }, () => null);
  },
  // send in app
  sendInApp: (userId, text) => {
    const searchParams = new URLSearchParams();
    searchParams.append('userId', userId);
    searchParams.append('text', text);
    return fetcher(`${apiURL}/messageConversation?${searchParams}`, {
      method: 'POST'
    });
  }
}
