import HttpUtility from 'utilities/http.utility';
import { ISeason, ISeasonData } from './season.types';

export default class SeasonAPI {
  private static _url:string = '/admin/reservationSeasons';
  private static _http:HttpUtility = new HttpUtility();

  // Fetch seasons list
  public static async fetchSeasonsList():Promise<any> {
    return SeasonAPI._http.get(SeasonAPI._url);
  }
  // Create season
  public static async createSeason(data:ISeason):Promise<any> {
    return SeasonAPI._http.post(SeasonAPI._url, data);
  }
  // Update season
  public static async updateSeason(seasonId:number, data:ISeasonData):Promise<any> {
    return SeasonAPI._http.put(`${SeasonAPI._url}/${seasonId}`, data);
  }
  // Delete season
  public static async deleteSeason(seasonId:number):Promise<any> {
    return SeasonAPI._http.delete(`${SeasonAPI._url}/${seasonId}`);
  }
  // Update slots
  public static async updateSlots(data:any):Promise<any> {
    return SeasonAPI._http.put(`${SeasonAPI._url}/slots/update`, data);
  }
}