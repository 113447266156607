import create from './create-action';
import Types from '../types/notification.types';

class NotificationActions {
  // Fetch content items
  fetchAll = (params, isMore) => create(Types.FETCH_ALL, { params, isMore })
  fetchAllSuccess = (notifications, params, isMore) => create(Types.FETCH_ALL_SUCCESS, { notifications, params, isMore })
  fetchAllFailure = () => create(Types.FETCH_ALL_FAILURE)
  // Send notification
  send = notification => create(Types.SEND, { notification })
  sendSuccess = () => create(Types.SEND_SUCCESS)
  sendFailure = () => create(Types.SEND_FAILURE)
  // Send bulk notification
  sendBulk = data => create(Types.SEND_BULK, { data })
  sendBulkSuccess = () => create(Types.SEND_BULK_SUCCESS)
  sendBulkFailure = () => create(Types.SEND_BULK_FAILURE)
  // Default
  setInitialState = () => create(Types.SET_INITIAL_STATE)
  setIsFetched = () => create(Types.SET_IS_FETCHED)
};

const action = new NotificationActions();
export default action;
