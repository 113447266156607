import { ReservationCategoryActionTypes } from './reservation-category.types';

const initialState = {
  categories: [],
  availableCategories: [],
  daysRange: [],
  filter: {
    search: ''
  },
  status: 'Initial'
}

export default (state = initialState, action) => {
  switch (action.type) {
    // Fetch categories
    case ReservationCategoryActionTypes.FetchCategories:
      return { ...state, categories: initialState.categories, status: 'Fetching' }
    case ReservationCategoryActionTypes.FetchCategoriesSuccess:
      return { ...state, categories: action.categories, status: 'Fetched' };
    // Fetch available categories
    case ReservationCategoryActionTypes.FetchAvailableCategories:
      return { ...state, availableCategories: initialState.availableCategories, status: 'FetchingModal' }
    case ReservationCategoryActionTypes.FetchAvailableCategoriesSuccess:
      return {
        ...state,
        availableCategories: action.categories.map(c => ({ ...c, quantity: 0 })),
        status: 'FetchedModal'
      };

    case ReservationCategoryActionTypes.ClearAvailableCategories:
      return { ...state, availableCategories: initialState.availableCategories, status: 'Initial' };

    // Create
    case ReservationCategoryActionTypes.Create:
      return { ...state, status: 'Loading' };
    case ReservationCategoryActionTypes.CreateSuccess:
      return { ...state, categories: [...state.categories, action.category], status: 'Loaded' };
    // Update
    case ReservationCategoryActionTypes.Update:
      return { ...state, status: 'Loading' };
    case ReservationCategoryActionTypes.UpdateSuccess:
      return {
        ...state,
        categories: state.categories.map(b =>
          b.id === action.category.id
          ? { ...b, ...action.category }
          : b
        ),
        status: 'Loaded'
      };
    // Delete
    case ReservationCategoryActionTypes.Delete:
      return state;
    case ReservationCategoryActionTypes.DeleteSuccess:
      return {
        ...state,
        categories: state.categories.filter(b => b.id !== action.categoryId)
      };
    // Actions
    case ReservationCategoryActionTypes.Enable:
    case ReservationCategoryActionTypes.Disable:
      return state;
    case ReservationCategoryActionTypes.EnableSuccess:
    case ReservationCategoryActionTypes.DisableSuccess:
      return {
        ...state,
        categories: state.categories.map(b =>
          b.id === action.category.id
          ? { ...b, ...action.category }
          : b
        )
      };
    // Increment/Decrement available categories
    case ReservationCategoryActionTypes.IncrementAvailableCategory:
      return {
        ...state,
        availableCategories: state.availableCategories.map(c => c.id === action.categoryId ? { ...c, quantity: c.quantity + 1 } : c)
      }
    case ReservationCategoryActionTypes.DecrementAvailableCategory:
      return {
        ...state,
        availableCategories: state.availableCategories.map(c => c.id === action.categoryId ? { ...c, quantity: c.quantity - 1 } : c)
      }

    // Filter
    case ReservationCategoryActionTypes.Filter:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.filterType]: action.filterValue
        }
      };
    case ReservationCategoryActionTypes.ClearFilter:
      return { ...state, filter: initialState.filter };
    // Set status
    case ReservationCategoryActionTypes.StatusSuccess:
      return { ...state, status: 'Success' };
    case ReservationCategoryActionTypes.StatusError:
      return { ...state, status: 'Error' };
    case ReservationCategoryActionTypes.StatusInitial:
      return { ...state, status: 'Initial' };
    default:
      return state;
  }
}