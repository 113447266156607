import { createSelector } from 'reselect';

const _bookingItems = state => state.bookingItems;
const _filter = state => state.filter;
const _status = state => state.status;

export const getIsLoading = (state) => state.isLoading;

export const getBookingItems = createSelector(
  _bookingItems, state => state.filter,
  (bookingItems, filter) => {
    const { search } = filter;

    const nextSearch = search.toLowerCase();

    const nextSort = bookingItems.sort((a, b) => {
      if (a.reservationCategoryName < b.reservationCategoryName) return -1;
      if (a.reservationCategoryName > b.reservationCategoryName) return 1;
      return 0;
    });
    return [
      {
        id: 0,
        reservationCategoryId: 0,
        reservationCategoryName: 'Unassigned',
        identifier: 'Unassigned'
      },
      ...nextSort
    ].filter(bookingItem => {
      let hasSearch = false;

      hasSearch = !search
        ? true
        : bookingItem.identifier.toLowerCase().indexOf(nextSearch) > -1
      ;

      return hasSearch;
    })
  }
)
// ToDO
export const getBookingItemsSortedByBookingPropertyAndIdentifier = createSelector(
  _bookingItems, state => state.filter,
  (bookingItems, filter) => {
    const { search } = filter;

    const nextSearch = search.toLowerCase();

    const nextSort = bookingItems.sort((a, b) => {
      if ( a.bookingPropertyId === b.bookingPropertyId ){
        return b.identifier > a.identifier ? 1 : -1;
      }
      return a.bookingPropertyId - b.bookingPropertyId;
    });
    return nextSort.filter(bookingItem => {
      let hasSearch = false;

      hasSearch = !search
        ? true
        : bookingItem.identifier.toLowerCase().indexOf(nextSearch) > -1
      ;

      return hasSearch;
    })
  }
);
export const getBookingItemById = createSelector(
  _bookingItems, (_, props) => props,
  (bookingItems, bookingItemId) => {
    if ( !bookingItemId ) return {};
    return bookingItems.find(b => b.id === bookingItemId)
  }
)
export const getFilter = createSelector(
  _filter, filter => filter
);
export const getStatus = createSelector(
  _status, status => status
);
