import React from 'react';
import { useForm } from 'react-hook-form';
// Redux
import { connect } from 'react-redux';
import { ReservationCategoryActions } from 'app/store/reservation-category/reservation-category.actions';
import * as ReservationCategorySelectors from 'app/store/reservation-category/reservation-category.selectors';

const ReservationCategories = ({
  // Props
  data, setData,
  // State
  booking, availableCategories,
  // Dispatch
  decrementAvailableCategory, incrementAvailableCategory
}) => {
  const { customerDataFields } = booking;

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = formData => {
    let hasFormData = Object.keys(formData).length > 0;
    let items = [];
    const filteredCategories = availableCategories.filter(c => c.quantity > 0);
    if ( hasFormData ){
      for ( let category of filteredCategories ){
        for ( let i = 0; i < category.quantity; i++ ){
          const customerData = [];
          Object.keys(formData).forEach(key => {
            let [ name, categoryId, index ] = key.split('_');
            if ( Number(categoryId) === category.id && Number(index) === i ){
              if ( formData[key] ){
                customerData.push({
                  name,
                  value: formData[key]
                });
              }
            }
          })
          items.push({
            customerData,
            reservationCategoryId: category.id
          })
        }
      }
    } else {
      for ( let category of filteredCategories ){
        for ( let i = 0; i < category.quantity; i++ ){
          items.push({
            reservationCategoryId: category.id
          });
        }
      }
    }
    setData(prevState => ({
      ...prevState,
      formVisible: true,
      items
    }));
  }

  const handleDecrement = category => decrementAvailableCategory(category.id);
  const handleIncrement = category => incrementAvailableCategory(category.id);

  const handleReselect = () => {
    setData(prevState => ({
      ...prevState,
      formVisible: false,
      items: []
    }));
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ position: 'relative' }}>
        {(data.formVisible && data.items.length !== 0) ? <span style={{
          position: 'absolute',
          top: 0, left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255,255,255,0.35)',
          zIndex: 2
        }}></span> : null}
        <hr />
        <span style={{
          display: 'block',
          fontWeight: 700,
          marginTop: '8px',
          marginBottom: '8px'
        }}>Select reservation category(ies)</span>
        <ul className="list-group mb-2">
          {availableCategories.map((category, index) => (
            <li key={`category-${index}`} className="list-group-item | d-flex align-items-start" style={{ minHeight: '70px' }}>
              <div className="list-group-item--body">
                <h6 className="mb-0">{category.name}</h6>
                <small className="d-block">{category.description}</small>
                {category.availableQuantity ? (
                  <small><b>Available quantity:</b>&nbsp;{category.availableQuantity}</small>
                ) : null}
                {/* Booking customer fields */}
                {customerDataFields && customerDataFields.length !== 0 ? (
                  <>
                    {[...Array(category.quantity).keys()].map((_, keyIndex) => (
                      <div key={`category-${index}-field-${keyIndex}`}>
                        <hr />
                        {customerDataFields.map((field, fieldIndex) => (
                          <div key={`category-${index}-field-${keyIndex}-${fieldIndex}`} className="row row-8">
                            <small className="col-sm-5 col-form-label" style={{ fontSize: '0.875rem' }}>{field.name}&nbsp;{field.required ? '*' : ''}</small>
                            <div className="col-7">
                              <div className="form-group">
                                <input
                                  ref={register({
                                    required: {
                                      value: field.required,
                                      message: 'This field is required'
                                    }
                                  })}
                                  defaultValue=""
                                  className={`
                                    form-control form-control-sm
                                    ${ errors[`${field.name}_${category.id}_${keyIndex}_${fieldIndex}`] ? 'is-invalid' : null
                                  }`}
                                  id={`${field.name}_${category.id}_${keyIndex}_${fieldIndex}`}
                                  name={`${field.name}_${category.id}_${keyIndex}_${fieldIndex}`}
                                />
                                {errors[`${field.name}_${category.id}_${keyIndex}_${fieldIndex}`]
                                  ? <div className="invalid-feedback">{errors[`${field.name}_${category.id}_${keyIndex}_${fieldIndex}`]['message']}</div>
                                  : null
                                }
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                  </>
                ) : null}
              </div>
              <div className="list-group-item--right" style={{ paddingTop: '10px' }}>
                <div className="d-flex align-items-center">
                  <button
                    className="btn btn-primary btn-round btn-sm"
                    type="button"
                    onClick={() => handleDecrement(category)}
                    disabled={category.quantity === 0}
                  >-</button>
                  <div className="text-center" style={{minWidth: '2rem'}}>{category.quantity}</div>
                  <button
                    className="btn btn-primary btn-round btn-sm"
                    type="button"
                    onClick={() => handleIncrement(category)}
                    disabled={category.availableQuantity === category.quantity}
                  >+</button>
                </div>
              </div>
            </li>
          ))}
        </ul>
        {(!data.formVisible || data.items.length === 0) ? (
          <div className="text-right">
            <button
              className="btn btn-primary btn-sm"
              type="submit"
            >Set reservation details</button>
          </div>
        ) : null}
      </form>
      {(data.formVisible && data.items.length !== 0) ? (
        <div className="text-right">
          <button
            className="btn btn-outline-primary btn-sm"
            type="button"
            onClick={handleReselect}
          >Change reservation category(ies)</button>
        </div>
      ) : null }
    </>
  )
}

const mapStateToProps = ({ BookingState, ReservationCategoryState }) => ({
  booking: BookingState.booking,

  availableCategories: ReservationCategorySelectors.getAvailableCategories(ReservationCategoryState),
});

const mapDispatchToProps = dispatch => ({
  incrementAvailableCategory: categoryId => dispatch(
    ReservationCategoryActions.incrementAvailableCategory(categoryId)
  ),
  decrementAvailableCategory: categoryId => dispatch(
    ReservationCategoryActions.decrementAvailableCategory(categoryId)
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReservationCategories);
