import React, { useState } from 'react';
// Dialogs
import ContentItemFormDialog from 'dialogs/ContentItemForm.dialog';

const ContentItemCreate = () => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);

  return (
    <>
      <div className="text-right mb-3">
        <button
          className="btn btn-primary btn-sm"
          type="button"
          onClick={handleShow}
        >Create content item</button>
      </div>
      {show ? (
        <ContentItemFormDialog
          show={show}
          onHide={handleHide}
        />
      ) : null}
    </>
  )
}

export default ContentItemCreate;
