import create from './create-action';
import Types from '../types/asset.types';

class AssetActions {
  // Create asset
  createAsset = (file, folder) => create(Types.CREATE_ASSET, { file, folder })
  createAssetSuccess = asset => create(Types.CREATE_ASSET_SUCCESS, { asset })
  createAssetFailure = () => create(Types.CREATE_ASSET_FAILURE)
  // Delete content item
  // deleteAsset = contentItem => create(Types.DELETE_ASSET, { contentItem })
  // deleteAssetSuccess = contentItemId => create(Types.DELETE_ASSET_SUCCESS, { contentItemId })
  // deleteAssetFailure = () => create(Types.DELETE_ASSET_FAILURE)
  // Set is fetched
  setIsFetched = () => create(Types.SET_IS_FETCHED)
  setInitialState = () => create(Types.SET_INITIAL_STATE)
}

const actions = new AssetActions();
export default actions;