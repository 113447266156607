import React from 'react';
import { useForm } from 'react-hook-form';
// Types
import Statuses from 'types/statuses';
// Models
import { IWaiver } from 'models/Waiver';
// Store
import { useDispatch, useSelector } from 'react-redux';
// Actions
import WaiverActions from 'store/waiver/waiver.actions';
// Selectors
import { getWaiver, getIsLoading, getStatus } from 'store/waiver/waiver.selectors';
// Bootstrap
import Modal from 'react-bootstrap/Modal';
// Components
import { Input, Textarea } from 'components/Controls';
import { ButtonLoading } from 'app/components/Buttons';
// Utilities
import { isRequired } from 'utilities/validation';

type Props = {
  isPreview?: boolean;
  waiverId?: number;
  params?: any;
  show: boolean;
  onHide: () => void;
}

const WaiverFormDialog:React.FC<Props> = ({
  // Props
  isPreview = false, waiverId, params, show, onHide
}) => {
  // Dispatch
  const dispatch = useDispatch();
  // State
  const waiver:IWaiver = useSelector((state:any) => getWaiver(state));
  const isLoading:boolean = useSelector((state:any) => getIsLoading(state));
  const status:Statuses = useSelector((state:any) => getStatus(state));

  const { register, handleSubmit, errors, reset } = useForm<any>({
    defaultValues: {
      name: '',
      content: ''
    }
  });

  const onSubmit = handleSubmit((data:any) => {
    if ( waiverId ){
      dispatch(WaiverActions.updateWaiver(waiverId, data));
    } else {
      dispatch(WaiverActions.createWaiver({
        ...params,
        ...data
      }));
    }
  });

  React.useEffect(() => {
    if ( waiverId ){
      dispatch(WaiverActions.fetchWaiver(waiverId));
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if ( waiver ){
      reset({
        name: waiver.name,
        content: waiver.content
      })
    }
    // eslint-disable-next-line
  }, [waiver]);

  React.useEffect(() => {
    if ( status === Statuses.Success ) onHide();
    // eslint-disable-next-line
  }, [status]);

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <form onSubmit={onSubmit} noValidate>
        <Modal.Header closeButton>
          {isPreview ? (
            <Modal.Title>{waiver ? waiver.name : ''}</Modal.Title>
          ) : (
            <Modal.Title>{`${waiver && waiver.id ? 'Update' : 'Create'}`} waiver</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>

          {!isPreview ? (
            <Input
              controlRef={register({ required: isRequired })}
              label="Name" id="name" name="name"
              required={true}
              errorText={errors.name ? errors.name.message : null}
            />
          ) : null}

          <Textarea
            controlRef={register({ required: isRequired })}
            label={isPreview ? '' : `Content`} id="content" name="content"
            rows={32}
            required={true}
            errorText={errors.content ? errors.content.message : null}
          />

        </Modal.Body>
        {!isPreview ? (
          <Modal.Footer>
            <button
              className="btn btn-secondary btn-sm"
              type="button"
              onClick={onHide}
            >Cancel</button>
            <ButtonLoading
              loading={isLoading}
              type="submit"
            >Save</ButtonLoading>
          </Modal.Footer>
        ) : null}
      </form>
    </Modal>
  )
}

export default WaiverFormDialog;
