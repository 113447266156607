import React, { useState } from 'react';
// Redux
import { IMailTemplate } from 'store/mail-template/mail-template.types';
// Dialogs
import MailTemplateFormDialog from 'dialogs/MailTemplateForm.dialog';
// Utilities
import { toNormalCase } from 'utilities/wordbreak.utility';

type IProps = {
  item:IMailTemplate;
}

const SystemEmailTemplatesListItem:React.FC<IProps> = ({
  // Props
  item
}) => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);

  return (
    <>
      <li className="list-group-item | d-flex justify-content-between align-items-center">
        <div>
          <small className="text-muted text-capitalize">{toNormalCase(item.name)}</small>
        </div>
        <div>
          <button
            className="btn btn-primary btn-sm btn-block"
            type="button"
            onClick={handleShow}
          >Edit</button>
        </div>
      </li>
      {show ? (
        <MailTemplateFormDialog
          mailTemplateId={item.id}
          show={show}
          onHide={handleHide}
        />
      ) : null}
    </>
  )
}

export default SystemEmailTemplatesListItem;
