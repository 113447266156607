import { ContactMessageActionTypes } from './contact-message.types';

const initialParams = {
  limit: 20,
  offset: 0,
  username: '',

  initial: false
}

const initialState = {
  messages: [],
  messageTotal: null,

  conversation: [],
  params: initialParams,
  status: 'Initial',
  isLoading: false
}

export default (state = initialState, action) => {
  switch (action.type){
    // Fetch list
    case ContactMessageActionTypes.FetchList:
      const {params, isLoadMore} = action;
      return {
        ...state,
        messages: isLoadMore ? state.messages : initialState.messages,
        messageTotal: initialState.messageTotal,
        params: params.initial ? params : {...state.params, ...params},
        status: isLoadMore ? 'FetchingMore' : 'Fetching'
      };
    case ContactMessageActionTypes.FetchListSuccess:
      const { data = [], total } = action.messages;
      return {
        ...state,
        messages: [...state.messages, ...data],
        messageTotal: total,
        params: {
          ...state.params,
          initial: false
        },
        status: 'Fetched'
      };
    // Fetch list conversation
    case ContactMessageActionTypes.FetchListConversation:
      return {...state, conversation: initialState.conversation, isLoading: true};
    case ContactMessageActionTypes.FetchListConversationSuccess:
      return {...state, conversation: action.conversation, isLoading: false};
    // Mark as read
    case ContactMessageActionTypes.MarkAsRead:
      return state;
    case ContactMessageActionTypes.MarkAsReadSuccess:
      return {
        ...state,
        messages: state.messages.map(
          m => m.id === Number(action.messageId) ? {...m, status: 'read'} : m
        )
      };
    case ContactMessageActionTypes.SendInApp:
      return state;
    case ContactMessageActionTypes.SendInAppSuccess:
      return {
        ...state,
        conversation: [action.conversation, ...state.conversation]
      };
    // Set status
    case ContactMessageActionTypes.StatusSuccess:
      return { ...state, status: 'Success' };
    case ContactMessageActionTypes.StatusError:
      return { ...state, status: 'Error' };
    case ContactMessageActionTypes.StatusInitial:
      return { ...state, status: 'Initial' };
    case ContactMessageActionTypes.SetInitialState:
      return initialState;
    default:
      return state;
  }
}
