export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export const build24Hours = ():Array<{ value:string, label:string }> => {
  const hours = [];
  for ( let i = 0; i < 24; i++ ){
    let hour = `${i}:00`;
    if ( i < 10 ) hour = '0' + hour;
    hours.push({
      value: hour,
      label: convert24to12(hour)
    });
  }
  return hours;
}

const convert24to12 = (time:any) =>  {
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
  if ( time.length > 1 ){
    time = time.slice(1);
    time[5] = +time[0] < 12 ? ' am' : ' pm';
    time[0] = +time[0] % 12 || 12;
  }
  return time.join('');
}
