import React, { useEffect } from 'react';
// Redux
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import AccountActions from 'store/account/account.actions';
import { IAccount } from 'store/account/account.types';
import * as AccountSelectors from 'store/account/account.selectors';
// Components
import { Container } from 'components/Container';
// 
import AccountsCreate from './AccountsCreate';
import AccountsListItem from './AccountsListItem';

type IProps = {
  items: Array<IAccount>;
  isFetching: boolean;
  fetchAccounts: () => void;
  deleteAccount: (user:IAccount) => void;
}

const AccountsPage:React.FC<IProps> = ({
  // State
  items, isFetching,
  // Dispatch
  fetchAccounts, deleteAccount
}) => {
  useEffect(() => {
    fetchAccounts();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="container-fluid pt-3 pb-3">
      <AccountsCreate />
      <Container data={items} isFetching={isFetching}>
        <div className="row row-8 row-cols-1 row-cols-sm-3">
          {items && items.map(item => (
            <div key={`staff-item-${item.id}`} className="col | d-flex mb-2">
              <AccountsListItem
                item={item}
                onDelete={deleteAccount}
              />
            </div>
          ))}
        </div>
      </Container>
    </div>
  )
}

const mapStateToProps = (state:any) => ({
  items: AccountSelectors.getItems(state),
  isFetching: AccountSelectors.getIsFetching(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchAccounts: () => dispatch(AccountActions.fetchList()),
  deleteAccount: (user:IAccount) => dispatch(AccountActions.delete(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountsPage);
