import React from 'react';
// Redux
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ReservationActions } from 'app/store/reservation/reservation.actions';
import * as ReservationSelectors from 'app/store/reservation/reservation.selectors';
// Components
import { Message, Loader } from 'components/Utility';
// 
import ReservationTransactionsListItem from './ReservationTransactionsListItem';

type Props = {
  reservationId: number;
  email: string;
  // State
  transactions: Array<any> | null;
  status: string;
  isLoading: boolean;
  // Dispatch
  fetchTransactions: (reservationId:number) => void;
};

const ReservationTransactionsList:React.FC<Props> = ({
  // Props
  reservationId, email,
  // State
  transactions, status, isLoading,
  // Dispatch
  fetchTransactions
}) => {
  React.useEffect(() => {
    fetchTransactions(reservationId);
    // eslint-disable-next-line
  }, []);

  if ( !transactions || status === 'ModalFetching' ) return <Loader />;
  if ( transactions.length === 0 ) return <Message text="No transaction(s)" />
  return (
    <table className="table">
      <thead>
        <tr>
          <th>Type</th>
          <th>Status</th>
          <th>Amount</th>
          <th>Notes</th>
          <th>Created On</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {transactions.map((transaction, index) => (
          <ReservationTransactionsListItem
            key={`transaction-item-${transaction.id}`}
            item={transaction}
            reservationId={reservationId}
            email={email}
            isLoading={isLoading}
            status={status}
          />
        ))}
      </tbody>
    </table>
  )
}

const mapStateToProps = (state:any) => ({
  transactions: ReservationSelectors.getTransactions(state.ReservationState),
  status: ReservationSelectors.getStatus(state.ReservationState),
  isLoading: ReservationSelectors.getIsLoading(state.ReservationState)
});

const mapDispatchToProps = (dispatch:Dispatch) => ({
  fetchTransactions: (reservationId:number) => dispatch(ReservationActions.fetchTransactions(reservationId))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReservationTransactionsList);
