import fetcher from '../../utilities/fetcher';

const apiURL = '/admin/bookingPropertyReservationRestrictions';
const headers = {
  'Content-Type': 'application/json'
};

export const RestrictionService = {
  // Fetch list
  fetchList: bookingId => {
    const params = new URLSearchParams();
    params.append('bookingPropertyId', bookingId);
    return fetcher(`${apiURL}?${params}`);
  },
  // Create
  create: data => {
    return fetcher(`${apiURL}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(data)
    })
  },
  // Update
  update: (restrictionId, data) => {
    return fetcher(`${apiURL}/${restrictionId}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(data)
    })
  },
  // Delete
  delete: restrictionId => {
    return fetcher(`${apiURL}/${restrictionId}`, {
      method: 'DELETE'
    }, () => null)
  },
}
