import Types from '../types/product.types';

const product = {
  image: {
    href: ''
  },
  name: '',
  description: '',
  available: true,
  price: 0,
  quantity: '',
  inventoryGroup: {}
}

const initialParams = {
  offset: 0,
  limit: 20,
  inventoryGroupId: ''
}

const initialState = {
  totalProducts: 0,
  products: [],
  product,

  params: initialParams,

  isFetching: true,
  hasMore: true,
  hasMoreLoading: false,

  isImageFetching: false,

  isFormLoading: false,
  isFormSuccess: false
};

export default function(state = initialState, { type, payload }){
  switch (type){
    // Request
    case Types.FETCH_ALL:
      return _fetchAll(state, payload);
    case Types.FETCH_BY_ID:
      return { ...state, product: {}, isFetching: true };
    case Types.CREATE:
    case Types.UPDATE:
      return { ...state, isFormLoading: true, isFormSuccess: false };
    case Types.UPLOAD_IMAGE:
    case Types.DELETE_IMAGE:
      return { ...state, isImageFetching: true };
    // Success
    case Types.FETCH_ALL_SUCCESS:
      return _fetchAllSuccess(state, payload);
    case Types.FETCH_BY_ID_SUCCESS:
      return { ...state, product: payload.product, isFetching: false };
    case Types.CREATE_SUCCESS:
    case Types.UPDATE_SUCCESS:
        return { ...state, isFormLoading: false, isFormSuccess: true };
    case Types.DELETE_SUCCESS:
      return {
        ...state,
        totalProducts: state.totalProducts--,
        products: state.products.filter(p => p.id !== payload.productId)
      };
    case Types.UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        product: { ...state.product, image: payload.image },
        isImageFetching: false
      };
    case Types.DELETE_IMAGE_SUCCESS:
      const product = state.product;
      delete product['image'];
      return {
        ...state,
        product, isImageFetching: false
      };
    // Failure
    case Types.FETCH_ALL_FAILURE:
      return { ...state, totalProducts: 0, products: [], isFetching: false };
    case Types.FETCH_BY_ID_FAILURE:
      return { ...state, product: {}, isFetching: false };
    case Types.CREATE_FAILURE:
    case Types.UPDATE_FAILURE:
      return { ...state, isFormLoading: false, isFormSuccess: false };
    case Types.UPLOAD_IMAGE_FAILURE:
    case Types.DELETE_IMAGE_FAILURE:
      return { ...state, isImageFetching: false };
    // Other
    case Types.SET_INITIAL_STATE:
      return initialState;
    case Types.SET_IS_FETCHED:
      return { ...state, isFetching: false };
    default:
      return state;
  }
}

// Fetch all
const _fetchAll = (state, { params, isMore }) => {
  const isMoreExist = (typeof isMore !== 'undefined' && isMore);
  return {
    ...state,
    products: isMoreExist ? state.products : [],
    params,
    isFetching: isMoreExist ? false : true,
    hasMore: true,
    hasMoreLoading: isMoreExist ? true : false
  };
}
// Fetch all success
const _fetchAllSuccess = (state, { data: { total, data = [] }, params, isMore }) => {
  return {
    ...state,
    totalProducts: total,
    products: isMore ? [...state.products, ...data] : data,
    params,
    isFetching: false,
    hasMore:
      (data && data.length > 0 && data.length >= state.params.limit)
      ? true : false,
    hasMoreLoading: false
  }
}
