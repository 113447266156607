import { takeLatest, call, put } from 'redux-saga/effects';
// 
import * as LayoutActions from '../../actions/layout.actions';
import { UserActionTypes, UserMessageTypes } from './user.types';
import { UserService } from './user.service';
import { UserActions } from './user.actions';
import { login } from '../../actions/auth.actions';

function* _forgotPassword(action){
  try {
    const { email } = action;
    yield call(UserService.sendPasswordRecoveryEmail, email);
    yield put(UserActions.forgotPasswordSuccess());
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: UserMessageTypes.ForgotPassword
    }));
  } catch(error){
    console.error(error);
    yield put(UserActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _updatePassword(action){
  try {
    const { data } = action;
    const { email, password } = data;
    yield call(UserService.updatePassword, data);
    yield put(UserActions.updatePasswordSuccess());
    yield put(UserActions.setStatusInitial());
    yield put(login({ email, password }));
  } catch(error){
    console.error(error);
    yield put(UserActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _updateMe(action){
  try {
    const response = yield call(UserService.updateMe, action.id, action.data);
    yield put(UserActions.updateMeSuccess(response));
    yield put(UserActions.setStatusSuccess());
    yield put(UserActions.setStatusInitial());
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: 'Profile was updated'
    }));
  } catch(error){
    console.error(error);
    yield put(UserActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

export default function* (){
  yield takeLatest(UserActionTypes.ForgotPassword, _forgotPassword);
  yield takeLatest(UserActionTypes.UpdatePassword, _updatePassword);
  yield takeLatest(UserActionTypes.UpdateMe, _updateMe);
}
