export const RestrictionActionTypes = {
  FetchList: '[Restriction] Fetch list',
  FetchListSuccess: '[Restriction] Fetch list success',
  // Create
  Create: '[Restriction] Create',
  CreateSuccess: '[Restriction] Create success',
  // Update
  Update: '[Restriction] Update',
  UpdateSuccess: '[Restriction] Update success',
  // Delete
  Delete: '[Restriction] Delete',
  DeleteSuccess: '[Restriction] Delete success',
  // Status
  StatusSuccess: '[Restriction] Status success',
  StatusError: '[Restriction] Status error',
  StatusInitial: '[Restriction] Status initial'
}

export const RestrictionMessageTypes = {
  Create: 'Booking property reservation restriction created',
  Update: 'Booking property reservation restriction updated',
  Delete: 'Booking property reservation restriction deleted'
}
