import React, { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
import { BookingActions } from '../../../store/booking/booking.actions';
// Bootstrap
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
// Modal
import BookingFormModal from '../../../layouts/Modals/BookingFormModal';
// Constants
import { BOOKING_TYPES, BOOKING_PERIOD } from 'app/App.constants';

const BookingsListItem = ({
  // Props
  booking,
  // State
  // Dispatch
  deleteBooking, enableBooking, disableBooking
}) => {
  const { path } = useRouteMatch();

  const [show, setShow] = useState(false);

  return (
    <>
      <div className="col-12 col-sm-6 col-md-4 col-lg-3 | d-flex">
        <div className="card">
          <div className="card-body">
            <small className="text-info text-capitalize">{BOOKING_PERIOD[booking.period]}</small>
            <h5 className="mb-0">{booking.name}</h5>
            <div className="mb-1">
              <span className={`badge ${booking.enabled ? 'badge-success' : 'badge-danger'}`}>
                {booking.enabled ? 'Enabled' : 'Disabled'}
              </span>
            </div>
            <small className="text-secondary d-block">{BOOKING_TYPES[booking.type]}</small>
            <small>{booking.description}</small>
            {(booking.checkInTime && booking.checkOutTime) &&
              <div className="row row-8">
                <div className="col-6">
                  <b style={{fontSize: '0.875rem'}}>Check-In Time</b>
                  <small className="d-block">{booking.checkInTime}</small>
                </div>
                <div className="col-6">
                  <b style={{fontSize: '0.875rem'}}>Check-Out Time</b>
                  <small className="d-block">{booking.checkOutTime}</small>
                </div>
              </div>
            }
            {(booking.lat || booking.lon) &&
              <div className="row row-8">
                {booking.lat &&
                  <div className="col-6">
                    <b style={{fontSize: '0.875rem'}}>Lat</b>
                    <small className="d-block">{booking.lat}</small>
                  </div>
                }
                {booking.lon &&
                  <div className="col-6">
                    <b style={{fontSize: '0.875rem'}}>Lon</b>
                    <small className="d-block">{booking.lon}</small>
                  </div>
                }
              </div>
            }
            {(booking.tax || booking.petFee) ? (
              <div className="row row-8">
                {/* {booking.tax ?
                  <div className="col-6">
                    <b style={{fontSize: '0.875rem'}}>Tax</b>
                    <small className="d-block">{booking.tax}%</small>
                  </div>
                : null} */}
                {booking.petFee ?
                  <div className="col-12">
                    <b style={{fontSize: '0.875rem'}}>Pet fee</b>
                    <small className="d-block">${booking.petFee}</small>
                  </div>
                : null}
              </div>
            ) : null}
            <div>
              <b style={{fontSize: '0.875rem'}}>Deposit</b>
              <small className="d-block">{booking.deposit}%</small>
            </div>
            {booking.customerDataFields && booking.customerDataFields.length !== 0 ? (
              <div>
                <b style={{fontSize: '0.875rem'}}>Customer data fields</b>
                {booking.customerDataFields.map((field, index) => (
                  <small
                    key={`${field.name}-item-${index}`}
                    className="d-block"
                  >{field.name}&nbsp;&bull;&nbsp;<span className={`${field.required ? 'text-success' : ''}`}>{field.required ? 'Required' : 'Not-required'}</span></small>
                ))}
              </div>
            ) : null}
          </div>
          <div className="card-footer | text-right">
            <Link
              className="btn btn-link btn-sm | mr-1"
              to={`${path}/${booking.id}/calendar`}
            >Details</Link>
            <button
              className="btn btn-primary btn-sm"
              type="button"
              onClick={() => setShow(true)}
            >Edit</button>
            <DropdownButton
              as={ButtonGroup}
              alignRight
              className="ml-2"
              id={`dropdown-button-drop-${booking.id}`}
              size="sm"
              variant="outline-secondary"
              title={''}
            >
              {!booking.enabled
                ? <Dropdown.Item as="button" onClick={() => enableBooking(booking.id)}>Enable</Dropdown.Item>
                : <Dropdown.Item as="button" onClick={() => disableBooking(booking.id)}>Disable</Dropdown.Item>
              }
              <Dropdown.Item as="button" className="text-danger" onClick={() => deleteBooking(booking)}>Delete</Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
      </div>
      {/* Modal */}
      {show &&
        <BookingFormModal
          bookingId={booking.id}
          show={show}
          onHide={() => setShow(false)}
        />
      }
    </>
  )
}

const mapDispatchToProps = dispatch => ({
  deleteBooking: booking => dispatch(BookingActions.delete(booking)),
  enableBooking: bookingId => dispatch(BookingActions.enable(bookingId)),
  disableBooking: bookingId => dispatch(BookingActions.disable(bookingId)),
});

export default connect(null, mapDispatchToProps)(BookingsListItem);
