import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
// Actions
import { ReservationCategoryActions } from '../../../store/reservation-category/reservation-category.actions';
import SeasonActions from 'store/season/season.actions';
// Selectors
import * as BookingSelectors from '../../../store/booking/booking.selectors';
import * as ReservationCategorySelectors from '../../../store/reservation-category/reservation-category.selectors';
import { getSeasons } from 'store/season/season.selectors';
// Bootstrap
import { Modal } from 'react-bootstrap';
// Hooks
import useForm from '../../../hooks/useForm';
// Utilities
import { isTimeGreater } from 'app/utilities/datetime.convert';
// 
import CategoryFormImages from './CategoryFormImages';
import CategoryDaily from './CategoryDaily';
import CategorySeasonal from './CategorySeasonal';
import CategoryHourly from './CategoryHourly';
import CategoryFormAttributes from './CategoryFormAttributes';

const validate = values => {
  const pattern = /^\d{4}-\d{2}-\d{2}$/;
  const errors = {};
  if ( !values.name ) errors['name'] = 'This field is required';
  if ( !values.description ) errors['description'] = 'This field is required';

  if ( values.period === 'season' ){
    if ( !values.seasonStart ){
      errors['seasonStart'] = 'This field is required';
    } else if ( !pattern.test(values.seasonStart) ){
      errors['seasonStart'] = 'Date is not valid';
    }
    if ( !values.seasonEnd ){
      errors['seasonEnd'] = 'This field is required';
    } else if ( !pattern.test(values.seasonEnd) ){
      errors['seasonEnd'] = 'Date is not valid';
    }
    if ( values.seasonStart && values.seasonEnd ){
      if ( new Date(values.seasonEnd).getTime() - new Date(values.seasonStart).getTime() < 0 ){
        errors['seasonEnd'] = 'End date can`t be less than start date';
      }
    }
    if ( !values.periodPrice ){
      errors['periodPrice'] = 'This field is required';
    }
  } else {
    if ( values.sunday === '' ) errors['sunday'] = 'This field is required';
    if ( values.monday === '' ) errors['monday'] = 'This field is required';
    if ( values.tuesday === '' ) errors['tuesday'] = 'This field is required';
    if ( values.wednesday === '' ) errors['wednesday'] = 'This field is required';
    if ( values.thursday === '' ) errors['thursday'] = 'This field is required';
    if ( values.friday === '' ) errors['friday'] = 'This field is required';
    if ( values.saturday === '' ) errors['saturday'] = 'This field is required';


    const seasons = {};
    for ( let key of Object.keys(values) ){
      if ( key.includes('season-') ){
        const [ , id, day ] = key.split('-');
        const seasonId = Number(id);
        if ( seasons[seasonId] ){
          seasons[seasonId][day] = values[`season-${seasonId}-${day}`];
        } else {
          seasons[seasonId] = { [day]: values[`season-${seasonId}-${day}`] };
        }
      }
    }

    for ( let seasonId of Object.keys(seasons) ){
      let hasValue = false;
      for ( let day of Object.keys(seasons[seasonId]) ){
        if ( seasons[seasonId][day] ){
          hasValue = true;
          break;
        }
      }
      if ( hasValue ){
        for ( let day of Object.keys(seasons[seasonId]) ){
          if ( seasons[seasonId][day] === '' ){
            errors[`sseason-${seasonId}`] = true;
            errors[`season-${seasonId}-${day}`] = 'This field is required';
          }
        }
      }
    }

    if ( values.period === 'hour' ){
      if ( !values.sundayStart ) errors['sundayStart'] = 'This field is required';
      if ( !values.sundayEnd ) errors['sundayEnd'] = 'This field is required';
      if ( values.sundayStart && values.sundayEnd && isTimeGreater(values.sundayStart, values.sundayEnd) ){
        errors['sundayStart'] = 'Start time can`t be greater than end';
      }

      if ( !values.mondayStart ) errors['mondayStart'] = 'This field is required';
      if ( !values.mondayEnd ) errors['mondayEnd'] = 'This field is required';
      if ( values.mondayStart && values.mondayEnd && isTimeGreater(values.mondayStart, values.mondayEnd) ){
        errors['mondayStart'] = 'Start time can`t be greater than end';
      }

      if ( !values.tuesdayStart ) errors['tuesdayStart'] = 'This field is required';
      if ( !values.tuesdayEnd ) errors['tuesdayEnd'] = 'This field is required';
      if ( values.tuesdayStart && values.tuesdayEnd && isTimeGreater(values.tuesdayStart, values.tuesdayEnd) ){
        errors['tuesdayStart'] = 'Start time can`t be greater than end';
      }

      if ( !values.wednesdayStart ) errors['wednesdayStart'] = 'This field is required';
      if ( !values.wednesdayEnd ) errors['wednesdayEnd'] = 'This field is required';
      if ( values.wednesdayStart && values.wednesdayEnd && isTimeGreater(values.wednesdayStart, values.wednesdayEnd) ){
        errors['wednesdayStart'] = 'Start time can`t be greater than end';
      }

      if ( !values.thursdayStart ) errors['thursdayStart'] = 'This field is required';
      if ( !values.thursdayEnd ) errors['thursdayEnd'] = 'This field is required';
      if ( values.thursdayStart && values.thursdayEnd && isTimeGreater(values.thursdayStart, values.thursdayEnd) ){
        errors['thursdayStart'] = 'Start time can`t be greater than end';
      }

      if ( !values.fridayStart ) errors['fridayStart'] = 'This field is required';
      if ( !values.fridayEnd ) errors['fridayEnd'] = 'This field is required';
      if ( values.fridayStart && values.fridayEnd && isTimeGreater(values.fridayStart, values.fridayEnd) ){
        errors['fridayStart'] = 'Start time can`t be greater than end';
      }

      if ( !values.saturdayStart ) errors['saturdayStart'] = 'This field is required';
      if ( !values.saturdayEnd ) errors['saturdayEnd'] = 'This field is required';
      if ( values.saturdayStart && values.saturdayEnd && isTimeGreater(values.saturdayStart, values.saturdayEnd) ){
        errors['saturdayStart'] = 'Start time can`t be greater than end';
      }
    }
  }
  return errors;
}

const CategoryFormModal = ({
  // Props
  show, onHide,
  // State
  booking, category, status, seasons,
  // Dispatch
  createCategory, updateCategory, fetchSeasonsList
}) => {
  const { bookingId } = useParams();

  const callback = () => {
    const {
      period,
      images, name, description,
      // Day
      sunday, sundayStart, sundayEnd, monday, mondayStart, mondayEnd, tuesday, tuesdayStart, tuesdayEnd,
      wednesday, wednesdayStart, wednesdayEnd, thursday, thursdayStart, thursdayEnd, friday, fridayStart, fridayEnd,
      saturday, saturdayStart, saturdayEnd,
      // Season
      seasonStart, seasonEnd, periodPrice,
      attributes
    } = values;
    const data = { images, name, description, attributes };

    if ( period === 'season' ){
      data['seasonStart'] = seasonStart;
      data['seasonEnd'] = seasonEnd;
      data['periodPrice'] = Number(periodPrice);
    } else {
      data['dayOfWeekPricing'] = {};
      data['dayOfWeekPricing']['sunday'] = Number(sunday);
      data['dayOfWeekPricing']['monday'] = Number(monday);
      data['dayOfWeekPricing']['tuesday'] = Number(tuesday);
      data['dayOfWeekPricing']['wednesday'] = Number(wednesday);
      data['dayOfWeekPricing']['thursday'] = Number(thursday);
      data['dayOfWeekPricing']['friday'] = Number(friday);
      data['dayOfWeekPricing']['saturday'] = Number(saturday);

      const seasonalPricing = [];
      const seasons = {};
      for ( let key of Object.keys(values) ){
        if ( key.includes('season-') ){
          const [ , id, day ] = key.split('-');
          const seasonId = Number(id);
          if ( values[`season-${seasonId}-${day}`] !== '' ){
            if ( seasons[seasonId] ){
              seasons[seasonId][day] = values[`season-${seasonId}-${day}`];
            } else {
              seasons[seasonId] = { [day]: values[`season-${seasonId}-${day}`] };
            }
          }
        }
      }
      if ( Object.keys(seasons).length > 0 ){
        for ( let reservationSeasonId of Object.keys(seasons) ){
          seasonalPricing.push({
            reservationSeasonId,
            dayOfWeekPricing: seasons[reservationSeasonId]
          });
        }
      }
      if ( seasonalPricing.length > 0 ) data['seasonalPricing'] = seasonalPricing;
      if ( period === 'hour' ){
        data['dayOfWeekAvailabilityTime'] = {
          'sunday': { start: sundayStart, end: sundayEnd },
          'monday': { start: mondayStart, end: mondayEnd },
          'tuesday': { start: tuesdayStart, end: tuesdayEnd },
          'wednesday': { start: wednesdayStart, end: wednesdayEnd },
          'thursday': { start: thursdayStart, end: thursdayEnd },
          'friday': { start: fridayStart, end: fridayEnd },
          'saturday': { start: saturdayStart, end: saturdayEnd }
        };
      }
    }
    if ( category && category.id ){
      updateCategory(category.id, data);
    } else {
      data['bookingPropertyId'] = parseInt(bookingId, 10);
      createCategory(data);
    }
  };

  const [values, errors, handleChange, handleSubmit, handleSetValues] = useForm(callback, {
    period: booking.period,

    images: category.images || [],
    name: category.name || '',
    description: category.description || '',
    // Day
    sunday: category.dayOfWeekPricing ? category.dayOfWeekPricing['sunday'] : 0,
    sundayStart: getTimeByDay(category, 'sunday', 'start'),
    sundayEnd: getTimeByDay(category, 'sunday', 'end'),

    monday: category.dayOfWeekPricing ? category.dayOfWeekPricing['monday'] : 0,
    mondayStart: getTimeByDay(category, 'monday', 'start'),
    mondayEnd: getTimeByDay(category, 'monday', 'end'),

    tuesday: category.dayOfWeekPricing ? category.dayOfWeekPricing['tuesday'] : 0,
    tuesdayStart: getTimeByDay(category, 'tuesday', 'start'),
    tuesdayEnd: getTimeByDay(category, 'tuesday', 'end'),

    wednesday: category.dayOfWeekPricing ? category.dayOfWeekPricing['wednesday'] : 0,
    wednesdayStart: getTimeByDay(category, 'wednesday', 'start'),
    wednesdayEnd: getTimeByDay(category, 'wednesday', 'end'),

    thursday: category.dayOfWeekPricing ? category.dayOfWeekPricing['thursday'] : 0,
    thursdayStart: getTimeByDay(category, 'thursday', 'start'),
    thursdayEnd: getTimeByDay(category, 'thursday', 'end'),

    friday: category.dayOfWeekPricing ? category.dayOfWeekPricing['friday'] : 0,
    fridayStart: getTimeByDay(category, 'friday', 'start'),
    fridayEnd: getTimeByDay(category, 'friday', 'end'),

    saturday: category.dayOfWeekPricing ? category.dayOfWeekPricing['saturday'] : 0,
    saturdayStart: getTimeByDay(category, 'saturday', 'start'),
    saturdayEnd: getTimeByDay(category, 'saturday', 'end'),
    // Season
    seasonStart: category.seasonStart || '',
    seasonEnd: category.seasonEnd || '',
    periodPrice: category.periodPrice || '',

    attributes: category.attributes || []
  }, validate);

  useEffect(() => {
    fetchSeasonsList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( seasons && seasons.length > 0 ){
      handleSetValues({
        ...values,
        ...generateSeasons(seasons, category.seasonalPricing)
      });
    }
    // eslint-disable-next-line
  }, [seasons])

  useEffect(() => {
    if ( status === 'Success' ) onHide();
    // eslint-disable-next-line
  }, [status]);

  return (
    <Modal size="xl" show={show} onHide={onHide}>
      <form onSubmit={handleSubmit}>
        <Modal.Header>
          {category.id ? `Update: ${category.name}` : 'Create reservation category'}
        </Modal.Header>
        <Modal.Body>

          <div className="row">
            <div className="col-12 col-lg-5">
              <CategoryFormImages
                value={values.images}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 col-lg-7">
              {/* name */}
              <div className="form-group">
                <label htmlFor="name">Name&nbsp;*</label>
                <input
                  className={`form-control form-control-sm ${ errors.name && 'is-invalid' }`}
                  id="name"
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                />
                {errors.name && <div className="invalid-feedback">{errors.name}</div>}
              </div>
              {/* description */}
              <div className="form-group">
                <label htmlFor="description">Description&nbsp;*</label>
                <textarea
                  className={`form-control form-control-sm ${ errors.description && 'is-invalid' }`}
                  id="description"
                  name="description"
                  value={values.description}
                  rows="5"
                  onChange={handleChange}
                ></textarea>
                {errors.description && <div className="invalid-feedback">{errors.description}</div>}
              </div>
              {booking.period === 'season' && (
                <CategorySeasonal values={values} onChange={handleChange} errors={errors} />
              )}
              {booking.period === 'day' && (
                <CategoryDaily values={values} onChange={handleChange} errors={errors} />
              )}
              {booking.period === 'hour' && (
                <CategoryHourly values={values} onChange={handleChange} errors={errors} />
              )}
              {/* attributes */}
              <CategoryFormAttributes
                value={values.attributes}
                onChange={handleChange}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-secondary btn-sm"
            type="button"
            onClick={onHide}
          >Cancel</button>
          <button
            className="btn btn-primary btn-sm"
            type="submit"
          >Save</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

CategoryFormModal.defaultProps = {
  categoryId: null
};

const mapStateToProps = (state, ownProps) => ({
  seasons: getSeasons(state),
  booking: BookingSelectors.getBooking(state.BookingState),
  category: ReservationCategorySelectors.getCategoryById(state.ReservationCategoryState, ownProps.categoryId),
  status: ReservationCategorySelectors.getStatus(state.ReservationCategoryState)
});

const mapDispatchToProps = dispatch => ({
  createCategory: data => dispatch(ReservationCategoryActions.create(data)),
  updateCategory: (categoryId, data) => dispatch(ReservationCategoryActions.update(categoryId, data)),
  fetchSeasonsList: () => dispatch(SeasonActions.fetchSeasonsList())
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryFormModal);

const getTimeByDay = (obj, dayName, time) => {
  return (
    obj.dayOfWeekAvailabilityTime && obj.dayOfWeekAvailabilityTime[dayName] && obj.dayOfWeekAvailabilityTime[dayName][time]
  ) ? obj.dayOfWeekAvailabilityTime[dayName][time] : time === 'start' ? '00:00' : '23:00'
}

const generateSeasons = (seasons, seasonalPricing) => {
  if ( !seasons ) return {};
  if ( seasons.length === 0 ) return {};
  const result = {};
  let pricing = {};
  if ( seasonalPricing && seasonalPricing.length > 0 ){
    pricing = seasonalPricing.reduce((acc, cur) => {
      acc[cur.reservationSeasonId] = cur.dayOfWeekPricing;
      return acc;
    }, {});
  }
  for ( let season of seasons ){
    for ( let day of days ){
      result[`season-${season.id}-${day.name}`] =
        pricing[season.id]
        ? pricing[season.id][day.name]
        : ''
      ;
    }
  }
  return result;
}

const days = [
  { label: 'Sunday', name: 'sunday' },
  { label: 'Monday', name: 'monday' },
  { label: 'Tuesday', name: 'tuesday' },
  { label: 'Wednesday', name: 'wednesday' },
  { label: 'Thursday', name: 'thursday' },
  { label: 'Friday', name: 'friday' },
  { label: 'Saturday', name: 'saturday' },
];

