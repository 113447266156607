import React from 'react';
import { Select } from 'components/Controls';
// Types
import { ISlotData } from 'store/season/season.types';
// Service
import { daysInMonth } from 'store/season/season.service';
// Utilities
import { isRequired } from 'utilities/validation';
// Constants
import { months } from 'app/App.constants';

const mm:any = months;

type Props = {
  register: any;
  errors: any;
  index: number;
  item: ISlotData;
  onDeleteSlot: (uuid:string) => void;
};

interface IOption {
  value: string | number;
  label: string | number;
}

const SeasonsSlotListItem:React.FC<Props> = ({
  // Props
  register, errors, index, item, onDeleteSlot
}) => {
  const start = item.startMonth ? daysInMonth(item.startMonth) : 31;
  const end = item.endMonth ? daysInMonth(item.endMonth) : 31;

  const [ endDays, setEndDays ] = React.useState<Array<IOption>>(daysArray(start));
  const [ startDays, setStartDays ] = React.useState<Array<IOption>>(daysArray(end));

  const handleStartMonthChange = (event:React.ChangeEvent<HTMLSelectElement>) => {
    if ( event.target.value ){
      const days = daysInMonth(event.target.value);
      setStartDays(daysArray(days));
    }
  }

  const handleEndMonthChange = (event:React.ChangeEvent<HTMLSelectElement>) => {
    if ( event.target.value ){
      const days = daysInMonth(event.target.value);
      setEndDays(daysArray(days));
    }
  }

  return (
    <React.Fragment>
      <div key={`slot-item-${item.uuid}`} className="row row-8">
        <div className="col-3">
          <Select
            controlRef={register({ required: isRequired })} defaultValue={item.startMonth}
            label="Month" id={`startMonth-${index}`} name={`startMonth-${index}`}
            required={true}
            options={[
              { value: '', label: 'Choose month' },
              ...Object.keys(months).map((key:string) => ({
                value: key, label: mm[key]
              }))
            ]}
            onChange={handleStartMonthChange}
            errorText={errors[`startMonth-${index}`] ? errors[`startMonth-${index}`].message : null}
          />
        </div>
        <div className="col-2">
          <Select
            controlRef={register({ required: isRequired })} defaultValue={item.startDay}
            label="Day" id={`startDay-${index}`} name={`startDay-${index}`}
            required={true}
            options={startDays}
            errorText={errors[`startDay-${index}`] ? errors[`startDay-${index}`].message : null}
          />
        </div>
        <div className="col-3">
          <Select
            controlRef={register({ required: isRequired })} defaultValue={item.endMonth}
            label="Month" id={`endMonth-${index}`} name={`endMonth-${index}`}
            required={true}
            options={[
              { value: '', label: 'Choose month' },
              ...Object.keys(months).map((key:string) => ({
                value: key, label: mm[key]
              }))
            ]}
            onChange={handleEndMonthChange}
            errorText={errors[`endMonth-${index}`] ? errors[`endMonth-${index}`].message : null}
          />
        </div>
        <div className="col-2">
          <Select
            controlRef={register({ required: isRequired })} defaultValue={item.endDay}
            label="Day" id={`endDay-${index}`} name={`endDay-${index}`}
            required={true}
            options={endDays}
            errorText={errors[`endDay-${index}`] ? errors[`endDay-${index}`].message : null}
          />
        </div>
        <div className="col-2">
          <div className="form-group">
            <label htmlFor="">&nbsp;</label>
            <button
              className="btn btn-danger btn-sm btn-block"
              type="button"
              onClick={() => onDeleteSlot(item.uuid)}
            ><i className="fa fa-trash" aria-hidden="true"></i></button>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const daysArray = (days:number) => {
  return Array.from({length: days}, (_, i) => ({ value: i + 1, label: i + 1 }));
}

export default SeasonsSlotListItem;
