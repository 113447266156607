import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
// Redux
import { connect } from 'react-redux';
import OrderActions from 'app/actions/order.actions';
import { ReservationActions } from 'app/store/reservation/reservation.actions';
import MembershipActions from 'store/membership/membership.actions';
// Bootstrap
import Modal from 'react-bootstrap/Modal';
// Components
import { ButtonLoading } from 'app/components/Buttons';
// Utilities
import { isRequired, isEmail } from 'utilities/validation';

const SendReceiptFormDialog = ({
  // Props
  id, email, transactionId, type, show, onHide, isLoading, status,
  // Dispatch
  sendOrderReceipt, sendReservationReceipt, sendMembershipReceipt
}) => {
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      email: email || ''
    }
  });

  const onSubmit = data => {
    if ( type === 'order' ) sendOrderReceipt(id, transactionId, data.email);
    if ( type === 'reservation' ) sendReservationReceipt(id, transactionId, data.email);
    if ( type === 'membership' ) sendMembershipReceipt(id, transactionId, { email: data.email });
  }

  useEffect(() => {
    if ( status === 'Success' ) onHide();
    // eslint-disable-next-line
  }, [status]);

  return (
    <Modal
      backdropClassName="modal-confirmation-backdrop"
      className="modal-confirmation"
      show={show}
      onHide={onHide}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Send receipt</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {/* E-mail */}
          <div className="form-group">
            <label htmlFor="email">E-mail *</label>
            <input
              ref={register({ required: isRequired, pattern: isEmail })}
              className={`
                form-control form-control-sm
                ${ errors.email ? 'is-invalid' : null }
              `}
              name="email" id="email" type="email"
            />
            {errors.email ? <div className="invalid-feedback">{errors.email.message}</div> : null}
          </div>

        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary btn-sm"
            type="button"
            onClick={onHide}
          >Cancel</button>
          <ButtonLoading
            loading={isLoading}
            type="submit"
          >Send</ButtonLoading>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

SendReceiptFormDialog.defaultProps = {
  id: null,
  transactionId: null,
  type: null, // order, reservation, null
  show: false,
  onHide: () => null,
  isFetching: false,
  status: 'Initial'
}

const mapDispatchToProps = dispatch => ({
  sendOrderReceipt: (id, transactionId, email) => dispatch(OrderActions.sendReceipt(id, transactionId, email)),
  sendReservationReceipt: (id, transactionId, email) => dispatch(ReservationActions.sendReceipt(id, transactionId, email)),
  sendMembershipReceipt: (id, transactionId, params) => dispatch(MembershipActions.sendMembershipReceipt(id, transactionId, params))
});

export default connect(null, mapDispatchToProps)(SendReceiptFormDialog);
