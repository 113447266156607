export const BookingActionTypes = {
  FetchBookings: '[Booking] Fetch bookings',
  FetchBookingsSuccess: '[Booking] Fetch bookings success',

  FetchBookingById: '[Booking] Fetch booking by id',
  FetchBookingByIdSuccess: '[Booking] Fetch booking by id success',

  CheckAvailability: '[Booking] Check availability',
  CheckAvailabilitySuccess: '[Booking] Check availability success',

  FetchMinDaysReservationNumber: '[Booking] Fetch min days reservation number',
  FetchMinDaysReservationNumberSuccess: '[Booking] Fetch min days reservation number success',

  Create: '[Booking] Create',
  CreateSuccess: '[Booking] Create success',

  Update: '[Booking] Update',
  UpdateSuccess: '[Booking] Update success',

  Delete: '[Booking] Delete',
  DeleteSuccess: '[Booking] Delete success',

  UpdateImage: '[Booking] Update image',
  UpdateImageSuccess: '[Booking] Update image success',

  // Actions
  Enable: '[Booking] Enable',
  EnableSuccess: '[Booking] Enable success',

  Disable: '[Booking] Disable',
  DisableSuccess: '[Booking] Disable success',

  // Actions
  SetDefaultWaiver: '[Booking] Set default waiver',
  SetDefaultWaiverSuccess: '[Booking] Set default waiver success',

  ResetDefaultWaiver: '[Booking] Reset default waiver',
  ResetDefaultWaiverSuccess: '[Booking] Reset default waiver success',

  // Set filter
  Filter: '[Booking] Filter',
  ClearFilter: '[Booking] Clear filter',
  // Status
  StatusSuccess: '[Booking] Status success',
  StatusError: '[Booking] Status error',
  StatusInitial: '[Booking] Status initial',
  Reset: '[Booking] Reset'
}

export const BookingMessageTypes = {
  Create: 'Booking property created',
  Update: 'Booking property updated',
  Delete: 'Booking property deleted',
  Enable: 'Booking property enabled',
  Disable: 'Booking property disabled',
  ResetDefault: 'No waiver is now linked to booking property',
  MinStaDuration: minDays => `Minimal stay duration for this start date is ${minDays} nights.`
}
