import create from './create-action';
import Types from '../types/order.types';

class OrderActions {
  // Fetch transactions list
  fetchTransactionsList = (orderId) => create(Types.FETCH_TRANSACTIONS_LIST, { orderId })
  fetchTransactionsListSuccess = (transactions) => create(Types.FETCH_TRANSACTIONS_LIST_SUCCESS, { transactions })
  fetchTransactionsListFailure = () => create(Types.FETCH_TRANSACTIONS_LIST_FAILURE)

  // Fetch all
  fetchAll = (params, isMore) => create(Types.FETCH_ALL, { params, isMore })
  fetchAllSuccess = (orders, params, isMore) => create(Types.FETCH_ALL_SUCCESS, { orders, params, isMore })
  fetchAllFailure = () => create(Types.FETCH_ALL_FAILURE)
  // Fetch by id
  fetchById = orderId => create(Types.FETCH_BY_ID, { orderId })
  fetchByIdSuccess = order => create(Types.FETCH_BY_ID_SUCCESS, { order })
  fetchByIdFailure = () => create(Types.FETCH_BY_ID_FAILURE)
  // Set status
  setStatus = (orderId, status) => create(Types.SET_STATUS, { orderId, status })
  setStatusSuccess = order => create(Types.SET_STATUS_SUCCESS, { order })
  setStatusFailure = () => create(Types.SET_STATUS_FAILURE)
  // Order
  sendReceipt = (orderId, transactionId, email) => create(Types.SEND_RECEIPT, { orderId, transactionId, email })
  sendReceiptSuccess = () => create(Types.SEND_RECEIPT_SUCCESS)
  sendReceiptFailure = () => create(Types.SEND_RECEIPT_FAILURE)
  // Check-in
  checkIn = (id, activityId, confirmationCode, status) => create(Types.CHECK_IN, { id, activityId, confirmationCode, status })
  checkInSuccess = (id, activity) => create(Types.CHECK_IN_SUCCESS, { id, activity })
  // Cancel
  cancel = (orderId, notes) => create(Types.CANCEL, { orderId, notes })
  cancelSuccess = orderId => create(Types.CANCEL_SUCCESS, { orderId })
  cancelFailure = () => create(Types.CANCEL_FAILURE)
  // Export
  export = params => create(Types.EXPORT, { params })
  exportSuccess = () => create(Types.EXPORT_SUCCESS)
  exportFailure = () => create(Types.EXPORT_FAILURE)
  // Other
  setInitialState = () => create(Types.SET_INITIAL_STATE)
  setIsFetched = () => create(Types.SET_IS_FETCHED)

  setStateStatus = status => create(Types.SET_STATE_STATUS, { status })

  setFilter = (name, value) => create(Types.SET_FILTER, { name, value })
};

const actions = new OrderActions();
export default actions;
