import React from 'react';
// Redux
import { connect } from 'react-redux';
import { ContentCategoryActions as Actions } from 'app/store/content-category/content-category.actions';
import * as Selectors from 'app/store/content-category/content-category.selectors';
// Components
import { DataMessage } from 'app/components/DataMessage';
// 
import ContentCategoriesCreate from './ContentCategoriesCreate';
import ContentCategoriesListItem from './ContentCategoriesListItem';

const ContentCategories = ({
  // State
  items,
  // Dispatch
  enableContentCategory, disableContentCategory, deleteContentCategory
}) => {

  const handleEnable = id => enableContentCategory(id);
  const handleDisable = id => disableContentCategory(id);
  const handleDelete = item => deleteContentCategory(item);

  return (
    <div className="container-fluid pt-3">
      <ContentCategoriesCreate />
      <DataMessage data={items}>
        <div className="row row-8">
          {items.map((item) => (
            <ContentCategoriesListItem
              key={`content-category-${item.id}`} 
              item={item}
              onEnable={handleEnable}
              onDisable={handleDisable}
              onDelete={handleDelete}
            />
          ))}
        </div>
      </DataMessage>
    </div>
  )
}

const mapStateToProps = state => ({
  items: Selectors.getItems(state)
});

const mapDispatchToProps = dispatch => ({
  enableContentCategory: IDBRequest => dispatch(Actions.enable(IDBRequest)),
  disableContentCategory: IDBRequest => dispatch(Actions.disable(IDBRequest)),
  deleteContentCategory: item => dispatch(Actions.delete(item))
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentCategories);
