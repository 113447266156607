import { call, put, takeLatest } from 'redux-saga/effects';
import { MessageTemplateService } from './message-template.service';
import * as LayoutActions from '../../actions/layout.actions';
import {
  FETCH_LIST, FETCH_BY_ID, UPDATE,
  MessageTemplateActions
} from './message-template.actions';

function* _fetchList(){
  try {
    const response = yield call(MessageTemplateService.fetchList);
    yield put(MessageTemplateActions.fetchListSuccess(response));
  } catch(error){
    console.error(error);
    yield put(MessageTemplateActions.setStatus('Error'));
  }
}

function* _fetchById({ payload }){
  try {
    const response = yield call(MessageTemplateService.fetchById, payload.id);
    yield put(MessageTemplateActions.fetchByIdSuccess(response));
  } catch(error){
    console.error(error);
    yield put(MessageTemplateActions.setStatus('Error'));
  }
}

function* _update({ payload }){
  try {
    const response = yield call(MessageTemplateService.update, payload.id, payload.data);
    yield put(MessageTemplateActions.updateSuccess(response));
    yield put(MessageTemplateActions.setStatus('Success'));
    yield put(MessageTemplateActions.setStatus('Initial'));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: 'Message template was updated'
    }));
  } catch(error){
    console.error(error);
    yield put(MessageTemplateActions.setStatus('Error'));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

export default function* (){
  yield takeLatest(FETCH_LIST, _fetchList);
  yield takeLatest(FETCH_BY_ID, _fetchById);
  yield takeLatest(UPDATE, _update);
}
