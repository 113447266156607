import React from 'react';
// Store
import { useDispatch, useSelector } from 'react-redux';
// Actions
import MembershipActions from 'store/membership/membership.actions';
// Selectors
import { getMembershipsParams } from 'store/membership/membership.selectors';
// 
import MembershipPassesFilter from './MembershipPassesFilter';
import MembershipPassesList from './MembershipPassesList';
import MembershipPassesPagination from './MembershipPassesPagination';

type Props = {};

const MembershipPassesPage:React.FC<Props> = () => {
  // Dispatch
  const dispatch = useDispatch();
  // State
  const params = useSelector((state:any) => getMembershipsParams(state));

  React.useEffect(() => {
    handleFetchMemberships(params);
    return () => {
      dispatch(MembershipActions.setInitialField('memberships'));
    }
    // eslint-disable-next-line
  }, []);

  const handleFetchMemberships = (params:any) => {
    dispatch(MembershipActions.fetchMemberships(params));
  }

  return (
    <div className="container-md pt-3 pb-3">
      <MembershipPassesFilter onFetchMemberships={handleFetchMemberships} />
      <MembershipPassesList />
      <MembershipPassesPagination onFetchMemberships={handleFetchMemberships} />
    </div>
  )
}

export default MembershipPassesPage;
