import React from 'react'
import { Modal } from 'react-bootstrap';
import {
  SquarePaymentsForm,
  CreditCardInput,
} from 'react-square-web-payments-sdk'
// Redux
import { connect } from 'react-redux';
import { ReservationActions } from 'app/store/reservation/reservation.actions';
// import * as ReservationSelectors from 'app/store/reservation/reservation.selectors';
import { getApplicationId, getLocationId } from 'store/setting/setting.selectors';

const SquarePaymentFormDialog = ({
  // Props
  data, show, onHide,
  // State
  applicationId, locationId, currentUser,
  // Dispatch
  createReservation
}) => {
  const cardTokenizeResponseReceived = (token) => {
    if ( !token || !token.token ) return;

    onHide();

    createReservation({
      ...data,
      paymentDetails: {
        square: { cardNonce: token.token }
      },
    });
  }

  const createVerificationDetails = () => ({
    amount: String(data.totalPrice),
    currencyCode: 'USD',
    intent: 'CHARGE',
    billingContact: {
      familyName: currentUser.lastName,
      givenName: currentUser.firstName,
      email: currentUser.email,
      phone: `${currentUser.callingCode}${currentUser.phone}`
    },
  });

  return (
    <Modal className="modal-payment" show={show} onHide={onHide} backdropClassName="modal-payment-backdrop">
      <Modal.Header closeButton>Payment</Modal.Header>
      <Modal.Body>

        <SquarePaymentsForm
          applicationId={applicationId}
          locationId={locationId}
          cardTokenizeResponseReceived={cardTokenizeResponseReceived}
          createVerificationDetails={createVerificationDetails}
        ><CreditCardInput /></SquarePaymentsForm>

      </Modal.Body>
    </Modal>
  )
}

const mapStateToProps = state => ({
  applicationId: getApplicationId(state),
  locationId: getLocationId(state),
  currentUser: state.auth.user
});

const mapDispatchToProps = dispatch => ({
  createReservation: data => dispatch(ReservationActions.create(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SquarePaymentFormDialog);
