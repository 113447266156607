import * as Types from '../types/schedule.types';
import { parseUTCDate, isInRange } from '../utilities/datetime.convert';

const initialState = {
  selectedDate: null,
  slots: [],
  selectedSlot: {},

  isFetching: false,
  isFormLoading: false,
  isFormSuccess: false
}

export default function(state = initialState, action){
  const { type } = action;
  switch (type){
    // Request
    case Types.FETCH_SCHEDULE_SLOTS:
      return {
        ...state,
        selectedDate: parseUTCDate(action.params.start),
        slots: [], isFetching: true
      };
    case Types.CREATE_SCHEDULE_SLOT:
    case Types.UPDATE_SCHEDULE_SLOT:
      return {...state, isFormLoading: true, isFormSuccess: false};
    // Success
    case Types.FETCH_SCHEDULE_SLOTS_SUCCESS:
      return _fetchScheduleSlotsSuccess(state, action.slots);
    case Types.CREATE_SCHEDULE_SLOT_SUCCESS:
      return _createScheduleSlotSuccess(state, action.slot);
    case Types.UPDATE_SCHEDULE_SLOT_SUCCESS:
      return _updateScheduleSlotSuccess(state, action.slot);
    case Types.DELETE_SCHEDULE_SLOT_SUCCESS:
      return _deleteScheduleSlotSuccess(state, action.slotId);
    // 
    case Types.SELECT_SCHEDULE_SLOT:
      return _selectScheduleSlot(state, action.slotId);
    case Types.CLEAR_SELECTED_SCHEDULE_SLOT:
      return {...state, selectedSlot: {}}
    // Failure
    case Types.RESET_FORM:
    case Types.CREATE_SCHEDULE_SLOT_FAILURE:
    case Types.UPDATE_SCHEDULE_SLOT_FAILURE:
      return {...state, isFormLoading: false, isFormSuccess: false};
    default:
      return state;
  }
}

// Fetch schedule slots
// const _fetchScheduleSlots = state => ({
//   ...state,
//   slots: [], isFetching: true
// });

const _fetchScheduleSlotsSuccess = (state, slots) => ({
  ...state,
  slots, isFetching: false
});

// Select schedule slot
const _selectScheduleSlot = (state, slotId) => ({
  ...state,
  selectedSlot: state.slots.find(s => s.id === slotId)
});

// Create schedule slot
const _createScheduleSlotSuccess = (state, slot) => {
  const inRange = state.selectedDate ? isInRange(slot.start, slot.end, state.selectedDate) : false;
  return {
    ...state,
    slots: inRange ? [...state.slots, slot] : state.slots,
    isFormLoading: false, isFormSuccess: true
  }
};

// Update schedule slot
const _updateScheduleSlotSuccess = (state, slot) => ({
  ...state,
  slots: state.slots.map(s => s.id === slot.id ? {...s, ...slot} : s),
  isFormLoading: false, isFormSuccess: true
});

// Delete schedule slot
const _deleteScheduleSlotSuccess = (state, slotId) => ({
  ...state,
  slots: state.slots.filter(s => s.id !== slotId)
});
