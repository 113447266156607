import { Action } from 'redux';
import ModuleNotificationTypes from 'types/module-notification-types';
import Platforms from 'types/platforms';
import Roles from 'types/roles';
import Statuses from 'types/statuses';

export interface IStaff {
  appRegisteredOn: string;
  email: string;
  enabled: boolean;
  firstName: string;
  id: number;
  lastName: string;
  notificationPreferences: {
    sms: boolean;
    email: boolean;
  };
  callingCode: string;
  phone: string;
  platform: Platforms;
  role: Roles;
  biometricSignInEnabled: boolean;
  moduleNotificationTypes: Array<ModuleNotificationTypes>;
}

export interface IStaffData {
  role?: Roles;
  email: string;
  firstName: string;
  lastName: string;
  callingCode: string;
  phone: string;
  notificationPreferences?: {
    sms: boolean;
    email: boolean;
  };
  biometricSignInEnabled: boolean;
  moduleNotificationTypes?: Array<ModuleNotificationTypes>
}

export interface IStaffState {
  items: Array<IStaff> | null;
  isFetching: boolean;
  isLoading: boolean;
  status: Statuses;
}

// Messages
export enum StaffMessages {
  Create = 'User was created',
  Update = 'User was updated',
  Delete = 'User was deleted'
};

// Types
export const FETCH_LIST = '@@staff/Fetch list';
export const FETCH_LIST_SUCCESS = '@@staff/Fetch list success';

export const CREATE = '@@staff/Create';
export const CREATE_SUCCESS = '@@staff/Create success';

export const UPDATE = '@@staff/Update';
export const UPDATE_SUCCESS = '@@staff/Update success';

export const ENABLE = '@@staff/Enable';
export const ENABLE_SUCCESS = '@@staff/Enable success';

export const DISABLE = '@@staff/Disable';
export const DISABLE_SUCCESS = '@@staff/Disable success';

export const DELETE = '@@staff/Delete';
export const DELETE_SUCCESS = '@@staff/Delete success';

export const SET_STATUS = '@@staff/Set status';

// Actions
interface FetchList extends Action<typeof FETCH_LIST> {}
interface FetchListSuccess extends Action<typeof FETCH_LIST_SUCCESS> {
  payload: { items:Array<IStaff> }
}

interface Create extends Action<typeof CREATE> {
  payload: { data:IStaffData }
}
interface CreateSuccess extends Action<typeof CREATE_SUCCESS> {
  payload: { staff:IStaff }
}

interface Update extends Action<typeof UPDATE> {
  payload: { id:number, data:IStaffData }
}
interface UpdateSuccess extends Action<typeof UPDATE_SUCCESS> {
  payload: { staff:IStaff }
}

interface Enable extends Action<typeof ENABLE> {
  payload: { id:number }
}
interface EnableSuccess extends Action<typeof ENABLE_SUCCESS> {
  payload: { id:number }
}

interface Disable extends Action<typeof DISABLE> {
  payload: { id:number }
}
interface DisableSuccess extends Action<typeof DISABLE_SUCCESS> {
  payload: { id:number }
}

interface Delete extends Action<typeof DELETE> {
  payload: { staff:IStaff }
}
interface DeleteSuccess extends Action<typeof DELETE_SUCCESS> {
  payload: { id:number }
}

interface SetStatus extends Action<typeof SET_STATUS> {
  payload: { status:Statuses }
}

export type StaffActionTypes =
  FetchList | FetchListSuccess |
  Create | CreateSuccess |
  Update | UpdateSuccess |
  Enable | EnableSuccess |
  Disable | DisableSuccess |
  Delete | DeleteSuccess |
  SetStatus
;