import React from 'react';
import { useLocation } from 'react-router-dom';
// Models
import { IMembership } from 'models/Membership';
// Store
import { useDispatch, useSelector } from 'react-redux';
// Actions
import MembershipActions from 'store/membership/membership.actions';
// Selectors
import { getMembership, getIsLoading } from 'store/membership/membership.selectors';
// Components
import { Loader } from 'components/Utility';
import { ButtonLoading } from 'app/components/Buttons';

type IProps = {}

const MembershipCheckInPage:React.FC<IProps> = () => {
  const { search } = useLocation();
  // Dispatch
  const dispatch = useDispatch();
  // State
  const membership:IMembership = useSelector((state) => getMembership(state));
  const isLoading:boolean = useSelector((state) => getIsLoading(state));

  const query = new URLSearchParams(search);
  const membershipNumber:string = query.get('membershipNumber') || '';

  React.useEffect(() => {
    if ( membershipNumber ) dispatch(MembershipActions.fetchMembershipByNumber(Number(membershipNumber)))
    // eslint-disable-next-line
  }, []);

  const handleCheckIn = () => {
    dispatch(MembershipActions.checkInMembership(membership.id))
  }

  if ( !membershipNumber ) return (
    <div className="container-sm pt-3 pb-3">
      <div className="bg-white rounded shadow-sm p-4">
        <h4 className="d-block text-center mb-0">Cannot find the membership number</h4>
      </div>
    </div>
  );
  if ( !membership ) return <Loader />;

  const { membershipTypeData, userData } = membership;

  return (
    <div className="container-sm pt-3 pb-3">
      <div className="card">
        <div className="card-body">
          <div className="row row-8">
            <div className={membership.status === 'active' ? 'col-9' : 'col-12'}>
              <div className="d-flex align-items-center mb-2">
                <h5 className="mb-0 mr-3">{membershipTypeData.name}</h5>
                <span className={`badge ${membership.status === 'active' ? 'badge-success' : 'badge-secondary'}`}>
                  {membership.status}
                </span>
              </div>
              <div className="d-flex">
                {membership.membershipNumber ? (
                  <div className="d-flex flex-column pr-3">
                    <small className="text-secondary">Membership number</small>
                    <span style={{ fontWeight: 500 }}>{membership.membershipNumber}</span>
                  </div>
                ) : null}
              </div>
            </div>
            {membership.status === 'active' ? (
              <div className="col-3">
                <ButtonLoading
                  loading={isLoading}
                  classes="btn btn-primary btn-sm btn-block"
                  type="button"
                  onClick={handleCheckIn}
                >Check In</ButtonLoading>
              </div>
            ) : null}
          </div>
          <hr />
          <div className="d-flex">
            <div className="d-flex flex-column pr-3">
              <small className="text-secondary">User</small>
              <span style={{ fontWeight: 500 }}>{userData.firstName} {userData.lastName}</span>
            </div>
            {userData.email ? (
              <div className="d-flex flex-column pr-3">
                <small className="text-secondary">E-mail</small>
                <span style={{ fontWeight: 500 }}>{userData.email}</span>
              </div>
            ) : null}
            {userData.phone ? (
              <div className="d-flex flex-column pr-3">
                <small className="text-secondary">Phone</small>
                <span style={{ fontWeight: 500 }}>{userData.callingCode}{userData.phone}</span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MembershipCheckInPage;
