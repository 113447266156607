import React, { useEffect } from 'react';
// Redux
import { connect } from 'react-redux';
import { WaiverActions } from 'app/store/waiver/waiver.actions';
import * as WaiverSelectors from 'app/store/waiver/waiver.selectors';
// Bootstrap
import Modal from 'react-bootstrap/Modal';
// Components
import { ContentLoading } from 'app/components/Loadings';

const WaiverPreviewModal = ({
  // Props
  waiverId, show, onHide,
  // State
  waiver, status,
  // Dispatch
  fetchWaiverById, clearWaiver
}) => {

  useEffect(() => {
    if ( waiverId ) fetchWaiverById(waiverId);
    return () => clearWaiver();
    // eslint-disable-next-line
  }, []);

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <ContentLoading data={waiver} status={status === 'ModalFetching' ? 'Fetching' : 'Fetched'}>
        <Modal.Header>{waiver.name}</Modal.Header>
        <Modal.Body>
          <textarea
            className="form-control form-control-sm"
            value={waiver.content}
            rows={32}
            onChange={() => {}}
            readOnly
          ></textarea>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-secondary btn-sm"
            type="button"
            onClick={onHide}
          >Close</button>
        </Modal.Footer>
      </ContentLoading>
    </Modal>
  )
}

WaiverPreviewModal.defaultProps = {
  waiverId: null
}

const mapStateToProps = ({ WaiverState }) => ({
  waiver: WaiverSelectors.getWaiver(WaiverState),
  status: WaiverSelectors.getStatus(WaiverState)
});

const mapDispatchToProps = dispatch => ({
  fetchWaiverById: waiverId => dispatch(WaiverActions.fetchById(waiverId)),
  clearWaiver: () => dispatch(WaiverActions.clearWaiver())
});

export default connect(mapStateToProps, mapDispatchToProps)(WaiverPreviewModal);
