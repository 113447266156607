import fetcher from 'app/utilities/fetcher';

class ProductService {
  constructor(){
    this._url = '/admin/store/products';
  }

  // Fetch products list
  fetchAll = params => {
    const searchParams = new URLSearchParams();
    Object.keys(params).forEach(key => {
      if ( params[key] ) searchParams.append(key, params[key]);
    });
    return fetcher(`${this._url}?${searchParams}`, {
      method: 'GET'
    });
  }

  // Fetch product by id
  fetchById = productId => {
    return fetcher(`${this._url}/${productId}`, {
      method: 'GET'
    });
  }

  // Create product
  create = ({ data, image }) => {
    let formData = new FormData();
    if ( image && image.type ) formData.append('image', image);
    formData.append('data', new Blob([JSON.stringify(data)], {
      type: 'application/json'
    }));
    return fetcher(this._url, {
      method: 'POST',
      body: formData
    });
  }

  // Update product
  update = product => {
    return fetcher(`${this._url}/${product.id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(product)
    });
  }

  // Delete product
  delete = productId => {
    return fetcher(`${this._url}/${productId}`, {
      method: 'DELETE'
    }, () => null);
  }

  // Create or update image
  uploadImage = (productId, image) => {
    let formData = new FormData();
    formData.append('image', image);
    return fetcher(`${this._url}/${productId}/image`, {
      method: 'POST',
      body: formData
    });
  }

  // Delete image
  deleteImage = productId => {
    return fetcher(`${this._url}/${productId}/image`, {
      method: 'DELETE'
    }, () => null);
  }
}

const service = new ProductService();
export default service;
