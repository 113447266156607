import fetcher from 'app/utilities/fetcher';

class InventoryGroupService {
  constructor(){
    this._url = '/admin/store/inventoryGroups';
  }
  // Fetch all
  // @params {Object} params
  // @params {string} params.category
  // @params {boolean} params.enabled
  // @params {number|string} params.limit
  // @params {number|string} params.offset
  // @params {string} params.sort
  fetchAll = (limit, offset) => {
    const params = new URLSearchParams();
    if ( limit ) params.append('limit', limit);
    if ( offset ) params.append('offset', offset);
    return fetcher(`${this._url}?${params}`, {
      method: 'GET'
    });
  }
  // Fetch by id
  // @params {string|number} contenteItemId
  fetchById = inventoryGroupId => {
    return fetcher(`${this._url}/${inventoryGroupId}`, {
      method: 'GET'
    });
  }
  // Create
  // @params {object} contenteItem
  create = inventoryGroup => {
    return fetcher(`${this._url}`, {
      method: 'POST',
      body: JSON.stringify(inventoryGroup),
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
  // Update
  // @params {object} contenteItem
  update = inventoryGroup => {
    return fetcher(`${this._url}/${inventoryGroup.id}`, {
      method: 'PUT',
      body: JSON.stringify(inventoryGroup),
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
  // Delete
  // @params {string|number} inventoryGroupId
  delete = inventoryGroupId => {
    return fetcher(`${this._url}/${inventoryGroupId}`, {
      method: 'DELETE'
    }, () => null);
  }
  // Enable
  // @params {string|number} inventoryGroupId
  enable = inventoryGroupId => {
    return fetcher(`${this._url}/${inventoryGroupId}/actions/enable`, {
      method: 'POST'
    }, () => null)
  }
  // Disable
  // @params {string|number} inventoryGroupId
  disable = inventoryGroupId => {
    return fetcher(`${this._url}/${inventoryGroupId}/actions/disable`, {
      method: 'POST'
    }, () => null)
  }
}

const service = new InventoryGroupService();
export default service;