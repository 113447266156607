import { Reducer } from 'redux';
// Types
import { SettingTypes, SettingActionTypes } from './setting.types';
// Models
import { ISettingState } from './setting.models';

export default class SettingReducer {
  private static readonly _initialState:ISettingState = {
    companyName: '',
    companyPhone: '',
    displayName: '',
    membershipModuleEnabled: false,
    orderModuleEnabled: false,
    primaryColor: '',
    reservationModuleEnabled: false,
    remotelockIntegrationEnabled: false,
    squareApplicationId: '',
    squareLocationId: '',
    timeZone: ''
  };

  public static reducer:Reducer<ISettingState, SettingActionTypes> = (
    state = SettingReducer._initialState,
    action
  ) => {
    switch (action.type){
      // Fetch account settings
      case SettingTypes.FetchAccountSettings:
        return state;
      case SettingTypes.FetchAccountSettingsSuccess:
        return { ...state, ...action.payload.settings }
      default:
        return state;
    }
  }
}
