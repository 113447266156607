import React, { Component } from 'react';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear'

// Components
import { SelectDropdown } from '../../../components/Dropdowns';
// Constants
import { AppDays, AppMonths, AppYears } from '../../../App.constants';
// Utils
import { parseUTCDate } from '../../../utilities/datetime.convert';

class ScheduleCalendar extends Component {
  constructor(props){
    super(props);

    dayjs.extend(weekOfYear);

    const activeDate = dayjs();
    const activeMonth = activeDate.month();

    this.state = {
      calendar: [],

      activeDate,
      activeMonth,
      selectedDay: null,

      weekDays: AppDays(),

      calendarMonthOptions: {
        id: 'calendarMonth',
        name: 'calendarMonth',
        choises: AppMonths()
      },
      calendarYearOptions: {
        id: 'calendarYear',
        name: 'calendarYear',
        choises: AppYears()
      }
    }
  }

  componentDidMount(){
    const { activeDate, activeMonth } = this.state;
    this._buildCalendar(activeDate, activeMonth);
  }

  handlePrevMonth = evt => {
    this._setDate('subtract');
  }

  handleNextMonth = evt => {
    this._setDate('add');
  }

  handleSetMonth = month => {
    this._setDate(null, month);
  }

  handleSetYear = year => {
    this._setDate(null, year, false);
  }

  handleSelectDay = date => () => {
    this.setState({
      selectedDay: parseUTCDate(date) // dayjs(date)
    }, () => {
      this.props.onFetchScheduleSlots({
        start: dayjs(date).hour(0).minute(0).second(0).toISOString(),
        end: dayjs(date).hour(23).minute(59).second(59).toISOString()
      });
    });
  }

  _setDate = (type, value, isMonth = true) => {
    this.setState(prevState => {
      let activeDate;
      if ( isMonth ){
        activeDate = !value ? dayjs(prevState.activeDate)[type](1, 'month') : dayjs(prevState.activeDate).month(value);
      } else {
        activeDate = dayjs(prevState.activeDate).year(value);
      }
      return {
        ...prevState,
        activeDate,
        activeMonth: activeDate.month()
      }
    }, () => {
      const { activeDate, activeMonth } = this.state;
      this._buildCalendar(activeDate, activeMonth);
    })
  }

  _buildCalendar = (activeDate, activeMonth) => {
    const calendar = [];

    var date = dayjs(dayjs(activeDate).startOf('month')).startOf('week');
    var monthIndex = date.month();

    var done = false;
    var count = 0;

    while (!done) {
      calendar.push({
        days: this._buildWeek(date, activeMonth)
      });
      date = dayjs(date).add(1, 'week');
      done = count++ > 2 && monthIndex !== date.month();
      monthIndex = date.month();
    }

    this.setState({ calendar });
  }

  _buildWeek = (date, month) => {
    const days = [];
    for ( var i = 0; i < 7; i++ ){
      days.push({
        name: date.format('dddd'),
        number: date.date(),
        date,
        isSameMonth: date.month() === month,
        isSameDay: dayjs().isSame(date, 'day')
      });
      date = dayjs(date).add(1, 'day');
    }
    return days;
  }

  render() {
    const { calendar, activeDate, selectedDay, weekDays, calendarMonthOptions, calendarYearOptions } = this.state;
    return (
      <>
        <div className="calendar">
          <div className="calendar-head">
            <button
              className="btn btn-outline-primary btn-sm"
              type="button"
              onClick={this.handlePrevMonth}
            >Prev</button>
            <div className="d-flex">
              <SelectDropdown
                value={activeDate.format('M') - 1}
                options={calendarMonthOptions}
                onSetValue={this.handleSetMonth}
              />
              <SelectDropdown
                value={activeDate.format('YYYY')}
                options={calendarYearOptions}
                onSetValue={this.handleSetYear}
              />
            </div>
            <button
              className="btn btn-outline-primary btn-sm"
              type="button"
              onClick={this.handleNextMonth}
            >Next</button>
          </div>
          <div className="calendar-body">
            <div className="calendar-days">
              {weekDays.map((day, index) => (
                <span
                  key={index}
                  className="calendar-days-name"
                >{day.label}</span>
              ))}
            </div>
            {calendar.map((week, index) => (
              <div key={index} className="calendar-week">
                {week.days.map((day, i) => (
                  <div
                    key={i}
                    className={`calendar-day ${day.isSameMonth ? 'samr-month' : ''} ${day.isSameDay ? 'samr-day' : ''} ${selectedDay && dayjs(selectedDay).isSame(day.date, 'day') ? 'selected-day' : ''}`}
                    onClick={this.handleSelectDay(day.date)}
                  >
                    <span className="_number">{day.number}</span>
                  </div>
                ))}
              </div>
            ))}
          </div>
          {selectedDay && <div className="calendar-foot">{selectedDay}</div>}
        </div>
      </>
    )
  }
}

export default ScheduleCalendar;
