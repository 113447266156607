import React, { useState } from 'react';
// Redux
import { IMapPoi } from 'store/map-poi/map-poi.types';
// Dialogs
import MapPoiFormDialog from 'dialogs/MapPoiForm.dialog';

type IProps = {
  item: IMapPoi;
  onDelete: (id:number) => void;
}

const MapDetailsMarkersListItem:React.FC<IProps> = ({
  // Props
  item, onDelete
}) => {
  const [show, setShow] = useState<boolean>(false);

  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);

  const handleDelete = () => onDelete(item.id);

  return (
    <>
      <li className="list-group-item | d-flex justify-content-between align-items-center">
        <span className="flex-1">{item.name}</span>
        <div>
          <button
            className="btn btn-primary btn-sm | mr-2"
            type="button"
            onClick={handleShow}
          ><i className="fa fa-pen" aria-hidden="true"></i></button>
          <button
            className="btn btn-danger btn-sm"
            type="button"
            onClick={handleDelete}
          ><i className="fa fa-trash" aria-hidden="true"></i></button>
        </div>
      </li>
      {show ? (
        <MapPoiFormDialog
          marker={item}
          show={show}
          onHide={handleHide}
        />
      ) : null}
    </>
  )
}

export default MapDetailsMarkersListItem;
