import { call, put, takeLatest } from 'redux-saga/effects';
// Types
import Statuses from 'types/statuses';
import { MembershipTypeTypes, MembershipTypeMessages } from './membership-type.types';
// Actions
import * as LayoutActions from 'app/actions/layout.actions';
import MembershipTypeActions from './membership-type.actions';
// API
import MembershipAPI from './membership-type.api';

export default function* (){
  yield takeLatest(MembershipTypeTypes.FetchMembershipTypes, _fetchMembershipTypes);
  yield takeLatest(MembershipTypeTypes.FetchMembershipType, _fetchMembershipType);
  yield takeLatest(MembershipTypeTypes.CreateMembershipType, _createMembershipType);
  yield takeLatest(MembershipTypeTypes.UpdateMembershipType, _updateMembershipType);
  yield takeLatest(MembershipTypeTypes.DeleteMembershipType, _deleteMembershipType);
  yield takeLatest(MembershipTypeTypes.EnableMembershipType, _enableMembershipType);
  yield takeLatest(MembershipTypeTypes.DisableMembershipType, _disableMembershipType);
  yield takeLatest(MembershipTypeTypes.ResetDefaultWaiver, _resetDefaultWaiver);
  yield takeLatest(MembershipTypeTypes.SetDefaultWaiver, _setDefaultWaiver);
}

function* _fetchMembershipTypes(action:any){
  try {
    const { parseBody:membershipTypes } = yield call(MembershipAPI.fetchMembershipTypes);
    yield put(MembershipTypeActions.fetchMembershipTypesSuccess(membershipTypes));
  } catch(error:any){
    yield put(MembershipTypeActions.setStatus(Statuses.Error));
  }
}

function* _fetchMembershipType(action:any){
  try {
    const { membershipTypeId } = action.payload;
    const { parseBody:membership } = yield call(MembershipAPI.fetchMembershipType, membershipTypeId);
    yield put(MembershipTypeActions.fetchMembershipTypeSuccess(membership));
  } catch(error:any){
    yield put(MembershipTypeActions.setStatus(Statuses.Error));
  }
}

function* _createMembershipType(action:any){
  try {
    const { data } = action.payload;
    const { parseBody:membershipType } = yield call(MembershipAPI.createMembershipType, data);
    yield put(MembershipTypeActions.createMembershipTypeSuccess(membershipType));
    yield put(MembershipTypeActions.setStatus(Statuses.Success));
    yield put(MembershipTypeActions.setStatus(Statuses.Initial));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: MembershipTypeMessages.CreateMembershipType
    }));
  } catch(error:any){
    yield put(MembershipTypeActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _updateMembershipType(action:any){
  try {
    const { membershipTypeId, data } = action.payload;
    const { parseBody:membershipType } = yield call(MembershipAPI.updateMembershipType, membershipTypeId, data);
    yield put(MembershipTypeActions.updateMembershipTypeSuccess(membershipType));
    yield put(MembershipTypeActions.setStatus(Statuses.Success));
    yield put(MembershipTypeActions.setStatus(Statuses.Initial));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: MembershipTypeMessages.UpdateMembershipType
    }));
  } catch(error:any){
    yield put(MembershipTypeActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _deleteMembershipType(action:any){
  try {
    const { membershipTypeId } = action.payload;
    yield call(MembershipAPI.deleteMembershipType, membershipTypeId);
    yield put(MembershipTypeActions.deleteMembershipTypeSuccess(membershipTypeId));
    yield put(MembershipTypeActions.setStatus(Statuses.Success));
    yield put(MembershipTypeActions.setStatus(Statuses.Initial));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: MembershipTypeMessages.DeleteMembershipType
    }));
  } catch(error:any){
    yield put(MembershipTypeActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _enableMembershipType(action:any){
  try {
    const { membershipTypeId } = action.payload;
    yield call(MembershipAPI.enableMembershipType, membershipTypeId);
    yield put(MembershipTypeActions.enableMembershipTypeSuccess(membershipTypeId));
  } catch(error:any){
    yield put(MembershipTypeActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _disableMembershipType(action:any){
  try {
    const { membershipTypeId } = action.payload;
    yield call(MembershipAPI.disableMembershipType, membershipTypeId);
    yield put(MembershipTypeActions.disableMembershipTypeSuccess(membershipTypeId));
  } catch(error:any){
    yield put(MembershipTypeActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _resetDefaultWaiver(action:any){
  try {
    const { membershipTypeId } = action.payload;
    const { parseBody:membershipType } = yield call(MembershipAPI.resetDefaultWaiver, membershipTypeId);
    yield put(MembershipTypeActions.resetDefaultWaiverSuccess(membershipType));
  } catch(error:any){
    yield put(MembershipTypeActions.setStatus(Statuses.Error));
  }
}

function* _setDefaultWaiver(action:any){
  try {
    const { membershipTypeId } = action.payload;
    const { parseBody:membershipType } = yield call(MembershipAPI.setDefaultWaiver, membershipTypeId);
    yield put(MembershipTypeActions.setDefaultWaiverSuccess(membershipType));
  } catch(error:any){
    yield put(MembershipTypeActions.setStatus(Statuses.Error));
  }
}
