import HttpUtility from 'utilities/http.utility';
import { IStaffData } from './staff.types';

export default class StaffService {
  private static _url:string = '/admin/users';
  private static _http:HttpUtility = new HttpUtility();

  // Fetch list
  public static async fetchList():Promise<any> {
    return StaffService._http.get(`${StaffService._url}/staff`);
  }
  // Create
  public static async create(data:IStaffData):Promise<any> {
    return StaffService._http.post(`${StaffService._url}`, data);
  }
  // Update
  public static async update(id:number, data:IStaffData):Promise<any> {
    return StaffService._http.put(`${StaffService._url}/${id}`, data);
  }
  // Actions
  // Enable
  public static async enable(id:number):Promise<any> {
    return StaffService._http.post(`${StaffService._url}/${id}/actions/enable`);
  }
  // Disable
  public static async disable(id:number):Promise<any> {
    return StaffService._http.post(`${StaffService._url}/${id}/actions/disable`);
  }
  // Delete
  public static async delete(id:number):Promise<any> {
    return StaffService._http.delete(`${StaffService._url}/${id}`);
  }
}