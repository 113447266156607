import React from 'react';

const ButtonLoading = ({type = 'button', classes = 'btn btn-primary btn-sm', loading = false, onClick = () => {}, children}) => {
  return (
    <button
      className={classes}
      type={type}
      disabled={loading}
      onClick={onClick}
    >
      {loading && <i className="fa fa-spinner fa-pulse | mr-2"></i>}
      {children}
    </button>
  )
}

export default ButtonLoading;