import React from 'react';
// 
import MembershipTypesCreate from './MembershipTypesCreate';
import MembershipTypesList from './MembershipTypesList';

type Props = {};

const MembershipTypesPage:React.FC<Props> = () => {
  return (
    <div className="container-fluid pt-3 pb-3">
      <MembershipTypesCreate />
      <MembershipTypesList />
    </div>
  )
}

export default MembershipTypesPage;
