import { createSelector } from 'reselect';

const _campers = state => state.campers;
const _users = state => state.users;
const _params = state => state.params;
const _camperTotal = state => state.camperTotal;
const _status = state => state.status;

export const getCampers = createSelector(
  _campers, campers => {
    if ( !campers || campers.length === 0 ) return [];
    return campers;
  }
)
// export const getCampersOnSearch = createSelector(
//   _campers, campers => {
//     if ( !campers || campers.length === 0 ) return [];
//     return campers.map(c => ({ id: c.id, label: c.name }));
//   }
// )
export const getUsersOnSearch = createSelector(
  _users, users => {
    if ( !users || users.length === 0 ) return [];
    return users.map(c => ({ id: c.id, label: `${c.firstName} ${c.lastName}` }));
  }
)
export const getParams = createSelector(
  _params, params => params
);
export const getCamperTotal = createSelector(
  _camperTotal, camperTotal => camperTotal
);
export const getStatus = createSelector(
  _status, status => status
);
