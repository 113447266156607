import React from 'react';
import { useForm } from 'react-hook-form';
// Redux
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ReservationActions } from 'app/store/reservation/reservation.actions';
import * as ReservationSelectors from 'app/store/reservation/reservation.selectors';
// Components
import { Loader } from 'components/Utility';
// Utilities
import { isRequired, isMax } from 'utilities/validation';

type Props = {
  // Props
  reservation: any;
  // State
  transactionAmount: number;
  hasPaymentTransaction: boolean;
  isPaymentSameRefund: boolean;
  status: string;
  // Dispatch
  refundReservation: (reservatioId:number, data:any) => void;
  remainingPaymentReservation: (reservationId:number, data:any) => void;
};

const ReservationTransactionsForm:React.FC<Props> = ({
  // Props
  reservation,
  // State
  transactionAmount, hasPaymentTransaction, isPaymentSameRefund, status,
  // Dispatch
  refundReservation, remainingPaymentReservation
}) => {
  const type = setType(reservation.status, reservation.paymentType, reservation.paid);
  const amount = setAmount(type, transactionAmount, reservation.totalPrice, reservation.depositAmount);

  React.useEffect(() => {
    setValue('amount', amount);
    // eslint-disable-next-line
  }, [amount]);

  const { register, handleSubmit, errors, setValue } = useForm<any>({
    defaultValues: {
      amount,
      notes: ''
    }
  })

  const onSubmit = handleSubmit((data) => {
    const { amount, notes } = data;
    const newData:any = { amount: Number(amount) };
    if ( notes ) newData['notes'] = data.notes;
    if ( type === 'refund' ){
      refundReservation(reservation.id, newData);
    } else {
      remainingPaymentReservation(reservation.id, newData);
    }
  });

  if ( status === 'ModalFetching' ) return <Loader />;
  if ( !type ) return null;
  if ( type === 'refund' && !hasPaymentTransaction ) return null;
  if ( type === 'refund' && isPaymentSameRefund ) return null;
  return (
    <form onSubmit={onSubmit}>
      <div className="row row-8">
        <div className="col-12 col-sm-8 col-lg-10">
          <div className="input-group input-group-sm | mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text">$</span>
            </div>
            <input
              ref={register({ required: isRequired, max: isMax(amount) })}
              className={`form-control form-control-sm ${ errors.amount ? 'is-invalid' : '' }`}
              id="amount" name="amount" type="number" 
              placeholder="Amount *"
            />
            {errors.amount ? <div className="invalid-feedback">{errors.amount.message}</div> : null}
          </div>
        </div>
        <div className="col-12 col-sm-4 col-lg-2">
          <button
            className="btn btn-primary btn-block btn-sm"
            type="submit"
            disabled={status === 'Loading'}
          >
            {status === 'Loading' && <i className="fa fa-spinner fa-pulse | mr-2"></i>}
            {type === 'refund' ? 'Refund' : 'Pay remaining'}
          </button>
        </div>
      </div>
      <div className="form-group">
        <textarea
          ref={register()}
          className="form-control form-control-sm"
          id="notes" name="notes" placeholder="Notes"
          rows={3}
        ></textarea>
      </div>
    </form>
  )
}

const setType = (status:string, paymentType:string, paid:boolean) => {
  if ( status === 'active' && paymentType === 'online' && !paid ) return 'remaining';
  if ( status === 'cancelled' || status === 'completed' ) return 'refund';
  return '';
}

const setAmount = (type:string, transactionAmount:number, totalPrice:number, depositAmount:number) => {
  if ( !type ) return 0;
  if ( type === 'refund' ){
    return transactionAmount;
  } else {
    return depositAmount ? totalPrice - depositAmount : totalPrice;
  }
}

const mapStateToProps = (state:any) => ({
  transactionAmount: ReservationSelectors.getTransactionAmount(state.ReservationState),
  hasPaymentTransaction: ReservationSelectors.hasPaymentTransaction(state.ReservationState),
  isPaymentSameRefund: ReservationSelectors.isPaymentSameRefund(state.ReservationState),
  status: ReservationSelectors.getStatus(state.ReservationState),
});

const mapDispatchToProps = (dispatch:Dispatch) => ({
  refundReservation: (reservationId:number, data:any) => dispatch(ReservationActions.refund(reservationId, data)),
  remainingPaymentReservation: (reservationId:number, data:any) => dispatch(ReservationActions.remainingPayment(reservationId, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReservationTransactionsForm);
