import React from 'react';
// Layouts
import Details from '../../../layouts/Details';
// Utils
import { parseUTCDateTime } from '../../../utilities/datetime.convert';

const NotificationDetails = ({ notification, onHide }) => {
  return (
    <Details onClose={onHide}>
      <div className="form-group">
        <small>{parseUTCDateTime(notification.createdOn)}</small>
      </div>
      {notification.sender &&
        <div className="form-group">
          <small className="text-muted">Sender</small>
          <p className="mb-0">{notification.sender.name}</p>
        </div>
      }
      {/* E-mail */}
      {(notification.email && notification.email.subject) &&
        <div className="form-group">
          <hr />
          <h5 className="mb-1">E-mail</h5>
          <small className="text-muted">Subject</small>
          <p className="mb-0">{notification.email.subject}</p>
          <small className="text-muted">Body</small>
          <p className="mb-0">{notification.email.body}</p>
        </div>
      }
      {/* SMS */}
      {(notification.sms && notification.sms.text) &&
        <div className="form-group">
          <hr />
          <h5 className="mb-1">SMS</h5>
          <p className="mb-0">{notification.sms.text}</p>
        </div>
      }
      {/* InApp */}
      {(notification.inApp && notification.inApp.text) &&
        <div className="form-group">
          <hr />
          <h5 className="mb-1">In-App</h5>
          <p className="mb-0">{notification.inApp.text}</p>
        </div>
      }
      {(notification.recipients && notification.recipients.length > 0) &&
        <div className="form-group">
          <small className="text-muted">Recipients</small>
          <ul className="list-group" style={{ maxHeight: '320px', overflowY: 'auto' }}>
            {notification.recipients.map((recipient, index) => (
              <li key={index} className="list-group-item">{recipient.name}</li>
            ))}
          </ul>
        </div>
      }
    </Details>
  )
}

export default NotificationDetails;
