import React, { useEffect, useState } from 'react';
// Editor
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

type Props = {
  onChange: (_: any, editor: any) => void;
  onFocus?: (_: any, editor: any) => void;
  value: string;
  label?: string;
  required?: boolean;
}

const CustomEditor:React.FC<Props> = ({ onChange, onFocus, value, label, required }) => {
  const [data, setData] = useState(value);

  useEffect(() => {
    setData(value);
  }, [value]);

  return (
    <div>
      {label &&
        <label>{label}{required && <span>&nbsp;*</span>}</label>
      }
      <CKEditor
        config={{
          toolbar: [ 'undo', 'redo', '|', 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList' ],
        }}
        editor={ClassicEditor}
        data={data}
        onChange={onChange}
        onFocus={onFocus}
      />
    </div>
  );
}

export default CustomEditor;
