import {
  IMap, IMapData,
  MapActionTypes,
  FETCH_LIST, FETCH_LIST_SUCCESS,
  FETCH_BY_ID, FETCH_BY_ID_SUCCESS,
  CREATE, CREATE_SUCCESS,
  UPDATE, UPDATE_SUCCESS,
  DELETE, DELETE_SUCCESS,
  ENABLE, ENABLE_SUCCESS,
  DISABLE, DISABLE_SUCCESS,
  SET_AS_MAIN, SET_AS_MAIN_SUCCESS,
  SET_MAP_OPTIONS,
  SET_STATUS
} from './map.types';
import Statuses from 'types/statuses';

export default class MapActions {
  // Fetch list
  public static fetchList = ():MapActionTypes => ({
    type: FETCH_LIST
  });
  public static fetchListSuccess = (items: Array<IMap>):MapActionTypes => ({
    type: FETCH_LIST_SUCCESS, payload: { items }
  });
  // Fetch by id
  public static fetchById = (id:number):MapActionTypes => ({
    type: FETCH_BY_ID, payload: { id }
  });
  public static fetchByIdSuccess = (item:IMap):MapActionTypes => ({
    type: FETCH_BY_ID_SUCCESS, payload: { item }
  });
  // Create
  public static create = (data:IMapData):MapActionTypes => ({
    type: CREATE, payload: { data }
  });
  public static createSuccess = (item:IMap):MapActionTypes => ({
    type: CREATE_SUCCESS, payload: { item }
  });
  // Update
  public static update = (id:number, data:IMapData):MapActionTypes => ({
    type: UPDATE, payload: { id, data }
  });
  public static updateSuccess = (item:IMap):MapActionTypes => ({
    type: UPDATE_SUCCESS, payload: { item }
  });
  // Delete
  public static delete = (id:number):MapActionTypes => ({
    type: DELETE, payload: { id }
  });
  public static deleteSuccess = (id:number):MapActionTypes => ({
    type: DELETE_SUCCESS, payload: { id }
  });
  // Enable
  public static enable = (id:number):MapActionTypes => ({
    type: ENABLE, payload: { id }
  });
  public static enableSuccess = (id:number):MapActionTypes => ({
    type: ENABLE_SUCCESS, payload: { id }
  });
  // Disable
  public static disable = (id:number):MapActionTypes => ({
    type: DISABLE, payload: { id }
  });
  public static disableSuccess = (id:number):MapActionTypes => ({
    type: DISABLE_SUCCESS, payload: { id }
  });
  // Set as main
  public static setAsMain = (id:number):MapActionTypes => ({
    type: SET_AS_MAIN, payload: { id }
  });
  public static setAsMainSuccess = (id:number):MapActionTypes => ({
    type: SET_AS_MAIN_SUCCESS, payload: { id }
  });
  // Default
  public static setMapOptions = (options:{ lat:number, lng:number, zoom:number }):MapActionTypes => ({
    type: SET_MAP_OPTIONS, payload: { options }
  });
  // Set status
  public static setStatus = (status:Statuses):MapActionTypes => ({
    type: SET_STATUS, payload: { status }
  });
}
