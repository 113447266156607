import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
import { ReservationCategoryActions } from '../../../store/reservation-category/reservation-category.actions';
import * as ReservationCategorySelectors from '../../../store/reservation-category/reservation-category.selectors';
// Components
import { ContentLoading } from '../../../components/Loadings';
// 
import CategoriesFilter from './CategoriesFilter'
import CategoriesCreate from './CategoriesCreate';
import CategoriesListItem from './CategoriesListItem';

const Categries = props => {
  const {
    // Props
    // State
    categories, status,
    // Dispatch
    fetchCategories, clearFilter
  } = props;
  const { bookingId } = useParams();

  useEffect(() => {
    fetchCategories(bookingId);
    return () => clearFilter();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <CategoriesFilter />
      <CategoriesCreate />
      <ContentLoading data={categories} status={status}>
        <div className="row row-8">
          {categories.map((category, index) => (
            <CategoriesListItem
              key={index}
              category={category}
            />
          ))}
        </div>
      </ContentLoading>
    </>
  )
}

const mapStatToProps = state => ({
  categories: ReservationCategorySelectors.getCategories(state.ReservationCategoryState),
  status: ReservationCategorySelectors.getStatus(state.ReservationCategoryState)
});

const mapDispatchToProps = dispatch => ({
  fetchCategories: bookingId => dispatch(ReservationCategoryActions.fetchCategories(bookingId)),
  clearFilter: () => dispatch(ReservationCategoryActions.clearFilter())
});

export default connect(mapStatToProps, mapDispatchToProps)(Categries);
