import './DataMessage.scss';

import React from 'react';

const DataMessage = ({type, data, children}) => {
  return (
    <>
      {data && data.length === 0
        ? _buildMessage(_generateMessage(type))
        : children
      }
    </>
  )
}

function _generateMessage(type){
  switch (type){
    case 'user':
      return { icon: 'fa-users', text: 'No users' };
    case 'schedule':
      return { icon: 'fa-calendar-alt', text: 'No slots' };
    default:
      return { icon: 'fa-info', text: 'No data' };
  }
}

function _buildMessage({icon, text}){
  return (
    <div className="data-message">
      <i className={`fa ${icon} data-message-icon`} aria-hidden="true"></i>
      <span className="data-message-text">{text}</span>
    </div>
  );
}

export default DataMessage;
