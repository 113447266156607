import React from 'react';
// Store
import { useDispatch, useSelector } from 'react-redux';
// Actions
import MembershipActions from 'store/membership/membership.actions';
// Selectors
import { getVisitsParams } from 'store/membership/membership.selectors';
// React bootstrap
import { Modal } from 'react-bootstrap';
// 
import VisitsList from './VisitsList';
import VisitsPagination from './VisitsPagination';

type Props = {
  membershipId: number;
  show: boolean;
  onHide: () => void;
};

const VisitsDialog:React.FC<Props> = ({
  // Props
  membershipId, show, onHide
}) => {
  // Dispatch
  const dispatch = useDispatch();
  // State
  const params = useSelector((state:any) => getVisitsParams(state));

  React.useEffect(() => {
    handleFetchMembershipVisits(params);
    return () => {
      dispatch(MembershipActions.setInitialField('visits'));
    }
    // eslint-disable-next-line
  }, []);

  const handleFetchMembershipVisits = (params:any) => {
    dispatch(MembershipActions.fetchMembershipVisits(membershipId, params));
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>Visits</Modal.Header>
      <Modal.Body>
        <VisitsList />
        <VisitsPagination onFetchMembershipVisits={handleFetchMembershipVisits} />
      </Modal.Body>
    </Modal>
  )
}

export default VisitsDialog;
