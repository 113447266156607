import React from 'react';
// Models
import { IRemotelockDevice } from 'models/RemotelockDevice';
// Dialogs
import RemotelockDeviceAuditsDialog from 'dialogs/RemotelockDeviceAudits.dialog';

type Props = {
  device: IRemotelockDevice;
  onUnlockDevice: (deviceId:number) => void;
};

const RemotelockDevicesListItem:React.FC<Props> = ({
  // Props
  device, onUnlockDevice
}) => {
  const [ dialogOpen, setDialogOpen ] = React.useState<boolean>(false);

  const toggleDialog = () => setDialogOpen((prevState:boolean) => !prevState);

  const handleUnlockDevice = () => onUnlockDevice(device.id);
  return (
    <React.Fragment>
      <div className="card | w-100">
        <div className="card-body">
          <h5 className="card-title mb-1">{device.name}</h5>
          <small className="d-block text-muted mb-1">
            Type <b className="text-dark">{device.type}</b>
          </small>
          <small className="d-block text-muted">
            Serial number <b className="text-dark">{device.serialNumber}</b>
          </small>
          <small className="d-block text-muted">
            External ID <b className="text-dark">{device.externalId}</b>
          </small>
        </div>
        <div className="card-footer | text-right">
          <button
            className="btn btn-outline-primary btn-sm"
            type="button"
            onClick={toggleDialog}
          >Audit log</button>
          <button
            className="btn btn-primary btn-sm | ml-2"
            type="button"
            onClick={handleUnlockDevice}
          >Unlock</button>
        </div>
      </div>
      {dialogOpen ? (
        <RemotelockDeviceAuditsDialog
          deviceId={device.id}
          show={dialogOpen}
          onHide={toggleDialog}
        />
      ) : null}
    </React.Fragment>
  )
}

export default RemotelockDevicesListItem;
