import { WaiverActionTypes } from './waiver.types';

export const WaiverActions = {
  // Fetch list
  fetchList: bookingId => ({
    type: WaiverActionTypes.FetchList,
    bookingId
  }),
  fetchListSuccess: waivers => ({
    type: WaiverActionTypes.FetchListSuccess,
    waivers
  }),
  // Fetch by id
  fetchById: waiverId => ({
    type: WaiverActionTypes.FetchById,
    waiverId
  }),
  fetchByIdSuccess: waiver => ({
    type: WaiverActionTypes.FetchByIdSuccess,
    waiver
  }),
  clearWaiver: () => ({
    type: WaiverActionTypes.ClearWaiver
  }),
  // Create
  create: data => ({
    type: WaiverActionTypes.Create,
    data
  }),
  createSuccess: waiver => ({
    type: WaiverActionTypes.CreateSuccess,
    waiver
  }),
  // Update
  update: (waiverId, data) => ({
    type: WaiverActionTypes.Update,
    waiverId,
    data
  }),
  updateSuccess: waiver => ({
    type: WaiverActionTypes.UpdateSuccess,
    waiver
  }),
  // Delete
  delete: waiver => ({
    type: WaiverActionTypes.Delete,
    waiver
  }),
  deleteSuccess: waiverId => ({
    type: WaiverActionTypes.DeleteSuccess,
    waiverId
  }),
  // Set status
  setStatusSuccess: () => ({
    type: WaiverActionTypes.StatusSuccess
  }),
  setStatusError: () => ({
    type: WaiverActionTypes.StatusError
  }),
  setStatusInitial: () => ({
    type: WaiverActionTypes.StatusInitial
  }),
  reset: () => ({
    type: WaiverActionTypes.Reset
  })
}
