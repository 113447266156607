import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
import { ContactMessageActions } from 'app/store/contact-message/contact-message.actions';
import * as ContactMessageSelectors from 'app/store/contact-message/contact-message.selectors';
// Hooks
import useForm from 'app/hooks/useForm';

const parseQueryByName = (search, name) => {
  if ( !search ) return '';
  return new URLSearchParams(search).get(name);
}

const ConversationForm = ({
  // Props
  // State
  status, messages,
  // Dispatch
  markAsRead, sendInApp
}) => {
  const {search} = useLocation();
  const userId = parseQueryByName(search, 'userId');
  const messageId = parseQueryByName(search, 'messageId');
  const message = messages.find(m => m.id === Number(messageId));

  const callback = () => {
    if ( values.text && values.text.trim() ) sendInApp(userId, values.text);
  }

  const [values, _, handleChange, handleSubmit, handleSetValues] = useForm(callback, {
    text: ''
  }, () => { return {}; });

  useEffect(() => {
    if ( status === 'Success' ) handleSetValues({ text: '' });
    // eslint-disable-next-line
  }, [status]);

  const handleMarkAsRead = () => markAsRead(messageId);

  return (
    <form className="row row-8" onSubmit={handleSubmit} style={{position: 'relative'}}>
      <div className={message && message.status === 'unread' ? 'col-8' : 'col-10' }>
        <input
          autoFocus={true}
          className="form-control form-control-sm"
          value={values.text}
          name="text"
          onChange={handleChange}
        />
      </div>
      <div className="col-2">
        <button
          className="btn btn-primary btn-sm btn-block"
          type="submit"
        >Reply</button>
      </div>
      {message && message.status === 'unread' ? (
        <div className="col-2">
          <button
            className="btn btn-outline-primary btn-sm btn-block"
            type="button"
            onClick={handleMarkAsRead}
          >Mark as read</button>
        </div>
      ) : null}
    </form>
  )
}

const mapStateToProps = ({ ContactMessageState }) => ({
  messages: ContactMessageState.messages,
  status: ContactMessageSelectors.getStatus(ContactMessageState)
});

const mapDispatchToProps = dispatch => ({
  markAsRead: messageId => dispatch(ContactMessageActions.markAsRead(messageId)),
  sendInApp: (userId, text) => dispatch(ContactMessageActions.sendInApp(userId, text))
})

export default connect(mapStateToProps, mapDispatchToProps)(ConversationForm);
