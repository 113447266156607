export const UserActionTypes = {
  ForgotPassword: '[User] Forgot password',
  ForgotPasswordSuccess: '[User] Forgot password success',

  UpdatePassword: '[User] Update password',
  UpdatePasswordSuccess: '[User] Update password success',

  FetchMe: '[User] Fetch me',
  FetchMeSuccess: '[User] Fetch me success',

  UpdateMe: '[User] Update me',
  UpdateMeSuccess: '[User] Update me success',

  FetchListCampers: '[User] Fetch list campers',
  FetchListCampersSuccess: '[User] Fetch list campers success',

  UpdateCamper: '[User] Update camper',
  UpdateCamperSuccess: '[User] Update camper success',

  FetchList: '[User] Fetch list',
  FetchListSuccess: '[User] Fetch list success',

  FetchById: '[User] Fetch by id',
  FetchByIdSuccess: '[User] Fetch by id success',

  Create: '[User] Create',
  CreateSuccess: '[User] Create success',

  Update: '[User] Update',
  UpdateSuccess: '[User] Update success',

  Delete: '[User] Delete',
  DeleteSuccess: '[User] Delete success',

  Disable: '[User] Disable',
  DisableSuccess: '[User] Disable success',

  Enable: '[User] Enable',
  EnableSuccess: '[User] Enable success',

  CreateDeviceToken: '[User] Create device token',
  CreateDeviceTokenSuccess: '[User] Create device token success',

  DeleteDeviceToken: '[User] Delete device token',
  DeleteDeviceTokenSuccess: '[User] Delete device token success',

  StatusSuccess: '[User] Status success',
  StatusError: '[User] Status error',
  StatusInitial: '[User] Status initial'
};

export const UserMessageTypes = {
  ForgotPassword: 'Password reset link was sent to your email',
  Create: 'User was created',
  Update: 'User was updated',
  Delete: 'User was deleted',
  Disable: 'User was disabled',
  Enabled: 'User was enabled',
}
