import React from 'react'
// Models
import { IRemotelockDevice } from 'models/RemotelockDevice';
// Store
import { useDispatch, useSelector } from 'react-redux';
// Actions
import RemotelockDeviceActions from 'store/remotelock-device/remotelock-device.actions';
// Selectors
import { getRemotelockDevices } from 'store/remotelock-device/remotelock-device.selectors';
// Components
import { Loader, Message } from 'components/Utility';
// 
import RemotelockDevicesListItem from './RemotelockDevicesListItem';

const RemotelockDevicesList = () => {
  // Dispatch
  const dispatch = useDispatch();
  // State
  const remotelockDevices:Array<IRemotelockDevice> | null = useSelector((state:any) => getRemotelockDevices(state));

  const handleUnlockDevice = (deviceId:number) => dispatch(RemotelockDeviceActions.unlockDevice(deviceId));

  if ( !remotelockDevices ) return <Loader />;
  if ( remotelockDevices.length === 0 ) return <Message text="No locked device(s)" />
  return (
    <div className="row row-8">
      {remotelockDevices.map((device:IRemotelockDevice) => (
        <div
          key={`membership-type-item-${device.id}`}
          className="col col-lg-3 d-flex | mb-2"
        >
          <RemotelockDevicesListItem device={device} onUnlockDevice={handleUnlockDevice} />
        </div>
      ))}
    </div>
  )
}

export default RemotelockDevicesList;
