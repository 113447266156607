export const ProductCategoryActionTypes = {
  FetchList: '[Product category] Fetch list',
  FetchListSuccess: '[Product category] Fetch list success',
  // Create
  Create: '[Product category] Create',
  CreateSuccess: '[Product category] Create success',
  // Update
  Update: '[Product category] Update',
  UpdateSuccess: '[Product category] Update success',
  // Delete
  Delete: '[Product category] Delete',
  DeleteSuccess: '[Product category] Delete success',
  // Status
  StatusSuccess: '[Product category] Status success',
  StatusError: '[Product category] Status error',
  StatusInitial: '[Product category] Status initial'
}

export const ProductCategoryMessageTypes = {
  Create: 'Product category created',
  Update: 'Product category updated',
  Delete: 'Product category deleted'
}
