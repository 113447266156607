import React, { useState } from 'react';
// Redux
import { connect } from 'react-redux';
import { RestrictionActions } from '../../../store/restriction/restriction.actions';
// Bootstrap
import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
// Modal
import RestrictionFormModal from '../../../layouts/Modals/RestrictionFormModal';
// 
import { parseUTCDate } from '../../../utilities/datetime.convert';

const RestrictionsListItem = ({
  // Props
  restriction,
  // State
  // Dispatch
  deleteRestriction
}) => {
  const [ show, setShow ] = useState(false);
  return (
    <>
      <li className="list-group-item | d-flex align-items-center">
        <div className="list-group-item--body">
          {restriction.dayOfWeek &&
            <small
              className="d-block"
            >Day of week: <b>{restriction.dayOfWeek}</b></small>
          }
          {(restriction.start && restriction.end) &&
            <>
              <small
                className="d-block"
              >Start: <b>{parseUTCDate(restriction.start)}</b></small>
              <small
                className="d-block"
              >End: <b>{parseUTCDate(restriction.end)}</b></small>
            </>
          }
          {restriction.minDaysReservationNumber &&
            <small
              className="d-block"
            >Min reservation days: <b>{restriction.minDaysReservationNumber}</b></small>
          }
        </div>
        <div className="list-group-item--right">
          <DropdownButton
            as={ButtonGroup}
            alignRight
            className="ml-2"
            id={`dropdown-button-drop-${restriction.id}`}
            size="sm"
            variant="outline-secondary"
            title={''}
          >
            <Dropdown.Item as="button" onClick={() => setShow(true)}>Edit</Dropdown.Item>
            <Dropdown.Item as="button" className="text-danger" onClick={() => deleteRestriction(restriction)}>Delete</Dropdown.Item>
          </DropdownButton>
        </div>
      </li>
      {/* Modal */}
      {show &&
        <RestrictionFormModal
          show={show}
          restrictionId={restriction.id}
          onHide={() => setShow(false)}
        />
      }
    </>
  )
}

const mapDispatchToProps = dispatch => ({
  deleteRestriction: restriction => dispatch(RestrictionActions.delete(restriction))
});

export default connect(null, mapDispatchToProps)(RestrictionsListItem);
