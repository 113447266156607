import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
import { BookingItemActions } from '../../../store/booking-item/booking-item.actions';
import * as BookingItemSelectors from '../../../store/booking-item/booking-item.selectors';
// Components
import { ContentLoading } from '../../../components/Loadings';
// 
import BookingItemsFilter from './BookingItemsFilter';
import BookingItemsCreate from './BookingItemsCreate';
import BookingItemsListItem from './BookingItemsListItem';
// Selectors
import { getRemotelockIntegrationLocked } from 'store/setting/setting.selectors';

const BookingItems = props => {
  const {
    // Props
    // State
    bookingItems, status, remotelockIntegrationLocked,
    // Dispatch
    fetchBookingItems, clearFilter
  } = props;
  const { bookingId } = useParams();

  useEffect(() => {
    fetchBookingItems(bookingId);
    return () => clearFilter();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BookingItemsFilter />
      <BookingItemsCreate />
      <ContentLoading data={bookingItems} status={status}>
        <div className="row row-8">
          {bookingItems.map((bookingItem, index) => {
            if ( bookingItem.id === 0 ) return '';
            return (
              <BookingItemsListItem
                key={index}
                bookingItem={bookingItem}

                remotelockIntegrationLocked={remotelockIntegrationLocked}
              />
            )
          })}
        </div>
      </ContentLoading>
    </>
  )
}

const mapStateToProps = state => ({
  bookingItems: BookingItemSelectors.getBookingItemsSortedByBookingPropertyAndIdentifier(state.BookingItemState),
  status: BookingItemSelectors.getStatus(state.BookingItemState),

  remotelockIntegrationLocked: getRemotelockIntegrationLocked(state)
});

const mapDispatchToProps = dispatch => ({
  fetchBookingItems: bookingId => dispatch(BookingItemActions.fetchBookingItems(bookingId)),
  clearFilter: () => dispatch(BookingItemActions.clearFilter())
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingItems);
