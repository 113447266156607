import React from 'react';
// Redux
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import MailTemplateActions from 'store/mail-template/mail-template.actions';
import * as MailTemplateSelectors from 'store/mail-template/mail-template.selectors';
import { IMailTemplate } from 'store/mail-template/mail-template.types';
// Components
import { Container } from 'components/Container';
// 
import SystemEmailTemplatesListItem from './SystemEmailTemplatesListItem';

type IProps = {
  items:Array<IMailTemplate>;
  isFetching:boolean;
  fetchListTemplates:() => void;
}

const SystemEmailTemplatesList:React.FC<IProps> = ({
  // State
  items, isFetching,
  // Dispatch
  fetchListTemplates
}) => {

  React.useEffect(() => {
    fetchListTemplates();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="pt-3">
      <Container data={items} isFetching={isFetching}>
        <ul className="list-group">
          {items && items.map(item => (
            <SystemEmailTemplatesListItem
              key={`mail-template-${item.id}`}
              item={item}
            />
          ))}
        </ul>
      </Container>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  items: MailTemplateSelectors.getTemplates(state),
  isFetching: MailTemplateSelectors.getTemplatesFetching(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchListTemplates: () => dispatch(MailTemplateActions.fetchList())
});

export default connect(mapStateToProps, mapDispatchToProps)(SystemEmailTemplatesList);
