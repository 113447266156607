import React from 'react';

type Props = {
  // Props
  onAddSlot: () => void;
};

const SeasonSlotsAdd:React.FC<Props> = ({
  // Props
  onAddSlot
}) => {
  return (
    <div className="text-right mb-2">
      <button
        className="btn btn-outline-primary btn-sm"
        onClick={onAddSlot}
        type="button"
      >Add slot</button>
    </div>
  )
}

export default SeasonSlotsAdd;
