import { createSelector } from 'reselect';

const _bookings = state => state.bookings;
const _booking = state => state.booking;
const _status = state => state.status;

export const getBookings = createSelector(
  _bookings, state => state.filter,
  (bookings, filter) => {
    const { search } = filter;
    const nextSearch = search.toLowerCase();
    return bookings.filter(booking => {
      let hasSearch = false;
      hasSearch = !search
        ? true
        : (
            booking.name.toLowerCase().indexOf(nextSearch) > -1 ||
            booking.description.toLowerCase().indexOf(nextSearch) > -1
          )
      ;
      return hasSearch;
    })
  }
);
export const getBookingById = createSelector(
  _bookings, (_, props) => props,
  (bookings, bookingId) => {
    if ( !bookingId ) return {};
    return bookings.find(b => b.id === bookingId);
  }
);
export const getBooking = createSelector(
  _booking, booking => booking
);
export const getStatus = createSelector(
  _status, status => status
);
