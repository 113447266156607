import React from 'react';
// React bootstrap
import { Modal } from 'react-bootstrap';
// 
import OrderTransactionsList from './OrderTransactionsList';

type Props = {
  // Props
  orderId: number;
  email: string | null;
  show: boolean;
  onHide: () => void;
}

const OrderTransactionsDialog:React.FC<Props> = ({
  // Props
  orderId, email, show, onHide
}) => {
  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>Transactions</Modal.Header>
      <Modal.Body>
        <OrderTransactionsList orderId={orderId} email={email} />
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-outline-secondary btn-sm"
          type="button"
          onClick={onHide}
        >Close</button>
      </Modal.Footer>
    </Modal>
  )
}

export default OrderTransactionsDialog;
