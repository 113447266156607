import { UserActionTypes } from './user.types';

export const UserActions = {
  // Forgot password
  forgotPassword: email => ({
    type: UserActionTypes.ForgotPassword,
    email
  }),
  forgotPasswordSuccess: () => ({
    type: UserActionTypes.ForgotPasswordSuccess
  }),
  // Update password
  updatePassword: data => ({
    type: UserActionTypes.UpdatePassword,
    data
  }),
  updatePasswordSuccess: () => ({
    type: UserActionTypes.UpdatePasswordSuccess
  }),
  // Fetch me
  fetchMe: () => ({
    type: UserActionTypes.FetchMe
  }),
  fetchMeSuccess: () => ({
    type: UserActionTypes.FetchMeSuccess
  }),
  // Update me
  updateMe: (id, data) => ({
    type: UserActionTypes.UpdateMe,
    id,
    data
  }),
  updateMeSuccess: currentUser => ({
    type: UserActionTypes.UpdateMeSuccess,
    currentUser
  }),
  // Fetch list campers
  fetchListCampers: () => ({
    type: UserActionTypes.FetchListCampers
  }),
  fetchListCampersSuccess: campers => ({
    type: UserActionTypes.FetchListCampersSuccess,
    campers
  }),
  // Default
  setStatusSuccess: () => ({
    type: UserActionTypes.StatusSuccess
  }),
  setStatusError: () => ({
    type: UserActionTypes.StatusError
  }),
  setStatusInitial: () => ({
    type: UserActionTypes.StatusInitial
  })
}
