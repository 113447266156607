import { FinancialReportActionTypes } from './financial-report.types';
import { currentDate, firstDate } from 'app/utilities/datetime.convert';

const initialState = {
  summary: {},
  filter: {
    start: firstDate,
    end: currentDate
  },
  status: 'Initial'
};

export default (state = initialState, action) => {
  switch (action.type){
    case FinancialReportActionTypes.DownloadOrders:
    case FinancialReportActionTypes.DownloadOrdersSuccess:
    case FinancialReportActionTypes.DownloadReservations:
    case FinancialReportActionTypes.DownloadReservationsSuccess:
    case FinancialReportActionTypes.DownloadMemberships:
    case FinancialReportActionTypes.DownloadMembershipsSuccess:
      return state;
    case FinancialReportActionTypes.FetchListSummary:
      return { ...state, summary: initialState.summary, status: 'Fetching' };
    case FinancialReportActionTypes.FetchListSummarySuccess:
      return { ...state, summary: action.summary, status: 'Fetched' };

    // Filter
    case FinancialReportActionTypes.SetFilter:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.filterType]: action.filterValue
        }
      };
    case FinancialReportActionTypes.ResetFilter:
      return { ...state, filter: initialState.filter };

    // Set status
    case FinancialReportActionTypes.StatusSuccess:
      return { ...state, status: 'Success' };
    case FinancialReportActionTypes.StatusError:
      return { ...state, status: 'Error' };
    case FinancialReportActionTypes.StatusInitial:
      return { ...state, status: 'Initial' };
    default:
      return state;
  }
}
