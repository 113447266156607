import fetcher from '../../utilities/fetcher';

const apiURL = '/admin/bookingPropertyItemBlackouts';
const headers = {
  'Content-Type': 'application/json'
};

export const BlackoutService = {
  // Fetch blackouts
  fetchBlackouts: (bookingId, start, end) => {
    const params = new URLSearchParams();
    if ( bookingId ) params.append('bookingPropertyId', bookingId);
    if ( start ) params.append('start', start);
    if ( end ) params.append('end', end);
    return fetcher(`${apiURL}?${params}`);
  },
  // Create
  create: data => {
    return fetcher(`${apiURL}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(data)
    })
  },
  // Update
  update: (blackoutId, data) => {
    return fetcher(`${apiURL}/${blackoutId}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(data)
    })
  },
  // Delete
  delete: blackoutId => {
    return fetcher(`${apiURL}/${blackoutId}`, {
      method: 'DELETE'
    }, () => null)
  },
}
