import React, { Component } from 'react'

class SelectDropdown extends Component {
  constructor(props){
    super(props);

    const { value, options: { name } } = props;

    this.state = {
      [name]: value 
    }
  }

  componentWillReceiveProps(newProps){
    const { value, options: { name } } = newProps;
    this.setState({
      [name]: value
    });
  }

  handleChange = evt => {
    const { target: { name, value } } = evt;
    this.setState({
      [name]: value
    }, () => {
      this.props.onSetValue(value);
    });
  }

  render() {
    const { options: { id, name, choises = [] } } = this.props;
    return (
      <div className="form-group mb-0">
        <select
          className="form-control form-control-sm"
          id={id}
          name={name}
          onChange={this.handleChange}
          value={this.state[name]}
        >
          {choises.map((option, index) => (
            <option
              key={index}
              value={option.value}
            >{option.label}</option>
          ))}
        </select>
      </div>
    )
  }
}

export default SelectDropdown;
