import { Reducer } from 'redux';
// Types
import Statuses from 'types/statuses';
import { TaxesTypes, TaxesActionTypes, } from './taxes.types';
// Models
import { IState } from './taxes.models'
import { ITax } from 'models/Tax';

export default class TaxesReducer {
  private static readonly _initialState:IState = {
    taxes: null,
    tax: null,
    isLoading: false,
    status: Statuses.Initial
  };

  public static reducer:Reducer<IState, TaxesActionTypes> = (
    state = TaxesReducer._initialState,
    action
  ) => {
    switch (action.type){
      // Fetch taxes
      case TaxesTypes.FetchTaxes:
        return { ...state, taxes: null };
      case TaxesTypes.FetchTaxesSuccess:
        return { ...state, taxes: action.payload.taxes };
      // Fetch tax
      case TaxesTypes.FetchTax:
        return { ...state, tax: null };
      case TaxesTypes.FetchTaxSuccess:
        return { ...state, tax: action.payload.tax };
      // Create tax
      case TaxesTypes.CreateTax:
        return { ...state, isLoading: true };
      case TaxesTypes.CreateTaxSuccess:
        return {
          ...state,
          taxes: state.taxes
            ? [...state.taxes, action.payload.tax]
            : state.taxes
          ,
          isLoading: false
        };
      // Update tax
      case TaxesTypes.UpdateTax:
        return { ...state, isLoading: true };
      case TaxesTypes.UpdateTaxSuccess:
        return {
          ...state,
          taxes: state.taxes
            ? state.taxes.map((tax:ITax) => {
                if ( tax.id === action.payload.tax.id ) return action.payload.tax;
                return tax;
              })
            : state.taxes
          ,
          isLoading: false
        }
      // Delete tax
      case TaxesTypes.DeleteTax:
        return { ...state, isLoading: true };
      case TaxesTypes.DeleteTaxSuccess:
        return {
          ...state,
          taxes: state.taxes
            ? state.taxes.filter((tax:ITax) => {
                return tax.id !== action.payload.taxId
              })
            : state.taxes
          ,
          isLoading: false
        };
      // Default
      // Set status
      case TaxesTypes.SetStatus:
        const hasError:boolean = action.payload.status === Statuses.Error;
        return {
          ...state,
          isLoading: hasError ? false : state.isLoading,
          status: action.payload.status
        };
      default:
        return state;
    }
  }

}