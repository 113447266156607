import { createSelector } from 'reselect';
import { defaultDateFormat, parseDate } from 'app/utilities/datetime.convert';

const _conversation = state => state.conversation;
const _status = state => state.status;

export const getStatus = createSelector(_status, status => status);
export const getConversation = createSelector(
  _conversation,
  conversation => {
    const nextConversation = {};
    if ( !conversation || conversation.length === 0 ) return {};
    for ( let message of conversation ){
      const date = parseDate(message.createdOn, defaultDateFormat);
      const dateInConversation = nextConversation[date];
      if ( !dateInConversation ){
        const data = [message];
        nextConversation[date] = data;
      } else {
        nextConversation[date].push(message);
      }
    }
    return Object.keys(nextConversation).reduce((acc, cur) => {
      acc[cur] = nextConversation[cur].sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
      return acc;
    }, {});
    // console.log(rr);
    // return conversation;
  }
)
