import React from 'react';
// Redux
import { connect } from 'react-redux';
import { ReservationCategoryActions } from '../../../store/reservation-category/reservation-category.actions';

let timeout = null;

const CategoriesFilter = props => {
  const {
    // Props
    // State
    // Dispatch
    setFilter
  } = props;

  const handleChangeSearch = event => {
    const { value } = event.target;
    if ( timeout ) clearTimeout(timeout);
    timeout = setTimeout(() => {
      setFilter('search', value);
    }, 1000);
  }

  return (
    <div className="row row-8">
      <div className="col-12 col-sm-6 col-md-3">
        <div className="form-group">
          <label htmlFor="filterSearch">Search</label>
          <input
            className="form-control form-control-sm"
            id="filterSearch"
            type="text"
            name="filterSearch"
            placeholder="Name or description"
            onChange={handleChangeSearch}
          />
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  setFilter: (type, value) => dispatch(ReservationCategoryActions.setFilter(type, value))
});

export default connect(null, mapDispatchToProps)(CategoriesFilter);
