import fetcher from '../../utilities/fetcher';

const apiURL = '/admin/reservationCategories';
const mobileApiUrl = '/v1/reservationCategories';
const headers = {
  'Content-Type': 'application/json'
};

export const ReservationCategoryService = {
  // Fetch categories
  fetchCategories: (bookingId, enable) => {
    const params = new URLSearchParams();
    if ( bookingId ) params.append('bookingPropertyId', bookingId);
    if ( enable ) params.append('enable', enable);
    return fetcher(`${apiURL}?${params}`);
  },
  // Fetch available categories
  fetchAvailableCategories: (bookingId, quantity, start, end, hourQuantity) => {
    const params = new URLSearchParams();
    params.append('bookingPropertyId', bookingId);
    if ( quantity ) params.append('bookingPropertyItemMinQuantity', quantity);
    if ( start ) params.append('start', start);
    if ( end ) params.append('end', end);
    if ( hourQuantity && Number(hourQuantity) !== 0 ) params.append('hourQuantity', hourQuantity);
    return fetcher(`${mobileApiUrl}/available?${params}`);
  },
  // Create
  create: data => {
    return fetcher(`${apiURL}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(data)
    });
  },
  // Update
  update: (categoryId, data) => {
    return fetcher(`${apiURL}/${categoryId}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(data)
    });
  },
  // Delete
  delete: categoryId => {
    return fetcher(`${apiURL}/${categoryId}`, {
      method: 'DELETE'
    }, () => null);
  },
  // Actions
  // Enable
  enable: categoryId => {
    return fetcher(`${apiURL}/${categoryId}/actions/enable`, {
      method: 'POST',
      headers
    });
  },
  // Disable
  disable: categoryId => {
    return fetcher(`${apiURL}/${categoryId}/actions/disable`, {
      method: 'POST',
      headers
    });
  },
  // Image
  // Update
  updateImages: (categoryId, assetsIds) => {
    return fetcher(`${apiURL}/${categoryId}/images/update`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(assetsIds)
    });
  }
}
