import create from './create-type';

const _type = 'order';

const _actions = [
  'FETCH_ALL',
  'FETCH_BY_ID',
  'SET_STATUS',
  'SEND_RECEIPT',
  'CANCEL',
  'EXPORT',
  'CHECK_IN',
  'FETCH_TRANSACTIONS_LIST'
];

const Types = {};

for ( let i in _actions ){
  Types[_actions[i]] = create(_type, [_actions[i]]);
  Types[`${_actions[i]}_SUCCESS`] = create(_type, `${[_actions[i]]}_SUCCESS`);
  Types[`${_actions[i]}_FAILURE`] = create(_type, `${[_actions[i]]}_FAILURE`);
}

Types['SET_IS_FETCHED'] = create(_type, 'SET_IS_FETCHED');
Types['SET_INITIAL_STATE'] = create(_type, 'SET_INITIAL_STATE');

Types['SET_FILTER'] = create(_type, 'SET_FILTER');
Types['SET_STATE_STATUS'] = create(_type, 'SET_STATE_STATUS');

export default Types;
