import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
// Models
import { IMembershipType } from 'models/MembershipType';
// Bootstrap
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
// Dialog
import MembershipTypeFormDialog from 'dialogs/MembershipTypeForm.dialog';

type Props = {
  membershipType:IMembershipType;
  onEnable: (membershipTypeId:number) => void;
  onDisable: (membershipTypeId:number) => void;
  onDelete: (membershipTypeId:number) => void;
}

const MembershipTypesListItem:React.FC<Props> = ({
  // Props
  membershipType, onEnable, onDisable, onDelete
}) => {
  const { path } = useRouteMatch<{ path:string }>();

  const [ dialogOpen, setDialogOpen ] = React.useState<boolean>(false);

  const toggleDialog = () => setDialogOpen((prevState:boolean) => !prevState);

  const handleEnable = () => onEnable(membershipType.id);
  const handleDisable = () => onDisable(membershipType.id);
  const handleDelete = () => onDelete(membershipType.id);

  return (
    <React.Fragment>
      <div className="card | w-100">
        <div className="card-body">
          <h5 className="card-title mb-1">{membershipType.name}</h5>
          {membershipType.description ? (
            <small className="d-block text-muted mb-1">{membershipType.description}</small>
          ) : null}
          <div className="mb-1">
            <span className={`badge ${membershipType.enabled ? 'badge-success' : 'badge-danger'} | mr-1`}>
              {membershipType.enabled ? 'Enabled' : 'Disabled'}
            </span>
          </div>
          <small className="d-block text-muted mb-1">
            Price <b className="text-dark">${membershipType.price}</b>
          </small>
          {/* {membershipType.tax ? (
            <small className="d-block text-muted mb-1">
              Tax <b className="text-dark">{membershipType.tax}%</b>
            </small>
          ) : null} */}
          {membershipType.validityPeriodDays ? (
            <small className="d-block text-muted">
              Validity period <b className="text-dark">{membershipType.validityPeriodDays} day(s)</b>
            </small>
          ) : null}
        </div>
        <div className="card-footer | text-right">
          <button
            className="btn btn-primary btn-sm | mr-2"
            type="button"
            onClick={toggleDialog}
          >Edit</button>
          <DropdownButton
            as={ButtonGroup}
            alignRight
            id={`membership-type-item-${membershipType.id}-dropdown`}
            size="sm"
            variant="outline-secondary"
            title={''}
          >
            <Dropdown.Item as={Link} to={`${path}/${membershipType.id}/waivers`}>Waivers</Dropdown.Item>
            {membershipType.enabled ? (
              <Dropdown.Item as="button" onClick={handleDisable}>Disable</Dropdown.Item>
            ) : (
              <Dropdown.Item as="button" onClick={handleEnable}>Enable</Dropdown.Item>
            )}
            <Dropdown.Item
              as="button"
              className="text-danger"
              onClick={handleDelete}
            >Delete</Dropdown.Item>
          </DropdownButton>
        </div>
      </div>
      {dialogOpen ? (
        <MembershipTypeFormDialog
          membershipType={membershipType}
          show={dialogOpen}
          onHide={toggleDialog}
        />
      ) : null}
    </React.Fragment>
  )
}

export default MembershipTypesListItem;
