import fetcher from 'app/utilities/fetcher';

class AssetService {
  constructor(){
    this._url = '/admin/assets';

    this._CREATE_ASSET = 'File was uploaded';
    this._DELETE_ASSET = 'File was deleted';
  }
  // Create content item
  // @params {object} file
  createAsset = (file, folder = 'content/images') => {
    let formData = new FormData();
    // formData.append('data', new Blob([JSON.stringify({
    //   folder
    // })], { type: 'application/json' }));
    formData.append('file', file);
    return fetcher(this._url, {
      method: 'POST',
      body: formData
    }, null, this._CREATE_ASSET);
  }
  // Delete content item
  // @params {string|number} contenteItemId
  deleteAsset = fileId => {
    return fetcher(`${this._url}/${fileId}`, {
      method: 'DELETE'
    }, null, this._DELETE_ASSET);
  }
}

const service = new AssetService();
export default service;