import { Action } from 'redux';
// Types
import Statuses from 'types/statuses';
// Models
import { IRemotelockDevice, IRemotelockDeviceAudit } from 'models/RemotelockDevice';
import { IRemotelockDeviceState } from './remotelock-device.models';

// Types
export enum RemotelockDeviceTypes {
  FetchRemotelockDevices = '@@remotelockDevice/Fetch remotelock devices',
  FetchRemotelockDevicesSuccess = '@@remotelockDevice/Fetch remotelock devices success',

  FetchRemotelockDeviceAudits = '@@remotelockDevice/Fetch remotelock device audits',
  FetchRemotelockDeviceAuditsSuccess = '@@remotelockDevice/Fetch remotelock device audits success',

  UnlockDevice = '@@remotelockDevice/Unlock device',
  UnlockDeviceSuccess = '@@remotelockDevice/Unlock device success',

  SetStatus = '@@remotelockDevice/Set status',
  SetInitialField = '@@remotelockDevice/Set initial field'
}

interface FetchRemotelockDevices extends Action<typeof RemotelockDeviceTypes.FetchRemotelockDevices> {}
interface FetchRemotelockDevicesSuccess extends Action<typeof RemotelockDeviceTypes.FetchRemotelockDevicesSuccess> {
  payload: { remotelockDevices:Array<IRemotelockDevice> }
};

interface FetchRemotelockDeviceAudits extends Action<typeof RemotelockDeviceTypes.FetchRemotelockDeviceAudits> {
  payload: { deviceId:number, params:any }
};
interface FetchRemotelockDeviceAuditsSuccess extends Action<typeof RemotelockDeviceTypes.FetchRemotelockDeviceAuditsSuccess> {
  payload: { audits:Array<IRemotelockDeviceAudit>, auditTotal:number }
};

interface UnlockDevice extends Action<typeof RemotelockDeviceTypes.UnlockDevice> {
  payload: { deviceId:number }
};
interface UnlockDeviceSuccess extends Action<typeof RemotelockDeviceTypes.UnlockDeviceSuccess> {}

interface SetStatus extends Action<typeof RemotelockDeviceTypes.SetStatus> {
  payload: { status:Statuses }
};
interface SetInitialField extends Action<typeof RemotelockDeviceTypes.SetInitialField> {
  payload: { field:keyof IRemotelockDeviceState }
};

export type RemotelockDeviceActionTypes =
  FetchRemotelockDevices | FetchRemotelockDevicesSuccess |
  UnlockDevice | UnlockDeviceSuccess |
  FetchRemotelockDeviceAudits | FetchRemotelockDeviceAuditsSuccess |
  SetStatus | SetInitialField
;
