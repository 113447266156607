import { call, put, takeLatest } from 'redux-saga/effects';
// Store
import * as LayoutActions from '../../actions/layout.actions';
import { BookingActionTypes, BookingMessageTypes } from './booking.types';
import { BookingActions } from './booking.actions';
import { ReservationCategoryActions } from 'app/store/reservation-category/reservation-category.actions';
import { BookingService } from './booking.service';
// Utilities
import { setStart, buildRange } from 'app/utilities/datetime.convert';

const format = 'YYYY-MM-DDTHH:mm:ss.sss[Z]';

function* _fetchBookings(){
  try {
    const response = yield call(BookingService.fetchBookings);
    yield put(BookingActions.fetchBookingsSuccess(response));
    yield put(BookingActions.setStatusInitial());
  } catch(error){
    console.log(error);
    yield put(BookingActions.setStatusError());
  }
}

function* _fetchBookingById(action){
  try {
    const { bookingId } = action;
    const response = yield call(BookingService.fetchBookingById, bookingId);
    yield put(BookingActions.fetchBookingByIdSuccess(response));
  } catch(error){
    console.log(error);
    yield put(BookingActions.setStatusError());
  }
}

function* _checkAvailability(action){
  try {
    yield put(ReservationCategoryActions.clearAvailableCategories());
    const {booking, start, end, quantity, hourQuantity} = action;
    const nextStart = setStart(start, format);
    const nextEnd = setStart(end, format);
    const response = yield call(BookingService.checkAvailability, booking.id, nextStart, nextEnd);
    if ( response.available ){
      if ( booking.period !== 'hour' ){
        const days = yield call(BookingService.fetchMinDaysReservationNumber, booking.id, nextStart);
        const daysRange = buildRange(start, end);
        if ( days > daysRange.length ){
          yield put(BookingActions.setStatusError());
          yield put(LayoutActions.addNotification({
            type: 'danger',
            message: BookingMessageTypes.MinStaDuration(days)
          }));
        } else {
          yield put(ReservationCategoryActions.fetchAvailableCategories(booking.id, quantity, nextStart, nextEnd));
          yield put(BookingActions.checkAvailabilitySuccess());
        }
      } else {
        yield put(ReservationCategoryActions.fetchAvailableCategories(booking.id, quantity, nextStart, nextEnd, hourQuantity));
        yield put(BookingActions.checkAvailabilitySuccess());
      }
    } else {
      yield put(BookingActions.setStatusError());
      if ( response.message ){
        yield put(LayoutActions.addNotification({
          type: 'danger',
          message: response.message
        }));
      }
    }
  } catch(error){
    console.log(error);
  }
}

function* _create(action){
  try {
    const { data } = action;
    const response = yield call(BookingService.create, data);
    const imageResponse = yield call(BookingService.updateImages, response.id, data.images.map(image => image.id));
    yield put(BookingActions.createSuccess(imageResponse));
    yield put(BookingActions.setStatusSuccess());
    yield put(BookingActions.setStatusInitial());
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: BookingMessageTypes.Create
    }));
  } catch(error){
    console.log(error);
    yield put(BookingActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _update(action){
  try {
    const { bookingId, data } = action;
    yield call(BookingService.update, bookingId, data);
    const response = yield call(BookingService.updateImages, bookingId, data.images.map(image => image.id));
    const nextData = { ...response };
    if ( data.images.length === 0 ){
      nextData['images'] = [];
    }
    yield put(BookingActions.updateSuccess(nextData));
    yield put(BookingActions.setStatusSuccess());
    yield put(BookingActions.setStatusInitial());
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: BookingMessageTypes.Update
    }));
  } catch(error){
    console.log(error);
    yield put(BookingActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _delete(action){
  try {
    const { booking } = action;
    if ( window.confirm(`Are you sure you want to delete booking property: ${booking.name}?`) ){
      yield call(BookingService.delete, booking.id);
      yield put(BookingActions.deleteSuccess(booking.id));
      yield put(LayoutActions.addNotification({
        type: 'success',
        message: BookingMessageTypes.Delete
      }));
    }
  } catch(error){
    console.log(error);
    yield put(BookingActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _enable(action){
  try {
    const { bookingId } = action;
    yield call(BookingService.enable, bookingId);
    yield put(BookingActions.enableSuccess(bookingId));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: BookingMessageTypes.Enable
    }));
  } catch(error){
    console.log(error);
    yield put(BookingActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _disable(action){
  try {
    const { bookingId } = action;
    yield call(BookingService.disable, bookingId);
    yield put(BookingActions.disableSuccess(bookingId));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: BookingMessageTypes.Disable
    }));
  } catch(error){
    console.log(error);
    yield put(BookingActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _setDefaultWaiver(action){
  try {
    const { waiverId } = action;
    const response = yield call(BookingService.setDefaultWaiver, waiverId);
    yield put(BookingActions.setDefaultWaiverSuccess(response));
  } catch(error){
    yield put(BookingActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _resetDefaultWaiver(action){
  try {
    const { bookingId } = action;
    const response = yield call(BookingService.resetDefaultWaiver, bookingId);
    yield put(BookingActions.resetDefaultWaiverSuccess(response));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: BookingMessageTypes.ResetDefault
    }));
  } catch(error){
    yield put(BookingActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

export default function* (){
  yield takeLatest(BookingActionTypes.FetchBookings, _fetchBookings);
  yield takeLatest(BookingActionTypes.FetchBookingById, _fetchBookingById);
  yield takeLatest(BookingActionTypes.CheckAvailability, _checkAvailability);
  yield takeLatest(BookingActionTypes.Create, _create);
  yield takeLatest(BookingActionTypes.Update, _update);
  yield takeLatest(BookingActionTypes.Delete, _delete);
  yield takeLatest(BookingActionTypes.Enable, _enable);
  yield takeLatest(BookingActionTypes.Disable, _disable);
  yield takeLatest(BookingActionTypes.SetDefaultWaiver, _setDefaultWaiver);
  yield takeLatest(BookingActionTypes.ResetDefaultWaiver, _resetDefaultWaiver);
}