import { call, put, takeLatest } from 'redux-saga/effects';
// Store
import * as LayoutActions from 'app/actions/layout.actions';
import { BlackoutActionTypes, BlackoutMessageTypes } from './blackout.types';
import { BlackoutActions } from './blackout.actions';
import { BlackoutService } from './blackout.service';

function* _fetchBlackouts(action){
  try {
    const { bookingId, start, end } = action;
    const response = yield call(BlackoutService.fetchBlackouts, bookingId, start, end);
    yield put(BlackoutActions.fetchBlackoutsSuccess(response));
  } catch(error){
    console.log(error);
    yield put(BlackoutActions.setStatusError());
  }
}

function* _create(action){
  try {
    const { data } = action;
    const response = yield call(BlackoutService.create, data);
    yield put(BlackoutActions.createSuccess(response));
    yield put(BlackoutActions.setStatusSuccess());
    yield put(BlackoutActions.setStatusInitial());
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: BlackoutMessageTypes.Create
    }));
  } catch(error){
    console.log(error);
    yield put(BlackoutActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _update(action){
  try {
    const { blackoutId, data } = action;
    const response = yield call(BlackoutService.update, blackoutId, data);
    yield put(BlackoutActions.updateSuccess(response));
    yield put(BlackoutActions.setStatusSuccess());
    yield put(BlackoutActions.setStatusInitial());
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: BlackoutMessageTypes.Update
    }));
  } catch(error){
    console.log(error);
    yield put(BlackoutActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _delete(action){
  try {
    const { blackout } = action;
    if ( window.confirm(`Are you sure you want to delete booking item blackout: ${blackout.notes}?`) ){
      yield call(BlackoutService.delete, blackout.id);
      yield put(BlackoutActions.deleteSuccess(blackout.id));
      yield put(LayoutActions.addNotification({
        type: 'success',
        message: BlackoutMessageTypes.Delete
      }));
    }
  } catch(error){
    console.log(error);
    yield put(BlackoutActions.setStatusError());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

export default function* (){
  yield takeLatest(BlackoutActionTypes.FetchBlackouts, _fetchBlackouts);
  yield takeLatest(BlackoutActionTypes.Create, _create);
  yield takeLatest(BlackoutActionTypes.Update, _update);
  yield takeLatest(BlackoutActionTypes.Delete, _delete);
}