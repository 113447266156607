import { Action } from 'redux';
import Statuses from 'types/statuses';
import InventoryGroupTypes from 'types/inventory-group-types';

export interface IInventoryGroup {
  availability: {
    [key: string]: IDayAvailability
  },
  deliveryAvailable: boolean;
  enabled: boolean;
  id: number;
  name: string,
  orderManagers: Array<IOrderManager>,
  pickupLocation: string;
  tax: number;
  type: InventoryGroupTypes;
  waiverId?: number;
}

export interface IInventoryGroupData {
  availability?: {
    [key: string]: IDayAvailability
  },
  deliveryAvailable: boolean;
  name: string;
  orderManagerIds?: Array<number>;
  pickupLocation?: string;
  tax: 0;
  type?: InventoryGroupTypes;
}

interface IDayAvailability {
  available: boolean;
  time?: {
    end: string;
    start: string;
  }
}

interface IOrderManager {
  callingCode: string;
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  phone: string;
}

// State
export interface IInventoryGroupState {
  items: Array<IInventoryGroup> | null;
  item: IInventoryGroup | null;
  filter: {
    type: InventoryGroupTypes | ''
  }
  isFetching: boolean;
  isLoading: boolean;
  status: Statuses;
}

// Messages
export enum InventoryGroupMessages {
  Create = 'Inventory group was created',
  Update = 'Inventory group was updated',
  Delete = 'Inventory group was deleted',
  ResetDefaultWaiver = 'No waiver is now linked to inventory group'
}

// Types
export const FETCH_LIST = '@@inventoryGroup/Fetch list';
export const FETCH_LIST_SUCCESS = '@@inventoryGroup/Fetch list success';

export const FETCH_BY_ID = '@@inventoryGroup/Fetch by id';
export const FETCH_BY_ID_SUCCESS = '@@inventoryGroup/Fetch by id success';

export const CREATE = '@@inventoryGroup/Create';
export const CREATE_SUCCESS = '@@inventoryGroup/Create success';

export const UPDATE = '@@inventoryGroup/Update';
export const UPDATE_SUCCESS = '@@inventoryGroup/Update success';

export const DELETE = '@@inventoryGroup/Delete';
export const DELETE_SUCCESS = '@@inventoryGroup/Delete success';

export const ENABLE = '@@inventoryGroup/Enable';
export const ENABLE_SUCCESS = '@@inventoryGroup/Enable success';

export const DISABLE = '@@inventoryGroup/Disable';
export const DISABLE_SUCCESS = '@@inventoryGroup/Disable success';

export const RESET_DEFAULT_WAIVER = '@@inventoryGroup/Reset default waiver';
export const RESET_DEFAULT_WAIVER_SUCCESS = '@@inventoryGroup/Reset default waiver success';

export const SET_DEFAULT_WAIVER = '@@inventoryGroup/Set default waiver';
export const SET_DEFAULT_WAIVER_SUCCESS = '@@inventoryGroup/Set default waiver success';

export const SET_FILTER = '@@inventoryGroup/Set filter';
export const SET_STATUS = '@@inventoryGroup/Set status';
export const SetInitialField = '@@inventoryGroup/Set initial field';

// Actions
interface FetchList extends Action<typeof FETCH_LIST> {}
interface FetchListSuccess extends Action<typeof FETCH_LIST_SUCCESS> {
  payload: { items:Array<IInventoryGroup> }
}

interface FetchById extends Action<typeof FETCH_BY_ID> {
  payload: { id:number }
}
interface FetchByIdSuccess extends Action<typeof FETCH_BY_ID_SUCCESS> {
  payload: { item:IInventoryGroup }
}

interface Create extends Action<typeof CREATE> {
  payload: { data:IInventoryGroupData }
}
interface CreateSuccess extends Action<typeof CREATE_SUCCESS> {
  payload: { item:IInventoryGroup }
}

interface Update extends Action<typeof UPDATE> {
  payload: { id:number, data:IInventoryGroupData }
}
interface UpdateSuccess extends Action<typeof UPDATE_SUCCESS> {
  payload: { item:IInventoryGroup }
}

interface Delete extends Action<typeof DELETE> {
  payload: { id:number }
}
interface DeleteSuccess extends Action<typeof DELETE_SUCCESS> {
  payload: { id:number }
}

interface Enable extends Action<typeof ENABLE> {
  payload: { id:number }
}
interface EnableSuccess extends Action<typeof ENABLE_SUCCESS> {
  payload: { id:number }
}

interface Disable extends Action<typeof DISABLE> {
  payload: { id:number }
}
interface DisableSuccess extends Action<typeof DISABLE_SUCCESS> {
  payload: { id:number }
}

interface ResetDefaultWaiver extends Action<typeof RESET_DEFAULT_WAIVER> {
  payload: { id:number }
}
interface ResetDefaultWaiverSuccess extends Action<typeof RESET_DEFAULT_WAIVER_SUCCESS> {
  payload: { item:IInventoryGroup }
}

interface SetDefaultWaiver extends Action<typeof SET_DEFAULT_WAIVER> {
  payload: { waiverId:number }
}
interface SetDefaultWaiverSuccess extends Action<typeof SET_DEFAULT_WAIVER_SUCCESS> {
  payload: { item:IInventoryGroup }
}

interface SetFilter extends Action<typeof SET_FILTER> {
  payload: { field:string, value:string }
}
interface SetStatus extends Action<typeof SET_STATUS> {
  payload: { status:Statuses }
}
interface SetInitialField extends Action<typeof SetInitialField> {
  payload: { field:keyof IInventoryGroupState }
}

export type InventoryGroupActionTypes =
  FetchList | FetchListSuccess |
  FetchById | FetchByIdSuccess |
  Create | CreateSuccess |
  Update | UpdateSuccess |
  Delete | DeleteSuccess |
  Enable | EnableSuccess |
  Disable | DisableSuccess |
  ResetDefaultWaiver | ResetDefaultWaiverSuccess |
  SetDefaultWaiver | SetDefaultWaiverSuccess |
  SetFilter | SetStatus | SetInitialField
;

