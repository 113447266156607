import React from 'react';
// Dialog
import MembershipTypeFormDialog from 'dialogs/MembershipTypeForm.dialog';

type Props = {};

const MembershipTypesCreate:React.FC<Props> = () => {
  const [ dialogOpen, setDialogOpen ] = React.useState<boolean>(false);

  const toggleDialog = () => setDialogOpen((prevState:boolean) => !prevState);

  return (
    <React.Fragment>
      <div className="mb-3 text-right">
        <button
          className="btn btn-primary btn-sm"
          type="button"
          onClick={toggleDialog}
        >Create membership type</button>
      </div>
      {dialogOpen ? (
        <MembershipTypeFormDialog
          show={dialogOpen}
          onHide={toggleDialog}
        />
      ) : null}
    </React.Fragment>
  )
}

export default MembershipTypesCreate;
