import React, { useState } from 'react';
// Store
import { connect } from 'react-redux';
import OrderActions from '../../../actions/order.actions';
// Dialogs
import OrderCheckInDialog from 'dialogs/OrderCheckIn.dialog';
// Component
import { ButtonLoading } from '../../../components/Buttons';
// Utils
import { parseUTCDateTime } from '../../../utilities/datetime.convert';
// 
import OrderTransactionsDialog from './OrderTransactionsDialog';
import OrderCancelDialog from './OrderCancel.dialog';
import OrderListItemItem from './OrderListItemItem';

const OrderListItem = ({
  order, setStatus
}) => {
  const [ showOrderTransactionDialog, setShowOrderTransactionDialog ] = useState(false);
  const [ showOrderCheckInDialog, setShowOrderCheckInDialog ] = useState(false);
  const [ showOrderCancelDialog, setShowOrderCancelDialog ] = useState(false);

  const toggleOrderTransactionDialog = () => setShowOrderTransactionDialog(prevState => !prevState);
  const handleToggleOrderCheckInDialog = () => setShowOrderCheckInDialog(prevState => !prevState);
  const toggleOrderCancelDialog = () => setShowOrderCancelDialog(prevState => !prevState);

  const checkedIn = order.orderActivityItems
    ? order.orderActivityItems.some((item) => item.status === 'issued')
    : false
  ;

  return (
    <>
      <tr>
        <td><small className="text-muted">{order.orderNumber}</small></td>
        <td>
          {order.userData ? (
            <>
              <h5 className="mb-0">{order.userData.firstName} {order.userData.lastName}</h5>
              {order.userData.phone &&
                <p className="text-muted mb-0" style={{fontSize: '0.75rem'}}>{order.userData.callingCode}{order.userData.phone}</p>
              }
            </>
          ) : null}
          <small className="mb-0">{parseUTCDateTime(order.createdOn)}</small>
          {(order.items && order.items.length !== 0) &&
            order.items.map((item, index) => (
              <OrderListItemItem key={`order-list-item-${order.id}-item-${index}`} item={item} />
            ))
          }
        </td>
        <td>
          <span className={`badge badge-${
            order.status === 'processing'
            ? 'secondary'
            : order.status === 'completed'
            ? 'success'
            : order.status === 'pending'
            ? 'warning'
            : 'primary'
          }`}>{order.status}</span>
        </td>
        <td>{order.deliveryType}</td>
        <td>{order.deliveryLocation ? order.deliveryLocation : '-'}</td>
        <td>{`$${order.tipAmount ? order.tipAmount : 0}`}</td>
        <td>{`$${order.totalPrice}`}</td>
        <td>
          {(order.status === 'processing' && order.deliveryType === 'pickup') &&
            <button
              className="btn btn-primary btn-sm btn-block"
              type="button"
              onClick={() => setStatus(order.id, 'readyForPickup')}
            >Ready for pickup</button>
          }
          {(order.status === 'processing' && order.deliveryType === 'delivery') &&
            <button
              className="btn btn-success btn-sm btn-block"
              type="button"
              onClick={() => setStatus(order.id, 'completed')}
            >Complete</button>
          }
          {(order.status === 'readyForPickup' && order.deliveryType === 'pickup') &&
            <button
              className="btn btn-success btn-sm btn-block"
              type="button"
              onClick={() => setStatus(order.id, 'completed')}
            >Complete</button>
          }
          <button
            className="btn btn-outline-primary btn-sm btn-block"
            type="button"
            onClick={toggleOrderTransactionDialog}
          >Transactions</button>
          {order.status !== 'cancelled' &&
            <ButtonLoading
              classes="btn btn-outline-danger btn-sm btn-block"
              onClick={toggleOrderCancelDialog}
            >Cancel</ButtonLoading>
          }
          {order.orderActivityItems && order.orderActivityItems.length > 0 ? (
            <button
              className="btn btn-outline-secondary btn-sm btn-block"
              type="button"
              onClick={handleToggleOrderCheckInDialog}
            >{checkedIn ? 'Check-in' : 'Check-out'}</button>
          ) : null}
        </td>
      </tr>
      {showOrderCancelDialog ? (
        <OrderCancelDialog
          orderId={order.id}
          show={showOrderCancelDialog}
          onHide={toggleOrderCancelDialog}
        />
      ) : null}
      {showOrderTransactionDialog ? (
        <OrderTransactionsDialog
          orderId={order.id}
          email={order.userData && order.userData.email ? order.userData.email : null}
          show={showOrderTransactionDialog}
          onHide={toggleOrderTransactionDialog}
        />
      ) : null}
      {showOrderCheckInDialog ? (
        <OrderCheckInDialog
          order={order}
          show={showOrderCheckInDialog}
          onHide={handleToggleOrderCheckInDialog}
        />
      ) : null}
    </>
  )
}

export default connect(
  ({ OrderState }) => ({
    cancelIds: OrderState.cancelIds,
    stateIsLoading: OrderState.isLoading,
    stateStatus: OrderState.status
  }),
  dispatch => ({
    setStatus: (orderId, status) => dispatch(OrderActions.setStatus(orderId, status))
  })
)(OrderListItem);
