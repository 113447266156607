import {
  FETCH_LIST, FETCH_LIST_SUCCESS,
  CREATE, CREATE_SUCCESS,
  UPDATE, UPDATE_SUCCESS,
  DELETE, DELETE_SUCCESS,
  MOVE, MOVE_SUCCESS,
  SET_STATUS
} from './content-item.actions';

const initialState = {
  items: [],
  totalItems: 0,
  params: {
    contentCategoryId: null,
    offset: 0,
    limit: 10
  },
  isFetching: false,
  isLoading: false,
  status: 'Initial'
};

export default (state = initialState, { type, payload }) => {
  switch (type){
    case FETCH_LIST:
      let { params, isMoreLoad } = payload;
      return {
        ...state,
        items: isMoreLoad ? state.items : initialState.items,
        params,
        isFetching: !isMoreLoad,
        status: isMoreLoad ? 'FetchingMore' : state.status,
      };
    case FETCH_LIST_SUCCESS:
      const data = payload.data.data ? payload.data.data : [];
      return {
        ...state,
        items: [...state.items, ...data],
        totalItems: payload.data.total,
        isFetching: false,
        status: 'Initial'
      };

    case CREATE:
      return { ...state, isLoading: true };
    case CREATE_SUCCESS:
      return {
        ...state,
        items: [...state.items, payload.item],
        totalItems: state.totalItems + 1,
        isLoading: false
      };

    case UPDATE:
      return { ...state, isLoading: true };
    case UPDATE_SUCCESS:
      return {
        ...state,
        items: state.items.map(
          item => item.id === payload.item.id
            ? payload.item
            : item
        ),
        isLoading: false
      };

    case DELETE:
      return state;
    case DELETE_SUCCESS:
      return {
        ...state,
        items: state.items.filter(item => item.id !== payload.id),
        totalItems: state.totalItems - 1
      };

    case MOVE:
      return state;
    case MOVE_SUCCESS:
      let { id, moveId } = payload;
      let itemA = state.items.find(item => item.id === id);
      let indexA = state.items.indexOf(itemA);
      let itemB = state.items.find(item => item.id === moveId);
      let indexB = state.items.indexOf(itemB);
      return {
        ...state,
        items: [...state.items.swap(indexA, indexB)]
      };

    case SET_STATUS:
      return { ...state, status: payload.status };
    default:
      return state;
  }
}
