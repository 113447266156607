import React from 'react';
import { useForm } from 'react-hook-form';
// Redux
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ReservationActions } from 'app/store/reservation/reservation.actions';
import * as BookingSelectors from 'app/store/booking/booking.selectors';
// React bootstrap
import { Modal } from 'react-bootstrap';
// Components
import { ButtonLoading } from 'app/components/Buttons';
// Utilities
import { setEnd, parseDate, parseDateTime, checkIsSame, toCamperZone, checkIsBefore } from 'app/utilities/datetime.convert';
import { isRequired } from 'utilities/validation';
import { build24Hours } from 'utilities/other.utility';

const formatISO = 'YYYY-MM-DDTHH:mm:ss.sss[Z]';
const formatDate = 'YYYY-MM-DD';
const formatTime = 'HH:mm';

type Props = {
  // Props
  reservation: any;
  show: boolean;
  onHide: () => void;
  // State
  booking: any;
  reservationProlong: any;
  status: string;
  // Dispatch
  changeEndDate: (reservationId:number, data:any) => void;
  resetField: (field:string) => void;
}

const ReservationProlongDialog:React.FC<Props> = ({
  // Props
  reservation, show, onHide,
  // State
  booking, reservationProlong, status,
  // Dispatch
  changeEndDate, resetField
}) => {
  const [ lessEnd, setLessEnd ] = React.useState(false);

  React.useEffect(() => {
    return () => resetField('reservationProlong');
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if ( status === 'Success' ) onHide();
    // eslint-disable-next-line
  }, [status]);

  const { register, handleSubmit, errors, getValues } = useForm<any>({
    defaultValues: {
      end: parseDate(reservation.end, formatDate) || '',
      endTime: parseDateTime(reservation.end, formatTime) || '',
      totalPrice: reservation.totalPrice
    }
  });

  const onSubmit = handleSubmit((data:any) => {
    const newData:any = {}
    if ( booking.period !== 'hour' ){
      newData['end'] = setEnd(data.end, formatISO, true);
    } else {
      const date = parseDate(reservation.start, formatDate);
      const dateTime = `${date}T${data.endTime}`;
      newData['end'] = setEnd(dateTime, formatISO, true, true);
    }
    if ( lessEnd ) newData['totalPrice'] = data.totalPrice;
    changeEndDate(reservation.id, newData);
  });

  const handleProlongAnyway = () => {
    const values = getValues();
    const newData:any = { force: true }
    if ( booking.period !== 'hour' ){
      newData['end'] = setEnd(values.end, formatISO, true);
    } else {
      const date = parseDate(reservation.start, formatDate);
      const dateTime = `${date}T${values.endTime}`;
      newData['end'] = setEnd(dateTime, formatISO, true, true);
    }
    if ( lessEnd ) newData['totalPrice'] = values.totalPrice;
    changeEndDate(reservation.id, newData)
  }

  const handleChangeEndDate = (event:React.ChangeEvent<HTMLInputElement>) => {
    setLessEnd(checkIsBefore(event.target.value, toCamperZone(new Date(reservation.end)), 'day'));
  }

  const handleChangeEndTime = (event:React.ChangeEvent<HTMLSelectElement>) => {
    const date = parseDate(reservation.end, formatDate);
    const newDateTime = `${date}T${event.target.value}`;
    const oldTime = parseDateTime(reservation.end, formatTime);
    const oldDateTime = `${date}T${oldTime}`;
    setLessEnd(new Date(newDateTime) < new Date(oldDateTime));
  }

  return (
    <Modal show={show} onHide={onHide} backdropClassName="modal-backdrop-nested">
      <form onSubmit={onSubmit} noValidate>
        <Modal.Header>#{reservation.reservationNumber}</Modal.Header>
        <Modal.Body>
          {booking.period !== 'hour' ? (
            <div className="form-group">
              <label htmlFor="end">End&nbsp;*</label>
              <input
                ref={register({ required: isRequired, validate: {
                  beforeStart: v => validateBeforeStart(v, reservation.start, booking.period),
                  sameStart: v => validateSameStart(v, reservation.start, booking.period),
                  sameEnd: v => validateSameEnd(v, reservation.end, booking.period)
                } })}
                className={`form-control form-control-sm ${ errors.end && 'is-invalid' }`}
                id="end" name="end" type="date"
                onChange={handleChangeEndDate}
                required
              />
              {errors.end
                ? 
                  errors.end.type === 'beforeStart'
                  ? <div className="invalid-feedback">End date can`t be before start</div>
                  : errors.end.type === 'sameStart'
                  ? <div className="invalid-feedback">End date can't be same as start</div>
                  : errors.end.type === 'sameEnd'
                    ? <div className="invalid-feedback">End date can't be same as before</div>
                    : <div className="invalid-feedback">{errors.end.message}</div>
                : null
              }
            </div>
          ) : (
            <div className="form-group">
              <label htmlFor="endTime">End time</label>
              <select
                ref={register()}
                className="form-control form-control-sm"
                id="endTime" name="endTime"
                onChange={handleChangeEndTime}
              >
                {build24Hours().map((hour:{ value:string, label:string }, index:number) => (
                  <option key={`hour-item-${index}`} value={hour.value}>{hour.label}</option>
                ))}
              </select>
            </div>
          )}
          {lessEnd ? (
            <div className="form-group">
              <label htmlFor="totalPrice">Total price&nbsp;*</label>
              <div className="input-group input-group-sm">
                <div className="input-group-prepend">
                  <div className="input-group-text">$</div>
                </div>
                <input
                  ref={register({ required: isRequired })}
                  className={`form-control form-control-sm ${ errors.totalPrice && 'is-invalid' }`}
                  id="totalPrice" name="totalPrice" type="number"
                  required
                />
                {errors.totalPrice ? <div className="invalid-feedback">{errors.totalPrice.message}</div> : null }
              </div>
              <small className="form-text text-muted">Update reservation total for shorter period</small>
            </div>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          {(reservationProlong && reservationProlong.messages && reservationProlong.messages.length) ? (
            <>
              {reservationProlong.messages.map((message:string, index:number) => (
                <div key={`error-message-${index}`} className="alert alert-danger w-100 mb-2">{message}</div>
              ))}
            </>
          ) : null}
          <button
            className="btn btn-outline-secondary btn-sm"
            type="button"
            onClick={onHide}
          >Cancel</button>
          {(reservationProlong && !reservationProlong.datesChanged) ? (
            <ButtonLoading
            classes="btn btn-outline-danger btn-sm"
            loading={status === 'Loading'}
            onClick={handleProlongAnyway}
            >Change end date anyway</ButtonLoading>
          ) : (
            <ButtonLoading
              type="submit"
              loading={status === 'Loading'}
            >Change end date</ButtonLoading>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  )
}

const validateBeforeStart = (value:any, start:string, period:string) => {
  return !checkIsBefore(value, toCamperZone(new Date(start)), period !== 'hour' ? 'day' : 'hour');
}

const validateSameStart = (value:string, start:string, period:string) => {
  return !checkIsSame(value, toCamperZone(new Date(start)), period !== 'hour' ? 'day' : 'hour');
}

const validateSameEnd = (value:string, end:string, period:string) => {
  return !checkIsSame(value, toCamperZone(new Date(end)), period !== 'hour' ? 'day' : 'hour');
}

const mapStateToProps = (state:any) => ({
  booking: BookingSelectors.getBooking(state.BookingState),
  reservationProlong: state.ReservationState.reservationProlong,
  status: state.ReservationState.status
});

const mapDispatchToProps = (dispatch:Dispatch) => ({
  changeEndDate: (reservationId:number, data:any) => dispatch(ReservationActions.changeEndDate(reservationId, data)),
  resetField: (field:string) => dispatch(ReservationActions.resetField(field))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReservationProlongDialog);
