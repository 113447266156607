import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// Store
import { useSelector } from 'react-redux';
// Selectors
import { getVisits } from 'store/membership/membership.selectors';
// React bootstrap
import { IMembershipVisit } from 'models/Membership';
// Components
import { Message, Loader } from 'components/Utility';

dayjs.extend(utc);

type Props = {};

const TransactionsDialog:React.FC<Props> = () => {
  // State
  const visits:Array<IMembershipVisit> | null = useSelector((state:any) => getVisits(state));

  if ( !visits ) return <Loader />;
  if ( visits.length === 0 ) return <Message text="No visit(s)" />;
  return (
    <ul className="list-group">
      {visits.map((visit:IMembershipVisit) => (
        <li key={`visit-item-${visit.id}`} className="list-group-item">
          {dayjs.utc(visit.visitDate).format('MMMM DD YYYY')}
        </li>
      ))}
    </ul>
  )
}

export default TransactionsDialog;
