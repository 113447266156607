import React from 'react';
// Components
import { Loader } from 'components/Utility';
// Service
import { buildCalendar, Months } from 'store/season/season.service';

type Props = {
  items: any;
  fetching: boolean;
}

const SeasonsCalendar:React.FC<Props> = ({
  // Props
  items, fetching
}) => {
  const calendar = React.useMemo(() => {
    return buildCalendar();
    // eslint-disable-next-line
  }, []);

  if ( !items || fetching ) return <Loader />;
  return (
    <div className="d-flex">
      <div className="border-right border-left border-bottom bg-white">
        {Object.keys(calendar).map((month:any) => (
          <div
            className="px-3 py-2 border-top"
            key={`month-item-${month}`}
          >{Months[month]}</div>
        ))}
      </div>
      <div className="flex-fill overflow-auto border-bottom">
        {Object.keys(calendar).map((month:any) => (
          <div key={`month-days-item-${month}`} className="d-flex">
            {Object.keys(calendar[month]).map((day:any) => (
              <div
                key={`month-day-item-${day}`}
                className="position-relative p-2 text-center border-top border-right"
                style={{ minWidth: '48px', flex: '1 0' }}
              >
                {day}
                {items[month] && items[month][day] && items[month][day].id
                  ? <span
                      className="text-white p-2"
                      style={{ backgroundColor: items[month][day].color, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                    >{day}</span>
                  : null
                }
              </div>
            ))}
            {diffDays(Object.keys(calendar[month]).length) ? (
              diffDays(Object.keys(calendar[month]).length).map((day:any) => (
                <div
                  key={`month-day-muted-item-${day}`}
                  className="bg-secondary p-2 text-center border-right"
                  style={{ minWidth: '48px', flex: '1 0', opacity: 0.125 }}
                ></div>
              ))
            ) : null}
          </div>
        ))}
      </div>
    </div>
  )
}

const diffDays = (monthDays:number) => {
  const result:Array<number> = [];
  const diff:number = 31 - monthDays;
  if ( !diff ) return result;
  return Array.from({ length: diff }, (_, i) => i + 1);
}

export default SeasonsCalendar;
