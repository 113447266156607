import {
  IMailTemplate, IMailTemplateData,
  MailTemplateActionTypes,
  FETCH_LIST, FETCH_LIST_SUCCESS,
  FETCH_BY_ID, FETCH_BY_ID_SUCCESS,
  UPDATE, UPDATE_SUCCESS,
  SET_STATUS
} from './mail-template.types';
import Statuses from 'types/statuses';

export default class MailTemplateActions {
  // Fetch list
  public static fetchList = ():MailTemplateActionTypes => ({
    type: FETCH_LIST
  });
  public static fetchListSuccess = (items: Array<IMailTemplate>):MailTemplateActionTypes => ({
    type: FETCH_LIST_SUCCESS, payload: { items }
  });
  // Fetch by id
  public static fetchById = (id:number):MailTemplateActionTypes => ({
    type: FETCH_BY_ID, payload: { id }
  });
  public static fetchByIdSuccess = (mailTemplate:IMailTemplate):MailTemplateActionTypes => ({
    type: FETCH_BY_ID_SUCCESS, payload: { mailTemplate }
  });
  // Update
  public static update = (id:number, data:IMailTemplateData):MailTemplateActionTypes => ({
    type: UPDATE,
    payload: { id, data }
  });
  public static updateSuccess = (mailTemplate:IMailTemplate):MailTemplateActionTypes => ({
    type: UPDATE_SUCCESS, payload: { mailTemplate }
  });
  // Default
  // Set status
  public static setStatus = (status:Statuses):MailTemplateActionTypes => ({
    type: SET_STATUS, payload: { status }
  })
}
