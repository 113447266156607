import HttpUtility from 'utilities/http.utility';

export default class MembershipTypeAPI {
  private static _url:string = '/admin/membershipTypes';
  private static _http:HttpUtility = new HttpUtility();
  // Fetch membership types
  public static async fetchMembershipTypes():Promise<any> {
    return MembershipTypeAPI._http.get(MembershipTypeAPI._url);
  };
  // Fetch membership type
  public static async fetchMembershipType(membershipTypeId:number):Promise<any> {
    return MembershipTypeAPI._http.get(`${MembershipTypeAPI._url}/${membershipTypeId}`);
  };
  // Create membership type
  public static async createMembershipType(data:any):Promise<any> {
    return MembershipTypeAPI._http.post(MembershipTypeAPI._url, data);
  };
  // Update membership type
  public static async updateMembershipType(membershipTypeId:number, data:any):Promise<any> {
    return MembershipTypeAPI._http.put(`${MembershipTypeAPI._url}/${membershipTypeId}`, data);
  };
  // Delete membership type
  public static async deleteMembershipType(membershipTypeId:number):Promise<any> {
    return MembershipTypeAPI._http.delete(`${MembershipTypeAPI._url}/${membershipTypeId}`);
  };
  // Enable membership type
  public static async enableMembershipType(membershipTypeId:number):Promise<any> {
    return MembershipTypeAPI._http.post(`${MembershipTypeAPI._url}/${membershipTypeId}/actions/enable`);
  };
  // Disable membership type
  public static async disableMembershipType(membershipTypeId:number):Promise<any> {
    return MembershipTypeAPI._http.post(`${MembershipTypeAPI._url}/${membershipTypeId}/actions/disable`);
  };
  // Reset default waiver
  public static async resetDefaultWaiver(membershipTypeId:number):Promise<any> {
    return MembershipTypeAPI._http.post(`${MembershipTypeAPI._url}/${membershipTypeId}/actions/resetDefaultWaiver`);
  };
  // Set default waiver
  public static async setDefaultWaiver(membershipTypeId:number):Promise<any> {
    return MembershipTypeAPI._http.post(`${MembershipTypeAPI._url}/actions/setDefaultWaiver/${membershipTypeId}`);
  };
}