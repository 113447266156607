import HttpUtility from 'utilities/http.utility';

export default class WaiverAPI {
  private static _url:string = '/admin/waivers';
  private static _http:HttpUtility = new HttpUtility();

  // Fetch waivers
  public static async fetchWaivers(params:any):Promise<any> {
    const searchParams = new URLSearchParams();
    Object.keys(params).forEach((key:string) => {
      if ( params[key] ) searchParams.append(key, params[key]);
    });
    return WaiverAPI._http.get(`${WaiverAPI._url}?${searchParams}`);
  }
  // Fetch by id
  public static async fetchWaiver(waiverId:number):Promise<any> {
    return WaiverAPI._http.get(`${WaiverAPI._url}/${waiverId}`);
  }
  // Create
  public static async createWaiver(data:any):Promise<any> {
    return WaiverAPI._http.post(WaiverAPI._url, data);
  }
  // Update
  public static async updateWaiver(waiverId:number, data:any):Promise<any> {
    return WaiverAPI._http.put(`${WaiverAPI._url}/${waiverId}`, data);
  }
  // Delete
  public static async deleteWaiver(waiverId:number):Promise<any> {
    return WaiverAPI._http.delete(`${WaiverAPI._url}/${waiverId}`);
  }
}