import React from 'react';
// Models
import { IMembership } from 'models/Membership';
// Store
import { useDispatch, useSelector } from 'react-redux';
// Actions
import MembershipActions from 'store/membership/membership.actions';
// Selectors
import { getMemberships } from 'store/membership/membership.selectors';
// Components
import { Loader, Message } from 'components/Utility';
// 
import MembershipPassesListItem from './MembershipPassesListItem';

type Props = {};

const MembershipPassesList:React.FC<Props> = () => {
  // Dispatch
  const dispatch = useDispatch();
  // State
  const memberships:Array<IMembership> | null = useSelector((state:any) => getMemberships(state));

  React.useEffect(() => {
    dispatch(MembershipActions.fetchMemberships());
    return () => {
      dispatch(MembershipActions.setInitialField('memberships'));
    }
    // eslint-disable-next-line
  }, []);

  if ( !memberships ) return <Loader />;
  if ( memberships.length === 0 ) return <Message text="No membership passe(s)" />;
  return (
    <React.Fragment>
      {memberships.map((membership:IMembership) => (
        <MembershipPassesListItem
          key={`membership-item-${membership.id}`}
          membership={membership}
        />
      ))}
    </React.Fragment>
  )
}

export default MembershipPassesList;
