import { Action } from 'redux';
import Statuses from 'types/statuses';

export interface IAppState {
  requestedUrl: string | null;
  status: Statuses;
}

// Messages
export enum AppMessages {}

// Types
export enum AppTypes {
  SetInited = '@@app/Set inited',
  SetRequestedUrl = '@@app/Set requested url'
}

// Actions
interface SetInited extends Action<typeof AppTypes.SetInited> {}
interface SetRequestedUrl extends Action<typeof AppTypes.SetRequestedUrl> {
  payload: { requestedUrl:string; }
}

// ActionTypes
export type AppActionTypes =
  SetInited | SetRequestedUrl
;