import React, { useEffect } from 'react';
// Redux
import { connect } from 'react-redux';
import { BookingActions } from '../../../store/booking/booking.actions';
import * as BookingSelectors from '../../../store/booking/booking.selectors';
// Components
import { ContentLoading } from '../../../components/Loadings';
// 
import BookingsFilter from './BookingsFilter';
import BookingsCreate from './BookingsCreate';
import BookingsListItem from './BookingsListItem';

const Bookings = ({
  // Props
  // State
  bookings, status,
  // Dispatch
  fetchBookings, clearFilter
}) => {
  useEffect(() => {
    fetchBookings();
    return () => clearFilter();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="area">
      <div className="container-fluid">
        <div className="area-head">
          <h1 className="area-title">Booking Properties</h1>
        </div>
        <div className="area-container">
          <BookingsFilter />
          <BookingsCreate />
          <ContentLoading data={bookings} status={status}>
            <div className="row row-8">
              {bookings.map((booking, index) => (
                <BookingsListItem
                  key={index}
                  booking={booking}
                />
              ))}
            </div>
          </ContentLoading>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  bookings: BookingSelectors.getBookings(state.BookingState),
  status: BookingSelectors.getStatus(state.BookingState)
});

const mapDispatchToProps = dispatch => ({
  fetchBookings: () => dispatch(BookingActions.fetchBookings()),
  clearFilter: () => dispatch(BookingActions.clearFilter())
})

export default connect(mapStateToProps, mapDispatchToProps)(Bookings);
