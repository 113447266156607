import config from '../config';
import StorageService from 'services/storage.service';
import RequestMethods from 'types/request-methods';

interface HttpResponse<T> extends Response {
  parseBody?: T
}

export default class HttpUtility {
  public async get<T>(
    path:string,
    args:RequestInit = { method: RequestMethods.Get }
  ):Promise<HttpResponse<T>> {
    return await this._http<T>(path, args);
  }

  public async post<T>(
    path:string,
    body?:any,
    args:RequestInit = {
      method: RequestMethods.Post,
      body: body instanceof FormData ? body : JSON.stringify(body)
    }
  ):Promise<HttpResponse<T>> {
    if ( !(body instanceof FormData) ){
      args['headers'] = { 'Content-Type': 'application/json' };
    }
    return await this._http<T>(path, args);
  }

  public async put<T>(
    path:string,
    body:any,
    args:RequestInit = {
      method: RequestMethods.Put,
      body: body instanceof FormData ? body : JSON.stringify(body)
    }
  ):Promise<HttpResponse<T>> {
    if ( !(body instanceof FormData) ){
      args['headers'] = { 'Content-Type': 'application/json' };
    }
    return await this._http<T>(path, args);
  }

  public async patch<T>(
    path:string,
    body:any,
    args:RequestInit = { method: RequestMethods.Patch, body: JSON.stringify(body) }
  ):Promise<HttpResponse<T>> {
    args['headers'] = { 'Content-Type': 'application/json' };
    return await this._http<T>(path, args);
  }

  public async delete<T>(
    path:string,
    args:RequestInit = { method: RequestMethods.Delete }
  ):Promise<HttpResponse<T>> {
    return await this._http<T>(path, args);
  }

  private async _http<T>(path:string, args:RequestInit = {}):Promise<HttpResponse<T>> {
    args.headers = {
      ...args.headers,
      Authorization: `${StorageService.getTokenType()} ${StorageService.getToken()}`
    };
    const request:RequestInfo = new Request(`${config.apiUrl}/api${path}`, args);
    const response:HttpResponse<T> = await fetch(request);
    try {
      response.parseBody = await response.json();
    } catch(error){}
    if ( !response.ok ) throw response.parseBody;
    return response;
  }
}

