import { ISeason, ISlotData } from './season.types';

export const validateSlots = (seasons:Array<ISeason>, seasonsSlots:any, slots:any):any => {
  const slotsCalendar = buildCalendarSlots(seasonsSlots);
  const intercectedSeasonsIds:Array<number> = [];
  const seasonsToUpdate:Array<any> = [];

  let hasIntercectedSlots:boolean = false;

  for ( let slot of slots ){
    const calendarSlot = buildSlot(slot.start, slot.end, { id: -1 });
    for ( let key of Object.keys(calendarSlot) ){
      for ( let day of Object.keys(calendarSlot[key]) ){
        if ( slotsCalendar[key][day] ){
          if ( slotsCalendar[key][day] === -1 ){
            hasIntercectedSlots = true;
            break;
          } else {
            const seasonId:number = slotsCalendar[key][day];
            if ( !intercectedSeasonsIds.includes(seasonId) ) intercectedSeasonsIds.push(seasonId);
            slotsCalendar[key][day] = -1;
          }
        } else {
          slotsCalendar[key][day] = -1;
        }
      }
      if ( hasIntercectedSlots ) break;
    }
    if ( hasIntercectedSlots ) break;
  }
  if ( !hasIntercectedSlots ){
    if ( intercectedSeasonsIds.length > 0 ){
      for ( let currentSeasonId of intercectedSeasonsIds ){
        const slots:Array<ISlotData> = [];
        let currentSlot:any = null;
        for ( let month of Object.keys(slotsCalendar) ){
          for ( let day of Object.keys(slotsCalendar[month]) ){
            const seasonId = slotsCalendar[month][day];
            if ( currentSeasonId === seasonId ){
              if ( !currentSlot ){
                currentSlot = { startMonth: month, startDay: day };
              }
            } else {
              if ( currentSlot ){
                currentSlot['endMonth'] = month;
                currentSlot['endDay'] = day;
                slots.push(currentSlot);
                currentSlot = null;
              }
            }
          }
        }
        const currentSeason = seasons.find((season:ISeason) => season.id === currentSeasonId);
        if ( currentSeason ){
          currentSeason.slots = slots.map((slot:ISlotData) => {
            return {
              start: `${convertToBENumber(slot.startMonth)}-${convertToBENumber(slot.startDay)}`,
              end: `${convertToBENumber(slot.endMonth)}-${convertToBENumber(slot.endDay)}`,
            }
          });
          seasonsToUpdate.push(currentSeason);
        }
      }
    }
  }
  return { hasIntercectedSlots, seasonsToUpdate };
}

export const buildCalendar = ():any => {
  const result:any = {};
  for ( let key of Object.keys(Months) ){
    const days = daysInMonth(key);
    result[key] = daysArray(days).reduce((acc:any, cur:number) => {
      acc[cur] = {};
      return acc;
    }, {})
  }
  return result;
};

export const buildCalendarSlots = (slots:any = {}):any => {
  const calendar = buildCalendar();
  if ( Object.keys(slots).length > 0 ){
    for ( let key of Object.keys(slots) ){
      for ( let day of Object.keys(slots[key]) ){
        calendar[key][day] = slots[key][day].id
      }
    }
  }
  return calendar;
}

export const buildSlot = (slotStart:string, slotEnd:string, data:any = {}):any => {
  const result:any = {};

  const start = slotStart.split('-').map(Number);
  const end = slotEnd.split('-').map(Number);

  const startMonth = start[0];
  const startDay = start[1];
  const endMonth = end[1] === 1 ? end[0] - 1 : end[0];
  // Added +1 day, to include last day of prev month
  const endDay = end[1] === 1 ? (daysInMonth(end[0] - 1) + 1) : end[1];

  if ( startMonth > endMonth ){
    for ( let i = startMonth; i <= 12; i++ ){
      const days = daysInMonth(i);
      result[i] = buildSlotDays(days, data, i === startMonth, i === endMonth, startDay, endDay);
    }
    for ( let i = 1; i <= endMonth; i++ ){
      const days = daysInMonth(i);
      result[i] = buildSlotDays(days, data, i === startMonth, i === endMonth, startDay, endDay);
    }
  } else {
    for ( let i = startMonth; i <= endMonth; i++ ){
      const days = daysInMonth(i);
      result[i] = buildSlotDays(days, data, i === startMonth, i === endMonth, startDay, endDay);
    }
  }

  return result;
}

export const buildSlotDays = (days:number, data:any = {}, isStartMonth:boolean, isEndMonth:boolean, startDay:number, endDay:number):any => {
  return daysArray(days).reduce((acc:any, cur:number) => {
    if ( isStartMonth && isEndMonth ){
      if ( cur >= startDay && cur < endDay ) acc[cur] = data;
    } else {
      if ( isStartMonth ){
        if ( cur >= startDay ) acc[cur] = data;
      } else if ( isEndMonth ){
        if ( cur < endDay ) acc[cur] = data;
      } else {
        acc[cur] = data;
      }
    }
    return acc;
  }, {});
}

export const stringToColor = (str:string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xFF;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
}

export const daysInMonth = (month:number | string, year:number = 2008) => {
  return new Date(year, typeof month === 'string' ? Number(month) : month, 0).getDate();
};

export const daysArray = (days:number):Array<number> => {
  return Array.from({ length: days }, (_, i:number) => i + 1);
};

export const convertToBENumber = (value:number | string | undefined) => {
  if ( !value ) return '';
  const valueToNumber:number = typeof value === 'string' ? Number(value) : value;
  return ('0' + valueToNumber).slice(-2);
};

export const Months:any = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December'
};
