import { store } from 'index';
import * as LayoutActions from 'app/actions/layout.actions';
import * as AuthActions from 'app/actions/auth.actions';
// Config
import config from '../../config';
// Service
import * as StorageService from '../services/storage.service';

const updateOptions = options => {
  const update = { ...options };
  if ( StorageService.getToken() && !StorageService.checkIsExpires() ) {
    update.headers = {
      ...update.headers,
      Authorization: `${StorageService.getTokenType()} ${StorageService.getToken()}`,
    };
  }
  return update;
}

export default (url, options, cb, message = '') => {
  return fetch(`${config.apiUrl}/api${url}`, updateOptions(options))
    .then(response => {
      if ( message ) store.dispatch(LayoutActions.addNotification({
        type: 'success',
        message
      }));

      if ( response.ok ){
        if ( cb ) return cb(response);
        return response.json();
      } else {
        if ( response.status === 401 ) {
          store.dispatch(AuthActions.logout());
          throw new Error('Not authorized');
        }
        return response.json().then(error => {
          throw error;
        });
      }
    })
}
