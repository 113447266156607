import React from 'react';
// Redux
import { connect } from 'react-redux';
import OrderActions from 'app/actions/order.actions';
// Bootstrap
import Modal from 'react-bootstrap/Modal';
// Utilities
import { toNormalCase } from 'utilities/wordbreak.utility';

const OrderCheckInDialog = ({
  // Props
  order, show, onHide,
  // State
  // Dispatch
  checkInActivity
}) => {
  const { id, orderActivityItems } = order;

  const handleClick = (activityId, confirmationCode, status) => checkInActivity(id, activityId, confirmationCode, status);

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Order activity items</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {orderActivityItems && orderActivityItems.length > 0 ? (
          <table className="table table-sm">
            <thead className="thead-dark">
              <tr>
                <th>Product name</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {orderActivityItems.map(item => (
                <tr key={`order-activity-item-${item.id}`}>
                  <td className="align-middle"><h6 className="mb-0">{item.productName}</h6></td>
                  <td className="align-middle"><span className="badge bg-info | text-capitalize">{toNormalCase(item.status)}</span></td>
                  <td className="text-right align-middle">
                    {item.status === 'checkedIn' ? (
                      <button
                        className="btn btn-outline-danger btn-sm"
                        type="button"
                        onClick={() => handleClick(item.id, item.confirmationCode, 'issued')}
                      >Cancel check-in</button>
                    ) : (
                      <button
                        className="btn btn-primary btn-sm"
                        type="button"
                        onClick={() => handleClick(item.id, item.confirmationCode, 'checkedIn')}
                      >Check-in</button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
      </Modal.Body>
    </Modal>
  )
}

OrderCheckInDialog.defaultProps = {
  order: null,
  show: false,
  onHide: () => null
}

const mapDispatchToProps = dispatch => ({
  checkInActivity: (id, activityId, confirmationCode, status) => dispatch(OrderActions.checkIn(id, activityId, confirmationCode, status))
});

export default connect(null, mapDispatchToProps)(OrderCheckInDialog);
