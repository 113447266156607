import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// Models
import { IRemotelockDeviceAudit } from 'models/RemotelockDevice';
// Store
import { useDispatch, useSelector } from 'react-redux';
// Actions
import RemotelockDeviceActions from 'store/remotelock-device/remotelock-device.actions';
// Selectors
import { getAudits, getAuditParams, getAuditTotal } from 'store/remotelock-device/remotelock-device.selectors';
// Bootstrap
import Modal from 'react-bootstrap/Modal';
// Components
import { Loader, Message } from 'components/Utility';
import { Input } from 'components/Controls';

dayjs.extend(utc);

type Props = {
  deviceId: number;
  show: boolean;
  onHide: () => void;
}

const RemotelockDeviceAuditsDialog:React.FC<Props> = ({
  // Props
  deviceId, show, onHide
}) => {
  // Dispatch
  const dispatch = useDispatch();
  // State
  const audits:Array<IRemotelockDeviceAudit> | null = useSelector((state:any) => getAudits(state));
  const params:any = useSelector((state:any) => getAuditParams(state));
  const total:number = useSelector((state:any) => getAuditTotal(state));

  const totalPages:number = Math.ceil(total/params.limit);

  const [ currentPage, setCurrentPage ] = React.useState<number>(1);
  const [ date, setDate ] = React.useState<any>({
    start: params.start,
    end: params.end
  });

  React.useEffect(() => {
    dispatch(RemotelockDeviceActions.fetchRemotelockDeviceAudits(deviceId, params));
    return () => {
      dispatch(RemotelockDeviceActions.setInitialField('auditParams'));
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setDate((prevState:any) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleFilter = () => {
    dispatch(RemotelockDeviceActions.fetchRemotelockDeviceAudits(deviceId, {
      ...params,
      offset: 0,
      start: date.start,
      end: date.end
    }));
  }

  const handleReset = () => {
    setDate({ start: '', end: '' });
    dispatch(RemotelockDeviceActions.fetchRemotelockDeviceAudits(deviceId, {
      ...params,
      offset: 0,
      start: '',
      end: ''
    }));
  }

  const handleNextPage = (nextPage:number) => {
    setCurrentPage(nextPage);
    dispatch(RemotelockDeviceActions.fetchRemotelockDeviceAudits(deviceId, {
      ...params,
      offset: (nextPage - 1) * params.limit
    }));
  };

  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Audit log</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {audits ? (
          <React.Fragment>
            <div className="row row-8 mb-2 align-items-end">
              <div className="col-4">
                <Input
                  controlRef={null}
                  label="Start" name="start" id="start" defaultValue={date.start} type="date"
                  onChange={handleChange}
                />
              </div>
              <div className="col-4">
                <Input
                  controlRef={null}
                  label="End" name="end" id="end" defaultValue={date.end} type="date"
                  onChange={handleChange}
                />
              </div>
              <div className="col-2">
                <div className="form-group">
                  <button
                    className="btn btn-primary btn-sm btn-block"
                    type="button"
                    disabled={!Boolean(date.start && date.end)}
                    onClick={handleFilter}
                  >Filter</button>
                </div>
              </div>
              <div className="col-2">
                <div className="form-group">
                  <button
                    className="btn btn-secondary btn-sm btn-block"
                    type="button"
                    disabled={!Boolean(date.start && date.end)}
                    onClick={handleReset}
                  >Reset</button>
                </div>
              </div>
            </div>
            {audits.length > 0 ? (
              <React.Fragment>
                <ul className="list-group">
                  {audits.map((audit:IRemotelockDeviceAudit, index:number) => (
                    <li key={`audit-item-${index}`} className="list-group-item">
                      <div className="d-flex w-100 justify-content-between">
                        <h5 className="mb-1">{audit.userData.firstName} {audit.userData.lastName}</h5>
                        <small>{audit.action}</small>
                      </div>
                      {/* ToDO: DateTime */}
                      {/* parse UTC date 'YYYY-MM-DDTHH:mm:ssZ' */}
                      <small>{dayjs.utc(audit.date).format('MMMM DD YYYY, hh:mm a')}</small>
                    </li>
                  ))}
                </ul>
                {total > params.limit ? (
                  <nav>
                    <ul className="pagination pagination-sm justify-content-center mt-2">
                      {Array.from({ length: totalPages }, (_, i) => i + 1).map((page:number) => (
                        <li
                          key={`pagination-item-${page}`}
                          className={`page-item ${ page === currentPage ? 'active' : '' }`}
                          onClick={() => handleNextPage(page)}
                        ><a className="page-link">{page}</a></li>
                      ))}
                    </ul>
                  </nav>
                ) : null}
              </React.Fragment>
            ) : <Message text="No audit log(s)" />}
          </React.Fragment>
        ) : <Loader />}
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-secondary btn-sm"
          type="button"
          onClick={onHide}
        >Close</button>
      </Modal.Footer>
    </Modal>
  )
}

export default RemotelockDeviceAuditsDialog;
