import React, { useState, useEffect } from 'react';
// Redux
import { connect } from 'react-redux';
import AssetActions from '../../../actions/asset.actions';
// Hooks
import useStateCallback from '../../../hooks/useStateCallback';

const BookingFormImages = props => {
  const {
    // Props
    value, onChange,
    // State
    asset, isLoading, isSuccess,
    // Dispatch
    createAsset, clearAsset
  } = props;

  const imageCallback = state => {
    onChange({
      target: {
        name: 'images',
        value: state
      }
    });
  }

  const [ mainImage, setMainImage ] = useState(value && value.length !== 0 ? value[0].href : '');
  const [ images, setImages ] = useStateCallback(value || [], imageCallback);
  const [ selectedImage, setSelectedImage ] = useState('');

  useEffect(() => {
    if ( isSuccess && asset ){
      setImages(prevState => [...prevState, asset])
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  useEffect(() => {
    return () => clearAsset();
    // eslint-disable-next-line
  }, []);

  const handleChange = event => {
    createAsset(event.target.files[0], 'booking-property/images');
    setSelectedImage(event.target.files[0]);
  }

  const handleRemove = index => setImages(prevState => prevState.filter((_, i) => i !== index));

  console.log(selectedImage);

  return (
    <>
      {/* <div className="form-group">
        <input
          type="file" onChange={handleChange}
          disabled={isLoading}
        />
      </div> */}

      <div className="custom-file mb-1">
        <input type="file" className="custom-file-input" id="imageUpload" onChange={handleChange} disabled={isLoading} />
        <label className="custom-file-label" htmlFor="imageUpload">
          {selectedImage ? selectedImage.name : 'Choose file...'}
        </label>
      </div>

      {images.length !== 0 &&
        <div className="image-grid">
          {mainImage
            ? <div className="image-grid-main"><img src={mainImage} alt="" /></div>
            : <div className="image-grid-nomain"><i className="fa fa-image" aria-hidden="true"></i></div>
          }
          {images.map((image, index) => (
            <div key={index} className={`image-grid-item ${image.href === mainImage ? '_active' : ''}`}>
              <img
                src={image.href}
                alt=""
                onClick={() => setMainImage(image.href)}
              />
              <button
                type="button"
                onClick={() => handleRemove(index)}
              ><i className="fa fa-trash" aria-hidden="true"></i></button>
            </div>
          ))}
          {isLoading &&
            <div className="image-grid-loading">
              <div className="wrap">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          }
        </div>
      }
    </>
  )
}

const mapStateToProps = state => ({
  asset: state.AssetState.asset,
  isLoading: state.AssetState.isLoading,
  isSuccess: state.AssetState.isSuccess
});

const mapDispatchToProps = dispatch => ({
  createAsset: (file, folder) => dispatch(AssetActions.createAsset(file, folder)),
  clearAsset: () => dispatch(AssetActions.setInitialState())
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingFormImages);
