import React from 'react';
// Types
import Statuses from 'types/statuses';
// Models
import { IRemotelockDevice } from 'models/RemotelockDevice';
// Store
import { useDispatch, useSelector } from 'react-redux';
// Actions
import { BookingItemActions } from 'app/store/booking-item/booking-item.actions';
// Selectors
import { getRemotelockDevices } from 'store/remotelock-device/remotelock-device.selectors';
import { getIsLoading, getStatus } from 'app/store/booking-item/booking-item.selectors';
// Bootstrap
import Modal from 'react-bootstrap/Modal';
// Components
import { ButtonLoading } from 'app/components/Buttons';
import { Loader, Message } from 'components/Utility';

type Props = {
  bookingItemId: number;
  bookingRemotelockDevices: Array<IRemotelockDevice> | null;
  show: boolean;
  onHide: () => void;
}

const RemotelockDevicesDialog:React.FC<Props> = ({
  // Props
  bookingItemId, bookingRemotelockDevices, show, onHide
}) => {
  // Dispatch
  const dispatch = useDispatch();
  // State
  const remotelockDevices:Array<IRemotelockDevice> | null = useSelector((state:any) => getRemotelockDevices(state));
  const isLoading:boolean = useSelector((state:any) => getIsLoading(state.BookingItemState));
  const status:Statuses = useSelector((state:any) => getStatus(state.BookingItemState));

  const [ remotelockDevicesIds, setRemotelockDevicesIds ] = React.useState<Array<number>>(
    bookingRemotelockDevices ? bookingRemotelockDevices.map((device:IRemotelockDevice) => device.id) : []
  );

  React.useEffect(() => {
    if ( status === Statuses.Success ) onHide();
    // eslint-disable-next-line
  }, [status]);

  const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const deviceId = Number(value);
    if ( remotelockDevicesIds.includes(deviceId) ){
      setRemotelockDevicesIds((prevState:Array<number>) => prevState.filter((id:number) => id !== deviceId));
    } else {
      setRemotelockDevicesIds((prevState:Array<number>) => [...prevState, deviceId]);
    }
  }

  const handleSave = () => {
    dispatch(BookingItemActions.assignRemotelockDevices(bookingItemId, remotelockDevicesIds));
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Remotelock Devices</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {remotelockDevices ? (
          remotelockDevices.length > 0 ? (
            <div className="list-group">
              {remotelockDevices.map((device:IRemotelockDevice) => (
                <div className="list-group-item p-0" key={`remotelock-device-${device.id}`}>
                  <label className="form-check p-3 mb-0">
                    <input
                      id={`device-${device.id}`}
                      type="checkbox"
                      value={device.id}
                      checked={remotelockDevicesIds.includes(device.id)}
                      onChange={handleChange}
                      style={{ top: '1px', marginRight: '1rem' }}
                    />
                    {device.name}
                  </label>
                </div>
              ))}
            </div>
          ) : <Message text="No locked device(s)" />
        ) : <Loader />}
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-secondary btn-sm"
          type="button"
          onClick={onHide}
        >Cancel</button>
        <ButtonLoading
          loading={isLoading}
          type="button"
          onClick={handleSave}
        >Save</ButtonLoading>
      </Modal.Footer>
    </Modal>
  )
}

export default RemotelockDevicesDialog;
