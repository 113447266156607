import create from './create-type';

const _type = 'user';

const _actions = [
  'FETCH_ME',
  'UPDATE_ME',
  'FETCH_ALL_CAMPERS',
  'FETCH_ALL_STAFF',
  'FETCH_BY_ID',
  'CREATE',
  'UPDATE',
  'UPDATE_CAMPER',
  // 'SEARCH_CAMPER',
  'SEARCH_USERS',
  'DELETE',
  'DISABLE',
  'ENABLE',

  'CREATE_CAMPER'
];

const Types = {};

for ( let i in _actions ){
  Types[_actions[i]] = create(_type, [_actions[i]]);
  Types[`${_actions[i]}_SUCCESS`] = create(_type, `${[_actions[i]]}_SUCCESS`);
  Types[`${_actions[i]}_FAILURE`] = create(_type, `${[_actions[i]]}_FAILURE`);
}

Types['RESET_PARAMS'] = create(_type, 'RESET_PARAMS');

Types['RESET_FORM'] = create(_type, 'RESET_FORM');
Types['CHECK_USER'] = create(_type, 'CHECK_USER');
Types['UNCHECK_USER'] = create(_type, 'UNCHECK_USER');

Types['CHECK_ALL'] = create(_type, 'CHECK_ALL');
Types['UNCHECK_ALL'] = create(_type, 'UNCHECK_ALL');

Types['SET_IS_FETCHING'] = create(_type, 'SET_IS_FETCHING');
Types['SET_IS_FETCHED'] = create(_type, 'SET_IS_FETCHED');
Types['SET_INITIAL_STATE'] = create(_type, 'SET_INITIAL_STATE');

export default Types;
