import { BookingItemActionTypes } from './booking-item.types';

const initialState = {
  bookingItems: [],
  filter: {
    bookingId: null,
    search: ''
  },
  isLoading: false,
  status: 'Initial'
}

export default (state = initialState, action) => {
  switch (action.type) {
    case BookingItemActionTypes.FetchBookingItems:
      return { ...state, bookingItems: initialState.bookingItems, status: 'Fetching' }
    case BookingItemActionTypes.FetchBookingItemsSuccess:
      return { ...state, bookingItems: action.bookingItems, status: 'Fetched' };
    // Create
    case BookingItemActionTypes.Create:
      return { ...state, status: 'Loading' };
    case BookingItemActionTypes.CreateSuccess:
      return { ...state, bookingItems: [...state.bookingItems, action.bookingItem], status: 'Loaded' };
    // Update
    case BookingItemActionTypes.Update:
      return { ...state, status: 'Loading' };
    case BookingItemActionTypes.UpdateSuccess:
      return {
        ...state,
        bookingItems: state.bookingItems.map(b =>
          b.id === action.bookingItem.id
          ? { ...b, ...action.bookingItem }
          : b
        ),
        status: 'Loaded'
      };
    // Delete
    case BookingItemActionTypes.Delete:
      return state;
    case BookingItemActionTypes.DeleteSuccess:
      return {
        ...state,
        bookingItems: state.bookingItems.filter(b => b.id !== action.bookingItemId)
      };
    // Actions
    case BookingItemActionTypes.MarkAsFree:
    case BookingItemActionTypes.MarkAsOutOfService:
      return state;
    case BookingItemActionTypes.MarkAsFreeSuccess:
    case BookingItemActionTypes.MarkAsOutOfServiceSuccess:
      return {
        ...state,
        bookingItems: state.bookingItems.map(b =>
          b.id === action.bookingItem.id
          ? { ...b, ...action.bookingItem }
          : b
        )
      }
    // Filter
    case BookingItemActionTypes.Filter:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.filterType]: action.filterValue
        }
      };
    case BookingItemActionTypes.ClearFilter:
      return { ...state, filter: initialState.filter };
    // Set status
    case BookingItemActionTypes.ResetField:
      return { ...state, [action.field]: initialState[action.field] };
    case BookingItemActionTypes.StatusSuccess:
      return { ...state, status: 'Success' };
    case BookingItemActionTypes.StatusError:
      return { ...state, status: 'Error' };
    case BookingItemActionTypes.StatusInitial:
      return { ...state, status: 'Initial' };

    case BookingItemActionTypes.AssignRemotelockDevices:
      return { ...state, isLoading: true };
    case BookingItemActionTypes.AssignRemotelockDevicesSuccess:
      return {
        ...state,
        bookingItems: state.bookingItems.map(b => {
          if ( b.id === action.payload.bookingItem.id ) return action.payload.bookingItem;
          return b;
        }),
        isLoading: false
      };
    
    default:
      return state;
  }
}