import React, { useEffect } from 'react';
// Redux
import { useDispatch } from 'react-redux';
import UserActions from 'app/actions/user.actions';
//
import CampersSendMessage from './CampersSendMessage';
import CampersFilter from './CampersFilter';
import CamperList from './CamperList';

const Campers = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(UserActions.uncheckAll());
      dispatch(UserActions.resetParams());
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="area area-templates">
      <div className="container-fluid">
        <div className="area-container">
          <CampersSendMessage />
          <CampersFilter />
          <hr />
          <CamperList />
        </div>
      </div>
    </div>
  )
}

export default Campers;
