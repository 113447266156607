import { Reducer } from 'redux';
import {
  IMailTemplateState,
  MailTemplateActionTypes,
  FETCH_LIST, FETCH_LIST_SUCCESS,
  FETCH_BY_ID, FETCH_BY_ID_SUCCESS,
  UPDATE, UPDATE_SUCCESS,
  SET_STATUS
} from './mail-template.types';
import Statuses from 'types/statuses';

export default class MailTemplateReducer {
  private static readonly _initialState:IMailTemplateState = {
    templates: {
      items: null,
      isFetching: false,
      isLoading: false,
    },
    template: {
      item: null,
      isFetching: false,
      isLoading: false,
    },
    status: Statuses.Initial
  };

  public static reducer:Reducer<IMailTemplateState, MailTemplateActionTypes> = (
    state = MailTemplateReducer._initialState,
    action
  ) => {
    switch (action.type){
      // Fetch list
      case FETCH_LIST:
        return { ...state, templates: {
          ...state.templates,
          isFetching: true
        }};
      case FETCH_LIST_SUCCESS:
        return { ...state, templates: {
          ...state.templates,
          items: action.payload.items,
          isFetching: false
        }};
      // Create
      case FETCH_BY_ID:
        return { ...state, template: {
          ...state.template,
          isFetching: true
        }};
      case FETCH_BY_ID_SUCCESS:
        return { ...state, template: {
          ...state.template,
          item: action.payload.mailTemplate,
          isFetching: false
        }};
      // Update
      case UPDATE:
        return { ...state, template: {
          ...state.template,
          isLoading: true
        }};
      case UPDATE_SUCCESS:
        return { ...state, template: {
          ...state.template,
          isLoading: false
        }};
      // Default
      case SET_STATUS:
        const hasError:boolean = action.payload.status === Statuses.Error;
        return {
          ...state,
          templates: {
            ...state.templates,
            isLoading: hasError ? false : state.templates.isLoading,
            isFetching: hasError ? false : state.templates.isFetching,
          },
          template: {
            ...state.template,
            isLoading: hasError ? false : state.template.isLoading,
            isFetching: hasError ? false : state.template.isFetching,
          },
          status: action.payload.status,
        };
      default:
        return state;
    }
  }
}
