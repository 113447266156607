import React, { useState } from 'react';
// Redux
import { connect } from 'react-redux';
import { BookingItemActions } from '../../../store/booking-item/booking-item.actions';
// Bootstrap
import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
// Modal
import BookingItemFormModal from '../../../layouts/Modals/BookingItemFormModal';
import RemotelockDevicesDialog from 'dialogs/RemotelockDevices.dialog';

const BookingItemsListItem = props => {
  const {
    // Props
    bookingItem, remotelockIntegrationLocked,
    // Dispatch
    deleteBookingItem, markAsFreeBookingItem, markAsOutOfServiceBookingItem
  } = props;

  const [ dialogOpen, setDialogOpen ] = React.useState(false);

  const [ bookingItemId, setBookingItemId ] = useState(null);

  const toggleDialog = () => setDialogOpen((prevState) => !prevState);

  const hasBookingItemId = Boolean(bookingItemId);

  return (
    <>
      <div className="col-12 col-sm-6 col-md-4 col-lg-3 | d-flex">
        <div className="card card-booking-item">
          <div className="card-body">
            <h6 className="mb-0">{bookingItem.identifier}</h6>
            <small className="text-muted">{bookingItem.reservationCategoryName}</small>
            <div className="mb-1">
              <span className={`badge badge-${classes(bookingItem.status)}`}>{bookingItem.status}</span>
            </div>
            {remotelockIntegrationLocked && bookingItem.remotelockDevices ? (
              <small className="d-block text-muted">
                Remotelock Devices
                <b className="d-block text-dark">{bookingItem.remotelockDevices.map((remotelockDevice) => remotelockDevice.name).join(', ')}</b>
              </small>
            ) : null}
            {bookingItem.id !== 0 &&
              <div className="text-right | pt-2">
                <button
                  className="btn btn-primary btn-sm"
                  type="button"
                  onClick={() => setBookingItemId(bookingItem.id)}
                >Edit</button>
                {bookingItem.status !== 'reserved' &&
                  <DropdownButton
                    as={ButtonGroup}
                    alignRight
                    className="ml-2"
                    id={`dropdown-button-drop-${bookingItem.id}`}
                    size="sm"
                    variant="outline-secondary"
                    title={''}
                  >
                    {remotelockIntegrationLocked ? (
                      <Dropdown.Item as="button" onClick={toggleDialog}>Assign remotelock devices</Dropdown.Item>
                    ) : null}
                    {bookingItem.status === 'outOfService' &&
                      <Dropdown.Item as="button" onClick={() => markAsFreeBookingItem(bookingItem.id)}>Mark as free</Dropdown.Item>
                    }
                    {bookingItem.status === 'free' &&
                      <Dropdown.Item as="button" onClick={() => markAsOutOfServiceBookingItem(bookingItem.id)}>Mark as out of service</Dropdown.Item>
                    }
                    <Dropdown.Item as="button" className="text-danger" onClick={() => deleteBookingItem(bookingItem)}>Delete</Dropdown.Item>
                  </DropdownButton>
                }
              </div>
            }
          </div>
        </div>
      </div>
      {/* Modal */}
      {hasBookingItemId &&
        <BookingItemFormModal
          bookingItemId={bookingItemId}
          show={hasBookingItemId}
          onHide={() => setBookingItemId(null)}
        />
      }
      {dialogOpen ? (
        <RemotelockDevicesDialog
          bookingItemId={bookingItem.id}
          bookingRemotelockDevices={bookingItem.remotelockDevices || null}
          show={dialogOpen}
          onHide={toggleDialog}
        />
      ) : null}
    </>
  )
}

const classes = status => {
  return  status === 'reserved'
    ? 'primary'
    : status === 'free'
      ? 'success'
      : 'dark'
  ;
}

const mapDispatchToProps = dispatch => ({
  deleteBookingItem: bookingItem => dispatch(BookingItemActions.delete(bookingItem)),
  markAsFreeBookingItem: bookingItemId => dispatch(BookingItemActions.markAsFree(bookingItemId)),
  markAsOutOfServiceBookingItem: bookingItemId => dispatch(BookingItemActions.markAsOutOfService(bookingItemId)),
});

export default connect(null, mapDispatchToProps)(BookingItemsListItem);
