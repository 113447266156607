import React from 'react';
import { Link, useLocation } from 'react-router-dom';
// Store
import { connect } from 'react-redux';

const Header = ({ user, isAuthenticated, drawerOpen, displayName }) => {
  const { pathname } = useLocation();
  const drawerClasses = () => {
    if ( isAuthenticated ){
      if ( pathname === '/order-check-in' || pathname === '/membership-check-in' ) return '';
      return drawerOpen ? 'is-open' : 'is-closed';
    }
    return '';
  }
  return (
    <div className={`root-header ${ drawerClasses() }`}>
      <nav className="navbar navbar-dark badge-primary">
        <Link className="navbar-brand" to={`${
          isAuthenticated
          ? 
            user.role === 'admin'
            ? `/admin/users`
            : '/manager/products'
          : `/`
        }`}>AdminUI{displayName ? ` - ${displayName}` : null}</Link>
      </nav>
    </div>
  )
}

const mapStateToProps = state => ({
  drawerOpen: state.layout.drawerOpen,
  displayName: state.setting.displayName
});

const mapDispatchToProps = dispatch => ({
  
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
