import Types from '../types/notification.types';

const initialState = {
  notifications: [],

  params: {
    limit: 20,
    offset: 0,
    auth: false
  },

  isFetching: true,

  hasMore: true,
  hasMoreLoading: false,

  isFormLoading: false,
  isFormSuccess: false
};

export default function(state = initialState, { type, payload }){
  switch (type){
    // Request
    case Types.FETCH_ALL:
      return _fetchAll(state, payload);
    case Types.SEND:
    case Types.SEND_BULK:
      return { ...state, isFormLoading: true, isFormSuccess: false };
    // Success
    case Types.FETCH_ALL_SUCCESS:
      return _fetchAllSuccess(state, payload);
    case Types.SEND_SUCCESS:
    case Types.SEND_BULK_SUCCESS:
      return { ...state, isFormLoading: false, isFormSuccess: true };
    // Failure
    case Types.FETCH_ALL_FAILURE:
    case Types.SEND_FAILURE:
    case Types.SEND_BULK_FAILURE:
    case Types.SET_INITIAL_STATE:
      return { ...state, ...initialState }
    default:
      return state;
  }
}

// Fetch all
const _fetchAll = (state, { params, isMore }) => {
  const isMoreExist = (typeof isMore !== 'undefined' && isMore);
  return {
    ...state,
    notifications: isMoreExist ? state.notifications : [],
    params,
    isFetching: isMoreExist ? false : true,
    hasMore: true,
    hasMoreLoading: isMoreExist ? true : false
  };
}
// Fetch all success
const _fetchAllSuccess = (state, { notifications: { data = [] }, params, isMore }) => {
  return {
    ...state,
    notifications: isMore ? [...state.notifications, ...data] : data,
    params,
    isFetching: false,
    hasMore:
      (data.length > 0 && data.length >= state.params.limit)
      ? true : false,
    hasMoreLoading: false
  }
}
