import fetcher from 'app/utilities/fetcher';

class OrderService {
  constructor(){
    this._url = '/admin/store/orders'
  }

  fetchAll = params => {
    const searchParams = new URLSearchParams();
    Object.keys(params).forEach(key => {
      if ( params[key] ) searchParams.append(key, params[key]);
    });
    return fetcher(`${this._url}?${searchParams}`, {
      method: 'GET'
    });
  }

  fetchById = orderId => {
    return fetcher(`${this._url}/${orderId}`, {
      method: 'GET'
    });
  }

  setStatus = (orderId, status) => {
    return fetcher(`${this._url}/${orderId}/status`, {
      method: 'PUT',
      body: JSON.stringify({ status }),
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  cancel = (orderId, notes) => {
    const searchParams = new URLSearchParams();
    if ( notes ) searchParams.append('notes', notes);
    return fetcher(`${this._url}/${orderId}/actions/cancel?${searchParams}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    }, () => null);
  }

  sendReceipt = (orderId, transactionId, email) => {
    const searchParams = new URLSearchParams();
    searchParams.append('email', email);
    return fetcher(`${this._url}/${orderId}/sendReceipt/${transactionId}?${searchParams}`, {
      method: 'POST'
    }, () => null)
  }

  export = params => {
    const searchParams = new URLSearchParams();
    Object.keys(params).forEach(key => {
      if ( params[key] ) searchParams.append(key, params[key]);
    });
    return fetcher(`${this._url}/export?${searchParams}`, {
      method: 'GET',
      responseType: 'blob'
    }, response => response.blob().then(blob => {
      const disposition = response.headers.get('Content-disposition').split('=');
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.download = disposition.length > 0 ? disposition[1] : 'unknown.csv';
      a.href = url;
      a.target = '_blank';

      document.body.appendChild(a);

      a.click();
      a.remove();
    }));
  }

  checkIn = (activityId, confirmationCode, status) => {
    return fetcher(`${this._url}/activities/${activityId}/status`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ confirmationCode, status })
    }, () => null);
  }

  fetchTransactionsList = (orderId) => {
    return fetcher(`${this._url}/${orderId}/transactions`, {
      method: 'GET'
    });
  }
}

const service = new OrderService();
export default service;
