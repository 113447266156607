export const BlackoutActionTypes = {
  FetchBlackouts: '[Blackout] Fetch blackouts',
  FetchBlackoutsSuccess: '[Blackout] Fetch blackouts success',
  // Create
  Create: '[Blackout] Create',
  CreateSuccess: '[Blackout] Create success',
  // Update
  Update: '[Blackout] Update',
  UpdateSuccess: '[Blackout] Update success',
  // Delete
  Delete: '[Blackout] Delete',
  DeleteSuccess: '[Blackout] Delete success',
  // Set filter
  Filter: '[Reservation category] Filter',
  ClearFilter: '[Reservation category] Clear filter',
  // Status
  StatusSuccess: '[Blackout] Status success',
  StatusError: '[Blackout] Status error',
  StatusInitial: '[Blackout] Status initial'
}

export const BlackoutMessageTypes = {
  Create: 'Booking item blackout created',
  Update: 'Booking item blackout updated',
  Delete: 'Booking item blackout deleted'
}
