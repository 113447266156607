// Types
export const FETCH_LIST = '[Content item] Fetch list';
export const FETCH_LIST_SUCCESS = '[Content item] Fetch list success';

export const CREATE = '[Content item] Create';
export const CREATE_SUCCESS = '[Content item] Create success';

export const UPDATE = '[Content item] Update';
export const UPDATE_SUCCESS = '[Content item] Update success';

export const DELETE = '[Content item] Delete';
export const DELETE_SUCCESS = '[Content item] Delete success';

export const MOVE = '[Content item] Move';
export const MOVE_SUCCESS = '[Content item] Move success';

export const SET_STATUS = '[Content item] Set status';

// Actions
export const ContentItemActions = {
  // Fetch list
  fetchList: (params, isMoreLoad = false) => ({
    type: FETCH_LIST,
    payload: { params, isMoreLoad }
  }),
  fetchListSuccess: data => ({
    type: FETCH_LIST_SUCCESS,
    payload: { data }
  }),
  // Create
  create: data => ({
    type: CREATE,
    payload: { data }
  }),
  createSuccess: item => ({
    type: CREATE_SUCCESS,
    payload: { item }
  }),
  // Update
  update: (id, data) => ({
    type: UPDATE,
    payload: { id, data }
  }),
  updateSuccess: item => ({
    type: UPDATE_SUCCESS,
    payload: { item }
  }),
  // Delete
  delete: item => ({
    type: DELETE,
    payload: { item }
  }),
  deleteSuccess: id => ({
    type: DELETE_SUCCESS,
    payload: { id }
  }),
  // Move
  move: (id, type, moveId) => ({
    type: MOVE,
    payload: { id, type, moveId }
  }),
  moveSuccess: (id, moveId) => ({
    type: MOVE_SUCCESS,
    payload: { id, moveId }
  }),
  // Default
  // Set status
  setStatus: status => ({
    type: SET_STATUS,
    payload: { status }
  })
}
