import {
  IMapPoi, IMapPoiData,
  MapPoiActionTypes,
  SET_LIST,
  CREATE, CREATE_SUCCESS,
  UPDATE, UPDATE_SUCCESS,
  DELETE, DELETE_SUCCESS,
  SET_STATUS
} from './map-poi.types';
import Statuses from 'types/statuses';

export default class MapPoiActions {
  // Set list
  public static setList = (items:Array<IMapPoi>):MapPoiActionTypes => ({
    type: SET_LIST, payload: { items }
  });
  // Create
  public static create = (data:IMapPoiData):MapPoiActionTypes => ({
    type: CREATE, payload: { data }
  });
  public static createSuccess = (item:IMapPoi):MapPoiActionTypes => ({
    type: CREATE_SUCCESS, payload: { item }
  });
  // Update
  public static update = (id:number, data:IMapPoiData):MapPoiActionTypes => ({
    type: UPDATE, payload: { id, data }
  });
  public static updateSuccess = (item:IMapPoi):MapPoiActionTypes => ({
    type: UPDATE_SUCCESS, payload: { item }
  });
  // Delete
  public static delete = (id:number):MapPoiActionTypes => ({
    type: DELETE, payload: { id }
  });
  public static deleteSuccess = (id:number):MapPoiActionTypes => ({
    type: DELETE_SUCCESS, payload: { id }
  });
  // Default
  // Set status
  public static setStatus = (status:Statuses):MapPoiActionTypes => ({
    type: SET_STATUS, payload: { status }
  })
}
