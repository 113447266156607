import React from 'react';
// Types
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
// Actions
import OrderActions from 'app/actions/order.actions';
// 
import OrderTransactionsListItem from './OrderTransactionsListItem';

type Props = {
  // Props
  orderId: number;
  email: string | null;
  // State
  transactions: Array<any>;
  isFetching: boolean;
  isLoading: boolean;
  status: string;
  // Dispatch
  fetchTransactionsList: (orderId:number) => void;
};

const OrderTransactionsList:React.FC<Props> = ({
  // Props
  orderId, email,
  // State
  transactions, isFetching, isLoading, status,
  // Dispatch
  fetchTransactionsList
}) => {
  React.useEffect(() => {
    fetchTransactionsList(orderId);
    // eslint-disable-next-line
  }, []);

  if ( isFetching ) return <h1>Loading ...</h1>;
  if ( transactions.length === 0 ) return <h1>No transaction(s)</h1>;
  return (
    <React.Fragment>
      <table className="table">
        <thead>
          <tr>
            <th>Type</th>
            <th>Status</th>
            <th>Amount</th>
            <th>Notes</th>
            <th>Created On</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((item:any, index:number) => (
            <OrderTransactionsListItem
              key={`transactions-item-${index}`}
              orderId={orderId}
              email={email}
              item={item}
              isLoading={isLoading}
              status={status}
            />
          ))}
        </tbody>
      </table>
    </React.Fragment>
  )
}

const mapStateToProps = (state:any) => ({
  transactions: state.OrderState.transactions,
  isFetching: state.OrderState.transactionsFetching,
  isLoading: state.OrderState.isLoading,
  status: state.OrderState.status
});

const mapDispatchToProps = (dispatch:Dispatch) => ({
  fetchTransactionsList: (orderId:number) => dispatch(OrderActions.fetchTransactionsList(orderId))
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderTransactionsList);
