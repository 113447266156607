import fetcher from '../../utilities/fetcher';

const apiURL = '/admin/messageTemplates';
const headers = {
  'Content-Type': 'application/json'
};

export const MessageTemplateService = {
  // Fetch list
  fetchList: () => {
    return fetcher(apiURL, { method: 'GET' });
  },
  // Fetch by id
  fetchById: id => {
    return fetcher(`${apiURL}/${id}`, { method: 'GET' });
  },
  // Update
  update: (id, data) => {
    return fetcher(`${apiURL}/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(data)
    });
  }
}
