import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
import { RestrictionActions } from '../../../store/restriction/restriction.actions';
import * as RestrictionSelectors from '../../../store/restriction/restriction.selectors';
// Bootstrap
import Modal from 'react-bootstrap/Modal';
// Hooks
import useForm from '../../../hooks/useForm';

const pattern = /^\d{4}-\d{2}-\d{2}$/;

const validation = values => {
  const errors = {};
  if ( values.type === 'day' ){
    if ( !values.dayOfWeek ){
      errors['dayOfWeek'] = 'This field is required';
    }
  } else {
    if ( !values.start ){
      errors['start'] = 'This field is required';
    } else if ( !pattern.test(values.start) ){
      errors['start'] = 'Date is not valid';
    }
    if ( !values.end ){
      errors['end'] = 'This field is required';
    } else if ( !pattern.test(values.end) ){
      errors['end'] = 'Date is not valid';
    }
    if ( values.start && values.end ){
      if ( new Date(values.end).getTime() - new Date(values.start).getTime() < 0 ){
        errors['end'] = 'End date can`t be less than start date';
      }
    }
  }
  if ( !values.minDaysReservationNumber ){
    errors['minDaysReservationNumber'] = 'This field is required';
  } else if ( Number(values.minDaysReservationNumber) < 2 ){
    errors['minDaysReservationNumber'] = 'Minimum number of days 2';
  }
  return errors;
}

const RestrictionFormModal = ({
  // Props
  show, onHide,
  // State
  restriction, status,
  // Dispatch
  createRestriction, updateRestriction
}) => {
  const { bookingId } = useParams();

  const callback = () => {
    const { type, dayOfWeek, start, end, minDaysReservationNumber } = values;
    const data = { minDaysReservationNumber };
    if ( type === 'day' ){
      data['dayOfWeek'] = dayOfWeek;
    } else {
      data['start'] = start;
      data['end'] = end;
    }
    if ( restriction && restriction.id ){
      updateRestriction(restriction.id, data);
    } else {
      data['bookingPropertyId'] = parseInt(bookingId, 10);
      createRestriction(data);
    }
  }

  const [ values, errors, handleChange, handleSubmit ] = useForm(callback, {
    type: 
      restriction && restriction.id
      ? restriction.dayOfWeek ? 'day' : 'date'
      : 'day'
    ,
    dayOfWeek: restriction.dayOfWeek || '',
    start: restriction.start || '',
    end: restriction.end || '',
    minDaysReservationNumber: restriction.minDaysReservationNumber || 2,
  }, validation);

  useEffect(() => {
    if ( status === 'Success' ) onHide();
    // eslint-disable-next-line
  }, [status]);

  return (
    <Modal show={show} onHide={onHide}>
      <form onSubmit={handleSubmit}>
        <Modal.Header>
          {restriction.id ? `Update restriction` : 'Create restriction'}
        </Modal.Header>
        <Modal.Body>

          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="type"
              id="day"
              value="day"
              checked={values.type === 'day'}
              onChange={handleChange}
            />
            <label
              className="form-check-label"
              htmlFor="day"
            >Day</label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="type"
              id="date"
              value="date"
              checked={values.type === 'date'}
              onChange={handleChange}
            />
            <label
              className="form-check-label"
              htmlFor="date"
            >Date</label>
          </div>

          {values.type === 'day' &&
            <div className="form-group">
              <label htmlFor="dayOfWeek">Day of week&nbsp;*</label>
              <select
                className={`form-control form-control-sm ${ errors.dayOfWeek && 'is-invalid' }`}
                id="dayOfWeek"
                name="dayOfWeek"
                value={values.dayOfWeek}
                onChange={handleChange}
              >
                <option value="">Choose day</option>
                <option value="SUNDAY">Sunday</option>
                <option value="MONDAY">Monday</option>
                <option value="TUESDAY">Tuesday</option>
                <option value="WEDNESDAY">Wednesday</option>
                <option value="THURSDAY">Thursday</option>
                <option value="FRIDAY">Friday</option>
                <option value="SATURDAY">Saturday</option>
              </select>
              {errors.dayOfWeek && <div className="invalid-feedback">{errors.dayOfWeek}</div>}
            </div>
          }
          {values.type === 'date' &&
            <div className="row row-8">
              <div className="col-12 col-sm-6">
                <div className="form-group">
                  <label htmlFor="start">Start&nbsp;*</label>
                  <input
                    className={`form-control form-control-sm ${ errors.start && 'is-invalid' }`}
                    id="start"
                    type="date"
                    name="start"
                    value={values.start}
                    onChange={handleChange}
                  />
                  {errors.start && <div className="invalid-feedback">{errors.start}</div>}
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="form-group">
                  <label htmlFor="end">End&nbsp;*</label>
                  <input
                    className={`form-control form-control-sm ${ errors.end && 'is-invalid' }`}
                    id="end"
                    type="date"
                    name="end"
                    value={values.end}
                    onChange={handleChange}
                  />
                  {errors.end && <div className="invalid-feedback">{errors.end}</div>}
                </div>
              </div>
            </div>
          }

          <div className="form-group">
            <label htmlFor="minDaysReservationNumber">Min days reservation number&nbsp;*</label>
            <input
              className={`form-control form-control-sm ${ errors.minDaysReservationNumber && 'is-invalid' }`}
              id="minDaysReservationNumber"
              type="number"
              name="minDaysReservationNumber"
              value={values.minDaysReservationNumber}
              onChange={handleChange}
            />
            {errors.minDaysReservationNumber && <div className="invalid-feedback">{errors.minDaysReservationNumber}</div>}
          </div>

        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-secondary btn-sm"
            type="button"
            onClick={onHide}
          >Cancel</button>
          <button
            className="btn btn-primary btn-sm"
            type="submit"
          >Save</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

const mapStateToProps = ({ RestrictionState }, ownProps) => ({
  restriction: RestrictionSelectors.getRestrictionById(RestrictionState, ownProps.restrictionId),
  status: RestrictionSelectors.getStatus(RestrictionState)
});

const mapDispatchToProps = dispatch => ({
  createRestriction: data => dispatch(RestrictionActions.create(data)),
  updateRestriction: (restrictionId, data) => dispatch(RestrictionActions.update(restrictionId, data)),
});

RestrictionFormModal.defaultProps = {
  restriction: null
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionFormModal);
