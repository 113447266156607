import { createSelector } from 'reselect';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
// Selectors
import * as BookingItemSelectors from '../booking-item/booking-item.selectors';
// import * as BlackoutSelectors from '../blackout/blackout.selectors';
// Utilities
import { toCamperTimezone } from 'utilities/datetime.utility';
// ToDO
import { setStart, setEnd, checkIsSameOrAfter, checkIsSameOrBefore, toCamperZoneDate, toCamperZone } from 'app/utilities/datetime.convert';



dayjs.extend(utc);

// const isDateValid = value => /^\d{4}-\d{2}-\d{2}$/.test(value);

const _reservation = state => state.reservations;
const _reservationTotal = state => state.reservationTotal;
const _bookingItems = state => state.bookingItems;
const _transactions = state => state.transactions;
const _params = state => state.params;

export const getStatus = state => state.status;
export const getIsLoading = state => state.isLoading;
export const getParams = createSelector(
  _params, params => params
);
export const getReservations = createSelector(
  _reservation,
  reservations => {
    return reservations || [];
    // if ( !reservations ||  reservations.length === 0 ) return [];
    // const { search, status, start, end } = filter;
    // const nextSearch = search.toLowerCase();
    // const nextStart = start && isDateValid(start) ? setStart(start) : '';
    // const nextEnd = end && isDateValid(end) ? setEnd(end) : '';
    // return reservations.filter(reservation => {
    //   let hasSearch = false;
    //   let hasStatus = false;
    //   let hasStart = false;
    //   let hasEnd = false;

    //   const rs = setStart(reservation.start);
    //   const re = setEnd(reservation.end);

    //   hasSearch = !search
    //     ? true
    //     : 
    //       (
    //         reservation.reservationNumber.toString().indexOf(nextSearch) > -1 ||
    //         reservation.username.toLowerCase().indexOf(nextSearch) > -1
    //       ) ? true : false
    //   ;
    //   hasStatus = !status
    //     ? true
    //     : reservation.status === status
    //   ;
    //   hasStart = !nextStart ? true : checkIsSameOrAfter(rs, nextStart, 'day');
    //   hasEnd = !nextEnd ? true : checkIsSameOrBefore(re, nextEnd, 'day');

    //   return hasSearch && hasStatus && hasStart && hasEnd;
    // });
  }
);
export const getReservationTotal = createSelector(
  _reservationTotal, reservationTotal => reservationTotal
);
export const getReservationById = createSelector(
  _reservation, (_, props) => props,
  (reservations, reservationId) => reservations.find(r => r.id === reservationId)
);
export const getBookingItems = createSelector(
  _bookingItems,
  bookingItems => bookingItems
);
export const getReservationItems = createSelector(
  state => BookingItemSelectors.getBookingItems(state.BookingItemState),
  // ToDO
  // state => BlackoutSelectors.getBlackouts(state.BlackoutState),
  state => getReservations(state.ReservationState),
  (_, props) => props,
  (bookingItems, /* blackouts, */ reservations, days ) => {

    const unassignReservationItems = [];
    const assignReservationItems = [];
    const rooms = {};

    for ( let reservation of reservations ){
      if ( !reservation.items || reservation.items.length === 0 ) continue;
      // #197
      if ( reservation.status === 'cancelled' ) continue;
      const reservationCopy = { ...reservation };
      delete reservationCopy['items'];
      for ( let item of reservation.items ){
        const itemCopy = { ...reservationCopy, ...item };
        if ( item.bookingPropertyItemId ){
          assignReservationItems.push(itemCopy)
        } else {
          unassignReservationItems.push(itemCopy);
        }
      }
    }

    if ( unassignReservationItems.length !== 0 ){
      for ( let unassign of unassignReservationItems ){
        const start1 = setStart(unassign.start);
        const end1 = setEnd(unassign.end);
        let i = 1;
        while ( true ){
          if ( rooms[`_${i}`] && rooms[`_${i}`].length !== 0 ){
            let nextStep = false;
            for ( let room of rooms[`_${i}`] ){
              const start2 = setStart(room.start);
              const end2 = setEnd(room.end);
              if ( checkIsSameOrAfter(start1, end2, 'day') || checkIsSameOrBefore(end1, start2, 'day') ){
                // All ok
              } else {
                i++;
                nextStep = true;
                break;
              }
            }
            if ( nextStep ) continue;
            rooms[`_${i}`].push(unassign);
            break;
          } else {
            rooms[`_${i}`] = [unassign];
            break;
          }
        }
      }
    }

    // if ( blackouts.length !== 0 ){
    //   for ( let blackout of blackouts ){
    //     assignReservationItems.push({
    //       ...blackout,
    //       isBlackout: true
    //     });
    //   }
    // }

    return bookingItems.map(bookingItem => {
      let timelines = {};
      if ( bookingItem.status === 'outOfService' ) return {
        bookingItem,
        timelines: days.reduce((acc, day) => {
          const data = {};
          if ( days[0].isSame(day, 'day') ){
            data['isOutOfService'] = true;
            data['days'] = days[days.length - 1].add(1, 'day').diff(days[0], 'day');
          }
          acc[day.format('MM-DD-YYYY')] = data;
          return acc;
        }, {})
      }
      if ( bookingItem.id === 0 ){
        timelines = Object.keys(rooms).map(room => days.reduce((acc, day) => {
          const data = {};
          for ( let item of rooms[room] ){

            let start = setStart(toCamperTimezone(item.start));
            let end = item.checkOutDate ? setEnd(toCamperTimezone(item.checkOutDate)) : setEnd(toCamperTimezone(item.end));
            let hasStart = true;
            let hasEnd = true;

            if ( start.isBefore(day, 'month') ){
              start = setStart(days[0]);
              hasStart = false;
            }
            if ( end.isAfter(day, 'month') ){
              end = setEnd(days[days.length - 1].add(1, 'day'));
              hasEnd = false;
            }

            if ( day.isSame(start, 'day') ){
              data['hasReservation'] = start.isSame(end, 'day') || end.isBefore(day, 'month') ? false : true;
              data['days'] = end.diff(start, 'day') || 1;
              data['hasStart'] = hasStart;
              data['hasEnd'] = hasEnd;
              data['item'] = item;
            }
          }
          acc[day.format('MM-DD-YYYY')] = data;
          return acc;
        }, {}));
      } else {
        for ( let day of days ){
          const data = {};
          for ( let item of assignReservationItems ){
            if ( item.bookingPropertyItemId === bookingItem.id ){

              let start = setStart(toCamperTimezone(item.start));
              let end = item.checkOutDate ? setEnd(toCamperTimezone(item.checkOutDate)) : setEnd(toCamperTimezone(item.end));
              let hasStart = true;
              let hasEnd = true;

              if ( start.isBefore(day, 'month') ){
                start = setStart(days[0]);
                hasStart = false;
              }
              if ( end.isAfter(day, 'month') ){
                end = setEnd(days[days.length - 1].add(1, 'day'));
                hasEnd = false;
              }

              if ( day.isSame(start, 'day') ){
                data['hasReservation'] = start.isSame(end, 'day') || end.isBefore(day, 'month') ? false : true;
                data['days'] = end.diff(start, 'day');
                data['hasStart'] = hasStart;
                data['hasEnd'] = hasEnd;
                data['isBlackout'] = item.isBlackout || false;
                data['item'] = item;
              }
            }
          }
          timelines[day.format('MM-DD-YYYY')] = data;
        }
      }

      return {
        bookingItem,
        timelines
      };
    });
  }
);
export const getReservationItemsHourly = createSelector(
  state => BookingItemSelectors.getBookingItems(state.BookingItemState),
  state => getReservations(state.ReservationState),
  (_, props) => props,
  (bookingItems, reservations, now) => {
    const unassignReservationItems = [];
    const assignReservationItems = [];
    const rooms = {};

    const hours = Array.from(Array(24).keys());

    for ( let reservation of reservations ){
      if ( !reservation.items || reservation.items.length === 0 ) continue;
      // #197
      if ( reservation.status === 'cancelled' ) continue;
      const reservationCopy = { ...reservation };
      delete reservationCopy['items'];
      for ( let item of reservation.items ){
        const itemCopy = { ...reservationCopy, ...item };
        if ( item.bookingPropertyItemId ){
          assignReservationItems.push(itemCopy)
        } else {
          unassignReservationItems.push(itemCopy);
        }
      }
    }

    if ( unassignReservationItems.length !== 0 ){
      for ( let unassign of unassignReservationItems ){
        const start1 = setStart(unassign.start);
        const end1 = setEnd(unassign.end);
        let i = 1;
        while ( true ){
          if ( rooms[`_${i}`] && rooms[`_${i}`].length !== 0 ){
            let nextStep = false;
            for ( let room of rooms[`_${i}`] ){
              const start2 = setStart(room.start);
              const end2 = setEnd(room.end);
              if ( checkIsSameOrAfter(start1, end2, 'hour') || checkIsSameOrBefore(end1, start2, 'hour') ){
                // All ok
              } else {
                i++;
                nextStep = true;
                break;
              }
            }
            if ( nextStep ) continue;
            rooms[`_${i}`].push(unassign);
            break;
          } else {
            rooms[`_${i}`] = [unassign];
            break;
          }
        }
      }
    }

    const result = bookingItems.map(bookingItem => {
      let timelines = {}
      // if ( bookingItem.status === 'outOfService' ) return {
      //   bookingItem,
      //   timelines: hours.reduce((acc, hour) => {
      //     const data = {};
      //     if ( hours[0].isSame(day, 'day') ){
      //       data['isOutOfService'] = true;
      //       data['hours'] = hours[hours.length - 1].add(1, 'hour').diff(hours[0], 'hour');
      //     }
      //     acc[hour] = data;
      //     return acc;
      //   }, {})
      // }
      if ( bookingItem.id === 0 ){
        timelines = Object.keys(rooms).map(room => hours.reduce((acc, hour) => {
          const data = {};
          for ( let item of rooms[room] ){
            const start = toCamperZone(item.start);
            const end = item.checkOutDate ? toCamperZone(item.checkOutDate) : toCamperZone(item.end);

            if ( now.hour(hour).isSame(start, 'hour') ){
              data['hasReservation'] = true;
              data['hours'] = end.diff(start, 'hour') || 1;
              data['item'] = item;
            }
          }
          acc[hour] = data;
          return acc;
        }, {}))
      } else {
        for ( let hour of hours ){
          const data = {};
          for ( let item of assignReservationItems ){
            if ( item.bookingPropertyItemId === bookingItem.id ){
              const start = toCamperZone(item.start);
              const end = item.checkOutDate ? toCamperZone(item.checkOutDate) : toCamperZone(item.end);

              if ( now.hour(hour).isSame(start, 'hour') ){
                data['hasReservation'] = true;
                data['hours'] = end.diff(start, 'hour') || 1;
                data['item'] = item;
              }
            }
          }
          timelines[hour] = data;
        }
      }
      return {
        bookingItem,
        timelines
      };
    });
    return result;
  }
);
export const getTransactions = createSelector(
  _transactions,
  transactions => transactions.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn))
);
export const getTransactionAmount = createSelector(
  state => getTransactions(state),
  transactions => transactions.reduce((acc, cur) => {
    acc = cur.type === 'payment'
      ? acc + cur.amount
      : cur.type === 'refund'
        ? acc - cur.amount
        : acc
    ;
    return acc;
  }, 0)
);
export const hasPaymentTransaction = createSelector(
  _transactions, transactions => {
    if ( !transactions || transactions.length === 0 ) return false;
    return transactions.some(transaction => transaction.type === 'payment');
  }
);
export const isPaymentSameRefund = createSelector(
  _transactions, transactions => {
    if ( !transactions || transactions.length === 0 ) return false;
    const paymentTotal = transactions.reduce((acc, cur) => {
      if ( cur.type === 'payment' ) acc += cur.amount;
      return acc;
    }, 0);
    const refundTotal = transactions.reduce((acc, cur) => {
      if ( cur.type === 'refund' ) acc += cur.amount;
      return acc;
    }, 0);
    return paymentTotal === refundTotal;
  }
)
