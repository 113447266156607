import { ReservationActionTypes } from './reservation.types';

export const ReservationActions = {
  // Fetch reservations
  fetchReservations: (bookingId, params, isLoadMore = false) => ({
    type: ReservationActionTypes.FetchReservations,
    bookingId,
    params,
    isLoadMore
  }),
  fetchReservationsSuccess: reservation => ({
    type: ReservationActionTypes.FetchReservationsSuccess,
    reservation
  }),
  // Fetch reservation details
  fetchReservationDetails: (bookingId, params) => ({
    type: ReservationActionTypes.FetchReservationDetails,
    bookingId,
    params
  }),
  fetchReservationDetailsSuccess: reservation => ({
    type: ReservationActionTypes.FetchReservationDetailsSuccess,
    reservation
  }),

  fetchAvailableBookingItemsForAssigning: (includeAssignedBookingPropertyItems, reservationId, reservationCategoryId) => ({
    type: ReservationActionTypes.FetchAvailableBookingItemsForAssigning,
    includeAssignedBookingPropertyItems,
    reservationId,
    reservationCategoryId
  }),
  fetchAvailableBookingItemsForAssigningSuccess: bookingItems => ({
    type: ReservationActionTypes.FetchAvailableBookingItemsForAssigningSuccess,
    bookingItems
  }),

  clearAvailableBookingItems: () => ({
    type: ReservationActionTypes.ClearAvailableBookingItems
  }),

  // Create
  create: data => ({
    type: ReservationActionTypes.Create,
    data
  }),
  createSuccess: reservation => ({
    type: ReservationActionTypes.CreateSuccess,
    reservation
  }),

  // Assing
  assing: (reservationId, bookingItemIds) => ({
    type: ReservationActionTypes.Assign,
    reservationId,
    bookingItemIds
  }),
  assingSuccess: reservation => ({
    type: ReservationActionTypes.AssignSuccess,
    reservation
  }),
  // Unassign
  unassign: (reservationId, bookingItemIds) => ({
    type: ReservationActionTypes.Unassign,
    reservationId,
    bookingItemIds
  }),
  unassignSuccess: reservation => ({
    type: ReservationActionTypes.UnassignSuccess,
    reservation
  }),

  unassignAssign: (reservationId, unassignBookingItemIds, assignBookingItemIds) => ({
    type: ReservationActionTypes.UnassignAssign,
    reservationId,
    unassignBookingItemIds,
    assignBookingItemIds
  }),
  unassignAssignSuccess: reservation => ({
    type: ReservationActionTypes.UnassignAssignSuccess,
    reservation
  }),

  // Actions
  // Check in
  checkIn: (reservation, sendCheckInNotification) => ({
    type: ReservationActionTypes.CheckIn,
    reservation,
    sendCheckInNotification
  }),
  checkInSuccess: reservation => ({
    type: ReservationActionTypes.CheckInSuccess,
    reservation
  }),
  // Check out
  checkOut: reservation => ({
    type: ReservationActionTypes.CheckOut,
    reservation
  }),
  checkOutSuccess: reservation => ({
    type: ReservationActionTypes.CheckOutSuccess,
    reservation
  }),

  // Refund
  refund: (reservationId, data) => ({
    type: ReservationActionTypes.Refund,
    reservationId,
    data
  }),
  refundSuccess: transaction => ({
    type: ReservationActionTypes.RefundSuccess,
    transaction
  }),

  // Remaining payment
  remainingPayment: (reservationId, data) => ({
    type: ReservationActionTypes.RemainingPayment,
    reservationId,
    data
  }),
  remainingPaymentSuccess: (reservation) => ({
    type: ReservationActionTypes.RemainingPaymentSuccess,
    reservation
  }),

  // Cancel
  cancel: reservation => ({
    type: ReservationActionTypes.Cancel,
    reservation
  }),
  cancelSuccess: reservation => ({
    type: ReservationActionTypes.CancelSuccess,
    reservation
  }),

  // Send receipt
  sendReceipt: (reservationId, transactionId, email) => ({
    type: ReservationActionTypes.SendReceipt,
    reservationId,
    transactionId,
    email
  }),
  sendReceiptSuccess: () => ({
    type: ReservationActionTypes.SendReceiptSuccess
  }),

  // Mark order complete
  markOrderComplete: (reservationId, orderId, status) => ({
    type: ReservationActionTypes.MarkOrderComplete,
    reservationId,
    orderId,
    status
  }),
  markOrderCompleteSuccess: reservation => ({
    type: ReservationActionTypes.MarkOrderCompleteSuccess,
    reservation
  }),

  // Change end date
  changeEndDate: (reservationId, params) => ({
    type: ReservationActionTypes.ChangeEndDate,
    reservationId,
    params
  }),
  changeEndDateSuccess: (reservation) => ({
    type: ReservationActionTypes.ChangeEndDateSuccess,
    reservation
  }),
  changeEndDateFailure: (reservationProlong) => ({
    type: ReservationActionTypes.ChangeEndDateFailure,
    reservationProlong
  }),
  // Shift
  shift: (reservationId, params) => ({
    type: ReservationActionTypes.Shift,
    reservationId,
    params
  }),
  shiftSuccess: reservation => ({
    type: ReservationActionTypes.ShiftSuccess,
    reservation
  }),
  shiftError: reservationShift => ({
    type: ReservationActionTypes.ShiftError,
    reservationShift
  }),

  // Transactions
  fetchTransactions: reservationId => ({
    type: ReservationActionTypes.FetchTransactions,
    reservationId
  }),
  fetchTransactionsSuccess: transactions => ({
    type: ReservationActionTypes.FetchTransactionsSuccess,
    transactions
  }),

  // Filter
  setParams: (paramsType, paramsValue) => ({
    type: ReservationActionTypes.Filter,
    paramsType,
    paramsValue
  }),
  resetParams: () => ({
    type: ReservationActionTypes.ResetParams
  }),

  resetField: field => ({
    type: ReservationActionTypes.ResetField,
    field
  }),
  // Set status
  setStatusSuccess: () => ({
    type: ReservationActionTypes.StatusSuccess
  }),
  setStatusError: () => ({
    type: ReservationActionTypes.StatusError
  }),
  setStatusInitial: () => ({
    type: ReservationActionTypes.StatusInitial
  })
}