import { BlackoutActionTypes } from './blackout.types';

export const BlackoutActions = {
  // Fetch blackouts
  fetchBlackouts: (bookingId, start, end) => ({
    type: BlackoutActionTypes.FetchBlackouts,
    bookingId,
    start,
    end
  }),
  fetchBlackoutsSuccess: blackouts => ({
    type: BlackoutActionTypes.FetchBlackoutsSuccess,
    blackouts
  }),

  // Create
  create: data => ({
    type: BlackoutActionTypes.Create,
    data
  }),
  createSuccess: blackout => ({
    type: BlackoutActionTypes.CreateSuccess,
    blackout
  }),
  // Update
  update: (blackoutId, data) => ({
    type: BlackoutActionTypes.Update,
    blackoutId,
    data
  }),
  updateSuccess: blackout => ({
    type: BlackoutActionTypes.UpdateSuccess,
    blackout
  }),
  // Delete
  delete: blackout => ({
    type: BlackoutActionTypes.Delete,
    blackout
  }),
  deleteSuccess: blackoutId => ({
    type: BlackoutActionTypes.DeleteSuccess,
    blackoutId
  }),

  // Filter
  setFilter: (filterType, filterValue) => ({
    type: BlackoutActionTypes.Filter,
    filterType,
    filterValue
  }),
  clearFilter: () => ({
    type: BlackoutActionTypes.ClearFilter
  }),

  // Set status
  setStatusSuccess: () => ({
    type: BlackoutActionTypes.StatusSuccess
  }),
  setStatusError: () => ({
    type: BlackoutActionTypes.StatusError
  }),
  setStatusInitial: () => ({
    type: BlackoutActionTypes.StatusInitial
  })
}