import React from 'react';
// 
import ProductCategoriesCreate from './ProductCategoriesCreate';
import ProductCategoriesList from './ProductCategoriesList';

const ProductCategories = () => {
  return (
    <div className="area area-products">
      <div className="container-fluid">
        <ProductCategoriesCreate />
        <ProductCategoriesList />
      </div>
    </div>
  )
}

export default ProductCategories;
