import React, { Component } from 'react';
// Store
import { connect } from 'react-redux';
import {
  fetchScheduleSlots,
  selectScheduleSlot,
  deleteScheduleSlot
} from '../../../actions/schedule.actions';
// Layouts
import { ScheduleSlotFormModal } from '../../../layouts/Modals';
// Component
import { DataLoading } from '../../../components/Loadings';
// 
import ScheduleCalendar from './ScheduleCalendar';
import ScheduleSlotsList from './ScheduleSlotsList';

class Schedules extends Component {
  state = {
    isModalShown: false
  }

  componentDidMount(){

  }

  handleFetchScheduleSlots = params => {
    this.props.fetchScheduleSlots(params);
  }

  handleShowModal = slot => () => {
    (slot && slot.id) && this.props.selectScheduleSlot(slot.id);
    this.setState(prevState => ({
      isModalShown: !prevState.isModalShown
    }));
  }

  handleHideModal = () => {
    this.setState({
      isModalShown: false
    });
  }

  render() {
    const { isModalShown } = this.state;
    const { slots, isFetching } = this.props;
    return (
      <div className="area area-templates">
        <div className="container-fluid">
          <div className="area-container">
            <div className="form-group | clearfix">
              <button
                className="btn btn-primary btn-sm | float-right"
                type="button"
                onClick={this.handleShowModal(null)}
              >Create schedule slot</button>
            </div>
            <ScheduleCalendar
              onFetchScheduleSlots={this.handleFetchScheduleSlots}
            />
            {isFetching && <DataLoading />}
            {!isFetching &&
              <ScheduleSlotsList
                slots={slots}
                onUpdateScheduleSlot={this.handleShowModal}
                onDeleteScheduleSlot={this.props.deleteScheduleSlot}
              />
            }
            {/* Modal */}
            {isModalShown &&
              <ScheduleSlotFormModal
                show={isModalShown}
                onHide={this.handleHideModal}
              />
            }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  slots: state.schedule.slots,
  isFetching: state.schedule.isFetching
});

const mapDispatchToProps = dispatch => ({
  fetchScheduleSlots: params => dispatch(fetchScheduleSlots(params)),
  selectScheduleSlot: slotId => dispatch(selectScheduleSlot(slotId)),
  deleteScheduleSlot: slotId => dispatch(deleteScheduleSlot(slotId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Schedules);
