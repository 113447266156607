import {
  FETCH_LIST, FETCH_LIST_SUCCESS,
  FETCH_BY_ID, FETCH_BY_ID_SUCCESS,
  UPDATE, UPDATE_SUCCESS,
  SET_STATUS
} from './message-template.actions';

const initialState = {
  templates: {
    items: [],
    isFetching: false,
    isLoading: false
  },
  template: {
    item: {},
    isFetching: false,
    isLoading: false
  },
  status: 'Initial'
};

export default (state = initialState, { type, payload }) => {
  switch (type){
    case FETCH_LIST:
      return { ...state, templates: {
        ...state.templates,
        isFetching: true
      }};
    case FETCH_LIST_SUCCESS:
      return { ...state, templates: {
        ...state.templates,
        items: payload.items,
        isFetching: false
      }};

    case FETCH_BY_ID:
      return { ...state, template: {
        ...state.template,
        isFetching: true
      }};
    case FETCH_BY_ID_SUCCESS:
      return { ...state, template: {
        ...state.template,
        item: payload.item,
        isFetching: false
      }};

    case UPDATE:
      return { ...state, template: {
        ...state.template,
        isLoading: true
      }};
    case UPDATE_SUCCESS:
      return {
        ...state,
        template: {
          ...state.template,
          isLoading: false
        },
        templates: {
          ...state.templates,
          items: state.templates.items.map(
            item => item.id === payload.item.id
              ? payload.item
              : item
          )
        }
      };

    case SET_STATUS:
      return { ...state, status: payload.status };
    default:
      return state;
  }
}
