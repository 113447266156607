import React, { Component } from 'react';
// Store
import { connect } from 'react-redux';
import ProductActions from '../../../actions/product.actions';
// Components
import { Input, Textarea, Select, Checkbox } from '../../../components/Controls';
import { ButtonLoading } from '../../../components/Buttons';

class ProductFormBuilder extends Component {
  constructor(props){
    super(props);

    const { categories, inventoryGroups, product } = props;
    const { image, inventoryGroup = {}, productCategory = {}, quantity = '' } = product;

    const groups =
      inventoryGroups.length > 0
      ? inventoryGroups.map(g => ({ label: g.name, value: g.id }))
      : []
    ;

    const onlineInventoryGroupIds = inventoryGroups.filter(item => item.type === 'online').map(item => item.id);

    const productCategories =
      categories.length !== 0
      ? categories.map(c => ({ label: c.name, value: c.id }))
      : []
    ;

    this.state = {
      onlineInventoryGroupIds,

      image: {},
      imageToPreview: image && image.href ? image.href : '',

      isFormSubmitted: false,

      product: {
        ...product,
        quantity,
        inventoryGroupId:
          typeof inventoryGroup.id !== 'undefined'
          ? inventoryGroup.id
          : ''
        ,
        productCategoryId:
          typeof productCategory.id !== 'undefined'
          ? productCategory.id
          : ''
        ,
        reservationAvailable:
          typeof product.id !== 'undefined'
          ? product.reservationAvailable
          : false
      },

      categories: [
        { value: '', label: 'Choose product category' },
        ...productCategories
      ],
      groups: [
        { value: '', label: 'Choose inventory group' },
        ...groups
      ]
    }
  }

  handleChange = evt => {
    const { target: { type, name, value, checked } } = evt;
    this.setState(prevState => ({
      product: {
        ...prevState.product,
        [name]: type === 'checkbox' ? checked : value
      }
    }));
  }

  handleFileChange = evt => {
    const { product } = this.props;
    const image = evt.target.files[0];
    const imageToPreview = URL.createObjectURL(image);
    this.setState({
      image,
      imageToPreview
    }, () => {
      if ( product && product.id ){
        this.props.uploadImage(product.id, image);
      }
    });
  }

  handleProductImageDelete = evt => {
    this.setState({
      image: {},
      imageToPreview: ''
    }, () => {
      const { product } = this.props;
      this.props.deleteImage(product.id)
    });
  }

  handleSubmit = evt => {
    evt.preventDefault();

    this.setState({ isFormSubmitted: true });

    if ( !this.formRef.checkValidity() ) return;

    const { image, product } = this.state;

    const data = { ...product };

    delete data['image'];

    if ( product.price ) data['price'] = parseFloat(product.price);
    if ( product.quantity !== '' ){
      data['quantity'] = parseInt(product.quantity, 10)
    } else {
      if ( data.hasOwnProperty('quantity') ){
        delete data['quantity'];
      }
    }
    if ( product.productCategoryId !== '' ){
      data['productCategoryId'] = parseInt(product.productCategoryId, 10)
    } else {
      if ( data.hasOwnProperty('productCategoryId') ){
        delete data['productCategory'];
        delete data['productCategoryId'];
      }
    }
    if ( this.state.onlineInventoryGroupIds.indexOf(Number(product.inventoryGroupId)) !== -1 ){
      data['reservationAvailable'] = false;
    }

    if ( product && product.id ){
      this.props.update({id: product.id, ...data});
    } else {
      this.props.create({
        image,
        data
      });
    }
  }

  render() {
    const {
      isFormSubmitted, imageToPreview,
      product, groups, categories
    } = this.state;
    const { isImageFetching, isFormLoading } = this.props;
    return (
      <form
        noValidate
        name="productForm"
        ref={ref => this.formRef = ref}
        onSubmit={this.handleSubmit}
      >
        <div className="row row-8">
          <div className="col-12 col-sm-6 col-lg-4 mb-2">
            {/* Image */}
            <div className="image-upload">
              <div className="image-upload-preview">
                {imageToPreview
                  ? <img src={imageToPreview} alt="" />
                  : <div className="_no-image">
                      <i className="fa fa-image" aria-hidden="true"></i>
                      <h5>No image</h5>
                    </div>
                }
              </div>
              <div className="form-group form-group-image-upload">
                <input type="file" id="image" onChange={this.handleFileChange } disabled={isImageFetching} />
                <label
                  htmlFor="image"
                  className="btn btn-primary btn-block btn-sm"
                >
                  {imageToPreview
                    ? 'Update image'
                    : 'Choose image'
                  }
                </label>
              </div>
              {(imageToPreview) &&
                <ButtonLoading
                  loading={isImageFetching}
                  classes="btn btn-outline-danger btn-block btn-sm"
                  onClick={this.handleProductImageDelete}
                >Remove image</ButtonLoading>
              }
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-8">
            {/* Name */}
            <Input
              submitted={isFormSubmitted}
              label="Name" id="name" name="name"
              value={product.name}
              required={true}
              onChange={this.handleChange}
            />
            {/* Description */}
            <Textarea
              submitted={isFormSubmitted}
              label="Description" id="description" name="description"
              value={product.description}
              required={true}
              onChange={this.handleChange}
            />
            <div className="row row-8">
              <div className="col-12 col-sm-6">
                {/* Price */}
                <Input
                  submitted={isFormSubmitted}
                  label="Price" type="number" id="price" name="price"
                  value={product.price}
                  required={true}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-12 col-sm-6">
                {/* Quantity */}
                <Input
                  label="Quantity" type="number" id="quantity" name="quantity"
                  value={product.quantity}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="row row-8">
              <div className="col-12 col-sm-6">
                {/* Inventory groups */}
                <Select
                  submitted={isFormSubmitted}
                  label="Inventory group" id="inventoryGroupId" name="inventoryGroupId"
                  value={product.inventoryGroupId}
                  required={true}
                  onChange={this.handleChange}
                  options={groups}
                />
              </div>
              <div className="col-12 col-sm-6">
                {/* Inventory groups */}
                <Select
                  label="Product category" id="productCategoryId" name="productCategoryId"
                  value={product.productCategoryId}
                  onChange={this.handleChange}
                  options={categories}
                />
              </div>
            </div>
            {/* Available */}
            <Checkbox
              label="Available" id="available" name="available"
              checked={product.available}
              onChange={this.handleChange}
            />
            {this.state.onlineInventoryGroupIds.indexOf(Number(product.inventoryGroupId)) === -1 ? (
              <Checkbox
                label="Reservation available" id="reservationAvailable" name="reservationAvailable"
                checked={product.reservationAvailable}
                onChange={this.handleChange}
              />
            ) : null}
            <div className="text-right | border-top pt-3 mt-3">
              <ButtonLoading
                loading={isFormLoading}
                type="submit"
              >Save</ButtonLoading>
            </div>
          </div>
        </div>

      </form>
    )
  }
}

export default connect(
  ({ inventoryGroup, ProductState, ProductCategoryState }) => ({
    categories: ProductCategoryState.categories,

    inventoryGroups: inventoryGroup.items,

    product: ProductState.product,
    isImageFetching: ProductState.isImageFetching,
    isFormLoading: ProductState.isFormLoading,
    isFormSuccess: ProductState.isFormSuccess
  }),
  dispatch => ({
    create: product => dispatch(ProductActions.create(product)),
    update: product => dispatch(ProductActions.update(product)),
    uploadImage: (productId, image) => dispatch(ProductActions.uploadImage(productId, image)),
    deleteImage: productId => dispatch(ProductActions.deleteImage(productId))
  })
)(ProductFormBuilder);
