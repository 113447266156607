import React, { useEffect } from 'react';
// Redux
import { connect } from 'react-redux';
import { BookingActions } from 'app/store/booking/booking.actions';
import * as BookingSelectors from 'app/store/booking/booking.selectors';
// Bootstrap
import { Modal } from 'react-bootstrap';
// Hooks
import useForm from 'app/hooks/useForm';
// Constants
import { BOOKING_TYPES, BOOKING_PERIOD } from 'app/App.constants';
// 
import BookingFormImages from './BookingFormImages';
import BookingFormDynamicFields from './BookingFormDynamicFields';
import Taxes from 'components/Taxes';

const validate = values => {
  const errors = {};
  if ( !values.type ) errors['type'] = 'This field is required';
  if ( !values.period ) errors['period'] = 'This field is required';
  if ( values.checkInTime && !values.checkOutTime ){
    errors['checkOutTime'] = 'This field is required';
  }
  if ( !values.checkInTime && values.checkOutTime ){
    errors['checkInTime'] = 'This field is required';
  }
  if ( !values.name ){
    errors['name'] = 'This field is required';
  }
  if ( !values.description ){
    errors['description'] = 'This field is required';
  }
  if ( values.tax === '' ){
    errors['tax'] = 'This field is required';
  } else if ( Number(values.tax) < 0 ){
    errors['tax'] = 'It can`t be less than 0';
  } else if ( Number(values.tax) > 100 ){
    errors['tax'] = 'It can`t be more than 100';
  }
  if ( values.deposit === '' ){
    errors['deposit'] = 'This field is required';
  } else if ( Number(values.deposit) < 0 ){
    errors['deposit'] = 'It can`t be less than 0';
  } else if ( Number(values.deposit) > 100 ){
    errors['deposit'] = 'It can`t be more than 100';
  }
  if ( values.petFee && Number(values.petFee) < 0 ){
    errors['petFee'] = 'It can`t be less than 0';
  }
  return errors;
}

const BookingFormModal = ({
  // Props
  bookingId, show, onHide,
  // State
  booking, status,
  // Dispatch
  createBooking, updateBooking
}) => {
  const callback = () => {
    const { images, type, period, checkInTime, checkOutTime, name, description, lat, lon, tax, petFee, deposit, customerDataFields } = values;
    const data = { images, type, period, name, description, tax: Number(tax), deposit: Number(deposit) };
    if ( petFee ) data['petFee'] = Number(petFee);
    if ( checkInTime ) data['checkInTime'] = checkInTime;
    if ( checkOutTime ) data['checkOutTime'] = checkOutTime;
    if ( lat ) data['lat'] = lat;
    if ( lon ) data['lon'] = lon;
    if ( customerDataFields && customerDataFields.length !== 0 ) data['customerDataFields'] = customerDataFields;
    if ( booking && booking.id ){
      updateBooking(booking.id, data);
    } else {
      createBooking(data);
    }
  }

  const [values, errors, handleChange, handleSubmit] = useForm(callback, {
    images: booking.images || [],
    type: booking.type || '',
    period: booking.period || '',
    checkInTime: booking.checkInTime || '',
    checkOutTime: booking.checkOutTime || '',
    name: booking.name || '',
    description: booking.description || '',
    lat: booking.lat || '',
    lon: booking.lon || '',
    tax: booking.tax || 0,
    petFee: booking.petFee || 0,
    deposit: booking.deposit || 100,
    customerDataFields: booking.customerDataFields || []
  }, validate);

  useEffect(() => {
    if ( status === 'Success' ) onHide();
    // eslint-disable-next-line
  }, [status]);

  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <form onSubmit={handleSubmit}>
        <Modal.Header>
          {booking.id ? `Update: ${booking.name}` : 'Create booking property'}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-5">
              <BookingFormImages
                value={values.images}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-7">
              {!bookingId ? (
                <div className="form-group">
                  <label htmlFor="type">Type&nbsp;*</label>
                  <select
                    className={`form-control form-control-sm ${ errors.type && 'is-invalid' }`}
                    id="type"
                    name="type"
                    value={values.type}
                    onChange={handleChange}
                  >
                    <option value="">Choose type</option>
                    <option value="hotel">{BOOKING_TYPES.hotel}</option>
                    <option value="rentals">{BOOKING_TYPES.rentals}</option>
                    <option value="rv_sites">{BOOKING_TYPES.rv_sites}</option>
                  </select>
                  {errors.type && <div className="invalid-feedback">{errors.type}</div>}
                </div>
              ) : null}
              <div className="form-group">
                <label htmlFor="period">Period&nbsp;*</label>
                <select
                  className={`form-control form-control-sm ${ errors.period && 'is-invalid' }`}
                  id="period"
                  name="period"
                  value={values.period}
                  disabled={bookingId}
                  onChange={handleChange}
                >
                  <option value="">Choose period</option>
                  <option value="day">{BOOKING_PERIOD.day}</option>
                  <option value="hour">{BOOKING_PERIOD.hour}</option>
                  <option value="season">{BOOKING_PERIOD.season}</option>
                </select>
                {errors.period && <div className="invalid-feedback">{errors.period}</div>}
              </div>
              <div className="row row-8">
                <div className="col-12 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="lat">Check-In Time{values.checkOutTime ? ' *' : ''}</label>
                    <input
                      className={`form-control form-control-sm ${ errors.checkInTime && 'is-invalid' }`}
                      id="checkInTime"
                      type="time"
                      name="checkInTime"
                      value={values.checkInTime}
                      onChange={handleChange}
                    />
                    {errors.checkInTime && <div className="invalid-feedback">{errors.checkInTime}</div>}
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="lat">Check-Out Time{values.checkInTime ? ' *' : ''}</label>
                    <input
                      className={`form-control form-control-sm ${ errors.checkOutTime && 'is-invalid' }`}
                      id="checkOutTime"
                      type="time"
                      name="checkOutTime"
                      value={values.checkOutTime}
                      onChange={handleChange}
                    />
                    {errors.checkOutTime && <div className="invalid-feedback">{errors.checkOutTime}</div>}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="name">Name&nbsp;*</label>
                <input
                  className={`form-control form-control-sm ${ errors.name && 'is-invalid' }`}
                  id="name"
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                />
                {errors.name && <div className="invalid-feedback">{errors.name}</div>}
              </div>
              <div className="form-group">
                <label htmlFor="description">Description&nbsp;*</label>
                <textarea
                  className={`form-control form-control-sm ${ errors.description && 'is-invalid' }`}
                  id="description"
                  name="description"
                  value={values.description}
                  rows="5"
                  onChange={handleChange}
                ></textarea>
                {errors.description && <div className="invalid-feedback">{errors.description}</div>}
              </div>
              {booking.id ? (
                <Taxes bookingPropertyId={booking.id} />
              ) : null}
              <div className="row row-8">
                {/* <div className={`col-12 ${ values.type === 'hotel' ? 'col-md-4' : 'col-md-6' }`}>
                  <div className="form-group">
                    <label htmlFor="tax">Tax&nbsp;*</label>
                    <input
                      className={`form-control form-control-sm ${ errors.tax && 'is-invalid' }`}
                      id="tax"
                      type="number"
                      name="tax"
                      value={values.tax}
                      onChange={handleChange}
                    />
                    {errors.tax && <div className="invalid-feedback">{errors.tax}</div>}
                  </div>
                </div> */}
                {values.type === 'hotel' ? (
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="petFee">Pet fee</label>
                      <div className="input-group input-group-sm">
                        <div className="input-group-prepend">
                          <div className="input-group-text">$</div>
                        </div>
                        <input
                          className={`form-control form-control-sm ${ errors.petFee && 'is-invalid' }`}
                          id="petFee"
                          name="petFee"
                          type="number"
                          value={values.petFee}
                          onChange={handleChange}
                        />
                        {errors.petFee && <div className="invalid-feedback">{errors.petFee}</div>}
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className={`col-12 ${ values.type === 'hotel' ? 'col-md-6' : '' }`}>
                  <div className="form-group">
                    <label htmlFor="deposit">Deposit&nbsp;*</label>
                    <div className="input-group input-group-sm">
                      <input
                        className={`form-control form-control-sm ${ errors.deposit && 'is-invalid' }`}
                        id="deposit"
                        type="number"
                        name="deposit"
                        value={values.deposit}
                        onChange={handleChange}
                      />
                      <div className="input-group-append">
                        <div className="input-group-text">%</div>
                      </div>
                      {errors.deposit && <div className="invalid-feedback">{errors.deposit}</div>}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row row-8">
                <div className="col-12 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="lat">Lat</label>
                    <input
                      className="form-control form-control-sm"
                      id="lat"
                      type="number"
                      name="lat"
                      value={values.lat}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="lat">Lon</label>
                    <input
                      className="form-control form-control-sm"
                      id="lon"
                      type="number"
                      name="lon"
                      value={values.lon}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <BookingFormDynamicFields value={values.customerDataFields} onChange={handleChange} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-secondary btn-sm"
            type="button"
            onClick={onHide}
          >Cancel</button>
          <button
            className="btn btn-primary btn-sm"
            type="submit"
          >Save</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

const mapStateToProps = (state, ownProps) => ({
  booking: BookingSelectors.getBookingById(state.BookingState, ownProps.bookingId),
  status: BookingSelectors.getStatus(state.BookingState)
});

const mapDispatchToProps = dispatch => ({
  createBooking: data => dispatch(BookingActions.create(data)),
  updateBooking: (bookingId, data) => dispatch(BookingActions.update(bookingId, data))
});

BookingFormModal.defaultProps = {
  bookingId: null
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingFormModal);
