import {
  IAccount, IAccountData,
  AccountActionTypes,
  FETCH_LIST, FETCH_LIST_SUCCESS,
  CREATE, CREATE_SUCCESS,
  UPDATE, UPDATE_SUCCESS,
  DELETE, DELETE_SUCCESS,
  FETCH_SETTING_NAMES, FETCH_SETTING_NAMES_SUCCESS,
  SET_STATUS
} from './account.types';
import Statuses from 'types/statuses';

export default class AccountActions {
  // Fetch list
  public static fetchList = ():AccountActionTypes => ({
    type: FETCH_LIST
  });
  public static fetchListSuccess = (items:Array<any>):AccountActionTypes => ({
    type: FETCH_LIST_SUCCESS, payload: { items }
  });
  // Create
  public static create = (data:IAccountData):AccountActionTypes => ({
    type: CREATE, payload: { data }
  });
  public static createSuccess = (account:IAccount):AccountActionTypes => ({
    type: CREATE_SUCCESS, payload: { account }
  });
  // Update
  public static update = (id:number, data:IAccountData):AccountActionTypes => ({
    type: UPDATE, payload: { id, data }
  });
  public static updateSuccess = (account:IAccount):AccountActionTypes => ({
    type: UPDATE_SUCCESS, payload: { account }
  });
  // Delete
  public static delete = (account:IAccount):AccountActionTypes => ({
    type: DELETE, payload: { account }
  });
  public static deleteSuccess = (id:number):AccountActionTypes => ({
    type: DELETE_SUCCESS, payload: { id }
  });
  // Settings names
  public static fetchSettingNames = ():AccountActionTypes => ({
    type: FETCH_SETTING_NAMES
  });
  public static fetchSettingNamesSuccess = (settings:Array<string>):AccountActionTypes => ({
    type: FETCH_SETTING_NAMES_SUCCESS, payload: { settings }
  });
  // Default
  // Set status
  public static setStatus = (status:Statuses) => ({
    type: SET_STATUS, payload: { status }
  })
}
