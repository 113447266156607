import { replace } from 'connected-react-router';
import { takeLatest, call, put } from 'redux-saga/effects';
// Service
import { getToken, setStorageCredential, clearStorageCredential } from '../services/storage.service';
import { $login, $checkIsTokenExpired } from '../services/auth.service';
import UserService from '../services/user.service';
// Types
import * as LayoutActions from 'app/actions/layout.actions';
import * as AuthTypes from 'app/types/auth.types';
import UserTypes from 'app/types/user.types';

import { unsubscribeToken } from '../../firebase';

// import SettingService from 'store/setting/setting.api';
import SettingActions from 'store/setting/setting.actions';

import { store } from '../../index';

// Actions
import {
  setLoginCredential, loginFailure,
  logoutSuccess,
  setIsFetched
} from '../actions/auth.actions';
import UserActions from '../actions/user.actions';

// Login
function* _login(action){
  try {
    const response = yield call($login, action.credential);
    setStorageCredential(response);
    yield put(setLoginCredential(response));
    yield put(UserActions.fetchMe());
  } catch(error){
    console.error(error);
    yield put(loginFailure());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

// Logout
function* _logout(action){
  const { status } = action;
  unsubscribeToken();
  clearStorageCredential();
  // yield call($logout);
  yield put(logoutSuccess());
  yield put(replace({
    pathname: '/',
    search: typeof status !== 'undefined' ? `?status=${status}` : ''
  }));
}

// Fetch me
function* _fetchMe(){
  try {
    const response = yield call(UserService.fetchMe);
    if ( typeof response === 'undefined' ) throw new Error('User with role `camper` not allowed');
    const { role } = response;
    const { pathname } = window.location;
    if ( role !== 'accountManager' ){
      yield put(SettingActions.fetchAccountSettings())
      // const { parseBody } = yield call(SettingService.fetchAccountSettings);
      // yield put(SettingActions.fetchAccountSettingsSuccess(parseBody));
    }
    yield put(UserActions.fetchMeSuccess(response));
    if ( pathname.replace('/', '') === '' || pathname.indexOf('/update-password') !== -1 ){

      const appState = store.getState().app;
      const requestedUrl = appState.requestedUrl;
      if ( requestedUrl ){
        yield put(replace(requestedUrl));
      } else {
        if ( role === 'admin' ) yield put(replace('/admin/users'));
        if ( role === 'orderManager' ) yield put(replace('/manager/products'));
        if ( role === 'accountManager' ) yield put(replace('/am/accounts'));
      }
    }
  } catch(error){
    console.error(error);
    yield put(UserActions.fetchMeFailure());
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

// Update me
// function* _updateMe(action){
//   try {
//     const { user } = action.payload;
//     const response = yield call(UserService.updateMe, user);
//     yield put(UserActions.updateMeSuccess(response));
//     yield put(LayoutActions.addNotification({
//       type: 'success',
//       message: 'Profile was updated'
//     }));
//   } catch(error){
//     console.error(error);
//     yield put(UserActions.updateMeFailure());
//     yield put(LayoutActions.addNotification({
//       type: 'danger',
//       message: error.message
//     }));
//   }
// }

// Check is token expired
function* _checkIsTokenExpired(){
  try {
    const response = yield call($checkIsTokenExpired);
    if ( response ){
      yield put(setLoginCredential({
        accessToken: getToken()
      }));
      yield put(UserActions.fetchMe());
    }
  } catch(error){
    console.log(error);
    if ( error ){
      clearStorageCredential();
      const { pathname } = window.location;
      if ( pathname.replace('/', '') !== '' ){
        yield put(replace('/'))
      }
    }
    yield put(setIsFetched());
  }
}

export function* authSaga(){
  yield takeLatest(AuthTypes.LOGIN, _login);
  yield takeLatest(AuthTypes.LOGOUT, _logout);
  yield takeLatest(UserTypes.FETCH_ME, _fetchMe);
  // yield takeLatest(UserTypes.UPDATE_ME, _updateMe);
  yield takeLatest(AuthTypes.CHECK_IS_TOKEN_EXPIRED, _checkIsTokenExpired)
}