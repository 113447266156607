import React, { useState } from 'react';
// Redux
import { connect } from 'react-redux';
import * as BookingSelectors from 'app/store/booking/booking.selectors';
// Layouts
import WaiverPreviewModal from 'app/layouts/Modals/WaiverPreviewModal';
// Utils
import { parseUTCDate, toCamperZoneDate, toCamperZoneTime } from 'app/utilities/datetime.convert';
// 
import ReservationDetails from './ReservationDetails';

const ReservationListItem = ({
  // Props
  reservation, remotelockIntegrationLocked,
  // State
  booking
}) => {

  const [modalShow, setModalShow] = useState(false);
  const [reservationId, setReservationId] = useState(null);

  const hasReservationId = Boolean(reservationId);

  return (
    <>
      <div className="col-12 col-sm-6 col-md-4 col-lg-3 | d-flex" >
        <div className="card card-reservation">
          <div className="card-body">
            <small className="text-muted">ID: {reservation.reservationNumber}</small>
            <h6 className="mb-0">{reservation.userData.firstName} {reservation.userData.lastName}</h6>
            <div>
              <small className={`text-${reservation.paymentType === 'online' ? 'success' : 'danger'}`}>{reservation.paymentType}</small>
              <small className={`text-${reservation.paid ? 'success' : 'danger'}`}>&nbsp;&bull;&nbsp;{reservation.paid ? 'paid' : 'not paid'}</small>
            </div>
            <div>
              <span className={`badge badge-${classes(reservation.status)}`}>{reservation.status}</span>
            </div>
            <small className="d-block mt-1">
              Created on&nbsp;<b>{parseUTCDate(reservation.createdOn)}</b>
            </small>
            {booking.period !== 'hour' ? (
              <>
                <small className="d-block mt-1">
                  Arrival&nbsp;<b>{toCamperZoneDate(reservation.start)}</b>
                </small>
                <small className="d-block mt-1">
                  Departure&nbsp;<b>{toCamperZoneDate(reservation.end)}</b>
                </small>
              </>
            ) : (
              <small className="d-block mt-1">
                <b>{parseUTCDate(reservation.start)}, {toCamperZoneTime(reservation.start)} - {toCamperZoneTime(reservation.end)}</b>
              </small>
            )}
            {reservation.checkOutDate ? (
              <small className="d-block mt-1">
                Checked-out earlier at&nbsp;
                <b>{booking.period !== 'hour' ? parseUTCDate(reservation.checkOutDate) : toCamperZoneTime(reservation.checkOutDate)}</b>
              </small>
            ) : null}
            {typeof reservation.depositAmount !== 'undefined' ? (
              <React.Fragment>
                <small className="d-block mt-1">
                  Deposit paid&nbsp;<b>${reservation.depositAmount}</b>
                </small>
                <small className="d-block mt-1">
                  Remaining amount&nbsp;<b>${reservation.totalPrice - reservation.depositAmount}</b>
                </small>
              </React.Fragment>
            ) : (
              !reservation.paid ? (
                <small className="d-block mt-1">
                  Remaining amount&nbsp;<b>${reservation.totalPrice}</b>
                </small>
              ) : null
            )}
            <small className="d-block mt-1">
              Total price&nbsp;<b>${reservation.totalPrice}</b>
            </small>
            {reservation.status === 'active' && remotelockIntegrationLocked ? (
              <React.Fragment>
                {reservation.remotelockAccessGuestExternalId ? (
                  <small className="d-block mt-1">
                    Remotelock Access Guest External ID&nbsp;<b>{reservation.remotelockAccessGuestExternalId}</b>
                  </small>
                ) : null}
                {reservation.remotelockAccessGuestPin ? (
                  <small className="d-block mt-1">
                    Remotelock Access Guest Pin&nbsp;<b>{reservation.remotelockAccessGuestPin}</b>
                  </small>
                ) : null}
              </React.Fragment>
            ) : null}
            {reservation.items && reservation.items.length !== 0 ? (
              <>
                {reservation.items.map((item, index) => (
                  <React.Fragment key={`reservation-item-${reservation.id}-${index}-${item.reservationCategoryId}`} >
                    <small className="d-block mt-1"><b>{item.reservationCategoryName}</b></small>
                    {item.customerData && item.customerData.length !== 0 ? (
                      <>
                        {item.customerData.map((data, i) => (
                          <small key={`reservation-item-${reservation.id}-${index}-${item.reservationCategoryId}-data-${i}`} className="d-block mt-1">
                            {data.name}&nbsp;&bull;&nbsp;{data.value}
                          </small>
                        ))}
                      </>
                    ) : null}
                  </React.Fragment>
                ))}
              </>
            ) : null}
          </div>
          <div className="card-foot">
            {reservation.waiverId &&
              <button
                className="btn btn-link btn-sm mr-1"
                type="button"
                onClick={() => setModalShow(true)}
              >Waiver Accepted</button>
            }
            <button
              className="btn btn-outline-secondary btn-sm"
              type="button"
              onClick={() => setReservationId(reservation.id)}
            >More</button>
          </div>
        </div>
      </div>
      {/* Details */}
      {hasReservationId &&
        <ReservationDetails
          reservationId={reservationId}
          onClose={() => setReservationId(null)}
        />
      }
      {modalShow &&
        <WaiverPreviewModal
          waiverId={reservation.waiverId}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      }
    </>
  )
}

const classes = status => {
  return  status === 'upcoming'
    ? 'warning'
    : status === 'active'
      ? 'success'
      : status === 'completed'
        ? 'info'
        : 'danger'
  ;
}

const mapStateToProps = ({ BookingState }) => ({
  booking: BookingSelectors.getBooking(BookingState)
});

export default connect(mapStateToProps)(ReservationListItem);
