import fetcher from '../../utilities/fetcher';

const apiURL = '/admin/financialReports';

export const FinancialReportService = {
  // Downlaod orders
  downloadOrders: (start, end) => {
    const params = new URLSearchParams();
    if ( start ) params.append('start', start);
    if ( end ) params.append('end', end);
    return fetcher(`${apiURL}/orders/export?${params}`, {
      method: 'GET'
    }, response => response.blob().then(blob => {
      const disposition = response.headers.get('Content-disposition').split('=');
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.download = disposition.length > 0 ? disposition[1] : 'unknown.csv';
      a.href = url;
      a.target = '_blank';

      document.body.appendChild(a);

      a.click();
      a.remove();
    }));
  },
  // Download reservations
  downloadReservations: (start, end) => {
    const params = new URLSearchParams();
    if ( start ) params.append('start', start);
    if ( end ) params.append('end', end);
    return fetcher(`${apiURL}/reservations/export?${params}`, {
      method: 'GET'
    }, response => response.blob().then(blob => {
      const disposition = response.headers.get('Content-disposition').split('=');
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.download = disposition.length > 0 ? disposition[1] : 'unknown.csv';
      a.href = url;
      a.target = '_blank';

      document.body.appendChild(a);

      a.click();
      a.remove();
    }));
  },
  // Download memberships
  downloadMemberships: (start, end) => {
    const params = new URLSearchParams();
    if ( start ) params.append('start', start);
    if ( end ) params.append('end', end);
    return fetcher(`${apiURL}/memberships/export?${params}`, {
      method: 'GET'
    }, response => response.blob().then(blob => {
      const disposition = response.headers.get('Content-disposition').split('=');
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.download = disposition.length > 0 ? disposition[1] : 'unknown.csv';
      a.href = url;
      a.target = '_blank';

      document.body.appendChild(a);

      a.click();
      a.remove();
    }));
  },
  // Fetch list summary
  fetchListSummary: (start, end) => {
    const params = new URLSearchParams();
    if ( start ) params.append('start', start);
    if ( end ) params.append('end', end);
    return fetcher(`${apiURL}/summary?${params}`, {
      method: 'GET'
    });
  }
}
