import React from 'react';
// Redux
import { connect } from 'react-redux';
// Types
import { Dispatch } from 'redux';
import Statuses from 'types/statuses';
import { ISeason } from 'store/season/season.types';
// Actions
import SeasonActions from 'store/season/season.actions';
// Selectors
import { getIsFetching, getIsLoading, getStatus, getSeasonsWithColors, getSeasonsSlotsForCalendar } from 'store/season/season.selectors';
// 
import SeasonsCreate from './SeasonsCreate';
import SeasonsList from './SeasonsList';
import SeasonsCalendar from './SeasonsCalendar';

type Props = {
  // State
  seasons: Array<ISeason> | null;
  slots: any;
  isFetching: boolean;
  isLoading: boolean;
  status: Statuses;
  // Dispatch
  fetchSeasonsList: () => void;
  deleteSeason: (seasonId:number) => void;
}

const SeasonsPage:React.FC<Props> = ({
  // State
  seasons, slots, isFetching, isLoading, status,
  // Dispatch
  fetchSeasonsList, deleteSeason
}) => {

  React.useEffect(() => {
    fetchSeasonsList();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="container-fluid pt-3 pb-3">
      <SeasonsCreate />
      <SeasonsList
        items={seasons}
        fetching={isFetching}
        loading={isLoading}
        status={status}
        onDelete={deleteSeason}
      />
      <SeasonsCalendar
        items={slots}
        fetching={isFetching}
      />
    </div>
  )
}

const mapStateToProps = (state:any) => ({
  seasons: getSeasonsWithColors(state),
  slots: getSeasonsSlotsForCalendar(state),
  isFetching: getIsFetching(state),
  isLoading: getIsLoading(state),
  status: getStatus(state),
});

const mapDispatchToProps = (dispatch:Dispatch) => ({
  fetchSeasonsList: () => dispatch(SeasonActions.fetchSeasonsList()),
  deleteSeason: (seasonId:number) => dispatch(SeasonActions.deleteSeason(seasonId))
});

export default connect(mapStateToProps, mapDispatchToProps)(SeasonsPage);
