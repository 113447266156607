import React from 'react';
// Component
import { DataMessage } from '../../../components/DataMessage';

const TemplatesList = ({templates, onSelectTemplate, onDeleteTemplate}) => {
  return (
    <DataMessage data={templates} type="template">
      <ul className="list-group">
        {templates.map((tmp, index) => (
          <li
            key={index}
            className="list-group-item | d-flex justify-content-between align-items-center"
          >
            <div>
              <h5 className="mb-0">{tmp.name}</h5>
            </div>
            <div>
              <button
                className="btn btn-primary btn-sm btn-block"
                type="button"
                onClick={onSelectTemplate(tmp)}
              >Edit</button>
              <button
                className="btn btn-outline-danger btn-sm btn-block"
                type="button"
                onClick={onDeleteTemplate(tmp.id)}
              >Delete</button>
            </div>
          </li>
        ))}
      </ul>
    </DataMessage>
  )
}

export default TemplatesList;