import { call, put, takeLatest } from 'redux-saga/effects';
// Store
import { FinancialReportActionTypes } from './financial-report.types';
import { FinancialReportActions } from './financial-report.actions';
import { FinancialReportService } from './financial-report.service';

function* _downloadOrders(action){
  try {
    const { start, end } = action;
    yield call(FinancialReportService.downloadOrders, start, end);
    yield put(FinancialReportActions.downloadOrdersSuccess());
  } catch(error){
    console.error(error);
    yield put(FinancialReportActions.setStatusError());
  }
}

function* _downloadReservations(action){
  try {
    const { start, end } = action;
    yield call(FinancialReportService.downloadReservations, start, end);
    yield put(FinancialReportActions.downloadReservationsSuccess());
  } catch(error){
    console.error(error);
    yield put(FinancialReportActions.setStatusError());
  }
}

function* _downloadMemberships(action){
  try {
    const { start, end } = action;
    yield call(FinancialReportService.downloadMemberships, start, end);
    yield put(FinancialReportActions.downloadMembershipsSuccess());
  } catch(error){
    console.error(error);
    yield put(FinancialReportActions.setStatusError());
  }
}

function* _fetchListSummary(action){
  try {
    const { start, end } = action;
    const response = yield call(FinancialReportService.fetchListSummary, start, end);
    yield put(FinancialReportActions.fetchListSummarySuccess(response));
  } catch(error){
    console.error(error);
    yield put(FinancialReportActions.setStatusError());
  }
}

export default function* (){
  yield takeLatest(FinancialReportActionTypes.DownloadOrders, _downloadOrders);
  yield takeLatest(FinancialReportActionTypes.DownloadReservations, _downloadReservations);
  yield takeLatest(FinancialReportActionTypes.DownloadMemberships, _downloadMemberships);
  yield takeLatest(FinancialReportActionTypes.FetchListSummary, _fetchListSummary);
}
