import React from 'react';
import { NavLink } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
import { logout } from '../../actions/auth.actions';
// Selectors
import { getMembershipModuleEnabled, getRemotelockIntegrationLocked } from 'store/setting/setting.selectors';

const DrawerMiddle = props => {
  const {
    // Sttae
    user, membershipModuleEnabled, remotelockIntegrationLocked,
    // Dispatch
    onLogout
  } = props;

  const path = user.role === 'admin' ? 'admin' : 'manager';

  const adminRoutes = [
    { to: '/admin/users', text: 'Users', icon: 'fa-users' },
    { to: '/admin/templates', text: 'Schedule templates', icon: 'fa-user-clock' },
    { to: '/admin/system-notifications', text: 'System notifications', icon: 'fa-bell' },
    { to: '/admin/schedule', text: 'Schedule', icon: 'fa-calendar-alt' },
    { to: '/admin/content-categories', text: 'Content categories', icon: 'fa-book' },
    { to: '/admin/content-items', text: 'Content items', icon: 'fa-file-image' },
    { to: '/admin/notifications', text: 'Messages', icon: 'fa-paper-plane' },
    { isDivider: true },
    { to: '/admin/financial-report', text: 'Financial report', icon: 'fa-chart-pie' },
    { isDivider: true },
    { to: '/admin/maps', text: 'Maps', icon: 'fa-map' }
  ];

  const accountManagerRoutes = [
    { to: '/am/accounts', text: 'Accounts', icon: 'fa-users' }
  ];

  const routes = [
    { isVisible: true, to: `/${path}/contact-messages`, text: 'Help desk', icon: 'fa-envelope-open-text' },
    { isDivider: true },
    { isVisible: user.role === 'admin', to: `/${path}/staff`, text: 'Staff', icon: 'fa-users-cog' },
    { isDivider: true },
    { isVisible: true, to: `/${path}/inventory-groups`, text: 'Inventory groups', icon: 'fa-object-ungroup' },
    { isVisible: true, to: `/${path}/products`, text: 'Products', icon: 'fa-shopping-cart' },
    { isVisible: true, to: `/${path}/product-categories`, text: 'Product categories', icon: 'fa-dolly' },
    { isVisible: true, to: `/${path}/orders`, text: 'Orders', icon: 'fa-cash-register' },
    { isDivider: true },
    { isVisible: remotelockIntegrationLocked, to: `/${path}/remotelock-devices`, text: 'Remotelock devices', icon: 'fa-network-wired' },
    { isVisible: true, to: `/${path}/booking-properties`, text: 'Booking properties', icon: 'fa-hotel' },
    { isVisible: true, to: `/${path}/seasons`, text: 'Seasons', icon: 'fa-skiing' },
    { isDivider: membershipModuleEnabled },
    { isVisible: membershipModuleEnabled, to: `/${path}/membership-passes`, text: 'Membership passes', icon: 'fa-user-friends' },
    { isVisible: membershipModuleEnabled, to: `/${path}/membership-types`, text: 'Membership types', icon: 'fa-user-friends' }
  ];

  return (
    <div className="root-drawer-middle">
      {user.role === 'admin' &&
        <>
          <ul className="nav nav-drawer flex-column">
            {adminRoutes.map((route, index) =>  {
              if ( typeof route.isDivider !== 'undefined' && route.isDivider ) return <div key={index} className="dropdown-divider" />;
              return (
                <NavLink key={index} className="nav-link" activeClassName="is-active" to={route.to}>
                  <span className="nav-link-icon">
                    <i className={`fa ${route.icon}`} aria-hidden="true"></i>
                  </span>
                  <span className="nav-link-text">{route.text}</span>
                </NavLink>
              )
            })}
          </ul>
          <hr />
        </>
      }
      {(user.role === 'admin' || user.role === 'orderManager') ? (
        <ul className="nav nav-drawer flex-column">
          {routes.map((route, index) => {
            if ( typeof route.isDivider !== 'undefined' && route.isDivider && user.role === 'admin' ) return <div key={index} className="dropdown-divider" />;
            if ( route.isVisible ){
              return (
                <NavLink key={index} className="nav-link" activeClassName="is-active" to={route.to}>
                  <span className="nav-link-icon">
                    <i className={`fa ${route.icon}`} aria-hidden="true"></i>
                  </span>
                  <span className="nav-link-text">{route.text}</span>
                </NavLink>
              )
            }
            return null;
          })}
        </ul>
      ) : null}
      {user.role === 'accountManager' && (
        <ul className="nav nav-drawer flex-column">
          {accountManagerRoutes.map((route, index) => {
            return (
              <NavLink key={index} className="nav-link" activeClassName="is-active" to={route.to}>
                <span className="nav-link-icon">
                  <i className={`fa ${route.icon}`} aria-hidden="true"></i>
                </span>
                <span className="nav-link-text">{route.text}</span>
              </NavLink>
            )
          })}
        </ul>
      )}
      <hr />
      <ul className="nav nav-drawer flex-column">
        {user.role !== 'accountManager' ? (
          <li className="nav-item">
            <NavLink className="nav-link" activeClassName="is-active" to="/profile">
              <span className="nav-link-icon">
                <i className="fa fa-user" aria-hidden="true"></i>
              </span>
              <span className="nav-link-text">Profile</span>
            </NavLink>
          </li>
        ) : null}
        <li className="nav-item">
          <button
            className="nav-link"
            onClick={() => onLogout()}
          >
            <span className="nav-link-icon">
              <i className="fa fa-sign-out-alt" aria-hidden="true"></i>
            </span>
            <span className="nav-link-text">Logout</span>
          </button>
        </li>
      </ul>
    </div>
  )
}

const mapStateToProps = state => ({
  user: state.auth.user,
  membershipModuleEnabled :getMembershipModuleEnabled(state),
  remotelockIntegrationLocked: getRemotelockIntegrationLocked(state)
});

const mapDispatchToProps = dispatch => ({
  onLogout: () => dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(DrawerMiddle);
