import { Reducer } from 'redux';
import {
  IMapPoiState,
  MapPoiActionTypes,
  SET_LIST,
  CREATE, CREATE_SUCCESS,
  UPDATE, UPDATE_SUCCESS,
  DELETE, DELETE_SUCCESS,
  SET_STATUS
} from './map-poi.types';
import Statuses from 'types/statuses';

export default class MapPoiReducer {
  private static readonly _initialState:IMapPoiState = {
    items: null,
    isFetching: false,
    isLoading: false,
    status: Statuses.Initial
  }

  public static reducer:Reducer<IMapPoiState, MapPoiActionTypes> = (
    state = MapPoiReducer._initialState,
    action
  ) => {
    switch (action.type){
      case SET_LIST:
        return {...state, items: action.payload.items};
      // Create
      case CREATE:
        return {...state, isLoading: true};
      case CREATE_SUCCESS:
        return {
          ...state,
          items: state.items ? [...state.items, action.payload.item] : state.items,
          isLoading: false
        };
      // Update
      case UPDATE:
        return {...state, isLoading: true};
      case UPDATE_SUCCESS:
        return {
          ...state,
          items: state.items
            ? state.items.map(
                item =>
                  item.id === action.payload.item.id
                  ? action.payload.item
                  : item
              )
            : state.items,
          isLoading: false
        };
      // Delete
      case DELETE:
        return {...state, isLoading: true};
      case DELETE_SUCCESS:
        return {
          ...state,
          items: state.items
            ? state.items.filter(item => item.id !== action.payload.id)
            : state.items,
          isLoading: false
        };
      // Default
      case SET_STATUS:
        const hasError:boolean = action.payload.status === Statuses.Error;
        return {
          ...state,
          isFetching: hasError ? false : state.isFetching,
          isLoading: hasError ? false : state.isLoading,
          status: action.payload.status
        };
      default:
        return state;
    }
  }
}
