export const AvailabilityActionTypes = {
  FetchList: '[Availability] Fetch list',
  FetchListSuccess: '[Availability] Fetch list success',
  // Create
  Create: '[Availability] Create',
  CreateSuccess: '[Availability] Create success',
  // Update
  Update: '[Availability] Update',
  UpdateSuccess: '[Availability] Update success',
  // Delete
  Delete: '[Availability] Delete',
  DeleteSuccess: '[Availability] Delete success',
  // Status
  StatusSuccess: '[Availability] Status success',
  StatusError: '[Availability] Status error',
  StatusInitial: '[Availability] Status initial'
}

export const AvailabilityMessageTypes = {
  Create: 'Booking property availability restriction created',
  Update: 'Booking property availability restriction updated',
  Delete: 'Booking property availability restriction deleted'
}
