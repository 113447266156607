import { Reducer } from 'redux';
// Types
import Statuses from 'types/statuses';
import { MembershipTypes, MembershipActionTypes } from './membership.types';
// Models
import { IState } from './membership.models';
import { IMembership } from 'models/Membership';

export default class MembershipReducer {
  private static readonly _initialState:IState = {
    memberships: {
      items: null,
      total: 0,
      params: {
        limit: 10,
        offset: 0,
        statuses: '',
        username: ''
      }
    },
    membership: null,
    transactions: null,
    visits: {
      items: null,
      total: 0,
      params: {
        limit: 10,
        offset: 0
      }
    },
    isLoading: false,
    status: Statuses.Initial
  };

  public static reducer:Reducer<IState, MembershipActionTypes> = (
    state = MembershipReducer._initialState,
    action
  ) => {
    switch (action.type){
      // Fetch memberships
      case MembershipTypes.FetchMemberships:
        return { ...state, memberships: {
          ...state.memberships,
          items: null
        }};
      case MembershipTypes.FetchMembershipsSuccess:
        return { ...state, memberships: {
          ...state.memberships,
          items: action.payload.memberships,
          total: action.payload.total
        }};
      // Fetch membership by number
      case MembershipTypes.FetchMembershipByNumber:
        return { ...state, membership: null };
      case MembershipTypes.FetchMembershipByNumberSuccess:
        return { ...state, membership: action.payload.membership };
      // Fetch transactions
      case MembershipTypes.FetchMembershipTransactions:
        return { ...state, transactions: null };
      case MembershipTypes.FetchMembershipTransactionsSuccess:
        return { ...state, transactions: action.payload.transactions };
      // Send membership receipt
      case MembershipTypes.SendMembershipReceipt:
        return { ...state, isLoading: true };
      case MembershipTypes.SendMembershipReceiptSuccess:
        return { ...state, isLoading: false };
      // Inactivate membership
      case MembershipTypes.InactivateMembership:
        return { ...state, isLoading: true };
      case MembershipTypes.InactivateMembershipSuccess:
        return {
          ...state,
          memberships: {
            ...state.memberships,
            items: state.memberships.items
              ? state.memberships.items.map((membership:IMembership) => {
                  if ( membership.id === action.payload.membership.id ) return action.payload.membership;
                  return membership;
                })
              : state.memberships.items
          },
          isLoading: false
        };
      // Fetch membership visits
      case MembershipTypes.FetchMembershipVisits:
        return { ...state, visits: {
          ...state.visits,
          items: null
        }};
      case MembershipTypes.FetchMembershipVisitsSuccess:
        return { ...state, visits: {
          ...state.visits,
          items: action.payload.visits,
          total: action.payload.total
        }};
      // Check in membership
      case MembershipTypes.CheckInMembership:
        return { ...state, isLoading: true };
      case MembershipTypes.CheckInMembershipSuccess:
        return { ...state, isLoading: false };
      // Default
      // Set status
      case MembershipTypes.SetStatus:
        const hasError:boolean = action.payload.status === Statuses.Error;
        return {
          ...state,
          isLoading: hasError ? false : state.isLoading,
          status: action.payload.status
        };
      default:
        return state;
    }
  }
}
