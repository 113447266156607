
import { AppActionTypes } from './app.types';

const initialState = {
  pushNotifications: [],
  status: 'Initial'
};

export default function(state = initialState, action){
  switch (action.type){
    case AppActionTypes.AddPushNotification:
      return {
        ...state,
        pushNotifications: [...state.pushNotifications, action.pushNotification]
      };
    case AppActionTypes.RemovePushNotifcation:
      return {
        ...state,
        pushNotifications: state.pushNotifications.filter(n => n.id !== action.pushNotificationId)
      }

    case AppActionTypes.FetchAdminData:
    case AppActionTypes.FetchManagerData:
    case AppActionTypes.FetchAccountManagerData:
      return { ...state, status: 'Fetching' };
    case AppActionTypes.FetchAdminDataSuccess:
    case AppActionTypes.FetchManagerDataSuccess:
    case AppActionTypes.FetchAccountManagerDataSuccess:
      return { ...state, status: 'Fetched' };
    // Set status
    case AppActionTypes.StatusSuccess:
      return { ...state, status: 'Success' };
    case AppActionTypes.StatusError:
      return { ...state, status: 'Error' };
    case AppActionTypes.StatusInitial:
      return { ...state, status: 'Initial' };
    // Default
    default:
      return state;
  }
}

