import Types from '../types/order.types';

const initialParams = {
  statuses: '',
  offset: 0,
  limit: 20,
  orderNumber: '',
  start: '',
  end: '',
  inventoryGroupId: ''
}

const initialState = {
  orders: [],
  orderTotal: null,

  transactions: [],
  transactionsFetching: false,

  order: {},

  params: initialParams,

  cancelIds: [],

  isLoading: false,
  isFetching: true,
  status: 'Initial',

  hasMore: true,
  hasMoreLoading: false
};

export default function(state = initialState, { type, payload }){
  switch (type){
    // Request
    case Types.FETCH_TRANSACTIONS_LIST:
      return {...state, transactionsFetching: true };
    case Types.FETCH_TRANSACTIONS_LIST_SUCCESS:
      return {...state, transactions: payload.transactions, transactionsFetching: false };
    case Types.FETCH_TRANSACTIONS_LIST_FAILURE:
      return {...state, transactionsFetching: false, status: 'Error' };
    case Types.FETCH_ALL:
      return _fetchAll(state, payload);
    case Types.FETCH_BY_ID:
      return { ...state, order: {}, isFetching: true };
    case Types.CANCEL:
      return { ...state, isLoading: true };
    case Types.SEND_RECEIPT:
      return { ...state, isLoading: true };
    // Success
    case Types.FETCH_ALL_SUCCESS:
      return _fetchAllSuccess(state, payload);
    case Types.FETCH_BY_ID_SUCCESS:
      return { ...state, order: payload.order, isFetching: false };
    case Types.SET_STATUS_SUCCESS:
      return _setStatusSuccess(state, payload.order);
    case Types.SEND_RECEIPT_SUCCESS:
      return { ...state, isLoading: false };
    case Types.CANCEL_SUCCESS:
      return _cancelSuccess(state, payload.orderId);
    // Failure
    case Types.FETCH_ALL_FAILURE:
      return { ...state, orders: [], isFetching: false, hasMore: false, hasMoreLoading: false };
    case Types.FETCH_BY_ID_FAILURE:
      return { ...state, order: {}, isFetching: false };
    // case Types.SEND_RECEIPT_SUCCESS:
    //   return { ...state, isLoading: false };
    case Types.CANCEL_FAILURE:
      return { ...state, isLoading: false };

    case Types.CHECK_IN:
      return state;
    case Types.CHECK_IN_SUCCESS:
      return {
        ...state,
        orders: state.orders.map(o => o.id === payload.id ? {
          ...o,
          orderActivityItems: o.orderActivityItems.map(item => item.id === payload.activity.id ? {
            ...item,
            status: payload.activity.status
          } : item)
        } : o)
      }
    // Other
    case Types.SET_INITIAL_STATE:
      return initialState;
    case Types.SET_STATE_STATUS:
      return { ...state, status: payload.status };
    case Types.SET_IS_FETCHED:
      return { ...state, isFetching: false };
    default:
      return state;
  }
}

// Fetch all
const _fetchAll = (state, { params, isMore }) => {
  const isMoreExist = (typeof isMore !== 'undefined' && isMore);
  return {
    ...state,
    orders: isMoreExist ? state.orders : [],
    params,
    isFetching: isMoreExist ? false : true,
    hasMore: true,
    hasMoreLoading: isMoreExist ? true : false
  };
}
// Fetch all success
const _fetchAllSuccess = (state, { orders: { data = [] }, params, isMore }) => {
  return {
    ...state,
    orders: isMore ? [...state.orders, ...data] : data,
    params,
    isFetching: false,
    hasMore:
      (data.length > 0 && data.length >= state.params.limit)
      ? true : false,
    hasMoreLoading: false
  }
}

// Set status success
const _setStatusSuccess = (state, order) => {
  const { params: { status } } = state;
  return {
    ...state,
    orders: 
      !status
      ? state.orders.map(o => o.id === order.id ? {...o, ...order} : o)
      : state.orders.filter(o => o.id !== order.id)
  };
}

// Cancel success
const _cancelSuccess = (state, orderId) => {
  const { orders, params: { status } } = state;
  return {
    ...state,
    // cancelIds: state.cancelIds.filter(i => i !== orderId),
    orders:
      !status
      ? orders.map(o => o.id === orderId ? {...o, status: 'cancelled'} : o)
      : orders.filter(o => o.id !== orderId),
    isLoading: false
  };
}
