export const AppActionTypes = {
  AddPushNotification: '[App] Add push notification',
  RemovePushNotifcation: '[App] Remove push notification',

  FetchAdminData: '[App] Fetch admin data',
  FetchAdminDataSuccess: '[App] Fetch admin data success',

  FetchManagerData: '[App] Fetch manager data',
  FetchManagerDataSuccess: '[App] Fetch manager data success',

  FetchAccountManagerData: '[App] Fetch account manager data',
  FetchAccountManagerDataSuccess: '[App] Fetch account manager data success',

  // Status
  StatusSuccess: '[App] Status success',
  StatusError: '[App] Status error',
  StatusInitial: '[App] Status initial'
}

export const AppMessageTypes = {

}
