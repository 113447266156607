import {
  FETCH_LIST, FETCH_LIST_SUCCESS,
  CREATE, CREATE_SUCCESS,
  UPDATE, UPDATE_SUCCESS,
  DELETE, DELETE_SUCCESS,
  ENABLE, ENABLE_SUCCESS,
  DISABLE, DISABLE_SUCCESS,
  SET_STATUS
} from './content-category.actions';

const initialState = {
  items: [],
  isFetching: false,
  isLoading: false,
  status: 'Initial'
};

export default (state = initialState, { type, payload }) => {
  switch (type){
    case FETCH_LIST:
      return { ...state, isFetching: true };
    case FETCH_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: payload.items
      };

    case CREATE:
      return { ...state, isLoading: true };
    case CREATE_SUCCESS:
      return {
        ...state,
        items: [...state.items, payload.item],
        isLoading: false
      };

    case UPDATE:
      return { ...state, isLoading: true };
    case UPDATE_SUCCESS:
      return {
        ...state,
        items: state.items.map(
          item => item.id === payload.item.id
            ? payload.item
            : item
        ),
        isLoading: false
      };

    case DELETE:
      return state;
    case DELETE_SUCCESS:
      return {
        ...state,
        items: state.items.filter(item => item.id !== payload.id)
      };

    case ENABLE:
      return state;
    case ENABLE_SUCCESS:
      return {
        ...state,
        items: state.items.map(item => item.id === payload.id ? {...item, enabled: true} : item)
      };

    case DISABLE:
      return state;
    case DISABLE_SUCCESS:
      return {
        ...state,
        items: state.items.map(item => item.id === payload.id ? {...item, enabled: false} : item)
      };

    case SET_STATUS:
      return { ...state, status: payload.status };
    default:
      return state;
  }
}
