import { Action } from 'redux';
// Types
import Statuses from 'types/statuses';
// Models
import { ITax } from 'models/Tax';

// Messages
export enum TaxesMessages {
  Create = 'Tax was created',
  Update = 'Tax was updated',
  Delete = 'Tax was deleted'
}

// Types
export enum TaxesTypes {
  FetchTaxes = '@@taxes/Fetch taxes',
  FetchTaxesSuccess = '@@taxes/Fetch taxes success',

  FetchTax = '@@taxes/Fetch tax',
  FetchTaxSuccess = '@@taxes/Fetch tax success',

  CreateTax = '@@taxes/Create tax',
  CreateTaxSuccess = '@@taxes/Create tax success',

  UpdateTax = '@@taxes/Update tax',
  UpdateTaxSuccess = '@@taxes/Update tax success',

  DeleteTax = '@@taxes/Delete tax',
  DeleteTaxSuccess = '@@taxes/Delete tax success',

  SetStatus = '@@taxes/Set status'
}

interface FetchTaxes extends Action<typeof TaxesTypes.FetchTaxes> {
  payload: { params:any }
}
interface FetchTaxesSuccess extends Action<typeof TaxesTypes.FetchTaxesSuccess> {
  payload: { taxes:Array<ITax> }
}

interface FetchTax extends Action<typeof TaxesTypes.FetchTax> {
  payload: { taxId:number }
}
interface FetchTaxSuccess extends Action<typeof TaxesTypes.FetchTaxSuccess> {
  payload: { tax:ITax }
}

interface CreateTax extends Action<typeof TaxesTypes.CreateTax> {
  payload: { data:any }
}
interface CreateTaxSuccess extends Action<typeof TaxesTypes.CreateTaxSuccess> {
  payload: { tax:ITax }
}

interface UpdateTax extends Action<typeof TaxesTypes.UpdateTax> {
  payload: { taxId:number, data:any }
}
interface UpdateTaxSuccess extends Action<typeof TaxesTypes.UpdateTaxSuccess> {
  payload: { tax:ITax }
}

interface DeleteTax extends Action<typeof TaxesTypes.DeleteTax> {
  payload: { taxId:number }
}
interface DeleteTaxSuccess extends Action<typeof TaxesTypes.DeleteTaxSuccess> {
  payload: { taxId:number }
}

interface SetStatus extends Action<typeof TaxesTypes.SetStatus> {
  payload: { status:Statuses }
}

export type TaxesActionTypes =
  FetchTaxes | FetchTaxesSuccess |
  FetchTax | FetchTaxSuccess |
  CreateTax | CreateTaxSuccess |
  UpdateTax | UpdateTaxSuccess |
  DeleteTax | DeleteTaxSuccess |
  SetStatus
;
