import fetcher from '../../utilities/fetcher';

const apiURL = '/admin/waivers';
const headers = {
  'Content-Type': 'application/json'
};

export const WaiverService = {
  // Fetch list campers
  fetchList: bookingId => {
    const params = new URLSearchParams();
    params.append('bookingPropertyId', bookingId);
    return fetcher(`${apiURL}?${params}`);
  },
  // Fetch by id
  fetchById: waiverId => {
    return fetcher(`${apiURL}/${waiverId}`);
  },
  // Create
  create: data => {
    return fetcher(`${apiURL}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(data)
    });
  },
  // Update
  update: (waiverId, data) => {
    return fetcher(`${apiURL}/${waiverId}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(data)
    });
  },
  // Delete
  delete: waiverId => {
    return fetcher(`${apiURL}/${waiverId}`, {
      method: 'DELETE'
    }, () => null);
  }
}
