import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
// Store
import { connect } from 'react-redux';
import UserActions from 'app/actions/user.actions';
import NotificationActions from 'app/actions/notification.actions';
// React bootstrap
import { Modal } from 'react-bootstrap';
// Components
import { DataLoading } from 'app/components/Loadings';
import { ButtonLoading } from 'app/components/Buttons';
// Hooks
import useForm from 'app/hooks/useForm';
// Utilities
import { currentDate } from 'app/utilities/datetime.convert';
import CustomEditor from 'app/components/CustomEditor';

const validation = values => {
  const errors = {};
  const pattern = /^\d{4}-\d{2}-\d{2}$/;
  if ( values.visitingFrom && !pattern.test(values.visitingFrom) ){
    errors['visitingFrom'] = 'Date is not valid';
  }
  if ( values.visitingTo && !pattern.test(values.visitingTo) ){
    errors['visitingTo'] = 'Date is not valid';
  }
  if ( values.visitingFrom && values.visitingTo ){
    if (
      new Date(values.visitingTo).getTime() - new Date(values.visitingFrom).getTime() < 0
    ){
      errors['visitingTo'] = 'To date can`t be less than from date';
    }
  }
  if ( values.isSMSVisible ){
    if ( !values.smsText ) errors['smsText'] = 'This field is required';
  }
  if ( values.isEmailVisible ){
    if ( !values.emailSubject ) errors['emailSubject'] = 'This field is required';
    if ( !values.emailBody ) errors['emailBody'] = 'This field is required';
  }
  if ( values.isInAppVisible ){
    if ( !values.inAppText ) errors['inAppText'] = 'This field is required';
  }
  return errors;
}

const UsersNotificationModal = ({
  // Props
  bulk, isSMS, isEmail, isInApp, show, onHide,
  // State
  checkedUserIds, templatesVariables, isModalFetching, isFormLoading, isFormSuccess,
  // Dispatch
  sendNotification, sendBulkNotification, uncheckAll
}) => {
  const [variable, setVariable] = useState(null);

  const callback = () => {
    const {
      visitingFrom, visitingTo,
      isSMSVisible, smsText,
      isEmailVisible, emailSubject, emailBody,
      isInAppVisible, inAppText
    } = values;
    const data = {};
    if ( isSMSVisible ){
      data['sms'] = {};
      data['sms']['text'] = smsText;
    }
    if ( isEmailVisible ){
      data['email'] = {};
      data['email']['subject'] = emailSubject;
      data['email']['body'] = emailBody;
    }
    if ( isInAppVisible ){
      data['inApp'] = {};
      data['inApp']['text'] = inAppText;
    }
    if ( bulk ){
      if ( visitingFrom ) data['visitingFrom'] = visitingFrom;
      if ( visitingTo ) data['visitingTo'] = visitingTo;
      sendBulkNotification(data);
    } else {
      data['recipientIds'] = checkedUserIds;
      sendNotification(data);
    }
  }

  const [values, errors, handleChange, handleSubmit] = useForm(callback, {
    visitingFrom: currentDate,
    visitingTo: currentDate,

    isSMSVisible: isSMS,
    smsText: '',
    isEmailVisible: isEmail,
    emailSubject: '',
    emailBody: '',
    isInAppVisible: isInApp,
    inAppText: ''
  }, validation);

  useEffect(() => {
    if ( isFormSuccess ) onHide();
    // eslint-disable-next-line
  }, [isFormSuccess]);

  useEffect(() => {
    return () => uncheckAll();
    // eslint-disable-next-line
  }, []);

  const handleFocus = ({ target: { name, value } }) => {
    if ( variable ){
      handleChange({ target: { name, value: value + variable } });
      setVariable(null);
    }
  }

  const handleFocusEditor = (_, editor) => {
    setTimeout(() => {
      editor.model.change((writer) => {
        if ( !variable ) return;
        const position = editor.model.document.selection.getFirstPosition();
        writer.insertText(variable, position, 'after');
        this.handleClearVariable();
      });
    }, 100);
  }

  return (
    <Modal size="lg" show={show} onHide={onHide} enforceFocus={false}>
      <form noValidate onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>One time {bulk ? 'bulk' : ''} notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isModalFetching && <DataLoading />}
          {!isModalFetching && 
            <div className="row row-8">
              <div className="col-12 col-sm-8">
                {bulk &&
                  <>
                    <div className="row row-8">
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label htmlFor="visitingFrom">Visiting From</label>
                          <input
                            className={`form-control form-control-sm ${ errors.visitingFrom ? 'is-invalid' : '' }`}
                            name="visitingFrom" id="visitingFrom"
                            type="date"
                            value={values.visitingFrom}
                            onChange={handleChange}
                          />
                          {errors.visitingFrom && <div className="invalid-feedback">{errors.visitingFrom}</div>}
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label htmlFor="visitingTo">Visiting To</label>
                          <input
                            className={`form-control form-control-sm ${ errors.visitingTo ? 'is-invalid' : '' }`}
                            name="visitingTo" id="visitingTo"
                            type="date"
                            value={values.visitingTo}
                            onChange={handleChange}
                          />
                          {errors.visitingTo && <div className="invalid-feedback">{errors.visitingTo}</div>}
                        </div>
                      </div>
                    </div>
                    <hr/>
                  </>
                }
                <div className="form-group">
                  <small
                    className={
                      (!values.isEmailVisible && !values.isSMSVisible && !values.isInAppVisible)
                      ? 'text-danger'
                      : 'text-muted'
                    }
                  >(At least one option should be selected)</small>
                </div>
                <div className="form-group form-check">
                  <input
                    className="form-check-input" name="isSMSVisible" id="isSMSVisible"
                    type="checkbox"
                    checked={values.isSMSVisible}
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="isSMSVisible">SMS</label>
                </div>
                {values.isSMSVisible &&
                  <fieldset>
                    <div className="form-group">
                      <label htmlFor="smsText">Text&nbsp;*</label>
                      <textarea
                        className={`form-control form-control-sm ${ errors.smsText ? 'is-invalid' : '' }`}
                        name="smsText" id="smsText"
                        value={values.smsText}
                        rows={8}
                        onChange={handleChange}
                        onFocus={handleFocus}
                      ></textarea>
                      {errors.smsText && <div className="invalid-feedback">{errors.smsText}</div>}
                    </div>
                  </fieldset>
                }
                <div className="form-group form-check">
                  <input
                    className="form-check-input" name="isEmailVisible" id="isEmailVisible"
                    type="checkbox"
                    checked={values.isEmailVisible}
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="isEmailVisible">E-mail</label>
                </div>
                {values.isEmailVisible &&
                  <fieldset>
                    <div className="form-group">
                      <label htmlFor="emailSubject">Subject&nbsp;*</label>
                      <input
                        className={`form-control form-control-sm ${ errors.emailSubject ? 'is-invalid' : '' }`}
                        name="emailSubject" id="emailSubject"
                        type="text"
                        value={values.emailSubject}
                        onChange={handleChange}
                        onFocus={handleFocus}
                      />
                      {errors.emailSubject && <div className="invalid-feedback">{errors.emailSubject}</div>}
                    </div>
                    <div className="form-group">
                      <label htmlFor="emailBody">Body&nbsp;*</label>
                      <CustomEditor
                        value={values.emailBody}
                        onChange={(_, editor) => {
                          const value = editor.getData();
                          handleChange({ target: {value, name: 'emailBody'} });
                        }}
                        onFocus={handleFocusEditor}
                      />
                    </div>
                  </fieldset>
                }
                <div className="form-group form-check">
                  <input
                    className="form-check-input" name="isInAppVisible" id="isInAppVisible"
                    type="checkbox"
                    checked={values.isInAppVisible}
                    onChange={handleChange}
                  />
                  <label className="form-check-label" htmlFor="isInAppVisible">In-App</label>
                </div>
                {values.isInAppVisible &&
                  <fieldset>
                    <div className="form-group">
                      <label htmlFor="inAppText">Text&nbsp;*</label>
                      <textarea
                        className={`form-control form-control-sm ${ errors.inAppText ? 'is-invalid' : '' }`}
                        name="inAppText" id="inAppText"
                        value={values.inAppText}
                        rows={8}
                        onChange={handleChange}
                        onFocus={handleFocus}
                      ></textarea>
                      {errors.inAppText && <div className="invalid-feedback">{errors.inAppText}</div>}
                    </div>
                  </fieldset>
                }
              </div>
              <div className="col-12 col-sm-4">
                {templatesVariables.map((item, index) => {
                  if ( item.description === 'Visitor name' ){
                    return (
                      <div
                        key={index}
                        className="form-group"
                      >
                        <button
                          className="btn btn-primary btn-sm btn-block"
                          type="button"
                          onClick={() => setVariable(item.variable)}
                        >{item.variable}</button>
                        <small className="form-text text-muted">{item.description}</small>
                      </div>
                    )
                  }
                  return null;
                })}
                {variable &&
                  <button
                    className="btn btn-light btn-sm btn-block"
                    type="button"
                    onClick={() => setVariable(null)}
                  >Clear</button>
                }
              </div>
            </div>
          }

        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-secondary btn-sm"
            type="button"
            onClick={onHide}
          >Cancel</button>
          <ButtonLoading
            type="submit"
            loading={isFormLoading}
            disabled={(!values.isEmailVisible && !values.isSMSVisible && !values.isInAppVisible) && true}
          >Send</ButtonLoading>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

UsersNotificationModal.defaultProps = {
  bulk: false,
  isEmail: true,
  isSMS: true,
  isInApp: true
}

UsersNotificationModal.propTypes = {
  bulk: PropTypes.bool,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func
};

export default connect(
  ({ UserState, template, NotificationState }) => ({
    checkedUserIds: UserState.checkedUserIds,

    templatesVariables: template.templatesVariables,
    isModalFetching: template.isModalFetching,

    isFormLoading: NotificationState.isFormLoading,
    isFormSuccess: NotificationState.isFormSuccess
  }),
  dispatch => ({
    sendNotification: notification => dispatch(NotificationActions.send(notification)),
    sendBulkNotification: data => dispatch(NotificationActions.sendBulk(data)),
    uncheckAll: () => dispatch(UserActions.uncheckAll())
  })
)(UsersNotificationModal);
