import { RestrictionActionTypes } from './restriction.types';

const initialState = {
  restrictions: [],
  status: 'Initial'
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RestrictionActionTypes.FetchList:
      return { ...state, restrictions: initialState.restrictions, status: 'Fetching' }
    case RestrictionActionTypes.FetchListSuccess:
      return { ...state, restrictions: action.restrictions, status: 'Fetched' };
    // Create
    case RestrictionActionTypes.Create:
      return { ...state, status: 'Loading' };
    case RestrictionActionTypes.CreateSuccess:
      return { ...state, restrictions: [...state.restrictions, action.restriction], status: 'Loaded' };
    // Update
    case RestrictionActionTypes.Update:
      return { ...state, status: 'Loading' };
    case RestrictionActionTypes.UpdateSuccess:
      return {
        ...state,
        restrictions: state.restrictions.map(b =>
          b.id === action.restriction.id
          ? action.restriction
          : b
        ),
        status: 'Loaded'
      };
    // Delete
    case RestrictionActionTypes.Delete:
      return state;
    case RestrictionActionTypes.DeleteSuccess:
      return {
        ...state,
        restrictions: state.restrictions.filter(b => b.id !== action.restrictionId)
      };
    // Filter
    case RestrictionActionTypes.Filter:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.filterType]: action.filterValue
        }
      };
    case RestrictionActionTypes.ClearFilter:
      return { ...state, filter: initialState.filter };
    // Set status
    case RestrictionActionTypes.StatusSuccess:
      return { ...state, status: 'Success' };
    case RestrictionActionTypes.StatusError:
      return { ...state, status: 'Error' };
    case RestrictionActionTypes.StatusInitial:
      return { ...state, status: 'Initial' };
    default:
      return state;
  }
}