import React, { useEffect } from 'react';
// Redux
import { connect } from 'react-redux';
import { ContentItemActions as Actions } from 'app/store/content-item/content-item.actions';
import * as CategorySelectors from 'app/store/content-category/content-category.selectors';
import * as ItemSelectors from 'app/store/content-item/content-item.selectors';
// Components
import { DataLoading } from 'app/components/Loadings';
import { DataMessage } from 'app/components/DataMessage';
import { ButtonLoading } from 'app/components/Buttons';
// 
import ContentItemsCreate from './ContentItemsCreate';
import ContentItemsFilter from './ContentItemsFilter';
import ContentItemsListItem from './ContentItemsListItem';

const ContentItems = ({
  // State
  items, totalItems, params, isFetching, status,
  // Props
  fetchContentItems, deleteContenItem, moveContentItem
}) => {
  useEffect(() => {
    fetchContentItems({...params, offset: 0 });
    // eslint-disable-next-line
  }, []);

  const handleMove = (id, type, moveId) => moveContentItem(id, type, moveId);
  const handleDelete = item => deleteContenItem(item);

  const handleLoadMore = () => {
    const { limit, offset } = params;
    fetchContentItems({ ...params, offset: offset + limit }, true);
  }

  if ( isFetching ) return <DataLoading />
  return (
    <div className="container-fluid pt-3 pb-3">
      <ContentItemsCreate />
      <ContentItemsFilter />
      <DataMessage data={items}>
        <div className="row row-8">
          {items.map((item, index) => (
            <ContentItemsListItem
              key={`content-item-${index}-${item.id}`}
              index={index}
              item={item}
              items={items}
              onMove={handleMove}
              onDelete={handleDelete}
            />
          ))}
        </div>
        {items.length !== totalItems ? (
          <div className="pt-3 text-center">
            <ButtonLoading
              loading={status === 'FetchingMore'}
              onClick={handleLoadMore}
            >Load more</ButtonLoading>
          </div>
        ) : null}
      </DataMessage>
    </div>
  )
}

const mapStateToProps = state => ({
  categories: CategorySelectors.getItems(state),
  items: ItemSelectors.getItems(state),
  totalItems: ItemSelectors.getTotalItems(state),
  params: ItemSelectors.getParams(state),
  isFetching: ItemSelectors.getIsFetching(state),
  status: ItemSelectors.getStatus(state)
});

const mapDispatchToProps = dispatch => ({
  fetchContentItems: (params, isMoreLoad) => dispatch(Actions.fetchList(params, isMoreLoad)),
  deleteContenItem: item => dispatch(Actions.delete(item)),
  moveContentItem: (id, type, moveId) => dispatch(Actions.move(id, type, moveId))
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentItems);
