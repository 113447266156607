import React, { Component } from 'react';
// Components
import { InputControl, SelectControl, TextareaControl } from '../../../components/FormControls';
import CustomEditor from 'app/components/CustomEditor';

class FormFields extends Component {
  state = {
    selectedVariable: ''
  }

  handleFocus = evt => {
    const { selectedVariable } = this.state;
    const { onChange } = this.props;

    if ( selectedVariable ){
      const { target: { name, value } } = evt;
      onChange({ target: { name, value: value + selectedVariable } });
      this.handleClearVariable();
    }
  }

  handleFocusEditor = (_, editor) => {
    const { selectedVariable } = this.state;

    setTimeout(() => {
      editor.model.change((writer) => {
        if ( !selectedVariable ) return;
        const position = editor.model.document.selection.getFirstPosition();
        writer.insertText(selectedVariable, position, 'after');
        this.handleClearVariable();
      });
    }, 100);
  }

  handleSelectVariable = selectedVariable => () => {
    this.setState({
      selectedVariable
    })
  }

  handleClearVariable = () => {
    this.setState({
      selectedVariable: ''
    })
  }

  render(){
    const { selectedVariable } = this.state;
    const { templatesVariables, submitted, formControlsValue, formControlsOptions, onChange, onChangeVisible,
      isEmailVisible, isSMSVisible, isInAppVisible 
    } = this.props;

    return (
      <>
        <div className="row row-8">
          <div className="col-12 col-sm-8">
            {/* Name */}
            <InputControl
              submitted={submitted}
              value={formControlsValue.name}
              options={formControlsOptions.name}
              onChange={onChange}
            />
            {/* Type */}
            <SelectControl
              submitted={submitted}
              value={formControlsValue.deliverySchedule}
              options={formControlsOptions.deliverySchedule}
              onChange={onChange}
            />
            {/* Time */}
            {(
              formControlsValue.deliverySchedule === 'arrival' ||
              formControlsValue.deliverySchedule === 'departure'
            ) &&
              <>
                <div className="row row-8">
                  <div className={`${ formControlsValue.deliveryType !== 'sameDay' ? 'col-6' : 'col-12' }`}>
                    <SelectControl
                      submitted={submitted}
                      value={formControlsValue.deliveryType}
                      options={formControlsOptions.deliveryType}
                      onChange={onChange}
                    />
                  </div>
                  {formControlsValue.deliveryType !== 'sameDay' &&
                    <div className="col-6">
                      <InputControl
                        submitted={submitted}
                        value={formControlsValue.deliveryDaysSpan}
                        options={formControlsOptions.deliveryDaysSpan}
                        onChange={onChange}
                      />
                    </div>
                  }
                </div>
              </>
            }
            {/* Delivery hour */}
            <SelectControl
              submitted={submitted}
              value={formControlsValue.deliveryHour}
              options={formControlsOptions.deliveryHour}
              onChange={onChange}
            />
          </div>
        </div>

        <hr />

        <div className="row row-8">
          <div className="col-12 col-sm-8">
            <div className="form-group">
              <small className={(!isEmailVisible && !isSMSVisible && !isInAppVisible) ? 'text-danger' : 'text-muted'}>(At least one option should be selected)</small>
            </div>
            <div className="form-group form-check">
              <input type="checkbox" className="form-check-input" name="isSMSVisible" id="isSMSVisible" checked={isSMSVisible} onChange={onChangeVisible} />
              <label className="form-check-label" htmlFor="isSMSVisible">SMS</label>
            </div>
            {isSMSVisible &&
              <fieldset>
                {/* SMS */}
                <TextareaControl
                  submitted={submitted}
                  value={formControlsValue.smsText}
                  options={formControlsOptions.smsText}
                  onChange={onChange}
                  onFocus={this.handleFocus}
                />
              </fieldset>
            }
            <div className="form-group form-check">
              <input type="checkbox" className="form-check-input" name="isEmailVisible" id="isEmailVisible" checked={isEmailVisible} onChange={onChangeVisible} />
              <label className="form-check-label" htmlFor="isEmailVisible">E-mail</label>
            </div>
            {isEmailVisible &&
              <fieldset>
                <InputControl
                  submitted={submitted}
                  value={formControlsValue.emailSubject}
                  options={formControlsOptions.emailSubject}
                  onChange={onChange}
                  onFocus={this.handleFocus}
                />
                <CustomEditor
                  value={formControlsValue.emailBody}
                  onChange={(_, editor) => {
                    const value = editor.getData();
                    onChange({ target: {value, name: 'emailBody'} });
                  }}
                  onFocus={this.handleFocusEditor}
                  label="Body"
                  required={true}
                />
              </fieldset>
            }
            <div className="form-group form-check">
              <input type="checkbox" className="form-check-input" name="isInAppVisible" id="isInAppVisible" checked={isInAppVisible} onChange={onChangeVisible} />
              <label className="form-check-label" htmlFor="isInAppVisible">In-App</label>
            </div>
            {isInAppVisible &&
              <fieldset>
                <TextareaControl
                  submitted={submitted}
                  value={formControlsValue.inAppText}
                  options={formControlsOptions.inAppText}
                  onChange={onChange}
                  onFocus={this.handleFocus}
                />
              </fieldset>
            }
          </div>
          <div className="col-12 col-sm-4">
            {templatesVariables.map((item, index) => (
              <div
                key={index}
                className="form-group"
              >
                <button
                  className="btn btn-primary btn-sm btn-block"
                  type="button"
                  onClick={this.handleSelectVariable(item.variable)}
                >{item.variable}</button>
                <small className="form-text text-muted">{item.description}</small>
              </div>
            ))}
            {selectedVariable &&
              <button
                className="btn btn-light btn-sm btn-block"
                type="button"
                onClick={this.handleClearVariable}
              >Clear</button>
            }
          </div>
        </div>
      </>
    )
  }
}

export default FormFields;
