import { createSelector } from "reselect";
// Models
import { IMembership } from "models/Membership";

export const getMemberships = (state:any) => state.membership.memberships.items;
export const getMembershipsTotal = (state:any) => state.membership.memberships.total;
export const getMembershipsParams = (state:any) => state.membership.memberships.params;

export const getMembership = (state:any) => state.membership.membership;

export const getTransactions = (state:any) => state.membership.transactions;

export const getVisits = (state:any) => state.membership.visits.items;
export const getVisitsTotal = (state:any) => state.membership.visits.total;
export const getVisitsParams = (state:any) => state.membership.visits.params;

export const getIsLoading = (state:any) => state.membership.isLoading;
export const getStatus = (state:any) => state.membership.status;

export const selectUserEmailFromMembership = createSelector(
  [
    getMemberships,
    (_:any, props:{ membershipId:number }) => props
  ],
  (memberships:Array<IMembership>, { membershipId }) => {
    const findedMembership:IMembership | undefined = memberships.find((membership:IMembership) => membership.id === membershipId);
    if ( !findedMembership ) return '';
    const { userData } = findedMembership;
    return userData.email;
  }
)