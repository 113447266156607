import { createSelector } from 'reselect';

const _items = state => state.ContentItemState.items;

export const getItems = createSelector(
  _items, items => items
);

export const getTotalItems = state => state.ContentItemState.totalItems;
export const getParams = state => state.ContentItemState.params;

export const getIsFetching = state => state.ContentItemState.isFetching;
export const getIsLoading = state => state.ContentItemState.isLoading;
export const getStatus = state => state.ContentItemState.status;

