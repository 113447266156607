import React from 'react';
// Store
import { useSelector } from 'react-redux';
// Selectors
import { getMembershipsParams } from 'store/membership/membership.selectors';
// Components
import { Input, Select } from 'components/Controls';

type Props = {
  onFetchMemberships: (params:any) => void;
};

let timeout:any = null;

const MembershipPassesFilter:React.FC<Props> = ({
  // Props
  onFetchMemberships
}) => {
  // State
  const params:any = useSelector((state:any) => getMembershipsParams(state));

  const [ filter, setFilter ] = React.useState({
    username: params.username,
    statuses: params.statuses
  });

  const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFilter((prevState:any) => ({
      ...prevState,
      [name]: value
    }));
    if ( timeout ) clearTimeout(timeout);
    timeout = setTimeout(() => {
      onFetchMemberships({
        ...params,
        offset: 0,
        [name]: value
      });
    }, 1000);
  }

  return (
    <div className="row row-8">
      <div className="col-6">
        <Input
          controlRef={null}
          label="Username" name="username" id="username" defaultValue={filter.username}
          placeholder="Search by user first or last name"
          onChange={handleChange}
        />
      </div>
      <div className="col-6">
        <Select
          controlRef={null}
          label="Status" name="statuses" id="statuses" defaultValue={filter.statuses}
          onChange={handleChange}
          options={[
            { value: '', label: 'All' },
            { value: 'active', label: 'Active' },
            { value: 'inactive', label: 'Inactive' },
          ]}
        />
      </div>
    </div>
  )
}

export default MembershipPassesFilter;
