import { BlackoutActionTypes } from './blackout.types';

const initialState = {
  blackouts: [],
  filter: {
    search: ''
  },
  status: 'Initial'
}

export default (state = initialState, action) => {
  switch (action.type) {
    case BlackoutActionTypes.FetchBlackouts:
      return { ...state, blackouts: initialState.blackouts, status: 'Fetching' }
    case BlackoutActionTypes.FetchBlackoutsSuccess:
      return { ...state, blackouts: action.blackouts, status: 'Fetched' };
    // Create
    case BlackoutActionTypes.Create:
      return { ...state, status: 'Loading' };
    case BlackoutActionTypes.CreateSuccess:
      return { ...state, blackouts: [...state.blackouts, action.blackout], status: 'Loaded' };
    // Update
    case BlackoutActionTypes.Update:
      return { ...state, status: 'Loading' };
    case BlackoutActionTypes.UpdateSuccess:
      return {
        ...state,
        blackouts: state.blackouts.map(b =>
          b.id === action.blackout.id
          ? { ...b, ...action.blackout }
          : b
        ),
        status: 'Loaded'
      };
    // Delete
    case BlackoutActionTypes.Delete:
      return state;
    case BlackoutActionTypes.DeleteSuccess:
      return {
        ...state,
        blackouts: state.blackouts.filter(b => b.id !== action.blackoutId)
      };
    // Filter
    case BlackoutActionTypes.Filter:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.filterType]: action.filterValue
        }
      };
    case BlackoutActionTypes.ClearFilter:
      return { ...state, filter: initialState.filter };
    // Set status
    case BlackoutActionTypes.StatusSuccess:
      return { ...state, status: 'Success' };
    case BlackoutActionTypes.StatusError:
      return { ...state, status: 'Error' };
    case BlackoutActionTypes.StatusInitial:
      return { ...state, status: 'Initial' };
    default:
      return state;
  }
}