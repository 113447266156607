import { createSelector } from 'reselect';
import { IInventoryGroup } from './inventory-group.types';

export const getItems = (state:any) => state.inventoryGroup.items;
export const getInventoryGroup = (state:any) => state.inventoryGroup.item;
export const getFilter = (state:any) => state.inventoryGroup.filter;
export const getIsFetching = (state:any) => state.inventoryGroup.isFetching;
export const getIsLoading = (state:any) => state.inventoryGroup.isLoading;
export const getStatus = (state:any) => state.inventoryGroup.status;


export const getFilteredItems = createSelector(
  getItems, getFilter,
  (items, filter) => {
    if ( !items || items.length === 0 ) return items;
    const { type } = filter;
    return items.filter((item:IInventoryGroup) => {
      if ( type === '' ) return true;
      return item.type === type;
    })
  }
);

export const getInventoryGroupById = (state:any, props:{ id:number }) => {
  const { items } = state.inventoryGroup;
  if ( !items || items.length === 0 ) return {};
  const { id } = props;
  const founded = items.find((item:IInventoryGroup) => item.id === id);
  return founded || {};
}

export const getItemsForSelect = (state:any) => {
  const { items } = state.inventoryGroup;
  if ( !items || items.length === 0 ) return [];
  return items.map((item:IInventoryGroup) => ({ value: item.id, label: item.name }))
}
