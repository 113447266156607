import fetcher from 'app/utilities/fetcher';

const headers = { 'Content-Type': 'application/json' };

// Fetch schedule stors
// @params { Object } params
// @params { string } params.start
// @params { string } params.end
export function $fetchScheduleSlots(params){
  const searchParams = new URLSearchParams();
  if ( params.start ) searchParams.append('start', params.start);
  if ( params.end ) searchParams.append('end', params.end);
  return fetcher(`/admin/scheduleSlots?${searchParams}`, {
    method: 'GET'
  });
}

// Create schedule slot
// @params { Object } data
// @params { string } data.start
// @params { string } data.end
// @params { string } data.location
// @params { string } data.description
export function $createScheduleSlot(data){
  return fetcher('/admin/scheduleSlots', {
    method: 'POST',
    headers,
    body: JSON.stringify(data)
  });
    // .then(response => responseHandler(response, _CREATE))
    // .catch(errorHandler);
}

// Update schedule slot
// @params { Object } data
// @params { number } data.id
// @params { string } data.start
// @params { string } data.end
// @params { string } data.location
// @params { string } data.description
export function $updateScheduleSlot(data){
  return fetcher(`/admin/scheduleSlots/${data.id}`, {
    method: 'PUT',
    headers,
    body: JSON.stringify(data)
  })
    // .then(response => responseHandler(response, _UPDATE))
    // .catch(errorHandler);
}

// Delete schedule slot
export function $deleteScheduleSlot(slotId){
  return fetcher(`/admin/scheduleSlots/${slotId}`, {
    method: 'DELETE'
  }, () => null);
    // .then(response => responseHandler(response, _DELETE))
    // .catch(errorHandler);
}
