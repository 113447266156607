import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
import { RestrictionActions } from '../../../store/restriction/restriction.actions';
import * as RestrictionSelectors from '../../../store/restriction/restriction.selectors';
// Components
import { ContentLoading } from '../../../components/Loadings';
// 
import RestrictionsCreate from './RestrictionsCreate';
import RestrictionsListItem from './RestrictionsListItem';

const RestrictionsPage = ({
  // Props
  // State
  restrictions, status,
  // Dispatch
  fetchRestrictions
}) => {
  const { bookingId } = useParams();

  useEffect(() => {
    fetchRestrictions(bookingId);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <RestrictionsCreate />
      <ContentLoading data={restrictions} status={status}>
        <div className="container-sm">
          <ul className="list-group">
            {restrictions.map((restriction, index) => (
              <RestrictionsListItem
                key={index}
                restriction={restriction}
              />
            ))}
          </ul>
        </div>
      </ContentLoading>
    </>
  )
}

const mapStateToProps = ({ RestrictionState }) => ({
  restrictions: RestrictionSelectors.getRestrictions(RestrictionState),
  status: RestrictionSelectors.getStatus(RestrictionState)
});

const mapDispatchToProps = dispatch => ({
  fetchRestrictions: bookingId => dispatch(RestrictionActions.fetchList(bookingId))
});

export default connect(mapStateToProps, mapDispatchToProps)(RestrictionsPage);
