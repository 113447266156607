import { call, put, takeLatest } from 'redux-saga/effects';
import { ContentItemService } from './content-item.service';
import * as LayoutActions from 'app/actions/layout.actions';
import {
  FETCH_LIST, CREATE, UPDATE, DELETE, MOVE,
  ContentItemActions
} from './content-item.actions';

function* _fetchList({ payload }){
  try {
    const response = yield call(ContentItemService.fetchList, payload.params);
    yield put(ContentItemActions.fetchListSuccess(response));
  } catch(error){
    console.error(error);
    yield put(ContentItemActions.setStatus('Error'));
  }
}

function* _create({ payload }){
  try {
    const response = yield call(ContentItemService.create, payload.data);
    yield put(ContentItemActions.createSuccess(response));
    yield put(ContentItemActions.setStatus('Success'));
    yield put(ContentItemActions.setStatus('Initial'));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: 'Content item was created'
    }));
  } catch(error){
    console.error(error);
    yield put(ContentItemActions.setStatus('Error'));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _update({ payload }){
  try {
    const response = yield call(ContentItemService.update, payload.id, payload.data);
    yield put(ContentItemActions.updateSuccess(response));
    yield put(ContentItemActions.setStatus('Success'));
    yield put(ContentItemActions.setStatus('Initial'));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: 'Content item was updated'
    }));
  } catch(error){
    console.error(error);
    yield put(ContentItemActions.setStatus('Error'));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _delete({ payload }){
  try {
    if ( window.confirm(`Are you sure you want to delete category: ${payload.item.headline}?`) ){
      yield call(ContentItemService.delete, payload.item.id);
      yield put(ContentItemActions.deleteSuccess(payload.item.id));
      yield put(ContentItemActions.setStatus('Success'));
      yield put(ContentItemActions.setStatus('Initial'));
      yield put(LayoutActions.addNotification({
        type: 'success',
        message: 'Content item was deleted'
      }));
    }
  } catch(error){
    console.error(error);
    yield put(ContentItemActions.setStatus('Error'));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _move({ payload }){
  try {
    const { id, type, moveId } = payload;
    yield call(ContentItemService.move, id, type, moveId);
    yield put(ContentItemActions.moveSuccess(id, moveId));
  } catch(error){
    yield put(ContentItemActions.setStatus('Error'));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

export default function* (){
  yield takeLatest(FETCH_LIST, _fetchList);
  yield takeLatest(CREATE, _create);
  yield takeLatest(UPDATE, _update);
  yield takeLatest(DELETE, _delete);
  yield takeLatest(MOVE, _move);
}



// import { takeLatest, call, put } from 'redux-saga/effects';
// // Action
// import * as LayoutActions from 'app/actions/layout.actions';
// import Types from '../types/content-item.types';
// import ContentItemService from '../services/content-item.service';
// import ContentItemActions from '../actions/content-item.actions';

// // Fetch order list
// function* _fetchContentItems(action){
//   try {
//     const { params } = action.payload;
//     const response = yield call(ContentItemService.fetchContentItems, params);
//     yield put(ContentItemActions.fetchContentItemsSuccess(response));
//   } catch(err){
//     yield put(ContentItemActions.fetchContentItemsFailure());
//     console.error(err);
//   }
// }

// // Fetch content item by id
// function* _fetchContentItemById(action){
//   try {
//     const { contentItemId } = action.payload;
//     const response = yield call(ContentItemService.fetchContentItemById, contentItemId);
//     yield put(ContentItemActions.fetchContentItemByIdSuccess(response));
//   } catch(err){
//     yield put(ContentItemActions.fetchContentItemByIdFailure());
//     console.error(err);
//   }
// }

// // Create content item
// function* _createContentItem(action){
//   try {
//     const { contentItem } = action.payload;
//     const response = yield call(ContentItemService.createContentItem, contentItem);
//     yield put(ContentItemActions.createContentItemSuccess(response));
//     yield put(LayoutActions.addNotification({
//       type: 'success',
//       message: 'Content item was created'
//     }));
//   } catch(err){
//     yield put(ContentItemActions.createContentItemFailure());
//     console.error(err);
//     yield put(LayoutActions.addNotification({
//       type: 'danger',
//       message: err.message
//     }));
//   }
// }

// // Update content item
// function* _updateContentItem(action){
//   try {
//     const { contentItem } = action.payload;
//     const response = yield call(ContentItemService.updateContentItem, contentItem);
//     yield put(ContentItemActions.updateContentItemSuccess(response));
//     yield put(LayoutActions.addNotification({
//       type: 'success',
//       message: 'Content item was updated'
//     }));
//   } catch(err){
//     yield put(ContentItemActions.updateContentItemFailure());
//     console.error(err);
//     yield put(LayoutActions.addNotification({
//       type: 'danger',
//       message: err.message
//     }));
//   }
// }

// // Delete content item
// function* _deleteContentItem(action){
//   try {
//     const { contentItem } = action.payload;
//     if ( window.confirm(`Are you sure you want to delete content: ${contentItem.headline}?`) ){
//       yield call(ContentItemService.deleteContentItem, contentItem.id);
//       yield put(ContentItemActions.deleteContentItemSuccess(contentItem.id));
//       yield put(LayoutActions.addNotification({
//         type: 'success',
//         message: 'Content item was deleted'
//       }));
//     }
//   } catch(err){
//     yield put(ContentItemActions.deleteContentItemFailure());
//     console.error(err);
//     yield put(LayoutActions.addNotification({
//       type: 'danger',
//       message: err.message
//     }));
//   }
// }

// // Move content item
// function* _moveContentItem(action){
//   try {
//     const { contentItemId, type, moveId } = action.payload;
//     yield call(ContentItemService.moveContentItem, contentItemId, type, moveId);
//     yield put(ContentItemActions.moveContentItemSuccess(contentItemId, moveId));
//   } catch(err){
//     yield put(ContentItemActions.moveContentItemFailure());
//     console.error(err);
//     yield put(LayoutActions.addNotification({
//       type: 'danger',
//       message: err.message
//     }));
//   }
// }

// export default function* (){
//   yield takeLatest(Types.FETCH_CONTENT_ITEMS, _fetchContentItems);
//   yield takeLatest(Types.FETCH_CONTENT_ITEM_BY_ID, _fetchContentItemById);
//   yield takeLatest(Types.CREATE_CONTENT_ITEM, _createContentItem);
//   yield takeLatest(Types.UPDATE_CONTENT_ITEM, _updateContentItem);
//   yield takeLatest(Types.DELETE_CONTENT_ITEM, _deleteContentItem);
//   yield takeLatest(Types.MOVE_CONTENT_ITEM, _moveContentItem);
// }
