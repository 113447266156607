import React from 'react';
// Models
import { IMembershipType } from 'models/MembershipType';
// Store
import { useDispatch, useSelector } from 'react-redux';
// Actions
import MembershipTypeActions from 'store/membership-type/membership-type.actions';
// Selectors
import { getMembershipTypes } from 'store/membership-type/membership-type.selectors';
// Components
import { Loader, Message } from 'components/Utility';
// 
import MembershipTypesListItem from './MembershipTypesListItem';

type Props = {};

const MembershipTypesList:React.FC<Props> = () => {
  // Dispatch
  const dispatch = useDispatch();
  // State
  const membershipTypes:Array<IMembershipType> | null = useSelector((state:any) => getMembershipTypes(state));

  React.useEffect(() => {
    dispatch(MembershipTypeActions.fetchMembershipTypes());
    return () => {
      dispatch(MembershipTypeActions.setInitialField('membershipTypes'));
    }
    // eslint-disable-next-line
  }, []);

  const handleEnableMembershipType = (membershipTypeId:number) => dispatch(MembershipTypeActions.enableMembershipType(membershipTypeId));
  const handleDisableMembershipType = (membershipTypeId:number) => dispatch(MembershipTypeActions.disableMembershipType(membershipTypeId));
  const handleDeleteMembershipType = (membershipTypeId:number) => dispatch(MembershipTypeActions.deleteMembershipType(membershipTypeId));

  if ( !membershipTypes ) return <Loader />;
  if ( membershipTypes.length === 0 ) return <Message text="No membership type(s)" />;
  return (
    <div className="row row-8">
      {membershipTypes.map((membershipType:IMembershipType) => (
        <div
          key={`membership-type-item-${membershipType.id}`}
          className="col col-lg-3 d-flex | mb-2"
        >
          <MembershipTypesListItem
            membershipType={membershipType}
            onEnable={handleEnableMembershipType}
            onDisable={handleDisableMembershipType}
            onDelete={handleDeleteMembershipType}
          />
        </div>
      ))}
    </div>
  )
}

export default MembershipTypesList;
