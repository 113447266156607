import HttpUtility from 'utilities/http.utility';

export default class TaxesAPI {
  private static _url:string = '/admin/taxes';
  private static _http:HttpUtility = new HttpUtility();

  // Fetch taxes
  public static async fetchTaxes(params:any):Promise<any> {
    const searchParams = new URLSearchParams();
    Object.keys(params).forEach((key:string) => {
      if ( params[key] ) searchParams.append(key, params[key]);
    });
    return TaxesAPI._http.get(`${TaxesAPI._url}?${searchParams}`);
  }
  // Fetch by id
  public static async fetchTax(taxId:number):Promise<any> {
    return TaxesAPI._http.get(`${TaxesAPI._url}/${taxId}`);
  }
  // Create
  public static async createTax(data:any):Promise<any> {
    return TaxesAPI._http.post(TaxesAPI._url, data);
  }
  // Update
  public static async updateTax(taxId:number, data:any):Promise<any> {
    return TaxesAPI._http.put(`${TaxesAPI._url}/${taxId}`, data);
  }
  // Delete
  public static async deleteTax(taxId:number):Promise<any> {
    return TaxesAPI._http.delete(`${TaxesAPI._url}/${taxId}`);
  }
}