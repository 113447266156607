
import { AppTypes } from 'store/app/app.types';
import AppActions from 'store/app/app.actions';
import * as StorageService from 'app/services/storage.service';

export default (store:any) => (next:any) => (action:any) => {
  if ( action.type === AppTypes.SetInited ){
    if ( !StorageService.getToken() || StorageService.checkIsExpires() ){
      const { pathname } = window.location;
      if (
        pathname.replace('/', '') !== '' ||
        pathname.indexOf('/reset-password') !== -1
      ){
        next(AppActions.setRequestedUrl(pathname));
      }
    }
  }
  next(action);
}