import { Action } from 'redux';
// Types
import Statuses from 'types/statuses';
// Models
import { IWaiver } from 'models/Waiver';

// Messages
export enum WaiverMessages {
  Create = 'Waiver was created',
  Update = 'Waiver was updated',
  Delete = 'Waiver was deleted'
}

// Types
export enum WaiverTypes {
  FetchWaivers = '@@waiver/Fetch waivers',
  FetchWaiversSuccess = '@@waiver/Fetch waivers success',

  FetchWaiver = '@@waiver/Fetch waiver',
  FetchWaiverSuccess = '@@waiver/Fetch waiver success',

  CreateWaiver = '@@waiver/Create waiver',
  CreateWaiverSuccess = '@@waiver/Create waiver success',

  UpdateWaiver = '@@waiver/Update waiver',
  UpdateWaiverSuccess = '@@waiver/Update waiver success',

  DeleteWaiver = '@@waiver/Delete waiver',
  DeleteWaiverSuccess = '@@waiver/Delete waiver success',

  SetStatus = '@@waiver/Set status'
}

interface FetchWaivers extends Action<typeof WaiverTypes.FetchWaivers> {
  payload: { params:any }
}
interface FetchWaiversSuccess extends Action<typeof WaiverTypes.FetchWaiversSuccess> {
  payload: { waivers:Array<IWaiver> }
}

interface FetchWaiver extends Action<typeof WaiverTypes.FetchWaiver> {
  payload: { waiverId:number }
}
interface FetchWaiverSuccess extends Action<typeof WaiverTypes.FetchWaiverSuccess> {
  payload: { waiver:IWaiver }
}

interface CreateWaiver extends Action<typeof WaiverTypes.CreateWaiver> {
  payload: { data:any }
}
interface CreateWaiverSuccess extends Action<typeof WaiverTypes.CreateWaiverSuccess> {
  payload: { waiver:IWaiver }
}

interface UpdateWaiver extends Action<typeof WaiverTypes.UpdateWaiver> {
  payload: { waiverId:number, data:any }
}
interface UpdateWaiverSuccess extends Action<typeof WaiverTypes.UpdateWaiverSuccess> {
  payload: { waiver:IWaiver }
}

interface DeleteWaiver extends Action<typeof WaiverTypes.DeleteWaiver> {
  payload: { waiverId:number }
}
interface DeleteWaiverSuccess extends Action<typeof WaiverTypes.DeleteWaiverSuccess> {
  payload: { waiverId:number }
}

interface SetStatus extends Action<typeof WaiverTypes.SetStatus> {
  payload: { status:Statuses }
}

export type WaiverActionTypes =
  FetchWaivers | FetchWaiversSuccess |
  FetchWaiver | FetchWaiverSuccess |
  CreateWaiver | CreateWaiverSuccess |
  UpdateWaiver | UpdateWaiverSuccess |
  DeleteWaiver | DeleteWaiverSuccess |
  SetStatus
;
