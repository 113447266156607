import React, { useState } from 'react';
// Dialogs
import MessageTemplateFormDialog from 'dialogs/MessageTemplateForm.dialog';
// Utilities
import { toNormalCase } from 'utilities/wordbreak.utility';

const SystemMessageTemplatesList = ({
  // Props
  item
}) => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);

  return (
    <>
      <li className="list-group-item | d-flex justify-content-between align-items-center">
        <div>
          <small className="text-muted text-capitalize">{toNormalCase(item.name)}</small>
          <p className="mb-0">{item.description}</p>
        </div>
        <div>
          <button
            className="btn btn-primary btn-sm btn-block"
            type="button"
            onClick={handleShow}
          >Edit</button>
        </div>
      </li>
      {show ? (
        <MessageTemplateFormDialog
          messageTemplateId={item.id}
          show={show}
          onHide={handleHide}
        />
      ) : null}
    </>
  )
}

export default SystemMessageTemplatesList;
