import create from './create-type';

const _type = 'inventory_group';

const _actions = [
  'FETCH_ALL',
  'FETCH_BY_ID',
  'CREATE',
  'UPDATE',
  'DELETE',
  'ENABLE',
  'DISABLE'
];

const Types = {};

for ( let i in _actions ){
  Types[_actions[i]] = create(_type, [_actions[i]]);
  Types[`${_actions[i]}_SUCCESS`] = create(_type, `${[_actions[i]]}_SUCCESS`);
  Types[`${_actions[i]}_FAILURE`] = create(_type, `${[_actions[i]]}_FAILURE`);
}

Types['CLEAR_FETCH_BY_ID'] = create(_type, 'CLEAR_FETCH_BY_ID');

Types['SET_IS_FETCHING'] = create(_type, 'SET_IS_FETCHING');
Types['SET_IS_FETCHED'] = create(_type, 'SET_IS_FETCHED');

Types['RESET_FORM'] = create(_type, 'RESET_FORM');
Types['SET_INITIAL_STATE'] = create(_type, 'SET_INITIAL_STATE');

export default Types;

export const InventoryGroupMessageTypes = {
  Create: 'Inventory group was created',
  Update: 'Inventory group was updated',
  Delete: 'Inventory group was deleted'
}
