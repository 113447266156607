import Types from '../types/user.types';

const initialState = {
  campers: [],
  camperTotal: null,

  users: [],
  userTotal: null,

  staff: [],

  user: {},
  checkedUserIds: [],

  params: {
    limit: 20,
    offset: 0,
    username: '',
    phone: '',
    camperPlatform: '',
    visitingFrom: '',
    visitingTo: ''
  },

  status: 'Initial',

  isFetching: true,
  isFormLoading: false,
  isFormSuccess: false
};

export default function(state = initialState, { type, payload }){
  switch (type){
    // Request
    case Types.FETCH_ALL_CAMPERS:
      const { params, isLoadInitial, isLoadMore } = payload;
      return {
        ...state,
        campers: isLoadMore ? state.campers : initialState.campers,
        params: isLoadInitial ? initialState.params : { ...state.params, ...params },
        checkedUserIds:
          isLoadInitial
          ? []
          : params.offset === 0
            ? []
            : state.checkedUserIds,
        status: isLoadMore ? 'FetchingMore' : state.status,
        isFetching: !isLoadMore
      };
    case Types.FETCH_ALL_STAFF:
      return { ...state, campers: [] };
    case Types.FETCH_BY_ID:
      return { ...state, user: {}, isFetching: true };
    case Types.CREATE:
    case Types.UPDATE:
    case Types.UPDATE_CAMPER:
    case Types.CREATE_CAMPER:
      return { ...state, isFormLoading: true, isFormSuccess: false };
    // case Types.SEARCH_CAMPER:
    //   return { ...state, campers: initialState.campers, status: 'Searching' };
    // case Types.SEARCH_CAMPER_SUCCESS:
    //   return { ...state, campers: payload.camper.data, status: 'Searched' };

    case Types.SEARCH_USERS:
      return { ...state, users: [], userTotal: null, status: 'Searching' };
    case Types.SEARCH_USERS_SUCCESS:
      return { ...state, users: payload.user.data || [], userTotal: payload.user.total, status: 'Searched' };
    // Success
    case Types.FETCH_ALL_CAMPERS_SUCCESS:
      return _fetchAllCampersSuccess(state, payload.camper);
    case Types.FETCH_ALL_STAFF_SUCCESS:
      return { ...state, staff: payload.staff };
    case Types.FETCH_BY_ID_SUCCESS:
      return { ...state, user: payload.user, isFetching: false };
    case Types.CREATE_SUCCESS:
      return { ...state, staff: [...state.staff, payload.user], isFormLoading: false, isFormSuccess: true };
    case Types.UPDATE_SUCCESS:
    case Types.UPDATE_CAMPER_SUCCESS:
      return {
        ...state,
        campers: state.campers.map(c => c.id === payload.user.id ? payload.user : c),
        isFormLoading: false,
        isFormSuccess: true
      };
    case Types.CREATE_CAMPER_SUCCESS:
      return {
        ...state,
        campers: [...state.campers, payload.user],
        camperTotal: state.camperTotal ? state.camperTotal + 1 : state.camperTotal,
        isFormLoading: false,
        isFormSuccess: true
      };
    case Types.DELETE_SUCCESS:
      return { ...state, campers: state.campers.filter(s => s.id !== payload.userId) };
    case Types.DISABLE_SUCCESS:
      return { ...state, staff: state.staff.map(s => s.id === payload.userId ? {...s, enabled: false} : s) }
    case Types.ENABLE_SUCCESS:
      return { ...state, staff: state.staff.map(s => s.id === payload.userId ? {...s, enabled: true} : s) }
    // Failure
    case Types.FETCH_ALL_CAMPERS_FAILURE:
      return { ...state, isFetching: false };
    case Types.FETCH_ALL_STAFF_FAILURE:
      return state;
    case Types.CREATE_FAILURE:
    case Types.UPDATE_FAILURE:
    case Types.UPDATE_CAMPER_FAILURE:
      return { ...state, isFormLoading: false, isFormSuccess: false };
    case Types.RESET_PARAMS:
      return { ...state, params: initialState.params };
    // Other
    case Types.CHECK_USER:
      return {
        ...state,
        checkedUserIds: [...state.checkedUserIds, payload.userId],
        campers: state.campers.map(c => c.id === payload.userId ? { ...c, checked: true } : c)
      };
    case Types.UNCHECK_USER:
      const idx = state.checkedUserIds.indexOf(payload.userId);
      return {
        ...state,
        checkedUserIds: state.checkedUserIds.filter((_, index) => index !== idx),
        campers: state.campers.map(c => c.id === payload.userId ? { ...c, checked: false } : c)
      };
    case Types.CHECK_ALL:
      return {
        ...state,
        checkedUserIds: state.campers.map(c => c.id),
        campers: state.campers.map(c => ({ ...c, checked: true }))
      };
    case Types.UNCHECK_ALL:
      return {
        ...state,
        checkedUserIds: [],
        campers: state.campers.map(c => ({ ...c, checked: false }))
      };
    case Types.RESET_FORM:
      return { ...state, isFormLoading: false, isFormSuccess: false };
    case Types.SET_IS_FETCHING:
      return { ...state, isFetching: true };
    case Types.SET_IS_FETCHED:
      return { ...state, isFetching: false };
    case Types.SET_INITIAL_STATE:
      return { ...state, ...initialState };
    default:
      return state;
  }
}

// Fetch all campers
const _fetchAllCampersSuccess = (state, { data = [], total }) => {
  const campers = data.map(c => ({ ...c, checked: false }));
  return {
    ...state,
    campers: [...state.campers, ...campers],
    camperTotal: total,
    status: 'Fetched',
    isFetching: false
  }
}
