import React, { useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
import { ReservationActions } from 'app/store/reservation/reservation.actions';
import * as BookingItemSelectors from 'app/store/booking-item/booking-item.selectors';
import * as ReservationSelectors from 'app/store/reservation/reservation.selectors';
// Components
import { DataLoading } from 'app/components/Loadings';
// 
import CalendarDailyTimelineItem from './CalendarDailyTimelineItem';

const format = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';
// const formatBlackout = 'YYYY-MM-DD';

let last_known_scroll_position = 0;
let ticking = false;
let last_known_top = 0;

const CalendarDailyTimeline = ({
  now, days,
  // State
  bookingItems, reservationItems, status,
  // Dispatch
  fetchReservationDetails
}) => {
  const {bookingId} = useParams();

  const dayEl = useRef(null);

  useEffect(() => {
    const daysInMonth = now.daysInMonth();
    const start = now.date(1).hour('00').minute('00').second('00').format(format);
    const end = now.date(daysInMonth).hour('00').minute('00').second('00').format(format);
    // const startBlackout = now.date(1).format(formatBlackout);
    // const endBlackout = now.date(daysInMonth).format(formatBlackout);
    fetchReservationDetails(bookingId, {
      limit: bookingItems.length * 31, start, end,
      statuses: 'upcoming,active,completed'
    });
    // eslint-disable-next-line
  }, [now]);

  useEffect(() => {
    last_known_top = dayEl.current.getBoundingClientRect().top;
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      last_known_scroll_position = 0;
      ticking = false;
      last_known_top = 0;
    }
    // eslint-disable-next-line
  }, []);

  const handleScroll = () => {
    last_known_scroll_position = window.scrollY - last_known_top >= 0 ? window.scrollY - last_known_top : 0;

    if ( !ticking ){
      window.requestAnimationFrame(() => {
        if ( dayEl && dayEl.current ){
          dayEl.current.style.top = `${ last_known_scroll_position }px`;
          ticking = false;
        }
      });
      ticking = true;
    }
  }

  if ( status === 'Fetching' ) return <DataLoading />;
  return (

    <div className="reservation-body">
      <div className="reservation-left">

        <div className="timeline timeline-category">
          <div className="timeline-item"/>
          {reservationItems.map((reservationItem, index) => {
            if ( reservationItem.bookingItem.id === 0 && reservationItem.timelines.length === 0 ) return '';
            return (
              <div
                key={index}
                className="timeline-item"
                style={{
                  height: Array.isArray(reservationItem.timelines)
                    ? `${reservationItem.timelines.length * 2.5}rem`
                    : `2.5rem`
                }}
              >
                <b>{reservationItem.bookingItem.identifier}</b>
                <small className="d-block text-muted">{reservationItem.bookingItem.reservationCategoryName}</small>
              </div>
            )
          })}
        </div>

      </div>
      <div className="reservation-right" style={{paddingBottom: '1rem', position: 'relative'}}>
        <div style={{ height: '2.5rem' }}></div>

        <div ref={dayEl} className="timeline timeline-day" style={{ position: 'absolute', top: 0, left: 0, right: 0, zIndex: 2 }}>
          {days.map((day, idx) => (
            <div key={idx} className="timeline-item | badge-white d-flex flex-direction-column justify-content-center">
              <span className="_day">{day.format('dd')}</span>
              <span className="_number">{day.format('DD')}</span>
            </div>
          ))}
        </div>

        {reservationItems.map((reservationItem, index) => (
          Array.isArray(reservationItem.timelines)
          ? 
            reservationItem.timelines.map((timeline, ti) => (
              <div key={`_${ti}`} className="timeline timeline-day">
                {Object.keys(timeline).map((day, i) => (
                  <div key={`day_${reservationItem.bookingItem.id}_${i}`} className="timeline-item">
                    {timeline[day].hasReservation &&
                      <CalendarDailyTimelineItem reservation={timeline[day]} />
                    }
                  </div>
                ))}
              </div>
            ))
          :
            <div key={index} className="timeline timeline-day">
              {Object.keys(reservationItem.timelines).map((day, i) => (
                <div key={`day_${reservationItem.bookingItem.id}_${i}`} className="timeline-item">
                  {reservationItem.timelines[day].hasReservation &&
                    <CalendarDailyTimelineItem reservation={reservationItem.timelines[day]} />
                  }
                  {reservationItem.timelines[day].isOutOfService &&
                    <span
                      className="_outOfService"
                      style={{
                        width: `${100 * reservationItem.timelines[day].days - 4}%`,
                      }}
                      title="Out of service"
                    >Out of service</span>
                  }
                </div>
              ))}
            </div>
        ))}

      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  bookingItems: BookingItemSelectors.getBookingItems(state.BookingItemState),
  reservationItems: ReservationSelectors.getReservationItems(state, ownProps.days),
  status: ReservationSelectors.getStatus(state.ReservationState)
});

const mapDispatchToProps = dispatch => ({
  fetchReservationDetails: (bookingId, params) => dispatch(ReservationActions.fetchReservationDetails(bookingId, params))
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarDailyTimeline);
