import React from 'react'
// Types
import { ISlotData } from 'store/season/season.types';
// Components
import { Message } from 'components/Utility';
//
import SeasonSlotsListItem from './SeasonSlotsListItem';

type Props = {
  register: any;
  errors: any;
  items: Array<ISlotData>;
  onDeleteSlot: (uuid:string) => void;
}

const SeasonSlotsList:React.FC<Props> = ({
  // Props
  register, errors, items, onDeleteSlot
}) => {
  if ( items.length === 0 ) return <Message text="No slot(s)" />;
  return (
    <React.Fragment>
      {items.map((item:ISlotData, index:number) => (
        <SeasonSlotsListItem
          key={`season-slots-item-${item.uuid}`}
          register={register}
          errors={errors}
          index={index}
          item={item}
          onDeleteSlot={onDeleteSlot}
        />
      ))}
    </React.Fragment>
  )
}

export default SeasonSlotsList;
