import React from 'react';
// Store
import { connect } from 'react-redux';
import { ContentItemActions as Actions } from 'app/store/content-item/content-item.actions';
import * as CategorySelectors from 'app/store/content-category/content-category.selectors';
import * as ItemSelectors from 'app/store/content-item/content-item.selectors';

const ContentItemsFilter = ({
  // State
  categories, params,
  // Dispatch
  fetchContentItems
}) => {
  const { contentCategoryId } = params;

  const handleChange = e => {
    e.persist();
    fetchContentItems({
      ...params,
      contentCategoryId: e.target.value,
      offset: 0
    });
  }

  return (
    <div className="container-fluid">
      <div className="row row-8 | justify-content-center">
        <div className="col-12 col-sm-6">
          <div className="form-group">
            <label htmlFor="filterCategory">Category</label>
            <select
              className="form-control form-control-sm"
              name="filterCategory" id="filterCategory"
              value={contentCategoryId || ''}
              onChange={handleChange}
            >
              <option value="">All</option>
              {categories.map(category => (
                <option
                  key={`filterCategory-option-${category.id}`}
                  value={category.id}
                >{category.name}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  categories: CategorySelectors.getItems(state),
  params: ItemSelectors.getParams(state)
});

const mapDispatchToProps = dispatch => ({
  fetchContentItems: params => dispatch(Actions.fetchList(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentItemsFilter);
