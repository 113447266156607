import HttpUtility from 'utilities/http.utility';
import { IMapData } from './map.types';

export default class MapService {
  private static _url:string = '/admin/maps';
  private static _http:HttpUtility = new HttpUtility();

  // Fetch list
  public static async fetchList():Promise<any> {
    return MapService._http.get(`${MapService._url}`);
  }
  // Fetch by id
  public static async fetchById(id:number):Promise<any> {
    return MapService._http.get(`${MapService._url}/${id}`);
  }
  // Create
  public static async create(data:IMapData):Promise<any> {
    return MapService._http.post(MapService._url, data);
  }
  // Update
  public static async update(id:number, data:IMapData):Promise<any> {
    return MapService._http.put(`${MapService._url}/${id}`, data);
  }
  // Delete
  public static async delete(id:number):Promise<any> {
    return MapService._http.delete(`${MapService._url}/${id}`);
  }
  // Actions
  // Enable
  public static async enable(id:number):Promise<any> {
    return MapService._http.post(`${MapService._url}/${id}/actions/enable`);
  }
  // Disable
  public static async disable(id:number):Promise<any> {
    return MapService._http.post(`${MapService._url}/${id}/actions/disable`);
  }
  // Set as main
  public static async setAsMain(id:number):Promise<any> {
    return MapService._http.post(`${MapService._url}/${id}/actions/setAsMain`);
  }
}
