import { call, put, takeLatest } from 'redux-saga/effects';
import Statuses from 'types/statuses';
import * as LayoutActions from 'app/actions/layout.actions';
import MapPoiService from './map-poi.service';
import MapPoiActions from './map-poi.actions';
import {
  MapPoiMessages,
  CREATE, UPDATE, DELETE
} from './map-poi.types'

export default function* (){
  yield takeLatest(CREATE, _create);
  yield takeLatest(UPDATE, _update);
  yield takeLatest(DELETE, _delete);
}

function* _create(action:any){
  try {
    const { data } = action.payload;
    const { parseBody } = yield call(MapPoiService.create, data);
    yield put(MapPoiActions.createSuccess(parseBody));
    yield put(MapPoiActions.setStatus(Statuses.Success));
    yield put(MapPoiActions.setStatus(Statuses.Initial));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: MapPoiMessages.Create
    }));
  } catch(error:any){
    yield put(MapPoiActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _update(action:any){
  try {
    const { id, data } = action.payload;
    const { parseBody } = yield call(MapPoiService.update, id, data);
    yield put(MapPoiActions.updateSuccess(parseBody));
    yield put(MapPoiActions.setStatus(Statuses.Success));
    yield put(MapPoiActions.setStatus(Statuses.Initial));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: MapPoiMessages.Update
    }));
  } catch(error:any){
    yield put(MapPoiActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}

function* _delete(action:any){
  try {
    const { id } = action.payload;
    yield call(MapPoiService.delete, id);
    yield put(MapPoiActions.deleteSuccess(id));
    yield put(MapPoiActions.setStatus(Statuses.Success));
    yield put(MapPoiActions.setStatus(Statuses.Initial));
    yield put(LayoutActions.addNotification({
      type: 'success',
      message: MapPoiMessages.Delete
    }));
  } catch(error:any){
    yield put(MapPoiActions.setStatus(Statuses.Error));
    yield put(LayoutActions.addNotification({
      type: 'danger',
      message: error.message
    }));
  }
}
