import { Reducer } from 'redux';
import {
  IAccountState,
  AccountActionTypes,
  FETCH_LIST, FETCH_LIST_SUCCESS,
  CREATE, CREATE_SUCCESS,
  UPDATE, UPDATE_SUCCESS,
  DELETE, DELETE_SUCCESS,
  FETCH_SETTING_NAMES, FETCH_SETTING_NAMES_SUCCESS,
  SET_STATUS
} from './account.types';
import Statuses from 'types/statuses';

export default class AccountReducer {
  private static readonly _initialState:IAccountState = {
    items: null,
    settings: null,
    isFetching: false,
    isLoading: false,
    status: Statuses.Initial
  }

  public static reducer:Reducer<IAccountState, AccountActionTypes> = (
    state = AccountReducer._initialState,
    action
  ) => {
    switch (action.type){
      // Fetch list
      case FETCH_LIST:
        return { ...state, isFetching: true };
      case FETCH_LIST_SUCCESS:
        return { ...state, items: action.payload.items, isFetching: false };
      // Create
      case CREATE:
        return { ...state, isLoading: true };
      case CREATE_SUCCESS:
        return {
          ...state,
          items: state.items ? [...state.items, action.payload.account] : state.items,
          isLoading: false
        };
      // Update
      case UPDATE:
        return { ...state, isLoading: true };
      case UPDATE_SUCCESS:
        return {
          ...state,
          items: state.items
            ? state.items?.map(
              item => item.id === action.payload.account.id
                ? action.payload.account : item
            )
            : state.items
          ,
          isLoading: false
        };
      // Delete
      case DELETE:
        return state;
      case DELETE_SUCCESS:
        return {
          ...state,
          items: state.items
            ? state.items.filter(item => item.id !== action.payload.id)
            : state.items
          ,
        };
      // Fetch setting names
      case FETCH_SETTING_NAMES:
        return state;
      case FETCH_SETTING_NAMES_SUCCESS:
        return { ...state, settings: action.payload.settings };
      // Default
      // Set status
      case SET_STATUS:
        const hasError:boolean = action.payload.status === Statuses.Error;
        return {
          ...state,
          status: action.payload.status,
          isLoading: hasError ? false : state.isLoading,
          isFetching: hasError ? false : state.isFetching,
        };
      default:
        return state;
    }
  }
}
