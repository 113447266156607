export const loginForm = values => {
  const errors = {};
  if ( _isEmpty(values.email) ){
    errors['email'] = 'This field is required';
  } else if ( _isInvalidEmail(values.email) ){
    errors['email'] = 'E-mail is not valid';
  }
  if ( _isEmpty(values.password) ){
    errors['password'] = 'This field is required';
  }
  return errors;
}

// Reset password
export const forgotPasswordForm = values => {
  const errors = {};
  if ( _isEmpty(values.email) ){
    errors['email'] = 'This field is required';
  } else if ( _isInvalidEmail(values.email) ){
    errors['email'] = 'E-mail is not valid';
  }
  return errors;
}

// Update password form
export const updatePasswordForm = values => {
  const errors = {};
  if ( _isEmpty(values.confirmationCode) ){
    errors['confirmationCode'] = 'This field is required';
  }
  if ( _isEmpty(values.email) ){
    errors['email'] = 'This field is required';
  } else if ( _isInvalidEmail(values.email) ){
    errors['email'] = 'E-mail is not valid';
  }
  if ( _isEmpty(values.password) ){
    errors['password'] = 'This field is required';
  }
  if ( _isEmpty(values.rePassword) ){
    errors['rePassword'] = 'This field is required';
  } else if ( _notMatch(values.password, values.rePassword) ){
    errors['rePassword'] = 'Password isn`t match';
  }
  return errors;
}

const _isEmpty = value => {
  if ( typeof value === 'undefined' ) return true;
  if ( typeof value === 'string' ){
    return value.trim() === '';
  } else {
    return !Boolean(value);
  }
}
const _isInvalidEmail = value => {
  // const pattern = /^[a-zA-Z0-9._.+.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  // const pattern = /^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/;
  const pattern = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
  return !pattern.test(value);
}
const _isInvalidDate = value => {
  const pattern = /^\d{4}-\d{2}-\d{2}$/;
  return !pattern.test(value);
}
const _notMatch = (value, reValue) => value.trim() !== reValue.trim();