import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// Redux
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import OrderActions from 'store/order/order.actions';
import * as OrderSelectors from 'store/order/order.selectors';
import { IActivity } from 'store/order/order.types';

type IProps = {
  // State
  item: IActivity;
  isLoading: boolean;
  // Dispatch
  checkInOrder: (activityId:number, confirmationCode:string) => void;
}

const OrderCheckInPage:React.FC<IProps> = ({
  // State
  item, isLoading,
  // Dispatch
  checkInOrder
}) => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const activityId:string = query.get('activityId') || '';
  const confirmationCode:string = query.get('confirmationCode') || '';

  useEffect(() => {
    if ( activityId && confirmationCode ) checkInOrder(Number(activityId), confirmationCode);
    // eslint-disable-next-line
  }, []);

  if ( isLoading ) return (
    <div className="text-center p-3">
      <div className="spinner-border text-primary" role="status"></div>
    </div>
  );
  return (
    <div className="container-sm pt-3 pb-3">
      {item && item.id ? (
        <div className="alert alert-success">
          <b>{item.productionName}</b> successfully checked-in
        </div>
      ) : null}
    </div>
  )
}

const mapStateToProps = (state:any) => ({
  item: OrderSelectors.getActivityItem(state),
  isLoading: OrderSelectors.getActivityIsLoading(state)
});

const mapDispatchToProps = (dispatch:Dispatch) => ({
  checkInOrder: (activityId:number, confirmationCode:string) => dispatch(OrderActions.checkIn(activityId, confirmationCode, 'checkedIn'))
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderCheckInPage);
