import React, { useState } from 'react';
// Redux
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import MapPoiActions from 'store/map-poi/map-poi.actions';
import { getItems } from 'store/map-poi/map-poi.selectors';
import { IMapPoi } from 'store/map-poi/map-poi.types';
// Dialogs
import MapPoiFormDialog from 'dialogs/MapPoiForm.dialog';
// Components
import { Container } from 'components/Container';
// 
import MapDetailsMarkersListItem from './MapDetailsMarkersListItem';

type IProps = {
  // State
  items: Array<IMapPoi>;
  // Dispatch
  deleteMapPoi: (id:number) => void;
}

const MapDetailsMarkers:React.FC<IProps> = ({
  // State
  items,
  // Dispatch
  deleteMapPoi
}) => {
  const [show, setShow] = useState<boolean>(false);

  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);

  return (
    <>
      <b className="mb-2">Markers</b>
      <div className="text-right mb-2">
        <button
          className="btn btn-outline-primary btn-sm"
          type="button"
          onClick={handleShow}
        >Add marker</button>
      </div>
      <Container data={items} isFetching={false} text="No marker(s)">
        <ul className="list-group">
          {items && items.map(item => (
            <MapDetailsMarkersListItem
              key={`marker-item-${item.id}`}
              item={item}
              onDelete={deleteMapPoi}
            />
          ))}
        </ul>
      </Container>
      {show ? (
        <MapPoiFormDialog
          show={show}
          onHide={handleHide}
        />
      ) : null}
    </>
  )
}

const mapStateToProps = (state:any) => ({
  items: getItems(state)
});

const mapDispatchToProps = (dispatch:Dispatch) => ({
  deleteMapPoi: (id:number) => dispatch(MapPoiActions.delete(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(MapDetailsMarkers);
