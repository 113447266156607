import { Reducer } from 'redux';
// Types
import Statuses from 'types/statuses';
import { MembershipTypeTypes, MembershipTypeActionTypes } from './membership-type.types';
// Models
import { IState } from './membership-type.models';
import { IMembershipType } from 'models/MembershipType';

export default class MembershipTypeReducer {
  private static readonly _initialState:IState = {
    membershipTypes: null,
    membershipType: null,
    isLoading: false,
    status: Statuses.Initial
  };

  public static reducer:Reducer<IState, MembershipTypeActionTypes> = (
    state = MembershipTypeReducer._initialState,
    action
  ) => {
    switch (action.type){
      // Fetch membership types
      case MembershipTypeTypes.FetchMembershipTypes:
        return { ...state, membershipTypes: null };
      case MembershipTypeTypes.FetchMembershipTypesSuccess:
        return { ...state, membershipTypes: action.payload.membershipTypes };
      // Fetch membership type
      case MembershipTypeTypes.FetchMembershipType:
        return { ...state, membershipType: null };
      case MembershipTypeTypes.FetchMembershipTypeSuccess:
        return { ...state, membershipType: action.payload.membershipType };
      // Create membership type
      case MembershipTypeTypes.CreateMembershipType:
        return { ...state, isLoading: true };
      case MembershipTypeTypes.CreateMembershipTypeSuccess:
        return {
          ...state,
          membershipTypes: state.membershipTypes
            ? [...state.membershipTypes, action.payload.membershipType]
            : state.membershipTypes
          ,
          isLoading: false
        };
      // Update membership type
      case MembershipTypeTypes.UpdateMembershipType:
        return { ...state, isLoading: true };
      case MembershipTypeTypes.UpdateMembershipTypeSuccess:
        return {
          ...state,
          membershipTypes: state.membershipTypes
            ? state.membershipTypes.map((membershipType:IMembershipType) => {
                if ( membershipType.id === action.payload.membershipType.id ) return action.payload.membershipType;
                return membershipType;
              })
            : state.membershipTypes
          ,
          isLoading: false
        };
      // Delete membership type
      case MembershipTypeTypes.DeleteMembershipType:
        return { ...state, isLoading: true };
      case MembershipTypeTypes.DeleteMembershipTypeSuccess:
        return {
          ...state,
          membershipTypes: state.membershipTypes
            ? state.membershipTypes.filter((membershipType:IMembershipType) => {
                return membershipType.id !== action.payload.membershipTypeId
              })
            : state.membershipTypes
          ,
          isLoading: false
        };
      // Enable membership type
      case MembershipTypeTypes.EnableMembershipType:
        return { ...state, isLoading: true };
      case MembershipTypeTypes.EnableMembershipTypeSuccess:
        return {
          ...state,
          membershipTypes: state.membershipTypes
            ? state.membershipTypes.map((membershipType:IMembershipType) => {
                if ( membershipType.id === action.payload.membershipTypeId ) return { ...membershipType, enabled: true };
                return membershipType;
              })
            : state.membershipTypes
          ,
          isLoading: false
        };
      // Disable membership type
      case MembershipTypeTypes.DisableMembershipType:
        return { ...state, isLoading: true };
      case MembershipTypeTypes.DisableMembershipTypeSuccess:
        return {
          ...state,
          membershipTypes: state.membershipTypes
            ? state.membershipTypes.map((membershipType:IMembershipType) => {
                if ( membershipType.id === action.payload.membershipTypeId ) return { ...membershipType, enabled: false };
                return membershipType;
              })
            : state.membershipTypes
          ,
          isLoading: false
        };
      // Reset default waiver
      case MembershipTypeTypes.ResetDefaultWaiver:
        return { ...state, isLoading: true };
      case MembershipTypeTypes.ResetDefaultWaiverSuccess:
        return { ...state, membershipType: action.payload.membershipType, isLoading: false };
      // Set default waiver
      case MembershipTypeTypes.SetDefaultWaiver:
        return { ...state, isLoading: true };
      case MembershipTypeTypes.SetDefaultWaiverSuccess:
        return { ...state, membershipType: action.payload.membershipType, isLoading: false };
      // Default
      // Set status
      case MembershipTypeTypes.SetStatus:
        const hasError:boolean = action.payload.status === Statuses.Error;
        return {
          ...state,
          isLoading: hasError ? false : state.isLoading,
          status: action.payload.status
        };
      default:
        return state;
    }
  }
}
